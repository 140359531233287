import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./index.module.scss";

const CButton = ({
  title,
  icon = null,
  onClick,
  disabled = false,
  htmlType = "button",
  customStyles,
  type = "default",
  shape,
  color,
  onMouseUp,
  onMouseDown,
  error = "",
  onTouchStart,
  onTouchEnd,
  onTouchCancel,
  onTouchMove,
  backgroundColor,
  font,
  style,
  divTitle,
  dataLoading,
}) => {
  let cx = classNames.bind(styles);
  let globalClasses = cx({
    btnGlobal: true,
    [`${type}`]: type,
    [`${shape}`]: shape,
    [`${customStyles}`]: customStyles,
  });

  return (
    <>
      <button
        title={divTitle}
        className={globalClasses}
        onClick={onClick}
        disabled={disabled}
        type={htmlType}
        style={{
          color,
          backgroundColor: disabled ? `${backgroundColor}80` : backgroundColor,
          fontFamily: font,
          ...style,
        }}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchCancel={onTouchCancel}
        onTouchMove={onTouchMove}
        data-loading={dataLoading}
      >
        {icon}
        {icon && title && (
          <span className={styles["btnGlobal__divider"]}></span>
        )}
        {title}
      </button>
      {error && <div className={styles["u-style-errors"]}>{error}</div>}
    </>
  );
};

CButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  customStyles: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.string,
  color: PropTypes.string,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  error: PropTypes.string,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchCancel: PropTypes.func,
  onTouchMove: PropTypes.func,
  backgroundColor: PropTypes.string,
  font: PropTypes.string,
  style: PropTypes.object,
  divTitle: PropTypes.string,
};

export default CButton;

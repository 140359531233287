import React from 'react'
import RecentSearches from "../../pages/SearchDocuments/RecentSearches";
import './styles.scss'
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";
import { SEARCH_VALUE_KEY } from "../../pages/SearchDocuments";

const MobileSearchBar = ({
                           handleSubmit,
                             searchDocuments,
                               setSearched,
                                 userData,
                                   searchValue,
                                     setSearchValue,
                         }) => {
  const navigate = useNavigate();

  return (
    <div className="mobile-search-container">
      <RecentSearches
        handleSubmit={handleSubmit}
        searchDocuments={searchDocuments}
        setSearched={setSearched}
        userData={userData}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <button className="mobile-search-container__cancelButton"
              onClick={() => {
                localStorage.removeItem(SEARCH_VALUE_KEY)
                navigate(keys.DASHBOARD)
              }}
      >
        Cancel
      </button>
    </div>
  )
}

export default MobileSearchBar;

import React from "react";
import PropTypes from "prop-types";

const TrashBin = ({ size = 24, color = "#E53935", className }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.92913 3.71184H7.71484C7.8327 3.71184 7.92913 3.61541 7.92913 3.49755V3.71184H16.072V3.49755C16.072 3.61541 16.1684 3.71184 16.2863 3.71184H16.072V5.64041H18.0006V3.49755C18.0006 2.55201 17.2318 1.78326 16.2863 1.78326H7.71484C6.76931 1.78326 6.00056 2.55201 6.00056 3.49755V5.64041H7.92913V3.71184ZM21.4291 5.64041H2.57199C2.09788 5.64041 1.71484 6.02344 1.71484 6.49755V7.35469C1.71484 7.47255 1.81127 7.56898 1.92913 7.56898H3.54699L4.20859 21.5779C4.25145 22.4913 5.00681 23.2118 5.9202 23.2118H18.0809C18.997 23.2118 19.7497 22.494 19.7925 21.5779L20.4541 7.56898H22.072C22.1898 7.56898 22.2863 7.47255 22.2863 7.35469V6.49755C22.2863 6.02344 21.9032 5.64041 21.4291 5.64041ZM17.8747 21.2833H6.12645L5.47824 7.56898H18.5229L17.8747 21.2833Z"
        fill={color}
      />
    </svg>
  );
};

TrashBin.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default TrashBin;

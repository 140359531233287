import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import parse from "html-react-parser";
import {
  Layout,
  Header,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  HelpCenter,
  Insights,
  ConfirmSendContract,
} from "../../components";
import // getDocument,
// setOpenConfirmWindow,
// sendSignees,
// setIsOverview,
// updateDocument,
// addSignee,
"../../store/actions/prompts";
import {
  documentContentSelector,
  documentInsightsSelector,
  documentSelector,
  isPreviewSelector,
  signeeDataSelector,
  filteredPromptSelector,
  isOverviewSelector,
  signAndSendButtonTitleSelector,
  // userInfoSelector,
  // signeesSelector,
} from "../../store/selectors/prompts";
import { FORM_ICON } from "../../constants/config";
import * as keys from "../../utils/keys";
import { arrayOfParties, getPartyID } from "../../utils/helper";
import styles from "../../styles/Wizard.module.scss";
import { t } from "i18next";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReviewContract = (props) => {
  const {
    // getDocument,
    content = [],
    insights = [],
    documents = {},
    isPreview,
    signee,
    filteredPrompt,
    // setOpenConfirmWindow,
    isOverview,
    buttonTitle,
    userInfo,
    // sendSignees,
    // setIsOverview,
    allSignees,
    // updateDocument,
    // addSignee,
  } = props;
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  const query = useQuery();
  const [openRightPanel, setOpenPanelVisible] = useState(false); //a variable that indicates whether the help bar is open
  const [RightPanelComponent, setRightPanelComponent] = useState(null); //a variable that contains the element that is in the right panel
  const [insightsOnRightPanel, setIsInsightsOnRightPanel] = useState(false); //a variable that indicates whether there is data in the right pane, so as not to make the main document invisible, if so
  const [showInscriptionSmallScreen, setShowInscriptionSmallScreen] =
    useState(true); //a variable that indicates whether you want to show the caption above the button in the mobile view
  const [prevScrollPosition, setPrevScrollPosition] = useState(0); //variable that contains the last position of the screen, in order to compare with the current and change the previous variable according to the result
  const [buttonQuestionHeight, setButtonQuestionHeight] = useState("1%");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [isSign, setIsSign] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openSignWindow, setOpenSignWindow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      loadDefaultData();
    }, 10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //a function that checks whether the scroll state has changed
    const handleScroll = (e) => {
      //if the user scrolls up, show the caption
      if (window.scrollY < prevScrollPosition) {
        setShowInscriptionSmallScreen(true);
        setPrevScrollPosition(window.scrollY);
      } else if (window.scrollY > prevScrollPosition) {
        //if the user scrolls down, hide the caption
        setShowInscriptionSmallScreen(false);
        setPrevScrollPosition(window.scrollY);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    //change the position of the question mark, depending on the screen size, what mode is now in the pages and whether there is a button at the bottom of the screen
    if (!isOverview) {
      if (showInscriptionSmallScreen && !isPreview) {
        if (window.innerWidth < 361) {
          setButtonQuestionHeight("175px");
        } else if (window.innerWidth < 1182) {
          setButtonQuestionHeight("145px");
        } else {
          setButtonQuestionHeight("1%");
        }
      } else {
        if (window.innerWidth < 1182) {
          setButtonQuestionHeight("90px");
        } else {
          setButtonQuestionHeight("1%");
        }
      }
    } else {
      if (window.innerWidth < 1182) {
        setButtonQuestionHeight("25px");
      } else {
        setButtonQuestionHeight("1%");
      }
    }
  }, [showInscriptionSmallScreen]);

  useEffect(() => {
    //check if the button is active if we went from the next page
    const partyNamesArray = arrayOfParties(filteredPrompt);
    if (isPreview) {
      setIsButtonEnabled(false);
      if (signee !== undefined && signee?.length >= partyNamesArray?.length) {
        let numberOfTrue = 0;

        for (let i = 0; i < partyNamesArray?.length; i++) {
          if (
            signee?.some(
              (item) => item?.userData[0].value === partyNamesArray[i]
            )
          ) {
            numberOfTrue++;
          }
        }

        if (numberOfTrue >= partyNamesArray?.length) {
          setIsButtonEnabled(true);
        }
      }
    }
  }, [signee]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const loadDefaultData = () => {
    if (
      window.location.href
        .split("/")
        [window.location.href.split("/").length - 1].includes("contract") &&
      window.location.href
        .split("/")
        [window.location.href.split("/").length - 1].includes("recipient")
    ) {
      const signeeWithId = allSignees?.filter(
        (item) => item.signeeID === query.get("recipient")
      );
      if (signeeWithId?.[0]?.status === "TO_SIGN") {
        // setIsOverview(false);
        setIsSign(true);
      } else {
        // setIsOverview(true);
      }
    } else if (
      window.location.href
        .split("/")
        [window.location.href.split("/").length - 1].includes("contract")
    ) {
      // setIsOverview(true);
    }

    if (!isPreview) {
      // getDocument(`${params.id}?passcode=${query.get("passcode")}`);
    }
  };

  const openHelpCenter = (tips) => {
    setIsInsightsOnRightPanel(false);
    setRightPanelComponent(<HelpCenter data={tips} />);
    setOpenPanelVisible(true);
  };

  const openInsightsPanel = () => {
    setIsInsightsOnRightPanel(true);
    setRightPanelComponent(
      <Insights
        data={insights}
        signeeData={allSignees}
        headerStyles={
          isSign && styles["c-contract__sign__right-panel__header__mobile"]
        }
      />
    );
    setOpenPanelVisible(true);
  };

  //logo change function, depending on the received data
  const renderDocumentLogo = (id) => {
    switch (id) {
      case FORM_ICON.FEATHER_ICON_ID:
        return <Icons.FeatherIcon />;
      default:
        return <Icons.FeatherIcon />;
    }
  };

  const toSending = (openConfirm = false) => {
    //a function that goes to the next page depending on which page was before
    if (isPreview) {
      if (!openConfirm) {
        if (buttonTitle === "Send document") {
          let arrrayToSend = [];
          let type = "";
          const withOrder = signee
            ?.filter((el) => el !== null)
            ?.filter((el) => el !== undefined)
            ?.some((el) => el.showNumber === true);

          signee
            ?.filter((el) => el !== null)
            ?.filter((el) => el !== undefined)
            ?.forEach((item, index) => {
              let isSender = item.isMainUser;
              let partyID = getPartyID(
                documents.parties,
                item.userData[0].value
              );
              let order = index + 1;

              if (withOrder && isSender && order === 1) {
                type = "orderSenderFirst";
              }

              if (withOrder) {
                arrrayToSend.push({
                  contact: {
                    firstName: item.userData[2].value,
                    lastName: item.userData[3].value,
                    email: item.userData[4].value,
                  },
                  isSender,
                  partyID,
                  order,
                });
              } else {
                arrrayToSend.push({
                  contact: {
                    firstName: item.userData[2].value,
                    lastName: item.userData[3].value,
                    email: item.userData[4].value,
                  },
                  isSender,
                  partyID,
                });
              }
            });

          const isSender = signee
            ?.filter((el) => el !== null)
            ?.filter((el) => el !== undefined)
            ?.some((item) => item.isMainUser === true);

          if (type === "") {
            if (isSender && !withOrder) {
              type = "noOrderWithSender";
            } else if (!isSender && !withOrder) {
              type = "noOrderNoSender";
            } else if (withOrder && !isSender) {
              type = "orderWithoutSender";
            } else {
              type = "orderSenderNotFirst";
            }
          }

          let signeeID;
          if (type === "noOrderWithSender" || type === "orderSenderFirst") {
            signeeID = userInfo?.userID;
          }

          // sendSignees(
          //   type,
          //   arrrayToSend,
          //   documents.title,
          //   documents.documentID,
          //   signeeID,
          //   async () => {
          //     await // getDocument(
          //       `${params.id}?passcode=${query.get("passcode")}`
          //     );
          //     navigate(keys.DOCUMENT_OVERVIEW + `/${params.id}/end`);
          //   }
          // );
        } else {
          // setOpenConfirmWindow(true);
        }
      }
      navigate(
        keys.SEND_CONTRACT + `/${params.id}?passcode=${query.get("passcode")}`
      );
    } else if (isOverview) {
      navigate(
        keys.DOCUMENT_OVERVIEW +
          `/${params.id}/overview?passcode=${query.get("passcode")}`
      );
    } else {
      navigate(
        keys.SEND_CONTRACT + `/${params.id}?passcode=${query.get("passcode")}`
      );
    }
  };

  const toSign = () => {
    // addSignee(
    //   params.id,
    //   query.get("passcode"),
    //   {
    //     createdBy: allSignees?.filter(
    //       (item) => item.signeeID === query.get("recipient")
    //     )[0].signeeID,
    //   },
    //   () => {
    //     navigate(
    //       keys.DOCUMENT_OVERVIEW +
    //         `/${params.id}/overview?passcode=${query.get("passcode")}`
    //     );
    //   }
    // );
  };

  const toDecline = () => {
    const necessarySignee = allSignees?.filter(
      (item) => item.signeeID === query.get("recipient")
    );

    // updateDocument(
    //   params.id,
    //   { signees: [{ ...necessarySignee[0], status: "DECLINED" }] },
    //   () => {
    //     navigate(
    //       keys.DOCUMENT_OVERVIEW +
    //         `/${params.id}/overview?passcode=${query.get("passcode")}`
    //     );
    //   }
    // );
  };

  //open the right data panel if you click on the link
  useEffect(() => {
    if (location.hash === "#variables-side-modal") {
      openInsightsPanel();
    }
  }, [location]);

  return (
    <Layout
      header={
        <Header
          title={documents?.title} //130  1920
          shortTitle={documents?.title}
          progress={isPreview || isOverview || isSign ? 0 : 100}
          leftType={(isPreview || isOverview) && "arrowLeft"}
          onLeftSideClick={(isPreview || isOverview) && toSending}
          right={
            !openSignWindow && (
              <>
                {!isSign ? (
                  <>
                    <div className={styles["c-contract__small-screen-button"]}>
                      <CButton
                        type="icon"
                        icon={<Icons.MenuIcon />}
                        onClick={() => openInsightsPanel()}
                      />
                    </div>
                    <div className={styles["c-contract__full-screen-buttons"]}>
                      <CButton
                        type="reverse-primary"
                        title={t("contract:view_details")}
                        onClick={() => openInsightsPanel()}
                      />
                      {isOverview ? (
                        <></>
                      ) : (
                        <>
                          {isPreview ? (
                            <CButton
                              type="primary"
                              icon={<Icons.ArrowRightBig />}
                              title={buttonTitle}
                              onClick={() => toSending(false)}
                              disabled={!isButtonEnabled}
                            />
                          ) : (
                            <CButton
                              type="primary"
                              icon={<Icons.PlusIcon />}
                              title={t("contract:add_signatures")}
                              onClick={toSending}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles["c-contract__sign__header-buttons"]}>
                    <CButton
                      type="icon"
                      icon={<Icons.ThreeVerticalDotsIcon color={"#5F6CFF"} />}
                      onClick={() => setOpenDropdown(!openDropdown)}
                    />
                    {window.innerWidth > 1182 && (
                      <CButton
                        type="primary"
                        icon={
                          <Icons.FeatherIcon
                            className={styles["c-contract__sign__button-icon"]}
                          />
                        }
                        title={t("contract:accept_and_sign")}
                        onClick={() => setOpenSignWindow(true)}
                      />
                    )}
                  </div>
                )}
              </>
            )
          }
        />
      }
      helpButtonBottom={buttonQuestionHeight}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-wizard"]}>
        {!openSignWindow && (
          <>
            <RightPanel
              children={RightPanelComponent}
              open={openRightPanel}
              onClose={() => {
                setOpenPanelVisible(false);
                setIsInsightsOnRightPanel(false);
              }}
              visibleBack={insightsOnRightPanel}
              type={isSign && insightsOnRightPanel && "arrowLeft"}
            />
            {openDropdown && (
              <div
                className={styles["c-contract__sign__dropdown-background"]}
                onClick={() => {
                  setOpenDropdown(!openDropdown);
                }}
              >
                <div className={styles["c-contract__sign__dropdown"]}>
                  {window.innerWidth < 1182 && (
                    <div
                      className={styles["c-contract__sign__dropdown__item"]}
                      onClick={() => {
                        openInsightsPanel();
                        setOpenDropdown(!openDropdown);
                      }}
                    >
                      <Icons.MenuIcon />
                      {t("contract:view_details")}
                    </div>
                  )}
                  <div
                    className={styles["c-contract__sign__dropdown__item"]}
                    onClick={toDecline}
                  >
                    <Icons.DeclineIcon />
                    Decline
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {!openSignWindow ? (
          <div className={[styles["c-contract"], styles["c-review"]].join(" ")}>
            <div className={styles["c-contract__body"]}>
              <div
                className={styles["c-contract__head"]}
                style={{ background: documents?.style?.color }}
              >
                <h1>
                  <div className={styles["c-contract__head__logo"]}>
                    {renderDocumentLogo(documents?.style?.logo)}{" "}
                  </div>
                  <div>{documents?.documentCategory}</div>
                </h1>
              </div>
              <div
                className={[
                  styles["c-contract__article"],
                  styles["c-review__article"],
                ].join(" ")}
                style={{ fontFamily: documents?.style?.font }}
              >
                <div className={styles["c-contract__date"]}>
                  <h3 style={{ fontFamily: documents?.style?.font }}>
                    {t("contract:created_on")}{" "}
                    {documents?.createdAt?.split("T")[0]}
                  </h3>
                </div>

                {content.map((elem) => {
                  switch (elem.type) {
                    case "SUBTITLE_I":
                      return (
                        <span className={styles["c-contract__box"]}>
                          <span
                            className={styles["c-contract__number"]}
                            style={{
                              fontFamily: documents?.style?.font,
                              background: documents?.style?.color,
                            }}
                          >
                            <i>{elem.text.split(".")[0]}</i>
                          </span>
                          <span
                            className={styles["c-contract__title__section"]}
                            style={{ fontFamily: documents?.style?.font }}
                          >
                            {elem.text.split(".")[1]}
                          </span>
                          {elem.tips === undefined ? (
                            ""
                          ) : (
                            <span>
                              <CButton
                                type="icon"
                                onClick={() => openHelpCenter(elem.tips)}
                                icon={
                                  <Icons.LightBulbIcon
                                    className={styles["c-wizard__icon"]}
                                  />
                                }
                                color={documents?.style?.color}
                              />
                            </span>
                          )}
                        </span>
                      );
                    case "PARAGRAPH":
                      return (
                        <div
                          className={[
                            styles["c-review__group__body"],
                            styles["c-review__list"],
                          ].join(" ")}
                        >
                          <p
                            className={styles["c-contract__text"]}
                            style={{ fontFamily: documents.style.font }}
                          >
                            {parse(elem.text)}
                          </p>
                        </div>
                      );
                    default:
                      return <div>{t("contract:something_went_wrong")}</div>;
                  }
                })}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={[styles["c-review"], styles["c-send-contract"]].join(
              " "
            )}
          >
            <div className={styles["c-review__article"]}>
              <ConfirmSendContract
                onConfirm={toSign}
                onBreakClick={() => {
                  setOpenSignWindow(false);
                }}
              />
            </div>
          </div>
        )}

        {!openSignWindow && (
          <>
            {!isOverview ? (
              <>
                {/* pinned inscription on the right for full screen */}
                {!isPreview ? (
                  <>
                    <div
                      className={
                        styles["c-contract__navigation-bar__background"]
                      }
                    ></div>
                    <div className={styles["c-contract__navigation-bar"]}>
                      {!isSign ? (
                        <h1>{t("contract:get_ready_to_sign")}.</h1>
                      ) : (
                        <>
                          <div
                            className={
                              styles["c-contract__navigation-bar__background"]
                            }
                          ></div>
                          <div
                            className={styles["c-contract__sign__right-panel"]}
                          >
                            <Insights
                              data={insights}
                              signeeData={allSignees}
                              title={t("contract:sign_the_document")}
                              headerStyles={
                                styles["c-contract__sign__right-panel__header"]
                              }
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* pinned caption at the bottom for the small screen */}
                <div
                  className={styles[`c-contract__navigation-bar__small-screen`]}
                >
                  {!isPreview ? (
                    <div
                      className={
                        styles[
                          `c-contract__navigation-bar__small-screen__text${
                            showInscriptionSmallScreen
                              ? "__visible"
                              : "__hidden"
                          }`
                        ]
                      }
                    >
                      {!isSign ? (
                        <h1>{t("contract:get_ready_to_sign")}.</h1>
                      ) : (
                        <h1>{t("contract:sign_the_document")}</h1>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    className={
                      styles[
                        `c-contract__navigation-bar__small-screen__button${
                          showInscriptionSmallScreen ? "__visible" : "__hidden"
                        }`
                      ]
                    }
                  >
                    {isPreview ? (
                      <CButton
                        type="primary"
                        icon={<Icons.ArrowRightBig />}
                        title={buttonTitle}
                        onClick={() => toSending(false)}
                        disabled={!isButtonEnabled}
                      />
                    ) : isSign ? (
                      <CButton
                        type="primary"
                        icon={
                          <Icons.FeatherIcon
                            className={styles["c-contract__sign__button-icon"]}
                          />
                        }
                        title={t("contract:accept_and_sign")}
                        onClick={() => setOpenSignWindow(true)}
                      />
                    ) : (
                      <CButton
                        type="primary"
                        icon={<Icons.PlusIcon />}
                        title={t("contract:add_signatures")}
                        onClick={toSending}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  // content: documentContentSelector(state),
  // insights: documentInsightsSelector(state),
  documents: documentSelector(state),
  // isPreview: isPreviewSelector(state),
  // signee: signeeDataSelector(state),
  // filteredPrompt: filteredPromptSelector(state),
  // isOverview: isOverviewSelector(state),
  // buttonTitle: signAndSendButtonTitleSelector(state),
  // userInfo: userInfoSelector(state),
  // allSignees: signeesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getDocument,
      // setOpenConfirmWindow,
      // sendSignees,
      // setIsOverview,
      // updateDocument,
      // addSignee,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContract);

import React from "react";
import PropTypes from "prop-types";

const TickIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="19"
      height="15"
      viewBox="0 0 19 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0359 0.000689181L16.4757 0.00662769C16.2569 0.00746027 16.0497 0.108696 15.9165 0.28108L6.75778 11.9745L2.32358 6.39982C2.2565 6.31532 2.17115 6.24711 2.07394 6.20032C1.97672 6.15352 1.87017 6.12936 1.76228 6.12964L0.202023 6.13558C0.0524701 6.13614 -0.0294645 6.30833 0.0624945 6.42406L6.20577 14.1463C6.49286 14.5068 7.04196 14.5047 7.32853 14.142L18.1777 0.285866C18.2687 0.171679 18.1855 0.000119968 18.0359 0.000689181V0.000689181Z"
        fill={color}
      />
    </svg>
  );
};

TickIcon.propTypes = {
  color: PropTypes.string,
};

export default TickIcon;

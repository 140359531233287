export const DAY = "(^(0[1-9]|[12][0-9]|3[01])$)";
export const MONTH = "(^(0[1-9]|1[012])$)";
export const YEAR = "(^([2]0([2-9])([0-9])|[09]{4})$)";

export const EMAIL = "^[\+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,}$";

export const VERIFICATION_CODE = "^[0-9]{4}$";

export const IS_HEX = "[0-9A-F]+";

export const DATE =
  "^(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[/.](20)(2[2-9]|[3-9][0-9])$";

export const ANY_DATE =
  "^(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[/.]([0-9][0-9][0-9][0-9])$";

import React from "react";
import PropTypes from "prop-types";

const ArrowRightIcon = ({
  color = "currentColor",
  className,
  width = "20",
  height = "20",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.189 9.39882L0.517127 0.0372166C0.441235 -0.00072979 0.354181 -0.00965829 0.271592 0.010431C0.17993 0.0330912 0.100981 0.0911492 0.0520333 0.171893C0.00308517 0.252636 -0.0118742 0.349485 0.0104309 0.441234L1.93454 8.30284C1.96356 8.42114 2.05061 8.51713 2.16668 8.55507L5.46356 9.68677L2.16891 10.8185C2.05284 10.8586 1.96579 10.9524 1.939 11.0707L0.0104309 18.9435C-0.00965834 19.0261 -0.000729667 19.1131 0.0372168 19.1868C0.12427 19.3631 0.338556 19.4345 0.517127 19.3475L19.189 10.0394C19.2582 10.006 19.314 9.94793 19.3497 9.88097C19.4368 9.70239 19.3653 9.48811 19.189 9.39882ZM2.21132 16.7069L3.33409 12.1176L9.92338 9.85641C9.97472 9.83856 10.0171 9.79838 10.035 9.74481C10.0662 9.65106 10.0171 9.55061 9.92338 9.51713L3.33409 7.2582L2.21579 2.68677L16.2336 9.71579L2.21132 16.7069V16.7069Z"
        fill={color}
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  color: PropTypes.string,
};

export default ArrowRightIcon;

import React, { useState, useMemo } from "react";
import CButton from "../CButton";
import Icons from "../Icons";
import { api } from "../../services/api";

import { Modal, Space, Typography, Button, notification } from "antd";

const { Text, Title } = Typography;

export function ConfirmApprovalModal({
  isModalOpen,
  closeModal,
  isAppoveTrue,
  documentId,
  approverId,
  getDocument,
  passcode,
}) {
  function approveContract(status) {
    api
      .post(`document/${documentId}/review/${status}?approverID=${approverId}`)
      .then(() => {
        getDocument(documentId, passcode, true);
        closeModal();
        if (status === "approved") {
          notification.info({
            message: `🎉 You have accepted the document`,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        } else {
          notification.info({
            message: `You have rejected the document ❌`,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      });
  }

  return (
    <Modal
      footer={false}
      open={isModalOpen}
      onCancel={closeModal}
      closeIcon={false}
      width={"376px"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Icons.WarningFillIcon size={48} color="#F29D41" />

        <Space align="center" direction="vertical">
          <Title level={4} style={{ margin: 0 }}>
            {isAppoveTrue
              ? "Do you want to approve this contract?"
              : "Do you want to reject this contract?"}
          </Title>
          <Text style={{ fontSize: "14px", textAlign: "center" }}>
            {isAppoveTrue
              ? "If you approve it, we will notify the document owner."
              : "If you reject it, we will notify the document owner."}
          </Text>
        </Space>

        <div style={{ display: "flex", gap: 16, width: "100%" }}>
          <Button
            onClick={closeModal}
            type="ghost"
            style={{
              width: "100%",
              padding: "8px 32px",
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            No
          </Button>
          <Button
            type="primary"
            style={{
              width: "100%",
              padding: "8px 32px",
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() =>
              approveContract(isAppoveTrue ? "approved" : "rejected")
            }
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function Approval({ thisDocument, approverId, getDocument }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAppoveTrue, setIsAppoveTrue] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openRejectModal = () => {
    setIsAppoveTrue(false);
    setIsModalOpen(true);
  };
  const openApproveModal = () => {
    setIsAppoveTrue(true);
    setIsModalOpen(true);
  };

  function CancelApprovalDecision() {
    api
      .post(
        `document/${thisDocument?.documentID}/review/cancel?approverID=${approverId}`
      )
      .then(() => {
        getDocument(thisDocument?.documentID, thisDocument?.passcode, true);
        notification.info({
          message: `You have cancelled your decision 👌`,
          duration: 3,
          placement: "bottom",
          icon: <></>,
          closeIcon: <></>,
          className: "select-dropwdown-notification",
        });
      });
  }

  const approverUser = useMemo(() => {
    return thisDocument?.approvers?.find(
      (approver) => approver?.approverID === approverId
    );
  }, [approverId, thisDocument]);

  return (
    <>
      <div style={{ display: "flex", gap: 12 }}>
        {approverUser?.status === "not_reviewed" && (
          <>
            <CButton
              type="primary"
              title="Reject"
              icon={<Icons.CancelCircleIcon color="#fff" size={20} />}
              style={{ background: "#E53935", padding: "8px 16px" }}
              onClick={openRejectModal}
            />
            <CButton
              type="primary"
              title="Approve"
              icon={<Icons.CheckCircleOutlineIcon color="#fff" size={20} />}
              onClick={openApproveModal}
              style={{ background: "#42B983", padding: "8px 16px" }}
            />
          </>
        )}

        {((thisDocument?.approvers?.length > 1 &&
          approverUser?.status !== "approved") ||
          approverUser?.status === "rejected") && (
          <CButton
            type="reverse-primary"
            title="Cancel decision"
            icon={<Icons.CancelCircleIcon color="#5F6CFF" size={20} />}
            onClick={CancelApprovalDecision}
            style={{ padding: "8px 16px" }}
          />
        )}
      </div>
      <ConfirmApprovalModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        isAppoveTrue={isAppoveTrue}
        documentId={thisDocument?.documentID}
        approverId={approverId}
        getDocument={getDocument}
        passcode={thisDocument?.passcode}
      />
    </>
  );
}

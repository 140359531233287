import React, { useRef, useEffect, useState } from "react";

import styles from "./index.module.scss";

export function Message({
  newDocumentData,
  setNewDocumentData,
  documentStyles,
  thisDocument,
  t,
  isTemplateGenerator,
  saveEdit,
}) {
  const messageDefaultValue =
    thisDocument?.defaultReminder?.message ||
    thisDocument?.reminder?.message ||
    newDocumentData?.reminder?.message ||
    undefined;

  const typingTimeoutRef = useRef(null);
  const [text, setText] = useState(messageDefaultValue);

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  function handleTextAreaInput(event) {
    const value = event.currentTarget.value;

    setText(value);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (saveEdit) {
        saveEdit({
          defaultReminder: {
            ...thisDocument?.defaultReminder,
            message: value,
          },
        });
      } else {
        setNewDocumentData({
          reminder: {
            ...newDocumentData?.reminder,
            message: value,
          },
        });
      }
    }, 500); // 500ms delay
  }

  return (
    <div className={styles["c-signsend-status__item"]}>
      <div
        className={styles["c-signsend-status__article"]}
        style={{
          fontFamily: documentStyles?.font,
        }}
      >
        <div
          className={styles["teritary__head"]}
          style={{
            borderBottom: `1px solid ${
              documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
            }`,
          }}
        >
          <div className={styles["teritary__head__title"]}>
            {t("sign_send:message")}
          </div>
        </div>

        <div className={styles["teritary__body"]}>
          <p>
            {isTemplateGenerator
              ? "Predefine a message for the signees who sign documents created from this template."
              : t("sign_send:add_message")}
          </p>

          <div className={styles["u-message__textarea"]}>
            <textarea
              placeholder={t("sign_send:dear_team")}
              style={{ fontFamily: documentStyles?.font }}
              onChange={handleTextAreaInput}
              value={text}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

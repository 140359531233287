import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { TagViewNode } from "../../nodes/TagViewNode";

export function TagViewPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([TagViewNode])) {
      throw new Error("TagViewPlugin: TagNode not registered on editor");
    }
  }, []);

  return null;
}

import React, { useState, useRef, useMemo } from "react";
import { Button, Modal, Space, Typography, Upload, Spin, Row, Col } from "antd";
import styles from "../../styles/Document.module.scss";
import { Icons } from "../../components";
import { LoadingOutlined } from "@ant-design/icons";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";

const { Text, Title } = Typography;
const { Dragger } = Upload;

const LoadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export function CreateTemplate({ getDocument, isModalOpen, setIsModalOpen }) {
  const [status, setStatus] = useState({});
  const [fileInfo, setFileInfo] = useState();
  const [uploadedDocument, setUploadedDocument] = useState();
  const navigate = useNavigate();

  const uploadRef = useRef(null);

  async function handleFileUpload({ file, onSuccess, onError }) {
    let formData = new FormData();
    formData.append("file", file);

    return api
      .post(`document/static`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        onError();
      })
      .then((response) => {
        setUploadedDocument(response?.data);
        onSuccess();
      });
  }

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    customRequest: handleFileUpload,
    accept: "application/pdf",
    onChange(info) {
      const { status: fileStatus } = info.file;
      setFileInfo(info.file);
      if (fileStatus === "uploading") {
        setStatus({ ...status, isUploading: true });
      } else if (fileStatus === "done") {
        setStatus({ ...status, isUploading: false, isFinished: true });
      } else if (fileStatus === "error") {
        setStatus({
          ...status,
          isUploading: false,
          errorOnUpload: true,
          isFinished: true,
        });
      }
    },
  };

  const fileIsPdf = useMemo(() => {
    return fileInfo?.type === "application/pdf" ? true : false;
  }, [fileInfo]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setStatus({});
  };

  const handleButtonClick = () => {
    if (uploadRef.current) {
      uploadRef.current.upload.uploader.onClick();
    }
  };

  const handleDocumentClick = () => {
    getDocument(uploadedDocument?.documentID, undefined, undefined, () => {
      navigate(keys.REVIEW_INPUT, {
        state: { isPreviw: true },
      });
    });
  };
  return (
    <>
      {/* <div className={styles["document__item"]}>
        <div onClick={showModal} className={styles["document__article"]}>
          <div
            className={styles["document__article__head"]}
            style={{ backgroundColor: "#D9DCFF" }}
          >
            <Icons.PlusOutlined size={32} color="#5F6CFF" />
          </div>
          <div className={styles["document__article__body"]}>
            <div className={styles["document__article__body__logo"]}>
              <img
                src={
                  "https://storage.googleapis.com/swiftsign-icons/swiftsign_feather_logo.png"
                }
                alt={"document logo"}
              />
            </div>
            <div className={styles["document__article__body__detail"]}>
              <div className={styles["document__creatorname"]}>Blank</div>
              <div className={styles["document__username"]}>Swiftsign</div>
            </div>
          </div>
        </div>
      </div> */}
      <Modal
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={false}
        width={"376px"}
        destroyOnClose
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                {!status?.isUploading && !status?.isFinished
                  ? "Import PDF"
                  : fileIsPdf
                  ? "Import PDF"
                  : "Import Microsoft Word file"}
              </Title>
              <div
                style={{
                  background: "#A4AAFC",
                  padding: "2px 4px",
                  color: "white",
                  height: "100%",
                  borderRadius: "3px",
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                Beta
              </div>
            </Space>
            <Button
              type="text"
              shape="circle"
              onClick={handleCancel}
              icon={<Icons.CloseIcon size="14px" />}
            />
          </div>

          <Space direction="vertical" size={24}>
            {!status?.isUploading && !status?.isFinished && (
              <>
                <Text>
                  Import PDF files here. No PDF file available? Tools like
                  Microsoft Word, Google Docs and Pages can export to this
                  format.
                </Text>
              </>
            )}

            <Dragger
              {...props}
              ref={uploadRef}
              style={{
                display:
                  status?.isUploading || status?.isFinished ? "none" : "block",
                cursor: "default",
              }}
              openFileDialogOnClick={false}
            >
              <p className="ant-upload-text" style={{ fontSize: "14px" }}>
                Drag and drop here
              </p>
            </Dragger>

            {status?.isUploading && !status?.errorOnUpload && (
              <Space
                direction="vertical"
                align="center"
                size={24}
                style={{ width: "100%" }}
              >
                <img
                  src={fileIsPdf ? "/pdf-export.png" : "/word-doc.png"}
                  alt="word doc"
                />
                <Text>Uploading file...</Text>
                <Spin indicator={LoadingIcon} />
                <Text type="secondary" style={{ fontSize: "14px" }}>
                  For larger files, this may take a few minutes.
                </Text>
              </Space>
            )}

            {status?.errorOnUpload && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  textAlign: "center",
                }}
              >
                <Space align="center" direction="vertical">
                  <Icons.CloseCicleFilled size={48} />
                  <Text>We are so sorry</Text>
                </Space>

                <Text type="secondary" style={{ fontSize: "14px" }}>
                  There was an error and your file could not be uploaded. Would
                  you like to try again?
                </Text>

                <div style={{ display: "flex", gap: 8 }}>
                  <Button
                    onClick={handleCancel}
                    type="ghost"
                    style={{
                      width: "100%",
                      padding: "8px 32px",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={() => {
                      setStatus({});
                    }}
                    type="primary"
                    style={{
                      width: "100%",
                      padding: "8px 32px",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Retry
                  </Button>
                </div>
              </div>
            )}
            {status?.isFinished && !status?.errorOnUpload && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "24px",
                  textAlign: "center",
                }}
              >
                <Space align="center" direction="vertical">
                  <Icons.CheckCircleIcon size={48} />
                  <Text>Success!</Text>
                </Space>

                <Text type="secondary" style={{ fontSize: "14px" }}>
                  Your imported file is now in your template gallery.
                </Text>

                <div style={{ display: "flex", gap: 8 }}>
                  <Button
                    onClick={handleCancel}
                    type="ghost"
                    style={{
                      width: "100%",
                      padding: "8px 32px",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    type="primary"
                    style={{
                      width: "100%",
                      padding: "8px 32px",
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={handleDocumentClick}
                  >
                    Open document
                  </Button>
                </div>
              </div>
            )}

            {!status?.isUploading && !status?.isFinished && (
              <Button
                onClick={handleButtonClick}
                type="primary"
                style={{ width: "100%" }}
              >
                Upload file
              </Button>
            )}
          </Space>
        </div>
      </Modal>
    </>
  );
}

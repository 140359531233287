import React from "react";
import { Stepper, Icons } from "..";
import styles from "./index.module.scss";

const LeftSidebar = ({
  currentPrompt,
  prompts,
  open,
  onClose,
  goToCard,
  documentStyles,
  canChangePosition,
  isPositionsChanged,
}) => {
  return (
    <aside className={`${styles["c-aside"]} ${open ? styles["active"] : ""}`}>
      <div className={styles["c-aside__head"]}>
        <div className={styles["c-aside__title"]}>Outline</div>
        <div onClick={onClose} className={styles["c-aside__btn"]}>
          <Icons.CloseIcon />
        </div>
      </div>
      {prompts && (
        <Stepper
          currentPrompt={currentPrompt}
          prompts={prompts}
          goToCard={goToCard}
          documentStyles={documentStyles}
          canChangePosition={canChangePosition}
          isPositionsChanged={isPositionsChanged}
        />
      )}
    </aside>
  );
};

export default LeftSidebar;

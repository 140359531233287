import React, { useState, useRef, useEffect } from "react";
import { Space, Form, Select, Input, Button, Typography } from "antd";
import { Icons } from "../../../../components";
import { WarningTooltip } from "../index";

const { Option } = Select;
const { Text } = Typography;

export default function SelectFormItem({
  formName,
  focus,
  setFocus,
  type,
  icon,
  defaultValue,
  focusOn,
  onFinish,
  form,
  setVariables,
  arrayValues,
  disabled,
  canShare,
  onSelectChange,
}) {
  const [selectItems, setSelectItems] = useState([]);
  const [selectedOption, seSelectedOption] = useState("");
  const [addOptionOpen, setAddOptionOpen] = useState(true);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const submitRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  function handleTypeChange(value) {
    onSelectChange?.({
      value,
    });
    seSelectedOption(value);
  }

  const handleDeleteOption = (value) => {
    onSelectChange?.({
      options: {
        value: selectItems.filter((v) => v !== value),
      },
    });
    setSelectItems(selectItems.filter((v) => v !== value));
    form.setFieldValue(formName, undefined);
  };

  const addItem = (e) => {
    if (addOptionOpen && name) {
      e?.preventDefault?.();
      if (!selectItems.includes(name)) {
        const newOptions = [...selectItems, name];

        onSelectChange?.({
          options: {
            value: newOptions,
          },
        });
        setSelectItems(newOptions);
      }
      setName("");
      setAddOptionOpen(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      setAddOptionOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e?.preventDefault?.();
      inputRef.current?.focus();
      submitRef.current.click();
    }
  };

  useEffect(() => {
    if (arrayValues?.length) {
      setAddOptionOpen(false);
      setSelectItems(arrayValues);
    }
  }, [arrayValues]);

  useEffect(() => {
    if (defaultValue) {
      seSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setVariables &&
      setVariables((prevVariables) => {
        return prevVariables.map((variable) => {
          if (variable.key === formName) {
            // Check if key matches
            // Modify the object with the new selectItems value
            return {
              ...variable,
              options: { ...variable.options, value: selectItems },
            };
          }
          return variable; // Return the original object if key doesn't match
        });
      });
  }, [formName, selectItems, setVariables]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {icon}
      <WarningTooltip canShare={canShare}>
        <Form.Item name={formName} initialValue={defaultValue}>
          <Select
            disabled={disabled}
            placeholder={"Select option..."}
            onClick={() => {
              setFocus(focusOn);
              inputRef.current?.focus();
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
            onBlur={() => {
              onFinish();
              setFocus(0);
            }}
            onSelect={() => {
              setTimeout(() => {
                setFocus(focusOn);
              }, 100);
            }}
            onChange={handleTypeChange}
            dropdownRender={(menu) => (
              <AddDropdownRender
                addItem={addItem}
                addOptionOpen={addOptionOpen}
                inputRef={inputRef}
                submitRef={submitRef}
                menu={menu}
                name={name}
                onNameChange={onNameChange}
              />
            )}
            notFoundContent={<NotFoundContent />}
            onKeyDown={handleKeyDown}
            popupClassName="field-input-select"
          >
            {selectItems.map((option) => {
              return (
                <Option key={option} value={option}>
                  <Space
                    align="center"
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      display: "flex",
                      justifyContent:
                        selectedOption !== option ? "start" : "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 6 }}>
                      <div style={{ maxWidth: 305, overflow: "auto" }}>
                        {option}
                      </div>
                      <div
                        className="close-icon-select"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteOption(option);
                        }}
                      >
                        <Icons.CloseCicleFilled size="14px" color="#8C8C97" />
                      </div>
                    </div>

                    {selectedOption === option && (
                      <span
                        className={"current-icon-select"}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Icons.Check size="22px" />
                      </span>
                    )}
                  </Space>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </WarningTooltip>
    </Space>
  );
}

const AddDropdownRender = ({
  menu,
  inputRef,
  addOptionOpen,
  name,
  addItem,
  onNameChange,
  submitRef,
}) => {
  return (
    <form
      onSubmit={(e) => {
        addItem(e);
      }}
      id="data-fields"
    >
      {menu}

      <Space
        size={4}
        direction="vertical"
        style={{
          width: "100%",
        }}
      >
        {addOptionOpen && (
          <div style={{ padding: "4px 12px" }}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
          </div>
        )}

        <div
          style={{
            width: "100%",
            borderTop: "1px solid #F3F3F6",
          }}
        >
          <Button
            ref={submitRef}
            type="text"
            icon={<Icons.PlusOutlined size={16} />}
            onClick={addItem}
            htmlType="submit"
            disabled={!name && addOptionOpen}
            style={{
              color: !name && addOptionOpen ? "rgba(0,0,0,.25)" : "#5F6CFF",
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              gap: 10,
              width: "100%",
            }}
          >
            Add item
          </Button>
        </div>
      </Space>
    </form>
  );
};

const NotFoundContent = () => {
  return (
    <div
      style={{
        fontSize: "12px",
      }}
    >
      <Text type="secondary">No options to choose from</Text>
    </div>
  );
};

import styles from "../../styles/Document.module.scss";
import documentNda from "../../assets/images/document-nda.png";

export const TemplateItem = ({ onClick, color, item, noImage, Icon }) => {
  return (
    <div className={styles["document__item"]}>
      <div onClick={onClick} className={styles["document__article"]}>
        <div
          className={styles["document__article__head"]}
          style={{ backgroundColor: color }}
        >
          {!noImage && <img src={documentNda} alt={"document nda"} />}
          {Icon && <Icon />}
        </div>
        <div className={styles["document__article__body"]}>
          <div className={styles["document__article__body__logo"]}>
            <img
              src={
                item?.creatorInfo?.image ??
                "https://storage.googleapis.com/swiftsign-icons/swiftsign_feather_logo.png"
              }
              alt={"document logo"}
            />
          </div>
          <div className={styles["document__article__body__detail"]}>
            <div className={styles["document__creatorname"]}>{item?.title}</div>
            <div className={styles["document__username"]}>
              {item?.creatorInfo?.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateItem;

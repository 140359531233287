import React, { createContext, useContext, useMemo, useState } from 'react';

export const TemplateContext = createContext();

function TemplateProvider({ children }) {
  const [template, setTemplate] = useState({})
  const [currentSelectedVariableKey, setCurrentSelectedVariableKey] = useState("")

  const variables = useMemo(() => {
    return template.variables ?? []
  }, [template.variables])

  const currentSelectedVariable = useMemo(() => {
    return variables?.find((v) => v.key === currentSelectedVariableKey)
  }, [variables, currentSelectedVariableKey])

  return (
    <TemplateContext.Provider
      value={{ 
        template, 
        setTemplate, 
        variables, 
        currentSelectedVariableKey, 
        setCurrentSelectedVariableKey,
        currentSelectedVariable
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
}

function useTemplate() {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error('useTemplate: Component must be within the Context Provider');
  }
  const { template, setTemplate, variables, setCurrentSelectedVariableKey, currentSelectedVariableKey, currentSelectedVariable } = context;
  return { setTemplate, template, variables, setCurrentSelectedVariableKey, currentSelectedVariableKey, currentSelectedVariable };
}

export { TemplateProvider, useTemplate };
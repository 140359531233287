import axios from "axios";
import { API_URL } from "../../constants/config";
import {
  clearAuthData,
  setToken,
  setErrorLoadingScreenType,
} from "../../store/actions/auth";
import { store } from "../../store";
import { Navigate } from "react-router-dom";
import * as keys from "../../utils/keys";

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const persistData = localStorage.getItem("persist:root");

  if (persistData) {
    const parsedData = JSON.parse(persistData);
    const authReducer = JSON.parse(parsedData.authReducer);
    const token = JSON.parse(parsedData.token);
    const accountID = authReducer?.workspace;

    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (accountID) config.headers["account-id"] = accountID;
  }

  // If there is no _retry property, then add it and set to 0
  if (!config._retry) config._retry = 0;

  return config;
});

api.interceptors.response.use(
  (response) => {
    // Simply return the response if it was successful
    return response;
  },
  async (error) => {
    // If the error status was 401, then we want to get a new token and retry
    if (error?.response?.status === 401 && error.config._retry < 2) {
      try {
        const token = await store.getState()?.auth0?.getAccessTokenSilently();
        store.dispatch(setToken(token));

        // Increase the retry count
        error.config._retry++;

        // Now let's clone the original request and try again
        const config = error.config;

        // Update the Authorization header with the new token
        config.headers.Authorization = `Bearer ${token}`;

        // Retry the request
        return await api(config);
      } catch (error) {
        store.dispatch(setErrorLoadingScreenType("error"));
      }
    }

    // If the error was due to other reasons, we just throw it back
    return Promise.reject(error);
  }
);

export { api };

import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";

import { Icons } from "..";
import FolderLine from "./FolderLine";

import { accountsSelector } from "../../store/selectors/auth";
import { activeFolderSelector } from "../../store/selectors/dashboard";

import {
  changeAccountData,
  updateDocumentFromDashboard,
} from "../../store/actions/auth";
import { setActiveFolder } from "../../store/actions/dashboard";

import styles from "./index.module.scss";

const Folder = ({
  accounts,
  changeAccountData,
  setActiveFolder,
  activeFolder,
  onFolderClick = () => {},
  updateDocumentFromDashboard,
}) => {
  const { pathname } = useLocation();
  const foldersRefs = useRef([]);

  const [dropdownId, setDropdownId] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [nowEditing, setNowEditing] = useState(null);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    if (!!accounts?.[0]?.folders) {
      const localFolders = accounts?.[0]?.folders;

      setFolders(localFolders);

      if (!pathname.includes("folders")) {
        setActiveFolder(null);
      }

      setTimeout(() => {
        if (isCreatingFolder) {
          setNowEditing(localFolders[localFolders.length - 1]?.id);
          setIsCreatingFolder(false);
        }
      }, 200);
    }
  }, [accounts]);

  useEffect(() => {
    foldersRefs.current = foldersRefs.current.slice(0, folders.length);
  }, [folders]);

  const handleClick = (e) => {
    if (dropdownId) {
      setDropdownId(null);
    }

    if (!!nowEditing) {
      for (let i = 0; i < folders.length; i++) {
        if (
          !!foldersRefs?.current[i] &&
          foldersRefs?.current[i].id === nowEditing &&
          !foldersRefs?.current[i].contains(e.target)
        ) {
          setNowEditing(null);
        }
      }
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    for (let i = 0; i < folders.length; i++) {
      if (
        !!foldersRefs?.current[i] &&
        foldersRefs?.current[i].contains(event.target)
      ) {
        setDropdownId(foldersRefs.current[i]?.id);
      }
    }
  };

  return (
    <nav className={styles["c-folder"]}>
      <span className={`${styles["c-folder__box"]} ${styles["add-folder"]}`}>
        <span
          className={styles["c-folder__icon"]}
          onClick={() => {
            changeAccountData({
              folders: [...folders, { name: "New folder" }],
            });
            setIsCreatingFolder(true);
          }}
        >
          <Icons.AddFolderIcon />
        </span>
        <span className={styles["c-folder__text"]}>Folders</span>
      </span>

      <ul>
        {folders &&
          folders?.map((item, index) => {
            return (
              <FolderLine
                ref={(el) => (foldersRefs.current[index] = el)}
                key={`folder-${item.id}`}
                item={item}
                index={index}
                activeFolder={activeFolder}
                setActiveFolder={setActiveFolder}
                changeAccountData={changeAccountData}
                folders={folders}
                onFolderClick={onFolderClick}
                setNowEditing={setNowEditing}
                setDropdownId={setDropdownId}
                dropdownId={dropdownId}
                nowEditing={nowEditing}
                updateDocument={updateDocumentFromDashboard}
              />
            );
          })}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  accounts: accountsSelector(state),
  activeFolder: activeFolderSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeAccountData,
      setActiveFolder,
      updateDocumentFromDashboard,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Folder);

import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { redirectParamsSelector } from "../../store/selectors/auth";
import { availableHeaderStepSelector } from "../../store/selectors/prompts";

import * as keys from "../../utils/keys";

import styles from "./index.module.scss";

const StepTopBar = ({
  color,
  font,
  currentId,
  availableStep,
  redirectParams,
  canClickTab,
  data,
}) => {
  const { t } = useTranslation();

  const defaultData = data ?? [
    {
      name: t("wizard:header.create"),
      id: 1,
      position: 1,
    },
    {
      name: t("wizard:header.review"),
      id: 2,
      position: 2,
    },
    {
      name: t("wizard:header.sign_and_send"),
      id: 3,
      position: 3,
    },
  ];

  const navigate = useNavigate();

  const li = {
    boxShadow: `inset 0 -2px 0 ${color}`,
  };

  return (
    <nav className={styles["step-top-bar"]}>
      <ul>
        {defaultData.map(({ id, position, name }, index) => (
          <li
            key={id}
            className={
              id === currentId
                ? styles[`${"current"}`]
                : id < currentId
                ? styles[`${"completed"}`]
                : ""
            }
            style={
              id === currentId && window.innerWidth < 1023 && color
                ? li
                : undefined
            }
            onClick={() => {
              if (index === 0) {
                if (redirectParams) {
                  navigate(`${redirectParams?.path}${redirectParams?.search}`);
                } else {
                  navigate(keys.WIZARD);
                }
              } else if (index === 1 && availableStep > 0) {
                if (redirectParams) {
                  navigate(keys.REVIEW_INPUT, { state: { isSign: true } });
                } else {
                  navigate(keys.REVIEW_INPUT);
                }
              } else if (index === 2 && availableStep > 1) {
                if (redirectParams) {
                  navigate(keys.SIGN, { state: { cameFromEmail: true } });
                } else {
                  navigate(keys.SIGN_SEND);
                }
              }
            }}
          >
            <span className={styles["step-top-bar__box"]}>
              <span className={styles["step-top-bar__number"]}>
                <i
                  style={{
                    color:
                      id === currentId || id < currentId ? color : undefined,
                    fontFamily: font,
                  }}
                >
                  {position}
                </i>
              </span>
              <span
                className={styles["step-top-bar__text"]}
                style={{
                  color: id === currentId || id < currentId ? color : undefined,
                  fontFamily: font,
                }}
              >
                {name}
              </span>
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  availableStep: availableHeaderStepSelector(state),
  redirectParams: redirectParamsSelector(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepTopBar);

import React from "react";
import PropTypes from "prop-types";

const ThreeVerticalDotsIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4.47656C10.5 4.87439 10.658 5.25592 10.9393 5.53722C11.2206 5.81853 11.6022 5.97656 12 5.97656C12.3978 5.97656 12.7794 5.81853 13.0607 5.53722C13.342 5.25592 13.5 4.87439 13.5 4.47656C13.5 4.07874 13.342 3.69721 13.0607 3.4159C12.7794 3.1346 12.3978 2.97656 12 2.97656C11.6022 2.97656 11.2206 3.1346 10.9393 3.4159C10.658 3.69721 10.5 4.07874 10.5 4.47656ZM10.5 11.9766C10.5 12.3744 10.658 12.7559 10.9393 13.0372C11.2206 13.3185 11.6022 13.4766 12 13.4766C12.3978 13.4766 12.7794 13.3185 13.0607 13.0372C13.342 12.7559 13.5 12.3744 13.5 11.9766C13.5 11.5787 13.342 11.1972 13.0607 10.9159C12.7794 10.6346 12.3978 10.4766 12 10.4766C11.6022 10.4766 11.2206 10.6346 10.9393 10.9159C10.658 11.1972 10.5 11.5787 10.5 11.9766ZM10.5 19.4766C10.5 19.8744 10.658 20.2559 10.9393 20.5372C11.2206 20.8185 11.6022 20.9766 12 20.9766C12.3978 20.9766 12.7794 20.8185 13.0607 20.5372C13.342 20.2559 13.5 19.8744 13.5 19.4766C13.5 19.0787 13.342 18.6972 13.0607 18.4159C12.7794 18.1346 12.3978 17.9766 12 17.9766C11.6022 17.9766 11.2206 18.1346 10.9393 18.4159C10.658 18.6972 10.5 19.0787 10.5 19.4766Z"
        fill={color}
      />
    </svg>
  );
};

ThreeVerticalDotsIcon.propTypes = {
  color: PropTypes.string,
};

export default ThreeVerticalDotsIcon;

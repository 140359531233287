import { all, fork } from "redux-saga/effects";

import prompts from "./prompts";
import auth from "./auth";
import dashboard from "./dashboard";

/**
 * rootSaga
 */
export default function* root() {
  yield all([fork(prompts), fork(auth), fork(dashboard)]);
}

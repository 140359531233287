import React from "react";
import PropTypes from "prop-types";

const ShortAnswerQuestion = ({ color = "currentColor", size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="6" fill="#ECEDFF" />
      <path
        d="M5.01172 7.70775V8.95427C5.01172 9.06853 5.10432 9.16202 5.21749 9.16202H15.0946C15.2078 9.16202 15.3004 9.06853 15.3004 8.95427V7.70775C15.3004 7.59349 15.2078 7.5 15.0946 7.5H5.21749C5.10432 7.5 5.01172 7.59349 5.01172 7.70775ZM10.3123 11.0318H5.21749C5.10432 11.0318 5.01172 11.1253 5.01172 11.2395V12.4861C5.01172 12.6003 5.10432 12.6938 5.21749 12.6938H10.3123C10.4255 12.6938 10.5181 12.6003 10.5181 12.4861V11.2395C10.5181 11.1253 10.4255 11.0318 10.3123 11.0318Z"
        fill={color}
      />
      <path
        d="M15.1065 11.0318H12.2487C12.1356 11.0318 12.043 11.1253 12.043 11.2395V12.4861C12.043 12.6003 12.1356 12.6938 12.2487 12.6938H15.1065C15.2197 12.6938 15.3123 12.6003 15.3123 12.4861V11.2395C15.3123 11.1253 15.2197 11.0318 15.1065 11.0318Z"
        fill={color}
      />
    </svg>
  );
};

ShortAnswerQuestion.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default ShortAnswerQuestion;

import React from "react";
import PropTypes from "prop-types";

const RenameIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.18808 17.9261C4.24165 17.9261 4.29522 17.9207 4.34879 17.9127L8.85415 17.1225C8.90772 17.1118 8.95862 17.0877 8.99611 17.0475L20.3506 5.69302C20.3754 5.66824 20.3951 5.63881 20.4086 5.60641C20.422 5.574 20.4289 5.53927 20.4289 5.50419C20.4289 5.4691 20.422 5.43437 20.4086 5.40196C20.3951 5.36956 20.3754 5.34013 20.3506 5.31535L15.8988 0.860882C15.8479 0.809989 15.7809 0.783203 15.7086 0.783203C15.6363 0.783203 15.5693 0.809989 15.5184 0.860882L4.16397 12.2153C4.12379 12.2555 4.09969 12.3037 4.08897 12.3573L3.29879 16.8627C3.27274 17.0062 3.28205 17.1538 3.32592 17.2929C3.36979 17.432 3.4469 17.5583 3.55058 17.6609C3.72736 17.8323 3.94969 17.9261 4.18808 17.9261ZM5.99344 13.2546L15.7086 3.54213L17.672 5.50552L7.95683 15.218L5.57558 15.6386L5.99344 13.2546ZM20.8568 20.1761H1.14254C0.668436 20.1761 0.2854 20.5591 0.2854 21.0332V21.9975C0.2854 22.1153 0.381829 22.2118 0.499686 22.2118H21.4997C21.6175 22.2118 21.714 22.1153 21.714 21.9975V21.0332C21.714 20.5591 21.3309 20.1761 20.8568 20.1761Z"
        fill={color}
      />
    </svg>
  );
};

RenameIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default RenameIcon;

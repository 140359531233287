import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

import * as keys from "../../../utils/keys";

import { Icons, CButton } from "../../../components";

import documentLogo from "../../../assets/images/document-logo.png";

import styles from "../index.module.scss";

const PreviewDocument = (props) => {
  const {
    onClose,
    templateData,
    createDocumentFunction = () => {},
    setDocumentData,
    setIndex,
    setIsCreatingDocument,
  } = props;

  const navigate = useNavigate();
  const pdfWrapperRef = useRef();
  const { t } = useTranslation();

  const [modalPreview, setModalPreview] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    let numberOfPagesArr = new Array(numPages);

    for (let i = 0; i < numberOfPagesArr.length; i++) {
      numberOfPagesArr[i] = i;
    }

    setNumPages(numberOfPagesArr);
  }

  const onLoadError = (err) => {
    console.log(err, "er");
  };

  return (
    <div className={`${styles["u-overlay"]} ${styles["preview-document"]}`}>
      <div className={styles["u-modal-preview"]}>
        <div className={styles["u-modal-preview__head"]}>
          <div onClick={onClose} className={styles["u-modal-preview__close"]}>
            <Icons.CloseIcon />
          </div>
        </div>

        <div className={styles["u-modal-preview__row"]}>
          <div
            className={styles["u-modal-preview__figure"]}
            ref={pdfWrapperRef}
          >
            <Document
              file={templateData.preview ?? "/PlaceholderPDF.pdf"}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onLoadError}
              className={"create-document-pdf-viewer"}
            >
              <Page
                pageNumber={pageNumber}
                className={"create-document-pdf-viewer__main-page"}
                height={692}
              />
              <div
                className={"create-document-pdf-viewer__preview-page__wrapper"}
              >
                {!!numPages &&
                  numPages?.map((item, index) => (
                    <div
                      key={`preview-page-${index}`}
                      onClick={() => {
                        setPageNumber(index + 1);
                      }}
                    >
                      <Page
                        pageNumber={index + 1}
                        height={171}
                        width={123}
                        className={`create-document-pdf-viewer__preview-page${
                          index + 1 === pageNumber ? "__active" : ""
                        }${index === numPages.length - 1 ? "__last" : ""}`}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </div>
                  ))}
              </div>
            </Document>
          </div>
          <div className={styles["u-modal-preview__detail"]}>
            <div className={styles["u-modal-preview__title"]}>
              {templateData?.fileTitle || templateData?.title}
            </div>
            <div className={styles["u-modal-preview__lastupdated"]}>
              {`${
                !!templateData?.updatedAt
                  ? `Updated ${moment(templateData?.updatedAt).format(
                      "MMM DD, YYYY"
                    )}`
                  : ""
              }`}
            </div>
            <div className={styles["u-modal-preview__description"]}>
              <p>
                {templateData?.description?.split("\\n").map((item) => (
                  <p>{item}</p>
                ))}
              </p>
            </div>

            <div className={styles["u-modal-preview__creator"]}>
              <div className={styles["creator__row"]}>
                <div className={styles["creator__logo"]}>
                  <img
                    src={
                      !!templateData?.creatorInfo?.image
                        ? templateData?.creatorInfo?.image
                        : documentLogo
                    }
                    alt={"document logo"}
                  />
                </div>
                <div className={styles["creator__detail"]}>
                  <div className={styles["creator__creatorname"]}>
                    {templateData?.creatorInfo?.name}
                  </div>
                  <div className={styles["creator__username"]}>
                    {templateData?.creatorInfo?.email}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles["u-modal-preview__foot"]}>
              <div className={styles["u-modal-preview__foot__item"]}>
                <CButton
                  icon={<Icons.PlusIcon />}
                  type="primary"
                  title={"Create document"}
                  customStyles={styles["btn-preview"]}
                  onClick={() => {
                    createDocumentFunction(
                      templateData.templateID,
                      async (res) => {
                        setIsCreatingDocument(true);
                        await setDocumentData(res.documents);
                        setIndex(1);
                        navigate(keys.WIZARD);
                      }
                    );
                  }}
                />
              </div>

              <div className={styles["u-modal-preview__foot__item"]}>
                <CButton
                  type="white"
                  title={t("review_input:preview")}
                  customStyles={`${styles["btn-preview"]} ${styles["has-shadow"]}`}
                  onClick={() => {
                    setModalPreview(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalPreview && (
        <div className={styles["u-mobile__preview"]}>
          <div className={styles["u-mobile__preview__head"]}>
            <div
              onClick={() => setModalPreview(false)}
              className={styles["u-mobile__preview__head__prev"]}
            >
              <Icons.ArrowLeft />
            </div>
            <div className={styles["u-mobile__preview__head__title"]}>
              {t("review_input:preview")}
            </div>
          </div>
          <div className={styles["u-mobile__preview__body"]}>
            <div className={styles["u-mobile__preview__figure"]}>
              <Document
                file={templateData.preview ?? "/PlaceholderPDF.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
                className={"create-document-pdf-viewer-mobile"}
              >
                {!!numPages &&
                  numPages.map((item, index) => (
                    <Page
                      pageNumber={index + 1}
                      className={`create-document-pdf-viewer-mobile__page${
                        index === numPages.length - 1 ? "__last" : ""
                      }`}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  ))}
              </Document>
            </div>
          </div>
          <div className={styles["u-mobile__preview__foot"]}>
            <CButton
              icon={<Icons.PlusIcon />}
              type="primary"
              title={"Create document"}
              customStyles={styles["btn-preview"]}
              onClick={() => {
                createDocumentFunction(templateData.templateID, async (res) => {
                  setIsCreatingDocument(true);
                  await setDocumentData(res.documents);
                  setIndex(1);
                  navigate(keys.WIZARD);
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewDocument;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Row, Col } from "antd";

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  CInput,
  CSelect,
  Icons,
} from "../../components";

import {
  documentSelector,
  isCreatingDocumentSelector,
  newSigneeDataSelector,
} from "../../store/selectors/prompts";
import { userSelector } from "../../store/selectors/auth";

import { setSigneeData } from "../../store/actions/prompts";

import * as regular from "../../utils/regularExpression";
import * as keys from "../../utils/keys";
import { validateWithRegularExpression } from "../../utils/helper";

import localStyles from "./index.module.scss";
import styles from "../../styles/Wizard.module.scss";

const Signee = ({
  thisDocument,
  signeesData,
  setSigneeData,
  currentUser,
  isCreatingDocument,
}) => {
  const { t, i18n } = useTranslation();

  let navigate = useNavigate();

  const [form] = Form.useForm();
  const location = useLocation();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [emailDirty, setEmailDirty] = useState(false);
  const [documentStyles, setDocumentStyles] = useState(null);
  const [areWeEditing, setAreWeEditing] = useState(false);
  const [allFieldsEntered, setAllFieldsEntered] = useState(false);
  const [orderOptionArray, setOrderOptionArray] = useState();
  const [localData, setLocalData] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const [inputInFocus, setInputInFocus] = useState(0);

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  });

  useEffect(() => {
    if (!isCreatingDocument) {
      navigate(keys.DASHBOARD);
    }
  }, [isCreatingDocument]);

  useEffect(() => {
    if (thisDocument) {
      if (thisDocument?.style?.isApplyToWizard) {
        setDocumentStyles(thisDocument?.style);
      } else {
        setDocumentStyles(null);
      }

      if (thisDocument?.language) {
        i18n.changeLanguage(thisDocument.language?.toLowerCase());
      }
    }
  }, [thisDocument]);

  useEffect(() => {
    if (location.state) {
      setAreWeEditing(location.state.isEditing);

      if (location.state.isEditing) {
        setAllFieldsEntered(true);
      } else {
        setAllFieldsEntered(false);
      }

      createSelectOptions(location.state.isEditing);
    }
  }, [location.state, documentStyles]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(
      <HowCanWeHelp color={documentStyles?.color} font={documentStyles?.font} />
    );
    setOpenPanelVisible(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleKeydown = (event) => {
    if (event.keyCode === 13) {
      if (inputInFocus === 3) {
      } else if (inputInFocus === 2 && allFieldsEntered) {
        onFinish();
      } else {
        setInputInFocus(inputInFocus + 1);
      }
    }

    if (event.keyCode === 9) {
      event.preventDefault();
      setInputInFocus(inputInFocus + 1);
    }
  };

  const createSelectOptions = (isEditing) => {
    let orederArr = [];

    // const selectedNumbers = signeesData?.map(
    //   (signeeItem) => signeeItem?.number
    // );

    const maxNumber = isEditing ? signeesData?.length : signeesData?.length + 1;

    for (let i = 0; i < maxNumber; i++) {
      let icon = null;

      // if (selectedNumbers.some((numberItem) => numberItem === i + 1)) {
      //   icon = <Icons.TickIcon color={documentStyles?.color || "#5F6CFF"} />;
      // }

      if (
        location.state?.oldData?.showOrder &&
        i + 1 === location.state?.oldData?.order
      ) {
        icon = <Icons.TickIcon color={documentStyles?.color || "#5F6CFF"} />;
      }

      orederArr.push({
        value: `Position ${i + 1}`,
        icon: icon,
      });
    }

    if (orederArr.length === 0) {
      orederArr.push({
        value: `Position 1`,
        icon: null,
      });
      orederArr.push({
        value: `Position 2`,
        icon: null,
      });
    }

    if (orederArr.length === 1) {
      orederArr.push({
        value: `Position 2`,
        icon: null,
      });
    }

    orederArr.unshift(null);

    setOrderOptionArray(orederArr);

    setFieldsDefault();
  };

  const setFieldsDefault = () => {
    const oldData = location.state.oldData;

    form.setFieldsValue({
      [`firstName`]: oldData?.contact?.firstName,
      [`lastName`]: oldData?.contact?.lastName,
      [`workEmail`]: oldData?.contact?.email,
      [`signsOrder`]:
        oldData?.showOrder && oldData?.order
          ? `Position ${oldData.order}`
          : null,
    });

    setLocalData({
      firstName: oldData?.contact?.firstName,
      lastName: oldData?.contact?.lastName,
      workEmail: oldData?.contact?.email,
      signsOrder:
        oldData?.showOrder && oldData?.order
          ? `Position ${oldData.order}`
          : null,
    });
    setDefaultValues({
      firstName: oldData?.contact?.firstName,
      lastName: oldData?.contact?.lastName,
      workEmail: oldData?.contact?.email,
      signsOrder:
        oldData?.showOrder && oldData?.order
          ? `Position ${oldData.order}`
          : null,
    });
  };

  let timeoutId;

  const emailHandler = (event) => {
    const value = event.target.value;

    const userAlredySigned = thisDocument?.users.some(
      (user) => user.email === value && user.permission === "owner"
    );

    if (value === "") {
      setEmailDirty(false);
      return;
    }

    const validationResult = validateWithRegularExpression(
      regular.EMAIL,
      value
    );

    if (!validationResult) {
      setEmailDirty(true);

      setEmailError(t("signee:invalid_email"));

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setEmailDirty(false);
        setEmailError("");
      }, 10000);
    } else {
      if (userAlredySigned) {
        setEmailDirty(true);
        setEmailError(t("signee:invalid_user"));
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setEmailDirty(false);
          setEmailError("");
        }, 10000);
      } else {
        setEmailDirty(false);
      }
    }
  };

  const onFieldsChange = (_updateField, allFields) => {
    setLocalData({
      ...localData,
      [`${_updateField[0].name[0]}`]: _updateField[0].value,
    });

    isAllDataEntered({
      ...localData,
      [`${_updateField[0].name[0]}`]: _updateField[0].value,
    });
  };

  const isAllDataEntered = (allFields) => {
    let allEntered = true;

    const userAlredySigned = thisDocument?.users.some(
      (user) =>
        user.email === allFields?.workEmail && user.permission === "owner"
    );

    if (
      !allFields?.firstName ||
      !allFields?.lastName ||
      !allFields?.workEmail
    ) {
      allEntered = false;
    }

    if (
      allFields?.workEmail &&
      !validateWithRegularExpression(regular.EMAIL, allFields?.workEmail)
    ) {
      allEntered = false;
    }

    setAllFieldsEntered(allEntered && !userAlredySigned ? true : false);
  };

  const onFinish = () => {
    const oldSigneesData = [...signeesData];

    let order = null;

    if (localData?.signsOrder?.includes("Position")) {
      order = localData?.signsOrder?.split(" ")?.[1];
    }

    const newSignee = {
      contact: {
        email: localData.workEmail,
        firstName: localData.firstName,
        lastName: localData.lastName,
      },
      isSender: currentUser?.email === localData.workEmail,
      partyID: location.state?.partyId,
      order: order ? +order : null,
      showOrder: !!order,
    };

    let oldSigneesWithoutCurrent = oldSigneesData.filter(
      (signeeItem) => signeeItem?.contact?.email !== newSignee?.contact?.email
    );

    if (
      newSignee.order &&
      oldSigneesWithoutCurrent?.some(
        (signeeItem) => signeeItem.order === newSignee.order
      )
    ) {
      let elementWithSameNumber = oldSigneesWithoutCurrent.filter(
        (signeeItem) => signeeItem.order === newSignee.order
      )[0];
      const otherElements = oldSigneesWithoutCurrent.filter(
        (signeeItem) => signeeItem.order !== newSignee.order
      );

      const newOrder = defaultValues.signsOrder?.split(" ")?.[1];

      elementWithSameNumber = {
        ...elementWithSameNumber,
        order: newOrder,
        showOrder: false,
      };

      oldSigneesWithoutCurrent = [...otherElements, elementWithSameNumber];
    }

    setSigneeData([...oldSigneesWithoutCurrent, newSignee]);
    navigate(keys.SIGN_SEND);
  };

  return (
    <Layout
      header={
        <Header
          title={`${
            areWeEditing
              ? t("signee:edit_signee")
              : t("send-contract:add_signee")
          }`}
          shortTitle={`${
            areWeEditing
              ? t("signee:edit_signee")
              : t("send-contract:add_signee")
          }`}
          progress={0}
          leftType="arrowLeft"
          onLeftSideClick={goBack}
          right={<div></div>}
          documentStyles={documentStyles}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      questionIconColor={documentStyles?.color}
    >
      <div
        className={`${styles["c-wizard"]} ${localStyles["c-signee"]}`}
        style={{ fontFamily: documentStyles?.font }}
      >
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
          closeIconColor={documentStyles?.color}
        />

        <div className={localStyles["c-signee__wrapper"]}>
          <div
            className={[
              styles["c-review__article"],
              styles["c-signee__article"],
            ].join(" ")}
          >
            <h1>{t("signee:what_signee_details")}</h1>

            <div className={styles["c-signee__fields"]}>
              <Form
                layout="vertical"
                name="basic"
                onFieldsChange={onFieldsChange}
                form={form}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="firstName">
                      <CInput
                        label={t("send-contract:signee.labels.first_name")}
                        placeholder={t(
                          "send-contract:signee.placeholders.first_name"
                        )}
                        focusOn={inputInFocus === 0}
                        onClick={() => setInputInFocus(0)}
                        color={documentStyles?.color}
                        font={documentStyles?.font}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="lastName">
                      <CInput
                        label={t("send-contract:signee.labels.last_name")}
                        placeholder={t(
                          "send-contract:signee.placeholders.last_name"
                        )}
                        focusOn={inputInFocus === 1}
                        onClick={() => setInputInFocus(1)}
                        color={documentStyles?.color}
                        font={documentStyles?.font}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="workEmail">
                      <CInput
                        label={t("send-contract:signee.labels.work_email")}
                        placeholder={t(
                          "send-contract:signee.placeholders.work_email"
                        )}
                        onChange={(event) => {
                          emailHandler(event);
                        }}
                        errors={emailDirty && emailError}
                        focusOn={inputInFocus === 2}
                        onClick={() => setInputInFocus(2)}
                        color={documentStyles?.color}
                        font={documentStyles?.font}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* Remvoved until fixed. Do not delete  <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="signsOrder">
                      <CSelect
                        label={
                          <>
                            <span className={styles["c-signee__label"]}>
                              {t("send-contract:signee.labels.signing_order")}
                            </span>
                            <span
                              className={`${styles["c-signee__label__optional"]} ${localStyles["c-signee__label__optional"]}`}
                            >
                              {t("send-contract:optional")}
                            </span>
                          </>
                        }
                        placeholder={t(
                          "send-contract:signee.placeholders.signing_order"
                        )}
                        options={orderOptionArray}
                        onClick={() => setInputInFocus(3)}
                        focusOn={inputInFocus === 3}
                        color={documentStyles?.color}
                        font={documentStyles?.font}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
              </Form>
            </div>

            <div className={styles["c-signee__confirm-button"]}>
              <CButton
                type="primary"
                title={`${areWeEditing ? t("signee:done") : t("signee:add")}`}
                onClick={onFinish}
                disabled={!allFieldsEntered}
                backgroundColor={documentStyles?.color}
                font={documentStyles?.font}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisDocument: documentSelector(state),
  signeesData: newSigneeDataSelector(state),
  currentUser: userSelector(state),
  isCreatingDocument: isCreatingDocumentSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSigneeData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Signee);

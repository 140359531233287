import { documentSelector } from "./prompts";

export const accountsSelector = (state) => state.authReducer.accounts;
export const userSelector = (state) => state.authReducer.user;
export const isAuthRequestSelector = (state) => state.authReducer.isRequest;
export const templatesSelector = (state) => state.authReducer.templates;
export const documentsSelector = (state) => state.authReducer.documents;
export const redirectParamsSelector = (state) =>
  state.authReducer.redirectParams;
export const tokenSelector = (state) => state.token;
export const workspaceSelector = (state) => state.authReducer.workspace;
export const tempUserSelector = (state) => state.authReducer.tempUser;

// Selects the current user's permission for the currently watched document
export const userDocumentPermissionSelector = (state) => {
  const user = userSelector(state);
  const document = documentSelector(state);
  if (!document || !user) {
    return null;
  }
  const documentUser = document?.users?.find(
    (documentUser) => documentUser.userID === user.userID
  );
  return documentUser?.permission || null;
};

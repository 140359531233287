import React from "react";
import PropTypes from "prop-types";

const StatusPendingIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62171 5.66544C2.66993 4.74133 3.05564 3.87615 3.71457 3.21722C4.41636 2.51544 5.37528 2.11901 6.36903 2.11901C7.37082 2.11901 8.31368 2.51008 9.0235 3.21722C9.10921 3.30294 9.19225 3.39401 9.26993 3.48776L8.65118 3.96722C8.61927 3.99191 8.59498 4.02509 8.5811 4.06296C8.56722 4.10083 8.5643 4.14186 8.5727 4.18131C8.58109 4.22076 8.60045 4.25705 8.62855 4.28599C8.65665 4.31493 8.69235 4.33535 8.73154 4.3449L11.2306 4.94758C11.3646 4.97972 11.4931 4.87794 11.4958 4.74133L11.5119 2.18597C11.5123 2.14572 11.5013 2.10617 11.4803 2.07186C11.4592 2.03754 11.4289 2.00985 11.3929 1.99196C11.3568 1.97407 11.3164 1.9667 11.2764 1.9707C11.2363 1.97469 11.1982 1.98989 11.1664 2.01455L10.6226 2.43776C9.62082 1.19758 8.086 0.404724 6.36903 0.404724C3.42528 0.404724 1.02261 2.7324 0.904748 5.64669C0.899391 5.76722 0.998498 5.86901 1.11903 5.86901H2.40743C2.52261 5.86901 2.61635 5.78062 2.62171 5.66544ZM11.619 5.86901H10.3306C10.2155 5.86901 10.1217 5.9574 10.1164 6.07258C10.0681 6.99669 9.68243 7.86187 9.0235 8.52079C8.32171 9.22258 7.36279 9.61901 6.36903 9.61901C5.36725 9.61901 4.42439 9.22794 3.71457 8.52079C3.62886 8.43508 3.54582 8.34401 3.46814 8.25026L4.08689 7.7708C4.1188 7.74611 4.14309 7.71293 4.15697 7.67506C4.17085 7.63718 4.17377 7.59616 4.16537 7.55671C4.15698 7.51726 4.13762 7.48097 4.10952 7.45203C4.08142 7.42309 4.04572 7.40267 4.00653 7.39312L1.50743 6.79044C1.3735 6.7583 1.24493 6.86008 1.24225 6.99669L1.22618 9.55204C1.22579 9.5923 1.23675 9.63185 1.25781 9.66616C1.27886 9.70048 1.30915 9.72816 1.34522 9.74606C1.38128 9.76395 1.42165 9.77132 1.46171 9.76732C1.50177 9.76333 1.53989 9.74813 1.57171 9.72348L2.11546 9.30026C3.11725 10.5404 4.65207 11.3333 6.36903 11.3333C9.31279 11.3333 11.7155 9.00562 11.8333 6.09133C11.8387 5.97079 11.7396 5.86901 11.619 5.86901Z"
        fill={color}
      />
    </svg>
  );
};

StatusPendingIcon.propTypes = {
  color: PropTypes.string,
};

export default StatusPendingIcon;

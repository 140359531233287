import initialState from "../initialStates";
import injectReducer from "../injectReducer";

import { AUTH } from "../types";

export default injectReducer(initialState.authReducer, {
  [AUTH.GET_ACCOUNT_DATA_SUCCESS]: (state, { payload: { data } }) => {
    const existingWorkspace = data.accounts?.find(
      (account) => account?.accountID === state?.workspace
    );

    const workspace = existingWorkspace?.accountID;

    return {
      ...state,
      accounts: data.accounts,
      user: data.user,
      workspace: workspace ? workspace : data.accounts?.[0]?.accountID,
    };
  },
  [AUTH.SET_TOKEN]: (state, { payload: { token } }) => {
    return { ...state, token };
  },
  [AUTH.CLEAR_AUTH_DATA]: (state) => {
    return {
      ...state,
      accounts: null,
      token: null,
      user: null,
    };
  },

  [AUTH.CHANGE_ACCOUNT_DATA]: (state) => ({
    ...state,
  }),
  [AUTH.CHANGE_ACCOUNT_DATA_SUCCESS]: (state, { payload: { response } }) => {
    return { ...state, accounts: [response] };
  },
  [AUTH.CHANGE_ACCOUNT_DATA_FAILURE]: (state) => ({
    ...state,
  }),

  [AUTH.UPDATE_USER]: (state) => ({
    ...state,
    isRequest: true,
  }),
  [AUTH.UPDATE_USER_SUCCESS]: (state, { payload: { response } }) => {
    return { ...state, user: response, isRequest: false };
  },
  [AUTH.UPDATE_USER_FAILURE]: (state) => ({
    ...state,
    isRequest: false,
  }),

  [AUTH.UPDATE_ACCOUNT_DATA_SUCCESS]: (state, { payload: { response } }) => {
    return {
      ...state,
      isRequest: true,
      documents: response.documents,
      templates: response.templates,
    };
  },

  [AUTH.CREATE_DOCUMENT_FUNCTION_SUCCESS]: (
    state,
    { payload: { response } }
  ) => {
    return { ...state, documents: [...state.documents, response.documents] };
  },

  [AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD_SUCCESS]: (
    state,
    {
      payload: {
        response: { documents },
      },
    }
  ) => {
    let newDocuments = state.documents?.filter(
      (item) => item.documentID !== documents.documentID
    );

    newDocuments.push(documents);

    return { ...state, documents: [...newDocuments] };
  },

  [AUTH.DELETE_DOCUMENT_SUCCESS]: (state, { payload: { documentId } }) => {
    let newDocuments = state.documents?.filter(
      (item) => item.documentID !== documentId
    );
    return { ...state, documents: [...newDocuments] };
  },

  [AUTH.GET_SORTED_DOCUMENTS]: (state) => {
    return { ...state, documents: [] };
  },
  [AUTH.GET_SORTED_DOCUMENTS_SUCCESS]: (state, { payload }) => {
    return { ...state, documents: [...payload.response.documents] };
  },
  [AUTH.SET_REDIRECT_PARAMS]: (state, { payload: { params } }) => {
    return { ...state, redirectParams: params };
  },
  [AUTH.SET_ERROR_LOADING_SCREEN_TYPE]: (state, { payload: { type } }) => {
    return { ...state, errorLoadingScreenType: type };
  },
  [AUTH.SET_CURRENT_WORKSPACE]: (state, { payload: { workspace } }) => {
    return { ...state, workspace };
  },
  [AUTH.SET_TEMP_USER]: (state, { payload }) => {
    return { ...state, tempUser: payload };
  },
});

import React, { useState, useEffect, useRef } from "react";

import { Space, Form } from "antd";
import CAutoComplete from "../../../AutoComplete";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GOOGLE_PLACES_API_KEY } from "../../../../constants/config";
import { WarningTooltip } from "../index";
import { Modal } from "antd";
import { ConfirmAddress } from "../../../ConfirmAddress";

export default function LocationFormItem({
  formName,
  focus,
  setFocus,
  type,
  icon,
  defaultValue,
  focusOn,
  onFinish,
  disabled,
  canShare,
  form,
}) {
  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    //TODO ADD DOCUMENT LANGUAGE
    apiKey: GOOGLE_PLACES_API_KEY,
    language: "en-GB",
    options: { language: "en-GB" },
  });
  const [addressBeforeConfirm, setAddressBeforeConfirm] = useState();
  const [showModal, setShowModal] = useState(false);

  const convertAddressOptions = (oldOptions) => {
    let options = [];

    for (let i = 0; i < oldOptions.length; i++) {
      options[i] = {
        value: oldOptions[i].description,
        label: oldOptions[i].description,
        id: oldOptions[i].place_id,
      };
    }

    return options;
  };

  const getPlaceData = async (placeId) => {
    placesService.getDetails(
      { placeId: placeId, fields: ["address_components"] },
      (result) => {
        let tempAddr = {
          streetAddress: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: null,
          additionalAddressLine: null,
        };

        result.address_components.map((elem) => {
          if (
            elem.types.some((elemItem) =>
              ["route", "natural_feature", "establishment"].includes(elemItem)
            )
          ) {
            tempAddr.streetAddress = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "street_number")) {
            tempAddr.streetNumber = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "postal_code")) {
            tempAddr.postalCode = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "locality")) {
            tempAddr.city = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "country")) {
            tempAddr.country = elem.long_name;
          }
        });

        setAddressBeforeConfirm(tempAddr);

        setShowModal(true);
      }
    );
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: "100%", position: "relative" }}
      >
        {icon}

        <WarningTooltip canShare={canShare}>
          <Form.Item
            name={formName}
            initialValue={defaultValue}
            className="field-input"
          >
            <CAutoComplete
              disabled={disabled}
              placeholder={"Add address here..."}
              focusOn={focus === focusOn}
              type={type}
              onClick={() => setFocus(focusOn)}
              onBlur={() => {
                onFinish();
                setFocus(0);
              }}
              options={convertAddressOptions(placePredictions)}
              onSearch={(value) => {
                getPlacePredictions({ input: value });
              }}
              onSelect={(value, id) => {
                getPlaceData(id);
              }}
            />
          </Form.Item>
        </WarningTooltip>
      </Space>

      <Modal
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        destroyOnClose
      >
        <ConfirmAddress
          {...{
            addressBeforeConfirm,
            showModal,
            onFinish,
            setShowModal,
            form,
            formName,
          }}
        />
      </Modal>
    </>
  );
}

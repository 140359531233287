// @flow
/**
 * Client
 * @module Client
 */
import axios from "axios";

import { API_URL } from "../constants/config";
import { setErrorLoadingScreenType, setToken } from "../store/actions/auth";
import { store } from "../store";

export function request(url, options = {}) {
  const config = {
    method: "GET",
    ...options,
  };
  const errors = [];
  const headerParams = {};

  if (!url) errors.push("url");
  if (!config.payload && config.method !== "GET" && config.method !== "DELETE")
    errors.push("payload");
  if (errors.length)
    throw new Error(`Error! You must pass \`${errors.join("`, `")}\``);
  // if (userReducer?.auth_token)
  //   headerParams.Authorization = userReducer?.auth_token;

  const headers = {
    ...headerParams,
    ...config.headers,
  };

  const params = {
    headers,
    method: config.method,
    params: config.params,
    data: config.payload,
    withCredentials: true,
  };

  const makeRequest = async (url, params, retryCount = 0) => {
    try {
      const apiUrl = API_URL || ""; // Handle undefined API_URL
      const response = await axios({
        url: /^(https?:\/\/)/.test(url) ? url : `${apiUrl}${url}`,
        ...params,
      });

      if (response.status >= 400) throw new Error(response.data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retryCount < 2) {
        // Limit retries to 3
        try {
          const token = await store.getState()?.auth0?.getAccessTokenSilently();
          await store.dispatch(setToken(token));

          // Token refreshed successfully, retry the request now with updated headers with new token.
          const updatedParams = {
            ...params,
            headers: { ...params.headers, Authorization: `Bearer ${token}` },
          };
          return await makeRequest(url, updatedParams, retryCount + 1); // Increment the retry count
        } catch (error) {
          console.error(error); // Log the error
          store.dispatch(setErrorLoadingScreenType("error"));
          throw error;
        }
      } else {
        console.error(error); // Log the error
        throw error;
      }
    }
  };

  const axioReturn = makeRequest(url, params);
  return axioReturn;
}

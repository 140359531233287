import { api } from "."

export function sendCommentMailNotification({ 
  documentID, commentInstance, emailTo
}) {
  api.post('/email/comment-notification', {
    documentID: documentID,
    comment: commentInstance,
    emailTo
  }).catch(err => {
    console.log('error while trying to send mail notification', err)
  })
}
import React from "react";
import PropTypes from "prop-types";

const Send = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3628 9.71132L0.690956 0.349717C0.615063 0.31177 0.528009 0.302842 0.44542 0.322931C0.353758 0.345591 0.27481 0.403649 0.225861 0.484393C0.176913 0.565136 0.161954 0.661985 0.184259 0.753734L2.10837 8.61534C2.13738 8.73364 2.22444 8.82963 2.34051 8.86757L5.63738 9.99927L2.34274 11.131C2.22667 11.1711 2.13962 11.2649 2.11283 11.3832L0.184259 19.256C0.16417 19.3386 0.173098 19.4256 0.211045 19.4993C0.298098 19.6756 0.512384 19.747 0.690956 19.66L19.3628 10.3519C19.432 10.3185 19.4878 10.2604 19.5235 10.1935C19.6106 10.0149 19.5392 9.80061 19.3628 9.71132ZM2.38515 17.0194L3.50792 12.4301L10.0972 10.1689C10.1485 10.1511 10.191 10.1109 10.2088 10.0573C10.2401 9.96356 10.191 9.86311 10.0972 9.82963L3.50792 7.5707L2.38962 2.99927L16.4075 10.0283L2.38515 17.0194Z"
        fill="white"
      />
    </svg>
  );
};

Send.propTypes = {
  color: PropTypes.string,
};

export default Send;

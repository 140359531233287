import { $createParagraphNode, $getSelection, $isRangeSelection, DEPRECATED_$isGridSelection } from "lexical";
import { $createHeadingNode } from '@lexical/rich-text'
import {
  $setBlocksType,
} from '@lexical/selection';
import { DropDown, DropDownItem, dropDownActiveClass } from "./Dropdown";
import styles from '../index.module.scss'

export const blockTypeToBlockName = {
  h1: 'Title',
  h2: 'Headline',
  h3: 'Subheadline',
  paragraph: 'Normal text',
  small: 'Small text',
};

export function BlockFormatDropdown({
  editor,
  blockType,
  disabled = false,
}) {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if (
        $isRangeSelection(selection) ||
        DEPRECATED_$isGridSelection(selection)
      ) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };


  // const handleClick = useCallback(
  //   (option) => {
  //     editor.update(() => {
  //       const selection = $getSelection();
  //       if ($isRangeSelection(selection)) {
  //         $patchStyleText(selection, {
  //           [style]: option,
  //         });
  //       }
  //     });
  //   },
  //   [editor, style],
  // );

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={styles["popup-item"]}
      // buttonIconClassName={'icon block-type ' + blockType}
      buttonLabel={blockTypeToBlockName[blockType]}
      buttonAriaLabel="Formatting options for text style">
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(blockType === 'h1')}`}
        onClick={() => formatHeading('h1')}>
        <span className={`${styles["text"]} ${styles['editor-title']}`}>Title</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(blockType === 'h2')}`}
        onClick={() => formatHeading('h2')}>
        <span className={`${styles["text"]} ${styles['editor-headline']}`}>Headline</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(blockType === 'h3')}`}
        onClick={() => formatHeading('h3')}>
        <span className={`${styles["text"]} ${styles['editor-subheadline']}`}>Subheadline</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(blockType === 'paragraph')}`}
        onClick={formatParagraph}>
        <span className={`${styles["text"]} ${styles['editor-paragraph']}`}>Normal text</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(blockType === 'small')}`}
        onClick={() => formatHeading('small')}>
        <span className={`${styles["text"]} ${styles['editor-small']}`}>Small text</span>
      </DropDownItem>
    </DropDown>
  );
}

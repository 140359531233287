import React from "react";
import { Icons } from "../../..";
import { Stepper } from "./Stepper";
import styles from "./index.module.scss";

export const LeftSidebar = (props) => {
  const { open, onClose, prompts, visibleIndex, setVisibleIndex } = props;

  return (
    <aside className={`${styles["c-aside"]} ${open ? styles["active"] : ""}`}>
      <div className={styles["c-aside__head"]}>
        <div className={styles["c-aside__title"]}>Outline</div>
        <div onClick={onClose} className={styles["c-aside__btn"]}>
          <Icons.CloseIcon />
        </div>
      </div>
      {prompts && (
        <Stepper
          prompts={prompts}
          visibleIndex={visibleIndex}
          setVisibleIndex={setVisibleIndex}
        />
      )}
    </aside>
  );
};

import React from "react";
import { Icons, CButton } from "../../../components";
import styles from "../index.module.scss";
import { Badge } from "antd";
import Dragger from "antd/lib/upload/Dragger";

const defaultProps = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file);
    }
    // if (status === "done") {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === "error") {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  }
};

const UploadDocx = (props) => {
  const { onClose, onChange } = props;

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["upload-file"]}`}>
        {/* <div className={`${styles["u-modal__head"]} ${styles["orange"]}`}>
          <Icons.WarningFillIcon />
        </div> */}
        <div className={`${styles["upload-file__title"]} ${styles["align-start"]}`}>
          Import Microsoft Word file
          <Badge className={styles["badge"]} count="Beta"/>
        </div>
        <div className={styles["upload-file__desc"]}>
          Import a .docx file here. Not using Microsoft Word? Tools like Google Docs and Pages can export to this format.  
        </div>
        <div className={styles["upload-file__dragger"]}>
          <Dragger 
            {...defaultProps}
            // onChange={onChange}
          >
            <p className={styles["upload-file__dragger__desc"]}>Drag and drop here</p>
          </Dragger>
        </div>
        <div className={styles["u-modal__foot"]}>
          {/* <div className={styles["u-modal__foot__item"]}> */}
            <CButton
              onClick={onClose}
              type="primary"
              title={"Upload file"}
              customStyles={styles["btn-modal"]}
            />
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default UploadDocx;

import Icons from "../../Icons";
export const TYPE = {
  TEXT: "text",
  DATE: "date",
  NUMBER: "number",
  SELECT: "select",
  CHECKBOX: "checkbox",
  LOCATION: "address",
};

export const predefinedFields = [
  {
    label: "Person",
    position: 2,
    options: [
      {
        type: TYPE.TEXT,
        position: 2,
        key: "Person Name",
        value: "",
      },
      {
        type: TYPE.LOCATION,
        position: 3,
        key: "Person Address",
        value: "",
      },
    ],
  },
  {
    label: "Contract",
    position: 7,
    options: [
      {
        type: TYPE.TEXT,
        position: 18,
        key: "Contract Name",
        value: "",
      },
      {
        type: TYPE.DATE,
        position: 19,
        key: "Agreement Date",
        value: "",
      },
      {
        type: TYPE.DATE,
        position: 20,
        key: "Expiration Date",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 21,
        key: "Term length",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 22,
        key: "Opt Out Length",
        value: "",
      },
      {
        type: TYPE.DATE,
        position: 23,
        key: "Renewal Reminder",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 24,
        key: "Governing Law",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 25,
        key: "Protection Period",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 26,
        key: "Termination Notice Period",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 27,
        key: "Payment Frequency",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 28,
        key: "Payment Term",
        value: "",
      },
      {
        type: TYPE.NUMBER,
        position: 29,
        key: "Total Contract Value",
        icon: <Icons.NumberQuestion size="20" />,
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 30,
        key: "Venue",
        value: "",
      },
      {
        type: TYPE.CHECKBOX,
        position: 31,
        key: "Termination for Cause",
        icon: <Icons.Checkbox size="20" />,
        value: "",
      },
      {
        type: TYPE.CHECKBOX,
        position: 32,
        key: "Termination For Convenience",
        icon: <Icons.Checkbox size="20" />,
        value: "",
      },
      {
        type: TYPE.CHECKBOX,
        position: 33,
        key: "Auto-Renewal",
        icon: <Icons.Checkbox size="20" />,
        value: "",
      },
    ],
  },
  {
    label: "Company",
    position: 3,
    options: [
      {
        type: TYPE.TEXT,
        position: 4,
        key: "Company Name",
        value: "",
      },
      {
        type: TYPE.LOCATION,
        position: 5,
        key: "Company Address",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 6,
        key: "Company Reference",
        value: "",
      },
    ],
  },
  {
    label: "Counterparty",
    position: 4,
    options: [
      {
        type: TYPE.TEXT,
        position: 7,
        key: "Counterparty Name",
        value: "",
      },
      {
        type: TYPE.LOCATION,
        position: 8,
        key: "Counterparty Address",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 9,
        key: "Counterparty Reference",
        value: "",
      },
    ],
  },
  {
    label: "Counterparty Signee",
    position: 5,
    options: [
      {
        type: TYPE.TEXT,
        position: 10,
        key: "Counterparty Signee Name",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 11,
        key: "Counterparty Signee Email",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 12,
        key: "Counterparty Signee Signature",
        value: "",
      },
      {
        type: TYPE.DATE,
        position: 13,
        key: "Counterparty Signee Date Field",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 14,
        key: "Counterparty Signer Title",
        value: "",
      },
    ],
  },
  {
    label: "Company Signee",
    position: 6,
    options: [
      {
        type: TYPE.TEXT,
        position: 15,
        key: "Company Signee Name",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 16,
        key: "Company Signee Email",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 17,
        key: "Company Signee Signature",
        value: "",
      },
      {
        type: TYPE.DATE,
        position: 18,
        key: "Company Signee Date Field",
        value: "",
      },
      {
        type: TYPE.TEXT,
        position: 19,
        key: "Company Signer Title",
        value: "",
      },
    ],
  },
];

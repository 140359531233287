import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  Icons,
  StepTopBar,
} from "../../components";
import { Approvers, ApproversModal } from "./Approvers";
import { Recipients, RecipientModal } from "./Recipients";
import { Signees, SigneesModal } from "./Signees";
import { Message } from "./Message";
import inputStyles from "../../components/Input/index.module.scss";

import {
  setIndex,
  setNewDocumentData,
  setSigneeData,
  updateDocumentData,
  setAvailableHeaderStep,
  signDocument,
  getDocument,
} from "../../store/actions/prompts";

import {
  documentSelector,
  isCreatingDocumentSelector,
  newDocumentdataSelector,
  newSigneeDataSelector,
} from "../../store/selectors/prompts";
import { userSelector } from "../../store/selectors/auth";

import * as keys from "../../utils/keys";

import wizardStyles from "../../styles/Wizard.module.scss";
import styles from "./index.module.scss";
import SigneeItem from "./SigneeItem";
import { Input } from "antd";
import { api } from "../../services/api";
import { DOCUMENT_CURRENT_STEP } from "../../utils/documentCurrentStep";

const SignSend = (props) => {
  const {
    currentDocument,
    newDocumentData,
    setNewDocumentData,
    setSigneeData,
    newSigneeData,
    currentUser,
    updateDocumentData,
    setAvailableHeaderStep,
    signDocument,
    isCreatingDocument,
    isTemplateGenerator,
    generatorDoucument,
    goToModal,
    showModal,
    PreviewComponent,
    addSignees,
    editSignees,
    deleteSignees,
    saveEdit,
    handlePublishTemplate,
    addApprovers,
    editApprovers,
    deleteApprovers,
    addRecipients,
    deleteRecipients,
    editRecipients,
  } = props;
  const dispatch = useDispatch();
  const thisDocument = generatorDoucument ?? currentDocument;

  let navigate = useNavigate();
  const { t } = useTranslation();

  const [openRightPanel, setOpenPanelVisible] = useState(false); //a variable that indicates whether the help bar is open
  const [RightPanelComponent, setRightPanelComponent] = useState(null); //a variable that contains the element that is in the right pane

  const [documentStyles, setDocumentStyles] = useState(null);
  const [documentTitle, setDocumentTitle] = useState(
    () => thisDocument?.title || thisDocument?.fileTitle || ""
  );
  const [oldData, setOldData] = useState();

  const isMyoTemplate = thisDocument?.documentCategory?.toLowerCase() === "myo";

  useEffect(() => {
    setAvailableHeaderStep(2);
  }, []);

  useEffect(() => {
    if (!isCreatingDocument && !generatorDoucument) {
      navigate(keys.DASHBOARD);
    }
  }, [isCreatingDocument]);

  useEffect(() => {
    if (thisDocument) {
      if (
        thisDocument?.type === "GENERATED" &&
        thisDocument?.signees?.length > 0
      ) {
        const filteredSignees = (thisDocument?.signees ?? [])?.filter(
          (signee) =>
            !newSigneeData?.some(
              (existingSignee) => existingSignee.signeeID === signee.signeeID
            )
        );

        const updatedSigneeData = newSigneeData
          ? [...newSigneeData, ...filteredSignees]
          : [...filteredSignees];

        setSigneeData(updatedSigneeData);
      }
      if (thisDocument?.style?.isApplyToWizard) {
        setDocumentStyles(thisDocument?.style);
      } else {
        setDocumentStyles(null);
      }
    }
  }, [newSigneeData, setSigneeData, thisDocument]);

  useEffect(() => {
    if (thisDocument && !newDocumentData) {
      setNewDocumentData({
        fileTitle: thisDocument?.fileTitle,
        messages: thisDocument?.messages,
      });
    }
  }, [newDocumentData, thisDocument]);

  useEffect(() => {
    if (thisDocument && !newSigneeData && currentUser) {
      const documentOwner = thisDocument?.users?.find(
        (user) => user?.permission === "owner"
      );

      const contractUser = thisDocument?.users?.find(
        (user) => user?.userID === currentUser?.userID
      );

      if (contractUser?.permission === "owner") {
        setSigneeData([
          {
            contact: {
              email: currentUser?.email,
              firstName: isMyoTemplate ? "Jasper" : currentUser?.firstName,
              lastName: isMyoTemplate ? "Böckel" : currentUser?.lastName,
              initials: isMyoTemplate
                ? "JB"
                : `${currentUser?.firstName?.[0]?.toUpperCase()}${currentUser?.lastName?.[0]?.toUpperCase()}`,
            },
            isSender: true,
            partyID: thisDocument?.parties?.[0]?.partyID,
            order: null,
            showOrder: false,
          },
        ]);
      } else {
        setSigneeData([
          {
            contact: {
              email: documentOwner?.email,
              firstName: isMyoTemplate ? "Jasper" : documentOwner?.firstName,
              lastName: isMyoTemplate ? "Böckel" : documentOwner?.lastName,
              initials: isMyoTemplate
                ? "JB"
                : `${documentOwner?.firstName?.[0]?.toUpperCase()}${documentOwner?.lastName?.[0]?.toUpperCase()}`,
            },
            isSender: true,
            partyID: thisDocument?.parties?.[0]?.partyID,
            order: null,
            showOrder: false,
          },
        ]);
      }
    }
  }, [thisDocument, newSigneeData, currentUser, isMyoTemplate]);

  useEffect(() => {
    //delete number, if it`s larger then signees length
    if (
      newSigneeData &&
      newSigneeData?.some(
        (signeeItem) =>
          signeeItem?.order > 2 && signeeItem?.order > newSigneeData.length
      )
    ) {
      setSigneeData([
        ...newSigneeData.filter(
          (signeeItem) => signeeItem?.order <= newSigneeData.length
        ),
        ...[
          {
            ...newSigneeData.filter(
              (signeeItem) => signeeItem?.order > newSigneeData.length
            )[0],
            order: null,
            showOrder: false,
          },
        ],
      ]);
    }

    if (
      newSigneeData &&
      newSigneeData?.some((signeeItem) => signeeItem?.showOrder) &&
      newSigneeData?.some((signeeItem) => !signeeItem?.order)
    ) {
      const usedNumbers = newSigneeData
        .map((signeeItem) => signeeItem?.order)
        .filter((arrItem) => arrItem);

      let availableNumbers = newSigneeData
        .map((signeeItem, index) => index + 1)
        .filter(
          (arrItem) => !usedNumbers.some((numberItem) => numberItem === arrItem)
        );

      let result = [];

      for (let i = 0; i < newSigneeData.length; i++) {
        const tempObject = {
          ...newSigneeData[i],
        };

        if (!tempObject.showOrder && !tempObject?.order) {
          tempObject.order = availableNumbers[0];

          availableNumbers = availableNumbers.filter(
            (numberItem, index) => index !== 0
          );

          result.push(tempObject);
        } else {
          result.push(tempObject);
        }
      }

      setSigneeData(result);
    }
  }, [newSigneeData]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp font={documentStyles?.font} />);
    setOpenPanelVisible(true);
  };

  const setSendBtnTitle = () => {
    if (isTemplateGenerator) return "Publish";
    const currentUserElement = newSigneeData?.filter(
      (signeeItem) => signeeItem?.contact?.email === currentUser?.email
    )[0];

    if (
      newSigneeData?.some((signeeItem) => signeeItem?.showOrder) &&
      currentUserElement?.order !== 1
    ) {
      return t("sign_send:send");
    }

    if (
      newSigneeData?.some(
        (signeeItem) => signeeItem?.contact?.email === currentUser?.email
      )
    ) {
      return t("sign_send:send_and_sign");
    }

    return t("sign_send:send");
  };

  const isNextStepBtnEnable = () => {
    let partiesWithUsers = 0;

    if (thisDocument?.parties?.length) {
      for (let i = 0; i < thisDocument.parties?.length; i++) {
        if (
          newSigneeData?.some(
            (signeeItem) =>
              signeeItem.partyID === thisDocument.parties[i]?.partyID
          )
        ) {
          partiesWithUsers++;
        }
      }

      if (partiesWithUsers === thisDocument.parties?.length) {
        return true;
      }
    }

    return false;
  };

  const formatSigneeData = () => {
    if (!newSigneeData) return [];

    let result = [];

    const isSomeWithNumbers = newSigneeData.some(
      (signeeItem) => signeeItem?.order && signeeItem.showOrder
    );

    for (let i = 0; i < newSigneeData.length; i++) {
      const tempObject = {
        contact: {
          ...newSigneeData[i].contact,
        },
        isSender: newSigneeData[i].isSender,
        partyID: newSigneeData[i].partyID,
      };

      if (isSomeWithNumbers) {
        tempObject.order = newSigneeData[i]?.order;

        result.push(tempObject);
      } else {
        result.push(tempObject);
      }
    }

    return result;
  };

  const formSignatures = (partyItem) => {
    return newSigneeData
      ?.filter(
        (signeeItem) =>
          signeeItem.partyID === partyItem.partyID &&
          !!signeeItem?.contact?.email
      )
      ?.map((signeeItem, index) => {
        const localSigneeData = { ...signeeItem };

        if (
          thisDocument?.signees?.some(
            (someItem) =>
              someItem?.contact?.email === signeeItem?.contact?.email
          )
        ) {
          const dataBaseValues = thisDocument?.signees?.filter(
            (filterItem) =>
              filterItem?.contact?.email === signeeItem?.contact?.email
          )[0];
          localSigneeData.contact = dataBaseValues.contact;
        }

        return (
          <SigneeItem
            key={`signee-item-${index}`}
            documentStyles={documentStyles}
            initials={
              localSigneeData?.contact?.initials
                ? localSigneeData?.contact?.initials
                : `${localSigneeData?.contact?.firstName?.[0]?.toUpperCase()}${localSigneeData?.contact?.lastName?.[0]?.toUpperCase()}`
            }
            firstName={localSigneeData?.contact?.firstName}
            lastName={localSigneeData?.contact?.lastName}
            email={localSigneeData?.contact?.email}
            image={localSigneeData?.contact?.image}
            number={localSigneeData?.showOrder ? localSigneeData?.order : null}
            hideSigneeBtn={
              localSigneeData?.contact?.firstName === "Jasper" &&
              localSigneeData?.contact?.lastName === "Böckel" &&
              isMyoTemplate
            }
            onDeletePress={() => {
              let newData = [...newSigneeData];

              newData = newData.filter(
                (dataItem) =>
                  dataItem?.contact?.email !== localSigneeData?.contact?.email
              );

              setSigneeData(newData);
            }}
            onEditClick={() => {
              navigate(keys.SIGNEE, {
                state: {
                  isEditing: true,
                  oldData: localSigneeData,
                  partyId: partyItem.partyID,
                },
              });
            }}
          />
        );
      });
  };

  const onFinish = () => {
    if (isTemplateGenerator) {
      return handlePublishTemplate();
    }
    const resultObject = {
      ...newDocumentData,
      emailData: { ...newDocumentData?.emailData },
      signees: formatSigneeData(),
    };

    if (setSendBtnTitle() === "Send") {
      resultObject.emailData.sendInitialEmail = true;
    }

    updateDocumentData(
      thisDocument.documentID,
      resultObject,
      false,
      (result) => {
        if (setSendBtnTitle() === "Send") {
          navigate(keys.DOCUMENT_OVERVIEW);
        } else {
          navigate(keys.SIGN);
        }
      }
    );
  };

  function renderModal(showModal) {
    switch (showModal) {
      case "approvers":
        return (
          <ApproversModal
            {...{
              documentStyles,
              t,
              addApprovers,
              goToModal,
              oldData,
              editApprovers,
            }}
          />
        );
      case "signees":
        return (
          <SigneesModal
            {...{
              documentStyles,
              addSignees,
              t,
              goToModal,
              oldData,
              editSignees,
            }}
          />
        );
      case "recipients":
        return (
          <RecipientModal
            {...{
              documentStyles,
              t,
              addRecipients,
              goToModal,
              oldData,
              editRecipients,
            }}
          />
        );
      case "preview":
        return PreviewComponent;
      default:
        return <></>;
    }
  }

  useEffect(() => {
    if (!thisDocument?.documentID) return;

    api.patch(`document/${thisDocument.documentID}`, {
      currentStep: DOCUMENT_CURRENT_STEP.SIGN,
    });
  }, [thisDocument?.documentID]);

  return (
    <Layout
      header={
        !isTemplateGenerator && (
          <Header
            leftBlock={
              <div className={wizardStyles["u-nav-bar-back"]}>
                <div
                  className={wizardStyles["u-nav-bar-back__btn"]}
                  onClick={() => {
                    navigate(keys.DASHBOARD);
                  }}
                >
                  <Icons.ArrowLeft />
                </div>
                <div className={wizardStyles["u-nav-bar-back__detail"]}>
                  <div
                    className={wizardStyles["u-nav-bar-back__name"]}
                    style={{ fontFamily: documentStyles?.font }}
                  >
                    {thisDocument?.fileTitle}
                  </div>
                </div>
              </div>
            }
            stepTopBar={
              <StepTopBar
                currentId={3}
                color={documentStyles?.color}
                font={documentStyles?.font}
              />
            }
          />
        )
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      questionIconColor={documentStyles?.color}
    >
      <div
        className={wizardStyles["c-wizard"]}
        style={{ padding: isTemplateGenerator ? 0 : "" }}
      >
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
          closeIconColor={documentStyles?.color}
        />

        <div
          className={styles["c-signsend"]}
          style={{ padding: isTemplateGenerator ? 0 : "" }}
        >
          <div
            className={
              showModal === "preview" ? "" : styles["c-signsend__article"]
            }
          >
            {showModal ? (
              renderModal(showModal)
            ) : (
              <>
                <div
                  className={styles["c-signsend__head"]}
                  style={{
                    fontFamily: documentStyles?.font,
                    paddingBottom: "32px",
                  }}
                >
                  <h1>{t("sign_send:add_signees")}</h1>

                  <p>{t("sign_send:we_will_email_signees")}</p>
                </div>

                <div className={styles["c-signsend-status__item"]}>
                  <div className={styles["c-signsend-status__article"]}>
                    <div
                      className={styles["teritary__head"]}
                      style={{
                        fontFamily: documentStyles?.font,
                        borderBottom: `1px solid ${
                          documentStyles?.color
                            ? `${documentStyles.color}33`
                            : "#ecedff"
                        }`,
                      }}
                    >
                      <div className={styles["teritary__head__title"]}>
                        {t("sign_send:document")}
                      </div>

                      <div
                        className={styles["u-preview"]}
                        onClick={() => {
                          if (isTemplateGenerator) {
                            goToModal("preview");
                          } else {
                            navigate(keys.REVIEW_INPUT, {
                              state: {
                                isPreviw: true,
                              },
                            });
                          }
                        }}
                      >
                        <Icons.EyeIcon color={documentStyles?.color} />
                        <span style={{ color: documentStyles?.color }}>
                          {t("sign_send:show_contract")}
                        </span>
                      </div>
                    </div>

                    <div className={styles["teritary__body"]}>
                      <div className={inputStyles["u-style-article"]}>
                        <label
                          htmlFor="fileTitle"
                          className={inputStyles["u-style-label"]}
                          style={{ fontFamily: documentStyles?.font }}
                        >
                          {t("sign_send:title")}
                        </label>
                        <Input
                          type="text"
                          className={inputStyles["u-style-input"]}
                          name="fileTitle"
                          style={{
                            fontFamily: documentStyles?.font,
                          }}
                          onChange={(event) => {
                            setDocumentTitle(event.currentTarget.value);
                          }}
                          value={documentTitle}
                          onBlur={async () => {
                            if (
                              [
                                thisDocument?.fileTitle,
                                thisDocument?.title,
                              ].some((title) => title === documentTitle)
                            )
                              return;

                            const updatedFields = {
                              title: documentTitle,
                              fileTitle: documentTitle,
                            };

                            if (isTemplateGenerator) {
                              saveEdit(updatedFields);
                            } else {
                              try {
                                await api.patch(
                                  `document/${thisDocument?.documentID}`,
                                  updatedFields
                                );
                              } finally {
                                dispatch(getDocument(thisDocument.documentID));
                              }
                            }

                            setNewDocumentData({
                              ...newDocumentData,
                              ...updatedFields,
                            });
                          }}
                          onPressEnter={(e) => {
                            e.target.blur();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Signees
                  {...{
                    documentStyles,
                    thisDocument,
                    isTemplateGenerator,
                    formSignatures,
                    navigate,
                    deleteSignees,
                    t,
                    goToModal,
                    setOldData,
                    saveEdit,
                    setNewDocumentData,
                  }}
                />

                <Message
                  {...{
                    newDocumentData,
                    setNewDocumentData,
                    documentStyles,
                    thisDocument,
                    t,
                    isTemplateGenerator,
                    saveEdit,
                  }}
                />

                {isTemplateGenerator && (
                  <>
                    <Approvers
                      {...{
                        documentStyles,
                        t,
                        goToModal,
                        thisDocument,
                        deleteApprovers,
                        setOldData,
                      }}
                    />

                    <Recipients
                      {...{
                        documentStyles,
                        t,
                        goToModal,
                        thisDocument,
                        deleteRecipients,
                        setOldData,
                      }}
                    />
                  </>
                )}

                <div className={styles["c-signsend__buttons"]}>
                  <CButton
                    title={
                      isTemplateGenerator ? "Save draft" : t("sign:cancel")
                    }
                    type="reverse-primary"
                    customStyles={styles["c-signsend__buttons__with-text"]}
                    font={documentStyles?.font}
                    color={documentStyles?.color}
                    onClick={() =>
                      isTemplateGenerator
                        ? navigate(keys.TEMPLATES)
                        : navigate(keys.REVIEW_INPUT)
                    }
                  />
                  <div className={styles["c-signsend__buttons__with-text"]}>
                    <CButton
                      title={setSendBtnTitle()}
                      type="primary"
                      font={documentStyles?.font}
                      backgroundColor={documentStyles?.color}
                      disabled={!isTemplateGenerator && !isNextStepBtnEnable()}
                      onClick={onFinish}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  currentDocument: documentSelector(state),
  newDocumentData: newDocumentdataSelector(state),
  newSigneeData: newSigneeDataSelector(state),
  currentUser: userSelector(state),
  isCreatingDocument: isCreatingDocumentSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIndex,
      setNewDocumentData,
      setSigneeData,
      updateDocumentData,
      setAvailableHeaderStep,
      signDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignSend);

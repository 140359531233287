import React, { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { Button, Space, Form, Dropdown, Input, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import Icons from "../../../components/Icons";
import { TYPE, predefinedFields } from "../ContractRepository/data-fields";
import { useDoubleTap } from "use-double-tap";

import { default as DataFieldFormItem } from "../ContractRepository/components";
import { SelectDataField } from "../ContractRepository/components/SelectDataField";
import "../ContractRepository/index.scss";
import moment from "moment";
import templateStyles from "../../../pages/Template/index.module.scss";
import { items, testitems } from "./constants/options";

const { Title } = Typography;

export const TemplateDataFieldContent = ({
  template,
  onClose,
  currentShareUser = true,
  onCreateVariable,
  onDeleteVariable,
  onUpdateVariable,
  onUpdatePrompt,
  readOnly,
  isCreator = true,
  showCloseButton,
  currentPrompt,
  variablesCategories,
  currentVariable,
  goToCard,
  prompts,
  form,
  onCardClick,
  reviewStep,
  step,
}) => {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [nowEditing, setNowEditing] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [focus, setFocus] = useState("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const formValues = useMemo(() => {
    const fieldsValue = form.getFieldsValue();

    return Object.entries(fieldsValue)
      .filter(([key, value]) => Boolean(value))
      .map(([key]) => key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, template]);

  const variables = useMemo(
    () => template.variables ?? [],
    [template.variables]
  );

  function handleCreateVariable(arr, newVar) {
    onCreateVariable({
      ...newVar,
      position: newVar.position ?? 0,
      options: {
        ...newVar.options,
      },
    });
  }

  function handleActionsClick(e, variableID) {
    if (e.key === "remove" && variableID) {
      onDeleteVariable(variableID, true);
    }
  }

  function handleChangeType(option, variableKey) {
    const variable = variables.find((v) => v.key === variableKey);
    onUpdateVariable({
      ...variable,
      options: {
        ...variable?.options,
      },
      type: option.key,
    });
    form.setFieldValue(variableKey, "");

    setSelectedDropdownValue(null);
  }

  function handleCloseDate(newVar, key) {
    const variable = variables?.find((v) => v.key === key);

    if (!variable) return;

    if (variable.type !== TYPE.DATE) return;

    const newOptions = newVar.options;
    const oldOptions = variable.options;

    if (
      moment(variable.value).isSame(newVar.value) &&
      newOptions?.reminder === oldOptions?.reminder &&
      newOptions?.daysToExpire === oldOptions?.daysToExpire &&
      newOptions?.reminderDate === oldOptions?.reminderDate
    )
      return;

    onUpdateVariable({
      ...variable,
      ...newVar,
      options: {
        ...oldOptions,
        ...newOptions,
      },
    });
  }

  function handleChangeSelect(newVar, key) {
    const variable = variables?.find((v) => v.key === key);

    if (!variable) return;

    if (variable.type !== TYPE.SELECT) return;

    const newOptions = newVar.options;
    const oldOptions = variable.options;

    if (
      newVar.value === variable.value &&
      newOptions?.value?.length === oldOptions?.value?.length
    )
      return;

    onUpdateVariable({
      ...variable,
      ...newVar,
      options: {
        ...oldOptions,
        ...newOptions,
      },
    });

    if (
      currentPrompt &&
      onUpdatePrompt &&
      Array.isArray(newVar?.options?.value)
    ) {
      onUpdatePrompt({
        ...currentPrompt,
        answerOptions: newVar.options.value,
      });
    }
  }

  function handleChangeCheckbox(oldValue, key) {
    const newValue = form.getFieldValue(key) ?? "";

    if (oldValue === newValue) return;

    const variable = variables?.find((v) => v.key === key);

    if (!variable) return;

    if (variable.type !== TYPE.CHECKBOX) return;

    onUpdateVariable({
      ...variable,
      options: {
        ...variable.options,
      },
      value: newValue,
    });
  }

  function handleBlurTextInput(oldValue, key) {
    const newValue = form.getFieldValue(key) ?? "";

    if (oldValue === newValue) return;

    const variable = variables?.find((v) => v.key === key);

    if (!variable) return;

    if (![TYPE.NUMBER, TYPE.TEXT, TYPE.LOCATION].includes(variable.type))
      return;

    onUpdateVariable({
      ...variable,
      options: {
        ...variable?.options,
      },
      value: newValue,
    });
  }

  function handleEditKey(oldValue, value) {
    if (!value.trim().length || oldValue === value) {
      setNowEditing(null);
      setEditValue("");
      return;
    }

    const variable = variables?.find((item) => item.key === oldValue);

    if (!variable) return;

    const matchPredefined = predefinedFields
      .flatMap(({ options }) => options)
      .some(({ key }) => key === value);

    // todo: altering key breaks prompt, because shortPromptName and inputFieldLabel are not updated with the key

    onUpdateVariable({
      ...variable,
      options: {
        ...variable?.options,
        custom: !matchPredefined,
      },
      key: value,
    });
    setNowEditing(null);
    setEditValue("");
  }

  const iconDoubleClick = useDoubleTap((key) => {
    setSelectedDropdownValue(key);
  });

  const bindDoubleClick = useDoubleTap((e) => {
    const keyField = e?.target?.outerText;
    if (keyField?.length) setNowEditing(keyField);
  }, 300);

  /* useEffect(() => {
    if (isDropdownOpen) {
      console.log(isDropdownOpen);
      if (document?.getElementById("location")) {
        document.getElementById("location").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [isDropdownOpen]); */

  return (
    <div className={`c-review-upload ${templateStyles["template-sidebar"]}`}>
      <div
        className="data-field-title-container"
        style={{
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          boxShadow: "0px -1px 0px 0px #ECEDFF inset",
        }}
      >
        <Title className="data-field-title" level={5}>
          Data Fields
        </Title>
      </div>
      <div className="c-review-upload__scrollable">
        {!readOnly && isCreator && (
          <SelectDataField
            currentShareUser={true}
            variables={variables}
            setVariables={handleCreateVariable}
            selectProps={{
              getPopupContainer: (trigger) => trigger.parentElement,
            }}
          />
        )}
        <Form form={form}>
          <Space direction="vertical" size={0} style={{ width: "100%" }}>
            {reviewStep && (
              <div>
                <Title
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 8,
                    marginTop: 4,
                  }}
                  level={5}
                >
                  General
                </Title>
                <div
                  className={
                    currentPrompt?.shortPromptName === "Intro"
                      ? "templateActive"
                      : "templateInactive"
                  }
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    padding:
                      currentPrompt?.shortPromptName === "Intro"
                        ? "8px 6px"
                        : "8px 0px",
                  }}
                  onClick={() => {
                    if (goToCard) {
                      goToCard(1);
                    }
                  }}
                >
                  <Icons.ShortAnswerQuestion size={22} color={"#5F6CFF"} />
                  Welcome
                </div>
              </div>
            )}
            {variablesCategories?.map(({ variables, label }) => (
              <React.Fragment key={label}>
                <Title
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 8,
                    marginTop: 4,
                  }}
                  level={5}
                >
                  {label}
                </Title>

                {variables?.map((dataField) => {
                  let DataFieldItem;
                  let Icon;

                  const filteredItems = dataField?.options?.custom
                    ? items
                    : items.filter((item) => item.key === "remove");

                  switch (dataField.type) {
                    case TYPE.TEXT:
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      Icon = Icons.TextTag;
                      break;
                    case TYPE.DATE:
                      DataFieldItem = DataFieldFormItem.DateFormItem;
                      Icon = Icons.Calendar;
                      break;
                    case TYPE.NUMBER:
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      Icon = Icons.NumberQuestion;
                      break;
                    case TYPE.SELECT:
                      DataFieldItem = DataFieldFormItem.SelectFormItem;
                      Icon = Icons.RadioFilled;
                      break;
                    case TYPE.CHECKBOX:
                      DataFieldItem = DataFieldFormItem.CheckboxFormItem;
                      Icon = Icons.Checkbox;
                      break;
                    case TYPE.LOCATION:
                      DataFieldItem = DataFieldFormItem.LocationFormItem;
                      Icon = Icons.AdressQuestion;
                      break;
                    default:
                      // eslint-disable-next-line no-unused-vars
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      // eslint-disable-next-line no-unused-vars
                      Icon = Icons.TextTag;
                  }

                  const keyField = dataField.key;
                  const isActive = currentPrompt?.shortPromptName === keyField;
                  const isVariableActive = currentVariable?.key === keyField;

                  const isActiveConditionBasedOnCurrentStep = !!reviewStep
                    ? isActive
                    : isActive || isVariableActive;

                  const selectedPrompt = prompts?.find(
                    (prompt) => prompt?.shortPromptName === dataField?.key
                  );
                  const existInForm = formValues.includes(keyField);
                  const isPromptEditDisabled = (() => {
                    if (["checkbox", "select"].includes(dataField.type))
                      return false;

                    return existInForm || !!selectedPrompt?.defaultAnswer;
                  })();
                  const isMissingOptions =
                    step === 2 &&
                    dataField.type === TYPE.SELECT &&
                    !dataField?.options?.value?.length;

                  return (
                    <div key={keyField}>
                      <div
                        className={
                          isActiveConditionBasedOnCurrentStep
                            ? "templateActive"
                            : isMissingOptions
                            ? "missingOptions"
                            : "templateInactive"
                        }
                        style={{
                          cursor: "pointer",
                          background: isMissingOptions ? "#FCECE7" : undefined,
                        }}
                        onClick={(e) => {
                          if (
                            !isPromptEditDisabled &&
                            selectedPrompt &&
                            goToCard
                          ) {
                            goToCard(selectedPrompt?.position);
                          }

                          onCardClick?.(e, dataField);
                        }}
                      >
                        <DataFieldItem
                          key={keyField}
                          allowClear={false}
                          formName={keyField}
                          focus={focus}
                          noReminder={true}
                          focusOn={dataField.key}
                          setFocus={setFocus}
                          type={dataField.type}
                          defaultValue={dataField.value}
                          variables={variables}
                          canShare
                          arrayValues={dataField?.options?.value}
                          currentVariable={dataField}
                          disabled={!currentShareUser}
                          currentShareUser={currentShareUser}
                          form={form}
                          onSelectChange={(newValues) =>
                            handleChangeSelect(newValues, dataField.key)
                          }
                          onDatePickerClose={(newVar) =>
                            handleCloseDate(newVar, dataField.key)
                          }
                          onCheckboxChange={() =>
                            handleChangeCheckbox(dataField.value, dataField.key)
                          }
                          onFinish={() =>
                            handleBlurTextInput(dataField.value, dataField.key)
                          }
                          icon={
                            <Dropdown
                              open={selectedDropdownValue === dataField?.key}
                              key={keyField}
                              placement="bottom"
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                              menu={{
                                items: testitems,
                                onClick: (item) => {
                                  handleChangeType(item, keyField);
                                },
                                rootClassName: "dropdown-root-hover",
                              }}
                              trigger={["click"]}
                              autoFocus
                              autoAdjustOverflow={false}
                              overlayClassName="general-data-field-dropdown"
                              onOpenChange={(visible) => {
                                if (!visible) {
                                  setSelectedDropdownValue(null);
                                }
                                setDropdownOpen(visible);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    gap: 8,
                                  }}
                                >
                                  <button
                                    onClick={() =>
                                      iconDoubleClick.onClick(
                                        dataField?.options?.custom
                                          ? dataField.key
                                          : null
                                      )
                                    }
                                    style={{
                                      width: 20,
                                      display: "flex",
                                      alignItems: "center",
                                      background: "transparent",
                                      padding: 0,
                                      border: 0,
                                      cursor: dataField.options?.custom
                                        ? "pointer"
                                        : "default",
                                    }}
                                  >
                                    <Icon size={20} />
                                  </button>
                                  {nowEditing === keyField ? (
                                    <Input
                                      onChange={(e) =>
                                        setEditValue(e.target.value)
                                      }
                                      className="editing-input"
                                      autoFocus
                                      defaultValue={dataField.key}
                                      style={{
                                        width: "100%",
                                        height: "28px",
                                      }}
                                      onBlur={() =>
                                        handleEditKey(dataField.key, editValue)
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.currentTarget.blur();
                                        }
                                      }}
                                    />
                                  ) : (
                                    <span
                                      {...(!dataField?.options?.default &&
                                        bindDoubleClick)}
                                      style={{ width: "100%" }}
                                    >
                                      {dataField.key}
                                    </span>
                                  )}
                                </div>
                                {!dataField.options?.default &&
                                  currentShareUser &&
                                  !readOnly &&
                                  isCreator && (
                                    <Dropdown
                                      getPopupContainer={(trigger) =>
                                        trigger.parentElement
                                      }
                                      menu={{
                                        items: filteredItems,
                                        onClick: (item) => {
                                          handleActionsClick(
                                            item,
                                            dataField.variableID
                                          );
                                          setSelectedDropdownValue(
                                            dataField?.options?.custom &&
                                              item.key !== "remove"
                                              ? dataField.key
                                              : null
                                          );
                                        },
                                      }}
                                      placement="bottomRight"
                                      trigger={["click"]}
                                    >
                                      <Button
                                        type="text"
                                        className="data-icon-button"
                                        icon={<MoreOutlined />}
                                      />
                                    </Dropdown>
                                  )}
                                {isPromptEditDisabled && readOnly && (
                                  <Icons.EyeInvisible
                                    size={22}
                                    color="#BBBBBE"
                                  />
                                )}
                              </div>
                            </Dropdown>
                          }
                        />
                        {isMissingOptions && (
                          <p
                            style={{
                              color: "#E53935",
                              fontFamily: "Basier Circle",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "17px",
                              marginTop: -24,
                              padding: "4px 0",
                            }}
                          >
                            Oops! You must add options here to continue.
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </Space>
        </Form>
      </div>
    </div>
  );
};

import React, { useMemo } from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import { default as ToggleSuggestion } from "../SuggestionsAndComments/components/ToggleSuggestionsButton";

import { SharePdfPopover } from "../Share";
import { VisitCounter } from "../VisitCounter";
import { Approval } from "../Approval";

import { CButton, Icons, StepTopBar } from "../index";
import wizardStyles from "../../styles/Wizard.module.scss";
import {
  documentSelector,
  newDocumentdataSelector,
} from "../../store/selectors/prompts";
import {
  userSelector,
  userDocumentPermissionSelector,
} from "../../store/selectors/auth";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import * as keys from "../../utils/keys";
import { Button, Segmented } from "antd";
import template from "../StepTopBar/template";
import { useDocumentPermission } from "../../hooks/permissions/document-permissions";
import useMediaQuery from "../../hooks/helpers/useMediaQuery";

const ReviewHeader = ({
  isPreview,
  isSign,
  onCommentsBlockToggle,
  documentStyles,
  createPDF,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  signDocument,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
  canShare,
  currentShareUser,
  downloadingpdf,
  isContractNotUploaded,
  isCommentsBlockOpen,
  showDrawer,
  approverId,
  doGetDocument,
  doSetRedirectParams,
  onSwitchChange,
  switchValue,
  doSetIsCreatingDocument,
}) => {
  const thisDocument = useSelector(documentSelector);
  const newDocumentData = useSelector(newDocumentdataSelector);
  const user = useSelector(userSelector);
  const userDocumentPermission = useSelector(userDocumentPermissionSelector);
  const { isOwner, user: permissionUser } = useDocumentPermission();

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const shouldShowVisitCounter = useMemo(
    () =>
      userDocumentPermission === "owner" && thisDocument?.status === "AWAITING",
    [userDocumentPermission, thisDocument?.status]
  );

  const goBack = () => {
    if (location.state?.cameFromSearch) {
      navigate(keys.SEARCH);
      return;
    }
    doSetRedirectParams(null);
    navigate(keys.DASHBOARD);
  };

  const shouldShowCommentEditSegmented = useMemo(() => {
    const generatedDocument = thisDocument?.type === "GENERATED";
    const global = thisDocument?.origin?.global;
    return isOwner && thisDocument?.status === "DRAFT" && generatedDocument && !global;
  }, [thisDocument, isOwner]);

  const shouldShowComments = useMemo(() => {
    const isSignee = permissionUser?.permission === "signee";
    const isCommenter = permissionUser?.permission === "comment";
    const isApprover = !!permissionUser?.approverID;
    return isSignee || isCommenter || isOwner || isApprover;
  }, [permissionUser, isOwner]);

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const ToggleSuggestionsButton = () => {
    return (
      <>
        <div className={styles.suggestionsToggleButton}>
          <ToggleSuggestion
            onToggle={onCommentsBlockToggle}
            currentShareUser
            isContractNotUploaded={isContractNotUploaded}
            isCommentsBlockOpen={isCommentsBlockOpen}
          />
        </div>

        <div className={styles["c-review__sign-header-menu"]}>
          <Button
            type="link"
            onClick={showDrawer}
            icon={<Icons.MobileMenu color="#5F6CFF" />}
          />
        </div>
      </>
    );
  };

  if (isPreview)
    return (
      <div style={{ position: "fixed", width: "100%", top: 0, zIndex: "1000" }}>
        <Header
          title={
            location.state?.justSigned
              ? thisDocument?.fileTitle
              : newDocumentData?.fileTitle || thisDocument?.fileTitle
          }
          shortTitle={newDocumentData?.fileTitle || thisDocument?.fileTitle}
          leftType={user ? "arrowLeftFolders" : ""}
          right={
            <div className={styles["document-nav-bar"]}>
              {shouldShowVisitCounter && (
                <VisitCounter visitCount={thisDocument?.signeeVisitCount} />
              )}
              {shouldShowComments && isContractNotUploaded && (
                <ToggleSuggestionsButton />
              )}
              {!isContractNotUploaded && <ToggleSuggestionsButton />}
              <SharePdfPopover
                documentStyles={documentStyles}
                createPDF={createPDF}
                thisDocument={thisDocument}
                shareUser={shareUser}
                editSharedUser={editSharedUser}
                deleteSharedUser={deleteSharedUser}
                user={user}
                shareOptions={shareOptions}
                invitedUsers={invitedUsers}
                setInvitedUsers={setInvitedUsers}
                canShare={canShare}
                currentShareUser={currentShareUser}
                downloadingpdf={downloadingpdf}
              >
                <CButton
                  type="reverse-primary"
                  title={t("share:share")}
                  customStyles={styles["c-review__sign-header-right-side-btn"]}
                  backgroundColor={documentStyles?.color}
                  font={documentStyles?.font}
                />
              </SharePdfPopover>

              {approverId && (
                <Approval
                  thisDocument={thisDocument}
                  approverId={approverId}
                  getDocument={doGetDocument}
                />
              )}
            </div>
          }
          onLeftSideClick={goBack}
          documentStyles={documentStyles}
        />
      </div>
    );

  return (
    <Header
      leftBlock={
        isSign ? (
          <div className={styles["c-review__sign-header-left-side"]}>
            <div className={styles["c-review__sign-header-icon"]}>
              <Icons.SwiftsignLogoIcon width={82} height={18} />
            </div>
            <div className={styles["c-review__sign-header-text"]}>
              <span
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {thisDocument?.title}
              </span>
            </div>
            <div style={{ width: "10%" }} />
          </div>
        ) : (
          <div className={wizardStyles["u-nav-bar-back"]}>
            <div
              className={wizardStyles["u-nav-bar-back__btn"]}
              onClick={goBack}
            >
              <Icons.ArrowLeft />
            </div>
            <div className={wizardStyles["u-nav-bar-back__detail"]}>
              <div
                className={wizardStyles["u-nav-bar-back__name"]}
                style={{ fontFamily: documentStyles?.font }}
              >
                {newDocumentData?.fileTitle || thisDocument?.fileTitle}
              </div>
            </div>
          </div>
        )
      }
      stepTopBar={
        <StepTopBar
          currentId={2}
          color={documentStyles?.color}
          font={documentStyles?.font}
          canClickTab={!isSign}
        />
      }
      documentStyles={documentStyles}
      right={
        isSign ? (
          <div className={styles["c-review__sign-header-right-side"]}>
            {shouldShowCommentEditSegmented && !isMobile && (
              <Segmented
                onChange={onSwitchChange}
                value={switchValue}
                options={[
                  {
                    value: false,
                    label: "Comment",
                  },
                  {
                    value: true,
                    label: "Edit",
                  },
                ]}
              />
            )}
            {shouldShowComments && <ToggleSuggestionsButton />}
            <SharePdfPopover
              documentStyles={documentStyles}
              createPDF={createPDF}
              thisDocument={thisDocument}
              shareUser={shareUser}
              editSharedUser={editSharedUser}
              deleteSharedUser={deleteSharedUser}
              user={user}
              shareOptions={shareOptions}
              invitedUsers={invitedUsers}
              setInvitedUsers={setInvitedUsers}
              canShare={canShare}
              currentShareUser={currentShareUser}
              downloadingpdf={downloadingpdf}
            >
              <CButton
                type="reverse-primary"
                title={t("share:share")}
                customStyles={styles["c-review__sign-header-right-side-btn"]}
                backgroundColor={documentStyles?.color}
                font={documentStyles?.font}
              />
            </SharePdfPopover>
            <CButton
              onClick={() => {
                signDocument();
              }}
              icon={<Icons.FeatherIcon width={17} height={17} />}
              type="primary"
              title={t("review_input:sign")}
              customStyles={styles["c-review__sign-header-right-side-btn"]}
              backgroundColor={documentStyles?.color}
              font={documentStyles?.font}
            />
          </div>
        ) : (
          <div className={styles.headerRightBlockContainer}>
            {shouldShowCommentEditSegmented && !isMobile && (
              <Segmented
                onChange={onSwitchChange}
                value={switchValue}
                options={[
                  {
                    value: false,
                    label: "Comment",
                  },
                  {
                    value: true,
                    label: "Edit",
                  },
                ]}
              />
            )}
            {shouldShowComments && <ToggleSuggestionsButton />}
            <SharePdfPopover
              documentStyles={documentStyles}
              createPDF={createPDF}
              thisDocument={thisDocument}
              shareUser={shareUser}
              editSharedUser={editSharedUser}
              deleteSharedUser={deleteSharedUser}
              user={user}
              shareOptions={shareOptions}
              invitedUsers={invitedUsers}
              setInvitedUsers={setInvitedUsers}
              canShare={canShare}
              currentShareUser={currentShareUser}
              downloadingpdf={downloadingpdf}
            >
              <CButton
                type="reverse-primary"
                title={t("share:share")}
                customStyles={styles["c-review__sign-header-right-side-btn"]}
                backgroundColor={documentStyles?.color}
                font={documentStyles?.font}
              />
            </SharePdfPopover>
            <CButton
              onClick={() => {
                navigate(keys.SIGN_SEND);
                doSetIsCreatingDocument(true);
              }}
              icon={<Icons.FeatherIcon width={17} height={17} />}
              type="primary"
              title={t("wizard:buttons.save_and_continue")}
              customStyles={styles["c-review__sign-header-right-side-btn"]}
              backgroundColor={documentStyles?.color}
              font={documentStyles?.font}
            />
          </div>
        )
      }
    />
  );
};

export default ReviewHeader;

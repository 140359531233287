import React from "react";
import { Switch } from "antd";
import PropTypes from "prop-types";

const CSwitch = ({ onChange = () => {}, checked, size, color }) => {
  return (
    <Switch
      style={{ backgroundColor: checked && color }}
      size={size}
      checked={checked}
      onChange={onChange}
    />
  );
};

CSwitch.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default CSwitch;

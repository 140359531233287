import initialState from "../initialStates";
import injectReducer from "../injectReducer";

import { AUTH } from "../types";

export default injectReducer(initialState.token, {
  [AUTH.SET_TOKEN]: (state, { payload: { token } }) => {
    return token;
  },
});

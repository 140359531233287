import React, { useEffect, useState, useRef } from "react";

import { CButton } from "../../../components";

import { IS_HEX } from "../../../utils/regularExpression";

import styles from "../index.module.scss";

const ColorPicker = (props) => {
  const { onClose, color } = props;

  const hexInput = useRef();

  const [localColor, setLocalColor] = useState("");

  useEffect(() => {
    if (!!color) {
      setLocalColor(color.substring(1));
    }
  }, [color]);

  useEffect(() => {
    if (!!hexInput) {
      hexInput.current.focus();
    }
  }, [hexInput]);

  const createResult = () => {
    let result = localColor;

    while (result.length < 6) {
      result += "F";
    }

    onClose(`#${result}`);
  };

  return (
    <div className={styles["u-overlay"]}>
      <div className={styles["u-modal-colorpicker"]}>
        <div className={styles["u-modal-colorpicker__head"]}>Pick color</div>

        <div className={styles["u-modal-colorpicker__row"]}>
          <div className={styles["u-hex"]}>
            <div className={styles["u-colorpicker__input"]}>
              <input
                type="text"
                value={localColor}
                onChange={(event) => {
                  const value = event.target.value?.toUpperCase();

                  const valueArr = value.split("");

                  const regexMonth = new RegExp(IS_HEX);

                  const result = [];

                  for (let i = 0; i < valueArr.length; i++) {
                    if (regexMonth.test(valueArr[i])) {
                      result.push(valueArr[i]);
                    }
                  }

                  const resultString = result.join("").substring(0, 6);


                  // if (result || value.length === 0) setLocalColor(value);
                  setLocalColor(resultString);
                }}
                // maxLength={6}
                ref={hexInput}
                pattern={IS_HEX}
              />
            </div>
            <div className={styles["u-colorpicker__label"]}>Hex</div>
          </div>
        </div>

        <div className={styles["u-modal-colorpicker__foot"]}>
          <div className={styles["item"]}>
            <CButton
              onClick={() => onClose(color)}
              type="white"
              title={"Cancel"}
              customStyles={styles["btn-colorpicker"]}
            />
          </div>
          <div className={styles["item"]}>
            <CButton
              onClick={() => createResult()}
              type="white"
              title={"Save"}
              customStyles={styles["btn-colorpicker"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;

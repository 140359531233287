import React from "react";
import PropTypes from "prop-types";

const CreateAddFileIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.80357 6.53944C7.80357 6.45238 7.73438 6.38095 7.64732 6.38095H6.70982C6.625 6.38095 6.55357 6.45238 6.55357 6.53944V8.43452H4.66741C4.57589 8.43452 4.5 8.50372 4.5 8.59077V9.52827C4.5 9.6131 4.57589 9.68452 4.66741 9.68452H6.55357V11.5796C6.55357 11.6667 6.625 11.7381 6.70982 11.7381H7.64732C7.73438 11.7381 7.80357 11.6667 7.80357 11.5796V9.68452H9.68973C9.78125 9.68452 9.85714 9.6131 9.85714 9.52827V8.59077C9.85714 8.50372 9.78125 8.43452 9.68973 8.43452H7.80357V6.53944ZM9.53125 0.459821C9.39732 0.325893 9.21652 0.25 9.02679 0.25H1.46429C1.0692 0.25 0.75 0.569196 0.75 0.964285V16.6786C0.75 17.0737 1.0692 17.3929 1.46429 17.3929H12.8929C13.2879 17.3929 13.6071 17.0737 13.6071 16.6786V4.83259C13.6071 4.64286 13.5312 4.46205 13.3973 4.32812L9.53125 0.459821ZM12 15.7857H2.35714V1.85714H8.65625L12 5.20089V15.7857Z"
        fill={color}
      />
    </svg>
  );
};

CreateAddFileIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default CreateAddFileIcon;

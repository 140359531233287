export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  const newB = b[orderBy] ?? "";
  const newA = a[orderBy] ?? "";
  if (newB < newA) {
    return -1;
  }
  if (newB > newA) {
    return 1;
  }
  return 0;
}

export function getComparator(orderBy, order = "asc") {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function sortByPosition(arr) {
  return stableSort(arr, getComparator("position"));
}

import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Button, Select, Space, Typography } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import "./index.scss";
import { useViewportHeight } from "../../hooks/helpers/useViewportHeight";
import useMediaQuery from "../../hooks/helpers/useMediaQuery";
import { useLocation } from "react-router-dom";

const { Option, OptGroup } = Select;

const MOBILE_HEADER_HEIGHT = 52;
const MOBILE_FOOTER_HEIGHT = 54;

const ICON_COLOR = "#8C8C97";

const SearchInput = ({
  handleSubmit,
  searchDocuments,
  setSearched,
  searchValue,
  setSearchValue,
  userData,
}) => {
  const location = useLocation();
  const autoCompleteRef = useRef();
  const [recentSearchesArr, setRecentSearchesArr] = useState([]);

  const viewportHeight = useViewportHeight();
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const handleSelectRecentSearch = (value) => {
    setSearchValue(value);
    setSearched(false);
  };

  useEffect(() => {
    const deduplicatedSearches = userData?.recentSearches.filter(
      (value, index, self) => {
        return self.indexOf(value) === index;
      }
    );

    setRecentSearchesArr(deduplicatedSearches);
  }, [userData?.recentSearches]);

  function removeRecentSearch(value) {
    setRecentSearchesArr(
      recentSearchesArr.filter((search) => search !== value)
    );
  }

  const defaultOpen = !!location.state?.cameFromDashboard;

  return (
    <form onSubmit={handleSubmit} className="search-container">
      <SearchOutlined
        style={{
          color: !!searchValue ? "#5F6CFF" : "#959DA7",
        }}
        className="search-icon"
      />
      <AutoComplete
        ref={autoCompleteRef}
        defaultOpen={defaultOpen}
        autoFocus={defaultOpen}
        placeholder="Search by title, content, names"
        value={searchValue}
        onChange={handleSelectRecentSearch}
        allowClear
        className="search-autocomplete"
        popupClassName="search-autocomplete-dropdown"
        listHeight={
          isMobile
            ? viewportHeight - 20 - MOBILE_HEADER_HEIGHT - MOBILE_FOOTER_HEIGHT
            : 256
        }
        onSelect={(value) => {
          searchDocuments(value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            // prevent submission on Shift+Enter
            e.preventDefault();
            e.stopPropagation(); // stop event propagation to prevent dropdown from closing
            const form = e.target.closest("form");
            if (form) {
              form.dispatchEvent(new Event("submit", { bubbles: true }));
            }
          }

          if (e.key === "Escape") {
            e.preventDefault();
            e.stopPropagation(); // stop event propagation to prevent dropdown from closing
            autoCompleteRef.current?.blur();
          }
        }}
      >
        <OptGroup
          label={
            <Typography.Text
              style={{
                fontWeight: 400,
                fontSize: "10px",
                color: "#2B2A35",
              }}
            >
              RECENT SEARCHES
            </Typography.Text>
          }
        >
          {recentSearchesArr?.map((value, itemIndex) => {
            return (
              <Option key={itemIndex} value={value} defaultChecked={false}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Space>
                    <SearchOutlined style={{ color: ICON_COLOR }} />
                    {value}
                  </Space>

                  <Button
                    type="text"
                    shape="circle"
                    onClick={(e) => {
                      e.stopPropagation(); // stop propagation so the select won't close
                      removeRecentSearch(value);
                    }}
                    icon={<CloseOutlined style={{ color: ICON_COLOR }} />}
                  />
                </div>
              </Option>
            );
          })}
        </OptGroup>
      </AutoComplete>
    </form>
  );
};

export default SearchInput;

import React from "react";
import PropTypes from "prop-types";

const InfoCircleIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 0.5C3.13437 0.5 0 3.63437 0 7.5C0 11.3656 3.13437 14.5 7 14.5C10.8656 14.5 14 11.3656 14 7.5C14 3.63437 10.8656 0.5 7 0.5ZM7 13.3125C3.79063 13.3125 1.1875 10.7094 1.1875 7.5C1.1875 4.29063 3.79063 1.6875 7 1.6875C10.2094 1.6875 12.8125 4.29063 12.8125 7.5C12.8125 10.7094 10.2094 13.3125 7 13.3125Z"
        fill={color}
      />
      <path
        d="M6.24976 4.75C6.24976 4.94891 6.32877 5.13968 6.46943 5.28033C6.61008 5.42098 6.80084 5.5 6.99976 5.5C7.19867 5.5 7.38943 5.42098 7.53009 5.28033C7.67074 5.13968 7.74976 4.94891 7.74976 4.75C7.74976 4.55109 7.67074 4.36032 7.53009 4.21967C7.38943 4.07902 7.19867 4 6.99976 4C6.80084 4 6.61008 4.07902 6.46943 4.21967C6.32877 4.36032 6.24976 4.55109 6.24976 4.75ZM7.37476 6.5H6.62476C6.55601 6.5 6.49976 6.55625 6.49976 6.625V10.875C6.49976 10.9438 6.55601 11 6.62476 11H7.37476C7.44351 11 7.49976 10.9438 7.49976 10.875V6.625C7.49976 6.55625 7.44351 6.5 7.37476 6.5Z"
        fill={color}
      />
    </svg>
  );
};

InfoCircleIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default InfoCircleIcon;

import React, { useEffect, useState } from "react";
import { Input } from "antd";
import PropTypes from "prop-types";
import styles from "./index.module.scss";

const CInput = ({
  value = undefined,
  name = "",
  placeholder = "",
  customStyles = "",
  onChange = () => {},
  onBlur = () => {},
  onInput = () => {},
  label = "",
  errors = "",
  type = "text",
  styleLabel = "",
  focusOn = false,
  maxLength = 10000,
  inputmode,
  pattern,
  onClick,
  disabled = false,
  errorStyles,
  defaultValue = null,
  star = false,
  onRefChange = () => {},
  customType,
  font,
  color,
  onChangeEnd = () => {},
  addonAfter,
  fullWidth,
  fullHeight,
  textArea,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [inputTimeout, setInputTimeout] = useState(null);

  useEffect(() => {
    if (defaultValue === "verification") onChange("");
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    if (customType === "date") {
      if (
        e.keyCode !== 190 &&
        !(e.keyCode >= 48 && e.keyCode <= 57) &&
        e.keyCode !== 8 &&
        e.keyCode !== 110
      ) {
        e.preventDefault();
      }
    }
    if (customType === "positiveNumbers") {
      if (e.keyCode === 109 || e.keyCode === 189) {
        e.preventDefault();
      }
    }
  };

  const handleOnChange = (event) => {
    onChange(event);

    clearTimeout(inputTimeout);

    const value = event.currentTarget.value;

    const timer = setTimeout(() => {
      onChangeEnd(value);
    }, 2000);

    setInputTimeout(timer);
  };

  return (
    <div
      className={styles["u-style-article"]}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={() => {
        setIsFocus(false);
      }}
      style={{
        width: fullWidth ? "100%" : "auto",
        height: fullHeight ? "100%" : "auto",
      }}
    >
      {label && (
        <label
          htmlFor={name}
          className={[styles["u-style-label"], styleLabel].join(" ")}
          style={{ fontFamily: font }}
        >
          {star && <span className={styles["u-style-star"]}>*</span>}
          {label}
        </label>
      )}

      {textArea ? (
        <Input.TextArea
          className={[
            styles["u-style-input"],
            errors && styles["u-style-input__error"],
            customStyles,
          ].join(" ")}
          value={value}
          id={name}
          name={name}
          onChange={handleOnChange}
          onInput={onInput}
          addonAfter={addonAfter}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          ref={(input) => {
            if (focusOn) {
              focusOn
                ? input && input.focus() //focus on the field if "true" is transmitted
                : input && input.blur();
            }
            if (!!input) {
              onRefChange(input.input);
            }
          }}
          inputMode={inputmode}
          pattern={pattern}
          onClick={onClick}
          // KeyboardDisplayRequiresUserAction={false} // show keyboard if input focused, ios
          disabled={disabled}
          onKeyDown={handleKeyDown}
          style={{
            fontFamily: font,
            borderColor: isHover || isFocus ? color : undefined,
            boxShadow: isFocus ? `0px 0px 0px 4px ${color}33` : undefined,
          }}
          onBlur={() => {
            onBlur();
          }}
          maxLength={maxLength}
          {...props}
        />
      ) : (
        <Input
          className={[
            styles["u-style-input"],
            errors && styles["u-style-input__error"],
            customStyles,
          ].join(" ")}
          value={value}
          id={name}
          name={name}
          onChange={handleOnChange}
          onInput={onInput}
          addonAfter={addonAfter}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
          ref={(input) => {
            if (focusOn) {
              focusOn
                ? input && input.focus() //focus on the field if "true" is transmitted
                : input && input.blur();
            }

            if (!!input) {
              onRefChange(input.input);
            }
          }}
          inputMode={inputmode}
          pattern={pattern}
          onClick={onClick}
          // KeyboardDisplayRequiresUserAction={false} // show keyboard if input focused, ios
          disabled={disabled}
          onKeyDown={handleKeyDown}
          style={{
            fontFamily: font,
            borderColor: isHover || isFocus ? color : undefined,
            boxShadow: isFocus ? `0px 0px 0px 4px ${color}33` : undefined,
          }}
          onBlur={(e) => {
            onBlur(e);
          }}
          maxLength={maxLength}
          {...props}
        />
      )}

      {errors && (
        <div
          className={[styles["u-style-errors"], errorStyles].join(" ")}
          style={{
            fontFamily: font,
          }}
        >
          {errors}
        </div>
      )}
    </div>
  );
};

CInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  customStyles: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.string,
  type: PropTypes.string,
  styleLabel: PropTypes.string,
  focusOn: PropTypes.bool,
  maxLength: PropTypes.number,
  inputmode: PropTypes.string,
  pattern: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  errorStyles: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  star: PropTypes.bool,
  onRefChange: PropTypes.func,
  customType: PropTypes.string,
  font: PropTypes.string,
  color: PropTypes.string,
  onChangeEnd: PropTypes.func,
  addonAfter: PropTypes.node,
  fullWidth: PropTypes.string,
  fullHeight: PropTypes.string,
  textArea: PropTypes.bool,
};

export default CInput;

import React from "react";
import PropTypes from "prop-types";

const SendIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M23.2348 12.1535L0.828561 0.919538C0.737489 0.874002 0.633025 0.863288 0.533918 0.887395C0.423923 0.914587 0.329186 0.984257 0.270448 1.08115C0.21171 1.17804 0.193759 1.29426 0.220525 1.40436L2.52945 10.8383C2.56428 10.9803 2.66874 11.0954 2.80803 11.141L6.76428 12.499L2.8107 13.857C2.67142 13.9053 2.56695 14.0177 2.53481 14.1597L0.220525 23.607C0.196418 23.7061 0.207132 23.8106 0.252668 23.899C0.357132 24.1106 0.614275 24.1963 0.828561 24.0919L23.2348 12.9222C23.3179 12.882 23.3848 12.8124 23.4277 12.732C23.5321 12.5178 23.4464 12.2606 23.2348 12.1535ZM2.8616 20.9231L4.20892 15.416L12.1161 12.7026C12.1777 12.6811 12.2286 12.6329 12.25 12.5686C12.2875 12.4561 12.2286 12.3356 12.1161 12.2954L4.20892 9.58471L2.86695 4.099L19.6884 12.5338L2.8616 20.9231Z"
      />
    </svg>
  );
};

SendIcon.propTypes = {
  color: PropTypes.string,
};

export default SendIcon;

import CheckboxFormItem from "./CheckboxFormItem";
import DateFormItem from "./DateFormItem";
import SelectFormItem from "./SelectFormItem";
import TextFormItem from "./TextFormItem";
import LocationFormItem from "./LocationFormITem";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CheckboxFormItem,
  DateFormItem,
  SelectFormItem,
  TextFormItem,
  LocationFormItem,
};

export const SuggestionStatus = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  SENT: "sent",
  RESOLVED: "resolved",
};

export const SuggestionType = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
  COMMENT: "comment",
};

export const SUGGESTION_FORMAT_STRING = "d MMM H:mm";

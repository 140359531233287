import React from "react";
import PropTypes from "prop-types";

const Comments = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.4928 8.72188C21.0009 7.55318 20.2958 6.50419 19.397 5.60318C18.5044 4.70739 17.4449 3.99535 16.2783 3.50737C15.0813 3.00463 13.8103 2.75 12.5002 2.75H12.4566C11.1378 2.75653 9.86025 3.01769 8.65891 3.53131C7.50232 4.02429 6.45268 4.7376 5.56851 5.63147C4.67839 6.5303 3.97979 7.57494 3.49664 8.73929C2.99608 9.94498 2.74362 11.2268 2.75015 12.5457C2.75754 14.0571 3.11511 15.5462 3.7948 16.8962V20.2042C3.7948 20.4698 3.90027 20.7244 4.08802 20.9121C4.27576 21.0999 4.5304 21.2054 4.79591 21.2054H8.10612C9.4561 21.885 10.9452 22.2426 12.4566 22.25H12.5023C13.806 22.25 15.0704 21.9975 16.2609 21.5035C17.4216 21.0213 18.4772 20.3176 19.3687 19.4316C20.2675 18.5415 20.9748 17.5012 21.4688 16.3412C21.9825 15.1399 22.2436 13.8624 22.2502 12.5435C22.2567 11.2181 21.9999 9.93192 21.4928 8.72188ZM18.2043 18.2542C16.6787 19.7646 14.6547 20.596 12.5002 20.596H12.4632C11.1508 20.5895 9.8472 20.263 8.69591 19.6493L8.5131 19.5513H5.44881V16.4871L5.35088 16.3042C4.73715 15.153 4.4107 13.8493 4.40417 12.537C4.39547 10.3672 5.22465 8.33013 6.74591 6.79581C8.265 5.2615 10.2955 4.41272 12.4653 4.40402H12.5023C13.5905 4.40402 14.646 4.61512 15.6406 5.03298C16.6113 5.43996 17.4818 6.02539 18.2305 6.77405C18.9769 7.52054 19.5646 8.39325 19.9715 9.3639C20.3937 10.3694 20.6048 11.4358 20.6005 12.537C20.5874 14.7046 19.7365 16.7352 18.2043 18.2542Z"
        fill={color}
      />
    </svg>
  );
};

Comments.propTypes = {
  color: PropTypes.string,
};

export default Comments;

import ConfirmDelete from "./ConfirmDelete";
import ConfirmTemplateDelete from "./ConfirmTemplateDelete";
import ConfirmLexicalParse from "./ConfirmLexicalParse";
import ConfirmRemove from "./ConfirmRemove";
import ChangePassword from "./ChangePassword";
import ConfirmAddress from "./ConfirmAddress";
import ColorPicker from "./ColorPicker";
import Welcome from "./Welcome";
import Registration from "./Registration";
import PreviewDocument from "./PreviewDocument";
import UploadDocx from "./UploadDocx";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ConfirmDelete,
  ConfirmTemplateDelete,
  ConfirmRemove,
  ChangePassword,
  ConfirmAddress,
  ColorPicker,
  Welcome,
  Registration,
  PreviewDocument,
  UploadDocx,
  ConfirmLexicalParse,
};

import React from "react";
import { Icons } from "../../index";
import styles from "../styles.module.scss";
import { useComments } from "../../../contexts/CommentContext";

const CommentsButtonContent = () => {
  const { commentList } = useComments();

  return (
    <>
      <Icons.ContactIcon color="#5F6CFF" />
      {!!commentList.length && (
        <span className={styles.toggleSuggestionsButton__suggestionsNumber}>
          {commentList.length}
        </span>
      )}
    </>
  );
};

const ToggleSuggestionsButton = ({
  onToggle,
  style,
  currentShareUser,
  isContractNotUploaded,
  isCommentsBlockOpen,
}) => {
  // if (!currentShareUser && isContractNotUploaded) {
  //   return null;
  // }

  return (
    <button
      className={`${styles.toggleSuggestionsButton} ${
        isCommentsBlockOpen ? styles.toggleSuggestionsButton__active : ""
      }`}
      onClick={onToggle}
      {...{ style }}
    >
      {isContractNotUploaded ? (
        <CommentsButtonContent />
      ) : (
        <Icons.DataField color="currentColor" />
      )}
    </button>
  );
};

export default ToggleSuggestionsButton;

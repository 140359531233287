import React from "react";
import PropTypes from "prop-types";

const UserIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.5 3H1.5H0V1.5V0H1.5H12.5H14V1.5V3H12.5H8.5V13.5L8.50003 15H7H5.5V13.5V3Z"
        fill={color}
      />
    </svg>
  );
};

UserIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default UserIcon;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { api } from "../../services/api";
import moment from "moment";

import {
  Header,
  HowCanWeHelp,
  Layout,
  NavBarBottom,
  RightPanel,
  WorkspaceSidebar,
  WorkspacesList,
} from "../../components";

import { activeFolderSelector } from "../../store/selectors/dashboard";
import {
  accountsSelector,
  documentsSelector,
  userSelector,
} from "../../store/selectors/auth";

import {
  deleteDocument,
  getSortedDocuments,
  setErrorLoadingScreenType,
  updateDocumentFromDashboard,
} from "../../store/actions/auth";
import {
  getDocument,
  loadDocumentPdf,
  setIndex,
  setIsCreatingDocument,
} from "../../store/actions/prompts";

import stylesWorkspace from "../../components/WorkspaceTabs/index.module.scss";
import styles from "../../styles/Workspace.module.scss";
import { Tooltip, Typography } from "antd";
import { SearchResults } from "../../components/SearchDocumentsResult";
import RecentSearches from "./RecentSearches";

import DefaultUserImg from "../../components/DefaultUserImg";
import { returnStatusTooltip, statusDetect } from "../../utils/DocumentStatus";
import createPersistedState from "use-persisted-state";
import classNames from "classnames";
import useMediaQuery from "../../hooks/helpers/useMediaQuery";
import MobileSearchBar from "../../components/MobileSearchBar";

import "./index.scss";
import { orderBy, uniqueId } from "lodash";

const { Title } = Typography;

export const SEARCH_VALUE_KEY = "swiftsign:searchValue";
const usePersistedSearchValue = createPersistedState(SEARCH_VALUE_KEY);

const SearchDocuments = ({
  activeFolder,
  account,
  userData,
  documents,
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  loadDocumentPdf,
  setErrorLoadingScreenType,
}) => {
  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChoosingFolder, setIsChoosingFolder] = useState(true);
  const [searchedFolders, setSearchedFolders] = useState();
  const [searched, setSearched] = useState(false);
  const [customColumn, setCustomColumn] = useState();
  const [newColumns, setNewColumns] = useState([]);
  const [variables, setVariables] = useState([]);
  const [sortedProperty, setSortedProperty] = useState();
  const [tableData, setTableData] = useState([]);
  const [searchValue, setSearchValue] = usePersistedSearchValue("");

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const searchDocuments = async (value) => {
    const actualValue = value || searchValue;

    try {
      if (actualValue) {
        const { data } = await api.get(`document/search?query=${actualValue}`);
        setSearchedFolders(data.data);
        setSearched(true);
      } else {
        setSearchedFolders(undefined);
        setSearched(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleCreateVariables(_, newVar) {
    setVariables((old) => [...old, newVar]);
  }

  function onDeleteVariable(key) {
    setVariables((old) => old.filter((v) => v.key !== key));
    setNewColumns((old) => old.filter((c) => c !== key));
  }

  function handleSubmit() {
    searchDocuments();
  }

  useEffect(() => {
    const lastItem = variables[variables.length - 1];
    setCustomColumn(lastItem?.key);
  }, [variables]);

  useEffect(() => {
    if (customColumn && !newColumns.includes(customColumn)) {
      setNewColumns([...newColumns, customColumn]);
    }
  }, [customColumn]);

  function transformSearchedText(text) {
    const newString = text
      .replace(/<em>/gi, '<span class="highlight">')
      .replace(/<\/em>/gi, "</span>");

    return newString;
  }

  function transformToString(text) {
    const newString = text
      .replace(/<em>/gi, "")
      .replace(/<\/em>/gi, "")
      .replace(/\n/g, "");

    return newString;
  }

  const getAprovedOrRejectedStatus = useCallback((doc) => {
    if (["AWAITING", "TO_SIGN"].includes(doc?.status)) {
      // TODO: to add approved status uncomment these
      // if (doc.approvers?.every(approver => approver.status === "approved")) {
      //   return "approved";
      // }

      if (
        doc.approvers?.some((approver) => approver.status === "rejected") &&
        !doc.approvers?.some((approver) => approver.status === "not_reviewed")
      ) {
        return "rejected";
      }
    }

    return null;
  }, []);

  function removeTextBeforeEmTag(inputString) {
    const startIndex = inputString.indexOf("<em>");
    if (startIndex !== -1) {
      return inputString.substring(startIndex);
    } else {
      return inputString; // Return the original string if no <em> tag is found
    }
  }

  useEffect(() => {
    if (searchedFolders) {
      const { documents, searchData } = searchedFolders;

      const getData = documents.map((doc, index) => {
        const owner = doc?.users?.find(
          (user) => user?.userID === doc?.createdBy
        );

        const uniqueColumnsObj = newColumns?.map((column) => {
          const foundVar = doc?.variables?.find(
            (element) => element.key === column
          );

          return {
            [column]: {
              formatted: foundVar?.value,
              string: foundVar?.value,
              key: column,
            },
          };
        });

        const mergedObjects = Object.assign({}, ...uniqueColumnsObj);

        const approvedOrRejectedStatus = getAprovedOrRejectedStatus(doc);

        return {
          id: uniqueId(),
          document: doc,
          values: {
            title: {
              formatted: (
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: transformSearchedText(
                      searchData?.hits[index]?._highlightResult?.fileTitle
                        ?.value
                    ),
                  }}
                />
              ),
              string: transformToString(
                searchData?.hits[index]?._highlightResult?.fileTitle?.value
              ),
              key: "title",
            },
            snippet: {
              formatted: (
                <div
                  className={classNames("title", "snippet-block")}
                  dangerouslySetInnerHTML={{
                    __html: transformSearchedText(
                      removeTextBeforeEmTag(
                        searchData?.hits[index]?._snippetResult?.content?.value
                      )
                    ),
                  }}
                />
              ),
              string: transformToString(
                searchData?.hits[index]?._highlightResult?.content?.value
              ),
              key: "snippet",
            },

            status: {
              formatted: (
                <div className="status">
                  <Tooltip
                    title={returnStatusTooltip(
                      approvedOrRejectedStatus ?? doc?.status
                    )}
                    overlayClassName={"u-tooltip"}
                  >
                    <div>
                      <div>
                        {statusDetect(approvedOrRejectedStatus ?? doc?.status)}
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ),
              string: approvedOrRejectedStatus ?? doc?.status,
              key: "status",
            },
            owner: {
              formatted: (
                <DefaultUserImg
                  initials={owner?.initials}
                  firstName={owner?.firstName}
                  lastName={owner?.lastName}
                  withTooltip={true}
                  image={owner?.image}
                />
              ),
              string: owner?.initials,
              key: "owner",
            },
            updated: {
              formatted: <>{moment(doc?.updatedAt).format("MMM DD, YY")}</>,
              string: doc?.updatedAt,
              key: "updated",
            },
            ...mergedObjects,
          },
        };
      });

      setTableData(getData);
    }
  }, [searchedFolders, newColumns]);

  const sortedDocuments = useMemo(() => {
    if (sortedProperty?.property && sortedProperty?.order) {
      return orderBy(
        tableData,
        `values.${sortedProperty.property}.string`,
        sortedProperty.order
      );
    }

    return tableData;
  }, [sortedProperty, tableData]);

  useEffect(() => {
    if (searchValue) {
      searchDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      header={
        isMobile ? (
          <MobileSearchBar
            {...{
              handleSubmit,
              searchDocuments,
              setSearched,
              userData,
              searchValue,
              setSearchValue,
            }}
          />
        ) : (
          <Header
            dashboard={
              <RecentSearches
                handleSubmit={handleSubmit}
                searchDocuments={searchDocuments}
                setSearched={setSearched}
                userData={userData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            }
            dashboardContainer={{ width: "100%" }}
            leftContainer={{ minWidth: 130, marginRight: "182px" }}
            leftType={isChoosingFolder ? "auth" : "arrowLeftFolders"}
            dropdownOpened={(isOpen) => {
              setIsMenuOpen(isOpen);
            }}
            onLeftSideClick={() => {
              setIsChoosingFolder(true);
            }}
            userData={userData}
            accountData={account?.[0]}
          />
        )
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      workspaces={<WorkspacesList />}
    >
      <div className={styles["c-folders-view"]}>
        <WorkspaceSidebar />
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />
        <div
          className={styles["c-folders-view__section"]}
          style={{ overflow: "auto" }}
        >
          <div className={styles["c-folders-view__body"]}>
            <div
              data-tab-body={"recently-edited"}
              className={`${stylesWorkspace["table"]} ${stylesWorkspace["active"]} search-section`}
              style={{
                overflow: "auto",
              }}
            >
              {sortedDocuments?.length ? (
                sortedDocuments.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={stylesWorkspace["item"]}
                      style={{
                        width: "100%",
                        marginBottom: 0,
                        ...(index > 0 && {
                          marginTop: 8,
                        }),
                      }}
                    >
                      <SearchResults
                        item={item?.values}
                        document={item?.document}
                        getDocument={getDocument}
                        setIsCreatingDocument={setIsCreatingDocument}
                        setIndex={setIndex}
                        setErrorLoadingScreenType={setErrorLoadingScreenType}
                        index={index}
                        variables={variables}
                        setVariables={handleCreateVariables}
                        onDeleteVariable={onDeleteVariable}
                        userData={userData}
                        setSortedProperty={setSortedProperty}
                        tableData={tableData}
                        setTableData={setTableData}
                      />
                    </div>
                  );
                })
              ) : !searched ? (
                <div className="search-instructions-container">
                  <Title level={3}>Find what’s important</Title>
                  <Typography.Text>
                    Search by title, content, names and more.
                  </Typography.Text>
                </div>
              ) : (
                <div className="search-instructions-container">
                  <Title level={3}>Nothing turned up. Try again?</Title>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {!isMenuOpen && !openRightPanel && <NavBarBottom />}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  activeFolder: activeFolderSelector(state),
  account: accountsSelector(state),
  userData: userSelector(state),
  documents: documentsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDocumentFromDashboard,
      deleteDocument,
      getSortedDocuments,
      getDocument,
      setIsCreatingDocument,
      setIndex,
      loadDocumentPdf,
      setErrorLoadingScreenType,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchDocuments);

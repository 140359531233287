import React from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import { Icons } from "../index";
import Signees from "./Signees";
import { useSelector } from "react-redux";
import { documentSelector } from "../../store/selectors/prompts";
import { useTranslation } from "react-i18next";

const DocumentSignatures = ({ stylesForDocument, onTipsPress, signeeData }) => {
  const thisDocument = useSelector(documentSelector);
  const { t } = useTranslation();

  return (
    <div className={styles["c-review-status__item"]}>
      <div className={styles["teritary__head"]}>
        <div className={styles["teritary__head__icon"]}>
          <Icons.FeatherIcon width={22} height={22} />
        </div>
        <div className={styles["teritary__head__title"]}>
          {t("contract:signatures")}
        </div>
        {thisDocument?.tips.find(
          (value) => value?.tipID === "SIGNATURES_123"
        ) && (
          <div
            data-html2canvas-ignore="true"
            className={styles["has-help"]}
            style={{ color: stylesForDocument?.color }}
            onClick={() => {
              onTipsPress("SIGNATURES_123");
            }}
          >
            <Icons.QuestionCircleIcon color={stylesForDocument?.color} />
            {t("wizard:info.help")}
          </div>
        )}
      </div>

      <div className={styles["teritary__row"]}>
        {thisDocument?.parties?.map((partyItem) => {
          return (
            <div
              className={styles["teritary__item"]}
              key={`signees-${partyItem?.partyID}`}
            >
              <div
                className={styles["teritary__card"]}
                style={{
                  border: `1px solid ${
                    stylesForDocument?.color
                      ? `${stylesForDocument.color}33`
                      : "#ecedff"
                  }`,
                  fontFamily: stylesForDocument?.font,
                }}
              >
                <div
                  className={styles["teritary__card__head"]}
                  style={{
                    borderBottom: `1px solid ${
                      stylesForDocument?.color
                        ? `${stylesForDocument.color}33`
                        : "#ecedff"
                    }`,
                  }}
                >
                  <div className={styles["icon"]}>
                    <Icons.OfficeIcon color={stylesForDocument?.color} />
                  </div>
                  <div className={styles["title"]}>
                    {partyItem?.type === "Company"
                      ? `${partyItem?.contact?.organizationName ?? ""} ${
                          partyItem?.contact?.organizationType ?? ""
                        }`
                      : `${partyItem?.contact?.firstName} ${
                          partyItem?.contact?.lastName ?? ""
                        }`}
                  </div>
                </div>

                <Signees
                  list={thisDocument.signees || signeeData}
                  isLocal={!thisDocument?.signees}
                  {...{
                    partyItem,
                    signeeData,
                    stylesForDocument,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentSignatures;

import React from "react";
import PropTypes from "prop-types";

const ContactIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.9928 6.22188C18.5009 5.05318 17.7958 4.00419 16.8969 3.10318C16.0044 2.20739 14.9448 1.49535 13.7782 1.00737C12.5813 0.504632 11.3103 0.25 10.0001 0.25H9.9566C8.63773 0.256529 7.36022 0.51769 6.15888 1.03131C5.00228 1.52429 3.95265 2.2376 3.06848 3.13147C2.17836 4.0303 1.47976 5.07494 0.996608 6.23929C0.49605 7.44498 0.243594 8.72684 0.250123 10.0457C0.257508 11.5571 0.615078 13.0462 1.29477 14.3962V17.7042C1.29477 17.9698 1.40024 18.2244 1.58799 18.4121C1.77573 18.5999 2.03037 18.7054 2.29588 18.7054H5.60609C6.95606 19.385 8.44519 19.7426 9.9566 19.75H10.0023C11.3059 19.75 12.5704 19.4975 13.7608 19.0035C14.9215 18.5213 15.9771 17.8176 16.8686 16.9316C17.7675 16.0415 18.4748 15.0012 18.9688 13.8412C19.4824 12.6399 19.7436 11.3624 19.7501 10.0435C19.7567 8.71814 19.4998 7.43192 18.9928 6.22188ZM15.7043 15.7542C14.1787 17.2646 12.1547 18.096 10.0001 18.096H9.96313C8.65079 18.0895 7.34717 17.763 6.19588 17.1493L6.01307 17.0513H2.94878V13.9871L2.85085 13.8042C2.23712 12.653 1.91067 11.3493 1.90414 10.037C1.89544 7.86719 2.72462 5.83013 4.24588 4.29581C5.76497 2.7615 7.79549 1.91272 9.9653 1.90402H10.0023C11.0905 1.90402 12.146 2.11512 13.1406 2.53298C14.1112 2.93996 14.9818 3.52539 15.7304 4.27405C16.4769 5.02054 17.0645 5.89325 17.4715 6.8639C17.8937 7.86936 18.1048 8.93577 18.1005 10.037C18.0874 12.2046 17.2365 14.2352 15.7043 15.7542Z"
        fill={color}
      />
    </svg>
  );
};

ContactIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default ContactIcon;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const CCheckbox = ({
  value = undefined,
  name = "",
  title = "",
  customStyles = "",
  onChange = () => {},
  onBlur = () => {},
  onInput = () => {},
  errors = "",
  type = "checkbox",
  defaultChecked = false,
  checked,
  font,
  color,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <>
      <label
        className={`${styles["form__label"]} ${
          errors && styles["is-error"]
        } ${customStyles}`}
      >
        <input
          className={styles["form__input"]}
          name={name}
          value={value}
          onChange={(e) => {
            setIsChecked(!isChecked);
            onChange(e);
          }}
          onBlur={onBlur}
          onInput={onInput}
          type={type}
          // defaultChecked={defaultChecked}
          checked={isChecked}
        />
        <span className={styles["form__section"]}>
          <span
            className={`${styles["form__box"]} ${
              errors && styles["is-error"]
            } ${customStyles}`}
            style={
              checked || isChecked
                ? { backgroundColor: color, borderColor: color }
                : { borderColor: color }
            }
          >
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.60496 7.72199L3.58735 7.73961L0.1875 4.33977L1.62018 2.90709L3.60502 4.89194L8.37904 0.11792L9.81172 1.5506L3.62264 7.73967L3.60496 7.72199Z"
                fill={"currentColor"}
              />
            </svg>
          </span>
          <span className={styles["form__name"]} style={{ fontFamily: font }}>
            {title}
          </span>
        </span>
      </label>
      {errors && <div className={styles["u-style-errors"]}>{errors}</div>}
    </>
  );
};

CCheckbox.propTypes = {
  customStyles: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  errors: PropTypes.string,
  type: PropTypes.string,
};

export default CCheckbox;

import React, { useState } from "react";
import { Popover, Space, Typography, notification, Button } from "antd";
import { TeamOutlined, LinkOutlined } from "@ant-design/icons";
import { CButton } from "../../components";
import { useTranslation } from "react-i18next";
import { ShareContent } from "./components/shareContent";
import "./index.scss";

import { Icons } from "../../components";

const { Text } = Typography;

const Content = ({
  documentStyles,
  createPDF,
  setTab,
  tab,
  thisDocument,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  user,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
  downloadingpdf,
}) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(1);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/?shareCallback=true&documentId=${thisDocument?.documentID}&passcode=${thisDocument?.passcode}`
    );
    notification.info({
      message: t("share:link_copied"),
      description: t("share:link_description"),
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  return (
    <div style={{ display: "flex", fontWeight: 500 }}>
      <div style={{ padding: "12px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div
            onClick={() => setTab(1)}
            className={tab === 1 ? "action-button-active" : "action-button"}
          >
            <TeamOutlined size={20} style={{ fontSize: 20 }} />
            {t("share:tab:invite")}
          </div>

          {thisDocument?.status === "COMPLETED" && (
            <div
              onClick={() => setTab(2)}
              className={tab === 2 ? "action-button-active" : "action-button"}
            >
              <Icons.PdfIcon />
              {t("share:tab:pdf")}
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          borderLeft: "1px solid #E6E7F2",
          backgroundColor: "#FAFAFC",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <div
          style={{
            minWidth: "440px",
            minHeight: "240px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {tab === 1 ? (
            <>
              <ShareContent
                setFocused={setFocused}
                focused={focused}
                documentStyles={documentStyles}
                thisDocument={thisDocument}
                shareUser={shareUser}
                editSharedUser={editSharedUser}
                deleteSharedUser={deleteSharedUser}
                user={user}
                shareOptions={shareOptions}
                invitedUsers={invitedUsers}
                setInvitedUsers={setInvitedUsers}
              />

              <Space
                align="center"
                style={{
                  padding: "8px 16px",
                  borderTop: "1px solid #E6E7F2",
                }}
              >
                <div className={"link-button"} onClick={handleCopyLink}>
                  <LinkOutlined style={{ fontSize: 20 }} />
                  {t("share:link")}
                </div>

                <Text
                  type="secondary"
                  style={{ lineHeight: "1.5", fontSize: "12px" }}
                >
                  {t("share:link_description")}
                </Text>
              </Space>
            </>
          ) : (
            <Space
              direction="vertical"
              style={{ width: "100%", padding: "20px 16px 0px" }}
              size={"middle"}
            >
              <Text>
                {t("share:export")} {thisDocument?.fileTitle}{" "}
                {t("share:to_pdf")}
              </Text>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <CButton
                  disabled={downloadingpdf}
                  onClick={createPDF}
                  type="primary"
                  title={t("review_input:export")}
                />
              </div>
            </Space>
          )}
        </div>
      </div>
    </div>
  );
};

export const SharePdfPopover = ({
  children,
  documentStyles,
  createPDF,
  thisDocument,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  user,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
  canShare,
  currentShareUser,
  downloadingpdf,
}) => {
  const [tab, setTab] = useState(1);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleVisibleChange = (visible) => {
    if (visible) {
      // Popover is opening, add scroll listener
      window.addEventListener("scroll", handleScroll);
    } else {
      // Popover is closing, remove scroll listener
      window.removeEventListener("scroll", handleScroll);
    }
    setVisible(visible);
  };

  const handleScroll = () => {
    // User scrolled, close the Popover
    setVisible(false);
    // Remove scroll listener
    window.removeEventListener("scroll", handleScroll);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/?shareCallback=true&documentId=${thisDocument?.documentID}&passcode=${thisDocument?.passcode}`
    );
    notification.info({
      message: t("share:link_copied"),
      description: t("share:link_description"),
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  const PopoverContent = () => {
    return canShare ? (
      <Content
        documentStyles={documentStyles}
        createPDF={createPDF}
        setTab={setTab}
        tab={tab}
        thisDocument={thisDocument}
        shareUser={shareUser}
        editSharedUser={editSharedUser}
        deleteSharedUser={deleteSharedUser}
        user={user}
        shareOptions={shareOptions}
        invitedUsers={invitedUsers}
        setInvitedUsers={setInvitedUsers}
        downloadingpdf={downloadingpdf}
      />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          fontSize: "14px",
          fontWeight: "500",
          padding: "6px",
        }}
      >
        <Button
          type="text"
          className={"drawer-share-button"}
          onClick={() => {
            handleCopyLink();
            setVisible(false);
          }}
        >
          <LinkOutlined className={"button-icon"} />
          <Text style={{ margin: 0 }}>{t("share:link")}</Text>
        </Button>

        {currentShareUser && currentShareUser?.permission !== "view" && (
          <Button
            type="text"
            className={"drawer-share-button"}
            onClick={() => {
              createPDF();
              setVisible(false);
            }}
          >
            <Icons.PdfIcon className={"button-icon"} />
            <Text style={{ margin: 0 }}>{t("share:tab:pdf")}</Text>
          </Button>
        )}
      </div>
    );
  };

  return (
    <Popover
      placement="bottomLeft"
      content={<PopoverContent />}
      trigger="click"
      overlayInnerStyle={{ borderRadius: "12px" }}
      showArrow={false}
      overlayClassName={"share-popover-overlay"}
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      {children}
    </Popover>
  );
};

import React from "react";
import PropTypes from "prop-types";

const OfficeIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="17"
      height="23"
      viewBox="0 0 17 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.816 17.2885C16.3131 17.2885 16.716 17.6914 16.716 18.1885V21.8412C16.716 22.3382 16.3131 22.7412 15.816 22.7412C15.319 22.7412 14.916 22.3382 14.916 21.8412V18.1885C14.916 17.6914 15.319 17.2885 15.816 17.2885Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1006 17.875C10.1006 17.3779 10.5035 16.975 11.0006 16.975L15.5006 16.975C15.9976 16.975 16.4006 17.3779 16.4006 17.875C16.4006 18.372 15.9976 18.775 15.5006 18.775L11.0006 18.775C10.5035 18.775 10.1006 18.372 10.1006 17.875Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.38455 5.96834C2.36698 5.96834 2.35273 5.98258 2.35273 6.00015V21.841C2.35273 22.338 1.94979 22.741 1.45273 22.741C0.955678 22.741 0.552734 22.338 0.552734 21.841V6.00015C0.552734 4.98847 1.37286 4.16833 2.38455 4.16833H9.83905C10.8507 4.16833 11.6709 4.98847 11.6709 6.00015V21.841C11.6709 22.338 11.2679 22.741 10.7709 22.741C10.2738 22.741 9.87086 22.338 9.87086 21.841V6.00015C9.87086 5.98258 9.85662 5.96834 9.83905 5.96834H2.38455Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66788 1.01803C3.88057 0.734449 4.2431 0.474976 4.71934 0.474976H7.53184C8.00807 0.474976 8.3706 0.734449 8.58329 1.01803C8.79577 1.30134 8.90059 1.65148 8.90059 1.99998V5.12498C8.90059 5.62203 8.49764 6.02498 8.00059 6.02498H4.25059C3.75353 6.02498 3.35059 5.62203 3.35059 5.12498V1.99998C3.35059 1.65148 3.4554 1.30134 3.66788 1.01803ZM5.15059 2.27498V4.22498H7.10059V2.27498H5.15059Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10059 8.8751C4.10059 8.37804 4.50353 7.9751 5.00059 7.9751H7.25059C7.74764 7.9751 8.15059 8.37804 8.15059 8.8751C8.15059 9.37215 7.74764 9.7751 7.25059 9.7751H5.00059C4.50353 9.7751 4.10059 9.37215 4.10059 8.8751Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10059 13.375C4.10059 12.8779 4.50353 12.475 5.00059 12.475H7.25059C7.74764 12.475 8.15059 12.8779 8.15059 13.375C8.15059 13.872 7.74764 14.275 7.25059 14.275H5.00059C4.50353 14.275 4.10059 13.872 4.10059 13.375Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.10059 17.875C4.10059 17.3779 4.50353 16.975 5.00059 16.975H7.25059C7.74764 16.975 8.15059 17.3779 8.15059 17.875C8.15059 18.372 7.74764 18.775 7.25059 18.775H5.00059C4.50353 18.775 4.10059 18.372 4.10059 17.875Z"
        fill={color}
      />
    </svg>
  );
};

OfficeIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default OfficeIcon;

import React from "react";
import styles from "./index.module.scss";

const Card = ({ item, onEdit, index, iconOnRight, documentStyles }) => {
  //card with short information about the signatory
  return (
    <div className={[styles["card"], styles["card__article"]].join(" ")}>
      <div className={styles["card__content"]}>
        <div className={styles["card__content__userinfo"]}>
          <div className={styles["card__content__img-wrapper"]}>
            {item?.image ? (
              <img src={item?.image} className={styles["card__content__img"]} />
            ) : (
              <span
                className={styles["card__content__initials"]}
                style={{ color: documentStyles?.color }}
              >
                {item?.initials}
              </span>
            )}
            {!index ? (
              <></>
            ) : (
              <div
                className={styles["card__content__index"]}
                style={{ backgroundColor: documentStyles?.color }}
              >
                {index}
              </div>
            )}
          </div>
          <div className={styles["card__content__info"]}>
            <span className={styles["card__content__title"]}>
              {`${item?.firstName}`} {`${item?.lastName}`}
            </span>
            <span className={styles["card__content__email"]}>
              {item?.email}
            </span>
          </div>
        </div>

        <div className={styles["card__content__edit"]} onClick={onEdit}>
          {iconOnRight}
        </div>
      </div>
    </div>
  );
};

export default Card;

import styles from './index.module.scss'
import { ListItemNode, ListNode } from '@lexical/list'
import { HeadingNode } from '@lexical/rich-text'
import { Editor } from './editor'
import { TemplateEditor } from './TemplateEditor'
import { TagNode } from './nodes/TagNode'
// import { TableNode } from './nodes/TableNode'
import {TableCellNode, TableNode, TableRowNode} from '@lexical/table';

import './theme/EditorTheme.css'

const defaultTheme = {
  comment: styles['comment'],
  editor: styles['editor'],
  list: {
    listitem: styles['editor-list'],
  },
  heading: {
    h1: styles['editor-title'],
    h2: styles['editor-headline'],
    h3: styles['editor-subheadline'],
  },
  paragraph: styles['editor-paragraph'],
  text: {
    bold: styles['editor-bold'],
    small: styles['editor-small'],
    italic: styles['editor-italic'],
  },
  table: 'PlaygroundEditorTheme__table',
  tableAddColumns: 'PlaygroundEditorTheme__tableAddColumns',
  tableAddRows: 'PlaygroundEditorTheme__tableAddRows',
  tableCell: 'PlaygroundEditorTheme__tableCell',
  tableCellActionButton: 'PlaygroundEditorTheme__tableCellActionButton',
  tableCellActionButtonContainer:
    'PlaygroundEditorTheme__tableCellActionButtonContainer',
  tableCellEditing: 'PlaygroundEditorTheme__tableCellEditing',
  tableCellHeader: 'PlaygroundEditorTheme__tableCellHeader',
  tableCellPrimarySelected: 'PlaygroundEditorTheme__tableCellPrimarySelected',
  tableCellResizer: 'PlaygroundEditorTheme__tableCellResizer',
  tableCellSelected: 'PlaygroundEditorTheme__tableCellSelected',
  tableCellSortedIndicator: 'PlaygroundEditorTheme__tableCellSortedIndicator',
  tableResizeRuler: 'PlaygroundEditorTheme__tableCellResizeRuler',
  tableSelected: 'PlaygroundEditorTheme__tableSelected',
  tableSelection: 'PlaygroundEditorTheme__tableSelection',
}

const defaultNodes = [
  ListNode,
  ListItemNode,
  HeadingNode,
  TagNode,
]

const tableNodes = [
  TableCellNode,
  TableRowNode,
  TableNode
]

function defaultOnError(error) {
  console.error(error);
  throw error
}

export { Editor, TemplateEditor, defaultTheme, defaultNodes, defaultOnError, tableNodes }
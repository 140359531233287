import React, { useEffect, useState, useMemo, useCallback } from "react";
import { notification, Spin } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";

import { MobileShare } from "../../components/Share/MobileShare";
import {
  Layout,
  RightPanel,
  HowCanWeHelp,
  CButton,
  Icons,
  HelpCenter,
} from "../../components";
import {
  setIndex,
  setAvailableHeaderStep,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  updateDocumentData,
  getDocument,
  setIsCreatingDocument,
} from "../../store/actions/prompts";
import { setRedirectParams } from "../../store/actions/auth";
import {
  availableHeaderStepSelector,
  documentSelector,
  newDocumentdataSelector,
  newSigneeDataSelector,
} from "../../store/selectors/prompts";
import { userSelector, tempUserSelector } from "../../store/selectors/auth";
import * as keys from "../../utils/keys";
import { useQuery } from "../../utils/helper";
import wizardStyles from "../../styles/Wizard.module.scss";
import styles from "./index.module.scss";
import { useDisclosure } from "../../hooks/helpers/useDisclosure";
import SuggestionsAndComments from "../../components/SuggestionsAndComments";
import useMediaQuery from "../../hooks/helpers/useMediaQuery";
import ReviewHeader from "../../components/ReviewInput/ReviewHeader";
import DocumentParties from "../../components/ReviewInput/DocumentParties";
import DocumentServiceDescription from "../../components/ReviewInput/DocumentServiceDescription";
import DocumentContent from "../../components/ReviewInput/DocumentContent";
import DocumentAttachments from "../../components/ReviewInput/DocumentAttachments";
import DocumentSignatures from "../../components/ReviewInput/DocumentSignatures";
import DocumentTitle from "../../components/ReviewInput/DocumentTitle";
import ContractRepository from "../../components/ReviewInput/ContractRepository";
import { CommentsProvider } from "../../contexts/CommentContext";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { initialDocumentViewerConfig } from "../../components/Lexical/document-viewer";
import { useDocumentPermission } from "../../hooks/permissions/document-permissions";
import { DOCUMENT_CURRENT_STEP } from "../../utils/documentCurrentStep";
import { CommentNode, NoHighlightCommentNode } from "../../components/Lexical/nodes/Comments";
import ToggleSuggestionsButton from "../../components/SuggestionsAndComments/components/ToggleSuggestionsButton";

let controller = new AbortController();

export const SHARE_TYPE = {
  COMMENT: "comment",
  SHARE: "share",
};

const ReviewInput = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const approverId = query?.get("approverId");
  const documentId = query?.get("documentId");
  const passcode = query?.get("passcode");

  const thisDocument = useSelector(documentSelector);
  const user = useSelector(userSelector);
  const { user: permissionUser, isOwner } = useDocumentPermission();
  // const userDocumentPermission = useSelector(selectUserDocumentPermission)
  const signeeData = useSelector(newSigneeDataSelector);
  const newDocumentData = useSelector(newDocumentdataSelector);
  const availableHeaderStep = useSelector(availableHeaderStepSelector);
  const tempUser = useSelector(tempUserSelector);

  const dispatch = useDispatch();
  const doSetIndex = (...args) => dispatch(setIndex(...args));
  const doShareUser = (...args) => dispatch(shareUser(...args));
  const doEditSharedUser = (...args) => dispatch(editSharedUser(...args));
  const doDeleteSharedUser = (...args) => dispatch(deleteSharedUser(...args));
  const doSetRedirectParams = (...args) => dispatch(setRedirectParams(...args));
  const doSetIsCreatingDocument = (...args) =>
    dispatch(setIsCreatingDocument(...args));

  const doSetAvailableHeaderStep = useCallback(
    (...args) => dispatch(setAvailableHeaderStep(...args)),
    [dispatch]
  );
  const doUpdateDocumentData = (...args) =>
    dispatch(updateDocumentData(...args));
  const doGetDocument = (...args) => dispatch(getDocument(...args));

  const [openRightPanel, setOpenPanelVisible] = useState(false); //a variable that indicates whether the help bar is open
  const [RightPanelComponent, setRightPanelComponent] = useState(null); //a variable that contains the element that is in the right pane

  const [documentStyles, setDocumentStyles] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [stylesForDocument, setStylesForDocument] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [downloadingpdf, setDownloadingpdf] = useState(false);

  const showDrawer = () => {
    setMobileMenu(true);
  };
  const onCloseDrawer = () => {
    setMobileMenu(false);
  };

  const showChildrenDrawer = (component) => {
    setChildrenDrawer(component);
  };
  const onChildrenDrawerClose = () => {
    setChildrenDrawer(null);
  };

  const isContractNotUploaded = useMemo(() => {
    return thisDocument?.status !== "UPLOADED";
  }, [thisDocument]);

  useEffect(() => {
    const currentInvintedUser = thisDocument?.users?.filter(
      (user) => !["owner", "signee"].includes(user.permission)
    );

    setInvitedUsers(currentInvintedUser);
  }, [thisDocument?.users]);

  useEffect(() => {
    if (passcode && documentId) {
      doGetDocument(documentId, passcode, true);
    }
  }, []);

  const currentShareUser = useMemo(() => {
    if (!user?.userID) {
      return null;
    }

    return thisDocument?.users?.find(
      (currentUser) =>
        currentUser?.userID && currentUser?.userID === user?.userID
    );
  }, [thisDocument?.users, user?.userID]);

  const shouldShowComments = useMemo(() => {
    const isSignee = permissionUser?.permission === "signee";
    const isCommenter = permissionUser?.permission === "comment";
    const isApprover = !!permissionUser?.approverID;
    return isContractNotUploaded && (isSignee || isCommenter || isOwner || isApprover);
  }, [permissionUser, isOwner, isContractNotUploaded])

  const canShare = useMemo(() => {
    if (!user?.userID) {
      return null;
    }

    return ["owner", "edit"].includes(currentShareUser?.permission);
  }, [currentShareUser?.permission, user?.userID]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  });

  useEffect(() => {
    if (availableHeaderStep === 0) {
      doSetAvailableHeaderStep(1);
    }
  }, [availableHeaderStep, doSetAvailableHeaderStep]);

  useEffect(() => {
    if (thisDocument) {
      if (thisDocument?.style?.isApplyToWizard) {
        setStylesForDocument(thisDocument?.style);
        addingHelpToTheContent(thisDocument?.style);
        setDocumentStyles(thisDocument?.style);

        const links = document.querySelectorAll("a");

        if (links && links?.length) {
          for (let i = 0; i < links.length; i++) {
            links[i].style.color = thisDocument?.style?.color;
          }
        }

        const numbers = document.querySelectorAll("h1 span");

        if (numbers && numbers.length) {
          for (let i = 0; i < numbers.length; i++) {
            numbers[i].style.backgroundColor = thisDocument?.style?.color;
          }
        }
      } else {
        setDocumentStyles(null);
      }
    }
  }, [thisDocument]);

  useEffect(() => {
    if (!isContractNotUploaded) {
      setIsPreview(true);
      return;
    }

    if (!canShare && !thisDocument?.signees?.length) {
      setIsSign(false);
      setIsPreview(true);
    } else {
      setIsSign(false);
      setIsPreview(false);
    }

    if (thisDocument && thisDocument?.signees?.length) {
      const isContractPreview = ["UPLOADED", "COMPLETED", "AWAITING"].includes(
        thisDocument?.status
      );

      //Always give priority to tempUser
      const userSigned = thisDocument?.signees?.find(
        (signee) => signee?.signeeID === (tempUser ?? user?.userID)
      );

      const SigneeToSign =
        thisDocument?.status !== "COMPLETED" &&
        userSigned &&
        userSigned?.status !== "SIGNED";

      const creatorNeedToSign = userSigned?.isSender && SigneeToSign;

      if (creatorNeedToSign) {
        setIsSign(true);
        setIsPreview(false);
      } else {
        if (SigneeToSign) {
          setIsPreview(false);
          setIsSign(true);
        } else if (location?.state?.isPreviw || isContractPreview) {
          setIsSign(false);
          setIsPreview(true);
        }
      }
    }
  }, [location, thisDocument, currentShareUser, canShare]);

  useEffect(() => {
    if (thisDocument?.language) {
      i18n.changeLanguage(thisDocument.language?.toLowerCase());
    }
  }, [thisDocument?.language, i18n]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp font={documentStyles?.font} />);
    setOpenPanelVisible(true);
  };

  const handleKeydown = (event) => {
    if (event.keyCode === 13 && !isPreview && !isSign && !event.altKey && !event.shiftKey && !isEditor) {
      event.preventDefault();

      navigate(keys.SIGN_SEND);
    }
  };

  const addingHelpToTheContent = (styles) => {
    const h1List = document.querySelectorAll("h1");

    for (let i = 1; i < h1List.length; i++) {
      const attr = h1List[i].attributes;

      const children = h1List[i].children;

      let alreadyHaveDiv = false;

      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeName === "DIV") {
          alreadyHaveDiv = true;
        }
      }

      if (attr["swiftsign-tip-ids"] && !alreadyHaveDiv) {
        const tipValues = attr["swiftsign-tip-ids"].value;

        let div = document.createElement("div");

        div.innerHTML = `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="https://www.w3.org/2000/svg">
          <path
            fill=${styles?.color || "#5f6cff"}
            d="M10.5 1.25C5.66797 1.25 1.75 5.16797 1.75 10C1.75 14.832 5.66797 18.75 10.5 18.75C15.332 18.75 19.25 14.832 19.25 10C19.25 5.16797 15.332 1.25 10.5 1.25ZM10.5 17.2656C6.48828 17.2656 3.23438 14.0117 3.23438 10C3.23438 5.98828 6.48828 2.73438 10.5 2.73438C14.5117 2.73438 17.7656 5.98828 17.7656 10C17.7656 14.0117 14.5117 17.2656 10.5 17.2656Z"
          />
          <path
            fill=${styles?.color || "#5f6cff"}
            d="M9.71875 14.2969C9.71875 14.5041 9.80106 14.7028 9.94757 14.8493C10.0941 14.9958 10.2928 15.0781 10.5 15.0781C10.7072 15.0781 10.9059 14.9958 11.0524 14.8493C11.1989 14.7028 11.2812 14.5041 11.2812 14.2969C11.2812 14.0897 11.1989 13.891 11.0524 13.7444C10.9059 13.5979 10.7072 13.5156 10.5 13.5156C10.2928 13.5156 10.0941 13.5979 9.94757 13.7444C9.80106 13.891 9.71875 14.0897 9.71875 14.2969ZM12.6797 6.18555C12.0937 5.67383 11.3203 5.39062 10.5 5.39062C9.67969 5.39062 8.90625 5.67188 8.32031 6.18555C7.71094 6.71875 7.375 7.43555 7.375 8.20312V8.35156C7.375 8.4375 7.44531 8.50781 7.53125 8.50781H8.46875C8.55469 8.50781 8.625 8.4375 8.625 8.35156V8.20312C8.625 7.3418 9.4668 6.64062 10.5 6.64062C11.5332 6.64062 12.375 7.3418 12.375 8.20312C12.375 8.81055 11.9453 9.36719 11.2812 9.62305C10.8672 9.78125 10.5156 10.0586 10.2637 10.4219C10.0059 10.793 9.875 11.2383 9.875 11.6895V12.1094C9.875 12.1953 9.94531 12.2656 10.0312 12.2656H10.9688C11.0547 12.2656 11.125 12.1953 11.125 12.1094V11.666C11.126 11.4764 11.1841 11.2915 11.2918 11.1354C11.3994 10.9793 11.5516 10.8593 11.7285 10.791C12.8809 10.3477 13.625 9.33203 13.625 8.20312C13.625 7.43555 13.2891 6.71875 12.6797 6.18555Z"
          />
        </svg> 
        <div style='color: ${styles?.color ? styles.color : "#5f6cff"}; ${
          styles?.font ? `font-family: ${styles?.font}` : ""
        }; margin-left: 5px; font-weight: 400; font-size: 16px; line-height: 24px; align-items: center; margin-right: 0px;'>${t(
          "wizard:info.help"
        )}</div>`;

        div.onclick = () => {
          onTipsPress(tipValues);
        };

        h1List[i].append(div);
      }
    }

    const olList = document.querySelectorAll("ol");

    for (let i = 0; i < olList.length; i++) {
      if (olList[i].type !== "a") {
        olList[i].style["list-style"] = "none";
      }

      if (olList[i].type === "i") {
        olList[i].style["list-style"] = "lower-roman";
      }
    }
  };

  const skippedValuesReview =
    thisDocument?.variables
      ?.find((variable) => variable?.key?.toLowerCase() === "skip")
      ?.value?.toLowerCase() === "nein";

  const skippedValues =
    thisDocument?.prompts
      ?.filter((prompt) =>
        prompt?.answerDataTypes?.find((value) => value?.target === "V_Skip")
      )?.[0]
      ?.answer?.string?.toLowerCase() === "nein";

  const onTipsPress = (tipsID) => {
    setRightPanelComponent(
      <HelpCenter
        data={tipsID.split(",")}
        allTips={thisDocument?.tips}
        font={documentStyles?.font}
      />
    );

    setOpenPanelVisible(true);
  };

  const onEditClick = (promptIds) => {
    navigate(keys.WIZARD, { state: { promptIds } });
  };

  useEffect(() => {
    if (downloadingpdf) {
      notification.info({
        message: `${t("share:create_pdf")} ${thisDocument?.fileTitle}.pdf`,
        description: "",
        duration: downloadingpdf,
        placement: "bottom",
        icon: (
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />
            }
          />
        ),
        closeIcon: (
          <span
            onClick={() => {
              controller.abort();
            }}
          >
            Cancel
          </span>
        ),
        className: "select-dropwdown-notification",
      });
    }
  }, [downloadingpdf, t, thisDocument]);

  const errorOnDownload = (message) => {
    notification.info({
      message: message,
      description: "",
      duration: 3,
      placement: "bottom",
      icon: <CloseOutlined style={{ color: "red", fontSize: 20, width: 24 }} />,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  const createPDF = async () => {
    setDownloadingpdf(true);
    api
      .get(
        `document/${thisDocument?.documentID}/pdf?passcode=${thisDocument?.passcode}`,
        {
          signal: controller.signal,
        }
      )
      .then((response) => {
        if (response?.data) {
          saveAs(
            response?.data,
            `${
              newDocumentData?.fileTitle
                ? newDocumentData?.fileTitle
                : thisDocument?.fileTitle
            }.pdf`
          );
        }
      })
      .catch((error) => {
        if (error.message === "canceled") {
          errorOnDownload(
            `${t("share:pdf_failed")} ${thisDocument?.fileTitle}.pdf`
          );
        } else {
          errorOnDownload(`${t("api:error")}`);
        }
      })
      .finally(() => {
        // Reset the state variable after the API call completes or is cancelled
        controller = new AbortController();
        setDownloadingpdf(false);
      });
  };

  const signDocument = (fromEmail = true) => {
    navigate(keys.SIGN, {
      state: { cameFromEmail: fromEmail, cameFromWorkspace: true },
    });
  };

  // const isMobile = useMediaQuery("(max-width: 1023px)");

  const {
    isOpen: isCommentsBlockOpen,
    onClose: onCommentsBlockClose,
    onToggle: onCommentsBlockToggle,
    onOpen: onCommentsBlockOpen,
  } = useDisclosure(false);

  const defaultShareOptions = [
    {
      value: "edit",
      label: t("share:edit_options:edit"),
      secondaryLabel: t("share:edit_options:edit_description"),
    },

    {
      value: "comment",
      label: t("share:edit_options:comment"),
      secondaryLabel: t("share:edit_options:comment_description"),
    },
    {
      value: "view",
      label: t("share:edit_options:view"),
      secondaryLabel: t("share:edit_options:view_description"),
    },
  ];

  const shareOptions = isContractNotUploaded
    ? defaultShareOptions.filter((option) => option.value !== "edit")
    : defaultShareOptions.filter((option) => option.value !== "comment");

  const saveAndContinue = !isPreview && !isSign;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/?shareCallback=true&documentId=${thisDocument?.documentID}&passcode=${thisDocument?.passcode}`
    );
    notification.info({
      message: t("share:link_copied"),
      description: t("share:link_description"),
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  useEffect(() => {
    if (!thisDocument?.documentID || 
      thisDocument?.currentStep === DOCUMENT_CURRENT_STEP.REVIEW || !isOwner) return;

    api.patch(`document/${thisDocument.documentID}`, {
      currentStep: DOCUMENT_CURRENT_STEP.REVIEW,
    });
  }, [thisDocument?.documentID]);

  const lexicalState = useMemo(() => {
    if (thisDocument?.lexicalState) {
      if (typeof thisDocument?.lexicalState === "string") {
        return thisDocument?.lexicalState;
      }

      return JSON.stringify(thisDocument?.lexicalState);
    }

    return undefined;
  }, [thisDocument]);

  const [isEditor, setIsEditor] = useState(false);

  const [isParseModalOpen, setIsParseModalOpen] = useState(false);
  const onSwitchChange = useCallback((val) => {
    if (val && !thisDocument?.hasLexicalTagsBeenParsed) {
      setIsParseModalOpen(true);
      return;
    }
    setIsEditor(val);
  }, [thisDocument]);

  const handleModalParseClose = useCallback(() => {
    setIsParseModalOpen(false);
  }, []);

  const shouldShowCommentEditSegmented = useMemo(() => {
    const generatedDocument = thisDocument?.type === "GENERATED";
    const global = thisDocument?.origin?.global;
    return isOwner && thisDocument?.status === "DRAFT" && generatedDocument && !global;
  }, [thisDocument, isOwner]);

  if (!thisDocument) {
    return null
  }

  return (
    <LexicalUpdater canComment={shouldShowComments}>
      <CommentsProvider>
        <Layout
          wrapperClass={styles["wrapper"]}
          header={
            <ReviewHeader
              {...{
                isPreview,
                isSign,
                onCommentsBlockToggle,
                documentStyles,
                createPDF,
                signDocument,
                shareOptions,
                invitedUsers,
                setInvitedUsers,
                canShare,
                currentShareUser,
                downloadingpdf,
                isContractNotUploaded,
                isCommentsBlockOpen,
                showDrawer,
                deleteSharedUser: doDeleteSharedUser,
                shareUser: doShareUser,
                editSharedUser: doEditSharedUser,
                approverId,
                doGetDocument,
                doSetRedirectParams,
                doSetIsCreatingDocument,
                onSwitchChange,
                switchValue: isEditor
              }}
            />
          }
          helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
          onQuestionButtonClick={() => openHowCanWeHelp()}
          questionIconColor={documentStyles?.color}
        >
          <div
            className={`${wizardStyles["c-wizard"]} ${
              isPreview || isSign ? styles["c-wizard__preview"] : ""
            }`}
          >
            <RightPanel
              children={RightPanelComponent}
              open={openRightPanel}
              onClose={() => setOpenPanelVisible(false)}
              closeIconColor={documentStyles?.color}
            />
            {isContractNotUploaded ? (
              <div className={styles["c-review"]} id="pdf">
                <div
                  className={`${styles["c-review__article"]} ${
                    isPreview
                      ? styles["c-review__article__preview"]
                      : isSign
                      ? styles["c-review__article__preview__sign"]
                      : ""
                  }`}
                  id="pdf"
                >
                  {!isPreview && !isSign && (
                    <div
                      className={styles["c-review__head"]}
                      style={{ fontFamily: documentStyles?.font }}
                    >
                      <h1>{t("review_input:review_title")}</h1>
                      <p>{t("review_input:please_review_document")}</p>
                      <div className={styles["c-review__preview-text"]}>
                        {t("review_input:preview")}
                      </div>
                    </div>
                  )}
                  <div className={styles["c-review-status__article"]}>
                    <div className={styles["c-review-status__prop"]}>
                      <DocumentTitle {...{ stylesForDocument }} />
                      <DocumentParties
                        {...{
                          stylesForDocument,
                          onTipsPress,
                          isPreview,
                          isSign,
                          onEditClick,
                        }}
                      />
                      {!skippedValues && !skippedValuesReview && (
                        <DocumentServiceDescription
                          {...{
                            stylesForDocument,
                            onTipsPress,
                            isPreview,
                            isSign,
                            onEditClick,
                          }}
                        />
                      )}
                    </div>
                    <DocumentContent
                      {...{
                        stylesForDocument,
                        lexicalState,
                        isEditor,
                        setIsEditor,
                        isParseModalOpen,
                        onParseModalClose: handleModalParseClose,
                        shouldShowComments
                      }}
                    />
                    <div className={styles["c-review-status__prop"]}>
                      <DocumentAttachments
                        {...{
                          stylesForDocument,
                          onTipsPress,
                        }}
                      />
                      <DocumentSignatures
                        {...{
                          stylesForDocument,
                          onTipsPress,
                          signeeData,
                        }}
                      />
                    </div>
                    {isSign && (
                      <div className={styles["c-review__sign-bottom-btn-wrapper"]}>
                        <CButton
                          onClick={() => {
                            signDocument();
                          }}
                          icon={<Icons.FeatherIcon width={17} height={17} />}
                          type="primary"
                          title={t("review_input:sign")}
                          customStyles={styles["c-review__bottom-btn"]}
                          backgroundColor={documentStyles?.color}
                          font={documentStyles?.font}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {isSign ? (
                  <>
                    <div className={styles["c-review__nav"]}>
                      {shouldShowComments && (
                        <ToggleSuggestionsButton
                          onToggle={() => {
                            onCommentsBlockOpen();
                            // showChildrenDrawer(SHARE_TYPE.COMMENT);
                          }}
                          currentShareUser
                          isContractNotUploaded={isContractNotUploaded}
                          isCommentsBlockOpen={isCommentsBlockOpen}
                        />
                      )}
                      <CButton
                        onClick={() => {
                          signDocument();
                        }}
                        icon={<Icons.FeatherIcon width={17} height={17} />}
                        type="primary"
                        title={t("review_input:sign")}
                        customStyles={styles["c-review__bottom-btn"]}
                        backgroundColor={documentStyles?.color}
                        font={documentStyles?.font}
                      />
                    </div>
                    <MobileShare
                      onSwitchEditor={onSwitchChange}
                      isEditor={isEditor}
                      canSwitchEditor={shouldShowCommentEditSegmented}
                      documentStyles={documentStyles}
                      mobileMenu={mobileMenu}
                      onClose={onCloseDrawer}
                      createPDF={createPDF}
                      thisDocument={thisDocument}
                      shareUser={doShareUser}
                      editSharedUser={doEditSharedUser}
                      deleteSharedUser={doDeleteSharedUser}
                      user={user}
                      shareOptions={shareOptions}
                      invitedUsers={invitedUsers}
                      setInvitedUsers={setInvitedUsers}
                      canShare={canShare}
                      currentShareUser={currentShareUser}
                      getDocument={doGetDocument}
                      childrenDrawer={childrenDrawer}
                      showChildrenDrawer={showChildrenDrawer}
                      onChildrenDrawerClose={onChildrenDrawerClose}
                      handleCopyLink={handleCopyLink}
                    >
                      <div></div>
                    </MobileShare>
                  </>
                ) : (
                  <div className={styles["c-review__nav"]}>
                    <MobileShare
                      onSwitchEditor={onSwitchChange}
                      isEditor={isEditor}
                      canSwitchEditor={shouldShowCommentEditSegmented}
                      documentStyles={documentStyles}
                      mobileMenu={mobileMenu}
                      onClose={onCloseDrawer}
                      createPDF={createPDF}
                      thisDocument={thisDocument}
                      shareUser={doShareUser}
                      editSharedUser={doEditSharedUser}
                      deleteSharedUser={doDeleteSharedUser}
                      user={user}
                      shareOptions={shareOptions}
                      invitedUsers={invitedUsers}
                      setInvitedUsers={setInvitedUsers}
                      canShare={canShare}
                      currentShareUser={currentShareUser}
                      getDocument={doGetDocument}
                      childrenDrawer={childrenDrawer}
                      showChildrenDrawer={showChildrenDrawer}
                      onChildrenDrawerClose={onChildrenDrawerClose}
                      handleCopyLink={handleCopyLink}
                    >
                      {shouldShowComments && (
                        <ToggleSuggestionsButton
                          onToggle={() => {
                            onCommentsBlockOpen();
                            // showChildrenDrawer(SHARE_TYPE.COMMENT);
                          }}
                          currentShareUser
                          isContractNotUploaded={isContractNotUploaded}
                          isCommentsBlockOpen={isCommentsBlockOpen}
                        />
                      )}
                      {saveAndContinue ? (
                        <CButton
                          onClick={() => {
                            navigate(keys.SIGN_SEND);
                            doSetIsCreatingDocument(true);
                          }}
                          icon={<Icons.FeatherIcon width={17} height={17} />}
                          type="primary"
                          title={t("wizard:buttons.save_and_continue")}
                          backgroundColor={documentStyles?.color}
                          font={documentStyles?.font}
                        />
                      ) : (
                        <CButton
                          type="primary"
                          title={t("share:share")}
                          onClick={() =>
                            canShare || currentShareUser
                              ? showChildrenDrawer(SHARE_TYPE.SHARE)
                              : handleCopyLink()
                          }
                          customStyles={styles["btn-export-as-pdf"]}
                          backgroundColor={documentStyles?.color}
                          font={documentStyles?.font}
                        />
                      )}
                    </MobileShare>
                  </div>
                )}
                <SuggestionsAndComments
                  isOpen={isCommentsBlockOpen}
                  onClose={onCommentsBlockClose}
                  onOpen={onCommentsBlockOpen}
                  {...{ thisDocument, isSign, isPreview, currentShareUser, shouldShowComments }}
                />
              </div>
            ) : (
              <>
                <ContractRepository
                  canShare={canShare}
                  onClose={onCommentsBlockClose}
                  thisDocument={thisDocument}
                  updateDocumentData={doUpdateDocumentData}
                  isOpen={isCommentsBlockOpen}
                  user={user}
                  getDocument={doGetDocument}
                />
                <div className={styles["c-review__nav"]}>
                  <MobileShare
                    shouldShow
                    documentStyles={documentStyles}
                    mobileMenu={mobileMenu}
                    onClose={onCloseDrawer}
                    createPDF={createPDF}
                    thisDocument={thisDocument}
                    shareUser={doShareUser}
                    editSharedUser={doEditSharedUser}
                    deleteSharedUser={doDeleteSharedUser}
                    user={user}
                    shareOptions={shareOptions}
                    invitedUsers={invitedUsers}
                    setInvitedUsers={setInvitedUsers}
                    canShare={canShare}
                    currentShareUser={currentShareUser}
                    uploadedContract={true}
                    updateDocumentData={doUpdateDocumentData}
                    getDocument={doGetDocument}
                    childrenDrawer={childrenDrawer}
                    showChildrenDrawer={showChildrenDrawer}
                    onChildrenDrawerClose={onChildrenDrawerClose}
                    handleCopyLink={handleCopyLink}
                  >
                    <CButton
                      type="primary"
                      title={t("share:share")}
                      onClick={() => showChildrenDrawer(SHARE_TYPE.SHARE)}
                      customStyles={styles["btn-export-as-pdf"]}
                      backgroundColor={documentStyles?.color}
                      font={documentStyles?.font}
                    />
                  </MobileShare>
                </div>
              </>
            )}
          </div>
        </Layout>
        <div id="confirm-parse-modal"></div>
      </CommentsProvider>
    </LexicalUpdater>
  );
};

function LexicalUpdater({ canComment, children }) {
  return canComment ? (
    <LexicalComposer initialConfig={{
      ...initialDocumentViewerConfig,
      namespace: initialDocumentViewerConfig.namespace,
      nodes: [...initialDocumentViewerConfig.nodes, CommentNode],
      editable: true
    }}>
      {children}
    </LexicalComposer>
  ) : (
    <LexicalComposer initialConfig={{
      ...initialDocumentViewerConfig,
      namespace: "DocumentViewer",
      nodes: [...initialDocumentViewerConfig.nodes, NoHighlightCommentNode],
      editable: true
    }}>
      {children}
    </LexicalComposer>
  )
}

export default ReviewInput;
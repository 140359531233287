import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CButton, Icons } from "..";
import styles from "./index.module.scss";

//a form to confirm that, all party details are entered correctly
const ConfirmSendContract = ({
  onConfirm = () => {},
  onBreakClick = () => {},
}) => {
  const { t } = useTranslation();

  const [phase, setPhase] = useState(1);
  const [timer, setTimer] = useState();
  const [error, setError] = useState("");

  function absorbEvent_(event) {
    const e = event || window.event;
    e.preventDefault && e.preventDefault();
    e.stopPropagation && e.stopPropagation();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  const startAnimation = (event) => {
    // absorbEvent_(event);
    //function that activates button filling
    if (phase === 1) {
      setPhase(2); //go to phase 2
      setTimer(
        setTimeout(() => {
          //start the timer for 2 seconds, then go to phase 3
          setPhase(3);
          window.navigator.vibrate(500);
        }, 2000)
      );
      event.stopPropagation();
    }
  };

  const endAnimation = (event) => {
    // absorbEvent_(event);
    //button animation completion function
    if (phase === 2) {
      //if it is still not phase 3
      setPhase(1); //return to phase 1
      clearTimeout(timer); //clear timer
      setError(t("sign:hold_to_sign")); //display a hint that you need to hold the button for 2 seconds
      setTimeout(() => {
        setError("");
      }, 10000); //remove the error after 10 seconds
    } else if (phase === 3) {
      //if it is already phase 3 call the confirmation function
      onConfirm();
    }
  };

  return (
    <div className={styles["confirm-contract"]}>
      <h1>{t("contract:sign_and_send")}</h1>
      <div className={styles["confirm-contract__small-text"]}>
        The document will be signed by you and sent to the subsequent signee.
      </div>
      <div className={styles["confirm-contract__small-text"]}>
        By signing this document, you agree to its terms and understand that it
        will be legally binding.
      </div>

      <div className={styles["confirm-contract__buttons"]}>
        <div className={styles["confirm-contract__confirm"]}>
          <div
            className={[
              (phase === 3 || phase === 1) &&
                styles["background-color__active"],
              styles[`confirm-contract__animated-button`],
            ].join(" ")}
            onMouseDown={startAnimation}
            onTouchStart={startAnimation}
            onMouseUp={endAnimation}
            onTouchEnd={endAnimation}
          >
            <Icons.TickIcon />
            {phase === 1
              ? t("other:long_press_to_sign")
              : phase === 2
              ? t("other:signing")
              : `${t("other:signed")}!`}
          </div>

          {error && (
            <div className={styles["confirm-contract__error"]}>{error}</div>
          )}
        </div>

        <CButton
          type="reverse-primary"
          icon={<Icons.CrossIcon />}
          title="I have changed my mind"
          onClick={() => {
            onBreakClick();
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmSendContract;

import { useCallback } from "react";
import { DropDown, DropDownItem, dropDownActiveClass } from "./Dropdown";
import { $getSelection, $isRangeSelection } from "lexical";
import { $patchStyleText } from "@lexical/selection";
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import styles from '../index.module.scss'

const FONT_SIZE_OPTIONS = [
  ['10px', '10'],
  ['12px', '12'],
  ['16px', '16'],
  ['18px', '18'],
  ['24px', '24'],
  ['28px', '28'],
  ['32px', '32'],
  ['36px', '36'],
  ['48px', '48'],
  ['80px', '80'],
];

export function FontSizeDropDown({
  editor,
  value,
  disabled = false,
}){
  const handleClick = useCallback(
    (option) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            'font-size': option,
            'line-height': option,
          });
        }
      });
    },
    [editor],
  );

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={styles["popup-item"]}
      buttonLabel={value.replace('px', '')}
      buttonAriaLabel="Formatting options for font size"
      Icon={(props) => (
        <i 
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          {...props}
        >
          <UpOutlined style={{ fontSize: 10 }}/>
          <DownOutlined style={{ fontSize: 10 }}/>
        </i>
      )}
    >
      {FONT_SIZE_OPTIONS.map(
        ([option, text]) => (
          <DropDownItem
            className={`${styles["item"]} ${dropDownActiveClass(value === option)}`}
            onClick={() => handleClick(option)}
            key={option}>
            <span className={styles["text"]}>{text}</span>
          </DropDownItem>
        ),
      )}
    </DropDown>
  );
}

export function format(number) {
  if (number) {
    if (typeof number === "number") {
      return number.toFixed(2);
    }

    const value = number
      .split("")
      .reverse()
      .join("")
      .replace(/(\d{1,2})(\d{1,3})?(\d{1,3})?(\d{1,3})?/, "$1.$2$3$4")
      .split("")
      .reverse()
      .join("");

    return value;
  } else {
    return undefined;
  }
}

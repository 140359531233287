import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { notification, Button } from "antd";

import { Layout, Header, RightPanel, HowCanWeHelp } from "../../../components";

import {
  accountsSelector,
  userSelector,
  workspaceSelector,
} from "../../../store/selectors/auth";
import {
  changeAccountData,
  sendWorkspaceInvite,
  setCurrentWorkspace,
} from "../../../store/actions/auth";

import * as keys from "../../../utils/keys";

import styles from "../index.module.scss";
import { EMAIL } from "../../../utils/regularExpression";
import { useTranslation } from "react-i18next";
import CreateComponent from "./CreateComponent";

const CreateWorkspace = ({
  accounts,
  changeAccountData,
  user,
  workspace,
  sendWorkspaceInvite,
  setCurrentWorkspace,
}) => {
  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [workSpaceMembers, setWorkSpaceMembers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserCreator, setIsUserCreator] = useState(false);
  const [numberOfInputForFocus, setNumberOfInputForFocus] = useState(1);
  const [workspaceName, setWorkspaceName] = useState("");
  const [workspaceInitials, setWorkspaceInitials] = useState("");
  const [createdWorkspace, setCreatedWorkspace] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [invitedEmails, setInvitedEmails] = useState([]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const apiError = () => {
    return notification.info({
      message: `❌ ${t("api:error")}`,
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  useEffect(() => {
    if (accounts?.length && user) {
      //find existing workspace
      const existingWorkspace = accounts?.find(
        (account) => account?.accountID === workspace
      );

      //If user was deleted from workspace but it still is in local storage. Defaults to first found account that has the userID
      if (!existingWorkspace) {
        const firstAccountWithUserId = accounts.find((account) =>
          account.users.some((item) => item.userID === user?.userID)
        );

        const { isUserOwner, isUserAdmin, isUserCreator } = findUserInWorkspace(
          firstAccountWithUserId,
          user.userID
        );

        dispatch(setCurrentWorkspace(firstAccountWithUserId?.accountID));
        setWorkSpaceMembers(firstAccountWithUserId?.users);
        setIsUserOwner(isUserOwner);
        setIsUserAdmin(isUserAdmin);
        setIsUserCreator(isUserCreator);
      } else {
        setWorkSpaceMembers(existingWorkspace?.users);
        const { isUserOwner, isUserAdmin, isUserCreator } = findUserInWorkspace(
          existingWorkspace,
          user.userID
        );

        setIsUserOwner(isUserOwner);
        setIsUserAdmin(isUserAdmin);
        setIsUserCreator(isUserCreator);
      }
    }
  }, [accounts, user, workspace]);

  function findUserInWorkspace(workspace, userID) {
    const foundUser = workspace?.users.find(
      (member) => member.userID === userID
    );
    const isUserOwner = foundUser?.permission === "OWNER";
    const isUserAdmin = foundUser?.permission === "ADMIN";
    const isUserCreator = workspace?.createdBy === userID;

    return { isUserOwner, isUserAdmin, isUserCreator };
  }

  const changePermission = (changedMemberId, permission) => {
    // Create a new array with the updated values
    const newMembersArr = workSpaceMembers.map((item) => {
      if (item?.userID === changedMemberId) {
        return {
          ...item,
          permission: permission.toUpperCase(),
        };
      }

      return item;
    });

    changeAccountData({ users: newMembersArr }, (isSuccess, res) => {
      if (res.type === "ERROR") {
        apiError();
      } else {
        const changedMember = newMembersArr.find(
          (member) => member?.userID === changedMemberId
        );
        const changedName = changedMember?.firstName
          ? `${changedMember?.firstName} ${changedMember?.lastName}`
          : changedMember?.email;
        notification.info({
          message: `💪 Success! ${changedName} is now a ${changedMember?.permission.toLowerCase()}.`,
          duration: 3,
          placement: "bottom",
          icon: <></>,
          closeIcon: <></>,
          className: "select-dropwdown-notification",
        });
      }
    });
  };

  const checkUserEmail = () => {
    if (!!newUserEmail) {
      const regexMonth = new RegExp(EMAIL);
      const result = regexMonth.test(newUserEmail.trim());

      return !result;
    }

    return true;
  };

  const handleInputChange = (event) => {
    const inputString = event.target.value;
    setWorkspaceName(inputString);

    const words = inputString.split(" ");
    if (words.length >= 2) {
      setWorkspaceInitials(
        `${words[0].charAt(0).toUpperCase()}${words[1].charAt(0).toUpperCase()}`
      );
    } else if (words.length === 1) {
      setWorkspaceInitials(words[0].charAt(0).toUpperCase());
    } else {
      setWorkspaceInitials("");
    }
  };

  const handleTextAreaChange = (event) => {
    const inputString = event.target.value;

    if (inputString.trim().endsWith(",")) {
      setInputValue(inputString);
    } else {
      const stringWithCommas = inputString.replace(/\s+$/, ", ");
      setInputValue(stringWithCommas);
    }

    let wordArray = inputString.split(",");

    wordArray = wordArray
      .map((str) => str.replace(/,/g, "").trim())
      .filter((str) => str !== "");

    let splitIndex = wordArray.findIndex((str) => str.includes(" ")); // find the index of the first string containing a space character
    if (splitIndex !== -1) {
      let splitValues = wordArray[splitIndex].split(" "); // split the string into multiple values based on a space character
      wordArray.splice(splitIndex, 1, ...splitValues); // remove the original string and insert the split values back into the array
    }

    const removedSameValues = wordArray.filter((value, index, self) => {
      if (wordArray.indexOf(value) !== index) {
        notification.destroy();
        notification.info({
          message: `Duplicate email ${value}!`,
          duration: 3,
          placement: "bottom",
          icon: <></>,
          closeIcon: <></>,
          className: "select-dropwdown-notification",
        });
      }
      return self.indexOf(value) === index;
    });

    setInvitedEmails(removedSameValues);
  };

  const handleAddWorkspaceMember = () => {
    const newMembersArr = [...workSpaceMembers, { email: newUserEmail }];
    changeAccountData(
      {
        users: newMembersArr,
      },
      (isSuccess, res) => {
        if (res.type === "ERROR") {
          apiError();
        } else {
          const newMember = newMembersArr.find(
            (member) => member?.email === newUserEmail
          );
          const changedName = newMember?.firstName
            ? `${newMember?.firstName} ${newMember?.lastName}`
            : newMember?.email;

          setNewUserEmail("");
          notification.info({
            message: `💪 Success! ${changedName} is now a member of your workspace.`,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      }
    );
  };

  const handleAddWorkspaceName = () => {
    setCreatedWorkspace(true);
  };

  const handleDeleteWorkspaceMember = (deletedEmail, message) => {
    const changedWorkSpaceMembers = workSpaceMembers.filter(
      (item) => item.email !== deletedEmail
    );

    changeAccountData(
      {
        users: changedWorkSpaceMembers,
      },
      (isSuccess, res) => {
        if (res.type === "ERROR") {
          apiError();
        } else {
          notification.info({
            message: message,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      }
    );
  };

  const handleMenuClick = (e, email, accountID, userID) => {
    switch (e.key) {
      case "resend":
        sendWorkspaceInvite(accountID, userID, (isSuccess, res) => {
          if (res.type === "ERROR") {
            apiError();
          } else {
            notification.info({
              message: `You have resend the invitation to ${email}.`,
              duration: 3,
              placement: "bottom",
              icon: <></>,
              closeIcon: <></>,
              className: "select-dropwdown-notification",
            });
          }
        });

        break;
      case "revoke":
        handleDeleteWorkspaceMember(
          email,
          `You have revoked the invitation to ${email}.`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Layout
      header={
        <Header
          title={createdWorkspace ? "Invite others" : "Create a new workspace"}
          shortTitle={
            createdWorkspace ? "Invite others" : "Create a new workspace"
          }
          right={
            <Link to={keys.DASHBOARD}>
              <Button type="ghost">
                {createdWorkspace ? "Skip for now" : "Cancel"}
              </Button>
            </Link>
          }
          hideLogo={true}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-workspace"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-workspace__section"]}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                background: "white",
                minWidth: "720px",
                minHeight: "508px",
                padding: "32px 24px",
                borderRadius: "24px",
                boxShadow: "0px 4px 12px 0px #EBEBEB 0px 0px 1px 0px #EEEEEF",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CreateComponent
                {...{
                  workspaceInitials,
                  handleAddWorkspaceName,
                  setNumberOfInputForFocus,
                  numberOfInputForFocus,
                  handleInputChange,
                  createdWorkspace,
                  inputValue,
                  handleTextAreaChange,
                  invitedEmails,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  accounts: accountsSelector(state),
  user: userSelector(state),
  workspace: workspaceSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { changeAccountData, sendWorkspaceInvite, setCurrentWorkspace },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkspace);

import React from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import documentSignature from "../../utils/document-signature.json";

import { Icons } from "..";

import {
  setErrorLoadingScreenType,
  setRedirectParams,
} from "../../store/actions/auth";

import RecentlyEditedUrl from "../../assets/images/recently-edited.svg";

import * as keys from "../../utils/keys";

import styles from "./index.module.scss";

const LoadErrorScreens = ({ type = "" }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const images = [1, 2, 3];
  const randomImage = images[Math.floor(Math.random() * images.length)];

  return type ? (
    <div className={styles["load-error-screens"]}>
      {type === "load" && (
        <div className={styles["screen"]}>
          <div className={`${styles["figure"]} ${styles["lottie"]}`}>
            <Lottie
              animationData={documentSignature}
              style={{ width: 180, height: 180 }}
            />
          </div>
          <div className={styles["h1"]}>Loading…</div>
          <div className={styles["desctiption"]}>
            This might take a few seconds.
          </div>
        </div>
      )}

      {type === "error" && (
        <div className={styles["screen"]}>
          <div className={styles["figure-error"]}>
            <img src={`/dog-${randomImage}.png`} alt="dog" />
          </div>
          <div className={styles["h1"]}>
            Sorry something went <br /> wrong on our end.
          </div>
          <div>Please go back and try again.</div>
          <button
            className={styles["btn"]}
            onClick={() => {
              navigate(keys.DASHBOARD);
              dispatch(setErrorLoadingScreenType(""));
              dispatch(setRedirectParams(null));
            }}
          >
            Back to Workspace
          </button>
        </div>
      )}

      {type === "edit" && (
        <div className={styles["screen"]}>
          <div className={styles["figure"]}>
            <img src={RecentlyEditedUrl} alt="recently edite logo" />
          </div>
          <div className={styles["h1"]}>No recent edits at the moment</div>
          <div className={styles["desctiption"]}>
            Create or edit documents and come back here
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default LoadErrorScreens;

const sendMailByApp = (subject) => {
  window.location.href = `mailto:feedback@swiftsign.io?subject=${subject}`;
};

const getSteppers = (step, item) => {
  let status = "";
  if (step > item) {
    status = "completed";
  }
  if (step < item) {
    status = "incomplete";
  }
  if (step === item) {
    status = "current";
  }
  return status;
};

const validateField = (event, regularExpression, previousValue, valueName) => {
  if (previousValue !== undefined || event !== undefined) {
    const value =
      event === undefined
        ? previousValue.filter((item) =>
            item.name[0].includes(`${valueName}`)
          )[0]?.value
        : event.target.value;
    const regexMonth = new RegExp(regularExpression); //creating a new regular expression
    const result = regexMonth.test(value); //check the data using a regular expression
    return result;
  } else {
    return true;
  }
};

const arrayOfParties = (prompt) => {
  return prompt
    ?.filter((item) => item.shortPromptName === "Details")
    .map((item) => item.answer.string || item.answer.contact)
    .map((item) => {
      if (typeof item === "object") {
        return `${item.firstName}, ${item.lastName}`;
      } else {
        return `${item}`;
      }
    });
};

const getPartyID = (parties, partyName) => {
  const [firstName, lastName] = partyName.split(", ");
  let result = "";

  if (lastName === undefined) {
    result = parties
      ?.filter((item) => item?.type === "Company")
      ?.find((item) => item?.contact?.organizationName === firstName)?.partyID;
  } else {
    result = parties
      ?.filter((item) => item?.type === "Person")
      ?.find(
        (item) =>
          item?.contact?.firstName === firstName &&
          item?.contact?.lastName === lastName
      )?.partyID;
  }

  return result;
};

const entities = [
  "GbR",
  "KG",
  "AG & Co. KG",
  "GmbH & Co. KG",
  "Limited & Co. KG",
  "Stiftung & Co. KG",
  "Stiftung GmbH & Co. KG",
  "UG (haftungsbeschränkt) & Co. KG",
  "OHG",
  "GmbH & Co. OHG",
  "AG & Co. OHG",
  "Partenreederei",
  "PartG",
  "PartG mbB",
  "Stille Gesellschaft",
  "AG",
  "gAG",
  "GmbH",
  "gGmbH",
  "InvAG",
  "KGaA",
  "AG & Co. KGaA",
  "SE & Co. KGaA",
  "GmbH & Co. KGaA",
  "Stiftung & Co. KGaA",
  "REIT-AG",
  "UG (haftungsbeschränkt)",
  "AöR",
  "eG",
  "Eigenbetrieb",
  "Einzelunternehmen",
  "e. V.",
  "KöR",
  "Regiebetrieb",
  "Stiftung",
  "VVaG",
  "EWIV",
  "SE",
  "SCE",
  "LLP",
];

const fonts = [
  "Open Sans",
  "Lato",
  "Roboto",
  "Work Sans",
  "Georgia",
  "Verdana",
  "Arial",
  "Helvetica",
  "Basier Circle",
];

const currency = ["€", "£", "US$", "CHF"];

const validateWithRegularExpression = (regularExpression, value) => {
  const regexMonth = new RegExp(regularExpression);
  const result = regexMonth.test(value);

  return result;
};

const useQuery = (string) => {
  return new URLSearchParams(string ? string : window.location.search);
};

const jwtDecoder = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export {
  sendMailByApp,
  getSteppers,
  validateField,
  arrayOfParties,
  getPartyID,
  entities,
  fonts,
  currency,
  validateWithRegularExpression,
  useQuery,
  jwtDecoder,
};

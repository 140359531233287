import React, { useState, useRef, useEffect } from "react";
import { Tooltip, notification } from "antd";
import moment from "moment";
import { useDrag } from "react-dnd";
import { useDoubleTap } from "use-double-tap";
import { usePopper } from "react-popper";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../components";

import * as keys from "../../utils/keys";

import stylesCard from "./index.module.scss";
import { api } from "../../services/api";
import { returnStatusTooltip, statusDetect } from "../../utils/DocumentStatus";

const WorkspaceTemplateCard = React.forwardRef((props, ref) => {
  const {
    item,
    handleDelete,
    isDropdownActive = false,
    onOptionsPress = () => {},
    setNowEditing,
    nowEditing,
    isLastCard,
    setTemplates,
    onUseTemplate,
  } = props;
  const inputRef = useRef(null);

  const navigate = useNavigate();

  const [editedValue, setEditedValue] = useState(null);
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);

  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [10, 12],
        },
      },
    ],
  });

  useEffect(() => {
    if (nowEditing !== item.templateID) {
      handleDetectEnter(null, true);
    } else {
      handleStartEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowEditing]);

  const [, drag] = useDrag(() => ({
    type: "any",
    item: { name: item.templateID },
  }));

  const handleDropdown = () => {
    onOptionsPress();
  };

  const bindDoubleClick = useDoubleTap(
    () => {
      setNowEditing(item?.templateID);
    },
    300,
    {
      onSingleTap: (event) => {
        handleClickItem(event);
      },
    }
  );

  const handleStartEdit = () => {
    setEditedValue(inputRef.current.value);

    const target = inputRef.current;
    target.focus();
    target.readOnly = false;
    target.classList.add(stylesCard["edit-name"]);
  };

  const handleDetectEnter = async (e, isForce = false) => {
    const target = inputRef.current;

    if (isForce) {
      target.readOnly = true;
      target.classList.remove(stylesCard["edit-name"]);

      if (editedValue && editedValue !== item.fileTitle) {
        api
          .patch(`template/${item.templateID}`, {
            fileTitle: editedValue,
          })
          .then(() => {
            setTemplates((old) =>
              old.map((i) => {
                if (i.templateID === item.templateID) {
                  return {
                    ...i,
                    fileTitle: editedValue,
                  };
                }

                return i;
              })
            );
            notification.info({
              message: "Template updated!",
              description: `Template "${editedValue}" successfully updated.`,
              duration: 3,
              placement: "bottom",
              icon: (
                <Icons.StatusAcceptedIconWithWhiteBackground
                  color={"#42B983"}
                />
              ),
              closeIcon: <></>,
              bottom: 0,
            });
            setEditedValue(null);
          })
          .catch(() => {
            setEditedValue(null);
            notification.info({
              message: "Template not updated!",
              description:
                "There was an error while trying to update the template.",
              duration: 3,
              placement: "bottom",
              icon: (
                <Icons.WarningFillIconWithWhiteBackground
                  color={"#E53935"}
                  width={"22"}
                  height={"20"}
                />
              ),
              closeIcon: <></>,
              bottom: 0,
            });
          });
      } else {
        setEditedValue(null);
      }

      return;
    }

    if (e?.key === "Enter" || e?.keyCode === 13) {
      target.readOnly = true;
      target.classList.remove(stylesCard["edit-name"]);

      if (editedValue && editedValue !== item.fileTitle) {
        setNowEditing(null);
      }
    }
  };

  function handleClickItem(e) {
    if (nowEditing) return;
    navigate(`${keys.TEMPLATE}/${item.templateID}`);
  }

  function handleDuplicate() {
    api
      .post(
        "/template",
        {
          templateID: item.templateID,
          language: item.language,
        },
        {
          params: {
            origin: "DUPLICATE",
          },
        }
      )
      .then((res) => {
        setTemplates((old) => [res.data.templates, ...old]);
        notification.info({
          message: "Template duplicated!",
          description: `Template "${item.fileTitle}" successfully duplicated.`,
          duration: 3,
          placement: "bottom",
          icon: (
            <Icons.StatusAcceptedIconWithWhiteBackground color={"#42B983"} />
          ),
          closeIcon: <></>,
          bottom: 0,
        });
      })
      .catch(() => {
        notification.info({
          message: "Template not duplicated!",
          description:
            "There was an error while trying to duplicate the template.",
          duration: 3,
          placement: "bottom",
          icon: (
            <Icons.WarningFillIconWithWhiteBackground
              color={"#E53935"}
              width={"22"}
              height={"20"}
            />
          ),
          closeIcon: <></>,
          bottom: 0,
        });
      });
  }

  return (
    <div
      ref={drag}
      className={isLastCard ? stylesCard["workspace-card__last-card"] : ""}
    >
      <span
        className={stylesCard["workspace-card"]}
        ref={ref}
        id={item.templateID}
      >
        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-title"]}`}
        >
          <div className={stylesCard["title"]} {...bindDoubleClick}>
            <input
              type="text"
              className={stylesCard["title__input"]}
              readOnly={true}
              ref={inputRef}
              value={editedValue !== null ? editedValue : item?.fileTitle}
              onKeyDown={(e) => handleDetectEnter(e, false)}
              onChange={(e) => {
                setEditedValue(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-last-update"]}`}
          onClick={handleClickItem}
        >
          <div className={stylesCard["last-update"]}>
            {moment(item?.updatedAt).format("MMM DD, YY")}
          </div>
        </div>

        <div
          onClick={handleClickItem}
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-status"]}`}
        >
          <Tooltip
            title={returnStatusTooltip(item?.status)}
            overlayClassName={"u-tooltip"}
          >
            <div
              className={`${stylesCard["status"]} ${stylesCard[item?.status]}`}
            >
              {statusDetect(item?.status)}
            </div>
          </Tooltip>
        </div>

        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-parties"]} ${stylesCard["w-350"]}`}
        ></div>

        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-action"]}`}
        >
          <div
            ref={setSelectRef}
            onClick={() => handleDropdown()}
            className={stylesCard["action"]}
          >
            <Icons.MoreIcon />
          </div>
        </div>

        <div
          ref={setSelectPop}
          style={{
            ...styles.popper,
          }}
          {...attributes.popper}
          className={`u-folder__dropdown ${isDropdownActive ? "active" : ""}`}
        >
          <div className={"u-folder__dropdown__row"}>
            {item?.status?.toUpperCase() === "PUBLISHED" && (
              <div className={"u-folder__dropdown__item"}>
                <div
                  className={"u-folder__dropdown__link"}
                  onClick={onUseTemplate}
                >
                  <i>
                    <Icons.Plus />
                  </i>
                  <div className={"text"}>Use template</div>
                </div>
              </div>
            )}
            <div className={"u-folder__dropdown__item"}>
              <div
                onClick={() => setNowEditing(item.templateID)}
                className={"u-folder__dropdown__link"}
              >
                <i>
                  <Icons.RenameIcon />
                </i>
                <div className={"text"}>Rename</div>
              </div>
            </div>
            <div className={"u-folder__dropdown__item"}>
              <div
                onClick={handleClickItem}
                className={"u-folder__dropdown__link"}
              >
                <i>
                  <Icons.EditFile />
                </i>
                <div className={"text"}>Edit template</div>
              </div>
            </div>
            <div className={"u-folder__dropdown__item"}>
              <div
                className={"u-folder__dropdown__link"}
                onClick={handleDuplicate}
              >
                <i>
                  <Icons.Duplicate />
                </i>
                <div className={"text"}>Duplicate</div>
              </div>
            </div>
            <div className={"u-folder__dropdown__item"}>
              <div
                onClick={handleDelete}
                className={"u-folder__dropdown__link"}
              >
                <i>
                  <Icons.DeleteIcon />
                </i>
                <div className={"text"}>Delete</div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
});

export default WorkspaceTemplateCard;

import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { SelectDropdown } from "../../SelectDropdown";
import "../index.scss";

export const InputWithSelect = forwardRef(
  (
    {
      setFocused,
      permission,
      setPermission,
      shareOptions,
      disabled,
      focused,
      handleValuesChange, // function to handle Input value change
      handlePressEnter, // function to handle on enter key press
      handleBlur, // function to handle on blur event
      signee, // value of input
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <div
        className={
          focused ? "invite-share-input-focused" : "invite-share-input"
        }
      >
        <Input
          ref={ref}
          value={signee}
          onClick={() => setFocused(1)}
          type="email"
          placeholder={t("share:input_email")}
          className="share-email-input"
          bordered={false}
          inputMode="email"
          autoComplete="email"
          onChange={handleValuesChange}
          onKeyPress={handlePressEnter}
          onBlur={handleBlur}
        />

        {!disabled && (
          <SelectDropdown
            isDropdown
            dropdownText={t(`share:edit_options:${permission.toLowerCase()}`)}
            options={shareOptions}
            setFocused={setFocused}
            onSelectFunction={setPermission}
          />
        )}
      </div>
    );
  }
);

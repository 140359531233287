import React, { useEffect, useCallback, useState, useRef } from "react";
import { Space } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { api } from "../../../../services/api";

export const RenderDocument = ({ thisDocument, loaded, setLoaded }) => {
  const [numPages, setNumPages] = useState(null);
  const [errorLoading, setErrorLoading] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfPage, setPdfPage] = useState({});

  const createPDF = async () => {
    try {
      const response = await api.get(
        `document/${thisDocument?.documentID}/file`
      );

      setPdfFile(response?.data);
      setErrorLoading(null); // reset error if pdfFile is valid
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    createPDF();
  }, [thisDocument?.documentID]);

  const onLoadError = (err) => {
    console.log(err, "er");
    setErrorLoading(true);
  };

  function onLoadSuccessPage(page) {
    if (page.pageNumber === 1) {
      setPdfPage({
        width: page.width,
        height: page.height,
      });
    }
  }

  function onDocumentLoadSuccess(props) {
    let numberOfPagesArr = new Array(props.numPages);

    for (let i = 0; i < numberOfPagesArr.length; i++) {
      numberOfPagesArr[i] = i;
    }

    setNumPages(numberOfPagesArr);
    setLoaded(true);
    setErrorLoading(null);
  }

  if (!pdfFile) {
    // still loading pdfFile
    return null;
  }

  return (
    <Document
      file={`data:application/pdf;base64,${pdfFile.staticFile}`}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
      className={"create-document-pdf-viewer"}
      loading={<></>}
    >
      {loaded && !errorLoading && (
        <Space direction="vertical">
          {numPages?.map((page) => {
            return (
              <div
                key={page + 1}
                style={{
                  width: "100%",
                  height: "100%",
                  maxWidth: pdfPage?.width,
                  maxHeight: pdfPage?.height,
                }}
              >
                <Page
                  key={page}
                  pageNumber={page + 1}
                  onLoadSuccess={onLoadSuccessPage}
                  className={"create-document-pdf-viewer__custom-page"}
                />
              </div>
            );
          })}
        </Space>
      )}
    </Document>
  );
};

import React from "react";
import PropTypes from "prop-types";

const StatusAcceptedIcon = ({
  color = "currentColor",
  className,
  size = "22",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0352 7.57109L10.0992 14.4148C10.0302 14.5111 9.93928 14.5896 9.83392 14.6437C9.72856 14.6978 9.61181 14.7261 9.49336 14.7261C9.37491 14.7261 9.25816 14.6978 9.1528 14.6437C9.04743 14.5896 8.95649 14.5111 8.8875 14.4148L5.96484 10.3648C5.87578 10.2406 5.96484 10.0672 6.11719 10.0672H7.21641C7.45547 10.0672 7.68281 10.182 7.82344 10.3789L9.49219 12.6945L13.1766 7.58516C13.3172 7.39062 13.5422 7.27344 13.7836 7.27344H14.8828C15.0352 7.27344 15.1242 7.44688 15.0352 7.57109Z"
        fill={color}
      />
    </svg>
  );
};

StatusAcceptedIcon.propTypes = {
  color: PropTypes.string,
};

export default StatusAcceptedIcon;

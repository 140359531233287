import { all, takeEvery, call, put, takeLatest } from "redux-saga/effects";

import { request } from "../../utils/client";
import { AUTH, PROMPTS } from "../types";
import { store } from "..";

export function* updateDocumentDataSaga({
  payload: {
    documentId,
    update,
    isPromptUpdate,
    callback,
    noAuth,
    passcode,
    returnRemainingPrompts,
    noNotification,
    useCallbackInError,
  },
}) {
  try {
    const state = store.getState();
    const token = state.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    if (!noAuth) {
      headers.Authorization = `Bearer ${token}`;
      headers[`account-id`] = accountId;
    }

    const response = yield call(
      request,
      `document/${documentId}${
        noAuth ? "/no-auth" : ""
      }?isPromptUpdate=${isPromptUpdate}${
        noAuth ? `&passcode=${passcode}` : ""
      }`,
      {
        method: "PATCH",
        headers: headers,
        payload: update,
      }
    );

    let getDocumentResponse;

    if (returnRemainingPrompts || noAuth) {
      getDocumentResponse = yield call(
        request,
        `document/${documentId}${
          noAuth ? `/no-auth?passcode=${passcode}` : ""
        }${returnRemainingPrompts ? "?returnRemainingPrompts=true" : ""}`,
        {
          method: "GET",
          headers: headers,
        }
      );
    }

    if (!!callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.UPDATE_DOCUMENT_DATA_SUCCESS,
      payload: {
        response:
          noAuth || returnRemainingPrompts ? getDocumentResponse : response,
        changePositions: noAuth || returnRemainingPrompts ? 1 : null,
        message: noNotification
          ? undefined
          : {
              message: response?.message,
              messageTitle: response?.messageTitle,
              showMessage: response?.showMessage,
            },
      },
    });
  } catch (err) {
    yield put({
      type: PROMPTS.UPDATE_DOCUMENT_DATA_FAILURE,
      payload: err,
    });

    if (useCallbackInError && callback) {
      callback(err);
      if (err?.response?.status !== 403) {
        yield put({
          type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
          payload: {
            type: "error",
          },
        });
      }
    } else {
      yield put({
        type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
        payload: {
          type: "error",
        },
      });
    }
  }
}

export function* signDocumentSaga({
  payload: { documentId, userId, callback },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;
    const passcode = state.promptsReducer.document.passcode;

    const headers = {
      Authorization: `Bearer ${token}`,
      [`account-id`]: accountId,
    };

    const response = yield call(request, `signature/${documentId}`, {
      headers,
      method: "POST",
      payload: { createdBy: userId },
    });

    if (!!callback) {
      callback(response);
    }

    const documentResponse = accountId
      ? yield call(request, `document/${documentId}`, {
          method: "GET",
          headers,
        })
      : yield call(
          request,
          `document/${documentId}/no-auth?passcode=${passcode}`,
          {
            method: "GET",
          }
        );

    yield put({
      type: AUTH.SET_TEMP_USER,
      payload: null,
    });

    yield put({
      type: PROMPTS.SIGN_DOCUMENT_SUCCESS,
      payload: {
        response,
        document: documentResponse,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    if (!!callback) {
      callback("error");
    }

    yield put({
      type: PROMPTS.SIGN_DOCUMENT_FAILURE,
      payload: err,
    });

    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* getDocumentSaga({
  payload: {
    documentId,
    passcode,
    noAuth,
    callback,
    returnRemainingPrompts,
    countAsVisit = false,
  },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    if (!noAuth) {
      headers.Authorization = `Bearer ${token}`;
      headers[`account-id`] = accountId;
    }

    const documentBasePath = `document/${documentId}${
      noAuth ? "/no-auth" : ""
    }`;

    const queryString = new URLSearchParams({
      ...(noAuth && countAsVisit ? { countAsVisit } : {}),
      ...(noAuth ? { passcode } : {}),
      ...(returnRemainingPrompts ? { returnRemainingPrompts: true } : {}),
    });

    const path = `${documentBasePath}?${queryString.toString()}`;

    const response = yield call(request, path, {
      method: "GET",
      headers: headers,
    });

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.GET_DOCUMENT_SUCCESS,
      payload: {
        response,
        changePositions: returnRemainingPrompts || noAuth,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    yield put({
      type: PROMPTS.GET_DOCUMENT_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* loadDocumentPdfSaga({
  payload: { documentId, passcode, callback },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers[`account-id`] = accountId;

    const response = yield call(
      request,
      `document/${documentId}/pdf?passcode=${passcode}`,
      {
        method: "GET",
        headers: headers,
      }
    );

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.LOAD_DOCUMENT_PDF_SUCCESS,
      payload: {
        response,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    yield put({
      type: PROMPTS.LOAD_DOCUMENT_PDF_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* verifyUserSaga({
  payload: { userId, firstName, lastName, documentID, callback },
}) {
  try {
    const response = yield call(
      request,
      `user/${userId}/verify?isNameCheck=true&documentID=${documentID}`,
      {
        method: "POST",
        payload: {
          firstName: firstName,
          lastName: lastName,
        },
      }
    );

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.VERIFY_USER_SUCCESS,
      payload: {
        response,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    yield put({
      type: PROMPTS.VERIFY_USER_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* shareUserSaga({
  payload: { documentId, userEmail, permission, callback },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers[`account-id`] = accountId;

    const response = yield call(request, `document/${documentId}/users`, {
      method: "POST",
      headers: headers,
      payload: {
        userEmail: userEmail,
        permission: permission,
      },
    });

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.SET_DOCUMENT_DATA,
      payload: {
        document: response.data,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export function* acceptedShareSage({ payload: { documentId, callback } }) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers[`account-id`] = accountId;

    const response = yield call(
      request,
      `document/${documentId}/accept-invite`,
      {
        method: "POST",
        headers: headers,
        payload: {},
      }
    );

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.SET_DOCUMENT_DATA,
      payload: {
        document: response.data,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export function* editSharedSage({
  payload: { documentId, userEmail, permission, callback },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers[`account-id`] = accountId;

    const response = yield call(request, `document/${documentId}/user`, {
      method: "PATCH",
      headers: headers,
      payload: {
        userEmail: userEmail,
        permission: permission,
      },
    });

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.SET_DOCUMENT_DATA,
      payload: {
        document: response.data,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
}

export function* deleteSharedSage({
  payload: { documentId, userEmail, callback },
}) {
  try {
    const state = store.getState();
    const token = state.authReducer?.token;
    const accountId = state.authReducer?.workspace;

    const headers = {};

    headers.Authorization = `Bearer ${token}`;
    headers[`account-id`] = accountId;

    const response = yield call(request, `document/${documentId}/user`, {
      method: "DELETE",
      headers: headers,
      payload: {
        userEmail: userEmail,
      },
    });

    if (callback) {
      callback(response);
    }

    yield put({
      type: PROMPTS.SET_DOCUMENT_DATA,
      payload: {
        document: response.data,
        message: {
          message: response?.message,
          messageTitle: response?.messageTitle,
          showMessage: response?.showMessage,
        },
      },
    });
  } catch (err) {
    console.log(err);
  }
}

/**
 * Prompts Sagas
 */
export default function* root() {
  yield all([takeEvery(PROMPTS.UPDATE_DOCUMENT_DATA, updateDocumentDataSaga)]);
  yield all([takeEvery(PROMPTS.SIGN_DOCUMENT, signDocumentSaga)]);
  yield all([takeEvery(PROMPTS.GET_DOCUMENT, getDocumentSaga)]);
  yield all([takeEvery(PROMPTS.LOAD_DOCUMENT_PDF, loadDocumentPdfSaga)]);
  yield all([takeEvery(PROMPTS.VERIFY_USER, verifyUserSaga)]);
  yield all([takeEvery(PROMPTS.SHARE_USER, shareUserSaga)]);
  yield all([takeEvery(PROMPTS.ACCEPTED_SHARE, acceptedShareSage)]);
  yield all([takeEvery(PROMPTS.EDIT_SHARED_USER, editSharedSage)]);
  yield all([takeEvery(PROMPTS.DELETE_SHARED_USER, deleteSharedSage)]);
}

import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Space, Typography, notification, Dropdown } from "antd";
import { ReloadOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  Icons,
} from "../../../components";

import {
  accountsSelector,
  userSelector,
  workspaceSelector,
} from "../../../store/selectors/auth";
import {
  changeAccountData,
  sendWorkspaceInvite,
  setCurrentWorkspace,
} from "../../../store/actions/auth";

import * as keys from "../../../utils/keys";

import styles from "../index.module.scss";
import { EMAIL } from "../../../utils/regularExpression";
import { SelectDropdown } from "../../../../src/components/SelectDropdown";
import DefaultUserImg from "../../../../src/components/DefaultUserImg";
import { CInput } from "../../../../src/components";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const Members = ({
  accounts,
  changeAccountData,
  user,
  workspace,
  sendWorkspaceInvite,
  setCurrentWorkspace,
}) => {
  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [workSpaceMembers, setWorkSpaceMembers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserCreator, setIsUserCreator] = useState(false);
  const [numberOfInputForFocus, setNumberOfInputForFocus] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const apiError = () => {
    return notification.info({
      message: `❌ ${t("api:error")}`,
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  function findUserInWorkspace(workspace, userID) {
    const foundUser = workspace?.users.find(
      (member) => member.userID === userID
    );
    const isUserOwner = foundUser?.permission === "OWNER";
    const isUserAdmin = foundUser?.permission === "ADMIN";
    const isUserCreator = workspace?.createdBy === userID;

    return { isUserOwner, isUserAdmin, isUserCreator };
  }

  useEffect(() => {
    if (accounts?.length && user) {
      //find existing workspace
      const existingWorkspace = accounts?.find(
        (account) => account?.accountID === workspace
      );

      //If user was deleted from workspace but it still is in local storage. Defaults to first found account that has the userID
      if (!existingWorkspace) {
        const firstAccountWithUserId = accounts.find((account) =>
          account.users.some((item) => item.userID === user?.userID)
        );

        const { isUserOwner, isUserAdmin, isUserCreator } = findUserInWorkspace(
          firstAccountWithUserId,
          user.userID
        );

        dispatch(setCurrentWorkspace(firstAccountWithUserId?.accountID));
        setWorkSpaceMembers(firstAccountWithUserId?.users);
        setIsUserOwner(isUserOwner);
        setIsUserAdmin(isUserAdmin);
        setIsUserCreator(isUserCreator);
      } else {
        setWorkSpaceMembers(existingWorkspace?.users);
        const { isUserOwner, isUserAdmin, isUserCreator } = findUserInWorkspace(
          existingWorkspace,
          user.userID
        );

        setIsUserOwner(isUserOwner);
        setIsUserAdmin(isUserAdmin);
        setIsUserCreator(isUserCreator);
      }
    }
  }, [accounts, user, workspace]);

  const changePermission = (changedMemberId, permission) => {
    // Create a new array with the updated values
    const newMembersArr = workSpaceMembers.map((item) => {
      if (item?.userID === changedMemberId) {
        return {
          ...item,
          permission: permission.toUpperCase(),
        };
      }

      return item;
    });

    changeAccountData({ users: newMembersArr }, (isSuccess, res) => {
      if (res.type === "ERROR") {
        apiError();
      } else {
        const changedMember = newMembersArr.find(
          (member) => member?.userID === changedMemberId
        );
        const changedName = changedMember?.firstName
          ? `${changedMember?.firstName} ${changedMember?.lastName}`
          : changedMember?.email;
        notification.info({
          message: `💪 Success! ${changedName} is now a ${changedMember?.permission.toLowerCase()}.`,
          duration: 3,
          placement: "bottom",
          icon: <></>,
          closeIcon: <></>,
          className: "select-dropwdown-notification",
        });
      }
    });
  };

  const checkUserEmail = () => {
    if (!!newUserEmail) {
      const regexMonth = new RegExp(EMAIL);
      const result = regexMonth.test(newUserEmail.trim());

      return !result;
    }

    return true;
  };

  const workSpaceOptions = [
    {
      value: "owner",
      label: "Owner",
    },

    {
      value: "admin",
      label: "Admin",
    },

    {
      value: "collaborator",
      label: "Collaborator",
    },
  ];

  const items = [
    {
      key: "resend",
      label: "Resend invite",
      icon: (
        <ReloadOutlined
          style={{ fontSize: 20, width: 24, transform: "rotate(90deg)" }}
        />
      ),
    },

    {
      key: "revoke",
      label: "Revoke invite",
      icon: <CloseOutlined style={{ fontSize: 20, width: 24 }} />,
      danger: true,
    },
  ];

  const handleAddWorkspaceMember = () => {
    const newMembersArr = [...workSpaceMembers, { email: newUserEmail }];
    changeAccountData(
      {
        users: newMembersArr,
      },
      (isSuccess, res) => {
        if (res.type === "ERROR") {
          apiError();
        } else {
          const newMember = newMembersArr.find(
            (member) => member?.email === newUserEmail
          );
          const changedName = newMember?.firstName
            ? `${newMember?.firstName} ${newMember?.lastName}`
            : newMember?.email;

          setNewUserEmail("");
          notification.info({
            message: `💪 Success! ${changedName} is now a member of your workspace.`,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      }
    );
  };

  const handleDeleteWorkspaceMember = (deletedEmail, message) => {
    const changedWorkSpaceMembers = workSpaceMembers.filter(
      (item) => item.email !== deletedEmail
    );

    changeAccountData(
      {
        users: changedWorkSpaceMembers,
      },
      (isSuccess, res) => {
        if (res.type === "ERROR") {
          apiError();
        } else {
          notification.info({
            message: message,
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      }
    );
  };

  const handleMenuClick = (e, email, accountID, userID) => {
    switch (e.key) {
      case "resend":
        sendWorkspaceInvite(accountID, userID, (isSuccess, res) => {
          if (res.type === "ERROR") {
            apiError();
          } else {
            notification.info({
              message: `You have resend the invitation to ${email}.`,
              duration: 3,
              placement: "bottom",
              icon: <></>,
              closeIcon: <></>,
              className: "select-dropwdown-notification",
            });
          }
        });

        break;
      case "revoke":
        handleDeleteWorkspaceMember(
          email,
          `You have revoked the invitation to ${email}.`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Layout
      header={
        <Header
          title={`Workspace members`}
          shortTitle={`Workspace members`}
          right={
            <Link to={keys.DASHBOARD} className={"btn-close"}>
              <Icons.CloseIcon />
            </Link>
          }
          hideLogo={true}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-workspace"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-workspace__section"]}>
          <div className={styles["c-workspace__detail"]}>
            <div className={styles["c-workspace__head"]}>
              <div
                className={styles["c-workspace__head__row"]}
                style={{ position: "relative" }}
              >
                <div className={styles["c-workspace__head__item"]}>
                  <h1>Workspace members</h1>
                  <p>
                    If you’re the owner or admin of the workspace you can manage
                    the permissions of each workspace member.
                  </p>
                </div>

                {(isUserOwner || isUserAdmin) && (
                  <div
                    className={`${styles["c-workspace__head__item__input"]}`}
                  >
                    <form
                      className={styles["u-form-invite-member"]}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleAddWorkspaceMember();
                      }}
                    >
                      <div className={styles["item"]}>
                        <CInput
                          value={newUserEmail}
                          onClick={() => {
                            setNumberOfInputForFocus(1);
                          }}
                          onBlur={() => setNumberOfInputForFocus(0)}
                          focusOn={numberOfInputForFocus === 1}
                          placeholder="Email address…"
                          onChange={(e) => {
                            setNewUserEmail(e.target.value);
                          }}
                          style={{ backgroundColor: "white" }}
                        />
                      </div>
                      <div className={styles["item"]}>
                        <button
                          value={newUserEmail}
                          type="submit"
                          className={
                            styles[
                              `button${checkUserEmail() ? "__disabled" : ""}`
                            ]
                          }
                          disabled={checkUserEmail()}
                        >
                          Invite member
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <div className={styles["member-list"]}>
              {workSpaceMembers &&
                workSpaceMembers?.map((workspaceUser, index) => {
                  const isCurrentUser = workspaceUser?.userID === user?.userID;
                  const currentUserPermission = workSpaceMembers?.find(
                    (e) => e?.userID === user?.userID
                  )?.permission;

                  const canChangeOtherUser = isUserCreator
                    ? true
                    : ![currentUserPermission, "OWNER"].includes(
                        workspaceUser?.permission
                      );

                  const invited = workspaceUser?.permission === "INVITE_SENT";

                  return (
                    <div key={index} className={styles["member-list__card"]}>
                      <div className={styles["member-list__user"]}>
                        <div className={styles["member-list__user__detail"]}>
                          <Space>
                            <DefaultUserImg
                              initials={workspaceUser?.initials}
                              firstName={workspaceUser?.firstName}
                              lastName={workspaceUser?.lastName}
                              image={workspaceUser?.image}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                lineHeight: "normal",
                              }}
                            >
                              <Text style={{ fontSize: 14 }}>
                                {`${workspaceUser?.firstName ?? ""} ${
                                  workspaceUser?.lastName ?? ""
                                }`}
                              </Text>

                              <Text
                                type="secondary"
                                style={{ fontSize: 12, lineHeight: "normal" }}
                              >
                                {workspaceUser?.email}
                              </Text>
                            </div>
                          </Space>
                        </div>
                      </div>
                      <div className={styles["member-list__select"]}>
                        <div>
                          {isCurrentUser || !canChangeOtherUser ? (
                            <Text
                              style={{
                                textTransform: "capitalize",
                                padding: "0px 12px",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {workspaceUser?.permission.toLowerCase() ===
                              "invite_sent"
                                ? "admin"
                                : workspaceUser?.permission.toLowerCase()}
                            </Text>
                          ) : (
                            <div>
                              {!invited ? (
                                <SelectDropdown
                                  permission={workspaceUser?.permission.toLowerCase()}
                                  setFocused={() => {}}
                                  editHandler={(e) =>
                                    invited
                                      ? null
                                      : changePermission(
                                          workspaceUser?.userID,
                                          e
                                        )
                                  }
                                  removeFunction={() =>
                                    handleDeleteWorkspaceMember(
                                      workspaceUser?.email,
                                      `You have removed ${workspaceUser?.email} from this workspace.`
                                    )
                                  }
                                  userEmail={workspaceUser?.email}
                                  itemsArray={workSpaceMembers}
                                  setChangedItemsArray={setWorkSpaceMembers}
                                  disabled={!canChangeOtherUser}
                                  options={workSpaceOptions}
                                  deleteOption={true}
                                  isDropdown={false}
                                  dropdownText={workspaceUser?.permission}
                                />
                              ) : (
                                <div>
                                  <Dropdown
                                    menu={{
                                      items,
                                      onClick: (item) =>
                                        handleMenuClick(
                                          item,
                                          workspaceUser?.email,
                                          workspace,
                                          workspaceUser?.userID
                                        ),
                                    }}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                  >
                                    <Space
                                      size={"small"}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Text
                                        style={{
                                          textTransform: "capitalize",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        ADMIN
                                      </Text>
                                      <DownOutlined />
                                    </Space>
                                  </Dropdown>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  accounts: accountsSelector(state),
  user: userSelector(state),
  workspace: workspaceSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { changeAccountData, sendWorkspaceInvite, setCurrentWorkspace },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Members);

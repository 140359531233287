import React from "react";
import PropTypes from "prop-types";

const FolderIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.2146 2.72943H9.20123L6.58292 0.224965C6.54961 0.193777 6.50579 0.176249 6.46016 0.175858H0.786048C0.390959 0.175858 0.0717621 0.495054 0.0717621 0.890143V14.1044C0.0717621 14.4995 0.390959 14.8187 0.786048 14.8187H17.2146C17.6097 14.8187 17.9289 14.4995 17.9289 14.1044V3.44371C17.9289 3.04863 17.6097 2.72943 17.2146 2.72943ZM16.3218 13.2116H1.67891V1.783H5.88649L8.55614 4.33657H16.3218V13.2116Z"
        fill={color}
      />
    </svg>
  );
};

FolderIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default FolderIcon;

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "antd";

import { useTranslation } from "react-i18next";

import { CCheckbox, CSelect } from "../..";

const Dropdown = ({
  groupId = "",
  placeholder,
  label,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  type,
  checkbox,
  form,
  visibleIndex,
  position,
  cardData,
  prompts,
  options,
  isCheckboxChecked,
  documentStyles,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (form && visibleIndex === position) {
      form.setFieldsValue({
        [`${groupId}###dropdown`]: "",
      });
    }
  }, [visibleIndex, form, position]);

  useEffect(() => {
    if (cardData) {
      form.setFieldsValue({
        [`${cardData?.[0]?.name}`]: cardData?.[0]?.value,
      });
    }
  }, [cardData, prompts]);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" span={24}>
        <Form.Item name={`${groupId}###dropdown`}>
          <CSelect
            label={label ? label : "label"}
            showSearch={true}
            focusOn={numberOfInputForFocus === 0}
            onSelect={() => {
              setTimeout(() => {
                setNumberOfInputForFocus(2);
              }, 100);
            }}
            onClick={() => {
              setNumberOfInputForFocus(0);
            }}
            options={options}
            placeholder={placeholder ? placeholder : "placeholder"}
            font={documentStyles?.font}
            color={documentStyles?.color}
          />
        </Form.Item>
      </Col>
      {checkbox && (
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###checkbox`}>
            <CCheckbox
              type={"checkbox"}
              title={t("wizard:buttons.invite_recipient")}
              checked={isCheckboxChecked}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default Dropdown;

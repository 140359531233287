import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider as PapertrailProvider, ErrorBoundary } from "@rollbar/react";

import { LoadErrorScreens, ScrollToTop } from "./components";
import {
  Wizard,
  ReviewInput,
  ReviewContract,
  SendContract,
  Signee,
  DocumentOverview,
  Dashboard,
  Folders,
  Workspace,
  AccountSettings,
  CreateDocument,
  DocumentStatus,
  SignSend,
  Sign,
  CreateTemplate,
  Templates,
  Template,
  SearchDocuments,
} from "./pages";

import { setToken, getAccountData, clearAuthData } from "./store/actions/auth";
import { setDocumentData } from "./store/actions/prompts";

import { store, persistor } from "./store";

import * as keys from "./utils/keys";

import "antd/dist/antd.min.css";
import "./styles/index.scss";
import SalesforceAuthorization from "./pages/SalesforceAuthorization";
import { TemplateProvider } from "./contexts/TemplateContext";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { initialDocumentViewerConfig } from "./components/Lexical/document-viewer";
import { CommentNode } from "./components/Lexical/nodes/Comments";

const rollbarConfig = {
  accessToken: `8695859ba5af45739225845000d48531`,
  environment: "testenv",
};

const PublicPage = ({ children }) => {
  return children;
};

const PrivatePage = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? children : <Dashboard />;
};

const App = () => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();

  const loadingErrorScreenType = useSelector(
    (state) => state.authReducer.errorLoadingScreenType
  );

  const handleAccountData = async (user) => {
    const token = await getAccessTokenSilently({ timeoutInSeconds: 10 });
    dispatch(setToken(token));

    dispatch(getAccountData({ user: user }));
  };

  useEffect(() => {
    if (user) {
      handleAccountData(user);
    } else {
      if (window.location.search) dispatch(setDocumentData(null));

      // TODO: check if its needed
      // dispatch(clearAuthData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PapertrailProvider config={rollbarConfig}>
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              {isAuthenticated && (
                <LoadErrorScreens type={loadingErrorScreenType} />
              )}
              <ScrollToTop />

              <DndProvider backend={HTML5Backend}>
                <Routes>
                  <Route
                    index
                    // path={`${keys.DASHBOARD}`}
                    element={
                      <PublicPage>
                        <Dashboard />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.FOLDERS}
                    element={
                      <PublicPage>
                        <Folders />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.ACCOUNT_SETTINGS}
                    element={
                      <PrivatePage>
                        <AccountSettings />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.WORKSPACE_MEMBERS}
                    element={
                      <PrivatePage>
                        <Workspace.Members />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.WORKSPACE_SETTINGS}
                    element={
                      <PrivatePage>
                        <Workspace.Settings />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.CREATE_WORKSPACE}
                    element={
                      <PrivatePage>
                        <Workspace.CreateWorkspace />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.CREATE_DOCUMENT}
                    element={
                      <PublicPage>
                        <CreateDocument />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.DOCUMENT_STATUS}
                    element={
                      <PublicPage>
                        <DocumentStatus />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.WIZARD}
                    element={
                      <PublicPage>
                        <Wizard />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.REVIEW_INPUT}
                    element={
                      <PublicPage>
                        <ReviewInput />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.SIGN_SEND}
                    element={
                      <PrivatePage>
                        <SignSend />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={`${keys.REVIEW_CONTRACTS}/:id`}
                    element={
                      <PrivatePage>
                        <ReviewContract />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={`${keys.SEND_CONTRACT}/:id`}
                    element={
                      <PrivatePage>
                        <SendContract />
                      </PrivatePage>
                    }
                  />
                  <Route path={keys.SIGNEE} element={<Signee />} />
                  <Route
                    path={keys.DOCUMENT_OVERVIEW}
                    element={
                      <PublicPage>
                        <DocumentOverview />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.SIGN}
                    element={
                      <PublicPage>
                        <LexicalComposer
                          initialConfig={{
                            ...initialDocumentViewerConfig,
                            namespace: "FakeEditor",
                            nodes: [
                              ...initialDocumentViewerConfig.nodes,
                              CommentNode,
                            ],
                            editable: true,
                          }}
                        >
                          <Sign />
                        </LexicalComposer>
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.TEMPLATES}
                    element={
                      <PrivatePage>
                        <Templates />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.CREATE_TEMPLATE}
                    element={
                      <PrivatePage>
                        <CreateTemplate />
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={`${keys.TEMPLATE}/:templateID`}
                    element={
                      <PrivatePage>
                        <TemplateProvider>
                          <Template />
                        </TemplateProvider>
                      </PrivatePage>
                    }
                  />
                  <Route
                    path={keys.SEARCH}
                    element={
                      <PublicPage>
                        <SearchDocuments />
                      </PublicPage>
                    }
                  />
                  <Route
                    path={keys.SALESFORCE_AUTHORIZATION}
                    element={
                      <PublicPage>
                        <SalesforceAuthorization />
                      </PublicPage>
                    }
                  />
                  {/* <Route
                path={`${keys.DOCUMENT_OVERVIEW}/:id/end`}
                element={
                  <PrivatePage>
                    <DocumentOverview />
                  </PrivatePage>
                }
              />
              <Route
                path={`${keys.DOCUMENT_OVERVIEW}/:id/contract`}
                element={
                  <PrivatePage>
                    <ReviewContract />
                  </PrivatePage>
                }
              /> */}
                </Routes>
              </DndProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </PapertrailProvider>
  );
};

export default App;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  Layout,
  HowCanWeHelp,
  NavBarBottom,
  TemplateStepTopBar,
  Editor,
  CButton,
} from "../../components";

import styles from "./index.module.scss";
import {
  TemplateEditor,
  defaultNodes,
  defaultOnError,
  defaultTheme,
} from "../../components/Lexical";
import { api } from "../../services/api";
import { store } from "../../store";
import { setErrorLoadingScreenType } from "../../store/actions/auth";
import { TemplateDataFieldContent } from "../../components/ReviewInput/TemplateGenerator";
import { useTemplate } from "../../contexts/TemplateContext";
import * as keys from "../../utils/keys";
import { useDebounce } from "../../hooks/helpers/useDebounce";
import { TemplateGeneratorLikeSwitch } from "../../components/Switch/template-generator-like";
import TogglePreviewTemplate from "../../components/SuggestionsAndComments/components/TogglePreviewTemplate";
import { PromptGenerator } from "../../components/PromptGenerator";
import { ConfirmPublishModal } from "./ConfirmPublish";
import { SignSend } from "../../pages";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import {
  LeftOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  predefinedFields,
  TYPE,
} from "../../components/ReviewInput/ContractRepository/data-fields";
import { sortByPosition } from "../../utils/stableSort";
import { Input, Form } from "antd";
import { useSelector } from "react-redux";
import { userSelector } from "../../store/selectors/auth";

import { useDoubleTap } from "use-double-tap";

import { Table } from "./Table";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { TagNode } from "../../components/Lexical/nodes/TagNode";
import { $getNodeByKey, $getRoot } from "lexical";
import {
  INSERT_TAG_COMMAND,
  REMOVE_ALL_TAGS_COMMAND,
  SCROLL_INTO_TAG,
} from "../../components/Lexical/plugins/TagsPlugin";
import { flatten, sortBy } from "lodash";
import { UploadModal } from "../../components/UploadModal";
import { convertDocxToHtml } from "../../utils/convertDocxToHtml";
import { $generateNodesFromDOM } from "@lexical/html";
import classNames from "classnames";
import { $generateHtmlFromNodes } from '@lexical/html';

const steps = [
  {
    name: "Create document",
    id: 1,
    position: 1,
  },
  {
    name: "Review form",
    id: 2,
    position: 2,
  },
  {
    name: "Publish",
    id: 3,
    position: 3,
  },
];

const uploadModalDefaultOptions = {
  multiple: false,
  showUploadList: false,
  accept:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

function Template() {
  const { template, setTemplate } = useTemplate();
  const [loading, setLoading] = useState(true);
  const { templateID } = useParams();

  const lexicalState = useMemo(() => {
    if (template.lexicalState) {
      if (typeof template.lexicalState === "string") {
        return template.lexicalState;
      }

      return JSON.stringify(template.lexicalState);
    }

    return undefined;
  }, [template.lexicalState]);

  useEffect(() => {
    setLoading(true);
    store.dispatch(setErrorLoadingScreenType("load"));
    setTimeout(() => {
      if (templateID) {
        api
          .get(`/template/${templateID}`)
          .then((res) => {
            setTemplate(res.data.templates?.[0]);
            store.dispatch(setErrorLoadingScreenType(""));
          })
          .catch(() => {
            store.dispatch(setErrorLoadingScreenType("error"));
          })
          .finally(() => setLoading(false));
        return;
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <LexicalComposer
      initialConfig={{
        namespace: "Template",
        nodes: defaultNodes,
        theme: defaultTheme,
        editorState: lexicalState,
        onError: defaultOnError,
      }}
    >
      <TemplateModule lexicalState={lexicalState} />
    </LexicalComposer>
  );
}

const DEFAULT_UPLOAD_MODAL_PROPERTIES = {
  open: false,
  error: false,
  finished: false,
  uploading: false,
};

function TemplateModule({ lexicalState }) {
  let navigate = useNavigate();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditor, setIsEditor] = useState(true);
  const { setTemplate, template, variables, currentSelectedVariable, setCurrentSelectedVariableKey } =
    useTemplate();
  const [step, setStep] = useState(1);
  const [editor] = useLexicalComposerContext();
  const ref = useRef(new Map());
  const [previewTemplate, setPreviewTemplate] = useState(false);
  const [disabledRestart, setDisabledRestart] = useState(true);
  const [visibleIndex, setVisibleIndex] = useState(1);
  const [showModal, setShowModal] = useState();
  const [uploadModalState, setUploadModalState] = useState(
    DEFAULT_UPLOAD_MODAL_PROPERTIES
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingFileTitle, setEditingFileTitle] = useState(false);
  const [loadingFileTitleEdit, setLoadingFileTitleEdit] = useState(false);
  const changeFileTitleInputRef = useRef(null);
  const [fileTitleInputStatus, setFileTitleInputStatus] = useState(undefined);
  const [form] = Form.useForm();
  const user = useSelector(userSelector);

  const previewStep = [
    {
      name: "Live Preview",
      id: 2,
      position: null,
      onClick: () => {
        setPreviewTemplate(false);
      },
    },
  ];

  const isCreator = useMemo(() => {
    if (template && user) {
      return template?.createdBy === user?.userID;
    }
  }, [template, user]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    navigate(keys.TEMPLATES);
    setIsModalOpen(false);
  };

  const showTemplatePreviewToggle = () => {
    setVisibleIndex(1);
    setPreviewTemplate((prevState) => !prevState);
  };

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const onChange = useDebounce(debounceCb, 500);

  function debounceCb(editorState) {
    let content;
    editorState.read(() => {
      if (isCreator) {
        try {
          editor.getEditorState().read(() => {
            content = $generateHtmlFromNodes(editor);
          })
        } catch {
          console.log('failed')
          // if it fails, it fails
        }
      }
    })

    editorState.read(() => {
      const json = editorState.toJSON();
      setTemplate((old) => ({
        ...old,
        lexicalState: JSON.stringify(json),
        ...(content && { content })
      }));

      api
        .post(`/template-gen/content/${template.templateID}`, {
          content: template.content,
          lexicalState: json,
          ...(content && { content })
        })
        .catch(() => {
          store.dispatch(setErrorLoadingScreenType("error"));
        });
    });
  }

  async function onUpdateVariable(newValues) {
    if (!newValues) return;

    const { position, ...data } = newValues;

    await api.patch(`template-gen/variable/${template.templateID}`, data);

    await createPrompts();
  }

  function onCreateVariable(values) {
    api
      .post(`template-gen/variable/${template.templateID}`, values)
      .then((res) => {
        const variable = res.data.variables.find((v) => v.key === values.key);
        setTemplate(res.data);
        editor.dispatchCommand(INSERT_TAG_COMMAND, {
          id: variable.variableID,
          appendToStart: true,
        });
      });
  }

  function onDeleteVariable(variableID, shouldRemoveTags = true) {
    const variable = variables.find((v) => v.variableID === variableID);
    if (variable?.options?.default) return;

    api
      .delete(`template-gen/variable/${template.templateID}/${variableID}`)
      .then((res) => {
        if (shouldRemoveTags) {
          editor.dispatchCommand(REMOVE_ALL_TAGS_COMMAND, variableID);
        }
        setTemplate(res.data);
      });
  }

  async function onUpdatePrompt(prompt) {
    if (!prompt?.promptID) return;

    const { position, ...data } = prompt;

    await api.patch(`template-gen/prompt/${template.templateID}`, data);

    await createPrompts();
  }

  async function saveEdit(body) {
    const res = await api.patch(
      `template-gen/details/${template.templateID}`,
      body
    );

    setTemplate(res.data);
  }

  function gotToStep(stepNumber) {
    setIsEditor(true);
    if (template?.variables.length > 0) {
      setStep(stepNumber);
    } else {
      return;
    }
  }

  function handleNextStep() {
    if (step + 1 > steps.length) return;

    setStep((old) => old + 1);
  }

  function handleRestart() {
    setVisibleIndex(1);
    setDisabledRestart(true);
  }

  function goToModal(title) {
    setShowModal(title);
  }

  async function handlePublishTemplate() {
    await updatePrompts(sortedPrompts);
    await api.post(`template-gen/publish/${template.templateID}`);
    navigate(keys.TEMPLATES);
  }

  function updateTemplate(variables) {
    api.patch(`template/${template.templateID}`, variables).then((res) => {
      setTemplate(res.data);
    });
  }

  function updatePrompts(variables) {
    api.patch(`template-gen/all-prompts/${template.templateID}`, variables);
  }

  async function createPrompts() {
    const res = await api.post(
      `template-gen/prompt/generate/${template.templateID}`
    );

    setTemplate(res.data);
  }

  useEffect(() => {
    if (visibleIndex <= 1) {
      setDisabledRestart(true);
    } else {
      setDisabledRestart(false);
    }
  }, [visibleIndex]);

  useEffect(() => {
    if (!editor.hasNodes([TagNode])) {
      throw new Error("TagsPlugin: TagNode not registered on editor");
    }

    return editor.registerMutationListener(TagNode, (nodeMutations) => {
      for (const [nodeKey, mutation] of nodeMutations) {
        if (mutation === "destroyed") {
          const node = ref.current.get(nodeKey);

          const variableID = node.getId();

          const otherVariables = Object.fromEntries(ref.current);

          const filtered = Object.entries(otherVariables)
            .filter(([k, v]) => v instanceof TagNode && k !== nodeKey)
            .map(([, v]) => v);

          const count = filtered.filter((v) => v.getId() === variableID).length;

          if (!count) {
            onDeleteVariable(variableID, false);
          }

          ref.current.delete(nodeKey);
        } else if (mutation === "created") {
          if (ref.current.get(nodeKey)) {
            ref.current.delete(nodeKey);
          }
          const editorState = editor.getEditorState();

          const node = $getNodeByKey(nodeKey, editorState);
          ref.current.set(nodeKey, node);
        }
      }
    });
  }, [editor]);

  useEffect(() => {
    setVisibleIndex(1);
    if (step >= 2) {
      createPrompts();
    }
  }, [step]);

  const onDoubleClickTemplateFileTile = useDoubleTap((e) => {
    setEditingFileTitle(true);
  }, 300);

  function onFileTitleUpdate() {
    const newValue = changeFileTitleInputRef.current.input.value;
    setFileTitleInputStatus(undefined);
    if (newValue === template.fileTitle) {
      setEditingFileTitle(false);
      return;
    }
    setLoadingFileTitleEdit(true);

    api
      .patch(`/template/${template.templateID}`, {
        fileTitle: newValue,
      })
      .then((res) => {
        setTemplate(res.data);
        setEditingFileTitle(false);
      })
      .catch(() => {
        setFileTitleInputStatus("error");
      })
      .finally(() => {
        setLoadingFileTitleEdit(false);
      });
  }

  function addSignees(body) {
    api
      .post(`template-gen/${template.templateID}/signees`, {
        ...body,
        partyID: "P_PARTY",
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function deleteSignees(signeeId) {
    api
      .delete(`template-gen/${template.templateID}/signees/${signeeId}`)
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function editSignees(body) {
    api
      .patch(`template-gen/${template.templateID}/signees`, {
        ...body,
        partyID: "P_PARTY",
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function addApprovers(body) {
    api
      .post(`template-gen/${template.templateID}/approvers`, {
        ...body,
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function editApprovers(body) {
    api
      .patch(`template-gen/${template.templateID}/approvers`, {
        ...body,
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function deleteApprovers(approverId) {
    api
      .delete(`template-gen/${template.templateID}/approvers/${approverId}`)
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function addRecipients(body) {
    api
      .post(`template-gen/${template.templateID}/recipient`, {
        ...body,
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function deleteRecipients(recipientId) {
    api
      .delete(`template-gen/${template.templateID}/recipient/${recipientId}`)
      .then((res) => {
        setTemplate(res.data);
      });
  }

  function editRecipients(body) {
    api
      .patch(`template-gen/${template.templateID}/recipient`, {
        ...body,
      })
      .then((res) => {
        setTemplate(res.data);
      });
  }

  useEffect(() => {
    if (!ref.current.size) {
      ref.current = new Map(editor.getEditorState()._nodeMap);
    }

    window.onbeforeunload = () => true;

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const variablesCategories = useMemo(() => {
    const categories = [];

    template?.variables?.forEach((variable) => {
      const matchingField = predefinedFields
        .flatMap(({ options }) => options)
        .find(({ key }) => key === variable.key);

      const existingCategory = predefinedFields.find(({ options }) =>
        options.some(({ key }) => key === variable.key)
      );

      const label = matchingField ? existingCategory.label : "Custom";

      const category = categories.find(
        ({ label: mergedLabel }) => mergedLabel === label
      );

      if (category) {
        category.variables.push(variable);
      } else {
        categories.push({
          ...existingCategory,
          ...(!matchingField && { position: 99 }),
          label,
          variables: [variable],
        });
      }
    });

    return sortByPosition(
      categories.map((category) => ({
        ...category,
        variables: sortByPosition(category.variables),
      }))
    );
  }, [template?.variables]);

  const sortedPrompts = useMemo(() => {
    const prompts = template?.prompts;

    if (!prompts) return [];

    const categoryOptionsOrdered = flatten(
      variablesCategories.map(({ options, variables }) => options || variables)
    ).map(({ key }) => key);

    return sortBy(prompts, ({ shortPromptName }) =>
      categoryOptionsOrdered.indexOf(shortPromptName)
    ).map((prompt, index) => ({
      ...prompt,
      position: index + 1,
    }));
  }, [template?.prompts, variablesCategories]);

  function onCardClick(event, variable) {
    const isInput = !!event.target.closest("input");
    const isButton = !!event.target.closest("button");
    if (step === 1 && !isInput && !isButton) {
      editor.dispatchCommand(SCROLL_INTO_TAG, variable.variableID);
    }
  }

  function onStartUpload() {
    setUploadModalState((old) => ({
      ...old,
      open: true,
    }));
  }

  async function customDraggerRequest({ onSuccess, file }) {
    try {
      setUploadModalState((old) => ({
        ...old,
        uploading: true,
        file,
      }));
      const htmlString = await convertDocxToHtml(file);

      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(htmlString, "text/html");

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        // Select the root
        const root = $getRoot();

        root.clear();

        root.select();

        // Insert them at a selection.
        root.append(...nodes);

        setUploadModalState((old) => ({
          ...old,
          finished: true,
          uploading: false,
          error: false,
        }));
      });
    } catch {
      setUploadModalState((old) => ({
        ...old,
        uploading: false,
        finished: true,
        error: true,
      }));
    }
    onSuccess?.("ok");
  }

  function resetUploadModalState() {
    setUploadModalState(DEFAULT_UPLOAD_MODAL_PROPERTIES);
  }

  const isPublishDisabled = useMemo(() => {
    if (!template?.variables?.length) return true;

    return template?.prompts?.some(
      (prompt) =>
        prompt.questionType === "SINGLE_SELECT" && !prompt.answerOptions?.length
    );
  }, [template?.prompts, template?.variables?.length]);

  if (!isCreator) {
    return (
      <Layout
        wrapperClass={styles["layout-wrapper"]}
        header={
          <div className={styles["header"]}>
            <div
              className={`${styles["header__item"]} ${styles["header__left-side"]}`}
            >
              <div
                className={styles["header__left-side__back"]}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(keys.TEMPLATES)}
              >
                <LeftOutlined style={{ fontSize: 32, color: "#5F6CFF" }} />
              </div>
            </div>

            <div className={styles["header__item"]}>
              <span style={{ fontWeight: "500" }}>Preview</span>
            </div>

            <div
              className={`${styles["header__item"]} ${styles["header__right-side"]}`}
            ></div>
          </div>
        }
        helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
        onQuestionButtonClick={() => openHowCanWeHelp()}
      >
        <PreviewTemplate
          template={template}
          onUpdatePrompt={onUpdatePrompt}
          onUpdateVariable={onUpdateVariable}
          onDeleteVariable={onDeleteVariable}
          onCreateVariable={onCreateVariable}
          variablesCategories={variablesCategories}
          form={form}
          saveEdit={saveEdit}
          onChange={onChange}
          lexicalState={lexicalState}
          isCreator={isCreator}
          step={step}
        />
      </Layout>
    );
  }

  return (
    <>
      <Layout
        wrapperClass={styles["layout-wrapper"]}
        header={
          <div className={styles["header"]}>
            <div
              className={classNames(
                styles["header__item"],
                styles["header__left-side"]
              )}
            >
              <div
                className={styles["header__left-side__back"]}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showModal
                    ? setShowModal(false)
                    : previewTemplate
                    ? setPreviewTemplate(false)
                    : template?.status.toLowerCase() === "published"
                    ? navigate(keys.TEMPLATES)
                    : openModal(true);
                }}
              >
                <LeftOutlined
                  style={showModal ? { fontSize: 32, color: "#5F6CFF" } : {}}
                />
              </div>
              {!showModal &&
                (editingFileTitle ? (
                  <Input
                    disabled={loadingFileTitleEdit}
                    ref={changeFileTitleInputRef}
                    autoFocus
                    status={fileTitleInputStatus}
                    defaultValue={template?.fileTitle}
                    onBlur={onFileTitleUpdate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.currentTarget.blur();
                      }
                    }}
                  />
                ) : (
                  <div
                    className={styles["header__left-side__text"]}
                    {...onDoubleClickTemplateFileTile}
                  >
                    {template?.fileTitle}
                  </div>
                ))}
            </div>

            <div className={styles["header__item"]}>
              {showModal ? (
                <span style={{ fontWeight: "500" }}>{showModal}</span>
              ) : (
                <TemplateStepTopBar
                  currentId={step}
                  data={previewTemplate ? previewStep : steps}
                  gotToStep={gotToStep}
                />
              )}
            </div>

            <div
              className={`${styles["header__item"]} ${styles["header__right-side"]}`}
            >
              {!showModal && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "12px",
                    minWidth: 215,
                  }}
                >
                  {step > 1 && !previewTemplate && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <TogglePreviewTemplate
                        onToggle={showTemplatePreviewToggle}
                        isTemplateOpen={previewTemplate}
                      />
                    </div>
                  )}
                  {step === 1 && isEditor && (
                    <CButton
                      customStyles={classNames(styles.iconButton)}
                      icon={<UploadOutlined />}
                      type="white"
                      onClick={onStartUpload}
                      style={{ padding: 10 }}
                      divTitle={"Upload docx"}
                    />
                  )}
                  {step === 1 && (
                    <TemplateGeneratorLikeSwitch
                      name="editor-tag"
                      leftText="Edit"
                      rightText="Tag"
                      value={isEditor}
                      onChange={() => setIsEditor((old) => !old)}
                    />
                  )}

                  {previewTemplate ? (
                    <CButton
                      customStyles={styles["header__right-side__button"]}
                      onClick={handleRestart}
                      type="primary"
                      title="Restart"
                      disabled={disabledRestart}
                      icon={<ReloadOutlined style={{ fontSize: "18px" }} />}
                    />
                  ) : (
                    <CButton
                      disabled={step === 3 ? isPublishDisabled : false}
                      customStyles={styles["header__right-side__button"]}
                      onClick={
                        step === 3 ? handlePublishTemplate : handleNextStep
                      }
                      type="primary"
                      title={step === 3 ? "Publish" : "Next"}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        }
        helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
        onQuestionButtonClick={() => openHowCanWeHelp()}
      >
        <div
          className={styles["wrapper"]}
          style={{ display: step === 2 ? "block" : "flex" }}
        >
          {step === 1 ? (
            <>
              <div
                style={{
                  marginRight: 10,
                }}
              >
                <TemplateDataFieldContent
                  template={template}
                  currentVariable={currentSelectedVariable}
                  onUpdatePrompt={onUpdatePrompt}
                  onUpdateVariable={onUpdateVariable}
                  onDeleteVariable={onDeleteVariable}
                  onCreateVariable={onCreateVariable}
                  variablesCategories={variablesCategories}
                  form={form}
                  onCardClick={onCardClick}
                  step={step}
                />
              </div>

              <div className={`${styles["card"]} ${styles["template"]}`}>
                <Table template={template} saveEdit={saveEdit}>
                  {isEditor ? (
                    <Editor
                      useOutsideComposer
                      state={lexicalState}
                      onChange={onChange}
                      disableSelectionChange
                    />
                  ) : (
                    <TemplateEditor
                      useOutsideComposer
                      state={lexicalState}
                      onChange={onChange}
                      disableSelectionChange
                    />
                  )}
                </Table>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: step === 2 || previewTemplate ? "flex" : "none",
                  paddingLeft: 12,
                  flex: 1,
                  gap: "40px",
                }}
              >
                <PromptGenerator
                  form={form}
                  documentLanguage={template?.language}
                  previewTemplate={previewTemplate}
                  setPreviewTemplate={setPreviewTemplate}
                  template={template}
                  visibleIndex={visibleIndex}
                  setVisibleIndex={setVisibleIndex}
                  variablesCategories={variablesCategories}
                  sortedPrompts={sortedPrompts}
                  onUpdatePrompt={onUpdatePrompt}
                  onUpdateVariable={onUpdateVariable}
                  onDeleteVariable={onDeleteVariable}
                  onCreateVariable={onCreateVariable}
                  currentSelectedVariable={currentSelectedVariable}
                  step={step}
                />
              </div>
              <div
                style={{
                  display: step === 3 && !previewTemplate ? "flex" : "none",
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <SignSend
                  isTemplateGenerator
                  generatorDoucument={template}
                  goToModal={goToModal}
                  showModal={showModal}
                  PreviewComponent={
                    <PreviewTemplate
                      template={template}
                      onUpdatePrompt={onUpdatePrompt}
                      onUpdateVariable={onUpdateVariable}
                      onDeleteVariable={onDeleteVariable}
                      onCreateVariable={onCreateVariable}
                      variablesCategories={variablesCategories}
                      form={form}
                      saveEdit={saveEdit}
                      onChange={onChange}
                      lexicalState={lexicalState}
                      step={step}
                    />
                  }
                  addSignees={addSignees}
                  editSignees={editSignees}
                  deleteSignees={deleteSignees}
                  addApprovers={addApprovers}
                  editApprovers={editApprovers}
                  deleteApprovers={deleteApprovers}
                  addRecipients={addRecipients}
                  editRecipients={editRecipients}
                  deleteRecipients={deleteRecipients}
                  saveEdit={saveEdit}
                  handlePublishTemplate={handlePublishTemplate}
                />
              </div>
            </>
          )}
        </div>
        {!isMenuOpen && !openRightPanel && <NavBarBottom />}
      </Layout>
      <ConfirmPublishModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handlePublishTemplate={handlePublishTemplate}
        createPrompts={createPrompts}
        closeModal={closeModal}
        isPublishDisabled={isPublishDisabled}
      />
      <UploadModal
        doneButtonOnClick={resetUploadModalState}
        doneDescription="The content of your contract has been changed."
        isModalOpen={uploadModalState.open}
        onCloseModal={resetUploadModalState}
        draggerProps={{
          ...uploadModalDefaultOptions,
          customRequest: customDraggerRequest,
        }}
        error={uploadModalState.error}
        finished={uploadModalState.finished}
        uploading={uploadModalState.uploading}
        onRetry={() =>
          customDraggerRequest({
            file: uploadModalState.file,
          })
        }
      />
    </>
  );
}

const PreviewTemplate = ({
  template,
  onUpdatePrompt,
  onUpdateVariable,
  onDeleteVariable,
  onCreateVariable,
  variablesCategories,
  form,
  saveEdit,
  onChange,
  lexicalState,
  isCreator,
  step,
}) => {
  return (
    <div className={styles["wrapper"]}>
      <div
        style={{
          marginRight: 10,
        }}
      >
        <TemplateDataFieldContent
          template={template}
          onUpdatePrompt={onUpdatePrompt}
          onUpdateVariable={onUpdateVariable}
          onDeleteVariable={onDeleteVariable}
          onCreateVariable={onCreateVariable}
          variablesCategories={variablesCategories}
          form={form}
          isCreator={isCreator}
          step={step}
        />
      </div>

      <div
        className={`${styles["card"]} ${styles["template"]}`}
        style={{
          minWidth: "673px",
          pointerEvents: "none",
        }}
      >
        <Table template={template} saveEdit={saveEdit}>
          <TemplateEditor
            useOutsideComposer
            state={lexicalState}
            onChange={onChange}
            disableSelectionChange
          />
        </Table>
      </div>
    </div>
  );
};

export default Template;

import React, { useState } from "react";
import { Form, Row, Col } from "antd";

import { Icons, CButton, CInput } from "../../../components";
import styles from "../index.module.scss";

const ChangePassword = (props) => {
  const { onClose } = props;
  const [form] = Form.useForm();
  const [inputFormInFocus, setInputFormInFocus] = useState(0);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["has-from"]}`}>
        <div className={styles["u-modal__form__head"]}>
          <span>Change Password</span>
          <span onClick={onClose} className={styles["u-modal__form__close"]}>
            <Icons.CloseIcon />
          </span>
        </div>

        <Form
          layout="vertical"
          id="change-password"
          name="change-password"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password!",
                  },
                ]}
              >
                <CInput
                  star={true}
                  label={"Current Password"}
                  placeholder={"Enter your current password"}
                  id="current-password"
                  name="current-password"
                  type={"password"}
                  onChange={() => setInputFormInFocus(1)}
                  focusOn={inputFormInFocus === 1 ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter a strong password!",
                  },
                ]}
              >
                <CInput
                  star={true}
                  label={"New Password"}
                  placeholder={"Enter a strong password"}
                  id="new-password"
                  name="new-password"
                  type={"password"}
                  onChange={() => setInputFormInFocus(2)}
                  focusOn={inputFormInFocus === 2 ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your new password again!",
                  },
                ]}
              >
                <CInput
                  star={true}
                  label={"Confirm Password"}
                  placeholder={"Enter your new password again"}
                  id="confirm-password"
                  name="confirm-password"
                  type={"password"}
                  onChange={() => setInputFormInFocus(3)}
                  focusOn={inputFormInFocus === 3 ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={styles["u-modal__form__foot"]}>
          <div className={styles["u-modal__form__foot__item"]}>
            <CButton
              onClick={onClose}
              type="white"
              title={"Cancel"}
              customStyles={`${styles["btn-form"]} ${styles["btn-form-black"]}`}
            />
          </div>
          <div className={styles["u-modal__form__foot__item"]}>
            <CButton
              onClick={onClose}
              type="primary"
              title={"Change Password"}
              customStyles={styles["btn-form"]}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;

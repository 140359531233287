import React from "react";
import { Icons, CButton } from "../../../components";
import styles from "../index.module.scss";

const ConfirmTemplateDelete = (props) => {
  const { onClose, onCancel } = props;

  return (
    <div className={styles["u-overlay"]}>
      <div className={styles["u-modal"]}>
        <div className={`${styles["u-modal__head"]} ${styles["red"]}`}>
          <Icons.WarningFillIcon />
        </div>
        <div className={styles["u-modal__title"]} style={{
          marginBottom: 8
        }}>
        Are you sure you want to remove this template?
        </div>
        <div style={{
          marginBottom: 24
        }}>
          <span>
            You can't undo this action. 
          </span>
        </div>
        <div className={styles["u-modal__foot"]}>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onCancel}
              type="white"
              title={"Cancel"}
              customStyles={styles["btn-modal"]}
            />
          </div>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onClose}
              type="primary"
              title={"Remove template"}
              customStyles={styles["btn-modal"]}
              backgroundColor="#E53935"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmTemplateDelete;

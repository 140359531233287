import React from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import { Icons } from "../index";
import { useSelector } from "react-redux";
import { documentSelector } from "../../store/selectors/prompts";
import { useTranslation } from "react-i18next";

const DocumentParties = ({
  stylesForDocument,
  onTipsPress,
  isPreview,
  isSign,
  onEditClick,
}) => {
  const thisDocument = useSelector(documentSelector);
  const { t } = useTranslation();

  return (
    <div className={styles["c-review-status__item"]}>
      <div
        className={styles["teritary__head"]}
        style={{ fontFamily: stylesForDocument?.font }}
      >
        <div className={styles["teritary__head__icon"]}>
          <Icons.UserIcon />
        </div>
        <div className={styles["teritary__head__title"]}>
          {t("contract:parties")}
        </div>
        {thisDocument?.tips.find(
          (value) => value?.tipID === "CONTRACT_PARTIES_123"
        ) && (
          <div
            data-html2canvas-ignore="true"
            className={styles["has-help"]}
            style={{ color: stylesForDocument?.color }}
            onClick={() => {
              onTipsPress("CONTRACT_PARTIES_123");
            }}
          >
            <Icons.QuestionCircleIcon color={stylesForDocument?.color} />
            {t("wizard:info.help")}
          </div>
        )}
      </div>

      <div className={styles["teritary__row"]}>
        {thisDocument?.variableGroups
          ?.filter((item) => item.variableGroupType === "PARTY")
          ?.sort((a, b) => a.variableGroupPosition - b.variableGroupPosition)
          ?.map((item, index) => {
            return (
              <div
                className={styles["teritary__item"]}
                key={`party-${index}`}
                style={{ fontFamily: stylesForDocument?.font }}
              >
                <div
                  className={styles["teritary__card"]}
                  style={{
                    border: `1px solid ${
                      stylesForDocument?.color
                        ? `${stylesForDocument.color}33`
                        : "#ecedff"
                    }`,
                  }}
                >
                  <div
                    className={styles["teritary__card__head"]}
                    style={{
                      borderBottom: `1px solid ${
                        stylesForDocument?.color
                          ? `${stylesForDocument?.color}33`
                          : "#ecedff"
                      }`,
                    }}
                  >
                    <div className={styles["icon"]}>
                      <Icons.OfficeIcon color={stylesForDocument?.color} />
                    </div>
                    <div className={styles["title"]}>
                      {item?.variableGroupName}
                    </div>
                  </div>
                  <div className={styles["teritary__card__body"]}>
                    <ul>
                      {thisDocument?.variables
                        ?.filter(
                          (variable) =>
                            variable.variableGroupID === item.variableGroupID
                        )
                        ?.sort((a, b) => a.position - b.position)
                        ?.map((variable, variableIndex) => {
                          const relatedPrompt = thisDocument?.prompts?.find(
                            (p) =>
                              p.promptID === variable?.relatedPromptIDs?.[0]
                          );

                          let hideIcon = false;

                          if (relatedPrompt) {
                            hideIcon = !!relatedPrompt.defaultAnswer;
                          }

                          return (
                            <li
                              key={`party-variable-${item?.partyID}-${variableIndex}`}
                            >
                              <div
                                className={styles["teritary__card__body__key"]}
                              >
                                {variable?.key}
                              </div>
                              <strong>
                                {variable?.value}
                                {variable?.hasOwnProperty("relatedPromptIDs") &&
                                  !isPreview &&
                                  !isSign &&
                                  !hideIcon && (
                                    <span
                                      onClick={() =>
                                        onEditClick(variable?.relatedPromptIDs)
                                      }
                                    >
                                      <Icons.Edit
                                        width={18}
                                        height={18}
                                        color={stylesForDocument?.color}
                                      />
                                    </span>
                                  )}
                              </strong>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DocumentParties;

import React from "react";
import String from "../PromptsTypes/String";
import CDataSelect from "../PromptsTypes/Date";
import Selects from "../PromptsTypes/Select";
import Address from "../PromptsTypes/Address";

const TYPE = {
  STRING: "STRING",
  DATE: "DATE",
  NUMBER: "NUMBER",
  MULTI_SELECT: "MULTI_SELECT",
  SINGLE_SELECT: "SINGLE_SELECT",
  CHECKBOX: "CHECKBOX",
  ADDRESS: "ADDRESS",
  INFO: "INFO",
};

const PromptComponent = (props) => {
  const variableType = props?.questionType;

  switch (variableType) {
    case TYPE.STRING:
    case TYPE.NUMBER:
      return <String type={variableType} {...props} />;
    case TYPE.CHECKBOX:
    case TYPE.MULTI_SELECT:
    case TYPE.SINGLE_SELECT:
      return <Selects type={variableType} {...props} />;
    case TYPE.DATE:
      return <CDataSelect {...props} />;
    case TYPE.ADDRESS:
      return <Address type={variableType} {...props} />;
    case TYPE.INFO:
      return <></>;
    default:
      return <String type={variableType} {...props} />;
  }
};

export default PromptComponent;

const initialState = {
  promptsReducer: {
    visibleIndex: 1,
    document: null,
    addressBeforeConfirm: null,
    newDocumentData: null,
    newSigneeData: null,
    availableHeaderStep: null,
    isCreatingDocument: false,
    documentPdf: null,
  },
  authReducer: {
    accounts: null,
    user: null,
    isRequest: false,
    documents: null,
    templates: null,
    redirectParams: null,
    errorLoadingScreenType: "",
    workspace: null,
    tempUser: null,
  },
  dashboardReducer: {
    activeFolder: null,
  },
  token: null,
  auth0: null,
};

export default initialState;

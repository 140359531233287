import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";

import { Icons } from "..";

import styles from "./index.module.scss";

const QuickActions = ({
  templates,
  createDocumentFunction = () => {},
  setIsCreatingDocument,
  setDocumentData,
  setIndex,
}) => {
  let navigate = useNavigate();
  const myoTemplates = useMemo(() => {
    const filteredTemplates = templates?.filter(
      (template) => template?.documentCategory?.toLowerCase() === "myo"
    );
    const otherTemplates = templates?.filter(
      (template) => template?.documentCategory?.toLowerCase() !== "myo"
    );
    return (filteredTemplates && filteredTemplates?.length)
      ? [...filteredTemplates, ...otherTemplates]
      : otherTemplates;
  }, [templates]);

  function handleNavigate(id) {
    createDocumentFunction(id, async (res) => {
      setIsCreatingDocument(true);
      await setDocumentData(res.documents);
      setIndex(1);
      navigate(keys.WIZARD);
    });
  }

  return (
    <div className={styles["c-quick-actions"]}>
      {myoTemplates?.slice(0, 3)?.map((template, index) => {
        const STYLE = {
          0: "green",
          1: "orange",
          2: "yellow",
        };

        return (
          <div
            key={template?.templateID}
            className={styles["c-quick-actions__item"]}
            onClick={() => handleNavigate(template?.templateID)}
            title={template?.title}
          >
            <div className={styles["c-quick-actions__card"]}>
              <div
                className={`${styles["c-quick-actions__figure"]} ${
                  styles[STYLE[index]]
                }`}
              >
                <Icons.FileIcon />
              </div>
              <div className={styles["c-quick-actions__text"]}>
                {template?.title}
              </div>
              <div className={styles["c-quick-actions__icon"]}>
                <Icons.ArrowRightIcon />
              </div>
            </div>
          </div>
        );
      })}
      <div
        className={styles["c-quick-actions__item"]}
        onClick={() => {
          navigate(keys.CREATE_DOCUMENT);
        }}
      >
        <div
          className={`${styles["c-quick-actions__card"]} ${styles["has-purple"]}`}
        >
          <div
            className={`${styles["c-quick-actions__figure"]} ${styles["purple"]}`}
          >
            <Icons.FolderOpenIcon />
          </div>
          <div className={styles["c-quick-actions__text"]}>
            Browse all templates
          </div>
          <div className={styles["c-quick-actions__icon"]}>
            <Icons.ArrowRightIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickActions;

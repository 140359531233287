import React from "react";
import PropTypes from "prop-types";

const PlusIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M14.1077 9.28676H10.7148V5.8939C10.7148 5.79569 10.6345 5.71533 10.5363 5.71533H9.46484C9.36663 5.71533 9.28627 5.79569 9.28627 5.8939V9.28676H5.89342C5.7952 9.28676 5.71484 9.36712 5.71484 9.46533V10.5368C5.71484 10.635 5.7952 10.7153 5.89342 10.7153H9.28627V14.1082C9.28627 14.2064 9.36663 14.2868 9.46484 14.2868H10.5363C10.6345 14.2868 10.7148 14.2064 10.7148 14.1082V10.7153H14.1077C14.2059 10.7153 14.2863 10.635 14.2863 10.5368V9.46533C14.2863 9.36712 14.2059 9.28676 14.1077 9.28676Z"
          fill={color}
        />
        <path
          d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10 18.3036C5.41518 18.3036 1.69643 14.5848 1.69643 10C1.69643 5.41518 5.41518 1.69643 10 1.69643C14.5848 1.69643 18.3036 5.41518 18.3036 10C18.3036 14.5848 14.5848 18.3036 10 18.3036Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

PlusIcon.propTypes = {
  color: PropTypes.string,
};

export default PlusIcon;

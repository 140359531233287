import React from "react";
import PropTypes from "prop-types";

const QuestionCircleIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M10.5 1.25C5.66797 1.25 1.75 5.16797 1.75 10C1.75 14.832 5.66797 18.75 10.5 18.75C15.332 18.75 19.25 14.832 19.25 10C19.25 5.16797 15.332 1.25 10.5 1.25ZM10.5 17.2656C6.48828 17.2656 3.23438 14.0117 3.23438 10C3.23438 5.98828 6.48828 2.73438 10.5 2.73438C14.5117 2.73438 17.7656 5.98828 17.7656 10C17.7656 14.0117 14.5117 17.2656 10.5 17.2656Z"
      />
      <path
        fill={color}
        d="M9.71875 14.2969C9.71875 14.5041 9.80106 14.7028 9.94757 14.8493C10.0941 14.9958 10.2928 15.0781 10.5 15.0781C10.7072 15.0781 10.9059 14.9958 11.0524 14.8493C11.1989 14.7028 11.2812 14.5041 11.2812 14.2969C11.2812 14.0897 11.1989 13.891 11.0524 13.7444C10.9059 13.5979 10.7072 13.5156 10.5 13.5156C10.2928 13.5156 10.0941 13.5979 9.94757 13.7444C9.80106 13.891 9.71875 14.0897 9.71875 14.2969ZM12.6797 6.18555C12.0937 5.67383 11.3203 5.39062 10.5 5.39062C9.67969 5.39062 8.90625 5.67188 8.32031 6.18555C7.71094 6.71875 7.375 7.43555 7.375 8.20312V8.35156C7.375 8.4375 7.44531 8.50781 7.53125 8.50781H8.46875C8.55469 8.50781 8.625 8.4375 8.625 8.35156V8.20312C8.625 7.3418 9.4668 6.64062 10.5 6.64062C11.5332 6.64062 12.375 7.3418 12.375 8.20312C12.375 8.81055 11.9453 9.36719 11.2812 9.62305C10.8672 9.78125 10.5156 10.0586 10.2637 10.4219C10.0059 10.793 9.875 11.2383 9.875 11.6895V12.1094C9.875 12.1953 9.94531 12.2656 10.0312 12.2656H10.9688C11.0547 12.2656 11.125 12.1953 11.125 12.1094V11.666C11.126 11.4764 11.1841 11.2915 11.2918 11.1354C11.3994 10.9793 11.5516 10.8593 11.7285 10.791C12.8809 10.3477 13.625 9.33203 13.625 8.20312C13.625 7.43555 13.2891 6.71875 12.6797 6.18555Z"
      />
    </svg>
  );
};

QuestionCircleIcon.propTypes = {
  color: PropTypes.string,
};

export default QuestionCircleIcon;

import CancelCircleIcon from "./CancelCircleIcon";
import CheckCircleIcon from "./CheckCircleIcon";
import CheckCircleOutlineIcon from "./CheckCircleOutlineIcon";
import FeatherIcon from "./FeatherIcon";
import QuestionIcon from "./QuestionIcon";
import LightBulbIcon from "./LightBulbIcon";
import TeamIcon from "./TeamIcon";
import AuditIcon from "./AuditIcon";
import InfoIcon from "./InfoIcon";
import CloseIcon from "./CloseIcon";
import FileUnknownIcon from "./FileUnknownIcon";
import WarningIcon from "./WarningIcon";
import QuestionCircleIcon from "./QuestionCircleIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import FileDoneIcon from "./FileDoneIcon";
import CircleWithTickIcon from "./CircleWithTickIcon";
import MenuIcon from "./MenuIcon";
import EyeIcon from "./EyeIcon";
import Edit from "./Edit";
import Send from "./Send";
import ArrowLeft from "./ArrowLeft";
import ArrowRightBig from "./ArrowRightBig";
import AddSignee from "./AddSignee";
import PlusIcon from "./PlusIcon";
import TickIcon from "./TickIcon";
import CrossIcon from "./CrossIcon";
import WaitIconCircle from "./WaitIconCircle";
import ErrorCircle from "./ErrorCircle";
import ThreeVerticalDotsIcon from "./ThreeVerticalDotsIcon";
import DeclineIcon from "./DeclineIcon";
import UserIcon from "./UserIcon";
import SettingsIcon from "./SettingsIcon";
import SendIcon from "./SendIcon";
import LogOutIcon from "./LogOutIcon";
import DashboardIcon from "./DashboardIcon";
import FolderIcon from "./FolderIcon";
import AddFolderIcon from "./AddFolderIcon";
import CreateAddFileIcon from "./CreateAddFileIcon";
import MoreIcon from "./MoreIcon";
import FileIcon from "./FileIcon";
import FolderOpenIcon from "./FolderOpenIcon";
import RenameIcon from "./RenameIcon";
import ExportIcon from "./ExportIcon";
import DeleteIcon from "./DeleteIcon";
import StatusPendingIcon from "./StatusPendingIcon";
import StatusPendingIconFilled from "./StatusPendingIconFilled";
import StatusAcceptedIcon from "./StatusAcceptedIcon";
import StatusDraftIcon from "./StatusDraftIcon";
import StatusDeclinedIcon from "./StatusDeclinedIcon";
import WarningFillIcon from "./WarningFillIcon";
import PlusFillIcon from "./PlusFillIcon";
import WorkspaceIcon from "./WorkspaceIcon";
import OfficeIcon from "./OfficeIcon";
import InfoCircleIcon from "./InfoCircleIcon";
import ClockTimeIcon from "./ClockTimeIcon";
import TitleIcon from "./TitleIcon";
import ListIcon from "./ListIcon";
import PaperClipIcon from "./PaperClipIcon";
import ContactIcon from "./ContactIcon";
import PdfIcon from "./PdfIcon";
import StatusAcceptedIconWithWhiteBackground from "./StatusAcceptedIconWithWhiteBackground";
import WarningFillIconWithWhiteBackground from "./WarningFillIconWithWhiteBackground";
import ArrowTopIcon from "./ArrowTopIcon";
import ArrowBottomIcon from "./ArrowBottomIcon";
import PlusCreatorIcon from "./PlusCreatorIcon";
import SwiftsignLogoIcon from "./SwiftsignLogoIcon";
import DownloadIcon from "./DownloadIcon";
import ErrorBigIcon from "./ErrorBigIcon";
import Bars from "./Bars";
import Comments from "./Comments";
import MobileMenu from "./MobileMenu";
import PlusOutlined from "./PlusOutlined";
import CloseCicleFilled from "./CloseCicleFilled";
import UploadedCircleFilled from "./UploadedCircleFilled";
import Calendar from "./Calendar";
import Enter from "./Enter";
import Checkbox from "./Checkbox";
import NumberQuestion from "./NumberQuestion";
import RadioFilled from "./RadioFilled";
import TextTag from "./TextTag";
import AdressQuestion from "./AdressQuestion";
import MoneyQuestion from "./MoneyQuestion";
import YesNoQuestion from "./YesNoQuestion";
import DataField from "./DataField";
import Check from "./Check";
import TrashBin from "./TrashBin";
import Ascending from "./Ascending";
import Descending from "./Descending";
import Duplicate from "./DuplicateIcon";
import Plus from "./Plus";
import EditFile from "./EditFile";
import EyeInvisible from "./eyeInvisible";
import ShortAnswerQuestion from "./ShortAnswerQuestion";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CancelCircleIcon,
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  FeatherIcon,
  QuestionIcon,
  LightBulbIcon,
  TeamIcon,
  AuditIcon,
  InfoIcon,
  CloseIcon,
  FileUnknownIcon,
  WarningIcon,
  QuestionCircleIcon,
  ArrowRightIcon,
  FileDoneIcon,
  CircleWithTickIcon,
  MenuIcon,
  EyeIcon,
  Edit,
  Send,
  ArrowLeft,
  ArrowRightBig,
  AddSignee,
  PlusIcon,
  TickIcon,
  CrossIcon,
  WaitIconCircle,
  ErrorCircle,
  ThreeVerticalDotsIcon,
  DeclineIcon,
  UserIcon,
  SettingsIcon,
  SendIcon,
  LogOutIcon,
  DashboardIcon,
  FolderIcon,
  AddFolderIcon,
  CreateAddFileIcon,
  MoreIcon,
  FileIcon,
  FolderOpenIcon,
  RenameIcon,
  ExportIcon,
  DeleteIcon,
  StatusPendingIcon,
  StatusPendingIconFilled,
  StatusAcceptedIcon,
  StatusDraftIcon,
  StatusDeclinedIcon,
  WarningFillIcon,
  PlusFillIcon,
  WorkspaceIcon,
  OfficeIcon,
  InfoCircleIcon,
  ClockTimeIcon,
  TitleIcon,
  ListIcon,
  PaperClipIcon,
  ContactIcon,
  PdfIcon,
  StatusAcceptedIconWithWhiteBackground,
  WarningFillIconWithWhiteBackground,
  ArrowTopIcon,
  ArrowBottomIcon,
  PlusCreatorIcon,
  SwiftsignLogoIcon,
  DownloadIcon,
  ErrorBigIcon,
  Bars,
  Comments,
  MobileMenu,
  PlusOutlined,
  CloseCicleFilled,
  UploadedCircleFilled,
  Calendar,
  Enter,
  Checkbox,
  NumberQuestion,
  RadioFilled,
  TextTag,
  AdressQuestion,
  MoneyQuestion,
  YesNoQuestion,
  DataField,
  Check,
  TrashBin,
  Ascending,
  Descending,
  Duplicate,
  Plus,
  EditFile,
  EyeInvisible,
  ShortAnswerQuestion,
};

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { mergeRegister } from "@lexical/utils";
import {
  $getNodeByKey,
  $getRoot,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  createCommand,
} from "lexical";
import { $createTagNode, TagNode } from "../../nodes/TagNode";

export const INSERT_TAG_COMMAND = createCommand();
export const REMOVE_ALL_TAGS_COMMAND = createCommand();
export const REMOVE_ONE_TAG_BY_KEY_COMMAND = createCommand();
export const SCROLL_INTO_TAG = createCommand();

export function TagsPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([TagNode])) {
      throw new Error("TagsPlugin: TagNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_TAG_COMMAND,
        ({ id, appendToStart = false }) => {
          const tagNode = $createTagNode(id);

          if (appendToStart) {
            $getRoot().selectStart();
          }

          $insertNodes([tagNode]);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_ALL_TAGS_COMMAND,
        (payload) => {
          editor.getEditorState()._nodeMap.forEach((node) => {
            if (node instanceof TagNode && node.getId() === payload) {
              node.remove();
            }
          });
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_ONE_TAG_BY_KEY_COMMAND,
        (payload) => {
          const node = $getNodeByKey(payload);

          node.remove();
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        SCROLL_INTO_TAG,
        (payload) => {
          const nodes = Array.from(editor.getEditorState()._nodeMap.values());
          const firstNode = nodes.find((node) => {
            return node instanceof TagNode && node.getId() === payload;
          });

          if (firstNode) {
            const domNode = editor.getElementByKey(firstNode.__key);

            if (domNode) {
              domNode.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              });

              // domNode.firstChild.click();
            }
          }
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, []);

  return null;
}

import React from "react";
import PropTypes from "prop-types";

const LogOutIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M21.079 17.07H19.638C19.5255 17.07 19.4317 17.1637 19.4317 17.2762V18.9316H2.56476V2.06192H19.4344V3.71728C19.4344 3.82978 19.5282 3.92353 19.6407 3.92353H21.0817C21.1942 3.92353 21.288 3.83246 21.288 3.71728V1.03335C21.288 0.577993 20.921 0.211029 20.4657 0.211029H1.53619C1.08083 0.211029 0.713867 0.577993 0.713867 1.03335V19.9601C0.713867 20.4155 1.08083 20.7825 1.53619 20.7825H20.463C20.9183 20.7825 21.2853 20.4155 21.2853 19.9601V17.2762C21.2853 17.161 21.1915 17.07 21.079 17.07ZM21.5773 10.328L17.7764 7.32799C17.6344 7.21549 17.4282 7.31728 17.4282 7.49674V9.53246H9.01744C8.89958 9.53246 8.80315 9.62889 8.80315 9.74675V11.2467C8.80315 11.3646 8.89958 11.461 9.01744 11.461H17.4282V13.4967C17.4282 13.6762 17.6371 13.778 17.7764 13.6655L21.5773 10.6655C21.6029 10.6455 21.6236 10.6198 21.6378 10.5906C21.6521 10.5614 21.6595 10.5293 21.6595 10.4967C21.6595 10.4642 21.6521 10.4321 21.6378 10.4029C21.6236 10.3737 21.6029 10.348 21.5773 10.328Z"
      />
    </svg>
  );
};

LogOutIcon.propTypes = {
  color: PropTypes.string,
};

export default LogOutIcon;

import React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// import {$getRoot, $getSelection} from 'lexical';
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import styles from "./index.module.scss";
import { ContentEditable } from "./components/ContentEditable";
import { defaultNodes, defaultOnError, defaultTheme } from ".";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TagsPlugin } from "./plugins/TagsPlugin";
import CommentViewPlugin from "./plugins/CommentViewPlugin";
import CommentPlugin from "./plugins/CommentPlugin";
// import { TablePlugin } from "./plugins/TablePlugin";
// import TableCellResizerPlugin from "./plugins/TableCellResizer";

export function Editor({
  onError = defaultOnError,
  onChange,
  disableSelectionChange,
  state,
  useOutsideComposer,
  hasComments = false,
  showCommentPlugin,
}) {
  const initialConfig = {
    namespace: "Editor",
    theme: defaultTheme,
    onError,
    nodes: defaultNodes,
    editorState: state,
  };

  const [floatingAnchorElem, setFloatingAnchorElem] = React.useState(null);
  const [isFocused, setIsFocused] = React.useState(false);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return useOutsideComposer ? (
    <>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable
              className={styles["ContentEditable__root"]}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
        }
        placeholder={
          !isFocused && (
            <div className={styles["editor-placeholder"]}>
              Start adding document content here...
            </div>
          )
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && (
        <>
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
          {/* <TableCellActionMenuPlugin
            anchorElem={floatingAnchorElem}
            cellMerge={true}
          /> */}
        </>
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
      <HistoryPlugin />
      <ListPlugin />
      <TagsPlugin />
      {/* <TablePlugin
        hasCellMerge={true}
        hasCellBackgroundColor={true}
      />
      <TableCellResizerPlugin /> */}
      {hasComments && (showCommentPlugin ? (
          <CommentPlugin />
        ) : (
          <CommentViewPlugin />
      ))}
    </>
  ) : (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable className={styles["ContentEditable__root"]} />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && (
        <>
          <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
          <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
          {/* <TableCellActionMenuPlugin
            anchorElem={floatingAnchorElem}
            cellMerge={true}
          /> */}
        </>
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
      <HistoryPlugin />
      <ListPlugin />
      <TagsPlugin />
      {/* <TablePlugin
        hasCellMerge={false}
        hasCellBackgroundColor={false}
      />
      <TableCellResizerPlugin /> */}
      {hasComments && (showCommentPlugin ? (
          <CommentPlugin />
        ) : (
          <CommentViewPlugin />
      ))}
    </LexicalComposer>
  );
}

import { createActions } from "redux-actions";

import { DASHBOARD } from "../types";

export const { setActiveFolder, saveFile, resetPassword } = createActions({
  [DASHBOARD.SET_ACTIVE_FOLDER]: (folderId) => ({
    folderId,
  }),
  [DASHBOARD.SAVE_FILE]: (file, callback) => ({ file, callback }),
  [DASHBOARD.RESET_PASSWORD]: (callback) => ({ callback }),
});

import Icons from "../../../Icons";
import { TYPE } from "../../ContractRepository/data-fields";
import { Typography } from "antd";
import templateStyles from "../../../../pages/Template/index.module.scss";

const { Text } = Typography;

export const testitems = [
  {
    key: TYPE.TEXT,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.TextTag size="20" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Text</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            All characters are allowed. Used for shorter and longer text.
          </Text>
        </div>
      </div>
    ),
  },
  {
    key: TYPE.NUMBER,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.NumberQuestion size="20" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Number</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            Only numbers allowed. Used for adding values and currencies.
          </Text>
        </div>
      </div>
    ),
  },
  {
    key: TYPE.DATE,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.Calendar size="20" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Date</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            Only dates allowed. You can format the way it displays later on.
          </Text>
        </div>
      </div>
    ),
  },
  {
    key: TYPE.SELECT,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.RadioFilled size="20" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Select</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            Specify available options and choose one from the list
          </Text>
        </div>
      </div>
    ),
  },
  {
    key: TYPE.CHECKBOX,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.Checkbox size="20" />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Checkbox</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            used for a single option that can be turned off
          </Text>
        </div>
      </div>
    ),
  },
  {
    key: TYPE.LOCATION,
    label: (
      <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
        <div style={{ width: 20, display: "flex" }}>
          <Icons.AdressQuestion size="20" />
        </div>
        <div id="location" style={{ display: "flex", flexDirection: "column" }}>
          <Text style={{ fontWeight: "500", fontSize: 14 }}>Location</Text>
          <Text
            type="secondary"
            style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
          >
            Used to add an addres from a list
          </Text>
        </div>
      </div>
    ),
  },
];

export const items = [
  {
    label: "Edit Data type",
    key: "edit",
    icon: <Icons.TextTag size={20} circleColor="transparent" color="black" />,
  },
  {
    label: "Delete",
    key: "remove",
    icon: <Icons.DeleteIcon size={20} />,
    className: templateStyles["danger-dropdown-item"],
  },
];

export const typeToIcon = {
  [TYPE.CHECKBOX]: Icons.Checkbox,
  [TYPE.DATE]: Icons.Calendar,
  [TYPE.LOCATION]: Icons.AdressQuestion,
  [TYPE.NUMBER]: Icons.NumberQuestion,
  [TYPE.SELECT]: Icons.RadioFilled,
  [TYPE.TEXT]: Icons.TextTag,
};

import React from "react";
import PropTypes from "prop-types";

const Ascending = ({ size = 24, color = "#2B2A35", className }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7733 10.407L18.3465 2.81859C18.3307 2.76781 18.299 2.72346 18.2561 2.6921C18.2131 2.66074 18.1612 2.64404 18.1081 2.64448H16.0349C15.925 2.64448 15.8313 2.71412 15.7965 2.81859L13.3509 10.407C13.3429 10.4311 13.3375 10.4579 13.3375 10.4847C13.3375 10.6213 13.45 10.7338 13.5867 10.7338H15.0974C15.2099 10.7338 15.3063 10.6588 15.3384 10.5516L15.8072 8.90162H18.1911L18.6545 10.5489C18.684 10.6561 18.7831 10.7311 18.8956 10.7311H20.5349C20.5617 10.7311 20.5858 10.7284 20.6099 10.7204C20.6742 10.6989 20.725 10.6561 20.7572 10.5972C20.7867 10.5382 20.792 10.4713 20.7733 10.407ZM16.0509 7.50609L16.9295 4.37484H17.0983L17.9581 7.50609H16.0509ZM19.8947 20.7088H16.4233V20.698L19.975 15.6382C20.0045 15.5954 20.0206 15.5472 20.0206 15.4936V14.5186C20.0206 14.382 19.9081 14.2695 19.7715 14.2695H14.3072C14.1706 14.2695 14.0581 14.382 14.0581 14.5186V15.6704C14.0581 15.807 14.1706 15.9195 14.3072 15.9195H17.5911V15.9302L14.0259 20.99C13.9962 21.0324 13.9803 21.0829 13.9804 21.1347V22.1097C13.9804 22.2463 14.0929 22.3588 14.2295 22.3588H19.892C20.0286 22.3588 20.1411 22.2463 20.1411 22.1097V20.9579C20.1415 20.9253 20.1354 20.893 20.1231 20.8627C20.1109 20.8325 20.0928 20.8051 20.0699 20.7819C20.047 20.7587 20.0197 20.7403 19.9896 20.7278C19.9595 20.7152 19.9273 20.7088 19.8947 20.7088ZM9.42683 17.5909H7.39112V3.39448C7.39112 3.27662 7.29469 3.18019 7.17683 3.18019H5.67683C5.55897 3.18019 5.46254 3.27662 5.46254 3.39448V17.5909H3.42683C3.24737 17.5909 3.14558 17.7998 3.25808 17.9391L6.25808 21.74C6.27813 21.7656 6.30374 21.7863 6.33297 21.8006C6.36221 21.8148 6.39431 21.8222 6.42683 21.8222C6.45935 21.8222 6.49145 21.8148 6.52069 21.8006C6.54993 21.7863 6.57554 21.7656 6.59558 21.74L9.59558 17.9391C9.7054 17.7998 9.6063 17.5909 9.42683 17.5909Z"
        fill={color}
      />
    </svg>
  );
};

Ascending.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Ascending;

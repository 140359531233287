import React from "react";
import PropTypes from "prop-types";

const FileUnknownIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M17.1914 5.63867L12.9883 1.43359C12.8711 1.31641 12.7109 1.25 12.5449 1.25H4.25C3.9043 1.25 3.625 1.5293 3.625 1.875V18.125C3.625 18.4707 3.9043 18.75 4.25 18.75H16.75C17.0957 18.75 17.375 18.4707 17.375 18.125V6.08008C17.375 5.91406 17.3086 5.75586 17.1914 5.63867ZM12.2578 2.69141L15.9336 6.36719H12.2578V2.69141ZM15.9688 17.3438H5.03125V2.65625H10.9297V6.875C10.9297 7.09256 11.0161 7.30121 11.17 7.45505C11.3238 7.60889 11.5324 7.69531 11.75 7.69531H15.9688V17.3438Z"
      />
      <path
        fill={color}
        d="M9.875 14.5312C9.875 14.697 9.94085 14.856 10.0581 14.9732C10.1753 15.0904 10.3342 15.1562 10.5 15.1562C10.6658 15.1562 10.8247 15.0904 10.9419 14.9732C11.0592 14.856 11.125 14.697 11.125 14.5312C11.125 14.3655 11.0592 14.2065 10.9419 14.0893C10.8247 13.9721 10.6658 13.9062 10.5 13.9062C10.3342 13.9062 10.1753 13.9721 10.0581 14.0893C9.94085 14.2065 9.875 14.3655 9.875 14.5312ZM8.35156 10.7226C8.35156 10.8281 8.4375 10.9082 8.54297 10.9082H9.17578C9.28125 10.9082 9.36719 10.8261 9.36719 10.7246C9.36719 10.1738 9.87109 9.71677 10.5 9.71677C11.1289 9.71677 11.6328 10.1738 11.6328 10.7226C11.6328 11.2168 11.2227 11.6445 10.6699 11.7168C10.293 11.7715 9.99609 12.1133 9.99219 12.5V13.125C9.99219 13.2324 10.0801 13.3203 10.1875 13.3203H10.8125C10.9199 13.3203 11.0078 13.2324 11.0078 13.125V12.8867C11.0078 12.7695 11.0859 12.6621 11.1973 12.6269C12.0684 12.3457 12.6621 11.5722 12.6484 10.6953C12.6328 9.6113 11.6875 8.72653 10.5293 8.71091C9.33008 8.69724 8.35156 9.60153 8.35156 10.7226Z"
      />
    </svg>
  );
};

FileUnknownIcon.propTypes = {
  color: PropTypes.string,
};

export default FileUnknownIcon;

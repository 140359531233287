import React, { useState, useRef, useEffect, useMemo } from "react";
import { Tooltip } from "antd";
import moment from "moment";
import { useDrag } from "react-dnd";
import { useDoubleTap } from "use-double-tap";
import { usePopper } from "react-popper";
import { useNavigate } from "react-router-dom";
import { returnStatusTooltip, statusDetect } from "../../utils/DocumentStatus";
import { Icons } from "../../components";
import DefaultUserImg from "../DefaultUserImg";

import * as keys from "../../utils/keys";

import stylesCard from "./index.module.scss";
import { DOCUMENT_CURRENT_STEP } from "../../utils/documentCurrentStep";
import { useDispatch, useSelector } from "react-redux";
import { useDocumentPermission } from "../../hooks/permissions/document-permissions";
import { userSelector } from "../../store/selectors/auth";

const WorkspaceCard = React.forwardRef((props, ref) => {
  const {
    item,
    width = false,
    handleExport,
    handleDelete,
    isDropdownActive = false,
    onOptionsPress = () => {},
    setNowEditing,
    nowEditing,
    updateDocument,
    getSortedDocuments,
    isLastCard,
    getDocument,
    setIsCreatingDocument,
    setIndex,
    setErrorLoadingScreenType,
    searchData,
  } = props;
  const user = useSelector(userSelector);

  const isDocumentOwner = useMemo(() => {
    return item?.users.find(u => u.userID === user?.userID && u.permission === "owner");
  }, [item, user]);

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const originalString = searchData?._highlightResult?.fileTitle?.value;
  const newString = originalString
    ?.replace(/<em>/g, '<span class="highlight">')
    ?.replace(/<\/em>/g, "</span> ");

  const navigate = useNavigate();

  const [editedValue, setEditedValue] = useState(null);
  const [showMoreUsers, setShowMoreUsers] = useState(null);
  const [signees, setSignees] = useState([]);
  const [selectRef, setSelectRef] = useState(null);
  const [selectPop, setSelectPop] = useState(null);

  const { styles, attributes } = usePopper(selectRef, selectPop, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [10, 12],
        },
      },
    ],
  });

  useEffect(() => {
    if (nowEditing !== item.documentID) {
      handleDetectEnter(null, true);
    } else {
      handleStartEdit();
    }
  }, [nowEditing]);

  useEffect(() => {
    if (item.signees) {
      let allSignees = item.signees.map((item) => item);

      if (allSignees.length <= 5) {
        setSignees(allSignees);
      } else if (allSignees.length > 5) {
        setSignees(allSignees.slice(0, 2));
        setShowMoreUsers(allSignees.length - 2);
      }
    }
  }, [item]);

  const [, drag] = useDrag(() => ({
    type: "any",
    item: { name: item.documentID },
  }));

  const handleDropdown = () => {
    if (!isDocumentOwner) return;
    onOptionsPress();
  };

  const bindDoubleClick = useDoubleTap(
    (event) => {
      setNowEditing(item?.documentID);
    },
    300,
    {
      onSingleTap: (event) => {
        onDocumentClick();
      },
    }
  );

  const handleStartEdit = () => {
    setEditedValue(inputRef.current.value);

    const target = inputRef.current;
    target.focus();
    target.readOnly = false;
    target.classList.add(stylesCard["edit-name"]);
  };

  const handleDetectEnter = async (e, isForce = false) => {
    const target = inputRef.current;

    if (isForce) {
      target.readOnly = true;
      target.classList.remove(stylesCard["edit-name"]);

      if (editedValue && editedValue !== item.fileTitle) {
        await updateDocument(item.documentID, { fileTitle: editedValue });

        await getSortedDocuments("LAST_UPDATED");
        setEditedValue(null);
      } else {
        setEditedValue(null);
      }

      return;
    }

    if (e?.key === "Enter" || e?.keyCode === 13) {
      target.readOnly = true;
      target.classList.remove(stylesCard["edit-name"]);

      if (editedValue && editedValue !== item.fileTitle) {
        setNowEditing(null);
      }
    }
  };

  const onDocumentClick = () => {
    if (nowEditing !== item?.documentID) {
      setErrorLoadingScreenType("load");
      getDocument(item?.documentID, undefined, undefined, () => {
        const navigateToWizard = () => {
          if (item?.status === "DRAFT") {
            const lastPrompt = item?.prompts?.find(
              (filterItem) => filterItem?.promptID === item?.lastAnsweredPrompt
            );

            const isSkippedPrompt =
              lastPrompt?.onSelect?.[lastPrompt?.answer?.string];

            if (isSkippedPrompt) {
              navigate(keys.REVIEW_INPUT, {
                state: { isPreviw: true, comeFromWizard: true },
              });
            } else {
              setIsCreatingDocument(true);
              setIndex(lastPrompt?.position);
              navigate(keys.WIZARD);
            }
          } else {
            navigate(keys.REVIEW_INPUT, {
              state: { isPreviw: true, comeFromWizard: true },
            });
          }
        };

        switch (item?.currentStep) {
          case DOCUMENT_CURRENT_STEP.CREATE: {
            navigateToWizard();
            break;
          }
          case DOCUMENT_CURRENT_STEP.REVIEW: {
            navigate(keys.REVIEW_INPUT, {
              state: { isPreviw: true, comeFromWizard: true },
            });
            break;
          }
          case DOCUMENT_CURRENT_STEP.SIGN: {
            dispatch(setIsCreatingDocument(true));
            navigate(keys.SIGN_SEND);
            break;
          }
          default: {
            navigateToWizard();
            break;
          }
        }

        setErrorLoadingScreenType("");
      });

      setTimeout(() => {
        setErrorLoadingScreenType("");
      }, 15000);
    }
  };

  const aprovedOrRejectedStatus = useMemo(() => {
    if (["AWAITING", "TO_SIGN"].includes(item?.status)) {
      // TODO: to add approved status uncomment these
      // if (item.approvers?.every(approver => approver.status === "approved")) {
      //   return "approved";
      // }

      if (
        item.approvers?.some((approver) => approver.status === "rejected") &&
        !item.approvers?.some((approver) => approver.status === "not_reviewed")
      ) {
        return "rejected";
      }
    }

    return null;
  }, [item]);

  return (
    <div
      ref={drag}
      className={isLastCard ? stylesCard["workspace-card__last-card"] : ""}
    >
      <span
        className={stylesCard["workspace-card"]}
        ref={ref}
        id={item.documentID}
      >
        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-title"]}`}
        >
          <div className={stylesCard["title"]} {...bindDoubleClick}>
            <input
              type="text"
              className={stylesCard["title__input"]}
              // defaultValue={item?.title}
              readOnly={true}
              ref={inputRef}
              value={editedValue !== null ? editedValue : item?.fileTitle}
              onKeyDown={(e) => handleDetectEnter(e, false)}
              onChange={(e) => {
                setEditedValue(e.target.value);
              }}
            />
          </div>
        </div>

        {newString ? (
          <div
            className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-last-update"]}`}
            onClick={() => {
              onDocumentClick();
            }}
          >
            <div
              style={{ width: "378px", textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: newString,
              }}
            ></div>
          </div>
        ) : (
          <div
            className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-last-update"]}`}
            onClick={() => {
              onDocumentClick();
            }}
          >
            <div className={stylesCard["last-update"]}>
              {moment(item?.updatedAt).format("MMM DD, YY")}
            </div>
          </div>
        )}

        <div
          onClick={() => {
            onDocumentClick();
          }}
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-status"]}`}
        >
          <Tooltip
            title={returnStatusTooltip(aprovedOrRejectedStatus ?? item?.status)}
            overlayClassName={"u-tooltip"}
          >
            <div
              className={`${stylesCard["status"]} ${stylesCard[item?.status]}`}
            >
              {statusDetect(aprovedOrRejectedStatus ?? item?.status)}
            </div>
          </Tooltip>
        </div>

        <div
          className={`${stylesCard["workspace-card__item"]} ${
            stylesCard["item-parties"]
          } ${width ? stylesCard["w-350"] : ""}`}
          onClick={() => {
            onDocumentClick();
          }}
        >
          <div className={stylesCard["parties"]}>
            <ul>
              {signees.map((item, index) => {
                return (
                  <li key={index}>
                    <DefaultUserImg
                      initials={item?.contact?.initials}
                      count={item.order}
                      firstName={item?.contact?.firstName}
                      lastName={item?.contact?.lastName}
                      withTooltip={true}
                      image={item?.contact?.image}
                    />
                  </li>
                );
              })}
              {showMoreUsers && (
                <li>
                  <DefaultUserImg initials={`+${showMoreUsers}`} />
                </li>
              )}
            </ul>
          </div>
          <div className={stylesCard["parties__count"]}>
            · {item?.signees?.length} Signees
          </div>
        </div>

        <div
          className={`${stylesCard["workspace-card__item"]} ${stylesCard["item-action"]}`}
        >
          <div
            ref={setSelectRef}
            onClick={() => handleDropdown()}
            className={stylesCard["action"]}
          >
            {isDocumentOwner && (
                <Icons.MoreIcon />
            )}
          </div>
        </div>

        <div
          ref={setSelectPop}
          style={{
            ...styles.popper,
          }}
          {...attributes.popper}
          className={`u-folder__dropdown ${isDropdownActive ? "active" : ""}`}
        >
          <div className={"u-folder__dropdown__row"}>
            {/* {item?.status !== "COMPLETED" && ( */}
            <div className={"u-folder__dropdown__item"}>
              <div
                onClick={() => setNowEditing(item.documentID)}
                className={"u-folder__dropdown__link"}
              >
                <i>
                  <Icons.RenameIcon />
                </i>
                <div className={"text"}>Rename</div>
              </div>
            </div>
            {/* )} */}
            {item?.status === "COMPLETED" && (
              <div className={"u-folder__dropdown__item"}>
                <div
                  onClick={handleExport}
                  className={"u-folder__dropdown__link"}
                >
                  <i>
                    <Icons.ExportIcon />
                  </i>
                  <div className={"text"}>Export</div>
                </div>
              </div>
            )}
            <div className={"u-folder__dropdown__item"}>
              <div
                onClick={handleDelete}
                className={"u-folder__dropdown__link"}
              >
                <i>
                  <Icons.DeleteIcon />
                </i>
                <div className={"text"}>Delete</div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>
  );
});

export default WorkspaceCard;

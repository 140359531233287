import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Typography,
  Space,
  Form,
  Dropdown,
  Affix,
  Tooltip,
  Input,
  notification,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";

import { Icons } from "../../../components";
import { TYPE, predefinedFields } from "./data-fields";
import { RenderDocument } from "./components/RenderDocument";
import { useDoubleTap } from "use-double-tap";

import { default as DataFieldFormItem } from "./components";
import { SelectDataField } from "./components/SelectDataField";
import styles from "../../../pages/ReviewInput/index.module.scss";
import "./index.scss";
import { getComparator, stableSort } from "../../../utils/stableSort";

const { Title, Text } = Typography;

export const WarningTooltip = ({ children, canShare }) => {
  return (
    <Tooltip
      title="You need permission to edit these."
      color="#A4AAFC"
      open={canShare ? false : undefined}
      overlayInnerStyle={{
        padding: "2px 4px",
        borderRadius: "3px",
        height: "20px",
        minHeight: "20px",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "16px",
      }}
      trigger="hover"
    >
      {children}
    </Tooltip>
  );
};

export const DataFieldContent = ({
  thisDocument,
  updateDocumentData,
  onClose,
  canShare,
  user,
  getDocument,
}) => {
  const [variables, setVariables] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState(null);
  const [nowEditing, setNowEditing] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [focus, setFocus] = useState("");
  const [form] = Form.useForm();

  const variablesCategories = useMemo(() => {
    const categories = [];

    variables.forEach((variable) => {
      const matchingField = predefinedFields
        .flatMap(({ options }) => options)
        .find(({ key }) => key === variable.key);

      const existingCategory = predefinedFields.find(({ options }) =>
        options.some(({ key }) => key === variable.key)
      );

      const label = matchingField ? existingCategory.label : "Custom";

      const category = categories.find(
        ({ label: mergedLabel }) => mergedLabel === label
      );

      if (category) {
        category.variables.push(variable);
      } else {
        categories.push({
          ...existingCategory,
          label,
          ...(!matchingField && { position: 99 }),
          variables: [variable],
        });
      }
    });

    return stableSort(
      categories.map((category) => ({
        ...category,
        variables: stableSort(category.variables, getComparator("position")),
      })),
      getComparator("position")
    );
  }, [variables]);

  useEffect(() => {
    if (user && thisDocument?.documentID) {
      getDocument(thisDocument?.documentID);
    }
  }, [user, thisDocument?.documentID]);

  useEffect(() => {
    if (thisDocument?.variables?.length) {
      setVariables(thisDocument?.variables);
    }
  }, []);

  function updateDocument(updatedVariables) {
    updateDocumentData(
      thisDocument.documentID,
      {
        variables: updatedVariables,
      },
      false,
      (error) => {
        if (error?.response?.status === 403) {
          notification.destroy();
          notification.info({
            message: (
              <Space>
                <span>New updates detected! 🌝</span>{" "}
                <button
                  style={{
                    background: "inherit",
                    border: "1px solid #EEEEEF",
                    borderRadius: "4px",
                  }}
                  onClick={() => {
                    window.location.reload(false);
                  }}
                >
                  Refresh page
                </button>
              </Space>
            ),
            duration: 3,
            placement: "bottom",
            icon: <></>,
            closeIcon: <></>,
            className: "select-dropwdown-notification",
          });
        }
      },
      undefined,
      undefined,
      undefined,
      true,
      true
    );
  }

  function updateVariables(updatedVariables) {
    const keys = {};
    //Remove all duplicates
    const newVariables = updatedVariables.filter((v) => {
      if (keys[v.key] && (v?.custom || v?.options?.custom)) {
        return false;
      }
      keys[v.key] = true;
      return true;
    });

    updateDocument(newVariables);
    setVariables(newVariables);
  }

  const handleMenuClick = (e, variableKey) => {
    if (e.key === "remove" && variableKey) {
      const newVariables = variables.filter(
        (variable) => variable.key !== variableKey
      );
      updateVariables(newVariables);
    }
  };

  const handleMenuItemClick = (option, variableKey) => {
    const updatedVariables = variables.map((variable) => {
      if (variable.key === variableKey) {
        return {
          ...variable,
          type: option.key,
          value: "",
          options: {
            ...variable?.options,
            custom: true,
          },
        };
      }

      return variable;
    });

    form.setFieldValue(variableKey, "");

    updateVariables(updatedVariables);
    setSelectedDropdownValue(null);
  };

  const handleSend = (newVariables) => {
    updateVariables(newVariables ?? variables);
  };

  const handleOnValuesChange = (changedValues) => {
    if (
      typeof changedValues !== "object" ||
      !Object.keys(changedValues).length
    ) {
      return;
    }

    const [changedValueName, changedValue] = Object.entries(changedValues)[0];

    const newVariables = variables?.map((variable) => {
      if (variable.key === changedValueName && variable.type !== "date") {
        return {
          ...variable,
          value: changedValue,
        };
      }
      return variable;
    });

    setVariables(newVariables);
  };

  const submitEdit = (oldValue, value) => {
    if (value.length && oldValue !== value) {
      const updatedVariable = variables.map((item) => {
        if (item.key === oldValue) {
          // create a new object with the same properties as the original item,
          // except for the updated value property
          return {
            ...item,
            key: value,
            options: {
              ...item?.options,
              custom: true,
            },
          };
        } else {
          return item;
        }
      });
      updateVariables(updatedVariable);
    }
    setNowEditing(null);
    setEditValue("");
  };

  const items = [
    {
      label: "Edit Data type",
      key: "edit",
      icon: <Icons.TextTag size={20} circleColor="transparent" color="black" />,
    },
    {
      label: "Delete",
      key: "remove",
      icon: <Icons.DeleteIcon size={20} />,
      className: styles["danger-dropdown-item"],
    },
  ];

  const testitems = [
    {
      key: TYPE.TEXT,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.TextTag size="20" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Text</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              All characters are allowed. Used for shorter and longer text.
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: TYPE.NUMBER,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.NumberQuestion size="20" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Number</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              Only numbers allowed. Used for adding values and currencies.
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: TYPE.DATE,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.Calendar size="20" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Date</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              Only dates allowed. You can format the way it displays later on.
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: TYPE.SELECT,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.RadioFilled size="20" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Select</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              Specify available options and choose one from the list
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: TYPE.CHECKBOX,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.Checkbox size="20" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Checkbox</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              used for a single option that can be turned off
            </Text>
          </div>
        </div>
      ),
    },
    {
      key: TYPE.LOCATION,
      label: (
        <div style={{ display: "flex", marginLeft: "-8px", gap: 8 }}>
          <div style={{ width: 20, display: "flex" }}>
            <Icons.AdressQuestion size="20" />
          </div>
          <div
            id="location"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Text style={{ fontWeight: "500", fontSize: 14 }}>Location</Text>
            <Text
              type="secondary"
              style={{ fontWeight: "500", fontSize: 12, lineHeight: "17px" }}
            >
              Used to add an addres from a list
            </Text>
          </div>
        </div>
      ),
    },
  ];

  const bindDoubleClick = useDoubleTap((e) => {
    const keyField = e?.target?.outerText;
    if (keyField?.length) setNowEditing(keyField);
  }, 300);

  const iconDoubleClick = useDoubleTap((key) => {
    setSelectedDropdownValue(key);
  });

  const handleFinish = (values) => {
    //console.log(values);
  };

  return (
    <div className={"c-review-upload"}>
      <div
        className="data-field-title-container"
        style={{
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          boxShadow: "0px -1px 0px 0px #ECEDFF inset",
        }}
      >
        <Title className="data-field-title" level={5}>
          Data Fields
        </Title>
        <Button
          onClick={onClose}
          type="text"
          shape="circle"
          icon={<Icons.CloseIcon size="12" />}
        />
      </div>
      <div
        className="c-review-upload__scrollable"
        style={{ pointerEvents: canShare ? "initial" : "none" }}
      >
        <SelectDataField
          shouldDisableDuplicated
          variables={variables}
          setVariables={setVariables}
          canShare={canShare}
          selectProps={{
            getPopupContainer: (trigger) => trigger.parentElement,
          }}
        />
        <Form
          form={form}
          onFinish={handleFinish}
          onValuesChange={handleOnValuesChange}
        >
          <Space direction="vertical" size={0} style={{ width: "100%" }}>
            {variablesCategories.map(({ label, variables: v }) => (
              <React.Fragment key={label}>
                <Title
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                  level={5}
                >
                  {label}
                </Title>

                {v?.map((dataField) => {
                  let DataFieldItem;
                  let Icon;

                  const filteredItems =
                    dataField?.options?.custom ?? dataField?.custom
                      ? items
                      : items.filter((item) => item.key === "remove");

                  switch (dataField.type) {
                    case TYPE.TEXT:
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      Icon = Icons.TextTag;
                      break;
                    case TYPE.DATE:
                      DataFieldItem = DataFieldFormItem.DateFormItem;
                      Icon = Icons.Calendar;
                      break;
                    case TYPE.NUMBER:
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      Icon = Icons.NumberQuestion;
                      break;
                    case TYPE.SELECT:
                      DataFieldItem = DataFieldFormItem.SelectFormItem;
                      Icon = Icons.RadioFilled;
                      break;
                    case TYPE.CHECKBOX:
                      DataFieldItem = DataFieldFormItem.CheckboxFormItem;
                      Icon = Icons.Checkbox;
                      break;
                    case TYPE.LOCATION:
                      DataFieldItem = DataFieldFormItem.LocationFormItem;
                      Icon = Icons.AdressQuestion;
                      break;
                    default:
                      // eslint-disable-next-line no-unused-vars
                      DataFieldItem = DataFieldFormItem.TextFormItem;
                      // eslint-disable-next-line no-unused-vars
                      Icon = Icons.TextTag;
                  }

                  const keyField = dataField.key;

                  return (
                    <DataFieldItem
                      key={keyField}
                      formName={keyField}
                      onFinish={handleSend}
                      focus={focus}
                      focusOn={dataField.key}
                      setFocus={setFocus}
                      type={dataField.type}
                      defaultValue={dataField.value}
                      variables={variables}
                      setVariables={setVariables}
                      optionsValues={dataField?.options}
                      arrayValues={dataField?.options?.value}
                      currentVariable={dataField}
                      disabled={!canShare}
                      canShare={canShare}
                      form={form}
                      icon={
                        <Dropdown
                          open={selectedDropdownValue === dataField?.key}
                          key={keyField}
                          placement="bottom"
                          getPopupContainer={(trigger) => trigger.parentElement}
                          menu={{
                            items: testitems,
                            onClick: (item) => {
                              handleMenuItemClick(item, keyField);
                            },
                            rootClassName: "dropdown-root-hover",
                          }}
                          trigger={["click"]}
                          autoFocus
                          autoAdjustOverflow={false}
                          overlayClassName="general-data-field-dropdown"
                          onOpenChange={(visible) => {
                            if (!visible) {
                              setSelectedDropdownValue(null);
                            }
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // fontWeight: "500",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                gap: 8,
                              }}
                            >
                              <button
                                onClick={() =>
                                  iconDoubleClick.onClick(
                                    dataField?.options?.custom ??
                                      dataField?.custom
                                      ? dataField.key
                                      : null
                                  )
                                }
                                style={{
                                  width: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  background: "transparent",
                                  padding: 0,
                                  border: 0,
                                  cursor:
                                    dataField?.options?.custom ??
                                    dataField?.custom
                                      ? "pointer"
                                      : "default",
                                }}
                              >
                                <Icon size={20} />
                              </button>
                              {nowEditing === keyField ? (
                                <Input
                                  onChange={(e) => setEditValue(e.target.value)}
                                  className="editing-input"
                                  autoFocus
                                  defaultValue={dataField.key}
                                  style={{
                                    width: "100%",
                                    height: "28px",
                                  }}
                                  onBlur={() =>
                                    submitEdit(dataField.key, editValue)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.currentTarget.blur();
                                    }
                                  }}
                                />
                              ) : (
                                <div
                                  {...bindDoubleClick}
                                  style={{
                                    width: 265,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={dataField.key}
                                >
                                  {dataField.key}
                                </div>
                              )}
                            </div>
                            {canShare && (
                              <Dropdown
                                getPopupContainer={(trigger) =>
                                  trigger.parentElement
                                }
                                menu={{
                                  items: filteredItems,
                                  onClick: (item) => {
                                    handleMenuClick(item, dataField.key);
                                    setSelectedDropdownValue(
                                      dataField?.options?.custom ??
                                        dataField?.custom
                                        ? dataField.key
                                        : null
                                    );
                                  },
                                }}
                                placement="bottomRight"
                                trigger={["click"]}
                              >
                                <Button
                                  type="text"
                                  className="data-icon-button"
                                  icon={<MoreOutlined />}
                                />
                              </Dropdown>
                            )}
                          </div>
                        </Dropdown>
                      }
                    />
                  );
                })}
              </React.Fragment>
            ))}
          </Space>
        </Form>
      </div>
    </div>
  );
};

const ContractRepository = ({
  thisDocument,
  updateDocumentData,
  isOpen,
  onClose,
  canShare,
  user,
  getDocument,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className={styles["c-review"]} id="pdf" style={{ marginTop: 36 }}>
      <div className={"c-review-repository-container"}>
        <RenderDocument
          loaded={loaded}
          setLoaded={setLoaded}
          thisDocument={thisDocument}
        />
        {loaded && (
          <Affix offsetTop={60}>
            <div className="c-review-repository-upload">
              {isOpen && (
                <DataFieldContent
                  {...{
                    thisDocument,
                    updateDocumentData,
                    onClose,
                    canShare,
                    user,
                    getDocument,
                  }}
                />
              )}
            </div>
          </Affix>
        )}
      </div>
    </div>
  );
};

export default ContractRepository;

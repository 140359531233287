import React from "react";
import classNames from "classnames/bind";
import { Icons } from "..";

import styles from "./index.module.scss";

const RightPanel = ({
  open,
  onClose,
  children,
  visibleBack = false,
  type,
  closeIconColor,
}) => {
  const cx = classNames.bind(styles);
  const overlayStyles = cx("overlay", {
    [styles["overlay__open"]]: open,
    [styles["overlay__hidden"]]: !open,
    [styles["overlay__visible-back"]]: visibleBack,
  });

  const drawerStyles = cx("drawer", {
    [styles["drawer__animate"]]: open,
    [styles["drawer__hidden"]]: !open,
  });

  const renderHeader = (type) => {
    switch (type) {
      case "arrowLeft":
        return (
          <div className={styles["drawer__header-arrow-left"]}>
            <button
              onClick={onClose}
              className={styles["drawer__button-arrow"]}
            >
              <Icons.ArrowLeft color={"#5F6CFF"} />
            </button>
            <p>Details</p>
          </div>
        );
      default:
        return (
          <div className={styles["drawer__header"]}>
            <button
              onClick={onClose}
              className={styles["drawer__button-cross"]}
            >
              <Icons.CloseIcon color={closeIconColor} />
            </button>
          </div>
        );
    }
  };

  return (
    <>
      <div className={overlayStyles} onClick={onClose} aria-hidden="true" />
      <div tabIndex="-1" className={drawerStyles}>
        {renderHeader(type)}
        <div className={styles["drawer-content"]}>{children}</div>
      </div>
    </>
  );
};

export default RightPanel;

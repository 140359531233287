import React from "react";
import PropTypes from "prop-types";

const UploadedCircleFilled = ({
  color = "#5F6CFF",
  circleColor = "#ECEDFF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill={circleColor} />
      <g clipPath="url(#clip0_273_11634)">
        <path
          d="M12.9283 3.60877H10.6783V2.75163C10.6783 2.6927 10.6301 2.64449 10.5712 2.64449H9.82115C9.76222 2.64449 9.71401 2.6927 9.71401 2.75163V3.60877H6.28544V2.75163C6.28544 2.6927 6.23722 2.64449 6.17829 2.64449H5.42829C5.36936 2.64449 5.32115 2.6927 5.32115 2.75163V3.60877H3.07115C2.8341 3.60877 2.64258 3.80029 2.64258 4.03734V12.9302C2.64258 13.1673 2.8341 13.3588 3.07115 13.3588H12.9283C13.1653 13.3588 13.3569 13.1673 13.3569 12.9302V4.03734C13.3569 3.80029 13.1653 3.60877 12.9283 3.60877ZM12.3926 12.3945H3.60686V7.3052H12.3926V12.3945ZM3.60686 6.39449V4.57306H5.32115V5.21591C5.32115 5.27484 5.36936 5.32306 5.42829 5.32306H6.17829C6.23722 5.32306 6.28544 5.27484 6.28544 5.21591V4.57306H9.71401V5.21591C9.71401 5.27484 9.76222 5.32306 9.82115 5.32306H10.5712C10.6301 5.32306 10.6783 5.27484 10.6783 5.21591V4.57306H12.3926V6.39449H3.60686Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_273_11634">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

UploadedCircleFilled.propTypes = {
  color: PropTypes.string,
};

export default UploadedCircleFilled;

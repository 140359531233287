import { DropDown, DropDownItem } from "./Dropdown";
import { AlignLeftOutlined, AlignRightOutlined, AlignCenterOutlined } from '@ant-design/icons'
import { FORMAT_ELEMENT_COMMAND } from 'lexical'
import styles from '../index.module.scss'


export function AlignDropdrown({
  editor,
  disabled = false,
}) {
  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`${styles["popup-item"]} ${styles["alignment"]}`}
      buttonAriaLabel="Formatting options for text alignment"
      ButtonIcon={AlignLeftOutlined}
      hideIcon
    >
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className={styles["item"]}>
        <AlignLeftOutlined className={styles["icon"]} />
        <span className={styles["text"]}>Left</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className={styles["item"]}>
        <AlignCenterOutlined className={styles["icon"]} />
        <span className={styles["text"]}>Center</span>
      </DropDownItem>
      <DropDownItem
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className={styles["item"]}>
        <AlignRightOutlined className={styles["icon"]} />
        <span className={styles["text"]}>Right</span>
      </DropDownItem>
    </DropDown>
  )
}
import React from "react";
import PropTypes from "prop-types";

const QuestionIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="28"
      viewBox="0 0 20 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M18.9996 5.74751C18.4996 4.65466 17.7889 3.67251 16.8817 2.83323C15.0389 1.12251 12.5924 0.179657 9.99958 0.179657C7.40672 0.179657 4.9603 1.12251 3.11744 2.82966C2.2103 3.67251 1.49958 4.65109 0.999582 5.74751C0.478153 6.89037 0.213867 8.10109 0.213867 9.35109V10.3154C0.213867 10.5368 0.392439 10.7154 0.613867 10.7154H2.54244C2.76387 10.7154 2.94244 10.5368 2.94244 10.3154V9.35109C2.94244 5.79751 6.10672 2.90823 9.99958 2.90823C13.8924 2.90823 17.0567 5.79751 17.0567 9.35109C17.0567 10.8082 16.5389 12.1797 15.5567 13.3225C14.5853 14.4547 13.2139 15.2654 11.696 15.6082C10.8282 15.8047 10.046 16.2939 9.49244 16.9939C8.9408 17.6916 8.64012 18.5546 8.63887 19.4439V20.5654C8.63887 20.7868 8.81744 20.9654 9.03887 20.9654H10.9674C11.1889 20.9654 11.3674 20.7868 11.3674 20.5654V19.4439C11.3674 18.8832 11.7567 18.3904 12.296 18.2689C14.3817 17.7975 16.2746 16.6725 17.6282 15.1011C18.3103 14.3047 18.8424 13.4189 19.2103 12.4582C19.5924 11.4618 19.7853 10.4154 19.7853 9.35109C19.7853 8.10109 19.521 6.8868 18.9996 5.74751ZM9.99958 23.8225C8.89601 23.8225 7.99958 24.7189 7.99958 25.8225C7.99958 26.9261 8.89601 27.8225 9.99958 27.8225C11.1032 27.8225 11.9996 26.9261 11.9996 25.8225C11.9996 24.7189 11.1032 23.8225 9.99958 23.8225Z"
      />
    </svg>
  );
};

QuestionIcon.propTypes = {
  color: PropTypes.string,
};

export default QuestionIcon;

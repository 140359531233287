import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import { CSelect, CInput, CCheckbox } from "../../../components";
import { currency, entities } from "../../../utils/helper";
import styles from "./index.module.scss";

const Name = ({
  groupId = "",
  placeholder,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  select,
  checkbox,
  form,
  setDefaultCardData,
  visibleIndex,
  position,
  prompts,
  cardData,
  isCheckboxChecked,
  label,
  documentStyles,
  fieldsError,
  options,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (form && visibleIndex === position) {
      if (groupId.includes("COMPANY_NAME")) {
        form.setFieldsValue({
          [`${groupId}###lastName`]: "GmbH",
        });

        setDefaultCardData([
          {
            name: `${groupId}###lastName`,
            value: "GmbH",
          },
        ]);
      }
      if (groupId.includes("CURRENCY")) {
        form.setFieldsValue({
          [`${groupId}###lastName`]: "€",
        });

        setDefaultCardData([
          {
            name: `${groupId}###lastName`,
            value: "€",
          },
        ]);
      }
    }
  }, [prompts]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###firstName`}>
            <CInput
              label={
                label?.name
                  ? label?.name
                  : t(
                      `wizard:labels.${
                        groupId.includes("COMPANY_NAME")
                          ? "company_name"
                          : groupId.includes("PERSON_NAME") ||
                            groupId.includes("VERIFICATION_NAME")
                          ? "first_name"
                          : "amount"
                      }`
                    )
              }
              placeholder={
                groupId.includes("COMPANY_NAME")
                  ? placeholder?.contact?.organizationName || ""
                  : groupId.includes("PERSON_NAME") ||
                    groupId.includes("VERIFICATION_NAME")
                  ? placeholder?.contact?.firstName || ""
                  : placeholder?.currency?.amount || ""
              }
              focusOn={numberOfInputForFocus === 0}
              onChange={() => {
                setNumberOfInputForFocus(0);
              }}
              onClick={() => {
                setNumberOfInputForFocus(0);
              }}
              type={groupId.includes("CURRENCY") ? "number" : null}
              font={documentStyles?.font}
              color={documentStyles?.color}
              inputmode={groupId.includes("CURRENCY") ? "decimal" : undefined}
              pattern={groupId.includes("CURRENCY") ? "d*" : undefined}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###lastName`}>
            {select ? (
              <CSelect
                label={
                  label?.type
                    ? label?.type
                    : groupId.includes("COMPANY_NAME")
                    ? t(`wizard:labels.company_type`)
                    : t(`wizard:labels.currency`)
                }
                showSearch={true}
                focusOn={numberOfInputForFocus === 1}
                onSelect={() => {
                  setNumberOfInputForFocus(10);
                }}
                onClick={() => {
                  setNumberOfInputForFocus(1);
                }}
                options={
                  options
                    ? options
                    : groupId.includes("COMPANY_NAME")
                    ? entities
                    : currency
                }
                placeholder={
                  groupId.includes("COMPANY_NAME")
                    ? placeholder?.contact?.organizationType
                    : ""
                }
                onChange={() => {}}
                font={documentStyles?.font}
                color={documentStyles?.color}
              />
            ) : (
              <CInput
                label={t(
                  `wizard:labels.${
                    groupId.includes("PERSON_NAME") ||
                    groupId.includes("VERIFICATION_NAME")
                      ? "last_name"
                      : ""
                  }`
                )}
                placeholder={placeholder?.contact?.lastName ?? ""}
                onChange={() => {
                  setNumberOfInputForFocus(1);
                }}
                onClick={() => {
                  setNumberOfInputForFocus(1);
                }}
                focusOn={numberOfInputForFocus === 1}
                font={documentStyles?.font}
                color={documentStyles?.color}
              />
            )}
          </Form.Item>
        </Col>
        {fieldsError && (
          <div className={styles["c-block-card__error"]}>{fieldsError}</div>
        )}
        {checkbox && (
          <Col className="gutter-row" span={24}>
            <Form.Item name={`${groupId}###checkbox`}>
              <CCheckbox
                type={"checkbox"}
                title={t("wizard:buttons.invite_recipient")}
                checked={isCheckboxChecked}
                font={documentStyles?.font}
                color={documentStyles?.color}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Name;

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDisclosure } from "../../../hooks/helpers/useDisclosure";
import styles from "../styles.module.scss";
import Icons from "../../Icons";
import { Button, Drawer, Popover, Typography } from "antd";
import { format, parseISO } from "date-fns";
import Reply from "./Reply";
import { SuggestionStatus } from "../constants";
import { useSelector } from "react-redux";
import { api } from "../../../services/api";
import { documentSelector } from "../../../store/selectors/prompts";
import { SUGGESTION_FORMAT_STRING } from "../constants";
import CustomTextarea from "./CustomTextArea";
import useMediaQuery from "../../../hooks/helpers/useMediaQuery";
import ActionsDrawer from "./ActionsDrawer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { DELETE_COMMENT_COMMAND, FREE_UPDATE_COMMENT_COMMAND } from "../../Lexical/nodes/Comments";
import { cloneDeep } from "lodash";
import { createUID } from "../../Lexical/plugins/AddCommentPlugin";
import classNames from "classnames";
import { useDocumentPermission } from "../../../hooks/permissions/document-permissions";
import { useComments } from "../../../contexts/CommentContext";
import { getInitialsFromName } from "../../../utils/getInitialsFromName";
import { isApple } from "../../../utils/platform";
import { RedoOutlined } from "@ant-design/icons";
import { sendCommentMailNotification } from "../../../services/api/mail-notification";

const Comment = ({ comment, active, activeEdit, setActiveEdit }) => {
  const [editor] = useLexicalComposerContext();
  const { user, isOwner } = useDocumentPermission();
  const comments = useComments();
  const thisDocument = useSelector(documentSelector);
  const editInputRef = useRef();

  const isMobile = useMediaQuery("(max-width: 1023px)");

  const {
    suggestionID,
    message,
    type,
    createdAt,
    resolved,
    replies,
    user: { firstName, lastName, initials },
  } = comment;

  const {
    isOpen: isReplyOpen,
    onClose: onReplyClose,
    onToggle: onReplyToggle,
  } = useDisclosure();
  const isEditingOpen = useMemo(() => {
    return activeEdit?.id === comment?.commentID && !activeEdit?.isReply
  }, [activeEdit, comment?.commentID])
  const {
    isOpen: isActionsOpen,
    onClose: onActionsClose,
    onToggle: onActionsToggle,
  } = useDisclosure();

  const [editedMessage, setEditedMessage] = useState(message);
  const [reply, setReply] = useState("");

  const name = useMemo(() => {
    return `${firstName} ${lastName}`;
  }, [firstName, lastName]);

  const formattedDate = useMemo(() => {
    return format(parseISO(createdAt), SUGGESTION_FORMAT_STRING);
  }, [createdAt]);

  const resetSuggestions = useCallback(async () => {
    onActionsClose();
    setActiveEdit("");
  }, [onActionsClose, setActiveEdit]);

  const handleEditButtonClick = useCallback(() => {
    setActiveEdit(comment?.commentID);
    setTimeout(() => {
      const end = editInputRef.current?.value.length;
      editInputRef.current?.setSelectionRange(end, end);
      editInputRef.current?.focus();
    }, 100);
    onActionsClose();
  }, [onActionsClose, setActiveEdit]);

  const handleEdit = useCallback(async () => {
    if (!editedMessage.length || editedMessage === message) {
      setActiveEdit("");
      onActionsClose();
    }

    if (comment.independent) {
      api.patch(`comments/${comment.commentID}`, {
        message: editedMessage
      }, {
        params: {
          userID: user?.userID
        }
      }).then(res => {
        comments.updateByCommentID(comment.commentID, res.data);
        resetSuggestions();
      })
      return;
    }

    const instance = cloneDeep(comment)

    instance.message = editedMessage
    editor.dispatchCommand(FREE_UPDATE_COMMENT_COMMAND, instance);
    resetSuggestions();
  }, [
    comment,
    editedMessage,
    onActionsClose,
    setActiveEdit,
    message,
    editor,
    resetSuggestions
  ]);

  const handleCancelEdit = useCallback(() => {
    setEditedMessage(message);
    setActiveEdit("");
  }, [setActiveEdit, message]);

  const handleResolve = useCallback(async () => {
    if (comment.independent) {
      api.patch(`comments/${comment.commentID}`, {
        status: 'resolved'
      }).then(res => {
        comments.updateByCommentID(comment.commentID, {
          ...res.data,
          resolved: res.data.status === 'resolved'
        });
        resetSuggestions();
      });
      return;
    }

    const instance = cloneDeep(comment)

    editor.dispatchCommand(DELETE_COMMENT_COMMAND, instance);

    api.post('comments', {
      documentID: thisDocument.documentID,
      message: instance.message,
      status: 'resolved',
    }).then(res => {
      comments.add({
        ...res.data,
        independent: true,
        resolved: res.data.status === 'resolved'
      });
    });
  }, [editor, comment]);

  const handleUnresolve = useCallback(() => {
    api.patch(`comments/${comment.commentID}`, {
      status: 'sent'
    }).then(res => {
      comments.updateByCommentID(comment.commentID, {
        ...res.data,
        resolved: res.data.status === 'resolved'
      });
      resetSuggestions();
    });
    return;
  }, [comment])

  const handleDelete = useCallback(async () => {
    if (comment.independent) {
      api.delete(`comments/${comment.commentID}`, {
        params: {
          userID: user?.userID
        }
      }).then((res) => {
        comments.removeByCommentID(comment.commentID)
      })
    }

    const instance = cloneDeep(comment)

    editor.dispatchCommand(DELETE_COMMENT_COMMAND, instance);
  }, [editor, comment]);

  const handleReply = useCallback(async () => {
    if (!reply.length) return;

    if (comment.independent) {
      api.post('comments', {
        documentID: thisDocument.documentID,
        message: reply,
        repliedCommentID: comment.commentID,
      }, {
        params: {
          userID: user?.userID
        }
      }).then(res => {
        comments.updateByCommentID(comment.commentID, {
          ...res.data,
          independent: true
        });
      });
      setReply("");
      onReplyClose();
      return;
    }

    const instance = cloneDeep(comment)

    const dummyReplyInstance = {
      replyID: createUID(),
      message: reply,
      user: {
        userID: user?.userID,
        firstName: user?.firstName,
        lastName: user?.lastName,
        initials: user?.initials ?? getInitialsFromName({
          firstName: user?.firstName,
          lastName: user?.lastName,
        })
      },
      createdAt: new Date().toISOString(),
    }

    instance.replies.push(dummyReplyInstance);
    editor.dispatchCommand(FREE_UPDATE_COMMENT_COMMAND, instance);
    if (instance?.user?.email && instance.user.email !== user?.email) {
      sendCommentMailNotification({
        commentInstance: dummyReplyInstance,
        documentID: thisDocument.documentID,
        emailTo: instance?.user?.email
      })
    }
    setReply("");
    onReplyClose();
  }, [
    resetSuggestions,
    comment,
    editor,
    reply
  ]);

  const shouldShowEditItem = !resolved && user?.userID === comment.user?.userID;
  const shouldShowResolveItem = !resolved && isOwner;
  const shouldShowUnresolveItem = resolved && isOwner;
  const shouldShowDeleteItem = user?.userID === comment.user?.userID;

  const shouldShowActions = shouldShowEditItem || shouldShowResolveItem || shouldShowDeleteItem || shouldShowUnresolveItem;

  const actionsContent = useMemo(
    () => (
      <div className={styles.popover}>
        {shouldShowEditItem && (
          <button
            className={styles.popover__button}
            onClick={handleEditButtonClick}
          >
            <Icons.RenameIcon />
            Edit
          </button>
        )}
        {shouldShowResolveItem && (
          <button className={styles.popover__button} onClick={handleResolve}>
            <Icons.CheckCircleOutlineIcon />
            Resolve
          </button>
        )}
        {shouldShowUnresolveItem && (
          <button className={styles.popover__button} onClick={handleUnresolve}>
            <RedoOutlined />
            Reopen
          </button>
        )}
        {shouldShowDeleteItem && (
          <button className={classNames(
            styles.popover__button,
            styles.danger
          )} onClick={handleDelete}>
            <Icons.DeleteIcon />
            Delete
          </button>
        )}
      </div>
    ),
    [handleDelete, handleEditButtonClick, shouldShowEditItem, shouldShowResolveItem, shouldShowDeleteItem]
  );

  const isDocumentComplete = useMemo(() => {
    return thisDocument.status === "COMPLETED";
  }, [thisDocument])

  function onKeyboardEventReply(event) {
    const enter = event.code === 'Enter';

    if (enter && event.altKey && isReplyOpen) {
      handleReply();
      return;
    }

    // if (enter && event.altKey) {
    //   setNewComment(old => old + '\n');
    //   return;
    // }
  }

  function onKeyboardEventEdit(event) {
    const enter = event.code === 'Enter';

    if (enter && event.altKey && isEditingOpen) {
      handleEdit();
      return;
    }

    // if (enter && event.altKey) {
    //   setNewComment(old => old + '\n');
    //   return;
    // }
  }

  return (
    <>
      <div className={classNames(styles.comment, active ? styles.active : '')}>
        <div className={styles.comment__header}>
          <div className={styles.comment__row}>
            <Typography.Text className={styles.comment__initials}>
              {initials}
            </Typography.Text>
            <div className={styles.comment__column}>
              <Typography.Text className={styles.comment__name}>
                {name}
              </Typography.Text>
              <div className={styles.comment__row}>
                {resolved && (
                  <Typography.Text className={styles.comment__resolved}>
                    Resolved
                  </Typography.Text>
                )}
                <Typography.Text className={styles.comment__date}>
                  {formattedDate}
                </Typography.Text>
              </div>
            </div>
          </div>
          {shouldShowActions && !isDocumentComplete && (
            <Popover
              open={!isMobile && isActionsOpen}
              onOpenChange={onActionsToggle}
              trigger="click"
              content={actionsContent}
              showArrow={false}
              placement="bottom"
              overlayClassName={styles.popover}
            >
              <Button
                icon={
                  <Icons.MoreIcon color={isActionsOpen ? "#5F6CFF" : "#8C8C97"} />
                }
                type="text"
              />
            </Popover>
          )}
        </div>

        {isEditingOpen ? (
          <div className={styles.comment__replyContainer}>
            <CustomTextarea
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              ref={editInputRef}
              onKeyDown={onKeyboardEventEdit}
            />

            <div className={styles.comment__cancelSaveButtonsContainer}>
              <div className={styles.comment__tip}>
                {isApple() ? '⌥ + Return to post' : 'Alt + Enter to post'}
              </div>
              <div className={styles.comment__cancelSaveButtonsGroup}>
                <Button
                  type="text"
                  onClick={handleCancelEdit}
                  className={styles.comment__cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className={styles.comment__submitButton}
                  onClick={handleEdit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Typography.Paragraph className={
            classNames(
              styles.comment__message,
              resolved && !replies.length ? styles.reply : ''
            )
          }>
            {message}
          </Typography.Paragraph>
        )}

        {!!replies?.length && (
          <div className={styles.replyContainer}>
            {replies.map((reply) => (
              <Reply
                key={reply.replyID}
                {...{ reply, comment, activeEdit, setActiveEdit }}
              />
            ))}
          </div>
        )}

        {!isEditingOpen && !resolved && (
          <>
            {isReplyOpen ? (
              <div className={styles.comment__replyContainer}>
                <CustomTextarea
                  value={reply}
                  onChange={(e) => setReply(e.target.value)}
                  autoFocus
                  onKeyDown={onKeyboardEventReply}
                />

                <div className={styles.comment__cancelSaveButtonsContainer}>
                  <div className={styles.comment__tip}>
                    {isApple() ? '⌥ + Return to post' : 'Alt + Enter to post'}
                  </div>
                  <div className={styles.comment__cancelSaveButtonsGroup}>
                    <Button
                      type="text"
                      className={styles.comment__cancelButton}
                      onClick={onReplyClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      className={styles.comment__submitButton}
                      onClick={handleReply}
                    >
                      Reply
                    </Button>
                  </div>
                </div>
              </div>
            ) : ( !isDocumentComplete && (
              <Button
                type="text"
                onClick={onReplyToggle}
                className={styles.comment__toggleReplyButton}
              >
                Reply
              </Button>
            ))}
          </>
        )}
      </div>

      {isMobile && shouldShowActions && (
        <ActionsDrawer isOpen={isActionsOpen} onClose={onActionsClose}>
          {actionsContent}
        </ActionsDrawer>
      )}
    </>
  );
};

export default Comment;

import { Space, Form, DatePicker, Switch, Input, InputNumber } from "antd";
import moment from "moment";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { WarningTooltip } from "../index";

const dateFormat = "MMM DD, YY";

function subtractDays(date, timeIndays) {
  const diffMoment = moment(date).subtract(timeIndays, "days");
  const isoDate = diffMoment.toISOString();

  return isoDate;
}

export default function DateFormItem({
  formName,
  focus,
  setFocus,
  type,
  icon,
  defaultValue,
  focusOn,
  onFinish,
  variables,
  setVariables,
  currentVariable,
  form,
  disabled,
  canShare,
  optionsValues,
  allowClear,
  onDatePickerClose,
  noReminder,
}) {
  const [open, setOpen] = useState(false);
  const [variable, setVariable] = useState(currentVariable);
  const [switchDisable, setSwitchDisable] = useState(false);
  const [options, setOptions] = useState({
    ...(currentVariable.options?.daysToExpire &&
      currentVariable?.value && {
        reminderDate: subtractDays(
          currentVariable.value,
          currentVariable.options.daysToExpire
        ),
      }),
    daysToExpire: currentVariable.options?.daysToExpire,
    reminder: currentVariable.options?.reminder,
  });

  const datePickerRef = useRef(null);
  const defaultMoment = moment(defaultValue, moment.ISO_8601); // Parse ISO 8601 date format,

  function getReminderDate(value, days) {
    let reminderDate;
    if (value && days) {
      reminderDate = subtractDays(value, days);
    }

    return reminderDate;
  }

  const checkReminder = useCallback(() => {
    const isBefore = moment(options.reminderDate).isBefore(new Date());
    const invalidDate = options.reminderDate && variable.value && isBefore;
    if (invalidDate || !options.reminderDate) {
      setOptionsReminder(false);
      setSwitchDisable(true);
    } else if (!isBefore) {
      setSwitchDisable(false);
    }
  }, [options.reminderDate, variable.value]);

  function setOptionsReminder(checked) {
    setOptions((old) => ({
      ...old,
      reminder: checked,
    }));
  }

  function setOptionsDays(days) {
    const filteredDays = days > 0 ? Number(days) : "";
    const reminderDate = getReminderDate(variable.value, filteredDays);
    setOptions((old) => ({
      ...old,
      daysToExpire: filteredDays,
      reminderDate,
    }));
  }

  function setValue(newValue) {
    const reminderDate = getReminderDate(newValue, options.daysToExpire);
    setVariable((old) => ({
      ...old,
      value: moment(newValue, dateFormat),
    }));
    setOptions((old) => ({
      ...old,
      reminderDate,
    }));
  }

  const handlePickerOpen = () => {
    setOpen(true);
  };

  const handlePickerClose = useCallback(() => {
    setOpen(false);
    const currentVar = variables.find((v) => v.key === formName);
    const newVar = {
      ...currentVar,
      ...variable,
      options: {
        ...currentVar?.options,
        ...options,
      },
    };
    onFinish(
      variables.map((v) => {
        if (v.key === formName) {
          return newVar;
        }

        return v;
      }),
      newVar
    );
    onDatePickerClose?.(newVar);
  }, [formName, onFinish, options, variable, variables, onDatePickerClose]);

  useEffect(() => {
    checkReminder();
  }, [checkReminder]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        open &&
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        handlePickerClose();
      }
    };

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handlePickerClose, open]);

  return (
    <Space direction="vertical" style={{ width: "100%", position: "relative" }}>
      {icon}
      <WarningTooltip canShare={canShare}>
        <Form.Item
          name={formName}
          initialValue={defaultMoment.isValid() ? defaultMoment : undefined}
          className="field-input"
        >
          <DatePicker
            disabled={disabled}
            allowClear={allowClear}
            inputReadOnly
            open={open}
            onClick={handlePickerOpen}
            onChange={(_, dateString) => {
              setValue(dateString);
            }}
            size="small"
            showToday={false}
            format={dateFormat}
            style={{ width: "100%" }}
            getPopupContainer={(trigger) => trigger.parentElement}
            renderExtraFooter={() =>
              !noReminder && (
                <RenderExtraFooter
                  switchChecked={options.reminder}
                  switchDisable={switchDisable}
                  inputValue={options.daysToExpire}
                  onInputChange={setOptionsDays}
                  onSwitchChange={setOptionsReminder}
                  hasValue={!!variable?.value}
                />
              )
            }
            panelRender={(panel) => <div ref={datePickerRef}>{panel}</div>}
          />
        </Form.Item>
      </WarningTooltip>
    </Space>
  );
}

const RenderExtraFooter = ({
  switchChecked = false,
  inputValue,
  onInputChange,
  onSwitchChange,
  switchDisable,
  hasValue,
}) => {
  const inputDate = useRef(null);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 6,
        padding: "3px 0px",
      }}
    >
      <span>
        Remind me&nbsp;
        <Input
          min="1"
          ref={inputDate}
          type="number"
          value={inputValue}
          disabled={!hasValue}
          onChange={(event) => {
            onInputChange(event.target.value);
          }}
          style={{
            background: "#F3F3F6",
            padding: "6px 4px",
            borderRadius: "6px",
            width: 26,
            height: 28,
            border: 0,
            borderColor: "#F3F3F6 !important",
            boxShadow: "none",
          }}
          onClick={() => inputDate.current.focus()}
          onBlur={() => inputDate.current.blur()}
        />
        &nbsp;days before
      </span>
      <Switch
        checked={!!inputValue}
        title="Pick a valid time before the chosen date to be reminded"
        disabled={switchDisable}
        size="small"
        onChange={onSwitchChange}
      />
    </div>
  );
};

import React from "react";
import PropTypes from "prop-types";

const Duplicate = ({ color = "currentColor", className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_416_9204)">
        <rect width="24" height="24" transform="translate(0 0.5)" fill="none" />
        <path
          fill={color}
          d="M11.0358 11.4759C11.0358 11.3714 10.9528 11.2857 10.8483 11.2857H9.72332C9.62153 11.2857 9.53582 11.3714 9.53582 11.4759V13.75H7.27243C7.1626 13.75 7.07153 13.833 7.07153 13.9375V15.0625C7.07153 15.1643 7.1626 15.25 7.27243 15.25H9.53582V17.5241C9.53582 17.6286 9.62153 17.7143 9.72332 17.7143H10.8483C10.9528 17.7143 11.0358 17.6286 11.0358 17.5241V15.25H13.2992C13.409 15.25 13.5001 15.1643 13.5001 15.0625V13.9375C13.5001 13.833 13.409 13.75 13.2992 13.75H11.0358V11.4759ZM13.109 4.18036C12.9483 4.01964 12.7314 3.92857 12.5037 3.92857H3.42868C2.95457 3.92857 2.57153 4.31161 2.57153 4.78571V23.6429C2.57153 24.117 2.95457 24.5 3.42868 24.5H17.143C17.6171 24.5 18.0001 24.117 18.0001 23.6429V9.42768C18.0001 9.2 17.909 8.98304 17.7483 8.82232L13.109 4.18036ZM16.0715 22.5714H4.5001V5.85714H12.059L16.0715 9.86964V22.5714ZM21.1662 6.99821L14.6599 0.738393C14.4992 0.585714 14.2876 0.5 14.0653 0.5H6.21439C6.09653 0.5 6.0001 0.596429 6.0001 0.714286V2.21429C6.0001 2.33214 6.09653 2.42857 6.21439 2.42857H13.634L19.5001 8.06964V20.8571C19.5001 20.975 19.5965 21.0714 19.7144 21.0714H21.2144C21.3323 21.0714 21.4287 20.975 21.4287 20.8571V7.61429C21.4287 7.38125 21.3349 7.15893 21.1662 6.99821Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_416_9204">
          <rect
            width="24"
            height="24"
            fill="none"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Duplicate.propTypes = {
  color: PropTypes.string,
};

export default Duplicate;

import initialState from "../initialStates";
import injectReducer from "../injectReducer";

import { PROMPTS } from "../types";

export default injectReducer(initialState.promptsReducer, {
  [PROMPTS.SET_INDEX]: (state, { payload: { index } }) => {
    return {
      ...state,
      visibleIndex: index,
    };
  },
  [PROMPTS.SET_DOCUMENT_DATA]: (state, { payload: { document } }) => {
    return { ...state, document: document };
  },
  [PROMPTS.UPDATE_DOCUMENT_DATA_SUCCESS]: (
    state,
    {
      payload: {
        response: { documents },
        changePositions,
      },
    }
  ) => {
    if (changePositions) {
      const oldPrompts = [...documents.prompts];

      const newPrompts = oldPrompts.map((mapItem, index) => ({
        ...mapItem,
        position: index + changePositions,
      }));

      return { ...state, document: { ...documents, prompts: newPrompts } };
    } else {
      return { ...state, document: documents };
    }
  },
  [PROMPTS.SET_ADDRESS_BEFORE_CONFIRM]: (
    state,
    { payload: { addressData } }
  ) => {
    return { ...state, addressBeforeConfirm: addressData };
  },
  [PROMPTS.SET_NEW_DOCUMENT_DATA]: (
    state,
    { payload: { newDocumentData } }
  ) => {
    return {
      ...state,
      newDocumentData: newDocumentData,
    };
  },
  [PROMPTS.SET_SIGNEE_DATA]: (state, { payload: { newSigneeData } }) => {
    return {
      ...state,
      newSigneeData: newSigneeData,
    };
  },
  [PROMPTS.SET_AVAILABLE_HEADER_STEP]: (state, { payload: { step } }) => {
    return {
      ...state,
      availableHeaderStep: step,
    };
  },
  [PROMPTS.GET_DOCUMENT_SUCCESS]: (
    state,
    {
      payload: {
        response: { documents },
        changePositions,
      },
    }
  ) => {
    if (changePositions) {
      const oldPrompts = [...documents.prompts];

      const newPrompts = oldPrompts.map((mapItem, index) => ({
        ...mapItem,
        position: index + 1,
      }));

      return { ...state, document: { ...documents, prompts: newPrompts } };
    } else {
      return { ...state, document: documents };
    }
  },
  [PROMPTS.SET_IS_CREATING_DOCUMENT]: (state, { payload: { isCreating } }) => {
    return {
      ...state,
      isCreatingDocument: isCreating,
    };
  },
  [PROMPTS.LOAD_DOCUMENT_PDF_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
    };
  },
  [PROMPTS.SIGN_DOCUMENT_SUCCESS]: (
    state,
    {
      payload: {
        document: { documents },
      },
    }
  ) => {
    return {
      ...state,
      document: documents,
    };
  },
  // [PROMPTS.VERIFY_USER_SUCCESS]: (state) => {
  //   const oldPrompts = [...state.document.prompts].filter(
  //     (filterItem, index) => index !== 0
  //   );

  //   return {
  //     ...state,
  //     document: { ...state.document, prompts: oldPrompts },
  //   };
  // },
});

import React from "react";
import { Space, Form, Select } from "antd";
import { WarningTooltip } from "../index";

export default function CheckboxFormItem({
  formName,
  focus,
  setFocus,
  type,
  icon,
  defaultValue,
  focusOn,
  onFinish,
  disabled,
  canShare,
  onCheckboxChange,
}) {
  return (
    <Space direction="vertical" style={{ width: "100%", position: "relative" }}>
      {icon}

      <WarningTooltip canShare={canShare}>
        <Form.Item
          name={formName}
          initialValue={defaultValue ? defaultValue : undefined}
          className="field-input"
        >
          <Select
            disabled={disabled}
            placeholder={"Select option..."}
            onClick={() => setFocus(focusOn)}
            onChange={onCheckboxChange}
            onBlur={() => {
              onFinish();
              setFocus(0);
            }}
            getPopupContainer={(trigger) => trigger.parentElement}
            onSelect={() => {
              setTimeout(() => {
                setFocus(focusOn);
              }, 100);
            }}
            options={[
              {
                value: "yes",
                label: "Yes",
              },
              { value: "no", label: "No" },
            ]}
          />
        </Form.Item>
      </WarningTooltip>
    </Space>
  );
}

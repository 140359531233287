import Members from "./Members";
import Settings from "./Settings";
import CreateWorkspace from "./CreateWorkspace";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Members,
  Settings,
  CreateWorkspace,
};

import { Space, Form } from "antd";
import { CInput } from "../../../../components";
import { TYPE } from "../data-fields";
import { WarningTooltip } from "../index";
export default function TextFormItem({
  formName,
  focus,
  setFocus,
  type,
  icon,
  defaultValue,
  focusOn,
  onFinish,
  disabled,
  canShare,
}) {
  const isNumber = type === TYPE.NUMBER;

  return (
    <Space direction="vertical" style={{ width: "100%", position: "relative" }}>
      {icon}

      <WarningTooltip canShare={canShare}>
        <Form.Item
          name={formName}
          initialValue={defaultValue}
          className="field-input"
        >
          <CInput
            disabled={disabled}
            placeholder={isNumber ? "Add number here..." : "Add text here..."}
            focusOn={focus === focusOn}
            type={type}
            onClick={() => {
              setFocus(focusOn);
            }}
            onBlur={(e) => {
              onFinish(undefined, undefined, e.target.value);
              setFocus(0);
            }}
            customStyles="c-input"
          />
        </Form.Item>
      </WarningTooltip>
    </Space>
  );
}

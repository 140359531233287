import React, { useEffect, useState } from "react";

import { Form } from "antd";
import { Row, Col } from "antd";
import { CInput, CSelect, CButton, Icons } from "../../components";
import { Countries } from "../../countries";

import styles from "./index.module.scss";

export const ConfirmAddress = ({
  addressBeforeConfirm,
  showModal,
  onFinish,
  setShowModal,
  form,
  formName,
}) => {
  const [confirmAddressForm] = Form.useForm();

  function handleClose() {
    const confirmAddress = confirmAddressForm?.getFieldsValue();
    const address = Object.entries(confirmAddress)
      .filter(([key, value]) => value != null) // Removes entries with null or undefined values
      .map(([key, value]) => value) // Gets value for each remaining entry
      .join(" "); // Joins all values into a single string separated by space

    if (address) {
      form.setFieldValue(formName, address);
    }
    onFinish();
    setShowModal(false);
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13 || event.key === "Enter") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    confirmAddressForm.setFieldsValue(addressBeforeConfirm);
  }, []);

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["has-from"]}`}>
        <div className={`${styles["u-modal__form__head"]} ${styles["fd-row"]}`}>
          <span>Confirm company address</span>
          <span
            onClick={() => setShowModal(false)}
            className={styles["u-modal__form__close"]}
          >
            <Icons.CloseIcon />
          </span>
        </div>

        <Form
          layout="vertical"
          id="confirm-company-address"
          name="confirm-company-address"
          form={confirmAddressForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item name="country">
                <CSelect
                  label={"Country/Region"}
                  id="country-region"
                  name="country-region"
                  options={Countries.map((item) => item.name)}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="streetAddress">
                <CInput
                  label={"Street name"}
                  id="street-name"
                  name="street-name"
                  placeholder={"e.g. Emser Platz"}
                />
              </Form.Item>
              <Form.Item name="streetNumber">
                <CInput
                  label={"Street number"}
                  id="street-number"
                  name="street-number"
                  placeholder={"e.g. 6"}
                />
              </Form.Item>
              <Form.Item name="additionalAddressLine">
                <CInput
                  label={"Additional (optional)"}
                  placeholder={"e.g. Building A"}
                  id="additionalAddressLine"
                  name="additionalAddressLine"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item name="city">
                <CInput label={"City"} id="city" name="city" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item name="postalCode">
                <CInput label={"Zipcode"} id="zipcode" name="zipcode" />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={`${styles["u-modal__form__foot"]} ${styles["tal"]}`}>
          <div className={styles["u-modal__form__foot__item"]}>
            <CButton
              onClick={handleClose}
              type="primary"
              title={"Save & continue"}
              customStyles={styles["btn-form"]}
            />
            <div className={styles["or-with-enter"]}>or with ENTER</div>
          </div>
        </div>
      </div>
    </div>
  );
};

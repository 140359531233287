import React from 'react'


export const useDebounce = (fn, delay) => {
  const timeoutRef = React.useRef()

  const customClearTimeout = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  React.useEffect(() => {
    return () => {
      customClearTimeout()
    }
  }, [customClearTimeout])

  const debounceFn = React.useCallback(
    (...args) => {
      customClearTimeout()
      timeoutRef.current = setTimeout(() => {
        fn(...args)
      }, delay)
    },
    [customClearTimeout, delay, fn]
  )

  return debounceFn
}

import React from "react";
import PropTypes from "prop-types";

const WorkspaceIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.9852 0.45459H1.34881C0.97168 0.45459 0.666992 0.759277 0.666992 1.13641V18.8637C0.666992 19.2408 0.97168 19.5455 1.34881 19.5455H14.9852C15.3623 19.5455 15.667 19.2408 15.667 18.8637V1.13641C15.667 0.759277 15.3623 0.45459 14.9852 0.45459ZM9.4454 1.98868H11.4909V6.46098L10.5001 5.73868L9.4454 6.49294V1.98868ZM14.1329 18.0114H2.20108V1.98868H8.16699V8.31467C8.16699 8.38499 8.1883 8.4553 8.23091 8.51283C8.25678 8.54939 8.28964 8.58047 8.3276 8.60425C8.36555 8.62804 8.40785 8.64406 8.45203 8.65139C8.49622 8.65872 8.54142 8.65722 8.58502 8.64697C8.62863 8.63671 8.66976 8.61792 8.70605 8.59166L10.4916 7.31538L12.2259 8.58101C12.2835 8.62362 12.3538 8.64706 12.4262 8.64706C12.6137 8.64706 12.7671 8.49365 12.7671 8.30615V1.98868H14.1308V18.0114H14.1329Z"
        fill={color}
      />
    </svg>
  );
};

WorkspaceIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default WorkspaceIcon;

export function getInitialsFromName({
  firstName = '',
  lastName = ''
}) {
  const nameInArray = firstName.split(' ').concat(lastName.split(' '))
  const temp = nameInArray.map(e => {
    return e.charAt(0);
  });

  //max 3 letters
  return temp.join('').substring(0, 3);
}
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Checkbox } from "antd";
import { CSelect, CInput } from "../../../components";
import { format } from "../../../utils/FormatCurrency";
import styles from "./index.module.scss";

const Plan = ({
  groupId = "",
  setTotalPrice,
  totalPrice,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  form,
  setDefaultCardData,
  visibleIndex,
  position,
  prompts,
  cardData,
  documentStyles,
  fieldsError,
  discountOptions,
  moduleOptions,
  extrasOptions,
  billingOptions,
  trainingOptions,
  isCheckboxChecked,
  id,
  setTotalBilling,
}) => {
  const { t } = useTranslation();
  const [inputBeds, setInputBeds] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedDiscount, setSelectedDiscount] = useState();
  const [selectedBilling, setSelectedBilling] = useState();
  const [selectedModule, setSelectedModule] = useState([]);
  const [contract, setContract] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  function removeAfterFirstParenthesis(str) {
    const index = str.indexOf("(");
    return index !== -1 ? str.substring(0, index) : str;
  }

  const delayedSetSelectedPlan = (value) => {
    const id = setTimeout(() => {
      setSelectedPlan(value);
    }, 350);
    setTimeoutId(id); // Store the ID of the newly created timeout
  };

  const handleValueChange = (e, fieldName) => {
    const inputValue = e.target.value;

    const parsedValue = inputValue.replace(/\D/g, "");

    if (!isNaN(parsedValue)) {
      const formatted = format(parsedValue);

      setDefaultCardData([{ name: fieldName, value: formatted }]);
      clearTimeout(timeoutId); // Clear previously set timeout (if any)
      delayedSetSelectedPlan(parsedValue / 100); // Call our function with the updated value
    } else {
      setSelectedPlan(0);
    }
  };

  const selectOptions = useMemo(() => {
    if (discountOptions) {
      const newOptions = [
        { value: "0", label: "Kein Rabatt" },
        ...discountOptions,
      ];
      return newOptions;
    }
  }, [discountOptions]);

  const moduleOptionsValue = useMemo(() => {
    return moduleOptions?.map((option) => {
      const { label, value } = option;
      return {
        label,
        value: value.price,
      };
    });
  }, [moduleOptions]);

  const trainingOptionsValue = useMemo(() => {
    return trainingOptions?.map((option) => {
      const { label, value } = option;
      return {
        label,
        value: value.price,
      };
    });
  }, [trainingOptions]);

  const billingType = useMemo(() => {
    if (totalPrice && contract && billingOptions) {
      const perMonth = (totalPrice / contract).toFixed(2);
      const perQuarter = (perMonth * 3).toFixed(2);
      const perYear = (perMonth * 12).toFixed(2);
      const resultArr = [];
      billingOptions?.forEach((option) => {
        const { label, value } = option;

        switch (contract >= value ? value : 0) {
          case 1:
            resultArr.push(`${label} (€${perMonth})`);
            break;
          case 3:
            resultArr.push(`${label} (€${perQuarter})`);
            break;
          case 12:
            resultArr.push(`${label} (€${perYear})`);
            break;
          default:
            break;
        }
      });

      return resultArr;
    } else {
      return [];
    }
  }, [totalPrice, billingOptions, contract]);

  useEffect(() => {
    if (!!billingType.length && cardData) {
      if (!selectedBilling) {
        form.setFieldValue(
          `${groupId}###billing`,
          billingType.find((value) => value.includes("Quartalsweise"))
        );
        setSelectedBilling(
          billingType.find((value) => value.includes("Quartalsweise"))
        );
      } else {
        const alreadySelected = billingType.find(
          (value) =>
            removeAfterFirstParenthesis(value) ===
            removeAfterFirstParenthesis(selectedBilling)
        );
        form.setFieldValue(`${groupId}###billing`, alreadySelected);
      }
    } else {
      if (cardData && form) {
        setInputBeds(form.getFieldValue(`${groupId}###beds`));
        setSelectedPlan(form.getFieldValue(`${groupId}###plan`));
        setSelectedModule(form.getFieldValue(`${groupId}###module`));
        setSelectedDiscount(form.getFieldValue(`${groupId}###discount`));
        setSelectedBilling(form.getFieldValue(`${groupId}###billing`));
      }
    }
  }, [visibleIndex, position, billingType, cardData]);

  useEffect(() => {
    if (!totalPrice && cardData) {
      form.setFieldValue(`${groupId}###billing`, undefined);
    }
  }, [totalPrice, cardData]);

  useEffect(() => {
    if (selectedBilling && contract && totalPrice) {
      const title = selectedBilling
        ?.substring(0, selectedBilling?.indexOf("("))
        ?.trim();

      const result = billingOptions.find((item) => item.label === title)?.value;

      const perMonth = totalPrice / contract;

      const price = result * Number(perMonth);

      const outputObject = {
        title: title,
        price: Number(price.toFixed(2)),
      };
      setTotalBilling(outputObject);
    }
  }, [totalPrice, selectedBilling]);

  const calculateTotal = () => {
    const bedsValue = parseInt(inputBeds, 10);

    const moduleValue = selectedModule?.reduce(
      (total, value) => total + parseFloat(value),
      0
    );

    const total =
      (parseFloat(selectedPlan) + moduleValue) * bedsValue * contract;

    const validDiscount = selectedDiscount > 0 ? selectedDiscount : 0;
    const discountedTotal = (total * (100 - validDiscount)) / 100;

    return discountedTotal;
  };

  useEffect(() => {
    if (contract && selectedPlan && inputBeds) {
      const total = calculateTotal();
      setTotalPrice(Number(total.toFixed(2)));
    } else {
      setTotalPrice(0);
    }
  }, [selectedPlan, inputBeds, selectedModule, selectedDiscount, contract]);

  const validateBeds = (_, value) => {
    if (value !== "" && Number(value) <= 0) {
      return Promise.reject(
        new Error("Sie benötigen eine gültige Bettennummer!")
      );
    }
    return Promise.resolve();
  };

  const validateModule = (_, value) => {
    return Promise.resolve();
  };

  const validateExtras = (_, value) => {
    return Promise.resolve();
  };

  useEffect(() => {
    if (prompts) {
      const answerContractMonths = prompts
        .filter((question) => question?.questionType === "NOTICE")
        .map((question) => question?.answer?.months)?.[0];

      if (answerContractMonths && position === visibleIndex) {
        setContract(answerContractMonths);
      } else if (cardData) {
        const currentContractMonths = cardData?.filter((item) =>
          item?.name?.includes("contract")
        )?.[0]?.value;
        setContract(currentContractMonths);
      }
    }
  }, [prompts, form, cardData]);

  return (
    <>
      <style>
        {`.c-checkbox .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${documentStyles?.color ?? "#5f6cff"};
            border-color:  ${documentStyles?.color ?? "#5f6cff"};
          }

          .c-checkbox .ant-checkbox+span {
            font-family:  ${documentStyles?.font ?? "inherit"}
          }
          
          `}
      </style>

      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            name={`${groupId}###beds`}
            rules={[{ validator: validateBeds }]}
          >
            <CInput
              label={"Anzahl der Lizenzen"}
              placeholder={"0"}
              focusOn={numberOfInputForFocus === 0}
              onChange={(e) => {
                setNumberOfInputForFocus(0);
                setInputBeds(Number(e.target.value));
              }}
              onClick={() => {
                setNumberOfInputForFocus(0);
              }}
              type={"number"}
              font={documentStyles?.font}
              color={documentStyles?.color}
              inputmode={"numeric"}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###plan`}>
            <CInput
              label={"Monatspreis pro Lizenz"}
              placeholder={"0.00"}
              focusOn={numberOfInputForFocus === 1}
              onChange={(e) => handleValueChange(e, `${groupId}###plan`)}
              onClick={() => {
                setNumberOfInputForFocus(1);
              }}
              type={"number"}
              font={documentStyles?.font}
              color={documentStyles?.color}
              prefix={"€"}
              inputmode={"numeric"}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            name={`${groupId}###training`}
            label={
              <span style={{ fontWeight: 500 }} title="Training">
                Training
              </span>
            }
          >
            <Checkbox.Group
              options={trainingOptionsValue}
              className={"c-checkbox"}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            name={`${groupId}###module`}
            label={
              <span style={{ fontWeight: 500 }} title="Module">
                Module
              </span>
            }
            rules={[{ validator: validateModule }]}
          >
            <Checkbox.Group
              options={moduleOptionsValue}
              onChange={setSelectedModule}
              focusOn={numberOfInputForFocus === 2}
              className={"c-checkbox"}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" span={24}>
          <Form.Item
            name={`${groupId}###extras`}
            label={
              <span style={{ fontWeight: 500 }} title="Extras">
                Extras
              </span>
            }
            rules={[{ validator: validateExtras }]}
            focusOn={numberOfInputForFocus === 3}
          >
            <Checkbox.Group options={extrasOptions} className={"c-checkbox"} />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###discount`}>
            <CSelect
              label={"Rabatt"}
              showSearch={false}
              focusOn={numberOfInputForFocus === 4}
              onSelect={() => {
                setTimeout(() => {
                  setNumberOfInputForFocus(5);
                }, 100);
              }}
              onClick={() => {
                setNumberOfInputForFocus(4);
              }}
              options={selectOptions}
              placeholder={"Wählen Sie einen Rabatt"}
              onChange={setSelectedDiscount}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###billing`}>
            <CSelect
              label={"Abrechngungsart"}
              showSearch={false}
              focusOn={numberOfInputForFocus === 5}
              onSelect={() => {
                setTimeout(() => {
                  setNumberOfInputForFocus(6);
                }, 100);
              }}
              onClick={() => {
                setNumberOfInputForFocus(5);
              }}
              options={billingType}
              disabled={!billingType.length}
              placeholder={"Abrechnungsart auswählen"}
              onChange={setSelectedBilling}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>

        {fieldsError && (
          <div className={styles["c-block-card__error"]}>{fieldsError}</div>
        )}

        {!contract && (
          <div className={styles["c-block-card__error"]}>
            Gehen Sie zurück und wählen Sie eine Vertragslaufzeit aus!
          </div>
        )}
      </Row>
    </>
  );
};

export default Plan;

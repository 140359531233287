import React from "react";
import PropTypes from "prop-types";

const DataField = ({ size = 24, color = "#2B2A35", className }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8125 5.375C4.08763 5.375 3.5 5.94674 3.5 6.65203V17.598C3.5 18.3033 4.08763 18.875 4.8125 18.875H6.5V17.0507H5.375V7.19932H6.5V5.375H4.8125Z"
        fill={color}
      />
      <path
        d="M21.125 6.65203C21.125 5.94674 20.5374 5.375 19.8125 5.375H11V7.19932H19.25V17.0507H11V18.875H19.8125C20.5374 18.875 21.125 18.3033 21.125 17.598V6.65203Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8125 5.41327H6.5V3.5H11V5.41327H9.6875V18.875H11V20.7883H6.5V18.875H7.8125V5.41327Z"
        fill={color}
      />
    </svg>
  );
};

DataField.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default DataField;

import React from "react";
import PropTypes from "prop-types";

const DownloadIcon = ({
  color = "white",
  className,
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35993 11.3217C8.37664 11.3431 8.39798 11.3603 8.42235 11.3722C8.44671 11.3841 8.47346 11.3902 8.50056 11.3902C8.52766 11.3902 8.55441 11.3841 8.57877 11.3722C8.60314 11.3603 8.62448 11.3431 8.64118 11.3217L11.1412 8.15876C11.2327 8.04269 11.1501 7.87082 11.0006 7.87082H9.34654V0.317243C9.34654 0.219029 9.26618 0.138672 9.16797 0.138672H7.82868C7.73047 0.138672 7.65011 0.219029 7.65011 0.317243V7.86858H6.00056C5.85101 7.86858 5.76842 8.04046 5.85993 8.15653L8.35993 11.3217ZM16.6702 10.5405H15.3309C15.2327 10.5405 15.1523 10.6208 15.1523 10.719V14.1565H1.84877V10.719C1.84877 10.6208 1.76842 10.5405 1.6702 10.5405H0.330915C0.232701 10.5405 0.152344 10.6208 0.152344 10.719V15.1387C0.152344 15.5338 0.47154 15.853 0.86663 15.853H16.1345C16.5296 15.853 16.8488 15.5338 16.8488 15.1387V10.719C16.8488 10.6208 16.7684 10.5405 16.6702 10.5405Z"
        fill={color}
      />
    </svg>
  );
};

DownloadIcon.propTypes = {
  color: PropTypes.string,
};

export default DownloadIcon;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Icons } from "../../../components";
import styles from "./index.module.scss";

const Signature = ({ data }) => {
  return (
    <div className={styles["c-signature"]}>
      <div className={styles["c-signature__name"]}>
        {data?.contact?.firstName} {data?.contact?.lastName ?? ""}
      </div>
      {data?.signature?.style?.showPartyName && (
        <p className={styles["c-signature__text-info"]}>
          Signed for {data?.signature?.contractPartyName}
        </p>
      )}
      {data?.signature?.style?.showDate && (
        <p className={styles["c-signature__text-info"]}>
          {moment(data?.signature?.createdAt).format("YYYY-MM-DD")} at{" "}
          {moment(data?.signature?.createdAt).local().format("HH:mm")}
        </p>
      )}
      <div className={styles["c-signature__short-info"]}>
        <div className={styles["c-signature__short-info__left-side"]}>
          <div className={styles["c-signature__short-info__intials"]}>
            {data?.contact?.initials}
          </div>
          <div
            className={
              styles["c-signature__short-info__name-and-email__wrapper"]
            }
          >
            <div
              className={
                styles["c-signature__short-info__name-and-email__weight-600"]
              }
            >
              {data?.contact?.firstName} {data?.contact?.lastName ?? ""}
            </div>
            <div className={styles["c-signature__short-info__name-and-email"]}>
              {data?.contact?.email}
            </div>
          </div>
        </div>
        <Icons.CheckCircleIcon color={"#42B983"} />
      </div>
    </div>
  );
};

Signature.propTypes = {
  data: PropTypes.array,
};

export default Signature;

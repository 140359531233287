import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import PropTypes from "prop-types";
import { validateField } from "../../utils/helper";
import * as regular from "../../utils/regularExpression";

import { CInput } from "..";
import CCheckbox from "../Checkbox";
import moment from "moment";

const CDataSelect = ({
  groupId = "",
  previousDate,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  placeholder,
  inputCondition,
  visibleIndex,
  form,
  position,
  checkbox,
  isCheckboxChecked,
  documentStyles,
  cardData,
}) => {
  const { t } = useTranslation();
  const [dayError, setDayError] = useState("Incorrect day"); //variables that contain default error values
  const [monthError, setMonthError] = useState("Incorrect month");
  const [yearError, setYearError] = useState("Incorrect year");

  useEffect(() => {
    dayHandler();
    monthHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form && visibleIndex === position) {
      form.setFieldsValue({
        [`${groupId}###day${
          inputCondition?.date ? `###${inputCondition.date}` : ""
        }`]: "",
        [`${groupId}###month${
          inputCondition?.date ? `###${inputCondition.date}` : ""
        }`]: "",
        [`${groupId}###year${
          inputCondition?.date ? `###${inputCondition.date}` : ""
        }`]: "",
      });
      for (let i = 0; i < cardData.length; i++) {
        form.setFieldsValue({ [`${cardData[i].name}`]: cardData[i].value });
      }
    }
  }, [visibleIndex, form, position, cardData]);

  const showError = (field) => {
    let neededValue;

    if (field === "day") {
      neededValue = cardData?.filter((item) => item?.name?.includes("day"))?.[0]
        ?.value;

      if (neededValue && neededValue?.length === 1) {
        return t("wizard:errors.day_field_format");
      }

      if (neededValue && (+neededValue > 31 || +neededValue < 1)) {
        return t("wizard:errors.invalid_day_number");
      }
    }

    if (field === "month") {
      neededValue = cardData?.filter((item) =>
        item?.name?.includes("month")
      )?.[0]?.value;

      if (neededValue && neededValue?.length === 1) {
        return t("wizard:errors.month_field_format");
      }

      if (neededValue && (+neededValue > 12 || +neededValue < 1)) {
        return t("wizard:errors.invalid_month_number");
      }
    }

    if (field === "year") {
      neededValue = cardData?.filter((item) =>
        item?.name?.includes("year")
      )?.[0]?.value;

      if (neededValue && neededValue?.length !== 4) {
        return t("wizard:errors.year_field_format");
      }
    }

    const day = cardData?.filter((item) => item?.name?.includes("day"))?.[0]
      ?.value;
    const month = cardData?.filter((item) => item?.name?.includes("month"))?.[0]
      ?.value;
    const year = cardData?.filter((item) => item?.name?.includes("year"))?.[0]
      ?.value;

    if (field === "general" && day && month && year && year.length === 4) {
      const timePeriod = cardData?.[0]?.name?.split("###")[4];
      const enteredDate = moment(`${year}.${month}.${day}`).add(1, "days");

      if (timePeriod === "PAST" && moment().isBefore(enteredDate)) {
        return t("wizard:errors.date_past");
      }

      if (timePeriod === "FUTURE" && !moment().isBefore(enteredDate)) {
        return t("wizard:errors.date_future");
      }
    }

    return null;
  };

  //checking the correctness of the entered day
  const dayHandler = (event) => {
    if (event?.target.value.length === 2) {
      setNumberOfInputForFocus(1);
    }
  };

  const monthHandler = (event) => {
    if (event?.target.value.length === 2) {
      setNumberOfInputForFocus(2);
    }
  };

  const replace = (e, maxLength) => {
    const formated = e.target.value.replace(/[^0-9]/g, "").slice(0, maxLength);
    e.target.value = formated;
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" span={8}>
        <Form.Item
          name={`${groupId ? `${groupId}###` : ""}day${
            inputCondition?.date ? `###${inputCondition.date}` : ""
          }`}
        >
          <CInput
            label={t("wizard:labels.day")}
            placeholder={placeholder?.date?.day ?? t("wizard:placeholders.day")}
            name={"day"}
            onChange={(event) => {
              dayHandler(event); //call the functions to check the correctness of the entered date

              if (event?.target.value.length < 2) setNumberOfInputForFocus(0);
              //change the active field to the current one
            }}
            onClick={() => {
              setNumberOfInputForFocus(0);
            }}
            errors={showError("day")}
            type="number" //the type of data that the field receives
            focusOn={numberOfInputForFocus === 0} //indicates whether the field is currently active
            maxLength={2} //indicates the maximum length of data in the field
            inputmode="decimal" //change the keyboard type to ios (not sure how it works)
            pattern="\d*"
            font={documentStyles?.font}
            color={documentStyles?.color}
            onInput={(e) => replace(e, 2)}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item
          name={`${groupId ? `${groupId}###` : ""}month${
            inputCondition?.date ? `###${inputCondition.date}` : ""
          }`}
        >
          <CInput
            label={t("wizard:labels.month")}
            placeholder={
              placeholder?.date?.month ?? t("wizard:placeholders.month")
            }
            name={"month"}
            onChange={(event) => {
              monthHandler(event);
              if (event?.target.value.length < 2) setNumberOfInputForFocus(1);
            }}
            onClick={() => {
              setNumberOfInputForFocus(1);
            }}
            errors={showError("month")}
            type="number"
            maxLength={2}
            focusOn={numberOfInputForFocus === 1}
            inputmode="decimal"
            pattern="\d*"
            font={documentStyles?.font}
            color={documentStyles?.color}
            onInput={(e) => replace(e, 2)}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item
          name={`${groupId ? `${groupId}###` : ""}year${
            inputCondition?.date ? `###${inputCondition.date}` : ""
          }`}
        >
          <CInput
            label={t("wizard:labels.year")}
            placeholder={
              placeholder?.date?.year ?? t("wizard:placeholders.year")
            }
            name={"year"}
            onChange={(event) => {
              setNumberOfInputForFocus(2);
            }}
            onClick={() => {
              setNumberOfInputForFocus(2);
            }}
            errors={showError("year")}
            maxLength={4}
            focusOn={numberOfInputForFocus === 2}
            type="number"
            inputmode="decimal"
            font={documentStyles?.font}
            color={documentStyles?.color}
            onInput={(e) => replace(e, 4)}
          />
        </Form.Item>
      </Col>
      {showError("general") && (
        <div
          style={{
            marginTop: "0px",
            fontSize: "12px",
            lineHeight: "17px",
            fontWeight: "400",
            color: "red",
            fontFamily: documentStyles?.font
              ? documentStyles?.font
              : "Basier Circle",
            marginLeft: "8px",
            marginBottom: "10px",
          }}
        >
          {showError("general")}
        </div>
      )}
      {checkbox && (
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###checkbox`}>
            <CCheckbox
              type={"checkbox"}
              title={t("wizard:buttons.invite_recipient")}
              checked={isCheckboxChecked}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

CDataSelect.propTypes = {
  customStyles: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  label: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  styleLabel: PropTypes.string,
};

export default CDataSelect;

import React, { useState, useEffect } from "react";
import { FORM_TYPE } from "../../../constants/config";
import { Form } from "antd";

import styles from "./index.module.scss";

const Selects = (props) => {
  const {
    groupId,
    typeSelect,
    options,
    visibleIndex,
    position,
    form,
    cardData,
    prompts,
    isCheckboxChecked,
    documentStyles,
  } = props;

  const [checkedInputs, setCheckedInputs] = useState([]);

  useEffect(() => {
    if (form && visibleIndex === position && options) {
      for (let i = 0; i < options.length; i++) {
        form.setFieldsValue({
          [`${groupId}###string###${i}`]: "",
        });
      }
    }
  }, [visibleIndex, form, position, options]);

  useEffect(() => {
    if (visibleIndex === position) {
      setCheckedInputs([]);

      let values = [];
      if (groupId?.includes(FORM_TYPE.MULTI_SELECT)) {
        for (let i = 0; i < cardData.length; i++) {
          values.push(+cardData?.[i]?.value);
        }
        setCheckedInputs(values);
      } else {
        setCheckedInputs([+cardData?.[0]?.value]);
      }
    }
  }, [cardData, prompts, visibleIndex, position]);

  return (
    <ul className={styles["selects__list"]}>
      {options &&
        options.map((item, index) => {
          return (
            <li key={`options-${index}`}>
              <Form.Item
                name={`${groupId}###string${
                  groupId?.includes(FORM_TYPE.MULTI_SELECT) ? index : ""
                }`}
                className={styles["selects__form-item"]}
                style={{
                  width: '100%'
                }}
              >
                <label className={styles["selects__label"]}>
                  <input
                    type={"checkbox"}
                    value={index}
                    checked={checkedInputs.some((item) => item === index)}
                    onChange={() => {}}
                  />
                  <span
                    className={styles["selects__box"]}
                    style={{
                      fontFamily: documentStyles?.font,
                      color: documentStyles?.color,
                      backgroundColor: checkedInputs.some(
                        (item) => item === index
                      )
                        ? `${documentStyles?.color}33`
                        : null,
                      borderColor: checkedInputs.some((item) => item === index)
                        ? documentStyles?.color
                        : null,
                    }}
                  >
                    {item}
                  </span>
                </label>
              </Form.Item>
            </li>
          );
        })}
    </ul>
  );
};

export default Selects;

import React from "react";
import PropTypes from "prop-types";

const ArrowTopIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.518 18.6896L14.9251 1.32885C14.468 0.700279 13.5322 0.700279 13.0787 1.32885L0.482243 18.6896C0.451244 18.7323 0.432674 18.7827 0.428591 18.8353C0.424508 18.8879 0.435072 18.9406 0.459111 18.9876C0.48315 19.0346 0.519726 19.074 0.564784 19.1014C0.609842 19.1289 0.661623 19.1433 0.714386 19.1431H3.39296C3.5751 19.1431 3.74653 19.0539 3.85367 18.9074L14.0001 4.92171L24.1465 18.9074C24.2537 19.0539 24.4251 19.1431 24.6072 19.1431H27.2858C27.518 19.1431 27.6537 18.8789 27.518 18.6896Z"
        fill={color}
      />
    </svg>
  );
};

ArrowTopIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default ArrowTopIcon;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import PropTypes from "prop-types";
import { CInput, CCheckbox } from "../../../index";
import moment from "moment";

const CDataSelect = ({
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  placeholder,
  checkbox,
  isCheckboxChecked,
}) => {
  const { t } = useTranslation();
  const [dayError, setDayError] = useState("Incorrect day"); //variables that contain default error values
  const [monthError, setMonthError] = useState("Incorrect month");
  const [yearError, setYearError] = useState("Incorrect year");

  useEffect(() => {
    dayHandler();
    monthHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //checking the correctness of the entered day
  const dayHandler = (event) => {
    if (event?.target.value.length === 2) {
      setNumberOfInputForFocus(1);
    }
  };

  const monthHandler = (event) => {
    if (event?.target.value.length === 2) {
      setNumberOfInputForFocus(2);
    }
  };

  const replace = (e, maxLength) => {
    const formated = e.target.value.replace(/[^0-9]/g, "").slice(0, maxLength);
    e.target.value = formated;
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" span={8}>
        <Form.Item>
          <CInput
            label={t("wizard:labels.day")}
            placeholder={placeholder ?? t("wizard:placeholders.day")}
            name={"day"}
            onChange={(event) => {
              dayHandler(event); //call the functions to check the correctness of the entered date

              if (event?.target.value.length < 2) setNumberOfInputForFocus(1);
              //change the active field to the current one
            }}
            onClick={() => {
              setNumberOfInputForFocus(1);
            }}
            type="number" //the type of data that the field receives
            focusOn={numberOfInputForFocus === 1} //indicates whether the field is currently active
            maxLength={2} //indicates the maximum length of data in the field
            inputmode="decimal" //change the keyboard type to ios (not sure how it works)
            pattern="\d*"
            onInput={(e) => replace(e, 2)}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item>
          <CInput
            label={t("wizard:labels.month")}
            placeholder={
              placeholder?.date?.month ?? t("wizard:placeholders.month")
            }
            name={"month"}
            onChange={(event) => {
              monthHandler(event);
              if (event?.target.value.length < 2) setNumberOfInputForFocus(2);
            }}
            onClick={() => {
              setNumberOfInputForFocus(2);
            }}
            type="number"
            maxLength={2}
            focusOn={numberOfInputForFocus === 2}
            inputmode="decimal"
            pattern="\d*"
            onInput={(e) => replace(e, 2)}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" span={8}>
        <Form.Item>
          <CInput
            label={t("wizard:labels.year")}
            placeholder={
              placeholder?.date?.year ?? t("wizard:placeholders.year")
            }
            name={"year"}
            onChange={(event) => {
              setNumberOfInputForFocus(3);
            }}
            onClick={() => {
              setNumberOfInputForFocus(3);
            }}
            maxLength={4}
            focusOn={numberOfInputForFocus === 3}
            type="number"
            inputmode="decimal"
            onInput={(e) => replace(e, 4)}
          />
        </Form.Item>
      </Col>
      {checkbox && (
        <Col className="gutter-row" span={24}>
          <Form.Item>
            <CCheckbox
              type={"checkbox"}
              title={t("wizard:buttons.invite_recipient")}
              checked={isCheckboxChecked}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

CDataSelect.propTypes = {
  customStyles: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  label: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  styleLabel: PropTypes.string,
};

export default CDataSelect;

import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Row, Col, Upload, message, Tooltip } from "antd";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  CInput,
  CSelect,
  Icons,
  Modals,
  DefaultUserImg,
  CAutoComplete,
  CCheckbox,
} from "../../../components";

import { saveFile } from "../../../store/actions/dashboard";
import { changeAccountData } from "../../../store/actions/auth";

import { accountsSelector } from "../../../store/selectors/auth";

import * as keys from "../../../utils/keys";
import { GOOGLE_PLACES_API_KEY } from "../../../constants/config";

import { entities, fonts } from "../../../utils/helper";

import styles from "../index.module.scss";

const Settings = ({ changeAccountData, accounts, saveFile }) => {
  const [formWotkspace] = Form.useForm();
  const [formStyles] = Form.useForm();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [inputGeneralFocus, setInputGeneralFocus] = useState(0);
  const [workspaceImageUrl, setWorkspaceImageUrl] = useState();
  const [stylesImageUrl, setStylesImageUrl] = useState();

  const [addressBeforeConfirm, setAddressBeforeConfirm] = useState();

  const [localInfo, setLocalInfo] = useState({
    name: "",
    contact: {
      address: {
        streetAddress: "",
        streetNumber: "",
        postalCode: "",
        city: "",
        country: "",
      },
      organizationName: "",
      organizationType: "",
    },
    logo: "",
  });
  const [localStyles, setLocalStyles] = useState({
    color: "",
    font: "",
    logo: "",
    name: "",
    isApplyToWizard: false,
  });

  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    apiKey: GOOGLE_PLACES_API_KEY,
    language: "en-GB", // de - Germany
  });

  useEffect(() => {
    if (!!accounts) {
      const tempInfo = accounts[0].info;
      const tempStyles = accounts[0].styles[0];

      setLocalInfo({
        name: tempInfo?.name || "",
        contact: {
          address: {
            streetAddress: tempInfo?.contact?.address?.streetAddress || "",
            streetNumber: tempInfo?.contact?.address?.streetNumber || "",
            postalCode: tempInfo?.contact?.address?.postalCode || "",
            city: tempInfo?.contact?.address?.city || "",
            country: tempInfo?.contact?.address?.country || "",
          },
          organizationName: tempInfo?.contact?.organizationName || "",
          organizationType: tempInfo?.contact?.organizationType || "",
        },
        logo: tempInfo?.logo || "",
      });

      setWorkspaceImageUrl(tempInfo?.logo);

      setLocalStyles({
        color: tempStyles?.color,
        font: tempStyles?.font,
        logo: tempStyles?.logo,
        name: tempStyles?.name,
        isApplyToWizard: tempStyles?.isApplyToWizard,
      });

      setStylesImageUrl(tempStyles?.logo);

      const tempAddress = tempInfo?.contact?.address;

      formWotkspace.setFieldsValue({
        workspaceName: !!tempInfo?.name ? tempInfo.name : undefined,
        companyName: !!tempInfo?.contact?.organizationName
          ? tempInfo.contact?.organizationName
          : undefined,
        entityType: !!tempInfo?.contact?.organizationType
          ? tempInfo.contact.organizationType
          : undefined,
        companyAddress: !!tempAddress
          ? `${!!tempAddress?.streetAddress ? tempAddress.streetAddress : ""}${
              !!tempAddress?.streetNumber ? ` ${tempAddress.streetNumber}` : ""
            }${!!tempAddress?.postalCode ? `, ${tempAddress.postalCode}` : ""}${
              !!tempAddress?.city ? ` ${tempAddress.city}` : ""
            }${!!tempAddress?.country ? `, ${tempAddress.country}` : ""}`
          : undefined,
      });

      formStyles.setFieldsValue({
        styleFonts: !!tempStyles?.font ? tempStyles.font : undefined,
      });
    }
  }, [accounts]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isPng = file.type === "image/png";
    if (!isPng) {
      message.error("You can only upload PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }

    return isPng && isLt2M;
  };

  const handleChange = (info, from) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      if (from === "styles") {
        setStylesImageUrl(imageUrl);
        setLocalStyles({ ...localStyles, logo: info.file.originFileObj });
      } else if (from === "workspace") {
        setWorkspaceImageUrl(imageUrl);
        setLocalInfo({ ...localInfo, logo: info.file.originFileObj });
      }
    });
  };

  const convertAddressOptions = (oldOptions) => {
    let options = [];

    for (let i = 0; i < oldOptions.length; i++) {
      options[i] = {
        value: oldOptions[i].description,
        label: oldOptions[i].description,
        id: oldOptions[i].place_id,
      };
    }

    return options;
  };

  const getPlaceData = async (placeId) => {
    placesService.getDetails(
      { placeId: placeId, fields: ["address_components"] },
      (result) => {
        let tempAddr = {
          streetAddress: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: null,
        };

        result.address_components.map((elem) => {
          if (elem.types.some((elemItem) => elemItem === "route")) {
            tempAddr.streetAddress = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "street_number")) {
            tempAddr.streetNumber = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "postal_code")) {
            tempAddr.postalCode = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "locality")) {
            tempAddr.city = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "country")) {
            tempAddr.country = elem.long_name;
          }
        });

        setAddressBeforeConfirm(tempAddr);

        setShowModal(true);
      }
    );
  };

  const checkIsBtnDisabled = (from) => {
    switch (from) {
      case "workspace":
        const tempInfo = accounts?.[0].info;
        const oldValues = {
          name: tempInfo?.name || "",
          contact: {
            address: {
              streetAddress: tempInfo?.contact?.address?.streetAddress || "",
              streetNumber: tempInfo?.contact?.address?.streetNumber || "",
              postalCode: tempInfo?.contact?.address?.postalCode || "",
              city: tempInfo?.contact?.address?.city || "",
              country: tempInfo?.contact?.address?.country || "",
            },
            organizationName: tempInfo?.contact?.organizationName || "",
            organizationType: tempInfo?.contact?.organizationType || "",
          },
          logo: tempInfo?.logo || "",
        };

        if (JSON.stringify(oldValues) === JSON.stringify(localInfo)) {
          return true;
        } else {
          return false;
        }

      case "styles":
        const oldValuesStyles = accounts?.[0].styles[0];
        if (JSON.stringify(oldValuesStyles) === JSON.stringify(localStyles)) {
          return true;
        } else {
          return false;
        }
      default:
        return true;
    }
  };

  const onFinish = (from) => {
    switch (from) {
      case "workspace":
        if (
          !!accounts?.[0].info?.logo &&
          localInfo.logo !== accounts?.[0].info?.logo
        ) {
          saveFile(localInfo.logo, (url) => {
            changeAccountData({ info: { ...localInfo, logo: url } });
          });
        } else {
          changeAccountData({ info: localInfo });
        }
        return null;
      case "styles":
        if (localStyles.logo !== accounts?.[0]?.styles[0]?.logo) {
          saveFile(localStyles.logo, (url) => {
            changeAccountData({ styles: [{ ...localStyles, logo: url }] });
          });
        } else {
          changeAccountData({ styles: [localStyles] });
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <Layout
      header={
        <Header
          title={`Workspace settings`}
          shortTitle={`Workspace settings`}
          right={
            <Link to={keys.DASHBOARD} className={"btn-close"}>
              <Icons.CloseIcon />
            </Link>
          }
          hideLogo={true}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={`workspace-settings ${styles["c-workspace-settings"]}`}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-workspace-settings__section"]}>
          <div className={styles["c-workspace-settings__article"]}>
            <h1>General</h1>
            <div className={styles["c-workspace-settings__fields"]}>
              <Form
                layout="vertical"
                id="general"
                name="general"
                form={formWotkspace}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="avatar">
                      <div className={styles["u-avatar"]}>
                        <div className={styles["u-avatar__photo"]}>
                          {workspaceImageUrl ? (
                            <img src={workspaceImageUrl} alt="avatar" />
                          ) : (
                            <DefaultUserImg
                              initials={accounts?.[0].info?.initials || "WS"}
                              size={"80px"}
                              initialsFontSize={"27px"}
                              initialsLineHeight={"33px"}
                            />
                          )}
                        </div>

                        <Upload
                          name="image"
                          className={styles["u-avatar__uploader"]}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={(info) => handleChange(info, "workspace")}
                        >
                          <button className={styles["u-avatar__uploader__btn"]}>
                            Upload new logo
                          </button>
                        </Upload>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="workspaceName">
                      <CInput
                        label={"Workspace name"}
                        id="workspace-name"
                        name="workspace-name"
                        onChange={(event) => {
                          setInputGeneralFocus(1);
                          setLocalInfo({
                            ...localInfo,
                            name: event.target.value,
                          });
                        }}
                        focusOn={inputGeneralFocus === 1 ? true : false}
                        placeholder={"e.g. Workspace name"}
                        value={localInfo?.name}
                        onClick={() => setInputGeneralFocus(1)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="companyName">
                      <CInput
                        label={"Company name"}
                        id="company-name"
                        name="company-name"
                        onChange={(event) => {
                          setInputGeneralFocus(2);

                          setLocalInfo({
                            ...localInfo,
                            contact: {
                              ...localInfo.contact,
                              organizationName: event.target.value,
                            },
                          });
                        }}
                        focusOn={inputGeneralFocus === 2 ? true : false}
                        placeholder={"e.g. My company"}
                        value={localInfo?.contact?.organizationName}
                        onClick={() => setInputGeneralFocus(2)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="entityType">
                      <CSelect
                        label={"Entity type"}
                        id="entity-type"
                        name="entity-type"
                        onChange={(value) => {
                          setLocalInfo({
                            ...localInfo,
                            contact: {
                              ...localInfo.contact,
                              organizationType: value,
                            },
                          });
                        }}
                        value={localInfo?.contact?.organizationType}
                        options={entities}
                        onClick={() => setInputGeneralFocus(3)}
                        focusOn={inputGeneralFocus === 3 ? true : false}
                        placeholder={"e.g. KG"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="companyAddress">
                      <CAutoComplete
                        label={"Company address"}
                        id="company-address"
                        name="companyAddress"
                        options={convertAddressOptions(placePredictions)}
                        placeholder={
                          "e.g. Emser Platz 5, 10719 Berlin, Germany"
                        }
                        onSearch={(value) => {
                          getPlacePredictions({ input: value });
                        }}
                        onSelect={(value, id) => {
                          getPlaceData(id);
                        }}
                        onChange={(value) => {
                          if (value === "") {
                            setLocalInfo({
                              ...localInfo,
                              contact: {
                                ...localInfo.contact,
                                address: {
                                  streetAddress: "",
                                  streetNumber: "",
                                  postalCode: "",
                                  city: "",
                                  country: "",
                                },
                              },
                            });

                            setTimeout(() => {
                              formWotkspace.setFieldsValue({
                                companyAddress: ``,
                              });
                            }, 10);
                          }
                          setInputGeneralFocus(4);
                        }}
                        onClick={() => setInputGeneralFocus(4)}
                        focusOn={inputGeneralFocus === 4 ? true : false}
                        value={`${localInfo.contact.address.streetAddress} ${localInfo.contact.address.streetNumber}${localInfo.contact.address.postalCode}${localInfo.contact.address.city}
                        ${localInfo.contact.address.country}`}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className={styles["c-workspace-settings__confirm-button"]}>
              <CButton
                type="primary"
                title="Save changes"
                disabled={checkIsBtnDisabled("workspace")}
                onClick={() => onFinish("workspace")}
              />
            </div>
          </div>

          <div className={styles["c-workspace-settings__article"]}>
            <h1>Style builder</h1>
            <p>Your documents will be styled based on these elements.</p>

            <div className={styles["c-workspace-settings__fields"]}>
              <Form
                layout="vertical"
                id="general"
                name="general"
                form={formStyles}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                      <Col xs={{ span: 24 }}>
                        <Form.Item name="logo">
                          <div className={styles["u-avatar"]}>
                            <div className={styles["u-avatar__photo"]}>
                              {stylesImageUrl ? (
                                <img src={stylesImageUrl} alt="logo" />
                              ) : (
                                <DefaultUserImg
                                  initials={
                                    accounts?.[0].styles?.[0]?.name?.substring(
                                      0,
                                      1
                                    ) || "WS"
                                  }
                                  size={"80px"}
                                  initialsFontSize={"27px"}
                                  initialsLineHeight={"33px"}
                                />
                              )}
                            </div>

                            <Upload
                              name="image"
                              className={styles["u-avatar__uploader"]}
                              showUploadList={false}
                              beforeUpload={beforeUpload}
                              onChange={(info) => handleChange(info, "styles")}
                            >
                              <button
                                className={styles["u-avatar__uploader__btn"]}
                              >
                                Upload new logo
                              </button>
                            </Upload>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }}>
                        <Form.Item name="styleFonts">
                          <CSelect
                            label={"Style font"}
                            tooltip={
                              <Tooltip
                                placement="topLeft"
                                title="Here you’ll find the document font."
                                overlayClassName={"u-style-builder__tooltip"}
                              >
                                <i className="u-style-builder__icon">
                                  <Icons.InfoCircleIcon />
                                </i>
                              </Tooltip>
                            }
                            id="style-fonts"
                            name="style-fonts"
                            onChange={(value) => {
                              setLocalStyles({ ...localStyles, font: value });
                            }}
                            value={localStyles?.font}
                            options={fonts}
                            onClick={() => setInputGeneralFocus(5)}
                            focusOn={inputGeneralFocus === 5 ? true : false}
                            placeholder={"Document font"}
                            isFonts={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }}>
                        <div className={"u-style-builder__label"}>
                          Style color
                          <Tooltip
                            placement="topLeft"
                            title="Here you’ll find the color of buttons and links."
                            overlayClassName={"u-style-builder__tooltip"}
                          >
                            <i className="u-style-builder__icon">
                              <Icons.InfoCircleIcon />
                            </i>
                          </Tooltip>
                        </div>
                        <div className={styles["u-style-builder__colorpicker"]}>
                          <i
                            onClick={() => {
                              setInputGeneralFocus(null);
                              setShowColorPicker(true);
                            }}
                            style={{
                              backgroundColor: localStyles?.color,
                            }}
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <div className={"u-style-builder__label"}>
                      Preview
                      <Tooltip
                        placement="topLeft"
                        title="Here you’ll preview the document style."
                        overlayClassName={"u-style-builder__tooltip"}
                      >
                        <i className="u-style-builder__icon">
                          <Icons.InfoCircleIcon />
                        </i>
                      </Tooltip>
                    </div>
                    <div className={styles["u-style-builder__preview"]}>
                      <div className={styles["u-style-builder__preview__row"]}>
                        <div className={styles["u-style-builder__preview__bg"]}>
                          {!!stylesImageUrl ? (
                            <img src={stylesImageUrl} alt="logo" />
                          ) : (
                            <i style={{ backgroundColor: "#bbbbbe" }}></i>
                          )}
                        </div>

                        <div
                          className={styles["u-style-builder__preview__desc"]}
                        >
                          <p style={{ fontFamily: localStyles?.font }}>
                            This is a header
                          </p>
                          <p>
                            <a
                              href="#"
                              title=""
                              style={{
                                fontFamily: localStyles?.font,
                                color: localStyles?.color,
                              }}
                            >
                              This is a link
                            </a>
                          </p>
                        </div>

                        <div
                          className={styles["u-style-builder__preview__btn"]}
                          style={{
                            backgroundColor: localStyles?.color,
                          }}
                        >
                          <span style={{ fontFamily: localStyles?.font }}>
                            Label
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 48 }} sm={{ span: 24 }}>
                    <Form.Item name={`checkbox`}>
                      <CCheckbox
                        type={"checkbox"}
                        title={"Apply style to document questionnaire"}
                        onChange={() => {
                          setLocalStyles({
                            ...localStyles,
                            isApplyToWizard: !localStyles?.isApplyToWizard,
                          });
                        }}
                        checked={localStyles?.isApplyToWizard}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className={styles["c-workspace-settings__confirm-button"]}>
              <CButton
                type="primary"
                title="Save changes"
                disabled={checkIsBtnDisabled("styles")}
                onClick={() => onFinish("styles")}
              />
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modals.ConfirmAddress
          onClose={(value) => {
            if (value) {
              setLocalInfo({
                ...localInfo,
                contact: {
                  ...localInfo.contact,
                  address: value,
                },
              });

              setTimeout(() => {
                formWotkspace.setFieldsValue({
                  companyAddress: `${
                    !!value?.streetAddress ? value.streetAddress : ""
                  }${!!value?.streetNumber ? ` ${value.streetNumber}` : ""}${
                    !!value?.postalCode ? `, ${value.postalCode}` : ""
                  }${!!value?.city ? ` ${value.city}` : ""}${
                    !!value?.country ? `, ${value.country}` : ""
                  }`,
                });
              }, 50);
            } else {
              const tempInfo = accounts?.[0].info;

              setLocalInfo({
                ...localInfo,
                contact: {
                  ...localInfo.contact,
                  address: {
                    streetAddress:
                      tempInfo?.contact?.address?.streetAddress || "",
                    streetNumber:
                      tempInfo?.contact?.address?.streetNumber || "",
                    postalCode: tempInfo?.contact?.address?.postalCode || "",
                    city: tempInfo?.contact?.address?.city || "",
                    country: tempInfo?.contact?.address?.country || "",
                  },
                },
              });

              setTimeout(() => {
                const address = tempInfo?.contact?.address;

                formWotkspace.setFieldsValue({
                  companyAddress: `${address.streetAddress || ""}${
                    !!address.streetNumber ? ` ${address.streetNumber}` : ""
                  }${!!address.postalCode ? `, ${address.postalCode}` : ""}${
                    !!address.city ? ` ${address.city}` : ""
                  }${!!address.country ? `, ${address.country}` : ""}`,
                });
              }, 50);
            }
            setShowModal(false);
          }}
          data={addressBeforeConfirm}
        />
      )}
      {showColorPicker && (
        <Modals.ColorPicker
          onClose={(color) => {
            setLocalStyles({ ...localStyles, color: color });
            setShowColorPicker(false);
          }}
          color={localStyles.color}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({ accounts: accountsSelector(state) });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeAccountData, saveFile }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

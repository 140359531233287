import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import styles from "../styles.module.scss";
import React from "react";

const CustomTextarea = React.forwardRef((props, ref) => (
  <TextareaAutosize
    ref={ref}
    rows={1}
    className={classNames(
      styles.comment__baseInput,
      styles.comment__replyInput
    )}
    {...props}
  />
));

export default CustomTextarea;

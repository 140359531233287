import React, { useEffect, useState, useRef, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as keys from "../../utils/keys";
import { SearchOutlined } from "@ant-design/icons";
import { api } from "../../services/api";

import {
  Layout,
  Header,
  WorkspaceSidebar,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  NavBarBottom,
  WorkspaceCard,
  Modals,
  Folder,
  CInput,
  WorkspacesList,
} from "../../components";

import { activeFolderSelector } from "../../store/selectors/dashboard";
import {
  accountsSelector,
  documentsSelector,
  userSelector,
} from "../../store/selectors/auth";

import {
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  setErrorLoadingScreenType,
} from "../../store/actions/auth";
import {
  getDocument,
  setIsCreatingDocument,
  setIndex,
  loadDocumentPdf,
} from "../../store/actions/prompts";

import stylesWorkspace from "../../components/WorkspaceTabs/index.module.scss";
import styles from "../../styles/Workspace.module.scss";
import { Typography } from "antd";
import { SearchResults } from "../../components/SearchDocumentsResult";

const { Title } = Typography;

const Folders = ({
  activeFolder,
  account,
  userData,
  documents,
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  loadDocumentPdf,
  setErrorLoadingScreenType,
}) => {
  let navigate = useNavigate();

  const documentsRefs = useRef([]);
  const sortDropdownRef = useRef();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [activeSelect, setActiveSelect] = useState(false);
  const [sortByName, setSortByName] = useState("Last updated");
  const [showModal, setShowModal] = useState(false);
  const [folderData, setFolderData] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isChoosingFolder, setIsChoosingFolder] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [folderDocuments, setFolderDocuments] = useState([]);
  const [nowEditing, setNowEditing] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchedFolders, setSearchedFolders] = useState();
  const [searched, setSearched] = useState(false);
  const [customColumn, setCustomColumn] = useState();
  const [newColumns, setNewColumns] = useState([]);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    if (!!activeFolder && !!account?.[0].folders) {
      const folders = account[0].folders;

      const activeFolderData = folders.filter(
        (item) => item.id === activeFolder
      );

      setFolderData(activeFolderData?.[0]);
    }
  }, [activeFolder, account]);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  useEffect(() => {
    if (!!account && !!documents) {
      setFolderDocuments([]);

      const folders = account?.[0].folders;

      const masterFolders = folders.filter((item) => item.type === "MASTER");

      let filteredDocuments = [];

      if (masterFolders.some((item) => item.id === activeFolder)) {
        filteredDocuments = documents.filter((item) => item);
      } else {
        for (let i = 0; i < documents.length; i++) {
          if (documents?.[i]?.folderID === activeFolder) {
            filteredDocuments.push(documents[i]);
          }
        }
      }
      setFolderDocuments(filteredDocuments);
    }
  }, [activeFolder, documents]);

  useEffect(() => {
    documentsRefs.current = documentsRefs.current.slice(0, folderDocuments);
  }, [folderDocuments]);

  useEffect(() => {
    if (!!account) {
      switch (sortByName) {
        case "Last updated":
          getSortedDocuments("LAST_UPDATED");
          break;
        case "Created at":
          getSortedDocuments("CREATED_AT");
          break;
        case "Title":
          getSortedDocuments("TITLE");
          break;
        default:
          getSortedDocuments("LAST_UPDATED");
          break;
      }
    }
  }, [sortByName, activeFolder, account]);

  useEffect(() => {
    const lastItem = variables[variables.length - 1];
    setCustomColumn(lastItem?.key);
  }, [variables]);

  useEffect(() => {
    if (customColumn) {
      setNewColumns([...newColumns, customColumn]);
    }
  }, [customColumn]);

  const handleClick = (e) => {
    if (activeDropdown) {
      setActiveDropdown(null);
    }

    if (!!nowEditing) {
      for (let i = 0; i < folderDocuments.length; i++) {
        if (
          !!documentsRefs?.current[i] &&
          documentsRefs?.current[i].id === nowEditing &&
          !documentsRefs?.current[i].contains(e.target)
        ) {
          setNowEditing(null);
        }
      }
    }

    if (activeSelect) {
      if (
        !!sortDropdownRef?.current &&
        !sortDropdownRef?.current.contains(e.target)
      ) {
        setActiveSelect(null);
      }
    }
  };

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const handleChangeName = (e) => {
    const target = e.currentTarget.dataset.name;
    setSortByName(target);
    setActiveSelect(false);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    for (let i = 0; i < folderDocuments.length; i++) {
      if (
        !!documentsRefs?.current[i] &&
        documentsRefs?.current[i].contains(event.target)
      ) {
        setActiveDropdown(documentsRefs.current[i]?.id);
      }
    }

    setNowEditing(null);
  };

  const searchDocuments = async () => {
    try {
      if (searchValue) {
        const { data } = await api.get(`document/search?query=${searchValue}`);
        setSearchedFolders(data.data);
        setSearched(true);
      } else {
        setSearchedFolders(undefined);
        setSearched(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleSubmit() {
    searchDocuments();
  }

  return (
    <Layout
      header={
        <Header
          right={<></>}
          dashboardContainer={{ width: "100%" }}
          leftType={isChoosingFolder ? "auth" : "arrowLeftFolders"}
          dropdownOpened={(isOpen) => {
            setIsMenuOpen(isOpen);
          }}
          onLeftSideClick={() => {
            setIsChoosingFolder(true);
          }}
          userData={userData}
          accountData={account?.[0]}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      workspaces={<WorkspacesList />}
    >
      <div className={styles["c-folders-view"]}>
        <WorkspaceSidebar />
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        {isChoosingFolder && window.innerWidth < 1020 ? (
          <>
            <div className={styles["c-workspace__background"]}>
              <div className={styles["c-workspace__workspace-name"]}>
                {account?.[0]?.info?.name}
              </div>
              <Folder
                onFolderClick={() => {
                  setIsChoosingFolder(false);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div
              className={styles["c-folders-view__section"]}
              style={{ overflow: "auto" }}
            >
              <div className={styles["c-folders-view__head"]}>
                <div className={styles["c-folders-view__head__info"]}>
                  {folderData?.name}
                </div>

                {!searchedFolders?.documents?.length && (
                  <div className={styles["u-sorted-by"]}>
                    <div className={styles["u-sorted-by__title"]}>
                      Sorted by:
                    </div>
                    <div className={styles["u-sorted-by__value"]}>
                      <div
                        onClick={() => {
                          activeSelect === `select-${1}`
                            ? setActiveSelect(null)
                            : setActiveSelect(`select-${1}`);
                        }}
                        className={styles["u-sorted-by__name"]}
                      >
                        {sortByName}
                      </div>

                      <div
                        className={`u-workspace__dropdown is-sorted-by ${
                          activeSelect === `select-${1}` ? "active" : ""
                        }`}
                        ref={sortDropdownRef}
                      >
                        <div className={"u-workspace__dropdown__row"}>
                          <div className={"u-workspace__dropdown__item"}>
                            <div
                              onClick={handleChangeName}
                              data-name={"Last updated"}
                              className={"u-workspace__dropdown__link"}
                            >
                              <i>
                                <Icons.RenameIcon />
                              </i>
                              <span>Last updated</span>
                            </div>
                          </div>
                          <div className={"u-workspace__dropdown__item"}>
                            <div
                              onClick={handleChangeName}
                              data-name={"Created at"}
                              className={"u-workspace__dropdown__link"}
                            >
                              <i>
                                <Icons.ClockTimeIcon />
                              </i>
                              <span>Created at</span>
                            </div>
                          </div>
                          <div className={"u-workspace__dropdown__item"}>
                            <div
                              onClick={handleChangeName}
                              data-name={"Title"}
                              className={"u-workspace__dropdown__link"}
                            >
                              <i>
                                <Icons.TitleIcon />
                              </i>
                              <span>Title</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles["c-folders-view__body"]}>
                <div
                  data-tab-body={"recently-edited"}
                  className={`${stylesWorkspace["table"]} ${stylesWorkspace["active"]}`}
                  style={{
                    maxWidth: "100vw",
                    minWidth: "1064px",
                    overflow: "auto",
                  }}
                >
                  {(
                    searched && !searchedFolders?.documents?.length
                      ? false
                      : folderDocuments.length
                  ) ? (
                    searchedFolders?.documents?.length ? (
                      searchedFolders?.documents?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={stylesWorkspace["item"]}
                          ></div>
                        );
                      })
                    ) : (
                      <>
                        <div className={stylesWorkspace["thead"]}>
                          <div className={stylesWorkspace["tr"]}>
                            <div
                              className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-title"]}`}
                            >
                              TITLE
                            </div>
                            <div
                              className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-last-update"]}`}
                            >
                              Last UPDATE
                            </div>
                            <div
                              className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-status"]}`}
                            >
                              STATUS
                            </div>
                            <div
                              className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-parties"]} ${stylesWorkspace["w-350"]}`}
                            >
                              SIGNEES
                            </div>
                            <div
                              className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-action"]}`}
                            >
                              ACTIONS
                            </div>
                          </div>
                        </div>
                        <div className={stylesWorkspace["tbody"]}>
                          {folderDocuments.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className={stylesWorkspace["item"]}
                              >
                                <WorkspaceCard
                                  ref={(el) =>
                                    (documentsRefs.current[index] = el)
                                  }
                                  key={`folder-${item.id}`}
                                  item={item}
                                  width={true}
                                  handleExport={() => {
                                    loadDocumentPdf(
                                      item.documentID,
                                      item.passcode,
                                      (link) => {
                                        saveAs(link, `${item?.fileTitle}.pdf`);
                                      }
                                    );
                                  }}
                                  onOptionsPress={() => {
                                    if (activeDropdown !== item.documentID) {
                                      setActiveDropdown(item.documentID);
                                    } else {
                                      setActiveDropdown(null);
                                    }
                                  }}
                                  isDropdownActive={
                                    activeDropdown === item.documentID
                                  }
                                  handleDelete={() =>
                                    setShowModal(item.documentID)
                                  }
                                  nowEditing={nowEditing}
                                  setNowEditing={setNowEditing}
                                  updateDocument={updateDocumentFromDashboard}
                                  getSortedDocuments={getSortedDocuments}
                                  isLastCard={
                                    folderDocuments.length - 1 === index
                                  }
                                  getDocument={getDocument}
                                  setIsCreatingDocument={setIsCreatingDocument}
                                  setIndex={setIndex}
                                  setErrorLoadingScreenType={
                                    setErrorLoadingScreenType
                                  }
                                />
                              </div>
                            );
                          })}
                          {showModal && (
                            <Modals.ConfirmDelete
                              onClose={async () => {
                                await deleteDocument(showModal);
                                setShowModal(false);
                              }}
                              onCancel={() => {
                                setShowModal(false);
                              }}
                            />
                          )}
                        </div>
                      </>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {!isMenuOpen && !openRightPanel && <NavBarBottom />}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  activeFolder: activeFolderSelector(state),
  account: accountsSelector(state),
  userData: userSelector(state),
  documents: documentsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDocumentFromDashboard,
      deleteDocument,
      getSortedDocuments,
      getDocument,
      setIsCreatingDocument,
      setIndex,
      loadDocumentPdf,
      setErrorLoadingScreenType,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Folders);

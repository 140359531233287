import React from "react";
import PropTypes from "prop-types";

const PlusOutlined = ({ size = 24, color = "currentColor", className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.0712 3.64453H14.9283C14.7378 3.64453 14.6426 3.73977 14.6426 3.93025V15.1445H4.00056C3.81008 15.1445 3.71484 15.2398 3.71484 15.4302V17.5731C3.71484 17.7636 3.81008 17.8588 4.00056 17.8588H14.6426V29.0731C14.6426 29.2636 14.7378 29.3588 14.9283 29.3588H17.0712C17.2616 29.3588 17.3569 29.2636 17.3569 29.0731V17.8588H28.0006C28.191 17.8588 28.2863 17.7636 28.2863 17.5731V15.4302C28.2863 15.2398 28.191 15.1445 28.0006 15.1445H17.3569V3.93025C17.3569 3.73977 17.2616 3.64453 17.0712 3.64453Z"
        fill={color}
      />
    </svg>
  );
};

PlusOutlined.propTypes = {
  color: PropTypes.string,
};

export default PlusOutlined;

import React from 'react';
import PropTypes from 'prop-types';

const SwiftsignLogoIcon = ({
	color = '#5F6CFF',
	className,
	width = 82,
	height = 18,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 82 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.47894 4.48609H10.116C10.0778 2.1668 8.17155 0.565528 5.27402 0.565528C2.42097 0.565528 0.33042 2.14138 0.343129 4.50516C0.336775 6.42414 1.69023 7.52342 3.8888 8.05082L5.30579 8.40666C6.72279 8.74979 7.51071 9.15646 7.51707 10.0333C7.51071 10.9865 6.60841 11.6346 5.21048 11.6346C3.78077 11.6346 2.75139 10.9738 2.66243 9.67115H0C0.0698967 12.4861 2.08419 13.9412 5.24225 13.9412C8.41937 13.9412 10.2875 12.4225 10.2939 10.0397C10.2875 7.8729 8.65448 6.72279 6.39237 6.21445L5.22319 5.93486C4.09213 5.67434 3.14535 5.25496 3.16441 4.32088C3.16441 3.48212 3.90786 2.86576 5.25496 2.86576C6.57029 2.86576 7.37727 3.46306 7.47894 4.48609Z"
				fill={color}
			/>
			<path
				d="M14.019 13.7569H16.8785L18.556 7.61873H18.6767L20.3542 13.7569H23.22L25.8697 3.99682H23.1374L21.6505 10.5163H21.5679L19.9666 3.99682H17.2788L15.7029 10.5544H15.614L14.1016 3.99682H11.363L14.019 13.7569Z"
				fill={color}
			/>
			<path
				d="M27.3137 13.7569H30.0206V3.99682H27.3137V13.7569ZM28.6735 2.73868C29.4805 2.73868 30.1413 2.12232 30.1413 1.36616C30.1413 0.616363 29.4805 0 28.6735 0C27.8729 0 27.212 0.616363 27.212 1.36616C27.212 2.12232 27.8729 2.73868 28.6735 2.73868Z"
				fill={color}
			/>
			<path
				d="M37.4376 3.99682H35.4996V3.34233C35.4996 2.68149 35.7664 2.29388 36.5607 2.29388C36.8848 2.29388 37.2152 2.36378 37.4312 2.43367L37.9078 0.400318C37.571 0.29865 36.8276 0.133439 35.9952 0.133439C34.1588 0.133439 32.7926 1.16918 32.7926 3.2915V3.99682H31.4138V6.03017H32.7926V13.7569H35.4996V6.03017H37.4376V3.99682Z"
				fill={color}
			/>
			<path
				d="M44.2827 3.99682H42.4463V1.65846H39.7394V3.99682H38.405V6.03017H39.7394V11.1136C39.7267 13.0262 41.0293 13.973 42.9928 13.8904C43.6918 13.865 44.1874 13.7252 44.4606 13.6362L44.0349 11.6219C43.9014 11.6473 43.6155 11.7109 43.3613 11.7109C42.8212 11.7109 42.4463 11.5075 42.4463 10.7577V6.03017H44.2827V3.99682Z"
				fill={color}
			/>
			<path
				d="M54.0269 6.77997C53.7855 4.98173 52.3367 3.86973 49.8713 3.86973C47.374 3.86973 45.7283 5.0262 45.7346 6.90706C45.7283 8.36853 46.6497 9.31532 48.5559 9.69657L50.2462 10.0333C51.0976 10.2049 51.4852 10.5163 51.4979 11.0055C51.4852 11.5838 50.8562 11.9968 49.9094 11.9968C48.9435 11.9968 48.3018 11.5838 48.1366 10.7895L45.4741 10.9293C45.7283 12.7974 47.3169 13.9476 49.903 13.9476C52.432 13.9476 54.243 12.6576 54.2493 10.7323C54.243 9.32167 53.3216 8.47656 51.4281 8.08895L49.6616 7.73311C48.7529 7.53613 48.4161 7.22477 48.4225 6.75456C48.4161 6.16997 49.077 5.78871 49.9157 5.78871C50.8562 5.78871 51.4153 6.30341 51.5488 6.93248L54.0269 6.77997Z"
				fill={color}
			/>
			<path
				d="M55.9539 13.7569H58.6608V3.99682H55.9539V13.7569ZM57.3137 2.73868C58.1207 2.73868 58.7815 2.12232 58.7815 1.36616C58.7815 0.616363 58.1207 0 57.3137 0C56.513 0 55.8522 0.616363 55.8522 1.36616C55.8522 2.12232 56.513 2.73868 57.3137 2.73868Z"
				fill={color}
			/>
			<path
				d="M65.2263 17.6203C68.0158 17.6203 69.9983 16.3495 69.9983 13.8522V3.99682H67.3105V5.63621H67.2088C66.8466 4.84193 66.0523 3.86973 64.4193 3.86973C62.2779 3.86973 60.467 5.53454 60.467 8.85781C60.467 12.1048 62.2271 13.6171 64.4256 13.6171C65.9824 13.6171 66.853 12.8356 67.2088 12.0286H67.3232V13.8141C67.3232 15.1549 66.4654 15.6759 65.2898 15.6759C64.0952 15.6759 63.4916 15.1549 63.2692 14.5639L60.7656 14.9007C61.0897 16.4384 62.5956 17.6203 65.2263 17.6203ZM65.2835 11.5838C63.9554 11.5838 63.231 10.529 63.231 8.8451C63.231 7.18665 63.9427 6.02382 65.2835 6.02382C66.5988 6.02382 67.3359 7.13581 67.3359 8.8451C67.3359 10.5671 66.5861 11.5838 65.2835 11.5838Z"
				fill={color}
			/>
			<path
				d="M74.8593 8.11436C74.8657 6.85622 75.6155 6.11913 76.7084 6.11913C77.795 6.11913 78.4495 6.83081 78.4431 8.02541V13.7569H81.15V7.54248C81.15 5.26766 79.8156 3.86973 77.7823 3.86973C76.3335 3.86973 75.285 4.58141 74.8466 5.71882H74.7322V3.99682H72.1524V13.7569H74.8593V8.11436Z"
				fill={color}
			/>
		</svg>
	);
};

SwiftsignLogoIcon.propTypes = {
	color: PropTypes.string,
	className: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number,
};

export default SwiftsignLogoIcon;

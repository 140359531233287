import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import parse from "html-react-parser";
import styles from "../../pages/ReviewInput/index.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { documentSelector } from "../../store/selectors/prompts";
import { DocumentViewer } from "../Lexical/document-viewer";
import { Editor } from "../Lexical";
import { updateDocumentData } from "../../store/actions/prompts";
import { useDebounce } from "../../hooks/helpers/useDebounce";
import { useDocumentPermission } from "../../hooks/permissions/document-permissions";
import { tempUserSelector } from "../../store/selectors/auth";
import Modals from "../Modals";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createPortal } from "react-dom";

const DocumentContent = ({ 
  stylesForDocument, 
  lexicalState, 
  isEditor = true, 
  isParseModalOpen, 
  onParseModalClose, 
  setIsEditor,
  shouldShowComments
}) => {
  const thisDocument = useSelector(documentSelector);
  const generatedDocument = thisDocument?.type === "GENERATED";
  const dispatch = useDispatch();
  const { isOwner, user } = useDocumentPermission();
  const [isLoadingParseModal, setIsLoadingParseModal] = useState(false);
  const [editor] = useLexicalComposerContext();
  const firstLoadRef = useRef(false);
  
  const handleConfirmParse = useCallback(() => {
    setIsLoadingParseModal(true);
    const newObj = updateObject(JSON.parse(lexicalState))
    dispatch(updateDocumentData(
      thisDocument.documentID,
      {
        lexicalState: newObj,
        hasLexicalTagsBeenParsed: true,
      },
      false,
      () => {
        setIsEditor(true);
        onParseModalClose();
        setIsLoadingParseModal(false);
        const newState = editor.parseEditorState(JSON.stringify(newObj));
        editor.setEditorState(newState);
      },
    ));
  }, [thisDocument, lexicalState]);

  function debounceCb(editorState) {
    if (!isOwner && !user) {
      return;
    } else if (!firstLoadRef.current) {
      firstLoadRef.current = true;
      return;
    }
    editorState.read(() => {
      const json = editorState.toJSON();
       dispatch(updateDocumentData(
        thisDocument.documentID,
        {
          lexicalState: JSON.stringify(json),
          ...(!isOwner && {
            updatedBy: user?.userID
          })
        },
        false,
        null,
        !isOwner,
        thisDocument.passcode,
        false,
        true
       ))
    });
  }

  function updateObject(obj) {
    if (Array.isArray(obj)) {
        obj.forEach(item => {
            updateObject(item);
        });
    } else if (typeof obj === 'object') {
        if (obj?.type === 'tag') {
            obj.type = 'text';
            obj.mode = 'normal';
            obj.format = 0;
            obj.detail = 0;
            obj.style = "";
            const variable = thisDocument.variables.find(variable => variable.variableID === obj.id);
            obj.text = variable?.value;
        }
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                updateObject(obj[key]);
            }
        }
    }
  
    return obj
  }

  const documentChange = useDebounce(debounceCb, 1000)

  useEffect(() => {
    if (lexicalState && !firstLoadRef.current) {
      const newState = editor.parseEditorState(lexicalState);
      editor.setEditorState(newState);
    }
  }, [lexicalState])

  const isDocumentCompleted = React.useMemo(() => {
    return thisDocument?.status === "COMPLETED";
  }, [thisDocument])

  if (!thisDocument) return null;
  if (!generatedDocument && !thisDocument?.content) return null;
  if (generatedDocument && !thisDocument?.lexicalState) return null;

  if (generatedDocument) {
    return (
      <>
        {isEditor ? (
          <Editor
            state={lexicalState}
            disableSelectionChange
            useOutsideComposer
            hasComments
            onChange={documentChange}
            showCommentPlugin={shouldShowComments}
          />
        ) : (
          <DocumentViewer
            state={lexicalState}
            useOutsideComposer
            disableSelectionChange
            onChange={documentChange}
            addCommentPlugin={shouldShowComments && !isDocumentCompleted}
          />
        )}
        {isParseModalOpen && createPortal(
          <Modals.ConfirmLexicalParse
            onConfirm={handleConfirmParse}
            onCancel={onParseModalClose}
            isLoading={isLoadingParseModal}
          />,
          document.getElementById("confirm-parse-modal")
        )}
      </>
    )
  }

  return (
    <div
      className={`${styles["u-swiftsign-header"]}`}
      style={{ fontFamily: stylesForDocument?.font }}
    >
      {parse(thisDocument.content)}

      {thisDocument.tableContent && (
        <div className={styles["c-table__table"]}>
          <table className={styles["c-table__table__container"]}>
            {thisDocument.tableContent.table?.map((value, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>{value.head}</th>
                    <td>
                      {value?.body?.map((subValue, index) => {
                        return typeof subValue === "string" ? (
                          <ul key={subValue}>
                            <li>{subValue}</li>
                          </ul>
                        ) : (
                          <ul key={index}>
                            <li>
                              {subValue?.subBody?.title}
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "10px",
                                }}
                              >
                                {subValue?.subBody?.text.map((sub) => (
                                  <li key={sub}>{sub}</li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </div>
  )
};

export default DocumentContent;

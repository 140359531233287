import React, { useEffect, useMemo } from "react";
import { Form, Row, Col } from "antd";

import { useTranslation } from "react-i18next";

import { CSelect } from "../..";

const Notice = ({
  groupId = "",
  placeholder,
  label,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  form,
  visibleIndex,
  position,
  cardData,
  prompts,
  documentStyles,
  contractOptions,
  noticeOptions,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (form && visibleIndex === position) {
      form.setFieldsValue({
        [`${groupId}###contract`]: "",
        [`${groupId}###notice`]: "",
      });
    }
  }, [visibleIndex, form, position]);

  useEffect(() => {
    if (cardData) {
      form.setFieldsValue({
        [`${cardData?.[0]?.name}`]: cardData?.[0]?.value,
      });
    }
  }, [cardData, prompts]);

  const contractOptionsBiggerThanBilling = useMemo(() => {
    const answerBilling = prompts
      ?.filter((question) => question?.questionType === "PLAN")
      ?.map((question) => question?.answer?.billing)?.[0];

    if (answerBilling?.title === "Jährliche Zahlung") {
      return contractOptions?.filter((contract) => contract.value >= 12);
    } else {
      return contractOptions;
    }
  }, [contractOptions]);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
        <Form.Item name={`${groupId}###contract`}>
          <CSelect
            label={label?.contract ? label?.contract : "label"}
            showSearch={false}
            focusOn={numberOfInputForFocus === 0}
            onSelect={() => {
              setTimeout(() => {
                setNumberOfInputForFocus(1);
              }, 100);
            }}
            onClick={() => {
              setNumberOfInputForFocus(0);
            }}
            options={contractOptionsBiggerThanBilling}
            placeholder={
              placeholder?.contract ? placeholder?.contract : "placeholder"
            }
            font={documentStyles?.font}
            color={documentStyles?.color}
          />
        </Form.Item>
      </Col>

      <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
        <Form.Item name={`${groupId}###notice`}>
          <CSelect
            label={label?.notice ? label?.notice : "label"}
            showSearch={false}
            focusOn={numberOfInputForFocus === 1}
            onSelect={() => {
              setTimeout(() => {
                setNumberOfInputForFocus(2);
              }, 100);
            }}
            onClick={() => {
              setNumberOfInputForFocus(1);
            }}
            options={noticeOptions}
            placeholder={
              placeholder?.notice ? placeholder?.notice : "placeholder"
            }
            font={documentStyles?.font}
            color={documentStyles?.color}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Notice;

import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Select, notification, Typography, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option } = Select;

export const SelectDropdown = ({
  setFocused = () => {},
  permission,
  editHandler = () => {},
  removeFunction = () => {},
  documentId,
  userEmail,
  itemsArray,
  setChangedItemsArray = () => {},
  deleteOption,
  options,
  deleteNotificationMessage,
  isDropdown,
  dropdownText,
  onSelectFunction = () => {},
  ...props
}) => {
  const [uniqueUserPermission, setUniqueUserPermission] = useState();
  const [openSelect, setOpenSelect] = useState(false);
  const selectRef = useRef(null);
  const closeTimeoutRef = useRef(null);
  const { t } = useTranslation();

  const deletedNotification = () => {
    notification.info({
      message: deleteNotificationMessage,
      duration: 3,
      placement: "bottom",
      icon: <></>,
      closeIcon: <></>,
      className: "select-dropwdown-notification",
    });
  };

  function handleChangePermission(e) {
    if (e === "delete") {
      setChangedItemsArray(
        itemsArray.filter((user) => user.email !== userEmail)
      );
      removeFunction();
      deleteNotificationMessage && deletedNotification();
      return;
    } else {
      if (e !== permission) {
        editHandler(e);
      }
      setUniqueUserPermission(e);
    }
  }

  function handleOpenSelect() {
    setOpenSelect(true);
    selectRef.current.focus();
  }

  function handleCloseSelect() {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
    }
    closeTimeoutRef.current = setTimeout(() => {
      setOpenSelect(false);
    }, 100);
  }

  return (
    <div className={isDropdown ? "select-dropdown" : undefined}>
      {isDropdown && (
        <Button
          type="text"
          className="select-dropdown-button"
          onClick={handleOpenSelect}
        >
          <Space size={4}>
            {dropdownText}
            <DownOutlined />
          </Space>
        </Button>
      )}
      <Select
        defaultValue={permission ? undefined : options[0]?.value}
        value={uniqueUserPermission ?? permission}
        dropdownStyle={{ width: "auto" }}
        placement="bottomRight"
        bordered={false}
        open={isDropdown ? openSelect : undefined}
        dropdownMatchSelectWidth={false}
        onClick={() => setFocused(1)}
        style={{ maxWidth: 170, width: "min-content", textAlign: "end" }}
        popupClassName="select-dropwdown-popup"
        onChange={
          permission
            ? handleChangePermission
            : (e) => setUniqueUserPermission(e)
        }
        ref={selectRef}
        onSelect={(e) => {
          onSelectFunction(e);
          handleCloseSelect();
        }}
        onDropdownVisibleChange={(open) => {
          if (!open) {
            handleCloseSelect();
          }
        }}
        {...props}
      >
        {options.map((value) => {
          return (
            <Option value={value.value} key={value.value}>
              <div
                direction="vertical"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  {value.icon}
                  {value.label}
                </Text>
                <Text
                  type="secondary"
                  style={{
                    lineHeight: "1.5",
                    fontSize: "12px",
                  }}
                >
                  {value.secondaryLabel}
                </Text>
              </div>
            </Option>
          );
        })}
        {deleteOption && (
          <Option value="delete">
            <Text type="danger" style={{ fontWeight: 500 }}>
              {t("share:edit_options:remove")}
            </Text>
          </Option>
        )}
      </Select>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import {
  Layout,
  Header,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  CInput,
  CSwitch,
  ConfirmSendContract,
  Card,
} from "../../components";
import // setIsPreview,
// setIsYouSigning,
// updateSigneeData,
// setDocumentTitle,
// sendSignees,
// // setOpenConfirmWindow,
// changeSignAndSendButtonTitle,
// getDocument,
"../../store/actions/prompts";
import {
  isYouSigningSelector,
  signeeDataSelector,
  documentSelector,
  filteredPromptSelector,
  openConfirmWindowSelector,
  userInfoSelector,
  // signAndSendButtonTitleSelector,
} from "../../store/selectors/prompts";
import * as keys from "../../utils/keys";
import { arrayOfParties, getPartyID } from "../../utils/helper";

import styles from "../../styles/Wizard.module.scss";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SendContract = ({
  // setIsPreview,
  // setIsYouSigning,
  isYouSigning,
  signee,
  // updateSigneeData,
  document,
  // setDocumentTitle,
  filteredPrompt,
  // sendSignees,
  openConfirmWindow,
  // setOpenConfirmWindow,
  userInfo,
  // changeSignAndSendButtonTitle,
  buttonTitle,
  // getDocument,
}) => {
  let params = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(10);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  useEffect(() => {
    // setIsPreview(false);
    if (
      signee
        ?.filter((el) => el !== null)
        ?.filter((el) => el !== undefined)
        .some((el) => el.isMainUser === true)
    ) {
      // setIsYouSigning(true);
    } else {
      // setIsYouSigning(false);
    }
  }, []);

  // const preparationForSendingSignees = () => {
  //   //function that generates data to send to the server
  //   let arrrayToSend = [];
  //   let type = "";
  //   const withOrder = signee
  //     ?.filter((el) => el !== null)
  //     ?.filter((el) => el !== undefined)
  //     ?.some((el) => el.showNumber === true); //check if at least one user is shown a number

  //   signee
  //     ?.filter((el) => el !== null)
  //     ?.filter((el) => el !== undefined)
  //     ?.forEach((item, index) => {
  //       //if the data in this element of the array exists
  //       let isSender = item.isMainUser; //if it is the main user, then we write true
  //       let partyID = getPartyID(document.parties, item.userData[0].value); //we get the id of the party to which the user belongs
  //       let order = index + 1;

  //       if (withOrder && isSender && order === 1) {
  //         type = "orderSenderFirst";
  //       }

  //       if (withOrder) {
  //         //if at least one user has a visible number, then write the object of the first type, otherwise - the second
  //         arrrayToSend.push({
  //           contact: {
  //             firstName: item.userData[2].value,
  //             lastName: item.userData[3].value,
  //             email: item.userData[4].value,
  //           },
  //           isSender,
  //           partyID,
  //           order,
  //         });
  //       } else {
  //         arrrayToSend.push({
  //           contact: {
  //             firstName: item.userData[2].value,
  //             lastName: item.userData[3].value,
  //             email: item.userData[4].value,
  //           },
  //           isSender,
  //           partyID,
  //         });
  //       }
  //     });

  //   const isSender = signee
  //     ?.filter((el) => el !== null)
  //     ?.filter((el) => el !== undefined)
  //     ?.some((item) => item.isMainUser === true);

  //   if (type === "") {
  //     if (isSender && !withOrder) {
  //       type = "noOrderWithSender";
  //     } else if (!isSender && !withOrder) {
  //       type = "noOrderNoSender";
  //     } else if (withOrder && !isSender) {
  //       type = "orderWithoutSender";
  //     } else {
  //       type = "orderSenderNotFirst";
  //     }
  //   }

  //   let signeeID;
  //   if (type === "noOrderWithSender" || type === "orderSenderFirst") {
  //     signeeID = userInfo?.userID;
  //   }

  //   // sendSignees(
  //     type,
  //     arrrayToSend,
  //     document.title,
  //     document.documentID,
  //     signeeID,
  //     () => {
  //       // getDocument(`${params.id}?passcode=${query.get("passcode")}`);
  //       // setOpenConfirmWindow(false);
  //       navigate(
  //         keys.DOCUMENT_OVERVIEW +
  //           `/${params.id}/end?passcode=${query.get("passcode")}`
  //       );
  //     }
  //   );
  // };

  useEffect(() => {
    //checking for signatures from all sides
    const partyNamesArray = arrayOfParties(filteredPrompt); //an array with the names of all parties
    setIsButtonEnabled(false);

    if (signee?.length >= partyNamesArray?.length) {
      let numberOfTrue = 0;

      for (let i = 0; i < partyNamesArray?.length; i++) {
        if (
          signee
            ?.filter((el) => el !== null)
            ?.filter((el) => el !== undefined)
            ?.some((el) => el?.userData[0].value === partyNamesArray[i])
        ) {
          //if at least one person has chosen the name of the i-th party in the selector, increase the number of parties with representatives by 1
          numberOfTrue++;
        }
      }

      if (numberOfTrue >= partyNamesArray?.length) {
        setIsButtonEnabled(true);
      }
    }
  }, [signee, isYouSigning, filteredPrompt]);

  useEffect(() => {
    const withOrder = signee
      ?.filter((el) => el !== null)
      ?.filter((el) => el !== undefined)
      ?.some((el) => el.showNumber === true);
    const isSender = signee
      ?.filter((el) => el !== null)
      ?.filter((el) => el !== undefined)
      ?.some((item) => item.isMainUser === true);

    // if (isSender && !withOrder) {
    //   changeSignAndSendButtonTitle("Sign and send document");
    // } else if (!isSender && !withOrder) {
    //   changeSignAndSendButtonTitle("Send document");
    // } else if (withOrder && !isSender) {
    //   changeSignAndSendButtonTitle("Send document");
    // } else {
    //   changeSignAndSendButtonTitle("Send document");
    // }

    signee
      ?.filter((el) => el !== null)
      ?.filter((el) => el !== undefined)
      ?.forEach((item, index) => {
        let isSender = item.isMainUser;
        let order = index + 1;

        if (withOrder && isSender && order === 1) {
          // changeSignAndSendButtonTitle("Sign and send document");
        }
      });
  }, [isYouSigning]);

  return (
    <Layout
      header={
        <Header
          title="Sign and send"
          shortTitle="Sign and send"
          progress={0}
          right={
            !openConfirmWindow ? (
              <div className={styles["c-review__header-button"]}>
                <CButton
                  type="primary"
                  icon={<Icons.ArrowRightBig />}
                  title={buttonTitle}
                  onClick={() => {
                    if (buttonTitle === "Sign and send document") {
                      // setIsPreview(false);
                      // setOpenConfirmWindow(true);
                    } else {
                      // preparationForSendingSignees();
                    }
                  }}
                  disabled={!isButtonEnabled} // isButtonEnabled = true - active
                />
              </div>
            ) : (
              <></>
            )
          }
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-wizard"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div
          className={[styles["c-review"], styles["c-send-contract"]].join(" ")}
        >
          <div className={styles["c-review__article"]}>
            {!openConfirmWindow ? (
              <div
                className={[
                  styles["c-review__wrapper"],
                  styles["c-send-contract__wrapper"],
                ].join(" ")}
              >
                <div className={[styles["c-send-contract__label"]]}>
                  <div className={[styles["c-review__title"]]}>
                    <h1>{t("contract:who_should_sign")}</h1>
                  </div>
                  <span className={styles["c-send-contract__small-text"]}>
                    The signatures are legally binding and valid according to
                    the <a href={"#"}>eIDAS Regulation.</a>
                  </span>
                </div>
                <div
                  className={[
                    styles["c-review__article"],
                    styles["c-send-contract__block"],
                  ].join(" ")}
                >
                  <div className={styles["c-send-contract__wrapper-litle"]}>
                    <div
                      className={styles["c-send-contract__block__title-part"]}
                    >
                      <span className={styles["c-send-contract__block__title"]}>
                        {t("sign_send:document")}
                      </span>
                      <div
                        className={styles["c-send-contract__block__eye"]}
                        onClick={() => {
                          // setIsPreview(true);
                          navigate(
                            keys.REVIEW_CONTRACTS +
                              `/${params.id}?passcode=${query.get("passcode")}`
                          );
                        }}
                      >
                        <p>Show document</p>
                        <Icons.EyeIcon color="#5F6CFF" />
                      </div>
                    </div>
                    <CInput
                      label={t("sign_send:title")}
                      placeholder={t("sign_send:title")}
                      onChange={(event) => {
                        // setDocumentTitle(event.target.value);
                        setInputInFocus(0);
                      }}
                      onClick={() => {}}
                      disabled={false}
                      value={document?.title}
                      focusOn={inputInFocus === 0 ? true : false}
                    />
                  </div>
                </div>

                <div
                  className={[
                    styles["c-review__article"],
                    styles["c-send-contract__block"],
                  ].join(" ")}
                >
                  <div className={styles["c-send-contract__wrapper-litle"]}>
                    <div
                      className={styles["c-send-contract__block__title-part"]}
                    >
                      <span className={styles["c-send-contract__block__title"]}>
                        {t("sign_send:signees")}
                      </span>
                    </div>
                    <div
                      className={styles["c-send-contract__block__switch-part"]}
                    >
                      <span>Are you signing?</span>
                      <div
                        className={
                          styles["c-send-contract__block__switch-container"]
                        }
                      >
                        <span
                          className={
                            styles["c-send-contract__block__switch-title"]
                          }
                        >
                          {isYouSigning ? "Yes" : "No"}
                        </span>
                        <CSwitch
                          checked={isYouSigning}
                          onChange={(value) => {
                            // setIsYouSigning(false);
                            if (value) {
                              navigate(
                                keys.SIGNEE +
                                  `/${params.id}?passcode=${query.get(
                                    "passcode"
                                  )}&user=${
                                    signee?.length + 1
                                  }&mainUser=true&action=add`
                              );
                            } else {
                              // updateSigneeData(
                              //   [],
                              //   signee?.findIndex(
                              //     (el) => el?.isMainUser === true
                              //   ),
                              //   "delete"
                              // );
                            }
                          }}
                          size={"small"}
                          color={"#5F6CFF"}
                        />
                      </div>
                    </div>
                    {isYouSigning &&
                      signee?.map((item, index) => {
                        if (
                          item !== null &&
                          item !== undefined &&
                          item.isMainUser === true
                        ) {
                          return (
                            <Card
                              key={index}
                              item={{
                                firstName: `${item?.userData?.[2]?.value}`,
                                lastName: `${item?.userData?.[3]?.value}`,
                                email: `${item?.userData?.[4]?.value}`,
                                initials: `${item?.userData?.[2]?.value
                                  .slice(0, 1)
                                  .toUpperCase()}${item?.userData?.[3]?.value
                                  .slice(0, 1)
                                  .toUpperCase()}`,
                              }}
                              index={item.showNumber === true ? index : null}
                              onEdit={() => {
                                navigate(
                                  keys.SIGNEE +
                                    `/${params.id}?passcode=${query.get(
                                      "passcode"
                                    )}&user=${
                                      index + 1
                                    }&mainUser=true&action=edit`
                                  //user - this is the number of the current item in the list, or if it is new then what position it should be
                                  //mainUser - indicates whether it is the user who created the contract (optional parameter)
                                );
                              }}
                              iconOnRight={<Icons.Edit />}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    <span
                      className={
                        styles["c-send-contract__block__title__between-cards"]
                      }
                    >
                      <span>Send invite to:</span>

                      {signee
                        ?.filter((el) => el !== null)
                        ?.filter((el) => el !== undefined)
                        ?.some((item) => item?.isMainUser === false) && (
                        <span
                          className={
                            styles["c-send-contract__block__title__link"]
                          }
                          onClick={() =>
                            navigate(
                              keys.SIGNEE +
                                `/${params.id}?passcode=${query.get(
                                  "passcode"
                                )}&user=${
                                  signee?.length + 1
                                }&mainUser=false&action=add`
                            )
                          }
                        >
                          {t("signee:add")}
                        </span>
                      )}
                    </span>
                    {!signee
                      ?.filter((el) => el !== null)
                      ?.filter((el) => el !== undefined)
                      ?.some((el) => el.isMainUser === false) ? (
                      <div className={styles["c-send-contract__add-signee"]}>
                        <Icons.AddSignee color={"#7F89FF"} />
                        <span
                          className={
                            styles["c-send-contract__add-signee__title"]
                          }
                        >
                          Start adding signees
                        </span>
                        <span
                          className={
                            styles["c-send-contract__add-signee__text"]
                          }
                        >
                          Before we can send your document, you first need to
                          add signees here.
                        </span>
                        <div
                          className={
                            styles["c-send-contract__add-signee__button"]
                          }
                        >
                          <CButton
                            type="icon-text"
                            icon={<Icons.PlusIcon color={"#5F6CFF"} />}
                            title={
                              <span
                                className={
                                  styles[
                                    "c-send-contract__add-signee__button-title"
                                  ]
                                }
                              >
                                {t("send-contract:add_signee")}
                              </span>
                            }
                            onClick={() => {
                              navigate(
                                keys.SIGNEE +
                                  `/${params.id}?passcode=${query.get(
                                    "passcode"
                                  )}&user=${
                                    signee?.length + 1
                                  }&mainUser=false&action=add`
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      signee?.map((item, index) => {
                        if (
                          item !== null &&
                          item !== undefined &&
                          item.isMainUser === false
                        ) {
                          return (
                            <div
                              className={styles["c-send-contract__card"]}
                              key={index}
                            >
                              <Card
                                item={{
                                  firstName: `${item?.userData?.[2]?.value}`,
                                  lastName: `${item?.userData?.[3]?.value}`,
                                  email: `${item?.userData?.[4]?.value}`,
                                  initials: `${item?.userData?.[2]?.value
                                    .slice(0, 1)
                                    .toUpperCase()}${item?.userData?.[3]?.value
                                    .slice(0, 1)
                                    .toUpperCase()}`,
                                }}
                                index={item.showNumber === true ? index : null}
                                onEdit={() => {
                                  navigate(
                                    keys.SIGNEE +
                                      `/${params.id}?passcode=${query.get(
                                        "passcode"
                                      )}&user=${
                                        index + 1
                                      }&mainUser=false&action=edit`
                                    //user - this is the number of the current item in the list, or if it is new then what position it should be
                                    //mainUser - indicates whether it is the user who created the contract (optional parameter)
                                  );
                                }}
                                iconOnRight={<Icons.Edit />}
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <ConfirmSendContract
                // onConfirm={preparationForSendingSignees}
                onBreakClick={() => {
                  // setOpenConfirmWindow(false);
                }}
              />
            )}
          </div>
        </div>

        {!openConfirmWindow ? (
          <div
            className={[
              styles["c-contract__navigation-bar__small-screen"],
              styles["c-review__finalize-document-mobile"],
            ].join(" ")}
          >
            <CButton
              type="primary"
              icon={<Icons.ArrowRightBig />}
              title={buttonTitle}
              onClick={() => {
                if (buttonTitle === "Sign and send document") {
                  // setIsPreview(false);
                  // setOpenConfirmWindow(true);
                } else {
                  // preparationForSendingSignees();
                }
              }}
              disabled={!isButtonEnabled} // isButtonEnabled = true - active
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  // isYouSigning: isYouSigningSelector(state),
  // signee: signeeDataSelector(state),
  document: documentSelector(state),
  // filteredPrompt: filteredPromptSelector(state),
  // openConfirmWindow: openConfirmWindowSelector(state),
  // userInfo: userInfoSelector(state),
  // buttonTitle: signAndSendButtonTitleSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // setIsPreview,
      // setIsYouSigning,
      // updateSigneeData,
      // setDocumentTitle,
      // sendSignees,
      // setOpenConfirmWindow,
      // changeSignAndSendButtonTitle,
      // getDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SendContract);

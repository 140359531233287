import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Row, Col, Upload, message } from "antd";

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  CInput,
  Icons,
  Modals,
  DefaultUserImg,
  Notification,
} from "../../components";

import { saveFile, resetPassword } from "../../store/actions/dashboard";
import { updateUser } from "../../store/actions/auth";

import { userSelector } from "../../store/selectors/auth";

import * as keys from "../../utils/keys";

import styles from "./index.module.scss";

const AccountSettings = ({ saveFile, user, updateUser, resetPassword }) => {
  const [form] = Form.useForm();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [notificationData, setNotificationData] = useState(null);

  const [inputInFocus, setInputInFocus] = useState(0);

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    imgFile: null,
    imgUrl: "",
  });

  useEffect(() => {
    if (!!user && !!form) {
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        workEmail: user.email,
      });
      setValues({
        ...values,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        imgUrl: user.image,
      });
    }
  }, [user, form]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isPng = file.type === "image/png";
    if (!isPng) {
      message.error("You can only upload PNG files!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }

    return isPng && isLt2M;
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => {
      setValues({
        ...values,
        imgUrl: imageUrl,
        imgFile: info.file.originFileObj,
      });
    });
  };

  const onFinish = () => {
    const updateValues = {};

    if (values.firstName !== user?.firstName) {
      updateValues.firstName = values.firstName;
    }
    if (values.lastName !== user?.lastName) {
      updateValues.lastName = values.lastName;
    }

    if (!!values.imgFile) {
      saveFile(values.imgFile, (link) => {
        updateValues.image = link;
        updateUser(updateValues);

        setValues({
          ...values,
          imgUrl: link,
          imgFile: "",
        });
      });
    } else {
      updateUser(updateValues);
    }
  };

  const handleChangePassword = () => {
    setShowModal(true);
  };

  const checkButtonDisabled = () => {
    if (
      !!values.imgFile ||
      values.firstName !== user?.firstName ||
      values.lastName !== user?.lastName
    ) {
      return false;
    }

    return true;
  };

  return (
    <Layout
      header={
        <Header
          title={`Account settings`}
          shortTitle={`Account settings`}
          right={
            <Link to={keys.DASHBOARD} className={"btn-close"}>
              <Icons.CloseIcon />
            </Link>
          }
          hideLogo={true}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-account-settings"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-account-settings__section"]}>
          <div className={styles["c-account-settings__article"]}>
            <h1>Profile</h1>

            <div className={styles["c-account-settings__fields"]}>
              <Form
                layout="vertical"
                id="profile"
                name="profile"
                // onFieldsChange={(val) => {
                // }}
                form={form}
              >
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="image">
                      <div className={styles["u-avatar"]}>
                        <div className={styles["u-avatar__photo"]}>
                          {values.imgUrl ? (
                            <img src={values.imgUrl} alt="avatar" />
                          ) : (
                            <DefaultUserImg
                              initials={
                                user?.initials ||
                                `${user?.firstName[0]}${user?.lastName[0]}`
                              }
                              size={"80px"}
                              initialsFontSize={"27px"}
                              initialsLineHeight={"33px"}
                            />
                          )}
                        </div>

                        <Upload
                          name="image"
                          className={styles["u-avatar__uploader"]}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                        >
                          <button className={styles["u-avatar__uploader__btn"]}>
                            Upload new photo
                          </button>
                        </Upload>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="firstName">
                      <CInput
                        label={"First name"}
                        placeholder={"First name"}
                        id="first-name"
                        name="first-name"
                        onChange={(event) => {
                          setValues({
                            ...values,
                            firstName: event.target.value,
                          });
                          setInputInFocus(2);
                        }}
                        focusOn={inputInFocus === 2 ? true : false}
                        onClick={() => {
                          setInputInFocus(2);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="lastName">
                      <CInput
                        label={"Last name"}
                        placeholder={"Last name"}
                        id="last-name"
                        name="last-name"
                        value={values.lastName}
                        onChange={(event) => {
                          setValues({
                            ...values,
                            lastName: event.target.value,
                          });
                          setInputInFocus(3);
                        }}
                        focusOn={inputInFocus === 3 ? true : false}
                        onClick={() => {
                          setInputInFocus(3);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item name="workEmail">
                      <CInput
                        label={"Email"}
                        placeholder={"Email"}
                        id="work-email"
                        name="work-email"
                        value={values.email}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className={styles["c-account-settings__confirm-button"]}>
              <CButton
                type="primary"
                title="Save changes"
                disabled={checkButtonDisabled()}
                onClick={onFinish}
              />
            </div>
          </div>

          <div
            className={`${styles["c-account-settings__article"]} ${styles["has-security"]}`}
          >
            <h1>Security</h1>

            <div className={styles["c-account-settings__fields"]}>
              <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
                <Col xs={{ span: 24 }}>
                  <div className={styles["u-style-label"]}>Password</div>

                  <div
                    onClick={() => {
                      resetPassword();
                    }}
                    className={styles["u-style-input"]}
                  >
                    <span>Reset password</span>
                    <i>
                      <Icons.ArrowRightIcon />
                    </i>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({ user: userSelector(state) });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveFile, updateUser, resetPassword }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);

import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

import styles from "./index.module.scss";

const DefaultUserImg = ({
  initials,
  count,
  size,
  initialsFontSize,
  initialsLineHeight,
  countFontSize,
  countLineHeight,
  firstName,
  lastName,
  withTooltip,
  image,
}) => {
  const initial = initials?.length
    ? initials
    : `${(firstName || "T")[0]}${(lastName || "T")[0]}`;

  return (
    <Tooltip
      title={
        withTooltip
          ? `${firstName} ${lastName}${count ? ` • Signee ${count}` : ""}`
          : ""
      }
      overlayClassName={"u-tooltip"}
    >
      <span
        className={styles["parties__user"]}
        style={{ width: size, height: size }}
      >
        {image ? (
          <img src={image} className={styles["parties__user__image"]} alt="" />
        ) : (
          <span
            className={styles["parties__user__name"]}
            style={{
              fontSize: initialsFontSize,
              lineHeight: initialsLineHeight,
            }}
          >
            {initial}
          </span>
        )}
        {count && (
          <span
            className={styles["parties__user__count"]}
            style={{ fontSize: countFontSize, lineHeight: countLineHeight }}
          >
            {count}
          </span>
        )}
      </span>
    </Tooltip>
  );
};

DefaultUserImg.propTypes = {
  initials: PropTypes.string,
  count: PropTypes.number,
  initialsFontSize: PropTypes.string,
  initialsLineHeight: PropTypes.string,
  countFontSize: PropTypes.string,
  countLineHeight: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

DefaultUserImg.defaultProps = {
  initials: undefined,
  count: null,
  initialsFontSize: "12px",
  initialsLineHeight: "13px",
  countFontSize: "6px",
  countLineHeight: "9px",
  width: "32px",
  height: "32px",
};

export default DefaultUserImg;

import React from "react";
import PropTypes from "prop-types";

const EditFile = ({ color = "currentColor", className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_416_9199)">
        <path
          fill={color}
          d="M16.7149 7.13796V5.85224C16.7149 5.73439 16.6185 5.63796 16.5006 5.63796H6.21491C6.09706 5.63796 6.00063 5.73439 6.00063 5.85224V7.13796C6.00063 7.25581 6.09706 7.35224 6.21491 7.35224H16.5006C16.6185 7.35224 16.7149 7.25581 16.7149 7.13796ZM6.21491 9.4951C6.09706 9.4951 6.00063 9.59153 6.00063 9.70939V10.9951C6.00063 11.113 6.09706 11.2094 6.21491 11.2094H11.1435C11.2613 11.2094 11.3578 11.113 11.3578 10.9951V9.70939C11.3578 9.59153 11.2613 9.4951 11.1435 9.4951H6.21491ZM10.0721 21.6022H3.85777V2.7451H18.8578V11.9594C18.8578 12.0772 18.9542 12.1737 19.0721 12.1737H20.5721C20.6899 12.1737 20.7863 12.0772 20.7863 11.9594V1.67367C20.7863 1.19956 20.4033 0.816528 19.9292 0.816528H2.78634C2.31223 0.816528 1.9292 1.19956 1.9292 1.67367V22.6737C1.9292 23.1478 2.31223 23.5308 2.78634 23.5308H10.0721C10.1899 23.5308 10.2863 23.4344 10.2863 23.3165V21.8165C10.2863 21.6987 10.1899 21.6022 10.0721 21.6022Z"
        />
        <path
          fill={color}
          d="M12.3953 20.7492C12.4188 20.7492 12.4422 20.7469 12.4656 20.7434L14.4365 20.3977C14.46 20.393 14.4822 20.3825 14.4986 20.3649L19.4657 15.3978C19.4766 15.387 19.4852 15.3741 19.4911 15.3599C19.497 15.3458 19.5 15.3306 19.5 15.3152C19.5 15.2999 19.497 15.2847 19.4911 15.2705C19.4852 15.2563 19.4766 15.2434 19.4657 15.2326L17.5183 13.284C17.496 13.2617 17.4667 13.25 17.4351 13.25C17.4034 13.25 17.3741 13.2617 17.3519 13.284L12.3848 18.2511C12.3672 18.2686 12.3567 18.2897 12.352 18.3132L12.0063 20.2841C11.9949 20.3468 11.999 20.4114 12.0182 20.4723C12.0374 20.5331 12.0711 20.5884 12.1165 20.6332C12.1938 20.7082 12.2911 20.7492 12.3953 20.7492Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_416_9199">
          <rect
            width="24"
            height="24"
            fill="none"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

EditFile.propTypes = {
  color: PropTypes.string,
};

export default EditFile;

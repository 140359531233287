import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { useEffect, useMemo, useState } from "react";
import styles from "../index.module.scss";
import { useTemplate } from "../../../contexts/TemplateContext";
import Icons from "../../Icons";
import { Dropdown } from "antd";
import { REMOVE_ONE_TAG_BY_KEY_COMMAND } from "../plugins/TagsPlugin";

export class TagNode extends DecoratorBlockNode {
  __id;

  static getType() {
    return "tag";
  }

  static clone(node) {
    return new TagNode(node.__id, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createTagNode(serializedNode.id);
    node.setFormat(serializedNode.format);
    return node;
  }

  isInline() {
    return true;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      type: "tag",
      version: 1,
    };
  }

  static importDOM() {
    return {
      div: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-tag-id")) {
          return null;
        }
        return {
          conversion: convertTagElement,
          priority: 2,
        };
      },
    };
  }

  exportDOM() {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-tag-id", this.__id);
    return { element };
  }

  createDOM(_config) {
    const element = document.createElement("span");
    return element;
  }

  constructor(id, key) {
    super(key);
    this.__id = id;
  }

  getId() {
    return this.__id;
  }

  decorate(editor, config) {
    return (
      <TagComponent tagID={this.__id} nodeKey={this.__key} editor={editor} />
    );
  }
}

export function $createTagNode(id) {
  return new TagNode(id);
}

function convertTagElement(domNode) {
  const id = domNode.getAttribute("data-lexical-tag-id");
  if (id) {
    const node = $createTagNode(id);
    return { node };
  }
  return null;
}

const items = [
  {
    label: "Delete",
    key: "remove",
    icon: <Icons.DeleteIcon size={20} />,
    className: styles["danger-dropdown-item"],
  },
];

function TagComponent({ tagID, nodeKey, editor }) {
  const { variables, setCurrentSelectedVariableKey } = useTemplate();
  const tag = useMemo(() => {
    return variables.find((t) => t.variableID === tagID);
  }, [tagID, variables]);
  const [open, setOpen] = useState(false);

  function changeDropdown(e) {
    if (e.key === "remove") {
      editor.dispatchCommand(REMOVE_ONE_TAG_BY_KEY_COMMAND, nodeKey);
    }
  }

  function onOpenChange(open) {
    if (open) {
      setCurrentSelectedVariableKey(tag?.key);
    } else {
      setCurrentSelectedVariableKey("");
    }
    setOpen(open);
  }

  return (
    <Dropdown
      open={open}
      onOpenChange={onOpenChange}
      menu={{ items, onClick: changeDropdown }}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <button className={styles["tag"]}>{tag?.key}</button>
    </Dropdown>
  );
}

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setAuth0 } from "../store/actions/auth";

const Auth0Wrapper = ({ children }) => {
  const dispatch = useDispatch();

  const auth0 = useAuth0();

  useEffect(() => {
    dispatch(setAuth0(auth0));
  }, [auth0]);

  return children;
};

export default Auth0Wrapper;

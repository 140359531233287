import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import { CInput } from "../../../components";
import CCheckbox from "../../Checkbox";
import { validateWithRegularExpression } from "../../../utils/helper";
import { ANY_DATE } from "../../../utils/regularExpression";

const Terms = ({
  groupId = "",
  placeholder,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  form,
  visibleIndex,
  position,
  checkbox,
  isCheckboxChecked,
  documentStyles,
  cardData,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (form && visibleIndex === position) {
      form.setFieldsValue({
        [`${groupId}###startDate`]: "",
        [`${groupId}###contractDuration`]: "",
        [`${groupId}###cancellationPeriod`]: "",
      });
    }
  }, [visibleIndex, form, position]);

  const showError = () => {
    const neededValue = cardData?.filter((item) =>
      item?.name?.includes("startDate")
    )?.[0]?.value;

    if (
      !!neededValue &&
      !validateWithRegularExpression(ANY_DATE, neededValue)
    ) {
      return t("other:wrong_date_format");
    } else {
      return null;
    }
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###startDate`}>
            <CInput
              label={t(`wizard:labels.start_date`)}
              placeholder={placeholder?.date?.startDate ?? ""}
              name="startDate"
              onChange={(value) => {
                setNumberOfInputForFocus(0);
              }}
              onClick={() => {
                setNumberOfInputForFocus(0);
              }}
              focusOn={numberOfInputForFocus === 0}
              customType={"date"}
              font={documentStyles?.font}
              color={documentStyles?.color}
              errors={showError()}
              inputmode={"decimal"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###contractDuration`}>
            <CInput
              label={t(`wizard:labels.contract_duration_months`)}
              placeholder={placeholder?.date?.contractLengthInMonths ?? ""}
              name="contractDuration"
              type="number"
              onChange={() => {
                setNumberOfInputForFocus(1);
              }}
              onClick={() => {
                setNumberOfInputForFocus(1);
              }}
              focusOn={numberOfInputForFocus === 1}
              font={documentStyles?.font}
              color={documentStyles?.color}
              customType="positiveNumbers"
              inputmode={"decimal"}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###cancellationPeriod`}>
            <CInput
              label={t(`wizard:labels.cancellation_period_months`)}
              placeholder={placeholder?.date?.cancellationPeriodInMonths ?? ""}
              name="cancellationPeriod"
              type="number"
              onChange={() => {
                setNumberOfInputForFocus(2);
              }}
              onClick={() => {
                setNumberOfInputForFocus(2);
              }}
              focusOn={numberOfInputForFocus === 2}
              font={documentStyles?.font}
              color={documentStyles?.color}
              customType="positiveNumbers"
              inputmode={"decimal"}
              pattern="\d*"
            />
          </Form.Item>
        </Col>
        {checkbox && (
          <Col className="gutter-row" span={24}>
            <Form.Item name={`${groupId}###checkbox`}>
              <CCheckbox
                type={"checkbox"}
                title={t("wizard:buttons.invite_recipient")}
                checked={isCheckboxChecked}
                font={documentStyles?.font}
                color={documentStyles?.color}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Terms;

import { createActions } from "redux-actions";

import { AUTH } from "../types";

export const {
  getAccountData,
  setToken,
  clearAuthData,
  changeAccountData,
  deleteAccountData,
  updateUser,
  updateAccountData,
  createDocumentFunction,
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  setRedirectParams,
  setErrorLoadingScreenType,
  sendWorkspaceInvite,
  setCurrentWorkspace,
  setTempUser,
  setAuth0,
} = createActions({
  [AUTH.GET_ACCOUNT_DATA]: ({ user }) => ({
    user,
  }),
  [AUTH.SET_TOKEN]: (token) => ({
    token,
  }),
  [AUTH.CLEAR_AUTH_DATA]: () => ({}),
  [AUTH.CHANGE_ACCOUNT_DATA]: (values, callback) => ({
    values,
    callback,
  }),
  [AUTH.DELETE_ACCOUNT_DATA]: (values, callback) => ({
    values,
    callback,
  }),
  [AUTH.UPDATE_USER]: (values, callback) => ({
    values,
    callback,
  }),
  [AUTH.UPDATE_ACCOUNT_DATA]: (returnFiles) => ({
    returnFiles,
  }),
  [AUTH.CREATE_DOCUMENT_FUNCTION]: (templateId, callback) => ({
    templateId,
    callback,
  }),
  [AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD]: (documentId, body, callback) => ({
    documentId,
    body,
    callback,
  }),
  [AUTH.DELETE_DOCUMENT]: (documentId) => ({
    documentId,
  }),
  [AUTH.GET_SORTED_DOCUMENTS]: (sortBy) => ({ sortBy }),
  [AUTH.SET_REDIRECT_PARAMS]: (params) => ({ params }),
  [AUTH.SET_ERROR_LOADING_SCREEN_TYPE]: (type) => ({ type }),
  [AUTH.SEND_WORKSPACE_INVITE]: (accountID, userID, callback) => ({
    accountID,
    userID,
    callback,
  }),
  [AUTH.SET_CURRENT_WORKSPACE]: (workspace, sortBy) => ({
    workspace,
    sortBy,
  }),
  [AUTH.SET_TEMP_USER]: (userId) => userId,
  [AUTH.SET_AUTH0]: (values) => ({
    values,
  }),
});

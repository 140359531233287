import React from "react";
import PropTypes from "prop-types";

const DeclineIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9 0.75C4.16797 0.75 0.25 4.66797 0.25 9.5C0.25 14.332 4.16797 18.25 9 18.25C13.832 18.25 17.75 14.332 17.75 9.5C17.75 4.66797 13.832 0.75 9 0.75ZM14.6348 14.0859L4.42383 3.875C4.03906 4.1875 3.6875 4.53906 3.375 4.92383L13.5859 15.1348C12.3359 16.1543 10.7383 16.7656 9 16.7656C4.98828 16.7656 1.73438 13.5117 1.73438 9.5C1.73438 5.48828 4.98828 2.23438 9 2.23438C13.0117 2.23438 16.2656 5.48828 16.2656 9.5C16.2656 11.2383 15.6543 12.8359 14.6348 14.0859Z"
        fill={color}
      />
    </svg>
  );
};

DeclineIcon.propTypes = {
  color: PropTypes.string,
};

export default DeclineIcon;

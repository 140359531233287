import { useEffect, useState } from "react";
import { CInput, CButton, Icons } from "../../components";
import { Form, Row, Col } from "antd";
import { Reminder } from "./Reminder";
import styles from "./index.module.scss";
import * as keys from "../../utils/keys";
import SigneeItem from "./SigneeItem";

export function SigneesModal({
  documentStyles,
  t,
  addSignees,
  goToModal,
  oldData,
  editSignees,
}) {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const onFinish = () => {
    const values = form.getFieldsValue();
    const initials = values.firstName.charAt(0) + values.lastName.charAt(0);
    isEditing
      ? editSignees({
          contact: { initials, ...values },
          signeeID: oldData?.signeeID,
        })
      : addSignees({
          contact: { initials, ...values },
        });
    goToModal(null);
  };

  useEffect(() => {
    const checkFormValidity = async () => {
      if (userHasInteracted) {
        try {
          await form.validateFields();
          setIsFormValid(true); // valid form means button is not disabled
        } catch (errorInfo) {
          setIsFormValid(false); // invalid form means button is disabled
        }
      }
    };

    checkFormValidity();
  }, [form, formValues, userHasInteracted, touchedFields]);

  useEffect(() => {
    if (oldData) {
      form.setFieldsValue({
        firstName: oldData.contact.firstName,
        lastName: oldData.contact.lastName,
        email: oldData.contact.email,
      });
      setIsEditing(true);
      setIsFormValid(true);
    }
  }, [form, oldData]);

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  return (
    <div>
      <h1 style={{ marginBottom: 48 }}>What are the details of the Signees?</h1>

      <div>
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          onValuesChange={(changedValues, allValues) => {
            setFormValues(allValues);
            setUserHasInteracted(true);
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        } else if (touchedFields?.firstName) {
                          return Promise.reject(
                            new Error("Please input the signee first name")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CInput
                    label={t("send-contract:signee.labels.first_name")}
                    placeholder={t(
                      "send-contract:signee.placeholders.first_name"
                    )}
                    color={documentStyles?.color}
                    font={documentStyles?.font}
                    onChange={() => handleBlur("firstName")}
                    autoFocus
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: "8px" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        } else if (touchedFields?.lastName) {
                          return Promise.reject(
                            new Error("Please input the signee last name")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CInput
                    label={t("send-contract:signee.labels.last_name")}
                    placeholder={t(
                      "send-contract:signee.placeholders.last_name"
                    )}
                    color={documentStyles?.color}
                    font={documentStyles?.font}
                    onChange={() => handleBlur("lastName")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message:
                      "Oops! This email address is not valid, please try again. The email must be in 'example@example.com' format.",
                    transform: (value) => value?.trim(),
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        return Promise.resolve();
                      } else if (touchedFields?.email) {
                        return Promise.reject(
                          new Error("Please input your E-mail!")
                        );
                      }
                    },
                  },
                ]}
              >
                <CInput
                  label={t("send-contract:signee.labels.work_email")}
                  placeholder={t(
                    "send-contract:signee.placeholders.work_email"
                  )}
                  color={documentStyles?.color}
                  font={documentStyles?.font}
                  onChange={() => handleBlur("email")}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
              }}
            >
              <CButton
                htmlType="submit"
                type="primary"
                disabled={!isFormValid}
                title={t("signee:add")}
                backgroundColor={documentStyles?.color}
                font={documentStyles?.font}
              />

              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  gap: 4,
                  alignItems: "center",
                }}
              >
                press Enter
                <Icons.Enter size="12" />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export function Signees(props) {
  const {
    documentStyles,
    isTemplateGenerator,
    t,
    saveEdit,
    thisDocument,
    setNewDocumentData,
  } = props;

  function handleReminder(body) {
    saveEdit(body);
  }

  return (
    <div className={styles["c-signsend-status__item"]}>
      <div className={styles["c-signsend-status__article"]}>
        <div
          className={styles["teritary__head"]}
          style={{
            fontFamily: documentStyles?.font,
            borderBottom: `1px solid ${
              documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
            }`,
          }}
        >
          <div className={styles["teritary__head__title"]}>
            {t("sign_send:signees")}
          </div>
        </div>

        <div
          className={styles["teritary__row"]}
          style={{
            fontFamily: documentStyles?.font,
          }}
        >
          {isTemplateGenerator && (
            <span style={{ padding: "0px 12px" }}>
              Predefine the people who sign documents for party 1 created from
              this template.
            </span>
          )}

          {isTemplateGenerator ? (
            <TemplateGeneratorSignees {...props} />
          ) : (
            <DocumentSignees {...props} />
          )}
        </div>
        <Reminder
          defaultReminder={
            thisDocument?.reminder ?? thisDocument?.defaultReminder
          }
          setNewDocumentData={setNewDocumentData}
          saveEdit={saveEdit}
          recipient={"signees"}
        />
      </div>
    </div>
  );
}

const TemplateGeneratorSignees = ({
  documentStyles,
  thisDocument,
  deleteSignees,
  t,
  goToModal,
  setOldData,
}) => {
  return (
    <div
      className={styles["teritary__item"]}
      style={{ flex: 1, maxWidth: "none" }}
    >
      <div
        className={styles["teritary__card"]}
        style={{
          border: `1px solid ${
            documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
          }`,
        }}
      >
        <div
          className={styles["teritary__card__head"]}
          style={{
            borderBottom: `1px solid ${
              documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
            }`,
          }}
        >
          <div className={styles["icon"]}>
            <Icons.OfficeIcon color={documentStyles?.color} />
          </div>
          <div className={styles["title"]}>Party</div>
        </div>

        <div className={styles["teritary__card__body"]}>
          <div className={styles["creator-signature"]}>
            {thisDocument?.defaultSignees?.map((localSigneeData, index) => {
              return (
                <SigneeItem
                  key={`signee-item-${index}`}
                  documentStyles={documentStyles}
                  initials={
                    localSigneeData?.contact?.initials
                      ? localSigneeData?.contact?.initials
                      : `${localSigneeData?.contact?.firstName?.[0]?.toUpperCase()}${localSigneeData?.contact?.lastName?.[0]?.toUpperCase()}`
                  }
                  firstName={localSigneeData?.contact?.firstName}
                  lastName={localSigneeData?.contact?.lastName}
                  email={localSigneeData?.contact?.email}
                  image={localSigneeData?.contact?.image}
                  number={
                    localSigneeData?.showOrder ? localSigneeData?.order : null
                  }
                  onDeletePress={() => {
                    deleteSignees(localSigneeData?.signeeID);
                  }}
                  onEditClick={() => {
                    setOldData(localSigneeData);
                    goToModal("signees");
                  }}
                />
              );
            })}

            <div
              className={styles["creator-signature__item"]}
              onClick={() => {
                setOldData(null);
                goToModal("signees");
              }}
            >
              <div className={styles["creator-signature__card"]}>
                <div
                  className={`${styles["creator-signature__card__logo"]} ${styles["add-signee"]}`}
                >
                  <Icons.PlusCreatorIcon color={documentStyles?.color} />
                </div>
                <div className={styles["creator-signature__card__detail"]}>
                  <div className={styles["creator-signature__card__name"]}>
                    {t("send-contract:add_signee")}
                  </div>
                  <div className={styles["creator-signature__card__info"]}>
                    {t("sign_send:multiple_can_sign")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DocumentSignees = ({
  documentStyles,
  thisDocument,
  formSignatures,
  navigate,
  t,
}) => {
  if (!thisDocument?.parties) return null;

  return thisDocument.parties.map((partyItem, index) => {
    return (
      <div key={`party-item-${index}`} className={styles["teritary__item"]}>
        <div
          className={styles["teritary__card"]}
          style={{
            border: `1px solid ${
              documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
            }`,
          }}
        >
          <div
            className={styles["teritary__card__head"]}
            style={{
              borderBottom: `1px solid ${
                documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
              }`,
            }}
          >
            <div className={styles["icon"]}>
              <Icons.OfficeIcon color={documentStyles?.color} />
            </div>
            <div className={styles["title"]}>
              {partyItem?.type === "Company"
                ? `${partyItem?.contact?.organizationName ?? ""} ${
                    partyItem?.contact?.organizationType ?? ""
                  }`
                : `${partyItem?.contact?.firstName ?? ""} ${
                    partyItem?.contact?.lastName ?? ""
                  }`}
            </div>
          </div>

          <div className={styles["teritary__card__body"]}>
            <div className={styles["creator-signature"]}>
              {formSignatures(partyItem)}
              <div
                className={styles["creator-signature__item"]}
                onClick={() => {
                  navigate(keys.SIGNEE, {
                    state: {
                      isEditing: false,
                      partyId: partyItem.partyID,
                    },
                  });
                }}
              >
                <div className={styles["creator-signature__card"]}>
                  <div
                    className={`${styles["creator-signature__card__logo"]} ${styles["add-signee"]}`}
                  >
                    <Icons.PlusCreatorIcon color={documentStyles?.color} />
                  </div>
                  <div className={styles["creator-signature__card__detail"]}>
                    <div className={styles["creator-signature__card__name"]}>
                      {t("send-contract:add_signee")}
                    </div>
                    <div className={styles["creator-signature__card__info"]}>
                      {t("sign_send:multiple_can_sign")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

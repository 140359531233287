import React from "react";
import PropTypes from "prop-types";

const PlusFillIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.5 0.25C4.66797 0.25 0.75 4.16797 0.75 9C0.75 13.832 4.66797 17.75 9.5 17.75C14.332 17.75 18.25 13.832 18.25 9C18.25 4.16797 14.332 0.25 9.5 0.25ZM13.25 9.46875C13.25 9.55469 13.1797 9.625 13.0938 9.625H10.125V12.5938C10.125 12.6797 10.0547 12.75 9.96875 12.75H9.03125C8.94531 12.75 8.875 12.6797 8.875 12.5938V9.625H5.90625C5.82031 9.625 5.75 9.55469 5.75 9.46875V8.53125C5.75 8.44531 5.82031 8.375 5.90625 8.375H8.875V5.40625C8.875 5.32031 8.94531 5.25 9.03125 5.25H9.96875C10.0547 5.25 10.125 5.32031 10.125 5.40625V8.375H13.0938C13.1797 8.375 13.25 8.44531 13.25 8.53125V9.46875Z"
        fill={color}
      />
    </svg>
  );
};

PlusFillIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default PlusFillIcon;

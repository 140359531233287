import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Form, Row, Col } from "antd";
import { $generateHtmlFromNodes } from '@lexical/html';

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  Icons,
  CCheckbox,
  LongPressButton,
  StepTopBar,
} from "../../components";

import {
  documentSelector,
  isCreatingDocumentSelector,
} from "../../store/selectors/prompts";
import {
  redirectParamsSelector,
  userSelector,
} from "../../store/selectors/auth";

import {
  signDocument,
  setAvailableHeaderStep,
  updateDocumentData,
} from "../../store/actions/prompts";
import { setRedirectParams } from "../../store/actions/auth";

import * as regular from "../../utils/regularExpression";
import * as keys from "../../utils/keys";
import { useQuery, validateWithRegularExpression } from "../../utils/helper";

import styles from "./index.module.scss";
import wizardStyles from "../../styles/Wizard.module.scss";
import reviewStyles from "../ReviewInput/index.module.scss";
import signeeStyles from "../Signee/index.module.scss";
import signAndSendStyles from "../SignSend/index.module.scss";
// import { initialDocumentViewerConfig } from "../../components/Lexical/document-viewer";
// import { defaultNodes } from "../../components/Lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useDocumentPermission } from "../../hooks/permissions/document-permissions";

const Sign = ({
  thisDocument,
  currentUser,
  signDocument,
  redirectParams,
  setRedirectParams,
  setAvailableHeaderStep,
  isCreatingDocument,
}) => {
  const [editor] = useLexicalComposerContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isOwner } = useDocumentPermission();

  let navigate = useNavigate();

  const query = useQuery(redirectParams?.search);

  const location = useLocation();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [isFromEmail, setIsFromEmail] = useState(false);
  const [documentStyles, setDocumentStyles] = useState(null);
  const [isAutorized, setIsAutorized] = useState(false);
  const [error, setError] = useState("");
  const [buttonSigned, setButtonSigned] = useState(false);

  let timeoutId = null;

  useEffect(() => {
    setAvailableHeaderStep(2);

    if (!isCreatingDocument && !location.state?.cameFromWorkspace) {
      navigate(keys.DASHBOARD);
    }
  }, []);

  useEffect(() => {
    if (location?.state?.cameFromEmail) {
      setIsFromEmail(true);
    } else {
      setIsFromEmail(false);
    }
  }, [location]);

  useEffect(() => {
    if (thisDocument) {
      if (thisDocument?.style?.isApplyToWizard) {
        setDocumentStyles(thisDocument?.style);
      } else {
        setDocumentStyles(null);
      }
    }
  }, [thisDocument]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp font={documentStyles?.font} />);
    setOpenPanelVisible(true);
  };

  const htmlString = useMemo(() => {
    let html
    if (thisDocument?.lexicalState && editor) {
      editor.update(() => {
        editor.setEditorState(editor.parseEditorState(thisDocument.lexicalState));
        html = $generateHtmlFromNodes(editor);
      })
    }

    return html
  }, [editor, thisDocument])

  const isGenerated = useMemo(() => {
    return thisDocument?.type === "GENERATED";
  }, [thisDocument])

  function handleSignOnPress() {
    if (buttonSigned) {
      return;
    }

    setButtonSigned(true);

    signDocument(
      thisDocument.documentID,
      isFromEmail
        ? query.get("userId")
          ? query.get("userId")
          : currentUser.userID
        : currentUser.userID,
      (response) => {
        if (response !== "error") {
          setRedirectParams(null);

          if (isOwner && isGenerated) {
            dispatch(updateDocumentData(
              thisDocument.documentID,
              {
                content: htmlString,
              },
              false
            ))
          }
          navigate(keys.DOCUMENT_OVERVIEW, {
            state: {
              cameAfterEmail: isFromEmail,
              currentUserJustSigned: true,
              cameFromWockspace: location.state?.cameFromWorkspace,
            },
            replace: true,
          });
        } else {
          setButtonSigned(false);
        }
      }
    );
  }

  return (
    <Layout
      header={
        isFromEmail ? (
          <Header
            leftBlock={
              <div className={reviewStyles["c-review__sign-header-left-side"]}>
                <div className={reviewStyles["c-review__sign-header-icon"]}>
                  <Icons.SwiftsignLogoIcon width={82} height={18} />
                </div>
                <div className={reviewStyles["c-review__sign-header-text"]}>
                  <span
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {thisDocument?.title}
                  </span>
                </div>
                <div />
              </div>
            }
            stepTopBar={
              <StepTopBar
                currentId={3}
                color={documentStyles?.color}
                font={documentStyles?.font}
                canClickTab={!isFromEmail}
              />
            }
            documentStyles={documentStyles}
          />
        ) : (
          <Header
            title={t("contract:signature")}
            shortTitle={t("contract:signature")}
            progress={0}
            right={<div></div>}
            documentStyles={documentStyles}
          />
        )
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      questionIconColor={documentStyles?.color}
    >
      <div
        className={`${wizardStyles["c-wizard"]} ${signeeStyles["c-signee"]}`}
        style={{ fontFamily: documentStyles?.font }}
      >
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
          closeIconColor={documentStyles?.color}
        />

        <div className={signeeStyles["c-signee__wrapper"]}>
          <div
            className={[
              reviewStyles["c-review__article"],
              signeeStyles["c-signee__article"],
              styles["c-sign"],
            ].join(" ")}
          >
            <div
              className={styles["c-sign__head"]}
              style={{
                fontFamily: documentStyles?.font,
              }}
            >
              <h1>{t("sign:signature_required")}</h1>

              <p>{t("sign:confirm_signature")}</p>
            </div>

            <CCheckbox
              title={t("sign:i_am_autorized")}
              font={documentStyles?.font}
              color={documentStyles?.color}
              checked={isAutorized}
              onChange={() => {
                setIsAutorized(!isAutorized);
              }}
            />

            <div className={signAndSendStyles["c-signsend__confirm-buttons"]}>
              <LongPressButton
                font={documentStyles?.font}
                color={documentStyles?.color}
                disabled={!isAutorized}
                onConfirm={handleSignOnPress}
                onPressOutEarly={() => {
                  const errorMessage = !isAutorized
                    ? t("sign:check_checkbox")
                    : t("sign:hold_to_sign");
                  setError(errorMessage);
                  // Clear previous timeout and start a new one.
                  clearTimeout(timeoutId);
                  timeoutId = setTimeout(() => setError(""), 2000);
                }}
              />
              <CButton
                icon={<Icons.CrossIcon />}
                title={t("sign:cancel")}
                type={"icon-text"}
                color={documentStyles?.color}
                font={documentStyles?.font}
                customStyles={signAndSendStyles["c-signsend__cancel-button"]}
                onClick={() => {
                  if (redirectParams) {
                    navigate(keys.REVIEW_INPUT, {
                      state: {
                        isSign: true,
                      },
                      replace: true,
                    });
                  } else {
                    navigate(-1);
                  }
                }}
              />
            </div>
            {error && <div className={styles["c-sign__error"]}>{error}</div>}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  currentUser: userSelector(state),
  redirectParams: redirectParamsSelector(state),
  thisDocument: documentSelector(state),
  isCreatingDocument: isCreatingDocumentSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signDocument,
      setRedirectParams,
      setAvailableHeaderStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sign);

import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { CButton, CInput } from "../../../components";
import styles from "../index.module.scss";

const Registration = (props) => {
  const { onClose, updateUser, onUpdateStart, logout } = props;
  const [form] = Form.useForm();

  const [inputFormInFocus, setInputFormInFocus] = useState(0);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (!!inputValues?.firstName && !!inputValues?.lastName) {
      setIsBtnDisabled(false);
    }
  }, [inputValues]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      setInputFormInFocus(inputFormInFocus + 1);

      onFinish();
    }
    if (event.keyCode === 9) {
      event.preventDefault();
      setInputFormInFocus(inputFormInFocus + 1);
    }
  };

  const onFinish = () => {
    if (!!inputValues?.firstName && !!inputValues?.lastName) {
      onUpdateStart();
      const values = {
        firstName: inputValues.firstName,
        lastName: inputValues.lastName,
      };

      updateUser(values, () => {
        onClose();
      });
    }
  };

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["has-from"]}`}>
        <Button
          shape="circle"
          type="text"
          style={{ position: "absolute", right: 15, top: 5 }}
          icon={<CloseOutlined />}
          onClick={() => logout({ returnTo: window.location.origin })}
        />

        <div className={styles["u-modal__form__head"]}>
          <h4>It’s a pleasure to meet you</h4>

          <p>Fill in your details to complete the registration.</p>
        </div>

        <Form
          layout="vertical"
          id="registration"
          name="registration"
          form={form}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please make sure to enter your first name.",
                  },
                ]}
              >
                <CInput
                  label={"First name"}
                  id="first-name"
                  name="first-name"
                  onChange={(event) => {
                    setInputFormInFocus(0);

                    setInputValues({
                      ...inputValues,
                      firstName: event.target.value,
                    });
                  }}
                  focusOn={inputFormInFocus === 0 ? true : false}
                  onClick={() => {
                    setInputFormInFocus(0);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please make sure to enter your last name.",
                  },
                ]}
              >
                <CInput
                  label={"Last name"}
                  id="last-name"
                  name="last-name"
                  onChange={(event) => {
                    setInputFormInFocus(1);

                    setInputValues({
                      ...inputValues,
                      lastName: event.target.value,
                    });
                  }}
                  focusOn={inputFormInFocus === 1 ? true : false}
                  onClick={() => {
                    setInputFormInFocus(1);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={`${styles["u-modal__form__foot"]} ${styles["tal"]}`}>
          <div className={styles["u-modal__form__foot__item"]}>
            <CButton
              onClick={onFinish}
              type="primary"
              title={"Continue"}
              customStyles={styles["btn-form"]}
              disabled={isBtnDisabled}
            />
            <div className={styles["or-with-enter"]}>or with ENTER</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

import React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import styles from "./index.module.scss";
import { ContentEditable } from "./components/ContentEditable";
import { defaultOnError, defaultTheme } from ".";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { TagViewNode } from "./nodes/TagViewNode";
import { TagViewPlugin } from "./plugins/TagViewPlugin";
import AddCommentPlugin from "./plugins/AddCommentPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import CommentViewPlugin from "./plugins/CommentViewPlugin";
import CommentPlugin from "./plugins/CommentPlugin";
import { TableNode, TableCellNode, TableRowNode } from "@lexical/table";
import { TablePlugin } from "./plugins/TablePlugin";
import TableCellResizerPlugin from "./plugins/TableCellResizer";
import { tableNodes } from '.'

export const initialDocumentViewerConfig = {
  namespace: "DocumentViewerWithComments",
  theme: defaultTheme,
  nodes: [ListNode, ListItemNode, HeadingNode, TagViewNode, ...tableNodes],
  onError: defaultOnError
};

export function DocumentViewer({
  onError = defaultOnError,
  state,
  useOutsideComposer,
  onChange,
  disableSelectionChange,
  addCommentPlugin,
}) {
  const [floatingAnchorElem, setFloatingAnchorElem] = React.useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

 
  return useOutsideComposer ? (
    <>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable
              contentEditable={false}
              className={styles["ContentEditable__root"]}
            />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && addCommentPlugin && (
        <AddCommentPlugin anchorElem={floatingAnchorElem} />
      )}
      <ListPlugin />
      <TagViewPlugin />
      <TablePlugin
        hasCellMerge={true}
        hasCellBackgroundColor={true}
      />
      <TableCellResizerPlugin />
      {addCommentPlugin ? (
        <CommentPlugin/>
      ) : (
        <CommentViewPlugin />
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
    </>
  ) : (
    <LexicalComposer initialConfig={{
      ...initialDocumentViewerConfig,
      editorState: state,
      onError
    }}>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable
              contentEditable={false}
              className={styles["ContentEditable__root"]}
            />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && addCommentPlugin && (
        <AddCommentPlugin anchorElem={floatingAnchorElem} />
      )}
      <ListPlugin />
      <TagViewPlugin />
      <TablePlugin
        hasCellMerge={true}
        hasCellBackgroundColor={true}
      />
      <TableCellResizerPlugin />
      {addCommentPlugin ? (
        <CommentPlugin/>
      ) : (
        <CommentViewPlugin />
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
    </LexicalComposer>
  );
}

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { LeftSidebar } from "./components/LeftSideBar";
import { KeysNavigations } from "./components/KeysNavigation";
import styles from "./index.module.scss";
import "./prompts.scss";

import { TemplateDataFieldContent } from "../../components/ReviewInput/TemplateGenerator";
import { reverse } from "lodash";
import PromptCard from "./components/PromptCard";

export const PromptGenerator = ({
  documentLanguage,
  previewTemplate,
  setPreviewTemplate,
  template,
  onUpdatePrompt,
  visibleIndex,
  setVisibleIndex,
  variablesCategories,
  sortedPrompts,
  form,
  onUpdateVariable,
  onDeleteVariable,
  onCreateVariable,
  currentSelectedVariable,
  step,
}) => {
  const [openAsidePanel, setOpenAsidePanel] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState();
  const [currentPromptQuestion, setCurrentPromptQuestion] = useState("");
  const [currentPromptDesc, setCurrentPromptDesc] = useState("");
  const [currentPromptLabel, setCurrentPromptLabel] = useState("");
  const parentDivRef = useRef(null);
  const questionTitleInputRef = useRef(null);
  const isInput =
    currentPrompt?.questionType === "STRING" ||
    currentPrompt?.questionType === "NUMBER";

  useEffect(() => {
    const newCurrentPrompt = sortedPrompts.find(
      (prompt) => prompt.position === visibleIndex
    );
    if (!newCurrentPrompt) return;

    setCurrentPromptQuestion(
      typeof newCurrentPrompt.question === "string"
        ? newCurrentPrompt.question
        : ""
    );
    if (typeof newCurrentPrompt.description === "string")
      setCurrentPromptDesc(newCurrentPrompt.description);
    if (typeof newCurrentPrompt.inputFieldLabel === "string")
      setCurrentPromptLabel(newCurrentPrompt.inputFieldLabel);

    setCurrentPrompt(newCurrentPrompt);
  }, [sortedPrompts, visibleIndex]);

  const currentVariable = useMemo(() => {
    return template?.variables?.find(
      (variable) => variable?.key === currentPrompt?.shortPromptName
    );
  }, [template, currentPrompt]);

  const onEditPrompt = useCallback(
    (optionalValues) => {
      const editedPrompt = {
        ...currentPrompt,
        question: currentPromptQuestion,
        description: currentPromptDesc,
        inputFieldLabel: currentPromptLabel,
        ...optionalValues,
      };

      onUpdatePrompt(editedPrompt);
    },
    [
      currentPrompt,
      currentPromptDesc,
      currentPromptLabel,
      currentPromptQuestion,
      onUpdatePrompt,
    ]
  );

  useEffect(() => {
    if (
      currentPrompt?.questionType !== "SINGLE_SELECT" &&
      currentPrompt?.defaultAnswer &&
      visibleIndex === currentPrompt?.position
    ) {
      setVisibleIndex(1);
    }
  }, [
    currentPrompt?.defaultAnswer,
    currentPrompt?.position,
    currentPrompt?.questionType,
    setVisibleIndex,
    visibleIndex,
  ]);

  const formValues = useMemo(() => {
    const fieldsValue = form.getFieldsValue();

    return Object.entries(fieldsValue)
      .filter(([key, value]) => Boolean(value))
      .map(([key]) => key);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, template]);

  const goBack = useCallback(() => {
    if (visibleIndex <= 1) return;
    const prevPrompts = sortedPrompts.slice(0, visibleIndex - 1);
    const prevPromptsReversed = reverse([...prevPrompts]);

    const firstUnansweredPrompt = prevPromptsReversed.find((prompt) => {
      if (previewTemplate) return !prompt.defaultAnswer;
      return (
        !prompt.defaultAnswer && !formValues.includes(prompt.shortPromptName)
      );
    });
    const firstUnansweredPromptIndex = sortedPrompts.findIndex(
      (prompt) => prompt.promptID === firstUnansweredPrompt?.promptID
    );

    if (firstUnansweredPrompt && firstUnansweredPromptIndex !== -1) {
      setVisibleIndex(firstUnansweredPromptIndex + 2 - 1);
      questionTitleInputRef.current?.focus();
    }
  }, [
    formValues,
    previewTemplate,
    setVisibleIndex,
    sortedPrompts,
    visibleIndex,
  ]);

  const goForward = useCallback(() => {
    if (visibleIndex >= sortedPrompts?.length) return;
    const nextPrompts = sortedPrompts.slice(visibleIndex);
    const firstUnansweredPrompt = nextPrompts.find((prompt) => {
      if (previewTemplate) return !prompt.defaultAnswer;
      return (
        !prompt.defaultAnswer && !formValues.includes(prompt.shortPromptName)
      );
    });

    const firstUnansweredPromptIndex = sortedPrompts.findIndex(
      (prompt) => prompt.promptID === firstUnansweredPrompt?.promptID
    );

    if (firstUnansweredPrompt && firstUnansweredPrompt !== -1) {
      setVisibleIndex(firstUnansweredPromptIndex + 1);
      questionTitleInputRef.current?.focus();
    }
  }, [
    formValues,
    previewTemplate,
    setVisibleIndex,
    sortedPrompts,
    visibleIndex,
  ]);

  useEffect(() => {
    function checkIsInputFocused(tagName) {
      return ["INPUT", "TEXTAREA"].includes(tagName);
    }

    function handleKeyDown(event) {
      switch (event.key) {
        case "ArrowUp": {
          if (checkIsInputFocused(document.activeElement.tagName)) return;
          event.preventDefault();
          goBack();
          break;
        }
        case "ArrowDown": {
          if (checkIsInputFocused(document.activeElement.tagName)) return;
          event.preventDefault();
          goForward();
          break;
        }
        case "Enter": {
          if (!previewTemplate) break;

          event.preventDefault();
          goForward();
          break;
        }
        case "Escape": {
          if (!previewTemplate) break;

          event.preventDefault();
          setPreviewTemplate((prevState) => !prevState);
          break;
        }
        default: {
          break;
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [goBack, goForward, previewTemplate, visibleIndex]);

  return (
    <>
      <div
        ref={parentDivRef}
        style={{
          display: previewTemplate ? "none" : "flex",
        }}
      >
        <TemplateDataFieldContent
          readOnly
          template={template}
          currentPrompt={currentPrompt}
          setCurrentPrompt={setCurrentPrompt}
          goToCard={setVisibleIndex}
          variablesCategories={variablesCategories}
          prompts={sortedPrompts}
          form={form}
          reviewStep
          onUpdatePrompt={onUpdatePrompt}
          onUpdateVariable={onUpdateVariable}
          onDeleteVariable={onDeleteVariable}
          onCreateVariable={onCreateVariable}
          currentVariable={currentSelectedVariable}
          step={step}
        />
      </div>
      <div
        className={previewTemplate ? styles["c-wizard"] : styles["c-prompts"]}
        style={{
          display: "flex",
          alignItems: "start",
          marginTop: 36,
        }}
      >
        {previewTemplate && (
          <LeftSidebar
            open={openAsidePanel}
            onClose={() => setOpenAsidePanel(false)}
            prompts={sortedPrompts}
            setVisibleIndex={setVisibleIndex}
            visibleIndex={visibleIndex}
          />
        )}
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <PromptCard
            ref={questionTitleInputRef}
            {...{
              previewTemplate,
              currentPrompt,
              isInput,
              currentPromptQuestion,
              setCurrentPromptQuestion,
              onEditPrompt,
              setCurrentPromptDesc,
              currentPromptDesc,
              currentPromptLabel,
              setCurrentPromptLabel,
              documentLanguage,
              visibleIndex,
              onUpdateVariable,
              currentVariable,
              sortedPrompts,
              goForward,
            }}
          />
        </div>
        <KeysNavigations goBack={goBack} goForward={goForward} />
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "antd";

import { Icons, CButton, CInput, CSelect } from "../../../components";
import styles from "../index.module.scss";
import { Countries } from "../../../countries";

const ConfirmAddress = (props) => {
  const { onClose, data } = props;

  const [form] = Form.useForm();

  const [inputFormInFocus, setInputFormInFocus] = useState(1);
  const [tempAddr, setTempAddr] = useState({
    streetAddress: null,
    streetNumber: null,
    postalCode: null,
    city: null,
    country: null,
    additionalAddressLine: null,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13 || event.key === "Enter") {
        onFinish();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    setTempAddr({
      streetAddress: data?.streetAddress,
      streetNumber: data?.streetNumber,
      postalCode: data?.postalCode,
      city: data?.city,
      country: data?.country,
      additionalAddressLine: null,
    });

    form.setFieldsValue({
      countryRegion: !!data?.country ? data.country : undefined,
      streetName: !!data?.streetAddress
        ? `${data.streetAddress}${
            !!data.streetNumber ? ` ${data.streetNumber}` : ""
          }`
        : undefined,
      zipcode: !!data?.postalCode ? data.postalCode : undefined,
      city: !!data?.city ? data.city : undefined,
    });
  }, [data]);

  const onFinish = () => {
    const localData = {
      streetAddress: data?.streetAddress,
      streetNumber: data?.streetNumber,
      postalCode: data?.postalCode,
      city: data?.city,
      country: data?.country,
      additionalAddressLine: null,
    };

    onClose(!!tempAddr.country ? tempAddr : localData);
  };

  const onFieldsChange = (changedFields, allFields) => {};

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["has-from"]}`}>
        <div className={`${styles["u-modal__form__head"]} ${styles["fd-row"]}`}>
          <span>Confirm company address</span>
          {/* <span className={styles["has-help"]}>
            <Icons.QuestionCircleIcon />
            Help
          </span> */}
          <span
            onClick={() => onClose()}
            className={styles["u-modal__form__close"]}
          >
            <Icons.CloseIcon />
          </span>
        </div>

        <Form
          layout="vertical"
          id="confirm-company-address"
          name="confirm-company-address"
          form={form}
          onFieldsChange={onFieldsChange}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item name="countryRegion">
                <CSelect
                  label={"Country/Region"}
                  id="country-region"
                  name="country-region"
                  onChange={(value) => {
                    setTempAddr({ ...tempAddr, country: value });
                  }}
                  value={tempAddr.country}
                  options={Countries.map((item) => item.name)}
                  onClick={() => setInputFormInFocus(1)}
                  focusOn={inputFormInFocus === 1 ? true : false}
                />
              </Form.Item>
              <Form.Item name="streetName">
                <CInput
                  label={"Street name"}
                  id="street-name"
                  name="street-name"
                  placeholder={"e.g. Emser Platz 6"}
                  onChange={(event) => {
                    const parts = event.target.value.split(" ");
                    // console.log("parts: ", `${parts} '`);
                    if (!isNaN(+parts[parts.length - 1].trim())) {
                      let streetAddress = [...parts];
                      streetAddress.pop();

                      setTempAddr({
                        ...tempAddr,
                        streetNumber: parts[parts.length - 1].trim(),
                        streetAddress: streetAddress.join(" "),
                      });
                    } else {
                      setTempAddr({
                        ...tempAddr,
                        streetNumber: null,
                        streetAddress: parts.join(" "),
                      });
                    }
                  }}
                  focusOn={inputFormInFocus === 2 ? true : false}
                  onClick={() => setInputFormInFocus(2)}
                  value={`${tempAddr.streetAddress}${
                    !!tempAddr.streetNumber ? ` ${tempAddr.streetNumber}` : ""
                  }`}
                />
              </Form.Item>
              <Form.Item name="additionalAddressLine">
                <CInput
                  label={"Additional (optional)"}
                  placeholder={"e.g. Building A"}
                  id="additionalAddressLine"
                  name="additionalAddressLine"
                  onChange={(event) => {
                    setTempAddr({
                      ...tempAddr,
                      additionalAddressLine: event.target.value,
                    });
                  }}
                  focusOn={inputFormInFocus === 3 ? true : false}
                  onClick={() => setInputFormInFocus(3)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item name="city">
                <CInput
                  label={"City"}
                  id="city"
                  name="city"
                  onChange={(event) => {
                    setTempAddr({
                      ...tempAddr,
                      city: event.target.value,
                    });
                  }}
                  defaultValue={"Berlin"}
                  focusOn={inputFormInFocus === 4 ? true : false}
                  onClick={() => setInputFormInFocus(4)}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <Form.Item name="zipcode">
                <CInput
                  label={"Zipcode"}
                  id="zipcode"
                  name="zipcode"
                  defaultValue={"10719"}
                  onChange={(event) => {
                    setTempAddr({
                      ...tempAddr,
                      postalCode: event.target.value,
                    });
                  }}
                  focusOn={inputFormInFocus === 5 ? true : false}
                  onClick={() => setInputFormInFocus(5)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className={`${styles["u-modal__form__foot"]} ${styles["tal"]}`}>
          <div className={styles["u-modal__form__foot__item"]}>
            <CButton
              onClick={onFinish}
              type="primary"
              title={"Save & continue"}
              customStyles={styles["btn-form"]}
            />
            <div className={styles["or-with-enter"]}>or with ENTER</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAddress;

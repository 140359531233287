import React from "react";
import PropTypes from "prop-types";

const Enter = ({ color = "#8C8C97", className, size = "24" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.62603 0.230499H6.95819C6.90921 0.230499 6.86914 0.27057 6.86914 0.319546V3.13743H2.62747V1.81921C2.62747 1.74464 2.54065 1.70234 2.48277 1.74909L0.903306 3.50141C0.892663 3.50974 0.884056 3.52039 0.878137 3.53254C0.872217 3.54469 0.869141 3.55802 0.869141 3.57154C0.869141 3.58505 0.872217 3.59839 0.878137 3.61054C0.884056 3.62269 0.892663 3.63333 0.903306 3.64166L2.48277 5.49991C2.54176 5.54666 2.62747 5.50436 2.62747 5.42978V3.98338H7.00271C7.39563 3.98338 7.71508 3.66392 7.71508 3.27101V0.319546C7.71508 0.27057 7.67501 0.230499 7.62603 0.230499Z"
        fill={color}
      />
    </svg>
  );
};

Enter.propTypes = {
  color: PropTypes.string,
};

export default Enter;

import React from "react";
import PropTypes from "prop-types";

const AuditIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M5.21488 4.97712C5.09703 4.97712 5.0006 5.07355 5.0006 5.19141V6.47712C5.0006 6.59498 5.09703 6.69141 5.21488 6.69141H15.5006C15.6185 6.69141 15.7149 6.59498 15.7149 6.47712V5.19141C15.7149 5.07355 15.6185 4.97712 15.5006 4.97712H5.21488ZM10.1435 8.83426H5.21488C5.09703 8.83426 5.0006 8.93069 5.0006 9.04855V10.3343C5.0006 10.4521 5.09703 10.5485 5.21488 10.5485H10.1435C10.2613 10.5485 10.3577 10.4521 10.3577 10.3343V9.04855C10.3577 8.93069 10.2613 8.83426 10.1435 8.83426ZM8.85774 21.1021H2.85774V2.24498H17.8577V10.8164C17.8577 10.9343 17.9542 11.0307 18.072 11.0307H19.572C19.6899 11.0307 19.7863 10.9343 19.7863 10.8164V1.17355C19.7863 0.699442 19.4033 0.316406 18.9292 0.316406H1.78631C1.3122 0.316406 0.929169 0.699442 0.929169 1.17355V22.1736C0.929169 22.6477 1.3122 23.0307 1.78631 23.0307H8.85774C8.9756 23.0307 9.07203 22.9343 9.07203 22.8164V21.3164C9.07203 21.1986 8.9756 21.1021 8.85774 21.1021ZM20.6435 18.745H16.7863V17.7646C18.0265 17.395 18.9292 16.2486 18.9292 14.8878C18.9292 13.2298 17.5872 11.8878 15.9292 11.8878C14.2711 11.8878 12.9292 13.2298 12.9292 14.8878C12.9292 16.2459 13.8318 17.395 15.072 17.7646V18.745H11.2149C10.9792 18.745 10.7863 18.9378 10.7863 19.1735V23.245C10.7863 23.4807 10.9792 23.6736 11.2149 23.6736H20.6435C20.8792 23.6736 21.072 23.4807 21.072 23.245V19.1735C21.072 18.9378 20.8792 18.745 20.6435 18.745ZM14.5899 14.8878C14.5899 14.1486 15.1899 13.5486 15.9292 13.5486C16.6685 13.5486 17.2685 14.1486 17.2685 14.8878C17.2685 15.6271 16.6685 16.2271 15.9292 16.2271C15.1899 16.2271 14.5899 15.6271 14.5899 14.8878ZM19.4113 22.0128H12.447V20.4057H19.4113V22.0128Z"
      />
    </svg>
  );
};

AuditIcon.propTypes = {
  color: PropTypes.string,
};

export default AuditIcon;

import { Button, Drawer, Space, Typography, notification } from "antd";
import React, { useState } from "react";
import { TeamOutlined, LinkOutlined, LeftOutlined } from "@ant-design/icons";
import { Icons } from "../../components";
import { useTranslation } from "react-i18next";
import { ShareContent } from "./components/shareContent";
import { DataFieldContent } from "../../components/ReviewInput/ContractRepository";
import { SHARE_TYPE } from "../../pages/ReviewInput";
import "./index.scss";

const { Text, Title } = Typography;

export const MobileShare = ({
  children = <div></div>,
  mobileMenu,
  onClose,
  createPDF,
  documentStyles,
  thisDocument,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  user,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
  canShare,
  currentShareUser,
  uploadedContract,
  updateDocumentData,
  getDocument,
  childrenDrawer,
  showChildrenDrawer,
  onChildrenDrawerClose,
  handleCopyLink,
  canSwitchEditor,
  isEditor,
  onSwitchEditor
}) => {
  const { t } = useTranslation();

  const buttonData = [
    {
      title: isEditor ? "Switch back to comments" : "Switch to full editing",
      icon: isEditor ? (
        <Icons.Comments className={"button-icon"} />
        ) : (
        <Icons.EditFile className={"button-icon"} />
      ),
      onClick: () => {
        onSwitchEditor(!isEditor);
        onClose();
      },
      hidden: !canSwitchEditor,
    },
    {
      title: t("share:tab:invite"),
      icon: <TeamOutlined className={"button-icon"} />,
      onClick: () => {
        showChildrenDrawer(SHARE_TYPE.SHARE);
        onClose();
      },
      hidden: !canShare,
    },
    {
      title: t("share:link"),
      icon: <LinkOutlined className={"button-icon"} />,
      onClick: () => {
        handleCopyLink();
        onClose();
      },
    },
    {
      title: t("share:tab:pdf"),
      icon: <Icons.PdfIcon className={"button-icon"} />,
      onClick: () => {
        createPDF();
        onClose();
      },
      hidden: uploadedContract
        ? !uploadedContract && !currentShareUser
        : thisDocument?.status !== "COMPLETED" || !currentShareUser,
    },
  ].filter((button) => !button.hidden);

  return (
    <>
      <Drawer
        title={thisDocument?.fileTitle}
        placement={"bottom"}
        onClose={onClose}
        open={mobileMenu}
        contentWrapperStyle={{
          borderRadius: "12px",
          padding: "8px",
          height: "auto",
        }}
        bodyStyle={{ padding: "6px" }}
        className="share-drawer"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {buttonData.map((button, index) => (
            <Button
              key={index}
              type="text"
              className={"drawer-share-button"}
              onClick={button.onClick}
            >
              {button.icon}
              <Text style={{ margin: 0 }}>{button.title}</Text>
            </Button>
          ))}
        </div>
      </Drawer>
      <ChilDrawerComponent
        canShare={canShare}
        childrenDrawer={childrenDrawer}
        currentShareUser={currentShareUser}
        deleteSharedUser={deleteSharedUser}
        documentStyles={documentStyles}
        editSharedUser={editSharedUser}
        getDocument={getDocument}
        invitedUsers={invitedUsers}
        onChildrenDrawerClose={onChildrenDrawerClose}
        setInvitedUsers={setInvitedUsers}
        shareOptions={shareOptions}
        shareUser={shareUser}
        thisDocument={thisDocument}
        updateDocumentData={updateDocumentData}
        uploadedContract={uploadedContract}
        user={user}
      />
      {children}
    </>
  );
};

const ChilDrawerComponent = ({
  thisDocument,
  uploadedContract,
  childrenDrawer,
  onChildrenDrawerClose,
  updateDocumentData,
  currentShareUser,
  user,
  getDocument,
  canShare,
  documentStyles,
  shareUser,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
  deleteSharedUser,
  editSharedUser,
}) => {
  const [focused, setFocused] = useState(1);

  return (
    <Drawer
      width={"100vw"}
      onClose={onChildrenDrawerClose}
      open={childrenDrawer}
      bodyStyle={{ background: "#FAFAFC", padding: 0 }}
      closeIcon={<LeftOutlined className={"button-icon"} />}
      headerStyle={{ display: uploadedContract ? "none" : "flex" }}
    >
      {uploadedContract ? (
        <DataFieldContent
          thisDocument={thisDocument}
          updateDocumentData={updateDocumentData}
          currentShareUser={currentShareUser}
          onClose={onChildrenDrawerClose}
          user={user}
          getDocument={getDocument}
          canShare={canShare}
        />
      ) : childrenDrawer === SHARE_TYPE.COMMENT ? (
        <Space
          direction="vertical"
          style={{ padding: "18px 16px", width: "100%" }}
        >
          <Title level={2}>Comments</Title>
        </Space>
      ) : childrenDrawer === SHARE_TYPE.SHARE ? (
        <Space
          direction="vertical"
          style={{ padding: "18px 16px", width: "100%" }}
        >
          <Title level={2}>Invite others</Title>
          <ShareContent
            setFocused={setFocused}
            focused={focused}
            documentStyles={documentStyles}
            thisDocument={thisDocument}
            shareUser={shareUser}
            editSharedUser={editSharedUser}
            deleteSharedUser={deleteSharedUser}
            user={user}
            shareOptions={shareOptions}
            invitedUsers={invitedUsers}
            setInvitedUsers={setInvitedUsers}
          />
        </Space>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

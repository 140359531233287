import React from "react";
import { Button, Drawer } from "antd";
import styles from "../styles.module.scss";
import Icons from "../../Icons";

const ActionsDrawer = ({ onClose, isOpen, children }) => {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      placement="bottom"
      closable={false}
      title={<span className={styles.drawerTitle}>More</span>}
      height="auto"
      style={{
        zIndex: 1001,
      }}
      contentWrapperStyle={{
        margin: 8,
        borderRadius: 8,
        overflow: "hidden",
        background: "#FFFFFF",
        border: "1px solid #F3F3F6",
        boxShadow:
          "0px 4px 6px rgba(0, 0, 0, 0.04), 0px 6px 12px rgba(0, 0, 0, 0.08)",
      }}
      headerStyle={{
        padding: "12px 10px",
        borderBottom: "1px solid #ECEDFF",
      }}
      bodyStyle={{
        padding: 0,
      }}
      extra={
        <Button
          type="text"
          icon={<Icons.CloseIcon size={14} color="#8C8C97" />}
          onClick={onClose}
        />
      }
    >
      {children}
    </Drawer>
  );
};

export default ActionsDrawer;

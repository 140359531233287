import React from "react";
import PropTypes from "prop-types";

const EyeIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0822 11.8953C19.8603 7.21484 16.5017 4.85938 11.9994 4.85938C7.49472 4.85938 4.13847 7.21484 1.91659 11.8977C1.82747 12.0864 1.78125 12.2925 1.78125 12.5012C1.78125 12.7099 1.82747 12.916 1.91659 13.1047C4.13847 17.7852 7.49706 20.1406 11.9994 20.1406C16.5041 20.1406 19.8603 17.7852 22.0822 13.1023C22.2627 12.7227 22.2627 12.282 22.0822 11.8953ZM11.9994 18.4531C8.21893 18.4531 5.45097 16.5359 3.49862 12.5C5.45097 8.46406 8.21893 6.54688 11.9994 6.54688C15.7799 6.54688 18.5478 8.46406 20.5002 12.5C18.5502 16.5359 15.7822 18.4531 11.9994 18.4531Z"
        fill={color}
      />
      <path
        d="M11.9062 8.375C9.62813 8.375 7.78125 10.2219 7.78125 12.5C7.78125 14.7781 9.62813 16.625 11.9062 16.625C14.1844 16.625 16.0312 14.7781 16.0312 12.5C16.0312 10.2219 14.1844 8.375 11.9062 8.375ZM11.9062 15.125C10.4555 15.125 9.28125 13.9508 9.28125 12.5C9.28125 11.0492 10.4555 9.875 11.9062 9.875C13.357 9.875 14.5312 11.0492 14.5312 12.5C14.5312 13.9508 13.357 15.125 11.9062 15.125Z"
        fill={color}
      />
    </svg>
  );
};

EyeIcon.propTypes = {
  color: PropTypes.string,
};

export default EyeIcon;

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "antd";

import { useTranslation } from "react-i18next";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import CAutoComplete from "../../AutoComplete";
import { CInput, CCheckbox } from "../../../components";

import { GOOGLE_PLACES_API_KEY } from "../../../constants/config";

const String = ({
  groupId = "",
  placeholder,
  label,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  type,
  checkbox,
  setAddressBeforeConfirm,
  addressBeforeConfirm,
  form,
  visibleIndex,
  position,
  cardData,
  prompts,
  isCheckboxChecked,
  setDefaultCardData,
  documentStyles,
  documentLanguage,
  onNextCardGoPress,
  isAllDataValid,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (visibleIndex === position && isAllDataValid && selected) {
      onNextCardGoPress({ toNext: true });
      setSelected(false);
    }
  }, [selected, isAllDataValid]);

  useEffect(() => {
    if (form && visibleIndex === position) {
      form.setFieldsValue({
        [`${groupId}###${
          type === "STRING"
            ? "string"
            : type === "ADDRESS_AUTO_COMPLETE"
            ? "addressAutoComplete"
            : "number"
        }`]: "",
      });
    }
  }, [visibleIndex, form, position]);

  useEffect(() => {
    if (cardData) {
      form.setFieldsValue({
        [`${cardData?.[0]?.name}`]: cardData?.[0]?.value,
      });
    }
  }, [cardData, prompts]);

  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    apiKey: GOOGLE_PLACES_API_KEY,
    language: documentLanguage === "EN" ? "en-GB" : "de",
    options: { language: documentLanguage === "EN" ? "en-GB" : "de" },
  });

  const convertAddressOptions = (oldOptions) => {
    return oldOptions.map(({ description, place_id }) => ({
      value: description,
      label: description,
      id: place_id,
    }));
  };

  const getPlaceData = async (placeId) => {
    placesService.getDetails(
      { placeId: placeId, fields: ["address_components"] },
      (result) => {
        let tempAddr = {
          streetAddress: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: null,
        };

        result.address_components.map((elem) => {
          if (
            elem.types.some((elemItem) =>
              ["route", "natural_feature", "establishment"].includes(elemItem)
            )
          ) {
            tempAddr.streetAddress = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "street_number")) {
            tempAddr.streetNumber = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "postal_code")) {
            tempAddr.postalCode = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "locality")) {
            tempAddr.city = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "country")) {
            tempAddr.country = elem.long_name;
          }
        });

        setAddressBeforeConfirm(tempAddr);
      }
    );
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" span={24}>
        <Form.Item
          name={`${groupId}###${
            type === "STRING"
              ? "string"
              : type === "ADDRESS_AUTO_COMPLETE"
              ? "addressAutoComplete"
              : "number"
          }`}
        >
          {type === "ADDRESS_AUTO_COMPLETE" ? (
            <CAutoComplete
              label={label ? label : "missed"}
              options={convertAddressOptions(placePredictions)}
              placeholder={placeholder.address.singleLineAddress}
              onSearch={(value) => {
                getPlacePredictions({ input: value });
              }}
              onSelect={(value, id) => {
                getPlaceData(id);
                setSelected(true);
              }}
              onChange={(value) => {
                if (value === "") {
                  setAddressBeforeConfirm(null);
                }
              }}
              onClick={() => setNumberOfInputForFocus(0)}
              focusOn={numberOfInputForFocus === 0}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          ) : (
            <CInput
              label={label ? label : ""}
              placeholder={
                placeholder?.string ||
                (placeholder?.number && `${placeholder?.number}`) ||
                ""
              }
              focusOn={numberOfInputForFocus === 0}
              onChange={() => {
                setNumberOfInputForFocus(0);
              }}
              onClick={() => {
                setNumberOfInputForFocus(0);
              }}
              type={type === "NUMBER" ? "number" : ""}
              font={documentStyles?.font}
              color={documentStyles?.color}
              inputmode={type === "NUMBER" ? "decimal" : undefined}
              pattern={type === "NUMBER" ? "d*" : undefined}
            />
          )}
        </Form.Item>
      </Col>
      {checkbox && (
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###checkbox`}>
            <CCheckbox
              type={"checkbox"}
              title={t("wizard:buttons.invite_recipient")}
              checked={isCheckboxChecked}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default String;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Divider } from "antd";
import Icons from "../../Icons";
import { partition } from "lodash";
import styles from "../styles.module.scss";
import { useDisclosure } from "../../../hooks/helpers/useDisclosure";
import Comment from "./Comment";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector } from "react-redux";
import { SuggestionStatus, SuggestionType } from "../constants";
import { api } from "../../../services/api";
import { documentSelector } from "../../../store/selectors/prompts";
import useMediaQuery from "../../../hooks/helpers/useMediaQuery";
import { useComments } from "../../../contexts/CommentContext";
import { useDocumentPermission } from "../../../hooks/permissions/document-permissions";
import { isApple } from "../../../utils/platform";

const CommentsAndEdits = ({ isOpen, onClose, onOpen }) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const thisDocument = useSelector(documentSelector);
  const comments = useComments();
  const { user } = useDocumentPermission();

  const { documentID } = thisDocument;

  const [newComment, setNewComment] = useState("");

  const { isOpen: isResolvedOpen, onToggle: onResolvedToggle } =
    useDisclosure();

  useEffect(() => {
    if (comments?.activeCommentInstance?.commentID) {
      onOpen();
    }
  }, [comments?.activeCommentInstance?.commentID])

  const handleAddComment = useCallback(async () => {
    if (!newComment.length) return;

    api.post('comments', {
      documentID: thisDocument.documentID,
      message: newComment,
    }, {
      params: {
        userID: user?.userID
      }
    }).then(res => {
      comments.add({
        ...res.data,
        independent: true
      });
      setNewComment("");
    })
  }, [documentID, newComment]);

  const resolvedComments = useMemo(() => {
    return comments.commentList.filter((comment) => comment.resolved);
  }, [comments.commentList]);

  const unresolvedComments = useMemo(() => {
    return comments.commentList.filter((comment) => !comment.resolved);
  }, [comments.commentList]);

  function onKeyboardEvent(event) {
    const enter = event.code === 'Enter';

    if (enter && event.altKey) {
      handleAddComment();
      return;
    }

    // if (enter && event.altKey) {
    //   setNewComment(old => old + '\n');
    //   return;
    // }
  }

  const isDocumentComplete = useMemo(() => {
    return thisDocument.status === "COMPLETED";
  }, [thisDocument])
  
  if (!isOpen) return null;

  return (
    <>
      <section className={styles.container}>
        <div className={styles.header}>
          <p className={styles.header__title}>Comments</p>
          <Button
            type="text"
            icon={<Icons.CloseIcon size={12} color="#8C8C97" />}
            className={styles.header__button}
            onClick={onClose}
          />
        </div>

        <div className={styles.commentInputContainer}>
          <TextareaAutosize
            placeholder="Add a comment…"
            rows={1}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className={styles.comment__baseInput}
            onKeyDown={onKeyboardEvent}
            disabled={isDocumentComplete}
          />
          {!!newComment.length && (
            <div className={styles.comment__cancelSaveButtonsContainer}>
              <div className={styles.comment__tip}>
                {isApple() ? '⌥ + Return to post' : 'Alt + Enter to post'}
              </div>
              <div className={styles.comment__cancelSaveButtonsGroup}>
                <Button
                  type="text"
                  onClick={() => setNewComment("")}
                  className={styles.comment__cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className={styles.comment__submitButton}
                  onClick={handleAddComment}
                >
                  Post
                </Button>
              </div>
            </div>
          )}

        </div>
        {!isMobile && <CustomDivider />}
        {/* {!!unresolvedComments.length && ( */}
          <div className={styles.commentsContainer}>
            <CommentsList
              activeCommentID={comments?.activeCommentInstance?.commentID}
              comments={unresolvedComments}
              isDocumentComplete
            />
          </div>
        {/* )} */}
      </section>

      {!!resolvedComments.length && (
        <>
          <Button
            onClick={onResolvedToggle}
            className={styles.toggleResolvedButton}
          >
            {isResolvedOpen
              ? "Hide resolved comments"
              : `Show ${resolvedComments.length} resolved`}
          </Button>

          {isResolvedOpen && (
            <section
              className={classNames(styles.container, styles.resolvedContainer)}
            >
              <div className={styles.commentsContainer}>
                {resolvedComments.map((comment) => (
                  <Comment
                    key={comment.commentID}
                    {...{ comment }}
                  />
                ))}
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export function CommentsList({ comments = [], activeCommentID }) {
  const [activeEditId, setActiveEditId] = React.useState("");
  const [isActiveEditAReply, setIsActiveEditAReply] = React.useState(false);

  const handleSetActiveEditId = React.useCallback((value, isReply = false) => {
    setActiveEditId(value);
    setIsActiveEditAReply(isReply);
  }, [])
  
  const activeEdit = React.useMemo(() => {
    return {
      id: activeEditId,
      isReply: isActiveEditAReply
    }
  }, [isActiveEditAReply, activeEditId])

  return comments.map((comment, idx) => (
    <React.Fragment
      key={comment.commentID}
    >
      <Comment
        comment={comment}
        active={activeCommentID === comment.commentID}
        activeEdit={activeEdit}
        setActiveEdit={handleSetActiveEditId}
      />
      {comments.length !== idx + 1 && (
        <CustomDivider/>
      )}
    </React.Fragment>
  ))
}

export default CommentsAndEdits;

const CustomDivider = () => <Divider className={styles.divider} />;

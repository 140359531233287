import React from "react";
import PropTypes from "prop-types";

const DashboardIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.214 0.574142H0.785453C0.390363 0.574142 0.071167 0.893339 0.071167 1.28843V17.717C0.071167 18.1121 0.390363 18.4313 0.785453 18.4313H17.214C17.6091 18.4313 17.9283 18.1121 17.9283 17.717V1.28843C17.9283 0.893339 17.6091 0.574142 17.214 0.574142ZM1.67831 2.18129H4.71402V16.8241H1.67831V2.18129ZM16.3212 16.8241H6.1426V6.64557H16.3212V16.8241ZM6.1426 5.217V2.18129H16.3212V5.217H6.1426Z"
        fill={color}
      />
    </svg>
  );
};

DashboardIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default DashboardIcon;

import React from "react";
import PropTypes from "prop-types";

const PlusCreatorIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.464 0.430054H10.5355C10.364 0.430054 10.2783 0.515768 10.2783 0.687197V10.7801H0.700502C0.529074 10.7801 0.443359 10.8658 0.443359 11.0372V12.9658C0.443359 13.1372 0.529074 13.2229 0.700502 13.2229H10.2783V23.3158C10.2783 23.4872 10.364 23.5729 10.5355 23.5729H12.464C12.6355 23.5729 12.7212 23.4872 12.7212 23.3158V13.2229H22.3005C22.4719 13.2229 22.5576 13.1372 22.5576 12.9658V11.0372C22.5576 10.8658 22.4719 10.7801 22.3005 10.7801H12.7212V0.687197C12.7212 0.515768 12.6355 0.430054 12.464 0.430054Z"
        fill={color}
      />
    </svg>
  );
};

PlusCreatorIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default PlusCreatorIcon;

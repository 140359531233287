import {
  $isElementNode,
  ElementNode,
  createCommand,
} from 'lexical'
import { addClassNamesToElement } from '@lexical/utils'

// const selectElementText = (el) => {
//   const range = document.createRange()
//   range.selectNode(el)

//   const sel = window.getSelection()
//   sel?.removeAllRanges()
//   sel?.addRange(range)
// }

const UPDATE_COMMENT_COMMAND = createCommand();

const FREE_UPDATE_COMMENT_COMMAND = createCommand();

const SET_COMMENT_COMMAND = createCommand();

const DELETE_COMMENT_COMMAND = createCommand();

class CommentNode extends ElementNode {
  __commentInstance;

  static getType() {
    return 'comment';
  }

  static clone(node) {
    return new CommentNode(node.__commentInstance, node.__key);
  }

  constructor(commentInstance, key) {
    super(key);
    this.__commentInstance = commentInstance;
  }

  createDOM(config) {
    const element = document.createElement('span');

    element.setAttribute('data-comment-instance', JSON.stringify(this.__commentInstance))

    // element.addEventListener('click', (e) => e.target && selectElementText(e.target))

    addClassNamesToElement(element, config.theme.comment);

    return element;
  }

  exportDOM() {
    const element = document.createElement('span');

    // element.setAttribute('data-comment-instance', JSON.stringify(this.__commentInstance))

    // element.addEventListener('click', (e) => e.target && selectElementText(e.target))

    // addClassNamesToElement(element, config.theme.comment);

    return {element};
  }

  updateDOM(prevNode, dom, config) {
    const commentSpan = dom;

    const [prevInstance, currentInstance] = [JSON.stringify(prevNode.__commentInstance), JSON.stringify(this.__commentInstance)]

    if (prevInstance !== currentInstance) commentSpan.setAttribute('data-comment-instance', currentInstance)

    return false;
  }
  
  static importJSON(serializedNode) {
    const node = $createCommentNode(serializedNode.instance);
    // node.setFormat(serializedNode.format);
    // node.setDetail(serializedNode.detail);
    // node.setMode(serializedNode.mode);
    // node.setStyle(serializedNode.style);
    // node.setAttribute('data-comment-instance', JSON.stringify(serializedNode.instance))
    return node;
  }
  
  exportJSON() {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      instance: this.__commentInstance
    };
  } 

  static importDOM() {
    return {
      span: (node) => ({
        conversion: convertCommentSpan,
        priority: 1,
      }),
    };
  }

  getComment() {
    const self = this.getLatest();
    return (self).__commentInstance
  }

  setComment(commentInstance) {
    const writable = this.getWritable();
    (writable).__commentInstance = commentInstance;
  }

  insertNewAfter(selection) {
    const element = this.getParentOrThrow().insertNewAfter(selection);

    if ($isElementNode(element)) {
      const commentNode = $createCommentNode(this.__commentInstance);

      (element)?.append(commentNode);

      return commentNode;
    }

    return null;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  canBeEmpty() {
    return false;
  }

  isInline() {
    return true;
  }
}

class NoHighlightCommentNode extends ElementNode {
  __commentInstance;

  static getType() {
    return 'comment';
  }

  static clone(node) {
    return new NoHighlightCommentNode(node.__commentInstance, node.__key);
  }

  constructor(commentInstance, key) {
    super(key);
    this.__commentInstance = commentInstance;
  }

  createDOM(config) {
    const element = document.createElement('span');

    element.setAttribute('data-comment-instance', JSON.stringify(this.__commentInstance))

    // element.addEventListener('click', (e) => e.target && selectElementText(e.target))

    // addClassNamesToElement(element, config.theme.comment);

    return element;
  }

  updateDOM(prevNode, dom, config) {
    const commentSpan = dom;

    const [prevInstance, currentInstance] = [JSON.stringify(prevNode.__commentInstance), JSON.stringify(this.__commentInstance)]

    if (prevInstance !== currentInstance) commentSpan.setAttribute('data-comment-instance', currentInstance)

    return false;
  }
  
  static importJSON(serializedNode) {
    const node = $createNoHighlightCommentNode(serializedNode.instance);
    // node.setFormat(serializedNode.format);
    // node.setDetail(serializedNode.detail);
    // node.setMode(serializedNode.mode);
    // node.setStyle(serializedNode.style);
    // node.setAttribute('data-comment-instance', JSON.stringify(serializedNode.instance))
    return node;
  }
  
  exportJSON() {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      instance: this.__commentInstance
    };
  } 

  static importDOM() {
    return {
      span: (node) => ({
        conversion: convertCommentSpan,
        priority: 1,
      }),
    };
  }

  getComment() {
    const self = this.getLatest();
    return (self).__commentInstance
  }

  setComment(commentInstance) {
    const writable = this.getWritable();
    (writable).__commentInstance = commentInstance;
  }

  insertNewAfter(selection) {
    const element = this.getParentOrThrow().insertNewAfter(selection);

    if ($isElementNode(element)) {
      const commentNode = $createNoHighlightCommentNode(this.__commentInstance);

      (element)?.append(commentNode);

      return commentNode;
    }

    return null;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }

  canBeEmpty() {
    return false;
  }

  isInline() {
    return true;
  }
}

function convertCommentSpan(domNode) {
  let node = null;

  if (domNode instanceof HTMLSpanElement) {
    const commentInstance = domNode.getAttribute('data-comment-instance')

    if (commentInstance) {
      const jsonCommentInstance = JSON.parse(commentInstance)

      node = $createCommentNode(jsonCommentInstance);
    }
  }

  return { node };
}

function $createCommentNode(commentInstance) {
  return new CommentNode(commentInstance);
}

function $isCommentNode(node) {
  return node instanceof CommentNode;
}
function $createNoHighlightCommentNode(commentInstance) {
  return new NoHighlightCommentNode(commentInstance);
}

function $isNoHighlightCommentNode(node) {
  return node instanceof NoHighlightCommentNode;
}


export {
  CommentNode,
  NoHighlightCommentNode,
  $createCommentNode,
  $createNoHighlightCommentNode,
  $isCommentNode,
  $isNoHighlightCommentNode,

  SET_COMMENT_COMMAND,
  UPDATE_COMMENT_COMMAND,
  FREE_UPDATE_COMMENT_COMMAND,
  DELETE_COMMENT_COMMAND,
}
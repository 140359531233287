import React from "react";
import { useTranslation } from "react-i18next";
import { CButton, Icons } from "..";
import { sendMailByApp } from "../../utils/helper";

import styles from "./index.module.scss";

const HowCanWeHelp = ({ color, font }) => {
  const { t } = useTranslation();
  const data = [
    {
      title: t("wizard:right_panel.can_help.item_question"),
      icon: <Icons.FileUnknownIcon />,
    },
    {
      title: t("wizard:right_panel.can_help.item_problem"),
      icon: <Icons.WarningIcon />,
    },
    {
      title: t("wizard:right_panel.can_help.item_topic"),
      icon: <Icons.QuestionCircleIcon />,
    },
  ];

  return (
    <div className={styles["hcwh-wrap"]}>
      <div className={styles["hcwh-wrap-header"]}>
        <Icons.QuestionIcon
          className={styles["hcwh-wrap-header__question-icon"]}
          color={color}
        />
        <h1
          className={styles["hcwh-wrap-header__title"]}
          style={{ fontFamily: font }}
        >
          {t("wizard:right_panel.can_help.title")}
        </h1>
      </div>
      <div className={styles["hcwh-wrap-content"]}>
        {data?.map((item, index) => {
          return (
            <div key={index} className={styles["hcwh-wrap-content__item"]}>
              <CButton
                icon={item.icon}
                title={item.title}
                type={"icon-text"}
                onClick={() => sendMailByApp(item.title)}
                font={font}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowCanWeHelp;

import { PRODUCTION_SUBDOMAIN } from "../constants/config";
import { getSubdomain } from "./getSubdomain"

export function isProduction() {
  const url = window.location.host;

  const subdomain = getSubdomain(url);

  if (subdomain === PRODUCTION_SUBDOMAIN) return true;

  return false;
};
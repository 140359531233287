import React, { useState, useEffect, createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import html2pdf from "html2pdf.js";
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";

import {
  Layout,
  Header,
  RightPanel,
  HowCanWeHelp,
  CButton,
  Icons,
} from "../../components";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

// const noop = () => {};

const DocumentStatus = () => {
  const { t } = useTranslation();
  const elementRef = createRef();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [messages, setMessages] = useState(false);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  // const createPDF = async () => {
  //   const pdf = new jsPDF("portrait", "pt", "a4");
  //   const data = await document.querySelector("#pdf");
  //   pdf.html(data).then(() => {
  //     pdf.save("shipping_label.pdf");
  //   });
  // };

  // const createPDF = async () => {
  //   // const pdf = new jsPDF("portrait", "pt", "a4");
  //   // const data = await html2canvas(document.querySelector("#pdf"));
  //   // const img = data.toDataURL("image/png");
  //   // const imgProperties = pdf.getImageProperties(img);
  //   // const pdfWidth = pdf.internal.pageSize.getWidth();
  //   // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  //   // pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   // pdf.save("shipping_label.pdf");
  //   const divToPrint = document.querySelector('#pdf');
  //   html2canvas(divToPrint).then(canvas => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const imgWidth = 190;
  //     const pageHeight = 290;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     const doc = new jsPDF('pt', 'mm');
  //     let position = 0;
  //     doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
  //     heightLeft -= pageHeight;
  //     while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         doc.addPage();
  //         doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
  //         heightLeft -= pageHeight;
  //     }
  //     doc.save('download.pdf');
  //     // setLoader(false);
  // });
  // };

  // const savePDF = () => {
  //   // See https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
  //   const doc = new jsPDF({
  //     orientation: "p",
  //     unit: "px",
  //     format: "letter",
  //   });
  //   // HTML element to render, using React's API for accessing DOM elements:
  //   // https://reactjs.org/docs/refs-and-the-dom.html
  //   const element = elementRef.current;
  //   const margins = {
  //     left: 40,
  //     top: 40,
  //     bottom: 40,
  //   };
  //   const options = {
  //     width: 522,
  //   };
  //   // See example usage from repo: https://github.com/MrRio/jsPDF/blob/b9f932cd2e22c82db0a39f0225521945a2568809/examples/basic.html#L357
  //   // DOM element, x pos to render on page, y pos to render on page, callback, options

  //   // doc.html(element, margins.left, margins.top, options, noop, margins);
  //   // doc.save("hmw.pdf");
  //   doc.html(element, {
  //     callback: function (doc) {
  //       doc.save("hmw.pdf");
  //     },
  //     margin: [0, 0, 0, 0],
  //     width: 522,
  //  });
  // };

  const createPDF = () => {
    // Get the element.
    var element = document.getElementById("pdf");
    // Choose pagebreak options based on mode.
    // var mode = document.getElementById('mode').value;
    // var pagebreak = (mode === 'specify') ?
    //     { mode: '', before: '.before', after: '.after', avoid: '.avoid' } :
    // { mode: mode };
    // Generate the PDF.
    html2pdf()
      .from(element)
      .set({
        filename: "1.pdf",
        pagebreak: {
          mode: "avoid-all",
          before: `${styles["before"]}`,
          after: ".after",
          avoid: ".avoid",
        },
        jsPDF: {
          orientation: "portrait",
          unit: "in",
          format: "letter",
          compressPDF: true,
        },
      })
      .save();
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1023) {
        setMessages(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   if (thisDocument?.language) {
  //     i18n.changeLanguage(thisDocument.language?.toLowerCase());
  //   }
  // }, [thisDocument?.language]);

  return (
    <Layout
      header={
        <Header
          title={`License Agreement for Communication Platform`}
          shortTitle={`License Agreement for Communication Platform`}
          leftType="arrowLeftFolders"
          right={
            <div className={styles["document-nav-bar"]}>
              <CButton
                onClick={() => createPDF()}
                icon={<Icons.PdfIcon />}
                type="primary"
                title={"Export as PDF"}
                disabled={false}
                customStyles={styles["btn-export-as-pdf"]}
              />

              <div
                onClick={() => setMessages(true)}
                className={styles["btn-messages"]}
              >
                <Icons.ContactIcon />
              </div>
            </div>
          }
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-document-status"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-document-status__section"]}>
          <div className={styles["c-document-status__over"]}>
            <div
              ref={elementRef}
              id="pdf"
              className={styles["c-document-status__article"]}
            >
              <div className={styles["c-document-status__prop"]}>
                <div className={styles["c-document-status__head"]}>
                  <div className={styles["c-document-status__head__logo"]}>
                    <img src={null} alt={""} />
                  </div>
                  <div className={styles["c-document-status__head__title"]}>
                    License Agreement for Communication Platform
                  </div>
                  <div className={styles["c-document-status__head__date"]}>
                    12. December 2021
                  </div>
                </div>

                <div className={styles["c-document-status__item"]}>
                  <div className={styles["teritary__head"]}>
                    <div className={styles["teritary__head__icon"]}>
                      <Icons.UserIcon />
                    </div>
                    <div className={styles["teritary__head__title"]}>
                      {t("contract:parties")}
                    </div>
                    <div
                      data-html2canvas-ignore="true"
                      className={styles["has-help"]}
                    >
                      <Icons.QuestionCircleIcon />
                      {t("wizard:info.help")}
                    </div>
                  </div>

                  <div className={styles["teritary__row"]}>
                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.OfficeIcon />
                          </div>
                          <div className={styles["title"]}>Partei</div>
                        </div>
                        <div className={styles["teritary__card__body"]}>
                          <ul>
                            <li>
                              Name
                              <strong>Myosotis GmbH </strong>
                            </li>
                            <li>
                              Address
                              <strong>
                                Swinemünder Straße 110, 10435 Berlin, Germany{" "}
                              </strong>
                            </li>
                            <li>
                              Type
                              <strong>Company</strong>
                            </li>
                            <li>
                              Position
                              <strong>Seller</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.OfficeIcon />
                          </div>
                          <div className={styles["title"]}>Partei</div>
                        </div>
                        <div className={styles["teritary__card__body"]}>
                          <ul>
                            <li>
                              Name
                              <strong>Nova GmbH</strong>
                            </li>
                            <li>
                              Address
                              <strong>
                                Emser Platz 6, 10719 Berlin, Germany
                              </strong>
                            </li>
                            <li>
                              Type
                              <strong>Company</strong>
                            </li>
                            <li>
                              Position
                              <strong>Buyer</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={styles["c-document-status__item"]}
                  id="services"
                >
                  <div className={styles["teritary__head"]}>
                    <div className={styles["teritary__head__icon"]}>
                      <Icons.LightBulbIcon />
                    </div>
                    <div className={styles["teritary__head__title"]}>
                      {t("contract:summary")}
                    </div>
                    <div
                      data-html2canvas-ignore="true"
                      className={styles["has-help"]}
                    >
                      <Icons.QuestionCircleIcon />
                      {t("wizard:info.help")}
                    </div>
                  </div>

                  <div className={styles["teritary__row"]}>
                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.ListIcon />
                          </div>
                          <div className={styles["title"]}>Terms</div>
                        </div>
                        <div className={styles["teritary__card__body"]}>
                          <ul>
                            <li>
                              Start date
                              <strong>12.12.2021</strong>
                            </li>
                            <li>
                              Contract duration
                              <strong>18 months</strong>
                            </li>
                            <li>
                              Cancellation period
                              <strong>3 months</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.ListIcon />
                          </div>
                          <div className={styles["title"]}>Offer</div>
                        </div>
                        <div className={styles["teritary__card__body"]}>
                          <ul>
                            <li>
                              Beds
                              <strong>23 </strong>
                            </li>
                            <li>
                              Plan
                              <strong>Myo Premium Plan</strong>
                            </li>
                            <li>
                              Extras
                              <ul>
                                <li>Laundry service provider</li>
                              </ul>
                            </li>
                            <li>
                              Total amount
                              <strong>€433,00 (incl. taxes)</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className={styles["c-document-status__prop"]}>
                <div className={styles["c-document-status__item"]}>
                  <div className={styles["teritary__head"]}>
                    <div className={styles["teritary__head__number"]}>1</div>
                    <div className={styles["teritary__head__title"]}>
                      Conditions
                    </div>
                    <div
                      data-html2canvas-ignore="true"
                      className={styles["has-help"]}
                    >
                      <Icons.QuestionCircleIcon />
                      {t("wizard:info.help")}
                    </div>
                  </div>

                  <div className={styles["teritary__desc"]}>
                    <p>
                      Myosotis offers a digital communication platform for care,
                      consisting of a mobile app and a web-based administration
                      tool for editing users and contributions (hereinafter
                      referred to as "myo"). For this purpose, Myosotis provides
                      myo at the agreed conditions and operates the IT
                      infrastructure required for its use.
                    </p>

                    <ol>
                      <li>
                        The customer commissions myosotis to provide myo, on the
                        basis of the attached{" "}
                        <a href="" title="">
                          Contractual Conditions
                        </a>
                        .
                      </li>
                      <li>
                        The provision of myo, shall be remunerated according to
                        the{" "}
                        <a href="" title="">
                          General conditions
                        </a>{" "}
                        listed in Annex 1.
                      </li>
                      <li>
                        The{" "}
                        <a href="" title="">
                          General Terms and Conditions
                        </a>{" "}
                        according to appendix 2 apply.
                      </li>
                      <li>
                        The{" "}
                        <a href="" title="">
                          Order Processing Agreement
                        </a>{" "}
                        shall be concluded in accordance with Annex 3.
                      </li>
                      <li>
                        Main contracts with the customer's facilities are
                        concluded on the basis of the{" "}
                        <a href="" title="">
                          General Conditions
                        </a>{" "}
                        of Annex 1 using Annex 4.
                      </li>
                      <li>
                        Enclosed Myosotis provides the{" "}
                        <a href="" title="">
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a href="" title="">
                          Terms of Use
                        </a>
                        .
                      </li>
                    </ol>
                  </div>
                </div>

                <div className={styles["c-document-status__item"]}>
                  <div className={styles["teritary__head"]}>
                    <div className={styles["teritary__head__icon"]}>
                      <Icons.PaperClipIcon />
                    </div>
                    <div className={styles["teritary__head__title"]}>
                      {t("contract:attachments")}
                    </div>
                    <div
                      data-html2canvas-ignore="true"
                      className={styles["has-help"]}
                    >
                      <Icons.QuestionCircleIcon />
                      {t("wizard:info.help")}
                    </div>
                  </div>

                  <div className={styles["teritary__desc"]}>
                    <ul>
                      <li>
                        <a href="" title="">
                          Terms of contract
                        </a>
                      </li>
                      <li>
                        <a href="" title="">
                          General conditions
                        </a>
                      </li>
                      <li>
                        <a href="" title="">
                          General terms and conditions
                        </a>
                      </li>
                      <li>
                        <a href="" title="">
                          Order processing contract
                        </a>
                      </li>
                      <li>
                        <a href="" title="">
                          Data protection concept
                        </a>
                      </li>
                      <li>
                        <a href="" title="">
                          Terms of use
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className={styles["c-document-status__item"]}>
                  <div className={styles["teritary__head"]}>
                    <div className={styles["teritary__head__icon"]}>
                      <Icons.PaperClipIcon />
                    </div>
                    <div className={styles["teritary__head__title"]}>
                      {t("contract:signatures")}
                    </div>
                    <div
                      data-html2canvas-ignore="true"
                      className={styles["has-help"]}
                    >
                      <Icons.QuestionCircleIcon />
                      {t("wizard:info.help")}
                    </div>
                  </div>

                  <div className={styles["teritary__row"]}>
                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.OfficeIcon />
                          </div>
                          <div className={styles["title"]}>Myo GmbH</div>
                        </div>
                        <div className={styles["teritary__creator__sign"]}>
                          <div
                            className={styles["teritary__creator__sign__name"]}
                          >
                            Julian Krämer
                          </div>
                          <div
                            className={styles["teritary__creator__sign__date"]}
                          >
                            12.12.2021 12:05:34 CET
                          </div>
                          <div className={styles["creator__row"]}>
                            <div className={styles["creator__logo"]}>JS</div>
                            <div className={styles["creator__detail"]}>
                              <div className={styles["creator__creatorname"]}>
                                Julian Krämers
                              </div>
                              <div className={styles["creator__creatoremail"]}>
                                julian@myo.com
                              </div>
                            </div>
                            <div
                              className={`${styles["creator__status"]} ${styles["accepted"]}`}
                            >
                              <Icons.StatusAcceptedIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles["teritary__item"]}>
                      <div className={styles["teritary__card"]}>
                        <div className={styles["teritary__card__head"]}>
                          <div className={styles["icon"]}>
                            <Icons.OfficeIcon />
                          </div>
                          <div className={styles["title"]}>Nova GmbH</div>
                        </div>
                        <div className={styles["teritary__creator__sign"]}>
                          <div
                            className={styles["teritary__creator__sign__name"]}
                          >
                            Peter Thiel
                          </div>
                          <div
                            className={styles["teritary__creator__sign__date"]}
                          >
                            12.12.2021 12:05:34 CET
                          </div>
                          <div className={styles["creator__row"]}>
                            <div className={styles["creator__logo"]}>PT</div>
                            <div className={styles["creator__detail"]}>
                              <div className={styles["creator__creatorname"]}>
                                Peter Thiel
                              </div>
                              <div className={styles["creator__creatoremail"]}>
                                peter.t@nova.com
                              </div>
                            </div>
                            <div
                              className={`${styles["creator__status"]} ${styles["pending"]}`}
                            >
                              <Icons.StatusPendingIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${styles["aside__messages"]} ${
                messages ? styles["active"] : ""
              }`}
            >
              <div className={styles["aside__messages__top"]}>
                <div
                  onClick={() => setMessages(false)}
                  className={styles["aside__messages__top__back"]}
                >
                  <Icons.ArrowLeft width="12px" height="23px" />
                </div>
                <div className={styles["aside__messages__top__title"]}>
                  {t("review_input:messages")}
                </div>
              </div>
              <div className={styles["aside__messages__body"]}>
                <div className={styles["aside__messages__item"]}>
                  <div className={styles["aside__messages__article"]}>
                    <div className={styles["aside__messages__head"]}>
                      <div className={styles["aside__messages__head__icon"]}>
                        SW
                      </div>
                      <div className={styles["aside__messages__head__user"]}>
                        Swiftsign Bot
                      </div>
                    </div>
                    <div className={styles["aside__messages__body"]}>
                      <p>
                        Hallo! Bitte überprüfen Sie ob alle Angaben im Dokument
                        korrekt sind.
                      </p>
                    </div>
                  </div>
                </div>

                <div className={styles["aside__messages__item"]}>
                  <div className={styles["aside__messages__article"]}>
                    <div className={styles["aside__messages__head"]}>
                      <div className={styles["aside__messages__head__icon"]}>
                        JK
                      </div>
                      <div className={styles["aside__messages__head__user"]}>
                        Julian von myo
                      </div>
                    </div>
                    <div className={styles["aside__messages__body"]}>
                      <p>Liebes Nova-Team,</p>
                      <p>
                        wir freuen uns, Sie zu unserem Produkt begrüßen zu
                        dürfen. Anbei finden Sie die wir besprochene
                        Nutzungsvertrag für Kommunikationsplattform.
                      </p>
                      <p>
                        Beste Grüße,
                        <br />
                        Julian
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles["c-document-status__nav"]}>
            <CButton
              onClick={() => {}}
              icon={<Icons.PdfIcon />}
              type="primary"
              title={"Export as PDF"}
              disabled={true}
              customStyles={styles["btn-export-as-pdf"]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentStatus);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Layout,
  Header,
  LeftSidebar,
  RightPanel,
  HelpCenter,
  HowCanWeHelp,
  PromtForms,
  Icons,
  StepTopBar,
} from "../../components";

import {
  setIndex,
  updateDocumentData,
  setAddressBeforeConfirm,
  setNewDocumentData,
  setSigneeData,
  setAvailableHeaderStep,
  getDocument,
  setIsCreatingDocument,
  verifyUser,
} from "../../store/actions/prompts";
import { setRedirectParams, setTempUser } from "../../store/actions/auth";

import {
  documentSelector,
  addressBeforeConfirmSelector,
  availableHeaderStepSelector,
  isCreatingDocumentSelector,
} from "../../store/selectors/prompts";
import { userSelector } from "../../store/selectors/auth";

import { FORM_TYPE } from "../../constants/config";
import { useQuery, validateWithRegularExpression } from "../../utils/helper";
import { format } from "../../utils/FormatCurrency";

import { ANY_DATE } from "../../utils/regularExpression";

import * as keys from "../../utils/keys";

import styles from "../../styles/Wizard.module.scss";
import reviewStyles from "../ReviewInput/index.module.scss";
import { api } from "../../services/api";
import { DOCUMENT_CURRENT_STEP } from "../../utils/documentCurrentStep";

const Wizard = (props) => {
  const {
    thisDocument,
    updateDocumentData,
    setAddressBeforeConfirm,
    addressBeforeConfirm,
    setNewDocumentData,
    setSigneeData,
    setAvailableHeaderStep,
    availableHeaderStep,
    isCreatingDocument,
    getDocument,
    setRedirectParams,
    setIsCreatingDocument,
    verifyUser,
    setTempUser,
  } = props;
  const location = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const query = useQuery();

  const [isRightPanelOpen, setIsRightPanelOpen] = useState(false);
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState(false);
  //TODO: Don't store component in state
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [isAllCardDataValid, setIsAllCardDataValid] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [fieldsError, setFieldsError] = useState(null);
  const [defaultCardData, setDefaultCardData] = useState(null);
  const [numberOfInputForFocus, setNumberOfInputForFocus] = useState(0);
  const [previousPosition, setPreviousPosition] = useState(null);
  const [documentStyles, setDocumentStyles] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [cameFromEmail, setCameFromEmail] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalBilling, setTotalBilling] = useState({});
  const [currentPromptId, setCurrentPromptId] = useState(null);
  const [isGoingForward, setIsGoingForward] = useState(undefined);

  const filteredPrompts = useMemo(() => {
    if (!thisDocument?.prompts) return [];

    return thisDocument.prompts
      .filter((prompt) => {
        if (prompt.questionType === "INFO") return true;

        return !prompt.defaultAnswer;
      })
      .map((prompt, index) => ({
        ...prompt,
        position: index + 1,
      }));
  }, [thisDocument?.prompts]);

  const planPrompt = filteredPrompts.find(
    (prompt) => prompt.questionType === "PLAN"
  );

  const currentPrompt = useMemo(
    () =>
      filteredPrompts.find(({ promptID }) => promptID === currentPromptId) ||
      null,
    [filteredPrompts, currentPromptId]
  );

  useEffect(() => {
    if (
      !currentPromptId ||
      !filteredPrompts.some((prompt) => prompt.promptID === currentPromptId)
    ) {
      const firstPrompt =
        filteredPrompts.find((prompt) => prompt.position === 1) ||
        thisDocument?.prompts?.find((prompt) => prompt.position === 1);

      if (firstPrompt) {
        setCurrentPromptId(firstPrompt.promptID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPrompts, currentPromptId, thisDocument?.prompts]);

  useEffect(() => {
    setIsLeftSidebarOpen(false);
    setCardData([]);
    setIsAllCardDataValid(false);
    setFieldsError(null);

    const firstPromptWithAddress = filteredPrompts?.find(
      (prompt) => prompt?.questionType === FORM_TYPE.ADDRESS
    );
    if (!firstPromptWithAddress?.answer) {
      setAddressBeforeConfirm(null);
    }

    if (currentPrompt) {
      //This sets the checkbox to true every time
      setCheckboxValue(currentPrompt?.isAllowedReceiverEdit);
      setDefaultValues(currentPrompt);
    }
  }, [currentPrompt, thisDocument, filteredPrompts]);

  useEffect(() => {
    //TODO: Use CSS for this
    const handleResize = () => {
      if (window.innerWidth < 1023) {
        setIsLeftSidebarOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    //TODO: Don't initialize redux state in a component
    if (!availableHeaderStep) {
      setAvailableHeaderStep(0);
    }
  }, []);

  useEffect(() => {
    if (window.location.search && window.location.href.includes(keys.WIZARD)) {
      setIsCreatingDocument(true);

      const isSender = query.get("isSender");

      setRedirectParams({
        search: window.location.search,
        path: keys.WIZARD,
        reset: true,
      });

      if (isSender === "true" && !isAuthenticated) {
        navigate(keys.DASHBOARD);
      } else {
        setCameFromEmail(isSender);
        const documentId = query.get("documentId");
        const passcode = query.get("passcode");

        getDocument(
          documentId, //documentId
          passcode, //passcode
          isSender === "true" ? false : true, //noAuth
          (response) => {
            //callback
            if (
              isSender === "true" &&
              response?.documents?.prompts.length === 0
            ) {
              navigate(keys.REVIEW_INPUT, {
                state: {
                  isSign: true,
                },
              });
            }
          },
          isSender === "true" ? true : false
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isCreatingDocument && !window.location.search) {
      navigate(keys.DASHBOARD);
    }
  }, [isCreatingDocument, navigate]);

  //TODO: No need to keep these things in state, we can simply derive it from the document and useMemo it.
  useEffect(() => {
    if (thisDocument) {
      if (thisDocument?.style?.isApplyToWizard) {
        setDocumentStyles(thisDocument.style);
      } else {
        setDocumentStyles(null);
      }
    }
  }, [thisDocument]);

  useEffect(() => {
    if (thisDocument?.language) {
      i18n.changeLanguage(thisDocument.language?.toLowerCase());
    }
  }, [i18n, thisDocument]);

  const checkIsAllDataValid = (
    fieldsValues,
    questionType,
    sendAfterSingleSelect = false,
    useSendedFields = false,
    useCurrentCheckbox = false,
    currentCheckbox
  ) => {
    let isValid = false;

    let currentCardFields = useSendedFields
      ? fieldsValues
      : fieldsValues?.filter((item) =>
          item?.name?.includes(currentPrompt?.promptID)
        );

    const promptId = questionType?.split("###")?.[0];

    const filteredPrompt = filteredPrompts?.find(
      (promptItem) => promptItem.promptID === promptId
    );

    const isMandatory = filteredPrompt?.isMandatory;

    const isAllowedReceiverEdit = useCurrentCheckbox
      ? currentCheckbox
      : currentPrompt?.promptID === promptId
      ? currentPrompt?.isAllowedReceiverEdit
      : filteredPrompt?.isAllowedReceiverEdit;

    const freeToSkip =
      (isMandatory === false || isAllowedReceiverEdit) && !cameFromEmail;

    switch (questionType?.split("###")?.[1]) {
      case FORM_TYPE.INFO:
        setIsAllCardDataValid(true);
        break;
      case FORM_TYPE.ADDRESS_AUTO_COMPLETE:
        if (addressBeforeConfirm?.country) {
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }

        break;
      case FORM_TYPE.TIME_PERIOD:
        if (freeToSkip) {
          setIsAllCardDataValid(true);
          break;
        }
        const { value: currentUnit } =
          currentCardFields.find((field) => /unit$/.test(field.name)) || {};
        const { value: currentValue } =
          currentCardFields.find((field) => /value$/.test(field.name)) || {};
        const isTimePeriodValid =
          currentUnit && Number.isInteger(parseInt(currentValue));
        setIsAllCardDataValid(isTimePeriodValid);
        if (isTimePeriodValid) {
          const answers = {
            answer: {
              value: currentValue,
              unit: currentUnit,
            },
            promptID: promptId,
          };
          setDataForUpdate([answers]);
        }
        break;
      case FORM_TYPE.SINGLE_SELECT:
        if (currentCardFields?.[0]?.value) {
          if (currentPrompt?.onSelect) {
            setIsAllCardDataValid(false);
          } else {
            setIsAllCardDataValid(true);
          }

          if (sendAfterSingleSelect) {
            const skipQuestions =
              currentPrompt?.onSelect &&
              currentPrompt?.onSelect[
                currentPrompt?.answerOptions[+currentCardFields?.[0]?.value]
              ];

            const answers = {};

            answers.promptID = promptId;
            answers.answer = {
              string:
                filteredPrompt.answerOptions[+currentCardFields?.[0]?.value],
            };

            if (skipQuestions) {
              onNextCardGoPress({
                endForm: true,
                data: [answers],
                isValid: true,
              });
            } else {
              onNextCardGoPress({
                toNext: true,
                data: [answers],
                isValid: true,
              });
            }
          }
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.CURRENCY:
      case FORM_TYPE.COMPANY_NAME:
        isValid =
          freeToSkip ||
          (currentCardFields?.some((item) =>
            item?.name?.includes("firstName")
          ) &&
            currentCardFields?.some((item) =>
              item?.name?.includes("lastName")
            ) &&
            currentCardFields?.every((item) => !!item?.value));

        if (isValid) {
          const answers = {
            answer: {
              [`${
                questionType?.split("###")?.[1] === FORM_TYPE.COMPANY_NAME
                  ? "contact"
                  : "currency"
              }`]: {
                [`${
                  questionType?.split("###")?.[1] === FORM_TYPE.COMPANY_NAME
                    ? "organizationName"
                    : "amount"
                }`]: currentCardFields?.filter((item) =>
                  item?.name?.includes("firstName")
                )?.[0]?.value,
                [`${
                  questionType?.split("###")?.[1] === FORM_TYPE.COMPANY_NAME
                    ? "organizationType"
                    : "currencyType"
                }`]: currentCardFields?.filter((item) =>
                  item?.name?.includes("lastName")
                )?.[0]?.value,
              },
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.OPTIONAL_COMPANY_NAME:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields
            ?.find((item) => item?.name?.includes("firstName"))
            ?.value?.trim()
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              contact: {
                organizationName: currentCardFields?.find((item) =>
                  item?.name?.includes("firstName")
                )?.value,
                optionalName: currentCardFields?.find((item) =>
                  item?.name?.includes("lastName")
                )?.value,
              },
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.VERIFICATION_NAME:
      case FORM_TYPE.PERSON_NAME:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) =>
            item?.name?.includes("firstName")
          ) &&
          currentCardFields?.some((item) => item?.name?.includes("lastName")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              contact: {
                firstName: currentCardFields?.filter((item) =>
                  item?.name?.includes("firstName")
                )?.[0]?.value,
                lastName: currentCardFields?.filter((item) =>
                  item?.name?.includes("lastName")
                )?.[0]?.value,
              },
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.ADDRESS:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("country")) &&
          currentCardFields?.some((item) =>
            item?.name?.includes("streetAddress")
          ) &&
          currentCardFields?.some((item) => item?.name?.includes("city")) &&
          currentCardFields?.some((item) =>
            item?.name?.includes("postalCode")
          ) &&
          currentCardFields?.every(
            (item) =>
              !!item?.value || item?.name?.includes("additionalAddressLine")
          )
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          setNumberOfInputForFocus(1);

          const answers = {
            answer: {
              address: {
                streetAddress: currentCardFields
                  ?.filter((item) => item?.name?.includes("streetAddress"))?.[0]
                  ?.value?.trim(),
                postalCode: currentCardFields
                  ?.filter((item) => item?.name?.includes("postalCode"))?.[0]
                  ?.value?.trim(),
                city: currentCardFields
                  ?.filter((item) => item?.name?.includes("city"))?.[0]
                  ?.value?.trim(),
                country: currentCardFields
                  ?.filter((item) => item?.name?.includes("country"))?.[0]
                  ?.value?.trim(),
                additionalAddressLine:
                  currentCardFields
                    ?.filter((item) =>
                      item?.name?.includes("additionalAddressLine")
                    )?.[0]
                    ?.value?.trim() || "",
              },
            },

            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.NUMBER:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("number")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              number: +currentCardFields?.filter((item) =>
                item?.name?.includes("number")
              )?.[0]?.value,
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.CONTRACT_LENGTH:
        const startDate = currentCardFields?.filter((item) =>
          item?.name?.includes("startDate")
        )?.[0]?.value;

        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) =>
            item?.name?.includes("startDate")
          ) &&
          validateWithRegularExpression(ANY_DATE, startDate) &&
          currentCardFields?.every(
            (item) =>
              !!item?.value ||
              item?.name?.includes("contractDuration") ||
              item?.name?.includes("cancellationPeriod")
          )
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              date: {
                startDate: startDate,
                contractLengthInMonths: currentCardFields?.filter((item) =>
                  item?.name?.includes("contractDuration")
                )?.[0]?.value,
                cancellationPeriodInMonths: currentCardFields?.filter((item) =>
                  item?.name?.includes("cancellationPeriod")
                )?.[0]?.value,
              },
            },

            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.DATE:
        const day = currentCardFields
          ?.filter((item) => item?.name?.includes("day"))?.[0]
          ?.value?.substr(0, 2);
        const month = currentCardFields
          ?.filter((item) => item?.name?.includes("month"))?.[0]
          ?.value?.substr(0, 2);
        const year = currentCardFields
          ?.filter((item) => item?.name?.includes("year"))?.[0]
          ?.value?.substr(0, 4);

        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("day")) &&
          currentCardFields?.some((item) => item?.name?.includes("month")) &&
          currentCardFields?.some((item) => item?.name?.includes("year")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          const fullDate = `${day}.${month}.${year}`;

          if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
            isValid = false;
          } else if (
            currentCardFields?.some((item) => item?.name?.includes("PAST"))
          ) {
            if (moment(fullDate, "DD.MM.YYYY").isSameOrBefore()) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else if (
            currentCardFields?.some((item) => item?.name?.includes("FUTURE"))
          ) {
            if (
              moment(fullDate, "DD.MM.YYYY").isSameOrAfter(
                moment().add("days", "-1")
              )
            ) {
              isValid = true;
            } else {
              isValid = false;
            }
          } else {
            isValid = true;
          }
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              date: {
                day: day,
                month: month,
                year: year,
              },
            },

            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }

        break;
      case FORM_TYPE.STRING:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("string")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
        if (isValid) {
          const answers = {
            answer: {
              string: currentCardFields?.filter((item) =>
                item?.name?.includes("string")
              )?.[0]?.value,
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.MULTI_SELECT:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("string")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const values = currentCardFields?.map(
            (item) => filteredPrompt?.answerOptions[+item?.value]
          );

          const answers = {
            answer: {
              array: values,
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.DROPDOWN:
        if (freeToSkip) {
          isValid = true;
        } else if (
          currentCardFields?.some((item) => item?.name?.includes("dropdown")) &&
          currentCardFields?.every((item) => !!item?.value)
        ) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answers = {
            answer: {
              string: currentCardFields?.filter((item) =>
                item?.name?.includes("dropdown")
              )?.[0]?.value,
            },
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.NOTICE:
        if (freeToSkip) {
          isValid = true;
        } else if (currentCardFields?.every((item) => !!item?.value)) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid) {
          const answersArray = [];

          answersArray.push({
            answer: {
              months: currentCardFields?.filter((item) =>
                item?.name?.includes("contract")
              )?.[0]?.value,
              notice: currentCardFields?.filter((item) =>
                item?.name?.includes("notice")
              )?.[0]?.value,
            },
            promptID: promptId,
          });

          if (totalPrice > 0) {
            const { ...answer } = planPrompt?.answer;
            answer.totalPrice = totalPrice;
            answer.billing = totalBilling;
            answersArray.push({
              answer,
              promptID: planPrompt?.promptID,
            });
          }

          setDataForUpdate(answersArray);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      case FORM_TYPE.PLAN:
        const allTargetsExist =
          !!currentCardFields?.filter(
            (item) => item?.name?.includes("beds")?.value?.length
          ) &&
          !!currentCardFields?.filter(
            (item) => item?.name?.includes("plan")?.value?.length
          ) &&
          !!currentCardFields?.filter(
            (item) => item?.name?.includes("billing")?.value?.length
          );

        if (freeToSkip) {
          isValid = true;
        } else if (allTargetsExist) {
          isValid = true;
        } else {
          isValid = false;
        }

        if (isValid && allTargetsExist) {
          const result = currentCardFields.reduce((acc, obj) => {
            const moduleArrValues = currentCardFields?.filter((item) =>
              item?.name?.includes("module")
            )?.[0]?.value;

            const trainingArrValues = currentCardFields?.filter((item) =>
              item?.name?.includes("training")
            )?.[0]?.value;

            const extrasArrValues = currentCardFields?.filter((item) =>
              item?.name?.includes("extras")
            )?.[0]?.value;

            if (obj.name.includes("beds")) {
              acc.beds = obj.value;
            } else if (obj.name.includes("plan")) {
              acc.plan = Number(obj.value);
            } else if (obj.name.includes("billing")) {
              acc.billing = totalBilling;
            }

            acc.totalPrice = totalPrice;

            acc.extras = !!extrasArrValues?.length
              ? extrasArrValues
              : ["nothing"];

            acc.module = moduleArrValues?.map((value) => {
              const mathchingModule =
                filteredPrompt.answerOptions.moduleOptions.find(
                  (module) => module.value.price === value
                );

              return mathchingModule.value;
            });

            acc.training = trainingArrValues?.map((value) => {
              const mathchingTraining =
                filteredPrompt.answerOptions.trainingOptions.find(
                  (training) => training.value.price === value
                );

              return mathchingTraining.value;
            });

            acc.functions = ["Videotelefonie", "Biografiearbeit"];

            acc.discount =
              currentCardFields?.filter((item) =>
                item?.name?.includes("discount")
              )?.[0]?.value ?? 0;

            return acc;
          }, {});

          const answers = {
            answer: result,
            promptID: promptId,
          };

          setDataForUpdate([answers]);
          setIsAllCardDataValid(true);
        } else {
          setIsAllCardDataValid(false);
        }
        break;
      default:
        setIsAllCardDataValid(false);
    }
  };

  const setDefaultValues = (prompt) => {
    let values = [];
    const basePath = `${prompt.promptID}###${prompt?.questionType}###${prompt.position}`;

    // eslint-disable-next-line default-case
    switch (prompt?.questionType) {
      case FORM_TYPE.TIME_PERIOD:
        const { value, unit } = prompt?.answer || prompt?.default || {};
        values.push({
          name: `${basePath}###value`,
          value: value,
        });
        values.push({
          name: `${basePath}###unit`,
          value: unit,
        });
        break;
      case FORM_TYPE.CURRENCY:
      case FORM_TYPE.PERSON_NAME:
      case FORM_TYPE.COMPANY_NAME:
        if (prompt?.answer) {
          values.push({
            name: `${basePath}###lastName`,
            value:
              prompt?.answer?.[
                `${
                  prompt?.questionType === FORM_TYPE.COMPANY_NAME ||
                  prompt?.questionType === FORM_TYPE.PERSON_NAME
                    ? "contact"
                    : "currency"
                }`
              ][
                `${
                  prompt?.questionType === FORM_TYPE.COMPANY_NAME
                    ? "organizationType"
                    : prompt?.questionType === FORM_TYPE.PERSON_NAME
                    ? "lastName"
                    : "currencyType"
                }`
              ],
          });
        } else {
          values.push({
            name: `${basePath}###lastName`,
            value:
              prompt?.questionType === FORM_TYPE.COMPANY_NAME
                ? "GmbH"
                : prompt?.questionType === FORM_TYPE.CURRENCY
                ? "€"
                : undefined,
          });
        }

        values.push({
          name: `${basePath}###firstName`,
          value:
            prompt?.answer?.[
              `${
                prompt?.questionType === FORM_TYPE.COMPANY_NAME ||
                prompt?.questionType === FORM_TYPE.PERSON_NAME
                  ? "contact"
                  : "currency"
              }`
            ][
              `${
                prompt?.questionType === FORM_TYPE.COMPANY_NAME
                  ? "organizationName"
                  : prompt?.questionType === FORM_TYPE.PERSON_NAME
                  ? "firstName"
                  : "amount"
              }`
            ],
        });

        break;
      case FORM_TYPE.OPTIONAL_COMPANY_NAME:
        values.push({
          name: `${basePath}###lastName`,
          value: prompt?.answer?.contact?.optionalName,
        });

        values.push({
          name: `${basePath}###firstName`,
          value: prompt?.answer?.contact?.organizationName,
        });
        break;
      case FORM_TYPE.ADDRESS:
        if (prompt?.answer?.address) {
          values.push({
            name: `${basePath}###streetAddress`,
            value: prompt?.answer?.address?.streetAddress,
          });
          values.push({
            name: `${basePath}###city`,
            value: prompt?.answer?.address?.city,
          });
          values.push({
            name: `${basePath}###postalCode`,
            value: prompt?.answer?.address?.postalCode,
          });
          values.push({
            name: `${basePath}###country`,
            value: prompt?.answer?.address?.country,
          });
          values.push({
            name: `${basePath}###additionalAddressLine`,
            value: prompt?.answer?.address?.additionalAddressLine,
          });
        }
        break;
      case FORM_TYPE.CONTRACT_LENGTH:
        values.push({
          name: `${basePath}###startDate`,
          value: prompt?.answer?.date?.startDate,
        });
        values.push({
          name: `${basePath}###contractDuration`,
          value: prompt?.answer?.date?.contractLengthInMonths,
        });
        values.push({
          name: `${basePath}###cancellationPeriod`,
          value: prompt?.answer?.date?.cancellationPeriodInMonths,
        });
        break;
      case FORM_TYPE.DATE:
        values.push({
          name: `${prompt.promptID}###${prompt?.questionType}###${
            prompt.position
          }###day${
            prompt.inputCondition?.date
              ? `###${prompt.inputCondition.date}`
              : ""
          }`,
          value: prompt?.answer?.date?.day,
        });
        values.push({
          name: `${prompt.promptID}###${prompt?.questionType}###${
            prompt.position
          }###month${
            prompt.inputCondition?.date
              ? `###${prompt.inputCondition.date}`
              : ""
          }`,
          value: prompt?.answer?.date?.month,
        });
        values.push({
          name: `${prompt.promptID}###${prompt?.questionType}###${
            prompt.position
          }###year${
            prompt.inputCondition?.date
              ? `###${prompt.inputCondition.date}`
              : ""
          }`,
          value: prompt?.answer?.date?.year,
        });
        break;
      case FORM_TYPE.MULTI_SELECT:
        for (let i = 0; i < prompt?.answer?.array?.length; i++) {
          values.push({
            name: `${prompt.promptID}###${prompt?.questionType}###${
              prompt.position
            }###string${prompt?.answerOptions?.findIndex(
              (item) => item === prompt.answer.array[i]
            )}`,
            value: prompt?.answerOptions?.findIndex(
              (item) => item === prompt.answer.array[i]
            ),
          });
        }
        break;
      case FORM_TYPE.STRING:
        values.push({
          name: `${basePath}###string`,
          value: prompt?.answer?.string,
        });
        break;
      case FORM_TYPE.NUMBER:
        values.push({
          name: `${basePath}###number`,
          value: prompt?.answer?.number ? `${prompt.answer.number}` : undefined,
        });
        break;
      case FORM_TYPE.ADDRESS_AUTO_COMPLETE:
        if (addressBeforeConfirm?.country) {
          const value = `${
            !!addressBeforeConfirm?.streetAddress
              ? addressBeforeConfirm.streetAddress
              : ""
          }${
            !!addressBeforeConfirm?.streetNumber
              ? ` ${addressBeforeConfirm.streetNumber}`
              : ""
          }${
            !!addressBeforeConfirm?.postalCode
              ? `, ${addressBeforeConfirm.postalCode}`
              : ""
          }${
            !!addressBeforeConfirm?.city ? ` ${addressBeforeConfirm.city}` : ""
          }${
            !!addressBeforeConfirm?.country
              ? `, ${addressBeforeConfirm.country}`
              : ""
          }`;

          values.push({
            name: `${basePath}###addressAutoComplete`,
            value: value.trim(),
          });
        }
        break;
      case FORM_TYPE.SINGLE_SELECT:
        values.push({
          name: `${basePath}###string`,
          value: prompt?.answerOptions?.findIndex(
            (item) => item === prompt?.answer?.string
          ),
        });
        break;
      case FORM_TYPE.DROPDOWN:
        values.push({
          name: `${basePath}###dropdown`,
          value: prompt?.answer?.string,
        });
        break;
      case FORM_TYPE.NOTICE:
        if (planPrompt?.answer?.totalPrice > 0) {
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###beds`,
            value: planPrompt?.answer?.beds,
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###plan`,
            value: format(planPrompt?.answer?.plan),
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###module`,
            value: planPrompt?.answer?.module
              ? planPrompt?.answer?.module?.map((value) => value.price)
              : [],
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###training`,
            value: planPrompt?.answer?.training
              ? planPrompt?.answer?.training?.map((value) => value.price)
              : [650],
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###extras`,
            value: planPrompt?.answer?.extras
              ? planPrompt?.answer?.extras?.map((value) => value)
              : [],
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###discount`,
            value:
              planPrompt?.answer?.discount > 0
                ? planPrompt?.answer?.discount
                : "0",
          });
          values.push({
            name: `${planPrompt.promptID}###${planPrompt?.questionType}###${planPrompt.position}###billing`,
            value: planPrompt?.answer?.billing
              ? `${planPrompt?.answer?.billing?.title} (€${planPrompt?.answer?.billing?.price})`
              : undefined,
          });
          setTotalPrice(
            planPrompt?.answer?.totalPrice > 0
              ? planPrompt?.answer?.totalPrice
              : 0
          );
          setTotalBilling(
            planPrompt?.answer?.billing?.price > 0
              ? planPrompt?.answer?.billing
              : {}
          );
        }
        values.push({
          name: `${basePath}###contract`,
          value: prompt?.answer?.months ?? 36,
        });
        values.push({
          name: `${basePath}###notice`,
          value: prompt?.answer?.notice ?? 3,
        });
        break;
      case FORM_TYPE.PLAN:
        values.push({
          name: `${basePath}###beds`,
          value: prompt?.answer?.beds,
        });
        values.push({
          name: `${basePath}###plan`,
          value: format(prompt?.answer?.plan),
        });
        values.push({
          name: `${basePath}###module`,
          value: prompt?.answer?.module
            ? prompt?.answer?.module?.map((value) => value.price)
            : [],
        });
        values.push({
          name: `${basePath}###training`,
          value: prompt?.answer?.training
            ? prompt?.answer?.training?.map((value) => value.price)
            : [650],
        });
        values.push({
          name: `${basePath}###extras`,
          value: prompt?.answer?.extras
            ? prompt?.answer?.extras?.map((value) => value)
            : [],
        });
        values.push({
          name: `${basePath}###discount`,
          value: prompt?.answer?.discount > 0 ? prompt?.answer?.discount : "0",
        });
        values.push({
          name: `${basePath}###billing`,
          value: prompt?.answer?.billing
            ? `${prompt?.answer?.billing?.title} (€${prompt?.answer?.billing?.price})`
            : undefined,
        });
        setTotalPrice(
          prompt?.answer?.totalPrice > 0 ? prompt?.answer?.totalPrice : 0
        );
        setTotalBilling(
          prompt?.answer?.billing?.price > 0 ? prompt?.answer?.billing : {}
        );
        break;
    }

    setCardData(values);

    checkIsAllDataValid(
      values,
      `${prompt?.promptID}###${prompt?.questionType}`,
      false,
      true
    );
  };

  useEffect(() => {
    if (defaultCardData && currentPrompt) {
      setCardData([...cardData, ...defaultCardData]);

      checkIsAllDataValid(
        [...cardData, ...defaultCardData],
        `${currentPrompt?.promptID}###${currentPrompt?.questionType}`,
        false
      );

      setDefaultCardData(null);
    }
  }, [currentPrompt, defaultCardData]);

  useEffect(() => {
    if (
      currentPrompt?.questionType === FORM_TYPE.PLAN ||
      currentPrompt?.questionType === FORM_TYPE.NOTICE
    ) {
      checkIsAllDataValid(
        cardData,
        `${currentPrompt?.promptID}###${currentPrompt?.questionType}`,
        false,
        true
      );
    }
  }, [totalPrice, totalBilling]);

  useEffect(() => {
    if (currentPrompt?.questionType === FORM_TYPE.PLAN && !totalPrice) {
      setIsAllCardDataValid(false);
    }
  }, [dataForUpdate]);

  useEffect(() => {
    const userId = query.get("userId");

    if (userId) {
      setTempUser(userId);
    }

    if (location.state?.promptIds) {
      setIsEditing(true);
      setCurrentPromptId(location.state?.promptIds?.[0]);
    } else {
      if (location?.state?.resetDoc) {
        setCurrentPromptId(null);
      }
      setNewDocumentData(null);
      setSigneeData(null);
      setIsEditing(false);
    }
  }, [location]);

  useEffect(() => {
    if (currentPrompt?.questionType === FORM_TYPE.ADDRESS_AUTO_COMPLETE) {
      checkIsAllDataValid(
        [],
        `${currentPrompt?.promptID}###${currentPrompt?.questionType}`
      );
    }
  }, [addressBeforeConfirm]);

  const openHelpCenter = (tips) => {
    setRightPanelComponent(
      <HelpCenter
        data={tips}
        allTips={thisDocument?.tips}
        font={documentStyles?.font}
      />
    );
    setIsRightPanelOpen(true);
  };

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp font={documentStyles?.font} />);
    setIsRightPanelOpen(true);
  };

  const onFieldsChange = async (event) => {
    const newValues = {
      name: event?.[0]?.name?.[0],
      value: event?.[0]?.value,
    };

    setCheckboxValue(false);

    if (newValues.name.includes("checkbox")) {
      const newCheckboxValue = !checkboxValue;

      setCheckboxValue(newCheckboxValue);

      let values = [
        {
          promptID: currentPrompt?.promptID,
          isAllowedReceiverEdit: newCheckboxValue,
        },
      ];

      if (currentPrompt?.questionType === FORM_TYPE.ADDRESS_AUTO_COMPLETE) {
        const nextPrompt = filteredPrompts?.find(
          (item) => item.position === currentPrompt?.position + 1
        );

        values.push({
          promptID: nextPrompt.promptID,
          isAllowedReceiverEdit: newCheckboxValue,
        });
      }

      if (newCheckboxValue) {
        onNextCardGoPress({
          data: values,
          toNext: true,
          isValid: true,
          useReverseCheckbox: true,
        });
      } else {
        checkIsAllDataValid(
          Array.isArray(cardData) ? cardData : [cardData],
          newValues.name,
          false,
          true,
          true,
          newCheckboxValue
        );
      }
    } else if (cardData?.some((item) => item.name === newValues.name)) {
      const cardDataWithoutCurrentValues = cardData?.filter(
        (item) => item.name !== newValues.name
      );
      if (currentPrompt?.questionType === FORM_TYPE.MULTI_SELECT) {
        setCardData([...cardDataWithoutCurrentValues]);

        checkIsAllDataValid(
          [...cardDataWithoutCurrentValues],
          newValues.name,
          true
        );
      } else {
        setCardData([...cardDataWithoutCurrentValues, newValues]);
        checkIsAllDataValid(
          [...cardDataWithoutCurrentValues, newValues],
          newValues.name,
          true
        );
      }
    } else {
      setCardData([...cardData, newValues]);

      checkIsAllDataValid([...cardData, newValues], newValues.name, true);
    }
  };

  const finishSurvey = useCallback(() => {
    navigate(keys.REVIEW_INPUT, {
      state: {
        isSign: !!cameFromEmail,
      },
    });
  }, [cameFromEmail, navigate]);

  const goToCard = useCallback(
    ({ position = null, id = null }) => {
      if (!position && !id) {
        return;
      }

      if (id) {
        if (id === "END") {
          return finishSurvey();
        }
        const nextPrompt = filteredPrompts.find(
          ({ promptID }) => promptID === id
        );
        if (nextPrompt) {
          return setCurrentPromptId(id);
        }
        throw new Error(
          "Can't go to next question, no prompt exists with that id."
        );
      }
      const nextPromptId = filteredPrompts.find(
        (prompt) => prompt.position === position
      )?.promptID;

      if (nextPromptId) {
        return setCurrentPromptId(nextPromptId);
      }

      return finishSurvey();
    },
    [filteredPrompts, finishSurvey]
  );

  const goToNextCard = useCallback(
    ({ answer } = {}) => {
      setIsGoingForward(true);

      const { position, nextPrompt } = currentPrompt;

      // This is gonna be hacky here, as we don't have the way at this moment to wait
      // with the card update until the backend request returns
      // we need to get the current answer (the one we sent at the
      // latest update) from that state variable. In this way we don't have the comfort of
      // reading the backend processed formatted value. The solution would be to set a flag
      // that we're waiting for an update and when the request returns
      // and the document gets updated we make transfer to the next card.
      // This requires more structural changes.
      const currentAnswer = answer?.[0]?.answer?.string;

      if (!nextPrompt || !currentAnswer) {
        return goToCard({ position: position + 1 });
      }
      if (Array.isArray(nextPrompt)) {
        // Let's look for a condition that matching the current answer
        const matchingCondition = nextPrompt.find(
          (condition) => condition.value === currentAnswer
        );
        if (matchingCondition) {
          return goToCard({ id: matchingCondition.promptId });
        }
        // If there was no match let's look for the "else branch" which is a simple string
        const defaultNextPrompt = nextPrompt.find(
          (condition) => !condition.value
        );
        if (defaultNextPrompt) {
          return goToCard({ id: defaultNextPrompt });
        }
        throw new Error("Couldn't find next prompt.");
      }

      // When nextPrompt is defined and it's not an array we assume it contains the id of the next prompt
      return goToCard({ id: nextPrompt });
    },
    [currentPrompt, goToCard]
  );

  const goToPreviousCard = useCallback(() => {
    setIsGoingForward(false);
    const previousLinkedPrompt = filteredPrompts.find((prompt) => {
      if (prompt.nextPrompt === currentPrompt?.promptID) {
        return true;
      }
      if (Array.isArray(prompt.nextPrompt)) {
        const connectingCondition = prompt.nextPrompt.find(
          (condition) =>
            // If a condition refers to our current prompt and the value is check we found the link
            condition.promptId === currentPrompt?.promptID &&
            prompt.answer.formatted === condition.value
        );
        if (connectingCondition) {
          return true;
        }
      }
      return false;
    });

    if (previousLinkedPrompt) {
      return goToCard({ id: previousLinkedPrompt.promptID });
    }

    const { position = 0, questionType } = currentPrompt || {};

    return goToCard({
      position: position - (questionType === FORM_TYPE.ADDRESS ? 2 : 1),
    });
  }, [currentPrompt, filteredPrompts, goToCard]);

  const onError = () => {
    // setFieldsError("123123");
  };

  const onFormSubmit = () => {
    // navigate(keys.REVIEW_INPUT);
  };

  const goBack = () => {
    if (location.state?.cameFromSearch) {
      navigate(keys.SEARCH);
      return;
    }

    navigate(keys.DASHBOARD);
  };

  const onNextCardGoPress = useCallback(
    async (values) => {
      const { toNext, data, isValid, endForm } = values;
      const currentQuestionType = currentPrompt?.questionType;

      if (currentQuestionType === FORM_TYPE.VERIFICATION_NAME) {
        const userId = query.get("userId");
        const firstName = dataForUpdate[0].answer.contact.firstName?.trim();
        const lastName = dataForUpdate[0].answer.contact.lastName?.trim();

        verifyUser(
          userId,
          firstName,
          lastName,
          thisDocument.documentID,
          (response) => {
            if (filteredPrompts.length === 1) {
              finishSurvey();
              goToCard({ position: 1 });
            } else {
              goToCard({ position: 2 });
            }
          }
        );
      } else if (
        (isAllCardDataValid || isValid) &&
        (data || dataForUpdate) &&
        currentQuestionType !== FORM_TYPE.ADDRESS_AUTO_COMPLETE
      ) {
        const dataForRequest = data || dataForUpdate;

        if (currentPrompt?.isAllowedReceiverEditOption) {
          for (let i = 0; i < dataForRequest.length; i++) {
            dataForRequest[i] = {
              ...dataForRequest[i],
              isAllowedReceiverEdit: values?.useReverseCheckbox
                ? !checkboxValue
                : checkboxValue,
            };
          }
        }
        const params = { prompts: dataForRequest };

        if (cameFromEmail && query.get("isSender") === "false") {
          params.updatedBy = query.get("userId");
        }
        await updateDocumentData(
          thisDocument?.documentID,
          params,
          true,
          () => {
            setDataForUpdate(null);
            if (endForm || isEditing) {
              return navigate(keys.REVIEW_INPUT);
            }
            return toNext
              ? goToNextCard({ answer: dataForRequest })
              : goToPreviousCard();
          },
          !!cameFromEmail && query.get("isSender") === "false",
          query.get("passcode"),
          query.get("isSender") === "true"
        );
      } else if (toNext && (isAllCardDataValid || isValid)) {
        if (currentQuestionType === FORM_TYPE.ADDRESS_AUTO_COMPLETE) {
          if (values?.data?.[0]?.isAllowedReceiverEdit) {
            const params = { prompts: data };

            if (cameFromEmail && query.get("isSender") === "false") {
              params.updatedBy = query.get("userId");
            }

            await updateDocumentData(
              thisDocument?.documentID,
              params,
              true,
              () => {
                goToCard({ position: currentPrompt?.position + 2 });
              },
              !!cameFromEmail && query.get("isSender") === "false",
              query.get("passcode"),
              query.get("isSender") === "true"
            );
          } else {
            const nextPrompt = filteredPrompts?.find(
              (item) => item.position === currentPrompt?.position + 1
            );

            const answer = nextPrompt.answer
              ? nextPrompt.answer
              : addressBeforeConfirm?.country
              ? {
                  address: {
                    streetAddress: `${
                      addressBeforeConfirm.streetAddress ?? ""
                    } ${addressBeforeConfirm.streetNumber ?? ""}`,

                    additionalAddressLine:
                      addressBeforeConfirm.additionalAddressLine ?? "",
                    country: addressBeforeConfirm.country ?? "",
                    postalCode: addressBeforeConfirm.postalCode ?? "",
                    city: addressBeforeConfirm.city ?? "",
                  },
                }
              : null;
            let newValues = [
              {
                promptID: currentPrompt?.promptID,
                answer: null,
              },
              {
                promptID: nextPrompt.promptID,
                answer: answer,
              },
            ];

            if (currentPrompt?.isAllowedReceiverEditOption) {
              newValues[0].isAllowedReceiverEdit = values?.useReverseCheckbox
                ? !checkboxValue
                : checkboxValue;
              newValues[1].isAllowedReceiverEdit = values?.useReverseCheckbox
                ? !checkboxValue
                : checkboxValue;
            }

            const params = { prompts: newValues };

            if (cameFromEmail && query.get("isSender") === "false") {
              params.updatedBy = query.get("userId");
            }

            await updateDocumentData(
              thisDocument?.documentID,
              params,
              true,
              () => {
                if (!addressBeforeConfirm) {
                  goToCard({ position: currentPrompt?.position + 2 });
                } else {
                  goToCard({ position: currentPrompt?.position + 1 });
                }
              },
              !!cameFromEmail && query.get("isSender") === "false",
              query.get("passcode"),
              query.get("isSender") === "true"
            );
          }
        } else {
          goToNextCard({ answer: undefined });
        }
      } else {
        console.log("onError");
        onError();
      }
    },
    [
      addressBeforeConfirm,
      cameFromEmail,
      checkboxValue,
      currentPrompt?.isAllowedReceiverEditOption,
      currentPrompt?.position,
      currentPrompt?.promptID,
      currentPrompt?.questionType,
      dataForUpdate,
      filteredPrompts,
      finishSurvey,
      goToCard,
      goToNextCard,
      goToPreviousCard,
      isAllCardDataValid,
      isEditing,
      navigate,
      query,
      thisDocument?.documentID,
      updateDocumentData,
      verifyUser,
    ]
  );

  console.log(currentPrompt?.questionType);

  useEffect(() => {
    const handleKeydown = (event) => {
      // Enter pressed
      if (event.keyCode === 13) {
        event.preventDefault();

        switch (currentPrompt?.questionType) {
          case FORM_TYPE.VERIFICATION_NAME:
          case FORM_TYPE.PERSON_NAME:
            if (numberOfInputForFocus === 1) {
              onNextCardGoPress({ toNext: true });
            } else {
              setNumberOfInputForFocus(numberOfInputForFocus + 1);
            }
            break;
          case FORM_TYPE.COMPANY_NAME:
          case FORM_TYPE.CURRENCY:
          case FORM_TYPE.OPTIONAL_COMPANY_NAME:
            if (numberOfInputForFocus !== 1) {
              onNextCardGoPress({ toNext: true });
            }
            break;
          case FORM_TYPE.ADDRESS:
            if (numberOfInputForFocus === 0 && isAllCardDataValid) {
              onNextCardGoPress({ toNext: true });
              break;
            }
            if (numberOfInputForFocus === 0) {
              onNextCardGoPress({ toNext: true });
              break;
            }

            if (numberOfInputForFocus < 4) {
              setNumberOfInputForFocus(numberOfInputForFocus + 1);
            } else {
              onNextCardGoPress({ toNext: true });
            }
            break;
          case FORM_TYPE.CONTRACT_LENGTH:
            if (numberOfInputForFocus < 2) {
              setNumberOfInputForFocus(numberOfInputForFocus + 1);
            } else {
              onNextCardGoPress({ toNext: true });
            }
            break;
          case FORM_TYPE.DATE:
            if (numberOfInputForFocus < 2) {
              setNumberOfInputForFocus(numberOfInputForFocus + 1);
            } else {
              onNextCardGoPress({ toNext: true });
            }
            break;

          case FORM_TYPE.DROPDOWN:
            if (numberOfInputForFocus !== 0) {
              onNextCardGoPress({ toNext: true });
            }
            break;
          case FORM_TYPE.MULTI_SELECT:
          case FORM_TYPE.STRING:
          case FORM_TYPE.NUMBER:
          case FORM_TYPE.ADDRESS_AUTO_COMPLETE:
            setNumberOfInputForFocus(10);
            onNextCardGoPress({ toNext: true });
            break;
          case FORM_TYPE.SINGLE_SELECT:
          case FORM_TYPE.INFO:
          default:
            onNextCardGoPress({ toNext: true });
        }
      }

      // Tab pressed
      if (event.keyCode === 9) {
        event.preventDefault();
        setNumberOfInputForFocus(numberOfInputForFocus + 1);
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [
    currentPrompt,
    currentPrompt?.questionType,
    isAllCardDataValid,
    numberOfInputForFocus,
    onNextCardGoPress,
  ]);

  useEffect(() => {
    if (!thisDocument?.documentID) return;

    api.patch(`document/${thisDocument.documentID}`, {
      currentStep: DOCUMENT_CURRENT_STEP.CREATE,
    });
  }, [thisDocument?.documentID]);

  return (
    <Layout
      header={
        <Header
          leftBlock={
            !!!query.get("isSender") ? (
              <div className={styles["u-nav-bar-back"]}>
                <div className={styles["u-nav-bar-back__btn"]} onClick={goBack}>
                  <Icons.ArrowLeft />
                </div>
                <div className={styles["u-nav-bar-back__detail"]}>
                  <div
                    className={styles["u-nav-bar-back__name"]}
                    style={{ fontFamily: documentStyles?.font }}
                  >
                    {thisDocument?.fileTitle}
                  </div>
                </div>
                <div
                  onClick={() => setIsLeftSidebarOpen(true)}
                  className={styles["u-nav-bar-back__list"]}
                >
                  <Icons.ListIcon />
                </div>
              </div>
            ) : (
              <div className={reviewStyles["c-review__sign-header-left-side"]}>
                <div className={reviewStyles["c-review__sign-header-icon"]}>
                  <Icons.SwiftsignLogoIcon width={82} height={18} />
                </div>
                <div className={reviewStyles["c-review__sign-header-text"]}>
                  {thisDocument?.fileTitle}
                </div>
                <div />
              </div>
            )
          }
          stepTopBar={
            <StepTopBar
              color={documentStyles?.color}
              font={documentStyles?.font}
              currentId={1}
              canClickTab={!!!query.get("isSender")}
            />
          }
          right={
            <div
              style={{
                marginLeft: 4,
              }}
            />
          }
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `5%`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      questionIconColor={documentStyles?.color}
    >
      <div className={styles["c-wizard"]}>
        <LeftSidebar
          currentPrompt={currentPrompt}
          prompts={filteredPrompts}
          open={isLeftSidebarOpen}
          onClose={() => setIsLeftSidebarOpen(false)}
          goToCard={goToCard}
          documentStyles={documentStyles}
          canChangePosition={!isEditing}
          isPositionsChanged={!!query.get("isSender")}
        />
        <RightPanel
          children={RightPanelComponent}
          open={isRightPanelOpen}
          onClose={() => setIsRightPanelOpen(false)}
          closeIconColor={documentStyles?.color}
        />
        <div className={styles["c-wizard__section"]}>
          <PromtForms
            prompts={filteredPrompts}
            onOpenHelp={(tips) => openHelpCenter(tips)}
            submitForm={onFormSubmit}
            setIndex={setIndex}
            isAllDataValid={isAllCardDataValid}
            onFieldsChange={onFieldsChange}
            onNextCardGoPress={onNextCardGoPress}
            setAddressBeforeConfirm={setAddressBeforeConfirm}
            addressBeforeConfirm={addressBeforeConfirm}
            fieldsError={fieldsError}
            setDefaultCardData={setDefaultCardData}
            numberOfInputForFocus={numberOfInputForFocus}
            setNumberOfInputForFocus={setNumberOfInputForFocus}
            cardData={cardData}
            setPreviousPosition={setPreviousPosition}
            previousPosition={previousPosition}
            currentPrompt={currentPrompt}
            documentStyles={documentStyles}
            documentLanguage={document?.language}
            documentStylesLogo={thisDocument?.style?.logo}
            cameFromEmail={cameFromEmail}
            checkboxValue={checkboxValue}
            setTotalPrice={setTotalPrice}
            setTotalBilling={setTotalBilling}
            totalPrice={totalPrice}
            isGoingForward={isGoingForward}
            thisDocument={thisDocument}
          />

          {!isEditing && (
            <div
              className={styles["c-wizard__controls"]}
              style={{ bottom: window.innerWidth > 1182 ? `1%` : `5%` }}
            >
              <div
                className={styles["c-wizard__controls__item"]}
                onClick={() => {
                  goToPreviousCard();
                }}
              >
                <div className={styles["c-wizard__controls__btn"]}>
                  <Icons.ArrowTopIcon color={documentStyles?.color} />
                </div>
              </div>
              <div
                className={styles["c-wizard__controls__item"]}
                onClick={() => {
                  onNextCardGoPress({ toNext: true });
                }}
              >
                <div className={styles["c-wizard__controls__btn"]}>
                  <Icons.ArrowBottomIcon color={documentStyles?.color} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisDocument: documentSelector(state),
  addressBeforeConfirm: addressBeforeConfirmSelector(state),
  availableHeaderStep: availableHeaderStepSelector(state),
  isCreatingDocument: isCreatingDocumentSelector(state),
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDocumentData,
      setAddressBeforeConfirm,
      setNewDocumentData,
      setSigneeData,
      setAvailableHeaderStep,
      getDocument,
      setRedirectParams,
      setIsCreatingDocument,
      verifyUser,
      setTempUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);

import { orderBy } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../services/api';
import { useSelector } from 'react-redux';
import { documentSelector } from '../store/selectors/prompts';
import { useDocumentPermission } from '../hooks/permissions/document-permissions';
import { $isCommentNode, $isNoHighlightCommentNode } from '../components/Lexical/nodes/Comments';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const CommentsContext = createContext();

function filterDuplicates(array) {
  const seen = new Set();
  return array.filter((obj) => {
    if (seen.has(obj.commentID)) {
      return false; // This is a duplicate commentID
    }
    seen.add(obj.commentID);
    return true;
  });
}

function CommentsProvider({ children }) {
  const [editor] = useLexicalComposerContext();
  const thisDocument = useSelector(documentSelector);
  const { user } = useDocumentPermission();
  const nodeMapRef = useRef(new Map());
  const [comments, setComments] = useState([]);
  const [activeCommentInstance, setActiveCommentInstance] = useState(undefined);

  function getNodeMap() {
    return nodeMapRef.current
  }

  function add(newComment) {
    setComments(old => [newComment, ...old]);
  }

  function updateByCommentID(commentID, newValues) {
    setComments(old => {
      return old.map(comment => {
        if (comment.commentID === commentID) {
          return {
            ...comment,
            ...newValues
          }
        }
        return comment
      })
    })
  }

  function removeByCommentID(commentID) {
    setComments((old) => old.filter(comment => comment.commentID !== commentID))
  }

  useEffect(() => {
    if (!!user?.userID && !!thisDocument) {
      api.get(`comments/${thisDocument.documentID}`).then((res) => {
        const nonRelatedComments = res.data.map((comment) => ({
          ...comment,
          independent: true,
          resolved: comment.status === 'resolved'
        }));


        setComments(old => {
          const remaining = nonRelatedComments.filter((comment) => {
            const repeated = old.some(c => c.commentID === comment.commentID);

            return !repeated;
          })

          return [...old, ...remaining]
        });
      })
    }
  }, [user?.userID, thisDocument?.documentID])

  const commentList = useMemo(() => {
    return orderBy(filterDuplicates(comments), ['createdAt'], ['desc']);
  }, [comments])

  useEffect(() => {
    const nodeMap = getNodeMap();
    const list = []
    editor.getEditorState()._nodeMap.forEach((node) => {
      const instance = node?.__commentInstance
      const exists = list.some((comment) => comment.commentID === instance?.commentID)
      const duplicated = commentList.some((comment) => comment.commentID === instance?.commentID)
      if (($isCommentNode(node) || $isNoHighlightCommentNode(node)) && !exists && !duplicated) {
        nodeMap.set(node.__key, node)
        list.push(instance)
      }
    });
    setComments(old => [...old, ...list]);
  }, [])

  return (
    <CommentsContext.Provider
      value={{ 
        commentList, 
        add, 
        updateByCommentID,
        removeByCommentID,
        setActiveCommentInstance,
        activeCommentInstance,
        getNodeMap
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
}

function useComments() {
  const context = useContext(CommentsContext);
  if (!context) {
    throw new Error('useComments: Component must be within the Context Provider');
  }
  const { commentList, add, updateByCommentID, removeByCommentID, setActiveCommentInstance, activeCommentInstance, getNodeMap } = context;
  return { commentList, add, updateByCommentID, removeByCommentID, setActiveCommentInstance, activeCommentInstance, getNodeMap };
}

export { CommentsProvider, useComments };
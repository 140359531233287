import React from "react";
import { Icons } from "../../src/components";

export const statusDetect = (status) => {
  const uppercaseStatus = status.toUpperCase()
  switch (uppercaseStatus) {
    case "DRAFT":
      return (
        <div
          style={{
            display: "flex",
            background: "#EEEEEF",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px 0px 0px",
          }}
        >
          <Icons.StatusDraftIcon />
          <span>Draft</span>
        </div>
      );
    case "AWAITING":
    case "TO_SIGN":
      return (
        <div
          style={{
            display: "flex",
            background: "#FFF0D4",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px",
            gap: 4,
          }}
        >
          <Icons.StatusPendingIcon />
          <span>Awaiting</span>
        </div>
      );

    case "COMPLETED":
      return (
        <div
          style={{
            display: "flex",
            background: "#E2F3ED",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px 0px 2px",
            whiteSpace: "nowrap",
          }}
        >
          <Icons.StatusAcceptedIcon />
          <span>Fully Signed</span>
        </div>
      );
    case "APPROVED":
      return (
        <div
          style={{
            display: "flex",
            background: "#E2F3ED",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px 0px 2px",
            whiteSpace: "nowrap",
          }}
        >
          <Icons.StatusAcceptedIcon />
          <span>Approved</span>
        </div>
      );
    case "PUBLISHED":
      return (
        <div
          style={{
            display: "flex",
            background: "#ECEDFF",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px 0px 2px",
            whiteSpace: "nowrap",
          }}
        >
          <Icons.StatusAcceptedIcon />
          <span>Published</span>
        </div>
      );
    case "DECLINED":
      return (
        <div
          style={{
            display: "flex",
            background: "red",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 4px",
          }}
        >
          <Icons.StatusDeclinedIcon />
          <span>Declined</span>
        </div>
      );
    case "REJECTED":
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px",
            gap: 4,
            background: '#FFE3E0',
          }}
        >
          <Icons.CloseIcon size={10} />

          <span>Rejected</span>
        </div>
      );

    case "UPLOADED":
      return (
        <div
          style={{
            display: "flex",
            background: "#EEEEEF",
            alignItems: "center",
            borderRadius: "4px",
            padding: "0px 8px 0px 0px",
          }}
        >
          <Icons.UploadedCircleFilled />
          <span>Uploaded</span>
        </div>
      );
    default:
      <></>;
  }
};

export const returnStatusTooltip = (status) => {
  const uppercaseStatus = status.toUpperCase()
  switch (uppercaseStatus) {
    case "DRAFT":
      return "Draft";
    case "AWAITING":
      return "Awaiting";
    case "TO_SIGN":
      return "To Sign";
    case "COMPLETED":
      return "Completed";
    case "DECLINED":
      return "Declined";
    case "UPLOADED":
      return "Uploaded";
    case "PUBLISHED":
      return "Published";
    case "REJECTED":
      return "Rejected";
    case "APPROVED":
      return "Approved";
    default:
      <></>;
  }
};

import React from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import styles from "./index.module.scss";
import { ContentEditable } from "./components/ContentEditable";
import { defaultNodes, defaultOnError, defaultTheme } from ".";
import ManageTagsPlugin from "./plugins/ManageTagsPlugin";
import { TagsPlugin } from "./plugins/TagsPlugin";

export function TemplateEditor({
  onError = defaultOnError,
  onChange,
  disableSelectionChange,
  state,
  useOutsideComposer,
}) {
  const initialConfig = {
    namespace: "TagsEditor",
    theme: defaultTheme,
    onError,
    nodes: defaultNodes,
    editorState: state,
  };
  const [floatingAnchorElem, setFloatingAnchorElem] = React.useState(null);

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return useOutsideComposer ? (
    <>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable
              contentEditable={false}
              className={styles["ContentEditable__root"]}
            />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && (
        <>
          <ManageTagsPlugin anchorElem={floatingAnchorElem} />
        </>
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
      <ListPlugin />
      <TagsPlugin />
    </>
  ) : (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <div className={styles["editor"]} ref={onRef}>
            <ContentEditable
              contentEditable={false}
              className={styles["ContentEditable__root"]}
            />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      {!!floatingAnchorElem && (
        <>
          <ManageTagsPlugin anchorElem={floatingAnchorElem} />
        </>
      )}
      <OnChangePlugin
        onChange={onChange}
        ignoreSelectionChange={disableSelectionChange}
      />
      <ListPlugin />
      <TagsPlugin />
    </LexicalComposer>
  );
}

import { PLAYGROUND_SUBDOMAIN } from "../constants/config";
import { getSubdomain } from "./getSubdomain"

export function isPlayground() {
  const url = window.location.host;

  const subdomain = getSubdomain(url);

  if (subdomain === PLAYGROUND_SUBDOMAIN) return true;

  return false;
};
import React, { useEffect, useState, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as keys from "../../utils/keys";

import {
  Layout,
  RightPanel,
  HowCanWeHelp,
  NavBarBottom,
  Icons,
  Modals,
} from "../../components";

import { CreateTemplate } from "./CreateTemplate";

import documentNda from "../../assets/images/document-nda.png";

import {
  updateAccountData,
  createDocumentFunction,
} from "../../store/actions/auth";
import {
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
  getDocument,
} from "../../store/actions/prompts";

import {
  accountsSelector,
  templatesSelector,
} from "../../store/selectors/auth";

import styles from "../../styles/Document.module.scss";
import { GLOBAL_ACCOUNT } from "../../constants/config";

const colors = [
  "#E18264",
  "#72B1A1",
  "#EAB96D",
  "#72B1A1",
  "#EAB96D",
  "#E18264",
  "#EAB96D",
  "#E18264",
  "#72B1A1",
];

const CreateDocument = ({
  updateAccountData,
  account,
  templates,
  createDocumentFunction,
  navi,
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
  getDocument,
}) => {
  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [tabNumber, setTabNumber] = useState(1);
  const [currentTemplates, setCurrentTemplates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openTemplateId, setOpenTemplateId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.openTab && location?.state?.templateId) {
      setTabNumber(2);
      setShowModal(true);
      setOpenTemplateId(location?.state?.templateId);
    }
  }, [location]);

  const currentTemplate = useMemo(() => {
    return currentTemplates?.find(
      (template) => template?.templateID === openTemplateId
    );
  }, [currentTemplates, openTemplateId]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  useEffect(() => {
    if (!!account) {
      updateAccountData(true);
    }
  }, [account]);

  useEffect(() => {
    if (!!templates) {
      if (tabNumber === 1) {
        setCurrentTemplates(
          templates?.filter((item) => item.createdBy === GLOBAL_ACCOUNT.userID && item.accountID === GLOBAL_ACCOUNT.accountID)
        );
      } else {
        setCurrentTemplates(
          templates?.filter(
            (item) => (item.type === "CUSTOM" || item.type === "GENERATED") && item.createdBy !== GLOBAL_ACCOUNT.userID && item.accountID !== GLOBAL_ACCOUNT.accountID
          )
        );
      }
    }
  }, [templates, tabNumber]);

  return (
    <Layout
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-create-document"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-navigation-bar"]}>
          <div className={styles["container"]}>
            <div className={styles["c-navigation-bar__title"]}>
              <Link to={keys.DASHBOARD} className={styles["link"]}>
                <Icons.ArrowLeft />
              </Link>
              Create document
            </div>

            <div className={styles["c-navigation-bar__nav"]}>
              <div className={styles["c-navigation-bar__nav__temp"]}>
                <div
                  className={styles["c-navigation-bar__nav__temp__item"]}
                  onClick={() => {
                    setTabNumber(1);
                  }}
                >
                  <div
                    data-nav="template-gallery"
                    className={`${styles["c-navigation-bar__nav__temp__link"]}${
                      tabNumber === 1 ? ` ${styles["active"]}` : ""
                    }`}
                  >
                    Template gallery
                  </div>
                </div>
                <div
                  className={styles["c-navigation-bar__nav__temp__item"]}
                  onClick={() => {
                    setTabNumber(2);
                  }}
                >
                  <div
                    data-nav="team-templates"
                    className={`${styles["c-navigation-bar__nav__temp__link"]}${
                      tabNumber === 2 ? ` ${styles["active"]}` : ""
                    }`}
                  >
                    Custom templates
                  </div>
                </div>

                <div
                  className={styles["c-navigation-bar__nav__temp__item"]}
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <div
                    data-nav="team-templates"
                    className={`${styles["c-navigation-bar__nav__temp__link"]}${
                      tabNumber === 3 ? ` ${styles["active"]}` : ""
                    }`}
                    style={{
                      color: "#5F6CFF",
                      alignItems: "center",
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <Icons.PdfIcon />
                    <span>Import file</span>
                    <span
                      style={{
                        background: "#A4AAFC",
                        padding: "2px 4px",
                        color: "white",
                        borderRadius: "3px",
                        fontSize: 12,
                        lineHeight: "16px",
                      }}
                    >
                      Beta
                    </span>
                  </div>
                </div>
              </div>

              {/* <CButton
                icon={<Icons.AddFolderIcon />}
                type="white"
                title={"Suggest a template"}
                customStyles={styles["btn-suggest-template"]}
                onClick={() => {
                  window.open(
                    `mailto:templates@swiftsign.io?subject=New Template Request - Account: ${account?.[0]?.accountID}`
                  );
                }}
              /> */}
            </div>
          </div>
        </div>
        <div className={styles["c-create-document__body"]}>
          <div className={styles["container"]}>
            <div
              data-content="template-gallery"
              className={`${styles["template-content"]} ${styles["active"]}`}
            >
              <div className={styles["document__row"]}>
                <CreateTemplate
                  getDocument={getDocument}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                />
                {!!currentTemplates &&
                  currentTemplates.map((item, index) => {
                    return (
                      <div key={index} className={styles["document__item"]}>
                        <div
                          onClick={() => {
                            setShowModal(index + 1);
                            setOpenTemplateId(item?.templateID);
                          }}
                          className={styles["document__article"]}
                        >
                          <div
                            className={styles["document__article__head"]}
                            style={{ backgroundColor: colors[index % 9] }}
                          >
                            <img src={documentNda} alt={"document nda"} />
                          </div>
                          <div className={styles["document__article__body"]}>
                            <div
                              className={
                                styles["document__article__body__logo"]
                              }
                            >
                              <img
                                src={item?.creatorInfo?.image}
                                alt={"document logo"}
                              />
                            </div>
                            <div
                              className={
                                styles["document__article__body__detail"]
                              }
                            >
                              <div className={styles["document__creatorname"]}>
                                {item?.fileTitle || item?.title}
                              </div>
                              <div className={styles["document__username"]}>
                                {item?.creatorInfo?.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div
              data-content="team-templates"
              className={`${styles["template-content"]}`}
            >
              Custom templates
            </div>
          </div>
        </div>
      </div>
      <NavBarBottom />

      {!!showModal && (
        <Modals.PreviewDocument
          onClose={() => setShowModal(null)}
          templateData={currentTemplate ?? currentTemplates[showModal - 1]}
          createDocumentFunction={createDocumentFunction}
          setDocumentData={setDocumentData}
          setIndex={setIndex}
          setIsCreatingDocument={setIsCreatingDocument}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: accountsSelector(state),
  templates: templatesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAccountData,
      createDocumentFunction,
      setDocumentData,
      setIndex,
      setIsCreatingDocument,
      getDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument);

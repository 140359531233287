import React from "react";
import PropTypes from "prop-types";

const AddFolderIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.53558 0.572266H6.46415C6.36891 0.572266 6.32129 0.619885 6.32129 0.715123V6.32227H1.00028C0.905041 6.32227 0.857422 6.36988 0.857422 6.46512V7.53655C0.857422 7.63179 0.905041 7.67941 1.00028 7.67941H6.32129V13.2866C6.32129 13.3818 6.36891 13.4294 6.46415 13.4294H7.53558C7.63081 13.4294 7.67843 13.3818 7.67843 13.2866V7.67941H13.0003C13.0955 7.67941 13.1431 7.63179 13.1431 7.53655V6.46512C13.1431 6.36988 13.0955 6.32227 13.0003 6.32227H7.67843V0.715123C7.67843 0.619885 7.63081 0.572266 7.53558 0.572266Z"
        fill={color}
      />
    </svg>
  );
};

AddFolderIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default AddFolderIcon;

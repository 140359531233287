import { all, takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { useAuth0 } from "@auth0/auth0-react";

import { request } from "../../utils/client";
import { AUTH } from "../types";
import { store } from "../index";
import { api } from "../../services/api";

export function* getAccountDataSaga({ payload: { user } }) {
  try {
    const state = store.getState();
    const token = state.token;

    if (token && user?.email) {
      const response = yield call(request, `user/${user.email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.exists) {
        if (
          !response.users.auth0UserID &&
          !!user.sub &&
          !!response.users.userID &&
          !!response.accounts?.[0]?.accountID
        ) {
          yield call(request, `user/${response.users.userID}`, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              [`account-id`]: response.accounts[0].accountID,
            },
            payload: {
              auth0UserID: user.sub,
            },
          });
        }

        let createAccountRequestResult;

        if (!response?.accounts) {
          createAccountRequestResult = yield call(request, "account", {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            payload: {
              users: [
                {
                  userID: response.users.userID,
                  email: user.email,
                },
              ],
            },
          });
        }

        if (response.type === "ERROR") {
          yield put({
            type: AUTH.GET_ACCOUNT_DATA_FAILURE,
            payload: {
              response: response,
              message: {
                message: response?.message,
                messageTitle: response?.messageTitle,
                showMessage: response?.showMessage,
              },
            },
          });
        } else {
          yield put({
            type: AUTH.GET_ACCOUNT_DATA_SUCCESS,
            payload: {
              data: {
                accounts: response?.accounts
                  ? response.accounts
                  : createAccountRequestResult?.accounts,
                user: response.users,
              },
              message: {
                message: response?.message,
                messageTitle: response?.messageTitle,
                showMessage: response?.showMessage,
              },
            },
          });
        }
      } else {
        let createResponse;
        if (user.sub.includes("google")) {
          createResponse = yield call(request, `user?createAccount=true`, {
            method: "POST",
            payload: {
              email: user.email,
              acceptedTermsAndConditions: true,
              auth0UserID: user.sub,
              firstName: user.given_name,
              lastNAme: user.family_name,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          createResponse = yield call(request, `user?createAccount=true`, {
            method: "POST",
            payload: {
              email: user.email,
              acceptedTermsAndConditions: true,
              auth0UserID: user.sub,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }

        if (response.type === "ERROR") {
          yield put({
            type: AUTH.GET_ACCOUNT_DATA_FAILURE,
            payload: {
              response: response,
              message: {
                message: response?.message,
                messageTitle: response?.messageTitle,
                showMessage: response?.showMessage,
              },
            },
          });
        } else {
          yield put({
            type: AUTH.GET_ACCOUNT_DATA_SUCCESS,
            payload: {
              data: {
                accounts: createResponse.accounts,
                user: createResponse.users,
              },
              message: {
                message: response?.message,
                messageTitle: response?.messageTitle,
                showMessage: response?.showMessage,
              },
            },
          });
        }
      }
    }
  } catch (err) {
    yield put({
      type: AUTH.GET_ACCOUNT_DATA_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* changeAccountDataSaga({ payload: { values, callback } }) {
  try {
    const state = store.getState();
    const token = state.token;
    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `account/${accountId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
      payload: { ...values },
    });

    if (!!callback) {
      callback(true, response);
    }

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.CHANGE_ACCOUNT_DATA_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: callback ? false : response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.CHANGE_ACCOUNT_DATA_SUCCESS,
        payload: {
          response: response.accounts,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: callback ? false : response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    if (!!callback) {
      callback(false, err);
    }

    yield put({
      type: AUTH.CHANGE_ACCOUNT_DATA_FAILURE,
      payload: err,
    });

    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* deleteAccountDataSaga({ payload: { values, callback } }) {
  try {
    const state = store.getState();
    const token = state.token;
    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `account/${accountId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
      payload: { ...values },
    });

    if (!!callback) {
      callback(true, response);
    }

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.CHANGE_ACCOUNT_DATA_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    if (!!callback) {
      callback(false, err);
    }

    yield put({
      type: AUTH.CHANGE_ACCOUNT_DATA_FAILURE,
      payload: err,
    });

    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* updateUserSaga({ payload: { values, callback } }) {
  try {
    const state = store.getState();
    const token = state.token;

    const accountId = state.authReducer?.workspace;

    const userId = state.authReducer?.user?.userID;

    const response = yield call(request, `user/${userId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
      payload: {
        ...values,
      },
    });

    if (!!callback) {
      callback();
    }

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.UPDATE_USER_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.UPDATE_USER_SUCCESS,
        payload: {
          response: response.users,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.UPDATE_USER_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* updateAccountDataSaga({ payload: { returnFiles } }) {
  const state = store.getState();
  const token = state.token;

  if (!token) {
    return;
  }

  try {
    const accountId = state.authReducer?.workspace;

    const response = yield call(
      request,
      `account/${accountId}?returnFiles=${!!returnFiles}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          [`account-id`]: accountId,
        },
      }
    );

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.UPDATE_ACCOUNT_DATA_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.UPDATE_ACCOUNT_DATA_SUCCESS,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.UPDATE_ACCOUNT_DATA_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* createDocumentSaga({ payload: { templateId, callback } }) {
  try {
    const state = store.getState();
    const token = state.token;

    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `document?origin=TEMPLATE`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
      payload: {
        templateInput: {
          templateID: templateId,
        },
      },
    });

    if (!!callback) callback(response);

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.CREATE_DOCUMENT_FUNCTION_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.CREATE_DOCUMENT_FUNCTION_SUCCESS,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.CREATE_DOCUMENT_FUNCTION_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* updateDocumentFromDashboardSaga({
  payload: { documentId, body, callback },
}) {
  try {
    const state = store.getState();
    const token = state.token;
    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `document/${documentId}`, {
      method: "PATCH",
      payload: body,
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
    });

    if (!!callback) {
      callback();
    }

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD_SUCCESS,
        payload: {
          response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* deleteDocumentSaga({ payload: { documentId } }) {
  try {
    const state = store.getState();
    const token = state.token;
    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `document/${documentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
    });

    if (response.type === "ERROR") {
      yield put({
        type: AUTH.DELETE_DOCUMENT_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.DELETE_DOCUMENT_SUCCESS,
        payload: {
          documentId: documentId,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.DELETE_DOCUMENT_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* getSortedDocumentsSaga({ payload: { sortBy } }) {
  const state = store.getState();
  const token = state.token;
  if (!token) {
    return;
  }

  try {
    const accountId = state.authReducer?.workspace;

    const response = yield call(request, `document/?sortBy=${sortBy}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: accountId,
      },
    });

    if (response?.type === "ERROR") {
      yield put({
        type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
        payload: {
          type: "",
        },
      });
      yield put({
        type: AUTH.GET_SORTED_DOCUMENTS_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
        payload: {
          type: "",
        },
      });
      yield put({
        type: AUTH.GET_SORTED_DOCUMENTS_SUCCESS,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.GET_SORTED_DOCUMENTS_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* setCurrentWorkspaceSaga({ payload: { sortBy, workspace } }) {
  try {
    const state = store.getState();
    const token = state.token;

    const response = yield call(request, `document/?sortBy=${sortBy}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        [`account-id`]: workspace,
      },
    });

    if (response?.type === "ERROR") {
      yield put({
        type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
        payload: {
          type: "",
        },
      });
      yield put({
        type: AUTH.GET_SORTED_DOCUMENTS_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
        payload: {
          type: "",
        },
      });
      yield put({
        type: AUTH.GET_SORTED_DOCUMENTS_SUCCESS,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: AUTH.GET_SORTED_DOCUMENTS_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* sendWorkspaceInviteSaga({
  payload: { accountID, userID, callback },
}) {
  try {
    const state = store.getState();
    const token = state.token;

    const response = yield call(
      request,
      `account/${accountID}/resend-invite/${userID}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          [`account-id`]: accountID,
        },
        payload: {},
      }
    );

    if (callback) {
      callback(true, response);
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield all([
    takeEvery(AUTH.GET_ACCOUNT_DATA, getAccountDataSaga),
    takeEvery(AUTH.CHANGE_ACCOUNT_DATA, changeAccountDataSaga),
    takeEvery(AUTH.DELETE_ACCOUNT_DATA, deleteAccountDataSaga),
    takeEvery(AUTH.UPDATE_USER, updateUserSaga),
    takeEvery(AUTH.UPDATE_ACCOUNT_DATA, updateAccountDataSaga),
    takeEvery(AUTH.CREATE_DOCUMENT_FUNCTION, createDocumentSaga),
    takeEvery(
      AUTH.UPDATE_DOCUMENT_FROM_DASHBOARD,
      updateDocumentFromDashboardSaga
    ),
    takeEvery(AUTH.DELETE_DOCUMENT, deleteDocumentSaga),
    takeLatest(AUTH.GET_SORTED_DOCUMENTS, getSortedDocumentsSaga),
    takeLatest(AUTH.SEND_WORKSPACE_INVITE, sendWorkspaceInviteSaga),
    takeLatest(AUTH.SET_CURRENT_WORKSPACE, setCurrentWorkspaceSaga),
  ]);
}

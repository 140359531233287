import React from "react";
import PropTypes from "prop-types";

const Plus = ({ color = "currentColor", className }) => {
  return (
    <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color} d="M12.8034 2.8584H11.1962C11.0534 2.8584 10.9819 2.92983 10.9819 3.07268V11.4834H3.00042C2.85756 11.4834 2.78613 11.5548 2.78613 11.6977V13.3048C2.78613 13.4477 2.85756 13.5191 3.00042 13.5191H10.9819V21.9298C10.9819 22.0727 11.0534 22.1441 11.1962 22.1441H12.8034C12.9462 22.1441 13.0176 22.0727 13.0176 21.9298V13.5191H21.0004C21.1433 13.5191 21.2147 13.4477 21.2147 13.3048V11.6977C21.2147 11.5548 21.1433 11.4834 21.0004 11.4834H13.0176V3.07268C13.0176 2.92983 12.9462 2.8584 12.8034 2.8584Z"/>
    </svg>
  );
};

Plus.propTypes = {
  color: PropTypes.string,
};

export default Plus;

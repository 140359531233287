import React, { useCallback, useMemo, useRef, useState } from "react";
import styles from "../styles.module.scss";
import { Button, Popover, Typography } from "antd";
import { format, parseISO } from "date-fns";
import classNames from "classnames";
import { SUGGESTION_FORMAT_STRING } from "../constants";
import Icons from "../../Icons";
import { useDisclosure } from "../../../hooks/helpers/useDisclosure";
import CustomTextarea from "./CustomTextArea";
import { api } from "../../../services/api";
import { useSelector } from "react-redux";
import { documentSelector } from "../../../store/selectors/prompts";
import useMediaQuery from "../../../hooks/helpers/useMediaQuery";
import ActionsDrawer from "./ActionsDrawer";
import CommentsAndEdits from "./CommentsAndEdits";
import { FREE_UPDATE_COMMENT_COMMAND } from "../../Lexical/nodes/Comments";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { cloneDeep } from "lodash";
import { useComments } from "../../../contexts/CommentContext";
import { useDocumentPermission } from "../../../hooks/permissions/document-permissions";
import { isApple } from "../../../utils/platform";

const Reply = ({ reply, comment, activeEdit, setActiveEdit }) => {
  const [editor] = useLexicalComposerContext()
  const {
    replyID,
    message,
    createdAt,
    user: { firstName, lastName, initials },
  } = reply;
  const comments = useComments();
  const { user, isOwner } = useDocumentPermission();
  const editInputRef = useRef();

  const thisDocument = useSelector(documentSelector);

  const isMobile = useMediaQuery("(max-width: 1023px)");

  // const {
  //   isOpen: isEditingOpen,
  //   onOpen: onEditingOpen,
  //   onClose: onEditingClose,
  // } = useDisclosure();
  const isEditingOpen = useMemo(() => {
    return activeEdit?.id === reply?.replyID && activeEdit?.isReply;
  }, [activeEdit, reply?.replyID])
  const {
    isOpen: isActionsOpen,
    onClose: onActionsClose,
    onToggle: onActionsToggle,
  } = useDisclosure();

  const [editedMessage, setEditedMessage] = useState(message);

  const name = useMemo(() => {
    return `${firstName} ${lastName}`;
  }, [firstName, lastName]);

  const formattedDate = useMemo(() => {
    return format(parseISO(createdAt), SUGGESTION_FORMAT_STRING);
  }, [createdAt]);

  const handleDelete = useCallback(async () => {
    const instance = cloneDeep(comment)

    if (comment.independent) {
      const replies = comment.replies.filter((r) => r.replyID !== replyID);

      api.patch(`comments/${comment.commentID}`, {
        replies
      }, {
        params: {
          userID: user?.userID
        }
      }).then(res => {
        comments.updateByCommentID(comment.commentID, res.data);
        onActionsClose();
      })
      return;
    }

    instance.replies = instance.replies.filter((r) => r.replyID !== replyID)
    editor.dispatchCommand(FREE_UPDATE_COMMENT_COMMAND, instance);
    onActionsClose();
  }, [
    onActionsClose,
    editor,
    comment,
    replyID,
    comment
  ]);

  const handleEdit = useCallback(async () => {
    if (!editedMessage.length || editedMessage === message) {
      setActiveEdit("");
      onActionsClose();
    }

    if (comment.independent) {
      const replies = comment.replies.map((r) => {
        if (r.replyID === replyID) {
          return {
            ...r,
            message: editedMessage,
          }
        }
  
        return r
      })

      api.patch(`comments/${comment.commentID}`, {
        replies
      }, {
        params: {
          userID: user?.userID
        }
      }).then(res => {
        comments.updateByCommentID(comment.commentID, res.data);
        setActiveEdit("");
      })
      return;
    }

    const instance = cloneDeep(comment)

    instance.replies = instance.replies.map((r) => {
      if (r.replyID === replyID) {
        return {
          ...r,
          message: editedMessage,
        }
      }

      return r
    })
    editor.dispatchCommand(FREE_UPDATE_COMMENT_COMMAND, instance);
    setActiveEdit("");
  }, [
    editedMessage,
    onActionsClose,
    setActiveEdit,
    message,
    editor,
    comment,
    replyID
  ]);

  const handleEditButtonClick = useCallback(() => {
    setActiveEdit(reply?.replyID, true);
    setTimeout(() => {
      const end = editInputRef.current?.value.length;
      editInputRef.current?.setSelectionRange(end, end);
      editInputRef.current?.focus();
    }, 100);
    onActionsClose();
  }, [onActionsClose, setActiveEdit]);

  const shouldShowEditItem = !comment.resolved && user?.userID === reply.user?.userID;
  const shouldShowDeleteItem = user?.userID === reply.user?.userID;

  const shouldShowActions = shouldShowEditItem || shouldShowDeleteItem
  const actionsContent = useMemo(
    () => (
      <div className={styles.popover}>
        {shouldShowEditItem && (
          <button className={styles.popover__button} onClick={handleEditButtonClick}>
            <Icons.RenameIcon />
            Edit
          </button>
        )}
        {shouldShowDeleteItem && (
          <button className={classNames(
            styles.popover__button,
            styles.danger
          )} onClick={handleDelete}>
            <Icons.DeleteIcon />
            Delete
          </button>
        )}
      </div>
    ),
    [handleDelete, setActiveEdit]
  );

  const isDocumentComplete = useMemo(() => {
    return thisDocument.status === "COMPLETED";
  }, [thisDocument])

  function onKeyboardEventEdit(event) {
    const enter = event.code === 'Enter';

    if (enter && event.altKey && isEditingOpen) {
      handleEdit();
      return;
    }

    // if (enter && event.altKey) {
    //   setNewComment(old => old + '\n');
    //   return;
    // }
  }

  return (
    <>
      <div className={classNames(styles.comment, styles.replyContainer__reply)}>
        <div className={styles.comment__header}>
          <div className={styles.comment__row}>
            <Typography.Text className={styles.comment__initials}>
              {initials}
            </Typography.Text>
            <div className={styles.comment__column}>
              <Typography.Text className={styles.comment__name}>
                {name}
              </Typography.Text>
              <Typography.Text className={styles.comment__date}>
                {formattedDate}
              </Typography.Text>
            </div>
          </div>
          {shouldShowActions && !isDocumentComplete && (
            <Popover
              open={!isMobile && isActionsOpen}
              onOpenChange={onActionsToggle}
              trigger="click"
              content={actionsContent}
              showArrow={false}
              placement="bottom"
              overlayClassName={styles.popover}
            >
              <Button
                icon={
                  <Icons.MoreIcon color={isActionsOpen ? "#5F6CFF" : "#8C8C97"} />
                }
                type="text"
              />
            </Popover>
          )}
        </div>
        {isEditingOpen ? (
          <div className={styles.comment__replyContainer}>
            <CustomTextarea
              ref={editInputRef}
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              onKeyDown={onKeyboardEventEdit}
            />

            <div className={styles.comment__cancelSaveButtonsContainer}>
              <div className={styles.comment__tip}>
                {isApple() ? '⌥ + Return to post' : 'Alt + Enter to post'}
              </div>
              <div className={styles.comment__cancelSaveButtonsGroup}>
                <Button
                  type="text"
                  onClick={() => {
                    setEditedMessage(message);
                    setActiveEdit("");
                  }}
                  className={styles.comment__cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className={styles.comment__submitButton}
                  onClick={handleEdit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <Typography.Paragraph className={classNames(styles.comment__message, styles.reply)}>
            {message}
          </Typography.Paragraph>
        )}
      </div>
      {isMobile && shouldShowActions && (
        <ActionsDrawer isOpen={isActionsOpen} onClose={onActionsClose}>
          {actionsContent}
        </ActionsDrawer>
      )}
    </>
  );
};

export default Reply;

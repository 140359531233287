import React from "react";
import PropTypes from "prop-types";

const FolderOpenIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M23.1429 7.17531H20.25V4.13246C20.25 3.65835 19.867 3.27531 19.3929 3.27531H10.9554L7.81339 0.269958C7.77342 0.232532 7.72083 0.211499 7.66607 0.211029H0.857143C0.383036 0.211029 0 0.594065 0 1.06817V16.9253C0 17.3994 0.383036 17.7825 0.857143 17.7825H19.5536C19.9018 17.7825 20.2179 17.5709 20.3491 17.2467L23.9384 8.35389C23.9786 8.2521 24 8.14228 24 8.03246C24 7.55835 23.617 7.17531 23.1429 7.17531ZM1.92857 2.1396H6.97768L10.1813 5.20389H18.3214V7.17531H4.66071C4.3125 7.17531 3.99643 7.38692 3.86518 7.71103L1.92857 12.511V2.1396ZM18.9455 15.8539H2.54464L5.31161 8.99674H21.7152L18.9455 15.8539Z"
        fill={color}
      />
    </svg>
  );
};

FolderOpenIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default FolderOpenIcon;

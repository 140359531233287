import { $getNodeByKey, $getSelection, $isElementNode, $isRangeSelection, $setSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from "lexical";
import { $createNoHighlightCommentNode, $isNoHighlightCommentNode, NoHighlightCommentNode, DELETE_COMMENT_COMMAND, FREE_UPDATE_COMMENT_COMMAND, SET_COMMENT_COMMAND, UPDATE_COMMENT_COMMAND } from "../../nodes/Comments";
import { useCallback, useEffect } from "react";
import { useComments } from "../../../../contexts/CommentContext";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { getSelectedNode } from "../../utils/getSelectedNode";
import { cloneDeep } from "lodash";
import { mergeRegister } from "@lexical/utils";
import { api } from "../../../../services/api";
import { sendCommentMailNotification } from "../../../../services/api/mail-notification";

export default function CommentViewPlugin() {
  const [editor] = useLexicalComposerContext();
  const comments = useComments();

  const setActiveStates = useCallback(() => {
    // const state = editor.getEditorState()

    // state.read(() => {
    //   const commentInstances = []

    //   state._nodeMap.forEach((node, key, map) => {
    //     node.__type === NoHighlightCommentNode.getType()

    //     const commentInstance = (node).__commentInstance || {}

    //     if (commentInstance.uuid) commentInstances.push(commentInstance)
    //   })

    //   setAllCommentInstances(commentInstances)
    // })

    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)

      const parent = node.getParent()

      let commentNode

      if ($isNoHighlightCommentNode(node)) commentNode = node
      else if (parent && $isNoHighlightCommentNode(parent)) commentNode = parent

      if (commentNode) {
        // setIsComment(true)
        const activeCommentInstance = cloneDeep(commentNode.__commentInstance)
        comments.setActiveCommentInstance(activeCommentInstance)
      } else {
        // setIsComment(false)
        comments.setActiveCommentInstance(undefined)
      }
    }
  }, [editor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, e) => {
        setActiveStates()
        return false
      },
      COMMAND_PRIORITY_LOW
    )
  })

  useEffect(() => {
    if (!editor.hasNodes([NoHighlightCommentNode])) {
      throw new Error('CommentPlugin: NoHighlightCommentNode not registered on editor');
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SET_COMMENT_COMMAND,
        ({
          shouldSendMail,
          commentInstance,
          documentID,
          emailTo
        }) => {
          setCommentInstance(commentInstance);
          if (shouldSendMail) {
            sendCommentMailNotification({
              commentInstance,
              documentID,
              emailTo
            })
          }
          return true;
        },
        EditorPriority,
      ),
      editor.registerCommand(
        UPDATE_COMMENT_COMMAND,
        (payload) => {
          updateCommentInstance(payload);
          return true;
        },
        EditorPriority,
      ),
      editor.registerCommand(
        FREE_UPDATE_COMMENT_COMMAND,
        (payload) => {
          const nodes = Array.from(editor.getEditorState()._nodeMap.values());
          const node = nodes.find((n) => {
            if (n instanceof NoHighlightCommentNode) {
              return n?.__commentInstance?.commentID === payload.commentID
            }
            return false
          })
          if (node) {
            node.setComment(payload)
          }
          return true
        },
        EditorPriority
      ),
      editor.registerCommand(
        DELETE_COMMENT_COMMAND,
        (payload) => {
          editor.getEditorState()._nodeMap.forEach((node) => {
            if (node instanceof NoHighlightCommentNode && node.__commentInstance.commentID === payload.commentID) {
              node.selectStart();
              const text = node.getTextContent();
              const selection = $getSelection();
              selection.insertRawText(text);
              node.remove();
            }
          });
          return true
        },
        EditorPriority
      )
    )
  }, [editor]);

  useEffect(() => {
    return editor.registerMutationListener(NoHighlightCommentNode, (nodeMutations) => {
      for (const [nodeKey, mutation] of nodeMutations) {
        if (mutation === "created") {
          const nodeMap = comments.getNodeMap();
          const editorState = editor.getEditorState();

          const node = $getNodeByKey(nodeKey, editorState);
          const instance = node.__commentInstance

          comments.add(instance);
          nodeMap.set(nodeKey, node);
        } else if (mutation === "destroyed") {
          const nodeMap = comments.getNodeMap();

          const node = nodeMap.get(nodeKey);
          const instance = node?.__commentInstance;

          if (node) {
            comments.removeByCommentID(instance?.commentID)
          }
        } else if (mutation === "updated") {
          const nodeMap = comments.getNodeMap();
          const editorState = editor.getEditorState();

          const node = $getNodeByKey(nodeKey, editorState);

          const instance = node.__commentInstance;

          comments.updateByCommentID(instance.commentID, instance);
          nodeMap.set(nodeKey, node);
        }
      }
    })
  }, [editor, comments])

  return null
}

function updateCommentInstance(commentInstance) {
  // const [editor] = useLexicalComposerContext()

  if (!commentInstance) return
  
  const selection = $getSelection();

  if (selection !== null) $setSelection(selection)

  const sel = $getSelection()

  if (sel !== null) {
    const nodes = sel.extract()

    for (const node of nodes) {
      const parent = node.getParent()

      let commentNode = undefined;

      if (parent && $isNoHighlightCommentNode(parent)) commentNode = parent
      else if (node && $isNoHighlightCommentNode(node)) commentNode = node

      const foundNodeWithSameUuid = commentNode?.__commentInstance.commentID === commentInstance.commentID || false

      if (foundNodeWithSameUuid) commentNode?.setComment(commentInstance)
    }
  }
}

const EditorPriority = 0;


function setCommentInstance(commentInstance) {
  const selection = $getSelection();

  if (selection !== null) $setSelection(selection)

  const sel = $getSelection();

  // IT should look like this
  // TODO: figure out what could be solution of the problem that occur when sel.getTextContent() is included
  // if (sel !== null && sel.getTextContent()) {

  if (sel !== null) {
    const nodes = sel.extract();
    if (commentInstance === null) {
      // Remove CommentNodes
      nodes.forEach((node) => {
        const parent = node.getParent();

        if (parent && $isNoHighlightCommentNode(parent)) {
          const children = parent.getChildren();

          for (let i = 0; i < children.length; i += 1) parent.insertBefore(children[i])

          parent.remove();
        }
      });
    } else {
      // Add or merge CommentNodes
      if (nodes.length === 1) {
        const firstNode = nodes[0];

        // if the first node is a NoHighlightCommentNode or if its
        // parent is a NoHighlightCommentNode, we update the commentInstance.

        if ($isNoHighlightCommentNode(firstNode)) {
          (firstNode).setComment(commentInstance);
          return;
        } else {
          const parent = firstNode.getParent();

          if (parent && $isNoHighlightCommentNode(parent)) {
            // set parent to be the current NoHighlightCommentNode
            // so that other nodes in the same parent
            // aren't handled separately below.
            (parent).setComment(commentInstance);
            return;
          }
        }
      }

      let prevParent = null;

      let commentNode = null;

      nodes.forEach((node) => {
        const parent = node.getParent();
        if (
          parent === commentNode ||
          parent === null ||
          ($isElementNode(node) && !(node).isInline())
        ) {
          return;
        }
        if (!parent.is(prevParent)) {
          prevParent = parent;
          commentNode = $createNoHighlightCommentNode(commentInstance);

          if ($isNoHighlightCommentNode(parent)) {
            if (node.getPreviousSibling() === null) {
              parent.insertBefore(commentNode);
            } else {
              parent.insertAfter(commentNode);
            }
          } else {
            node.insertBefore(commentNode);
          }
        }
        if ($isNoHighlightCommentNode(node)) {
          if (commentNode !== null) {
            const children = (node).getChildren();

            for (let i = 0; i < children.length; i++) commentNode.append(children[i])
          }

          node.remove();
          return;
        }
        if (commentNode !== null) {
          commentNode.append(node);
        }
      });
    }

    $setSelection(null);
  }
}

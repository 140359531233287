import React from "react";
import { Icons, CButton } from "../../../components";
import styles from "../index.module.scss";

const ConfirmLexicalParse = (props) => {
  const { onConfirm, onCancel, isLoading } = props;

  return (
    <div className={styles["u-overlay"]}>
      <div className={styles["u-modal"]}>
        <div className={`${styles["u-modal__head"]} ${styles["red"]}`}>
          <Icons.WarningFillIcon />
        </div>
        <div
          className={styles["u-modal__title"]}
          style={{
            marginBottom: 8,
          }}
        >
          Are you sure you want to change to editor mode?
        </div>
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <span>The links between dynamic data will be lost.</span>
        </div>
        <div className={styles["u-modal__foot"]}>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onCancel}
              type="reverse-primary"
              title={"Cancel"}
              customStyles={styles["btn-modal"]}
              disabled={isLoading}
              dataLoading={isLoading}
            />
          </div>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onConfirm}
              type="primary"
              title={"Continue"}
              customStyles={styles["btn-modal"]}
              disabled={isLoading}
              dataLoading={isLoading}
              // backgroundColor="#E53935"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmLexicalParse;

import React from "react";
import { Icons, CButton } from "../../../components";
import styles from "../index.module.scss";

const ConfirmRemove = (props) => {
  const { onClose, onConfirm } = props;

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["confirm-remove"]}`}>
        <div className={`${styles["u-modal__head"]} ${styles["red"]}`}>
          <Icons.WarningFillIcon />
        </div>
        <div className={styles["u-modal__title"]}>
          Are you sure you want to remove access for this member?
        </div>
        <p>
          If you remove access, this member will loose access to all documents
          of this organisation.
        </p>
        <div className={styles["u-modal__foot"]}>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onClose}
              type="white"
              title={"Cancel"}
              customStyles={styles["btn-modal"]}
            />
          </div>
          <div className={styles["u-modal__foot__item"]}>
            <CButton
              onClick={onConfirm}
              type="red"
              title={"Remove access"}
              customStyles={styles["btn-modal"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRemove;

import React, { forwardRef } from "react";
import { Input } from "antd";
import Icon from "../../../Icons";
import { CButton } from "../../../";
import PromptComponent from "../../components/Prompt";
import { useTranslation } from "react-i18next";
import styles from "../../index.module.scss";

const PromptCard = forwardRef(
  (
    {
      previewTemplate,
      currentPrompt,
      isInput,
      currentPromptQuestion,
      setCurrentPromptQuestion,
      onEditPrompt,
      setCurrentPromptDesc,
      currentPromptDesc,
      currentPromptLabel,
      setCurrentPromptLabel,
      documentLanguage,
      visibleIndex,
      onUpdateVariable,
      currentVariable,
      sortedPrompts,
      goForward,
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <div
        style={{
          background: "#fff",
          padding: "32px 24px",
          borderRadius: "24px",
          width: 721,
        }}
      >
        <div className={`${styles["c-block-card__head"]}`}>
          {!previewTemplate && currentPrompt?.questionType === "INFO" && (
            <div
              style={{
                width: 54,
                height: 54,
                background: "#656EE1",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                marginBottom: 24,
              }}
            >
              <Icon.FeatherIcon />
            </div>
          )}

          <div>
            <Input.TextArea
              ref={ref}
              type="text"
              autoSize
              defaultValue={currentPrompt?.question || ""}
              value={currentPromptQuestion}
              onChange={(e) => {
                setCurrentPromptQuestion(e.target.value);
              }}
              onBlur={() => {
                if (currentPrompt?.question !== currentPromptQuestion)
                  onEditPrompt();
              }}
              onPressEnter={(e) => {
                // Unfocus the input on Enter key press
                e.target.blur();
              }}
              placeholder={
                currentPrompt?.question
                  ? currentPrompt?.question
                  : `Your question here.`
              }
              disabled={previewTemplate}
              bordered={false}
              className={styles.questionInput}
              style={{
                fontSize: 28,
                fontWeight: "600",
                lineHeight: "36px",
                width: "100%",
                minWidth: "50px",
                border: "none",
                boxShadow: "none",
                padding: "0",
                caretColor: "#5F6CFF",
                caretWidth: "5px",
                wordWrap: "break-word",
              }}
            />
          </div>

          <div
            className={`${styles["c-block-card__desc"]} ${
              !currentPrompt?.description ? styles["c-block-card__missing"] : ""
            }`}
          >
            <Input.TextArea
              type="text"
              autoSize
              defaultValue={currentPrompt?.description ?? ""}
              value={currentPromptDesc}
              onChange={(e) => {
                setCurrentPromptDesc(e.target.value);
              }}
              onBlur={() => {
                if (currentPrompt?.description !== currentPromptDesc)
                  onEditPrompt();
              }}
              onPressEnter={(e) => {
                // Unfocus the input on Enter key press
                e.target.blur();
              }}
              placeholder={
                currentPrompt?.description || "Description (optional)"
              }
              disabled={previewTemplate}
              bordered={false}
              className={styles.questionInput}
              style={{
                fontSize: 16,
                width: "100%",
                minWidth: "50px",
                border: "none",
                boxShadow: "none",
                padding: "0",
                caretColor: "#5F6CFF",
                caretWidth: "5px",
              }}
            />
          </div>
          {isInput && (
            <div className={styles["c-block-card__label"]}>
              <Input
                type="text"
                defaultValue={currentPrompt?.inputFieldLabel ?? ``}
                value={currentPromptLabel}
                onChange={(e) => {
                  setCurrentPromptLabel(e.target.value);
                }}
                onBlur={() => {
                  if (currentPrompt?.inputFieldLabel !== currentPromptLabel)
                    onEditPrompt();
                }}
                onPressEnter={(e) => {
                  // Unfocus the input on Enter key press
                  e.target.blur();
                }}
                placeholder={
                  currentPrompt?.inputFieldLabel
                    ? currentPrompt?.inputFieldLabel
                    : "[name of data field]"
                }
                bordered={false}
                className={styles.questionInput}
                style={{
                  fontSize: 16,
                  width: "100%",
                  minWidth: "50px",
                  border: "none",
                  boxShadow: "none",
                  fontWeight: 500,
                  padding: "0",
                  caretColor: "#5F6CFF",
                  caretWidth: "5px",
                }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            pointerEvents:
              currentPrompt?.questionType === "SINGLE_SELECT" &&
              !previewTemplate
                ? "auto"
                : "none",
          }}
          className="prompt-card"
        >
          <PromptComponent
            documentLanguage={documentLanguage}
            key={visibleIndex}
            previewTemplate={previewTemplate}
            onEditPrompt={onEditPrompt}
            onUpdateVariable={onUpdateVariable}
            variable={currentVariable}
            {...currentPrompt}
          />
        </div>

        {!!previewTemplate && (
          <>
            <CButton
              type="primary"
              disabled={
                sortedPrompts?.length &&
                sortedPrompts?.indexOf(currentPrompt) ===
                  sortedPrompts?.length - 1
              }
              customStyles={styles["c-block-card__btn"]}
              title={"Continue"}
              onClick={goForward}
            />
            <div className={styles["c-block-card__info"]}>
              {t("wizard:info.with_enter")}
            </div>
          </>
        )}
      </div>
    );
  }
);

export default PromptCard;

import React, { useEffect, useState, useRef } from "react";

import Icons from "../../../components/Icons";

import styles from "../index.module.scss";

const SigneeItem = ({
  userId,
  image,
  initials,
  firstName,
  lastName,
  email,
  documentStyles,
  number,
  onDeletePress = () => {},
  onEditClick = () => {},
  hideSigneeBtn = false,
}) => {
  return (
    <div className={styles["creator-signature__item"]}>
      <div className={styles["creator-signature__card"]}>
        <div
          className={styles["creator-signature__card__logo"]}
          style={{ color: documentStyles?.color }}
          onClick={!hideSigneeBtn ? onEditClick : undefined}
        >
          {image ? <img src={image} alt="" /> : initials}
          {number && (
            <div
              className={styles["creator-signature__card__number"]}
              style={{ backgroundColor: documentStyles?.color }}
            >
              {number || 12}
            </div>
          )}
        </div>

        <div
          className={styles["creator-signature__card__detail"]}
          onClick={!hideSigneeBtn ? onEditClick : undefined}
        >
          <div className={styles["creator-signature__card__name"]}>
            {firstName} {lastName}
          </div>
          <div className={styles["creator-signature__card__email"]}>
            {email}
          </div>
        </div>
        {!hideSigneeBtn && (
          <div
            className={styles["creator-signature__card__cross"]}
            onClick={onDeletePress}
          >
            <Icons.CrossIcon color={documentStyles?.color} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SigneeItem;

import React, { useEffect, useState } from "react";
import { CInput, Icons, CButton } from "../../components";
import { Form, Row, Col } from "antd";
import SigneeItem from "./SigneeItem";

import styles from "./index.module.scss";

export function RecipientModal({
  documentStyles,
  t,
  addRecipients,
  goToModal,
  oldData,
  editRecipients,
}) {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const onFinish = () => {
    const values = form.getFieldsValue();
    const initials = values.firstName.charAt(0) + values.lastName.charAt(0);
    isEditing
      ? editRecipients({
          initials,
          ...values,
          recipientID: oldData?.recipientID,
        })
      : addRecipients({
          initials,
          ...values,
        });
    goToModal(null);
  };

  useEffect(() => {
    const checkFormValidity = async () => {
      if (userHasInteracted) {
        try {
          await form.validateFields();
          setIsFormValid(true); // valid form means button is not disabled
        } catch (errorInfo) {
          setIsFormValid(false); // invalid form means button is disabled
        }
      }
    };

    checkFormValidity();
  }, [form, formValues, userHasInteracted, touchedFields]);

  useEffect(() => {
    if (oldData) {
      form.setFieldsValue({
        firstName: oldData?.firstName,
        lastName: oldData?.lastName,
        email: oldData?.email,
      });
      setIsEditing(true);
      setIsFormValid(true);
    }
  }, [form, oldData]);

  const handleBlur = (field) => {
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  return (
    <div>
      <h1 style={{ marginBottom: 48 }}>
        What are the details of the Recipient?
      </h1>

      <div>
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={onFinish}
          onValuesChange={(changedValues, allValues) => {
            setFormValues(allValues);
            setUserHasInteracted(true);
          }}
        >
          <div style={{ marginBottom: "8px" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        } else if (touchedFields?.firstName) {
                          return Promise.reject(
                            new Error("Please input the signee first name")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CInput
                    label={t("send-contract:signee.labels.first_name")}
                    placeholder={t(
                      "send-contract:signee.placeholders.first_name"
                    )}
                    color={documentStyles?.color}
                    font={documentStyles?.font}
                    onChange={() => handleBlur("firstName")}
                    autoFocus
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ marginBottom: "8px" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  name="lastName"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          return Promise.resolve();
                        } else if (touchedFields?.lastName) {
                          return Promise.reject(
                            new Error("Please input the signee last name")
                          );
                        }
                      },
                    },
                  ]}
                >
                  <CInput
                    label={t("send-contract:signee.labels.last_name")}
                    placeholder={t(
                      "send-contract:signee.placeholders.last_name"
                    )}
                    onChange={() => handleBlur("lastName")}
                    color={documentStyles?.color}
                    font={documentStyles?.font}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message:
                      "Oops! This email address is not valid, please try again. The email must be in 'example@example.com' format.",
                    transform: (value) => value?.trim(),
                  },
                  {
                    validator: (_, value) => {
                      if (value) {
                        return Promise.resolve();
                      } else if (touchedFields?.email) {
                        return Promise.reject(
                          new Error("Please input your E-mail!")
                        );
                      }
                    },
                  },
                ]}
              >
                <CInput
                  label={t("send-contract:signee.labels.work_email")}
                  placeholder={t(
                    "send-contract:signee.placeholders.work_email"
                  )}
                  color={documentStyles?.color}
                  font={documentStyles?.font}
                  onChange={() => handleBlur("email")}
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginTop: 24,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 8,
              }}
            >
              <CButton
                htmlType="submit"
                type="primary"
                disabled={!isFormValid}
                title={t("signee:add")}
                backgroundColor={documentStyles?.color}
                font={documentStyles?.font}
              />

              <div
                style={{
                  display: "flex",
                  fontSize: 12,
                  gap: 4,
                  alignItems: "center",
                }}
              >
                press Enter
                <Icons.Enter size="12" />
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export function Recipients({
  documentStyles,
  t,
  goToModal,
  thisDocument,
  deleteRecipients,
  setOldData,
}) {
  return (
    <div className={styles["c-signsend-status__item"]}>
      <div
        className={styles["c-signsend-status__article"]}
        style={{
          fontFamily: documentStyles?.font,
        }}
      >
        <div
          className={styles["teritary__head"]}
          style={{
            borderBottom: `1px solid ${
              documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
            }`,
          }}
        >
          <div className={styles["teritary__head__title"]}>Recipients</div>
        </div>

        <div className={styles["teritary__body"]}>
          <p>
            To email copies of the document when it’s send for signing, signed
            by anyone, or fully signed.
          </p>

          <div
            className={styles["teritary__card"]}
            style={{
              border: `1px solid ${
                documentStyles?.color ? `${documentStyles.color}33` : "#ecedff"
              }`,
              marginBottom: 12,
            }}
          >
            <div className={styles["teritary__card__body"]}>
              <div className={styles["creator-signature"]}>
                {thisDocument?.recipients?.map((localSigneeData, index) => {
                  return (
                    <SigneeItem
                      key={`signee-item-${index}`}
                      documentStyles={documentStyles}
                      initials={
                        localSigneeData?.initials
                          ? localSigneeData?.initials
                          : `${localSigneeData?.contact?.firstName?.[0]?.toUpperCase()}${localSigneeData?.contact?.lastName?.[0]?.toUpperCase()}`
                      }
                      firstName={localSigneeData?.firstName}
                      lastName={localSigneeData?.lastName}
                      email={localSigneeData?.email}
                      image={localSigneeData?.image}
                      number={
                        localSigneeData?.showOrder
                          ? localSigneeData?.order
                          : null
                      }
                      onDeletePress={() => {
                        deleteRecipients(localSigneeData?.recipientID);
                      }}
                      onEditClick={() => {
                        setOldData(localSigneeData);
                        goToModal("recipients");
                      }}
                    />
                  );
                })}
                <div
                  className={styles["creator-signature__item"]}
                  onClick={() => {
                    setOldData(null);
                    goToModal("recipients");
                  }}
                >
                  <div className={styles["creator-signature__card"]}>
                    <div
                      className={`${styles["creator-signature__card__logo"]} ${styles["add-signee"]}`}
                    >
                      <Icons.PlusCreatorIcon color={documentStyles?.color} />
                    </div>
                    <div className={styles["creator-signature__card__detail"]}>
                      <div className={styles["creator-signature__card__name"]}>
                        Add recipient
                      </div>
                      <div className={styles["creator-signature__card__info"]}>
                        Add a rule for a tag to define
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

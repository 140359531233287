import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import styles from "./index.module.scss";

const { Option } = Select;

const arrayItems = ["Germany"];

const CSelect = ({
  onBlur = () => {},
  onChange = () => {},
  label = false,
  errors = "",
  customStyles = "",
  mode = false,
  showSearch = false,
  options = arrayItems,
  chosenOption,
  defaultValue = chosenOption !== undefined ? chosenOption : null,
  focusOn = false,
  onSelect = () => {},
  onClick = () => {},
  placeholder,
  disabled = false,
  value,
  tooltip,
  isFonts = false,
  font,
  color,
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);

    return () => window.removeEventListener("keydown", handleKeydown);
  });

  useEffect(() => {
    // for affect default value in form if not changed
    if (chosenOption !== null || chosenOption !== undefined) {
      onChange(chosenOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenOption]);

  useEffect(() => {
    if (color) {
      const selectors = document.getElementsByClassName("ant-select-selector");

      for (let i = 0; i < selectors.length; i++) {
        if (selectors[i].offsetParent !== null) {
          if (isHover || isFocus) {
            selectors[i].style.borderColor = color;

            if (isFocus) {
              selectors[i].style.boxShadow = `0px 0px 0px 4px ${color}33`;
              selectors[i].focus();
            } else {
              selectors[i].style.boxShadow = `none`;
            }
          } else {
            selectors[i].style.borderColor = "#d9d9d9";
            selectors[i].style.boxShadow = `none`;
          }
        }
      }
    }
  }, [isHover, isFocus]);

  useEffect(() => {
    if (color) {
      setStyles();
    }
  });

  const handleKeydown = (event) => {
    if (color && (event.keyCode === 38 || event.keyCode === 40)) {
      setStyles();
    }
  };

  const setStyles = () => {
    const options = document.getElementsByClassName("option");

    for (let i = 0; i < options.length; i++) {
      options[i].style.background = "#fff";
      options[i].style.color = "#000";
    }

    const optionsActive = document.getElementsByClassName(
      "ant-select-item-option-active"
    );

    for (let i = 0; i < optionsActive.length; i++) {
      optionsActive[i].style.background = `${color}33`;
      optionsActive[i].style.color = color;
    }

    const optionsSelected = document.getElementsByClassName(
      "ant-select-item-option-selected"
    );

    for (let i = 0; i < optionsSelected.length; i++) {
      optionsSelected[i].style.background = `${color}33`;
      optionsSelected[i].style.color = color;
    }
  };

  return (
    <div
      className={styles["u-select-wrap"]}
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={() => {
        setIsFocus(false);
      }}
      onClick={onClick}
    >
      {label && (
        <label className={styles["u-style-label"]} style={{ fontFamily: font }}>
          {label} {tooltip}
        </label>
      )}
      <Select
        placeholder={placeholder}
        className={[
          "u-style-input",
          styles["u-style-input"],
          errors && styles["u-style-input__error"],
          customStyles,
        ].join(" ")}
        showSearch={showSearch}
        optionFilterProp="children"
        mode={mode}
        onChange={(value) => {
          onChange(value);
        }}
        defaultValue={defaultValue}
        ref={(input) => {
          if (focusOn) {
            focusOn
              ? input && input.focus() //focus on the field if "true" is transmitted
              : input && input.blur(); //make the field inactive if "false" is passed
          }
        }}
        onSelect={() => {
          onSelect();
        }}
        onClick={onClick}
        disabled={disabled}
        value={value}
        autoComplete="none"
        onBlur={() => {
          onBlur();
        }}
        {...props}
      >
        {options.map((item, index) => {
          return (
            <Option
              key={index}
              value={
                typeof item?.value === "object"
                  ? JSON.stringify(item?.value)
                  : item?.value || item || undefined
              }
              defaultChecked={false}
              style={
                isFonts
                  ? {
                      fontFamily: item?.value || item,
                    }
                  : {
                      fontFamily: font,
                    }
              }
              className={`option`}
            >
              {item?.icon || null}{" "}
              {item?.label ? item?.label : item?.value || item}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

CSelect.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  errors: PropTypes.string,
  customStyles: PropTypes.string,
  mode: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  showSearch: PropTypes.bool,
  options: PropTypes.array,
  chosenOption: PropTypes.string,
  defaultValue: PropTypes.string,
  focusOn: PropTypes.bool,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltip: PropTypes.string,
  isFonts: PropTypes.bool,
  font: PropTypes.string,
  color: PropTypes.string,
};

export default CSelect;

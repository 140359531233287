import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as keys from "../../utils/keys";

import styles from "./index.module.scss";
import {
  BookOutlined,
  RightOutlined,
  LeftOutlined,
  FolderOutlined,
} from "@ant-design/icons";

const TemplateWorkspaceSidebar = () => {
  const { pathname } = useLocation();

  return (
    <aside className={styles["c-workspace-sidebar"]}>
      <div
        style={{ position: "fixed", width: "252px" }}
        className={styles["c-workspace-sidebar__main"]}
      >
        <Link
          to={keys.DASHBOARD}
          className={`${styles["c-workspace-sidebar__link"]} ${
            pathname === "/" && styles["active"]
          }`}
        >
          <span className={styles["c-workspace-sidebar__link__icon"]}>
            <LeftOutlined />
          </span>
          <span className={styles["c-workspace-sidebar__link__text"]}>
            Back
          </span>
        </Link>
        <Link
          to={keys.TEMPLATES}
          className={`${styles["c-workspace-sidebar__link"]} ${styles["active"]}`}
        >
          <span className={styles["c-workspace-sidebar__link__icon"]}>
            <FolderOutlined
              style={{
                fontSize: 20,
              }}
            />
          </span>
          <span className={styles["c-workspace-sidebar__link__text"]}>
            All templates
          </span>
        </Link>
      </div>
      {/* <div>
        <Link
          to={keys.TEMPLATES}
          className={
            styles["c-workspace-sidebar__link"]
          }
        >
          <span className={styles["c-workspace-sidebar__link__icon"]}>
            <BookOutlined/>
          </span>
          <span className={styles["c-workspace-sidebar__link__text"]}>
            Manage templates
          </span>
          <span>
            <RightOutlined/>
          </span>
        </Link>
      </div> */}
    </aside>
  );
};

export default TemplateWorkspaceSidebar;

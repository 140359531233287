import React from "react";
import PropTypes from "prop-types";

const CancelCircleIcon = ({ color = "currentColor", size = 20, className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1480_2949)">
        <path
          d="M13.8739 6.49205C13.8739 6.39383 13.7935 6.31348 13.6953 6.31348L12.2221 6.32017L10.0033 8.96526L7.78683 6.32241L6.31138 6.31571C6.21317 6.31571 6.13281 6.39383 6.13281 6.49428C6.13281 6.53669 6.14844 6.57687 6.17522 6.61035L9.07924 10.0702L6.17522 13.5278C6.14825 13.5605 6.13328 13.6014 6.13281 13.6438C6.13281 13.742 6.21317 13.8224 6.31138 13.8224L7.78683 13.8157L10.0033 11.1706L12.2199 13.8135L13.6931 13.8202C13.7913 13.8202 13.8717 13.742 13.8717 13.6416C13.8717 13.5992 13.856 13.559 13.8292 13.5255L10.9297 10.0679L13.8337 6.60812C13.8605 6.57687 13.8739 6.53446 13.8739 6.49205Z"
          fill={color}
        />
        <path
          d="M10 0.0219727C4.47768 0.0219727 0 4.49965 0 10.022C0 15.5443 4.47768 20.022 10 20.022C15.5223 20.022 20 15.5443 20 10.022C20 4.49965 15.5223 0.0219727 10 0.0219727ZM10 18.3255C5.41518 18.3255 1.69643 14.6068 1.69643 10.022C1.69643 5.43715 5.41518 1.7184 10 1.7184C14.5848 1.7184 18.3036 5.43715 18.3036 10.022C18.3036 14.6068 14.5848 18.3255 10 18.3255Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1480_2949">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

CancelCircleIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default CancelCircleIcon;

import React from "react";
import PropTypes from "prop-types";

const StatusDraftIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62528 15.3667C6.65247 15.3667 6.67965 15.3639 6.70684 15.3599L8.99307 14.9589C9.02026 14.9534 9.04608 14.9412 9.06511 14.9208L14.8269 9.15842C14.8395 9.14584 14.8495 9.1309 14.8563 9.11446C14.8632 9.09801 14.8667 9.08038 14.8667 9.06258C14.8667 9.04478 14.8632 9.02715 14.8563 9.0107C14.8495 8.99426 14.8395 8.97932 14.8269 8.96675L12.5679 6.70611C12.542 6.68028 12.5081 6.66669 12.4714 6.66669C12.4347 6.66669 12.4007 6.68028 12.3749 6.70611L6.61305 12.4685C6.59266 12.4889 6.58043 12.5133 6.57499 12.5405L6.17401 14.827C6.16079 14.8998 6.16552 14.9748 6.18778 15.0454C6.21004 15.1159 6.24917 15.18 6.30178 15.2321C6.39149 15.3191 6.50431 15.3667 6.62528 15.3667Z"
        fill={color}
      />
    </svg>
  );
};

StatusDraftIcon.propTypes = {
  color: PropTypes.string,
};

export default StatusDraftIcon;

import initialState from "../initialStates";
import injectReducer from "../injectReducer";

import { DASHBOARD } from "../types";

export default injectReducer(initialState.dashboardReducer, {
  [DASHBOARD.SET_ACTIVE_FOLDER]: (state, { payload: { folderId } }) => {
    return { ...state, activeFolder: folderId };
  },

  [DASHBOARD.SAVE_FILE]: (state) => ({
    ...state,
  }),
  [DASHBOARD.SAVE_FILE_SUCCESS]: (state, { payload: { response } }) => {
    return { ...state };
  },
  [DASHBOARD.SAVE_FILE_FAILURE]: (state) => ({
    ...state,
  }),

  [DASHBOARD.RESET_PASSWORD]: (state) => ({
    ...state,
  }),
  [DASHBOARD.RESET_PASSWORD_SUCCESS]: (state, { payload: { response } }) => {
    return { ...state };
  },
  [DASHBOARD.RESET_PASSWORD_FAILURE]: (state) => ({
    ...state,
  }),
});

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import { CSelect, CInput } from "../../../components";
import { Countries } from "../../../countries";

const Address = ({
  groupId = "",
  placeholder,
  label,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  addressBeforeConfirm,
  setAddressBeforeConfirm,
  form,
  setDefaultCardData,
  position,
  visibleIndex,
  cardData,
  prompts,
  prompt,
  documentStyles,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###country`}>
            <CSelect
              label={t("wizard:labels.country_region")}
              showSearch={true}
              focusOn={numberOfInputForFocus === 0}
              onSelect={() => {
                setTimeout(() => {
                  setNumberOfInputForFocus(1);
                }, 100);
              }}
              onClick={() => {
                setNumberOfInputForFocus(0);
              }}
              options={Countries.map((item) => item.name)}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###streetAddress`}>
            <CInput
              label={t("wizard:labels.street_name")}
              placeholder={placeholder?.address?.streetAddress ?? ""}
              onChange={() => {
                setNumberOfInputForFocus(1);
              }}
              onClick={() => {
                setNumberOfInputForFocus(1);
              }}
              focusOn={numberOfInputForFocus === 1}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" span={24}>
          <Form.Item name={`${groupId}###additionalAddressLine`}>
            <CInput
              label={t("wizard:labels.additional")}
              placeholder={placeholder?.address?.additionalAddressLine ?? ""}
              onChange={() => {
                setNumberOfInputForFocus(2);
              }}
              onClick={() => {
                setNumberOfInputForFocus(2);
              }}
              focusOn={numberOfInputForFocus === 2}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###city`}>
            <CInput
              label={t("wizard:labels.city")}
              name="city"
              placeholder={
                placeholder?.address?.city ?? t("wizard:placeholders.building")
              }
              onChange={() => {
                setNumberOfInputForFocus(3);
              }}
              onClick={() => {
                setNumberOfInputForFocus(3);
              }}
              focusOn={numberOfInputForFocus === 3}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###postalCode`}>
            <CInput
              label={t("wizard:labels.zipcode")}
              placeholder={placeholder?.address?.postalCode ?? ""}
              // type="number"
              onChange={() => {
                setNumberOfInputForFocus(4);
              }}
              onClick={() => {
                setNumberOfInputForFocus(4);
              }}
              focusOn={numberOfInputForFocus === 4}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Address;

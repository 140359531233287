import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./index.module.scss";
import Signature from "./Signature";

const Insights = ({ data = [], signeeData = [], title, headerStyles }) => {
  return (
    <div>
      <div className={[styles["c-insights__header"], headerStyles].join(" ")}>
        <h1>{title || "Details"}</h1>
      </div>
      {signeeData
        .filter((item) => item.status === "SIGNED")
        .sort(
          (item, nextItem) =>
            moment(nextItem?.signature?.createdAt) -
            moment(item?.signature?.createdAt)
        )
        .map((item) => (
          <Signature data={item} />
        ))}
      {data.map((elem, indexMain) => {
        return (
          <>
            <div
              key={`insight-${indexMain}`}
              className={[
                styles["c-insights__group"],
                styles["c-insights__group__item"],
                styles["c-insights__group__article"],
              ].join(" ")}
            >
              <div
                className={[
                  styles["c-insights__group__head"],
                  styles["c-insights__step"],
                ].join(" ")}
              >
                <div className={styles["c-insights__step__number"]}>
                  {elem.insightGroupPosition}
                </div>
                <div className={styles["c-insights__step__text"]}>
                  {elem.insightGroupName}
                </div>
              </div>

              {elem.insights.map((item, indexEl) => {
                if (item.content !== "") {
                  return (
                    <div
                      key={`insightEl-${indexEl}`}
                      className={[
                        styles["c-insights__group__body"],
                        styles["c-insights__list"],
                      ].join(" ")}
                    >
                      <div className={styles["c-insights__list__item"]}>
                        {item?.title}
                      </div>

                      <div className={styles["c-insights__list__item"]}>
                        {item?.content}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </>
        );
      })}
    </div>
  );
};

Insights.propTypes = {
  data: PropTypes.array,
  signeeData: PropTypes.array,
  title: PropTypes.string,
  headerStyles: PropTypes.object,
};

export default Insights;

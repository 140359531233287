import React from "react";
import PropTypes from "prop-types";

const NumberQuestion = ({
  color = "#5F6CFF",
  circleColor = "#ECEDFF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill="#ECEDFF" />
      <rect
        x="3.1875"
        y="3.1875"
        width="9.625"
        height="9.625"
        rx="3.66667"
        fill={color}
      />
      <path
        d="M7.97734 10.2333H8.81266V5.90205H7.97116L6.89453 6.70643V7.5108H6.95641L7.95259 6.75593H7.97734V10.2333Z"
        fill={circleColor}
      />
    </svg>
  );
};

NumberQuestion.propTypes = {
  color: PropTypes.string,
};

export default NumberQuestion;

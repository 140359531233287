import React from "react";
import PropTypes from "prop-types";

const WarningFillIconWithWhiteBackground = ({
  color = "currentColor",
  className,
  width = "48",
  height = "42",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.3991 18.0625L11.6491 1.1875C11.5038 0.936719 11.253 0.8125 10.9999 0.8125C10.7467 0.8125 10.4936 0.936719 10.3506 1.1875L0.600643 18.0625C0.312362 18.5641 0.673299 19.1875 1.24986 19.1875H20.7499C21.3264 19.1875 21.6874 18.5641 21.3991 18.0625ZM10.2499 7.75C10.2499 7.64687 10.3342 7.5625 10.4374 7.5625H11.5624C11.6655 7.5625 11.7499 7.64687 11.7499 7.75V12.0625C11.7499 12.1656 11.6655 12.25 11.5624 12.25H10.4374C10.3342 12.25 10.2499 12.1656 10.2499 12.0625V7.75ZM10.9999 16C10.7055 15.994 10.4252 15.8728 10.2191 15.6625C10.013 15.4522 9.89759 15.1695 9.89759 14.875C9.89759 14.5805 10.013 14.2978 10.2191 14.0875C10.4252 13.8772 10.7055 13.756 10.9999 13.75C11.2943 13.756 11.5746 13.8772 11.7806 14.0875C11.9867 14.2978 12.1021 14.5805 12.1021 14.875C12.1021 15.1695 11.9867 15.4522 11.7806 15.6625C11.5746 15.8728 11.2943 15.994 10.9999 16Z"
        fill={color}
      />
      <path
        d="M10.2499 7.75C10.2499 7.64687 10.3342 7.5625 10.4374 7.5625H11.5624C11.6655 7.5625 11.7499 7.64687 11.7499 7.75V12.0625C11.7499 12.1656 11.6655 12.25 11.5624 12.25H10.4374C10.3342 12.25 10.2499 12.1656 10.2499 12.0625V7.75Z"
        fill="white"
      />
      <path
        d="M10.9999 16C10.7055 15.994 10.4252 15.8728 10.2191 15.6625C10.013 15.4522 9.89759 15.1695 9.89759 14.875C9.89759 14.5805 10.013 14.2978 10.2191 14.0875C10.4252 13.8772 10.7055 13.756 10.9999 13.75C11.2943 13.756 11.5746 13.8772 11.7806 14.0875C11.9867 14.2978 12.1021 14.5805 12.1021 14.875C12.1021 15.1695 11.9867 15.4522 11.7806 15.6625C11.5746 15.8728 11.2943 15.994 10.9999 16Z"
        fill="white"
      />
    </svg>
  );
};

WarningFillIconWithWhiteBackground.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default WarningFillIconWithWhiteBackground;

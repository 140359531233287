import React from "react";
import PropTypes from "prop-types";

const PaperClipIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.1587 2.30635C14.6355 -0.216863 10.5266 -0.216863 8.00605 2.30635L1.01498 9.29207C0.969445 9.3376 0.945337 9.39921 0.945337 9.46349C0.945337 9.52778 0.969445 9.58939 1.01498 9.63492L2.00337 10.6233C2.04855 10.6683 2.10971 10.6936 2.17346 10.6936C2.23722 10.6936 2.29837 10.6683 2.34355 10.6233L9.33462 3.6376C10.2025 2.76974 11.3569 2.29296 12.5837 2.29296C13.8105 2.29296 14.965 2.76974 15.8302 3.6376C16.698 4.50546 17.1748 5.65992 17.1748 6.88403C17.1748 8.11082 16.698 9.2626 15.8302 10.1305L8.70516 17.2528L7.55069 18.4072C6.47123 19.4867 4.71677 19.4867 3.6373 18.4072C3.11498 17.8849 2.82837 17.1912 2.82837 16.4519C2.82837 15.7126 3.11498 15.0189 3.6373 14.4965L10.7061 7.43046C10.8855 7.25367 11.1212 7.15457 11.373 7.15457H11.3757C11.6275 7.15457 11.8605 7.25367 12.0373 7.43046C12.2168 7.60992 12.3132 7.84564 12.3132 8.09742C12.3132 8.34653 12.2141 8.58224 12.0373 8.75903L6.25962 14.5314C6.21409 14.5769 6.18998 14.6385 6.18998 14.7028C6.18998 14.7671 6.21409 14.8287 6.25962 14.8742L7.24802 15.8626C7.29319 15.9076 7.35435 15.9328 7.4181 15.9328C7.48186 15.9328 7.54302 15.9076 7.58819 15.8626L13.3632 10.0876C13.8962 9.55457 14.1882 8.84742 14.1882 8.09474C14.1882 7.34207 13.8935 6.63224 13.3632 6.10189C12.2623 5.00099 10.473 5.00367 9.37212 6.10189L8.68641 6.79028L2.30605 13.168C1.87301 13.5985 1.52974 14.1107 1.29616 14.6748C1.06258 15.239 0.943337 15.8439 0.945337 16.4546C0.945337 17.6947 1.43016 18.8599 2.30605 19.7358C3.21409 20.6412 4.40337 21.0939 5.59266 21.0939C6.78194 21.0939 7.97123 20.6412 8.87659 19.7358L17.1587 11.459C18.3775 10.2376 19.0525 8.61171 19.0525 6.88403C19.0552 5.15367 18.3802 3.52778 17.1587 2.30635Z"
        fill={color}
      />
    </svg>
  );
};

PaperClipIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default PaperClipIcon;

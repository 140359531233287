import React, { useEffect } from "react";

import { Icons, CButton } from "../../../components";
import styles from "../index.module.scss";

const Welcome = (props) => {
  const { onClose } = props;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13 || event.key === "Enter") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className={styles["u-overlay"]}>
      <div className={`${styles["u-modal"]} ${styles["has-welcome"]}`}>
        <div className={styles["u-modal__welcome"]}>
          <div className={styles["u-modal__welcome__icon"]}>
            <Icons.FeatherIcon />
          </div>

          <div className={styles["u-modal__welcome__title"]}>
            Welcome to Swiftsign! It’s great to have you.
          </div>

          <div className={styles["u-modal__welcome__desc"]}>
            Let’s start creating your first document so you can save time and
            <br /> focus on growth.
          </div>

          <CButton
            onClick={onClose}
            type="primary"
            title={"Start creating"}
            customStyles={styles["btn-start-creating"]}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;

import React from "react";
import PropTypes from "prop-types";

const FileIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.2797 5.66082L11.2359 0.568577C11.0953 0.426599 10.9055 0.346146 10.7062 0.346146H0.75C0.335156 0.346146 0 0.684525 0 1.10336V20.7909C0 21.2097 0.335156 21.5481 0.75 21.5481H15.75C16.1648 21.5481 16.5 21.2097 16.5 20.7909V6.19797C16.5 5.99684 16.4203 5.8028 16.2797 5.66082ZM10.3594 2.09246L14.7703 6.54581H10.3594V2.09246ZM14.8125 19.8443H1.6875V2.04987H8.76562V7.16105C8.76562 7.42463 8.86934 7.67742 9.05394 7.8638C9.23855 8.05018 9.48893 8.15489 9.75 8.15489H14.8125V19.8443Z"
        fill={color}
      />
      <path
        d="M3.5625 10.4265V11.5623C3.5625 11.6665 3.64688 11.7516 3.75 11.7516H12.75C12.8531 11.7516 12.9375 11.6665 12.9375 11.5623V10.4265C12.9375 10.3224 12.8531 10.2372 12.75 10.2372H3.75C3.64688 10.2372 3.5625 10.3224 3.5625 10.4265ZM8.0625 13.4554H3.75C3.64688 13.4554 3.5625 13.5406 3.5625 13.6447V14.7805C3.5625 14.8846 3.64688 14.9698 3.75 14.9698H8.0625C8.16563 14.9698 8.25 14.8846 8.25 14.7805V13.6447C8.25 13.5406 8.16563 13.4554 8.0625 13.4554Z"
        fill={color}
      />
    </svg>
  );
};

FileIcon.propTypes = {
  color: PropTypes.string,
};

export default FileIcon;

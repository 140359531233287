import React, { useState, useEffect, useRef } from "react";
import { saveAs } from "file-saver";

import { WorkspaceCard, Modals } from "..";

import styles from "./index.module.scss";

const WorkspaceTabs = ({
  documents,
  updateDocument,
  deleteDocument,
  getSortedDocuments,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  loadDocumentPdf,
  setErrorLoadingScreenType,
}) => {
  const documentsRefs = useRef([]);

  const [showModal, setShowModal] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [nowEditing, setNowEditing] = useState(null);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  });

  useEffect(() => {
    documentsRefs.current = documentsRefs.current.slice(0, documents);
  }, [documents]);

  const handleClick = (e) => {
    if (activeDropdown) {
      setActiveDropdown(null);
    }

    if (!!nowEditing) {
      for (let i = 0; i < documents.length; i++) {
        if (
          !!documentsRefs?.current[i] &&
          documentsRefs?.current[i].id === nowEditing &&
          !documentsRefs?.current[i].contains(e.target)
        ) {
          setNowEditing(null);
        }
      }
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    for (let i = 0; i < documents.length; i++) {
      if (
        !!documentsRefs?.current[i] &&
        documentsRefs?.current[i].contains(event.target)
      ) {
        setActiveDropdown(documentsRefs.current[i]?.id);
      }
    }

    setNowEditing(null);
  };

  return (
    <>
      <div className={styles["c-workspace-tabs"]}>
        <div className={styles["c-workspace-tabs__head"]}>
          <ul>
            <li>
              <span
                data-tab-head={"recently-edited"}
                className={styles["active"]}
              >
                Recently edited
              </span>
            </li>
            {/* <li>
              <span data-tab-head={"updates"}>Updates</span>
            </li> */}
          </ul>
        </div>
        <div className={styles["c-workspace-tabs__body"]}>
          <div
            data-tab-body={"recently-edited"}
            className={`${styles["table"]} ${styles["active"]}`}
          >
            <div className={styles["thead"]}>
              <div className={styles["tr"]}>
                <div className={`${styles["th"]} ${styles["th-title"]}`}>
                  TITLE
                </div>
                <div className={`${styles["th"]} ${styles["th-last-update"]}`}>
                  Last UPDATE
                </div>
                <div className={`${styles["th"]} ${styles["th-status"]}`}>
                  STATUS
                </div>
                <div className={`${styles["th"]} ${styles["th-parties"]}`}>
                  SIGNEES
                </div>
                <div className={`${styles["th"]} ${styles["th-action"]}`}>
                  ACTIONS
                </div>
              </div>
            </div>
            <div className={styles["tbody"]}>
              {!!documents &&
                documents.map((item, index) => {
                  return (
                    <div key={index} className={styles["item"]}>
                      <WorkspaceCard
                        ref={(el) => (documentsRefs.current[index] = el)}
                        el={index}
                        item={item}
                        handleExport={() => {
                          loadDocumentPdf(
                            item.documentID,
                            item.passcode,
                            (link) => {
                              saveAs(link, `${item?.fileTitle}.pdf`);
                            }
                          );
                        }}
                        handleDelete={() => setShowModal(item.documentID)}
                        onOptionsPress={() => {
                          if (activeDropdown !== item.documentID) {
                            setActiveDropdown(item.documentID);
                          } else {
                            setActiveDropdown(null);
                          }
                        }}
                        isDropdownActive={activeDropdown === item.documentID}
                        nowEditing={nowEditing}
                        setNowEditing={setNowEditing}
                        updateDocument={updateDocument}
                        getSortedDocuments={getSortedDocuments}
                        isLastCard={index === documents.length - 1}
                        getDocument={getDocument}
                        setIsCreatingDocument={setIsCreatingDocument}
                        setIndex={setIndex}
                        setErrorLoadingScreenType={setErrorLoadingScreenType}
                      />
                    </div>
                  );
                })}
              {showModal && (
                <Modals.ConfirmDelete
                  onClose={async () => {
                    await deleteDocument(showModal);
                    await getSortedDocuments("LAST_UPDATED");
                    setShowModal(false);
                  }}
                  onCancel={() => {
                    setShowModal(false);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkspaceTabs;

import React from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import {
  documentSelector,
  newDocumentdataSelector,
} from "../../store/selectors/prompts";

const DocumentTitle = ({ stylesForDocument }) => {
  const thisDocument = useSelector(documentSelector);
  const newDocumentData = useSelector(newDocumentdataSelector);

  return (
    <div className={styles["c-review-status__head"]}>
      <div className={styles["c-review-status__head__logo"]}>
        {thisDocument?.style?.logo && (
          <img src={thisDocument.style.logo} alt="" />
        )}
      </div>
      <div
        className={styles["c-review-status__head__title"]}
        style={{ fontFamily: stylesForDocument?.font }}
      >
        {newDocumentData?.title || thisDocument?.title}
      </div>
      <div
        className={styles["c-review-status__head__date"]}
        style={{ fontFamily: stylesForDocument?.font }}
      >
        {moment(thisDocument?.createdAt).format("DD.MM.YYYY")}
      </div>
    </div>
  );
};

export default DocumentTitle;

import React from "react";
import { Icons } from "../../index";
import styles from "../styles.module.scss";

export const TogglePreviewTemplate = ({ onToggle, style, isTemplateOpen }) => {
  return (
    <button
      className={`${styles.toggleSuggestionsButton} ${
        isTemplateOpen ? styles.toggleSuggestionsButton__active : ""
      }`}
      onClick={onToggle}
      {...{ style }}
    >
      <Icons.EyeIcon color="currentColor" />
    </button>
  );
};

export default TogglePreviewTemplate;

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./store";
import Auth0Wrapper from "./utils/auth0Wrapper";

import { APP_URL, AUTH } from "./constants/config";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./utils/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH.DOMAIN}
      clientId={AUTH.CLIENT_ID}
      redirectUri={APP_URL}
      audience={AUTH.AUDIENCE}
    >
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Auth0Wrapper>
              <App />
            </Auth0Wrapper>
          </PersistGate>
        </Provider>
      </I18nextProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import PropTypes from "prop-types";

const LightBulbIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="28"
      viewBox="0 0 20 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 0.666656C4.85332 0.666656 0.666656 4.85332 0.666656 9.99999C0.666656 13.1733 2.25332 15.96 4.66666 17.6533V20.6667C4.66666 21.4 5.26666 22 5.99999 22H14C14.7333 22 15.3333 21.4 15.3333 20.6667V17.6533C17.7467 15.96 19.3333 13.1733 19.3333 9.99999C19.3333 4.85332 15.1467 0.666656 9.99999 0.666656ZM5.99999 26C5.99999 26.7333 6.59999 27.3333 7.33332 27.3333H12.6667C13.4 27.3333 14 26.7333 14 26V24.6667H5.99999V26ZM12.6667 16.2667L13.8 15.4667C15.6 14.2133 16.6667 12.1733 16.6667 9.99999C16.6667 6.31999 13.68 3.33332 9.99999 3.33332C6.31999 3.33332 3.33332 6.31999 3.33332 9.99999C3.33332 12.1733 4.39999 14.2133 6.19999 15.4667L7.33332 16.2667V19.3333H12.6667V16.2667Z"
      />
    </svg>
  );
};

LightBulbIcon.propTypes = {
  color: PropTypes.string,
};

export default LightBulbIcon;

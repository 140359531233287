import React from "react";
import PropTypes from "prop-types";

const ExportIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="25"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.1762 6.51607L13.4119 0.751786C13.2512 0.591071 13.0342 0.5 12.8065 0.5H1.42794C0.953837 0.5 0.570801 0.883036 0.570801 1.35714V23.6429C0.570801 24.117 0.953837 24.5 1.42794 24.5H18.5708C19.0449 24.5 19.4279 24.117 19.4279 23.6429V7.12411C19.4279 6.89643 19.3369 6.67679 19.1762 6.51607ZM17.4512 7.51786H12.4101V2.47679L17.4512 7.51786ZM17.4994 22.5714H2.49937V2.42857H10.5887V8.21429C10.5887 8.51266 10.7072 8.7988 10.9182 9.00978C11.1291 9.22076 11.4153 9.33929 11.7137 9.33929H17.4994V22.5714Z"
        fill={color}
      />
      <path
        d="M4.03301 20.8679H4.95776V19.2479H6.05801C7.00976 19.2479 7.61726 18.5864 7.61726 17.6819C7.61726 16.8044 7.02326 16.1429 6.05801 16.1429H4.03301V20.8679ZM4.95776 18.4176V16.9664H5.88926C6.38201 16.9664 6.67226 17.2229 6.67226 17.6819C6.67226 18.1746 6.40226 18.4176 5.88926 18.4176H4.95776ZM8.41655 20.8679H10.0298C11.4878 20.8679 12.3721 19.8216 12.3721 18.5054C12.3721 17.0879 11.4203 16.1429 10.0298 16.1429H8.41655V20.8679ZM9.2873 20.0444V16.9731H9.96905C10.9073 16.9731 11.4271 17.5266 11.4271 18.5054C11.4271 19.4909 10.9073 20.0444 9.96905 20.0444H9.2873ZM13.1692 20.8679H14.094V18.9711H16.2067V18.1409H14.094V16.9731H16.4227V16.1429H13.1692V20.8679Z"
        fill={color}
      />
    </svg>
  );
};

ExportIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default ExportIcon;

import React from "react";
import PropTypes from "prop-types";

const ArrowRightIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M10.6629 8.43801L0.600448 0.578633C0.574155 0.557932 0.542555 0.545068 0.50928 0.541517C0.476004 0.537966 0.442402 0.543872 0.412332 0.558557C0.382262 0.573243 0.356944 0.596111 0.339285 0.624537C0.321627 0.652963 0.312343 0.685794 0.312502 0.719258V2.4447C0.312502 2.55408 0.363841 2.65899 0.448663 2.72595L8.48438 9.00051L0.448663 15.2751C0.361609 15.342 0.312502 15.4469 0.312502 15.5563V17.2818C0.312502 17.4313 0.484377 17.5139 0.600448 17.4224L10.6629 9.56301C10.7485 9.49629 10.8177 9.41096 10.8652 9.31349C10.9128 9.21601 10.9376 9.10898 10.9376 9.00051C10.9376 8.89204 10.9128 8.785 10.8652 8.68753C10.8177 8.59006 10.7485 8.50472 10.6629 8.43801Z"
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  color: PropTypes.string,
};

export default ArrowRightIcon;

export function TemplateGeneratorLikeSwitch({
  name,
  leftText,
  rightText,
  inputRef,
  onChange,
  value,
}) {
  return (
    <div className="template-switch">
      <label className="template-switch__label">
        <input value={value} onChange={onChange} ref={inputRef} type="checkbox" name={name} id={name}/>
        <label className="template-switch__label__inner" htmlFor={name} data-on={rightText} data-off={leftText}></label>
      </label>
    </div>
  )
}
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { redirectParamsSelector } from "../../store/selectors/auth";
import { availableHeaderStepSelector } from "../../store/selectors/prompts";

import * as keys from "../../utils/keys";

import styles from "./index.module.scss";

const TemplateStepTopBar = ({
  color,
  font,
  currentId,
  availableStep,
  redirectParams,
  canClickTab,
  data,
  gotToStep,
}) => {
  const { t } = useTranslation();

  const defaultData = data ?? [
    {
      name: t("wizard:header.create"),
      id: 1,
      position: 1,
    },
    {
      name: t("wizard:header.review"),
      id: 2,
      position: 2,
    },
    {
      name: t("wizard:header.sign_and_send"),
      id: 3,
      position: 3,
    },
  ];

  const li = {
    boxShadow: `inset 0 -2px 0 ${color}`,
  };

  return (
    <nav className={styles["step-top-bar"]}>
      <ul>
        {defaultData.map(({ id, position, name, onClick }) => (
          <li
            key={id}
            className={
              id === currentId
                ? styles[`${"current"}`]
                : id < currentId
                ? styles[`${"completed"}`]
                : ""
            }
            style={
              id === currentId && window.innerWidth < 1023 && color
                ? li
                : undefined
            }
            onClick={() => {
              if (onClick) {
                onClick();
                return;
              }

              if (typeof position !== "number") return;

              gotToStep(position);
            }}
          >
            <span className={styles["step-top-bar__box"]}>
              {position && (
                <span className={styles["step-top-bar__number"]}>
                  <i
                    style={{
                      color:
                        id === currentId || id < currentId ? color : undefined,
                      fontFamily: font,
                    }}
                  >
                    {position}
                  </i>
                </span>
              )}
              <span
                className={styles["step-top-bar__text"]}
                style={{
                  color: id === currentId || id < currentId ? color : undefined,
                  fontFamily: font,
                }}
              >
                {name}
              </span>
            </span>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  availableStep: availableHeaderStepSelector(state),
  redirectParams: redirectParamsSelector(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TemplateStepTopBar);

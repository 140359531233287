import { message, notification } from "antd";
import { Icons } from "../components";

export default () => (next) => (action) => {
  // if (action.type.includes("FAILURE") && action?.payload) {
  //   message.error(action.payload.message);
  // }

  if (
    action?.payload &&
    action.payload?.message &&
    action.payload.message?.showMessage
  ) {
    notification.info({
      message: action.payload.message.messageTitle,
      description: action.payload.message.message,
      placement: "bottom",
      duration: 5,
      icon: action.type.includes("FAILURE") ? (
        <Icons.WarningFillIconWithWhiteBackground
          color={"#E53935"}
          width={"22"}
          height={"20"}
        />
      ) : (
        <Icons.StatusAcceptedIconWithWhiteBackground color={"#42B983"} />
      ),
      closeIcon: <Icons.CloseIcon color={"#BBBBBE"} size={"12"} />,
      bottom: 0,
    });
  }

  return next(action);
};

import { useEffect, useMemo, useState } from "react";
import Icons from "../../components/Icons";
import { Divider, Input } from "antd";
import styles from "./index.module.scss";

const TagComponent = ({ title, tagName }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ color: "#8C8C97" }}>{title}</div>
      <div className={styles["tag"]}>
        <span>{tagName}</span>
      </div>
    </div>
  );
};

export function Table({ template, children, saveEdit }) {
  const isUntitled = useMemo(
    () => template?.fileTitle?.toLowerCase() === "untitled",
    [template?.fileTitle]
  );

  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(() => {
    if (!template?.fileTitle || isUntitled) return "";

    return template?.fileTitle;
  });

  useEffect(() => {
    if (!isEditing && !isUntitled && newTitle !== template?.fileTitle) {
      setNewTitle(template?.fileTitle);
    }
  }, [isEditing, isUntitled, newTitle, template?.fileTitle]);

  return (
    <>
      <div
        style={{
          padding: "0 24px",
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
      >
        <div style={{ minheight: "36px" }}>
          <Input.TextArea
            autoSize
            type="text"
            value={newTitle}
            onChange={(e) => {
              setIsEditing(true);
              setNewTitle(e.target.value);
            }}
            bordered={false}
            placeholder="Untitled template"
            style={{
              fontSize: 28,
              fontWeight: 600,
              width: "100%",
              minWidth: "50px",
              border: "none",
              boxShadow: "none",
              padding: "0",
              caretColor: "#5F6CFF",
              caretWidth: "5px",
              textAlign: "center",
              height: "inherit",
            }}
            onPressEnter={(e) => {
              // Unfocus the input on Enter key press
              e.target.blur();
            }}
            onBlur={async () => {
              console.log(newTitle);
              try {
                setIsEditing(true);

                if (newTitle) {
                  await saveEdit({
                    title: newTitle,
                    fileTitle: newTitle,
                  });
                } else {
                  await saveEdit({
                    title: "untitled",
                    fileTitle: "untitled",
                  });
                }
              } finally {
                setIsEditing(false);
              }
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            paddingBottom: "24px",
            paddingTop: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48px",
              height: "48px",
              backgroundColor: "#f3f3f6",
              borderRadius: "50%",
            }}
          >
            <Icons.UserIcon />
          </div>
          <div style={{ fontSize: "20px", fontWeight: "600" }}>Parties</div>
        </div>
        <div
          style={{
            paddingBottom: "48px",
            display: "flex",
            gap: 16,
          }}
        >
          <div
            style={{
              padding: "10px 12px",
              border: "1px solid #ECEDFF",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 8 }}>
                <Icons.OfficeIcon color="#A4AAFC" />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>Party</div>
              </div>
              <Divider style={{ borderColor: "#ECEDFF", margin: "10px 0" }} />
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              <TagComponent title={"Name"} tagName={"Company Name"} />

              <TagComponent title={"Address"} tagName={"Company Address"} />

              <TagComponent title={"Reference"} tagName={"Company Reference"} />
            </div>
          </div>

          <div
            style={{
              padding: "10px 12px",
              border: "1px solid #ECEDFF",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 8 }}>
                <Icons.OfficeIcon color="#A4AAFC" />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Counterparty
                </div>
              </div>
              <Divider style={{ borderColor: "#ECEDFF", margin: "10px 0" }} />
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
              <TagComponent title={"Name"} tagName={"Counterparty Name"} />

              <TagComponent
                title={"Address"}
                tagName={"Counterparty Address"}
              />

              <TagComponent
                title={"Reference"}
                tagName={"Counterparty Reference"}
              />
            </div>
          </div>
        </div>
        {/* <Space style={{ paddingBottom: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "32px",
              height: "32px",
              backgroundColor: "#A4AAFC",
              color: "#fff",
              borderRadius: "8px",
              fontSize: 16,
              fontWeight: "600",
            }}
          >
            1
          </div>
          <div style={{ fontSize: "20px", fontWeight: "600" }}>Conditions</div>
        </Space> */}
      </div>

      <div style={{ position: "relative", zIndex: 1 }}>{children}</div>

      <div
        style={{
          padding: "0 24px",
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 12,
            alignItems: "center",
            paddingBottom: "24px",
            paddingTop: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "48px",
              height: "48px",
              backgroundColor: "#f3f3f6",
              borderRadius: "50%",
            }}
          >
            <Icons.FeatherIcon width={24} height={24} />
          </div>
          <div style={{ fontSize: "20px", fontWeight: "600" }}>Signatures</div>
        </div>

        <div
          style={{
            paddingBottom: "48px",
            display: "flex",
            gap: 16,
          }}
        >
          <div
            style={{
              padding: "10px 12px",
              border: "1px solid #ECEDFF",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 8 }}>
                <Icons.OfficeIcon color="#A4AAFC" />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>Party</div>
              </div>
              <Divider style={{ borderColor: "#ECEDFF", margin: "10px 0" }} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid #ECEDFF",
                borderRadius: "8px",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Architects Daughter",
                textDecoration: "underline",
                fontSize: "24px",
                color: "#8C8C97",
                padding: "55px 0px",
                cursor: "pointer",
              }}
            >
              Signature
            </div>
          </div>

          <div
            style={{
              padding: "10px 12px",
              border: "1px solid #ECEDFF",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div>
              <div style={{ display: "flex", gap: 8 }}>
                <Icons.OfficeIcon color="#A4AAFC" />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Counterparty
                </div>
              </div>
              <Divider style={{ borderColor: "#ECEDFF", margin: "10px 0" }} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid #ECEDFF",
                borderRadius: "8px",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Architects Daughter",
                textDecoration: "underline",
                fontSize: "24px",
                color: "#8C8C97",
                padding: "55px 0px",
                cursor: "pointer",
              }}
            >
              Signature
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

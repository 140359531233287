import React from "react";
import PropTypes from "prop-types";

const YesNoQuestion = ({
  color = "#5F6CFF",
  className,
  size = "24",
  circleColor = "#ECEDFF",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={size} height={size} rx="6" fill={circleColor} />
      <g clipPath="url(#clip0_155_14936)">
        <path
          d="M10 4.375C6.89369 4.375 4.375 6.89369 4.375 10C4.375 13.1063 6.89369 15.625 10 15.625C13.1063 15.625 15.625 13.1063 15.625 10C15.625 6.89369 13.1063 4.375 10 4.375ZM13.3022 13.3022C12.8728 13.7316 12.373 14.0681 11.8168 14.3041C11.243 14.5477 10.6316 14.6708 10 14.6708C9.36844 14.6708 8.75698 14.5477 8.18192 14.3041C7.62654 14.0696 7.12206 13.7293 6.69657 13.3022C6.26716 12.8728 5.93066 12.373 5.69461 11.8168C5.45229 11.243 5.32924 10.6316 5.32924 10C5.32924 9.36844 5.45229 8.75698 5.69587 8.18192C5.93042 7.62654 6.27072 7.12206 6.69782 6.69657C7.12723 6.26716 7.62695 5.93066 8.18317 5.69461C8.75697 5.45229 9.36844 5.32924 10 5.32924C10.6316 5.32924 11.243 5.45229 11.8181 5.69587C12.3735 5.93042 12.8779 6.27072 13.3034 6.69782C13.7328 7.12723 14.0693 7.62695 14.3054 8.18317C14.5477 8.75697 14.6708 9.36844 14.6708 10C14.6708 10.6316 14.5477 11.243 14.3041 11.8181C14.0699 12.3732 13.7295 12.8774 13.3022 13.3022Z"
          fill={color}
        />
        <path
          d="M11.8168 14.3041C12.373 14.0681 12.8728 13.7316 13.3022 13.3022C13.7295 12.8774 14.0699 12.3732 14.3041 11.8181C14.5477 11.243 14.6708 10.6316 14.6708 10C14.6708 9.36844 14.5477 8.75697 14.3054 8.18317C14.0693 7.62695 13.7328 7.12723 13.3034 6.69782C12.8779 6.27072 12.3735 5.93042 11.8181 5.69587C11.243 5.45229 10.6316 5.32924 10 5.32924V14.6708C10.6316 14.6708 11.243 14.5477 11.8168 14.3041Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_155_14936">
          <rect
            width="11.25"
            height="11.25"
            fill="white"
            transform="translate(4.375 4.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

YesNoQuestion.propTypes = {
  color: PropTypes.string,
};

export default YesNoQuestion;

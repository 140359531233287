import React from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import { Icons } from "../index";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { documentSelector } from "../../store/selectors/prompts";
import { useTranslation } from "react-i18next";

const DocumentAttachments = ({ stylesForDocument, onTipsPress }) => {
  const thisDocument = useSelector(documentSelector);
  const { t } = useTranslation();

  if (!thisDocument?.attachments) return null;

  return (
    <div
      className={styles["c-review-status__item"]}
      style={{ fontFamily: stylesForDocument?.font }}
    >
      <div className={styles["teritary__head"]}>
        <div
          className={styles["teritary__head__icon"]}
          style={{ position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              visibility: "hidden",
              bottom: 250,
            }}
            id="attachments"
          />

          <Icons.PaperClipIcon />
        </div>
        <div className={styles["teritary__head__title"]}>
          {t("contract:attachments")}
        </div>
        {thisDocument.tips.find(
          (value) => value?.tipID === "ATTACHMENTS_123"
        ) && (
          <div
            data-html2canvas-ignore="true"
            className={styles["has-help"]}
            style={{ color: stylesForDocument?.color }}
            onClick={() => {
              onTipsPress("ATTACHMENTS_123");
            }}
          >
            <Icons.QuestionCircleIcon color={stylesForDocument?.color} />
            {t("wizard:info.help")}
          </div>
        )}
      </div>

      <div className={styles["teritary__desc"]}>
        {parse(thisDocument.attachments)}
      </div>
    </div>
  );
};

export default DocumentAttachments;

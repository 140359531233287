import { all, takeEvery, call, put } from "redux-saga/effects";

import { request } from "../../utils/client";
import { AUTH, DASHBOARD } from "../types";
import { store } from "../index";

export function* saveFileSaga({ payload: { file, callback } }) {
  try {
    const state = store.getState();
    const token = state.token;

    let formData = new FormData();

    formData.append("file", file);

    const response = yield call(request, `helper/file`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      payload: formData,
    });

    if (!!callback) {
      callback(response);
    }

    if (response.type === "ERROR") {
      yield put({
        type: DASHBOARD.SAVE_FILE_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: DASHBOARD.SAVE_FILE_SUCCESS,
        payload: {
          response: response.accounts,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: DASHBOARD.SAVE_FILE_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export function* resetPasswordSaga({ payload: { callback } }) {
  try {
    const state = store.getState();
    const token = state.token;

    const response = yield call(request, `user/password-reset`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      payload: {},
    });

    if (!!callback) {
      callback(response);
    }

    if (response.type === "ERROR") {
      yield put({
        type: DASHBOARD.RESET_PASSWORD_FAILURE,
        payload: {
          response: response,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    } else {
      yield put({
        type: DASHBOARD.RESET_PASSWORD_SUCCESS,
        payload: {
          response: response.accounts,
          message: {
            message: response?.message,
            messageTitle: response?.messageTitle,
            showMessage: response?.showMessage,
          },
        },
      });
    }
  } catch (err) {
    yield put({
      type: DASHBOARD.RESET_PASSWORD_FAILURE,
      payload: err,
    });
    yield put({
      type: AUTH.SET_ERROR_LOADING_SCREEN_TYPE,
      payload: {
        type: "error",
      },
    });
  }
}

export default function* root() {
  yield all([
    takeEvery(DASHBOARD.SAVE_FILE, saveFileSaga),
    takeEvery(DASHBOARD.RESET_PASSWORD, resetPasswordSaga),
  ]);
}

import React from "react";
import PropTypes from "prop-types";

const CrossIcon = ({
  color = "currentColor",
  className,
  width = "15",
  height = "15",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.23232 7.27679L14.0917 0.292411C14.1899 0.176339 14.1073 0 13.9555 0H12.1743C12.0694 0 11.9689 0.0468749 11.8997 0.127232L7.06714 5.88839L2.23455 0.127232C2.16759 0.0468749 2.06714 0 1.96 0H0.17875C0.0269647 0 -0.0556248 0.176339 0.0425895 0.292411L5.90197 7.27679L0.0425895 14.2612C0.0205886 14.287 0.00647419 14.3187 0.00192143 14.3523C-0.00263132 14.386 0.00256882 14.4202 0.016905 14.451C0.0312412 14.4818 0.0541113 14.5078 0.0827995 14.526C0.111488 14.5442 0.144789 14.5538 0.17875 14.5536H1.96C2.06491 14.5536 2.16536 14.5067 2.23455 14.4263L7.06714 8.66518L11.8997 14.4263C11.9667 14.5067 12.0671 14.5536 12.1743 14.5536H13.9555C14.1073 14.5536 14.1899 14.3772 14.0917 14.2612L8.23232 7.27679Z"
        fill={color}
      />
    </svg>
  );
};

CrossIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
  width: PropTypes.number || PropTypes.string,
  height: PropTypes.number || PropTypes.string,
};

export default CrossIcon;

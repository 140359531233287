import React from "react";
import PropTypes from "prop-types";

const ErrorBigIcon = ({ className }) => {
  return (
    <svg width="91" height="84" viewBox="0 0 91 84" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M34.068 10.1901C40.3773 -0.730046 50.6227 -0.730046 56.932 10.1901C56.932 10.1901 67.8423 29.0491 71.9147 36.1222C75.9872 43.1953 86.8195 62.0995 86.8195 62.0995C93.096 73.0386 87.9733 81.9359 75.3875 81.9549C75.3875 81.9549 53.6449 82 45.5 82C37.3551 82 15.6125 81.9549 15.6125 81.9549C3.02672 81.9359 -2.09596 73.0386 4.18053 62.0995C4.18053 62.0995 15.0128 43.1953 19.0853 36.1222C23.1577 29.0491 34.068 10.1901 34.068 10.1901Z" fill="#EF4444" />
      <path d="M34.068 10.1901C40.3773 -0.730046 50.6227 -0.730046 56.932 10.1901C56.932 10.1901 67.8423 29.0491 71.9147 36.1222C75.9872 43.1953 86.8195 62.0995 86.8195 62.0995C93.096 73.0386 87.9733 81.9359 75.3875 81.9549C75.3875 81.9549 53.6449 82 45.5 82C37.3551 82 15.6125 81.9549 15.6125 81.9549C3.02672 81.9359 -2.09596 73.0386 4.18053 62.0995C4.18053 62.0995 15.0128 43.1953 19.0853 36.1222C23.1577 29.0491 34.068 10.1901 34.068 10.1901Z" stroke="#EF4444" strokeWidth="2.88296" />
      <path d="M45.5 68.4777C46.9151 68.4777 48.0873 68.0016 49.016 67.0471C49.9448 66.0938 50.4092 64.9086 50.4092 63.4885C50.4092 62.0695 49.9448 60.8843 49.016 59.9298C48.0873 58.9765 46.9151 58.4993 45.5 58.4993C44.0838 58.4993 42.9128 58.9765 41.9841 59.9298C41.0553 60.8843 40.5909 62.0695 40.5909 63.4885C40.5909 64.9086 41.0553 66.0938 41.9841 67.0471C42.9128 68.0016 44.0838 68.4777 45.5 68.4777ZM45.5 54.508C46.1634 54.508 46.7276 54.2864 47.192 53.8427C47.6564 53.4002 47.9315 52.7564 48.0209 51.9136L50.4755 26.1029L50.5418 25.3712C50.5418 23.9973 50.0659 22.8994 49.1153 22.078C48.1634 21.2578 46.9595 20.8477 45.5 20.8477C44.0406 20.8477 42.8355 21.2578 41.8848 22.078C40.933 22.8994 40.4583 23.9973 40.4583 25.3712L40.5246 26.0364L42.9791 51.9136C43.0674 52.7564 43.3437 53.4002 43.8081 53.8427C44.2725 54.2864 44.8367 54.508 45.5 54.508Z" fill="white" />
    </svg>
  );
};

ErrorBigIcon.propTypes = {
  className: PropTypes.object,
};

export default ErrorBigIcon;

import React from "react";
import PropTypes from "prop-types";

const WarningFillIcon = ({
  color = "currentColor",
  className,
  width = "48",
  height = "42",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M47.3981 39.1406L25.4606 1.17188C25.1337 0.607617 24.5694 0.328125 23.9999 0.328125C23.4303 0.328125 22.8608 0.607617 22.5391 1.17188L0.601629 39.1406C-0.0470034 40.2691 0.765106 41.6719 2.06237 41.6719H45.9374C47.2346 41.6719 48.0467 40.2691 47.3981 39.1406ZM22.3124 15.9375C22.3124 15.7055 22.5022 15.5156 22.7342 15.5156H25.2655C25.4975 15.5156 25.6874 15.7055 25.6874 15.9375V25.6406C25.6874 25.8727 25.4975 26.0625 25.2655 26.0625H22.7342C22.5022 26.0625 22.3124 25.8727 22.3124 25.6406V15.9375ZM23.9999 34.5C23.3375 34.4865 22.7068 34.2139 22.2431 33.7406C21.7795 33.2674 21.5198 32.6313 21.5198 31.9688C21.5198 31.3062 21.7795 30.6701 22.2431 30.1969C22.7068 29.7236 23.3375 29.451 23.9999 29.4375C24.6622 29.451 25.2929 29.7236 25.7566 30.1969C26.2203 30.6701 26.48 31.3062 26.48 31.9688C26.48 32.6313 26.2203 33.2674 25.7566 33.7406C25.2929 34.2139 24.6622 34.4865 23.9999 34.5Z"
        fill={color}
      />
    </svg>
  );
};

WarningFillIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default WarningFillIcon;

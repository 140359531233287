import React, { useEffect, useRef, useState } from "react";
import { Button, Select, Divider, Typography } from "antd";
import Icons from "../../../Icons";
import { predefinedFields, TYPE } from "../data-fields";
import "../index.scss";
import { getComparator, stableSort } from "../../../../utils/stableSort";

const { Option, OptGroup } = Select;
const { Text } = Typography;

const sortedPredefinedFields = stableSort(
  predefinedFields.map((c) => ({
    ...c,
    options: stableSort(c.options, getComparator("position")),
  })),
  getComparator("position")
);

export function SelectDataField({
  variables,
  setVariables,
  searchOnly,
  selectProps: {
    defaultOpen,
    defaultActiveFirstOption = false,
    ...selectProps
  },
  form,
  inputRef,
  currentShareUser,
  onSelectDuplicate,
  canShare = true,
  shouldDisableDuplicated = false,
  disableCustom = false,
}) {
  const [customVariableKey, setCustomVariableKey] = useState(null);
  const [newFieldName, setNewFieldName] = useState("");
  const [open, setOpen] = useState(defaultOpen ?? false);
  const temporaryDisableCustom = useRef(false);

  function transformStringIntoVariable(value = "{}", custom = false) {
    const { key, type, position, label } = JSON.parse(value);
    const variableStructure = {
      key,
      position,
      type: type ?? "text",
      options: {
        custom,
      },
      value: "",
      variableGroupID: label ? `VG_${label.toUpperCase()}` : "VG_CUSTOM",
    };

    return variableStructure;
  }

  const handleChange = (value) => {
    if (value) {
      temporaryDisableCustom.current = true;
      const variableStructure = transformStringIntoVariable(value);

      const existingVariable = variables?.find(
        (variable) => variable?.key === variableStructure.key
      );

      if (existingVariable) {
        onSelectDuplicate?.(
          [...variables, variableStructure],
          existingVariable
        );
        return;
      }

      setVariables([...variables, variableStructure], variableStructure);
    }
  };

  const handleSearch = (value) => {
    setNewFieldName(value);

    decideIfItsCustom(value);
  };

  function decideIfItsCustom(value) {
    const variableExist = variables?.find(
      (variable) => variable.key.toLowerCase() === value.toLowerCase()
    );

    if ((variableExist && !variableExist.options?.custom) || !value) {
      setCustomVariableKey(null);
    } else if (variableExist && variableExist.options?.custom) {
      setCustomVariableKey(variableExist);
    } else {
      const variableStructure = transformStringIntoVariable(
        JSON.stringify({
          key: value,
        }),
        true
      );
      setCustomVariableKey(variableStructure);
    }
  }

  const NotFoundContent = () => {
    return (
      <div
        style={{
          fontSize: "12px",
        }}
      >
        <Text type="secondary">No options to choose from</Text>
      </div>
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (customVariableKey?.variableID) {
          onSelectDuplicate?.(
            [...variables, customVariableKey],
            customVariableKey
          );
        } else if (customVariableKey) {
          setVariables([...variables, customVariableKey], customVariableKey);
        }
        setCustomVariableKey(null);

        if (open) {
          setOpen(false);
        }
      }}
      id="data-fields"
    >
      <Select
        open={open}
        ref={inputRef}
        disabled={!canShare}
        className="data-fields-select"
        value={<span>Search or create fields…</span>}
        showSearch
        defaultActiveFirstOption={defaultActiveFirstOption}
        showArrow={false}
        onSearch={handleSearch}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey && !disableCustom) {
            // prevent submission on Shift+Enter
            e.preventDefault();
            e.stopPropagation(); // stop event propagation to prevent dropdown from closing
            const value = newFieldName;
            setTimeout(() => {
              const form = e.target.closest("form");

              decideIfItsCustom(value);

              if (form && !temporaryDisableCustom.current) {
                form.dispatchEvent(new Event("submit", { bubbles: true }));
              }

              temporaryDisableCustom.current = false;
            }, 100);
          }
        }}
        placeholder="Search or create fields…"
        style={{
          width: "100%",
        }}
        onChange={handleChange}
        notFoundContent={searchOnly ? <NotFoundContent /> : <></>}
        {...(!disableCustom && {
          dropdownRender: (menu) => (
            <DropdownRender
              customVariableKey={customVariableKey}
              menu={menu}
              searchOnly={searchOnly}
              newFieldName={newFieldName}
            />
          ),
        })}
        virtual={false}
        onDropdownVisibleChange={(e) => setOpen(e)}
        {...selectProps}
      >
        {sortedPredefinedFields.map((options) => {
          return (
            <OptGroup
              key={options.label}
              label={
                <Typography.Text style={{ fontWeight: 500, color: "#2B2A35" }}>
                  {options.label}
                </Typography.Text>
              }
            >
              {options.options.map((option) => {
                let Icon;

                switch (option.type) {
                  case TYPE.TEXT:
                    Icon = Icons.TextTag;
                    break;
                  case TYPE.DATE:
                    Icon = Icons.Calendar;
                    break;
                  case TYPE.NUMBER:
                    Icon = Icons.NumberQuestion;
                    break;
                  case TYPE.SELECT:
                    Icon = Icons.RadioFilled;
                    break;
                  case TYPE.CHECKBOX:
                    Icon = Icons.Checkbox;
                    break;
                  case TYPE.LOCATION:
                    Icon = Icons.AdressQuestion;
                    break;
                  default:
                    // eslint-disable-next-line no-unused-vars
                    Icon = Icons.TextTag;
                }

                const value = { ...option, label: options.label };
                const disabled = variables.some((v) => v.key === option.key);

                return (
                  <Option
                    key={option.key}
                    value={JSON.stringify(value)}
                    disabled={shouldDisableDuplicated && disabled}
                  >
                    <div
                      title={option.key}
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        fontSize: "14px",
                      }}
                    >
                      <div style={{ width: 20, height: 20 }}>
                        {option?.icon ?? <Icon size={20} />}
                      </div>
                      <div
                        style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        {option.key}
                      </div>
                    </div>
                  </Option>
                );
              })}
            </OptGroup>
          );
        })}
      </Select>
    </form>
  );
}

function DropdownRender({ searchOnly, menu, customVariableKey, newFieldName }) {
  if (searchOnly) {
    return null;
  }

  return (
    <>
      {menu}
      <Divider
        style={{
          margin: "8px 0",
        }}
      />

      <Button
        disabled={!customVariableKey}
        type="text"
        htmlType="submit"
        form="data-fields"
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          color: "#5F6CFF",
          gap: 8,
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            gap: 8,
          }}
        >
          <div style={{ width: 16, height: 16 }}>
            <Icons.PlusOutlined size={"16"} />
          </div>
          {`Create new ${newFieldName} field`}
        </div>
        <Text
          type="secondary"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "12px",
            gap: 4,
          }}
        >
          Press Enter <Icons.Enter size={12} />
        </Text>
      </Button>
    </>
  );
}

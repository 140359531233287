import React from "react";
import PropTypes from "prop-types";

const UploadedCircleFilled = ({
  color = "#49525C",
  circleColor = "#EEEEEF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 5.20156 4.70156 0.5 10.5 0.5C16.2984 0.5 21 5.20156 21 11C21 16.7984 16.2984 21.5 10.5 21.5C4.70156 21.5 0 16.7984 0 11ZM10.5496 8.08222C10.5296 8.05661 10.504 8.03589 10.4747 8.02165C10.4455 8.0074 10.4134 8 10.3809 8C10.3484 8 10.3163 8.0074 10.287 8.02165C10.2578 8.03589 10.2322 8.05661 10.2121 8.08222L7.21213 11.8778C7.1874 11.9093 7.17205 11.9472 7.16784 11.9871C7.16364 12.027 7.17074 12.0673 7.18835 12.1033C7.20595 12.1394 7.23334 12.1698 7.26739 12.191C7.30144 12.2122 7.34076 12.2234 7.38088 12.2233H9.36034V15.7857C9.36034 15.9036 9.45677 16 9.57463 16H11.1818C11.2996 16 11.3961 15.9036 11.3961 15.7857V12.226H13.3809C13.5603 12.226 13.6594 12.0197 13.5496 11.8804L10.5496 8.08222ZM13.6333 6H7.36667C7.25621 6 7.16667 6.08954 7.16667 6.2V7.13333C7.16667 7.24379 7.25621 7.33333 7.36667 7.33333H13.6333C13.7438 7.33333 13.8333 7.24379 13.8333 7.13333V6.2C13.8333 6.08954 13.7438 6 13.6333 6Z"
        fill={circleColor}
      />
      <path
        d="M10.5496 8.08222C10.5296 8.05661 10.504 8.03589 10.4747 8.02165C10.4455 8.0074 10.4134 8 10.3809 8C10.3484 8 10.3163 8.0074 10.287 8.02165C10.2578 8.03589 10.2322 8.05661 10.2121 8.08222L7.21213 11.8778C7.1874 11.9093 7.17205 11.9472 7.16784 11.9871C7.16364 12.027 7.17074 12.0673 7.18835 12.1033C7.20595 12.1394 7.23334 12.1698 7.26739 12.191C7.30144 12.2122 7.34076 12.2234 7.38088 12.2233H9.36034V15.7857C9.36034 15.9036 9.45677 16 9.57463 16H11.1818C11.2996 16 11.3961 15.9036 11.3961 15.7857V12.226H13.3809C13.5603 12.226 13.6594 12.0197 13.5496 11.8804L10.5496 8.08222Z"
        fill={color}
      />
      <path
        d="M7.36667 6H13.6333C13.7438 6 13.8333 6.08954 13.8333 6.2V7.13333C13.8333 7.24379 13.7438 7.33333 13.6333 7.33333H7.36667C7.25621 7.33333 7.16667 7.24379 7.16667 7.13333V6.2C7.16667 6.08954 7.25621 6 7.36667 6Z"
        fill={color}
      />
    </svg>
  );
};

UploadedCircleFilled.propTypes = {
  color: PropTypes.string,
};

export default UploadedCircleFilled;

import React from "react";
import PropTypes from "prop-types";

const EyeInvisible = ({ color = "currentColor", size = 16, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9371 9.75013C8.40123 9.75013 8.84635 9.56575 9.17454 9.23756C9.50273 8.90938 9.6871 8.46426 9.6871 8.00013C9.6871 7.94888 9.68476 7.8981 9.68038 7.84794L7.78492 9.74341C7.83507 9.74778 7.8857 9.75013 7.9371 9.75013ZM13.7296 2.587L13.0621 1.92013C13.0387 1.8967 13.0069 1.88354 12.9737 1.88354C12.9406 1.88354 12.9088 1.8967 12.8854 1.92013L11.1773 3.62872C10.235 3.14716 9.17575 2.90638 7.9996 2.90638C4.99648 2.90638 2.75585 4.47044 1.27773 7.59856C1.21831 7.72437 1.1875 7.86178 1.1875 8.00091C1.1875 8.14004 1.21831 8.27744 1.27773 8.40325C1.86835 9.64731 2.57955 10.6445 3.41132 11.3947L1.75835 13.047C1.73493 13.0704 1.72177 13.1022 1.72177 13.1354C1.72177 13.1685 1.73493 13.2003 1.75835 13.2237L2.42538 13.8908C2.44882 13.9142 2.4806 13.9273 2.51374 13.9273C2.54688 13.9273 2.57866 13.9142 2.6021 13.8908L13.7296 2.76388C13.7412 2.75227 13.7504 2.73848 13.7567 2.72331C13.763 2.70813 13.7663 2.69187 13.7663 2.67544C13.7663 2.65901 13.763 2.64275 13.7567 2.62757C13.7504 2.6124 13.7412 2.59861 13.7296 2.587ZM5.1871 8.00013C5.18706 7.52514 5.31005 7.05823 5.54408 6.6449C5.77812 6.23157 6.11523 5.8859 6.52256 5.64158C6.9299 5.39725 7.39357 5.2626 7.86841 5.25074C8.34325 5.23887 8.81308 5.3502 9.2321 5.57388L8.47242 6.33356C8.16604 6.23546 7.83856 6.22365 7.52592 6.29942C7.21327 6.37518 6.92753 6.53561 6.70006 6.76308C6.47258 6.99056 6.31216 7.27629 6.23639 7.58894C6.16062 7.90159 6.17244 8.22906 6.27054 8.53544L5.51085 9.29513C5.29767 8.89681 5.18644 8.4519 5.1871 8.00013Z"
        fill={color}
      />
      <path
        d="M14.722 7.59678C14.172 6.43844 13.5174 5.49391 12.7581 4.76318L10.5059 7.01553C10.6958 7.5118 10.7379 8.05243 10.6271 8.5721C10.5163 9.09177 10.2574 9.56825 9.88172 9.94397C9.506 10.3197 9.02953 10.5786 8.50986 10.6893C7.99018 10.8001 7.44955 10.758 6.95328 10.5682L5.04297 12.4785C5.92922 12.8886 6.91495 13.0937 8.00016 13.0937C11.0033 13.0937 13.2439 11.5296 14.722 8.40146C14.7815 8.27566 14.8123 8.13825 14.8123 7.99912C14.8123 7.85999 14.7815 7.72258 14.722 7.59678Z"
        fill={color}
      />
    </svg>
  );
};

EyeInvisible.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default EyeInvisible;

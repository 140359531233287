import React from "react";
import { Form, Row, Col } from "antd";

import { useTranslation } from "react-i18next";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import CAutoComplete from "../../../AutoComplete";
import { CInput, CCheckbox, CButton } from "../../../index";
import { GOOGLE_PLACES_API_KEY } from "../../../../constants/config";

const String = ({
  placeholder,
  label,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  type,
  checkbox,
  setAddressBeforeConfirm,
  isCheckboxChecked,
  documentLanguage,
  question,
}) => {
  const { t } = useTranslation();

  const { placePredictions, getPlacePredictions, placesService } = useGoogle({
    apiKey: GOOGLE_PLACES_API_KEY,
    language: documentLanguage === "EN" ? "en-GB" : "de",
    options: { language: documentLanguage === "EN" ? "en-GB" : "de" },
  });

  const convertAddressOptions = (oldOptions) => {
    return oldOptions.map(({ description, place_id }) => ({
      value: description,
      label: description,
      id: place_id,
    }));
  };

  const getPlaceData = async (placeId) => {
    placesService.getDetails(
      { placeId: placeId, fields: ["address_components"] },
      (result) => {
        let tempAddr = {
          streetAddress: null,
          streetNumber: null,
          postalCode: null,
          city: null,
          country: null,
        };

        result.address_components.map((elem) => {
          if (
            elem.types.some((elemItem) =>
              ["route", "natural_feature", "establishment"].includes(elemItem)
            )
          ) {
            tempAddr.streetAddress = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "street_number")) {
            tempAddr.streetNumber = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "postal_code")) {
            tempAddr.postalCode = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "locality")) {
            tempAddr.city = elem.long_name;
          }

          if (elem.types.some((elemItem) => elemItem === "country")) {
            tempAddr.country = elem.long_name;
          }
        });

        setAddressBeforeConfirm(tempAddr);
      }
    );
  };

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" span={24}>
        <Form.Item
          name={`${
            type === "STRING"
              ? "STRING"
              : type === "ADDRESS"
              ? "ADDRESS"
              : "NUMBER"
          }`}
        >
          {type === "ADDRESS" ? (
            <CAutoComplete
              label={label ?? ""}
              options={convertAddressOptions(placePredictions)}
              placeholder={placeholder}
              onSearch={(value) => {
                getPlacePredictions({ input: value });
              }}
              onSelect={(value, id) => {
                getPlaceData(id);
              }}
              onChange={(value) => {
                if (value === "") {
                  setAddressBeforeConfirm(null);
                }
              }}
              onClick={() => setNumberOfInputForFocus(1)}
              focusOn={numberOfInputForFocus === 1}
            />
          ) : (
            <CInput
              label={label ? label : ""}
              placeholder={placeholder}
              focusOn={numberOfInputForFocus === 1}
              onChange={() => {
                setNumberOfInputForFocus(1);
              }}
              onClick={() => {
                setNumberOfInputForFocus(1);
              }}
              onBlur={() => setNumberOfInputForFocus(0)}
              type={type === "NUMBER" ? "number" : ""}
              inputmode={type === "NUMBER" ? "decimal" : undefined}
              pattern={type === "NUMBER" ? "d*" : undefined}
            />
          )}
        </Form.Item>
      </Col>
      {checkbox && (
        <Col className="gutter-row" span={24}>
          <Form.Item name={`checkbox`}>
            <CCheckbox
              type={"checkbox"}
              title={t("wizard:buttons.invite_recipient")}
              checked={isCheckboxChecked}
            />
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default String;

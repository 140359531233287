import React from "react";
import PropTypes from "prop-types";

const TextTag = ({
  color = "#5F6CFF",
  circleColor = "#ECEDFF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill={circleColor} />
      <path
        d="M11.6971 5.29726V3.65801C11.6971 3.60166 11.651 3.55556 11.5947 3.55556H4.62784C4.57149 3.55556 4.52539 3.60166 4.52539 3.65801V5.29726C4.52539 5.35361 4.57149 5.39971 4.62784 5.39971H5.34502C5.40136 5.39971 5.44747 5.35361 5.44747 5.29726V4.47764H7.59898V11.6494H6.42077C6.36442 11.6494 6.31832 11.6955 6.31832 11.7518V12.469C6.31832 12.5253 6.36442 12.5714 6.42077 12.5714H9.80173C9.85808 12.5714 9.90418 12.5253 9.90418 12.469V11.7518C9.90418 11.6955 9.85808 11.6494 9.80173 11.6494H8.62352V4.47764H10.775V5.29726C10.775 5.35361 10.8211 5.39971 10.8775 5.39971H11.5947C11.651 5.39971 11.6971 5.35361 11.6971 5.29726Z"
        fill={color}
      />
    </svg>
  );
};

TextTag.propTypes = {
  color: PropTypes.string,
};

export default TextTag;

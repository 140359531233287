import React, { useMemo } from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import { Icons } from "../index";
import { useSelector } from "react-redux";
import { documentSelector } from "../../store/selectors/prompts";
import { useTranslation } from "react-i18next";

const DocumentServiceDescription = ({
  stylesForDocument,
  onTipsPress,
  isPreview,
  isSign,
  onEditClick,
}) => {
  const thisDocument = useSelector(documentSelector);
  const { t } = useTranslation();
  const excludedFromSummary = [
    "Person",
    "Counterparty Signee",
    "Company Signee",
  ];

  const sortedVariableGroups = useMemo(() => {
    if (!thisDocument?.variableGroups) return [];

    return thisDocument.variableGroups
      .filter(
        (item) =>
          item.variableGroupType === "SUMMARY" &&
          !excludedFromSummary.includes(item.variableGroupName)
      )
      .sort((a, b) => a.variableGroupPosition - b.variableGroupPosition);
  }, [excludedFromSummary, thisDocument?.variableGroups]);

  return (
    <div className={styles["c-review-status__item"]} id="services">
      <div
        className={styles["teritary__head"]}
        style={{ fontFamily: stylesForDocument?.font }}
      >
        <div className={styles["teritary__head__icon"]}>
          <Icons.Bars />
        </div>
        <div className={styles["teritary__head__title"]}>
          {t("contract:summary")}
        </div>
        {thisDocument?.tips.find(
          (value) => value?.tipID === "CONTRACT_SUMMARY_123"
        ) && (
          <div
            data-html2canvas-ignore="true"
            className={styles["has-help"]}
            style={{ color: stylesForDocument?.color }}
            onClick={() => {
              onTipsPress("CONTRACT_SUMMARY_123");
            }}
          >
            <Icons.QuestionCircleIcon color={stylesForDocument?.color} />
            {t("wizard:info.help")}
          </div>
        )}
      </div>

      <div className={styles["teritary__row"]}>
        {sortedVariableGroups?.map((item, index) => {
          const sortedVariables = thisDocument?.variables
            .filter(
              (variable) => variable?.variableGroupID === item?.variableGroupID
            )
            .sort((a, b) => a?.position - b?.position);

          if (!sortedVariables?.length) return null;

          return (
            <div
              key={`summary-${index}`}
              className={styles["teritary__item"]}
              style={{
                fontFamily: stylesForDocument?.font,
              }}
            >
              <div
                className={styles["teritary__card"]}
                style={{
                  border: `1px solid ${
                    stylesForDocument?.color
                      ? `${stylesForDocument.color}33`
                      : "#ecedff"
                  }`,
                }}
              >
                <div
                  className={styles["teritary__card__head"]}
                  style={{
                    borderBottom: `1px solid ${
                      stylesForDocument?.color
                        ? `${stylesForDocument.color}33`
                        : "#ecedff"
                    }`,
                  }}
                >
                  <div className={styles["icon"]}>
                    <Icons.ListIcon color={stylesForDocument?.color} />
                  </div>
                  <div className={styles["title"]}>
                    {item?.variableGroupName}
                  </div>
                </div>
                <div className={styles["teritary__card__body"]}>
                  <ul>
                    {sortedVariables.map((variable, variableIndex) => {
                      const relatedPrompt = thisDocument?.prompts?.find(
                        (p) => p.promptID === variable?.relatedPromptIDs?.[0]
                      );

                      let hideIcon = false;

                      if (relatedPrompt) {
                        hideIcon = !!relatedPrompt.defaultAnswer;
                      }

                      return (
                        variable?.value?.length > 0 && (
                          <li key={`summary-variable-${variableIndex}`}>
                            <div
                              className={styles["teritary__card__body__key"]}
                            >
                              {variable?.key}
                            </div>

                            {typeof variable?.value === "object" ? (
                              <ul>
                                {variable?.value.map((value) => {
                                  return (
                                    <li key={value}>
                                      {value}
                                      {variable?.hasOwnProperty(
                                        "relatedPromptIDs"
                                      ) &&
                                        !isPreview &&
                                        !isSign &&
                                        !hideIcon && (
                                          <span
                                            onClick={() =>
                                              onEditClick(
                                                variable?.relatedPromptIDs
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "4px",
                                            }}
                                          >
                                            <Icons.Edit
                                              width={18}
                                              height={18}
                                              color={stylesForDocument?.color}
                                            />
                                          </span>
                                        )}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <strong style={{ wordBreak: "break-all" }}>
                                {variable?.value}
                                {variable?.hasOwnProperty("relatedPromptIDs") &&
                                  !isPreview &&
                                  !isSign &&
                                  !hideIcon && (
                                    <span
                                      onClick={() =>
                                        onEditClick(variable?.relatedPromptIDs)
                                      }
                                    >
                                      <Icons.Edit
                                        width={18}
                                        height={18}
                                        color={stylesForDocument?.color}
                                      />
                                    </span>
                                  )}
                              </strong>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentServiceDescription;

import React, { useState, useEffect } from "react";
import { Typography, Dropdown } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../components";
import * as keys from "../../utils/keys";
import { SelectDataField } from "../ReviewInput/ContractRepository/components/SelectDataField";
import "./index.scss";

const { Title } = Typography;

export const SearchResults = ({
  item,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  setErrorLoadingScreenType,
  index,
  variables,
  setVariables,
  setSortedProperty,
  tableData,
  setTableData,
  document,
  onDeleteVariable
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [columns, setColumns] = useState([]);

  const firstCard = index === 0;

  const navigate = useNavigate();

  const onDocumentClick = () => {
    setErrorLoadingScreenType("load");
    getDocument(document?.documentID, undefined, undefined, () => {
      if (document?.status === "DRAFT") {
        const lastPrompt = document?.prompts?.find(
          (filterItem) => filterItem?.promptID === document?.lastAnsweredPrompt
        );

        const isSkippedPromt =
          lastPrompt?.onSelect?.[lastPrompt?.answer?.string];

        if (isSkippedPromt) {
          navigate(keys.REVIEW_INPUT, {
            state: {
              isPreviw: true,
              comeFromWizard: true,
              cameFromSearch: true,
            },
          });
        } else {
          setIsCreatingDocument(true);
          setIndex(lastPrompt?.position);
          navigate(keys.WIZARD, {
            state: { cameFromSearch: true },
          });
        }
      } else {
        navigate(keys.REVIEW_INPUT, {
          state: { isPreviw: true, comeFromWizard: true, cameFromSearch: true },
        });
      }
      setErrorLoadingScreenType("");
    });

    setTimeout(() => {
      setErrorLoadingScreenType("");
    }, 15000);
  };

  const items = [
    {
      key: "asc",
      icon: <Icons.Ascending />,
      label: "Sort 1-9",
    },
    {
      key: "desc",
      icon: <Icons.Descending />,
      label: "Sort 9-1",
    },
    {
      key: "delete",
      danger: true,
      icon: <Icons.DeleteIcon size={20} />,
      label: "Delete column",
    },
  ];

  function handleMenuSorting(e, property) {
    if (e.key === "delete") {
      const updatedTable = tableData.map((item) => {
        const updatedValues = {};

        Object.keys(item.values).forEach((key) => {
          if (key !== property) {
            updatedValues[key] = item.values[key];
          }
        });

        return {
          ...item,
          values: updatedValues,
        };
      });

      onDeleteVariable(property)

      setTableData(updatedTable);
      return;
    }

    setSortedProperty({ property, order: e.key });
  }

  useEffect(() => {
    if (item) {
      setColumns(Object.values(item));
    }
  }, [item]);

  return (
    <div className="seach-card-container">
      {columns?.map((column) => {
        function returnValue() {
          const isValidDate = moment(column?.string).isValid();
          const formattedDate = moment(column?.string).format("MMM DD, YY");

          if (isValidDate) {
            return formattedDate;
          } else {
            return column?.formatted;
          }
        }

        return (
          <div className="stack-card" key={column?.key}>
            {firstCard && (
              <ControlTableDropdown
                property={column?.key}
                items={items}
                handleMenuSorting={handleMenuSorting}
              >
                <Title type="secondary">{column?.key}</Title>
              </ControlTableDropdown>
            )}

            <div
              className="card"
              onClick={onDocumentClick}
              title="Open document"
              style={{
                minWidth: `${column.key.length + 9}ch`, // This is to fix uneven cards when there's too many columns. This component should probably be a table
              }}
            >
              {column?.string ? returnValue() : ""}
            </div>
          </div>
        );
      })}

      <div className="stack-card" style={{ minWidth: 194 }}>
        {!inputVisible && firstCard && (
          <Title
            type="secondary"
            style={{
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => setInputVisible(true)}
          >
            <Icons.PlusOutlined size={12} />
            ADD COLUMN
          </Title>
        )}

        {inputVisible && (
          <div style={{ marginBottom: 5 }}>
            <SelectDataField
              shouldDisableDuplicated
              variables={variables}
              setVariables={setVariables}
              currentShareUser={true}
              disableCustom
              selectProps={{
                onBlur: () => setInputVisible(false),
                autoFocus: true,
                placeholder: "Enter field name here",
                title: "Enter field name",
                defaultOpen: true,
                defaultActiveFirstOption: true,
              }}
            />
          </div>
        )}

        <div className="card"></div>
      </div>
    </div>
  );
};

function ControlTableDropdown({ children, property, items, handleMenuSorting }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div
      style={{
        cursor: "pointer",
      }}
      title="Sort documents"
    >
      <Dropdown
        overlayClassName="table-control-dropdown"
        onOpenChange={(e) => setIsMenuOpen(e)}
        menu={{
          items,
          onClick: (item) => handleMenuSorting(item, property),
        }}
        trigger={["click"]}
      >
        <div
          className="table-control-dropdown-item"
          style={{
            backgroundColor: isMenuOpen ? "#ECEDFF" : "inherit",
          }}
        >
          {children}
        </div>
      </Dropdown>
    </div>
  );
}

// function ControlTableDropdown({
//   children,
//   property,
//   items,
//   handleMenuSorting,
// }) {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   return (
//     <div
//       style={{
//         cursor: "pointer",
//       }}
//       title="Sort documents"
//     >
//       <Dropdown
//         overlayClassName="table-control-dropdown"
//         onOpenChange={(e) => setIsMenuOpen(e)}
//         menu={{
//           items,
//           onClick: (item) => handleMenuSorting(item, property),
//         }}
//         trigger={["click"]}
//       >
//         <div
//           className="table-control-dropdown-item"
//           style={{
//             backgroundColor: isMenuOpen ? "#ECEDFF" : "inherit",
//           }}
//         >
//           {children}
//         </div>
//       </Dropdown>
//     </div>
//   );
// }

import React from "react";
import PropTypes from "prop-types";

const Check = ({ size = 24, color = "#5F6CFF", className }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7136 3.875H20.8413C20.5788 3.875 20.3297 3.99554 20.169 4.20179L9.12521 18.192L3.82967 11.4821C3.74957 11.3804 3.64746 11.2982 3.53102 11.2416C3.41457 11.185 3.28682 11.1555 3.15735 11.1554H1.28503C1.10557 11.1554 1.00646 11.3616 1.11628 11.5009L8.45289 20.7955C8.79575 21.2295 9.45468 21.2295 9.80021 20.7955L22.8824 4.21786C22.9922 4.08125 22.8931 3.875 22.7136 3.875Z"
        fill={color}
      />
    </svg>
  );
};

Check.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Check;

import React from "react";
import PropTypes from "prop-types";

const FeatherIcon = ({
  color = "currentColor",
  className,
  width = 33,
  height = 33,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9028 4.26642C16.649 2.52022 19.0173 1.53922 21.4868 1.53922C23.9563 1.53922 26.3247 2.52022 28.0709 4.26642C29.8171 6.01262 30.7981 8.38097 30.7981 10.8505C30.7981 13.3195 29.8175 15.6874 28.0719 17.4335C28.0716 17.4338 28.0712 17.4342 28.0709 17.4345L19.1111 26.4209C18.8617 26.671 18.5229 26.8117 18.1696 26.8117H6.86849C6.1342 26.8117 5.53894 26.2164 5.53894 25.4821V14.181C5.53894 13.8284 5.67902 13.4902 5.92836 13.2409L14.9028 4.26642ZM21.4868 4.19831C19.7226 4.19831 18.0306 4.89916 16.783 6.14668L8.19803 14.7317V24.1526H17.6178L26.1892 15.5557L26.1906 15.5543C27.4381 14.3067 28.139 12.6147 28.139 10.8505C28.139 9.08621 27.4381 7.3942 26.1906 6.14668C24.9431 4.89916 23.2511 4.19831 21.4868 4.19831Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.93973 28.5306L20.5534 9.91699L22.4336 11.7973L3.81999 30.4109L1.93973 28.5306Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1867 18.8344C12.1867 18.1001 12.7819 17.5049 13.5162 17.5049L24.1526 17.5049C24.8869 17.5049 25.4821 18.1001 25.4821 18.8344C25.4821 19.5687 24.8869 20.1639 24.1526 20.1639L13.5162 20.1639C12.7819 20.1639 12.1867 19.5687 12.1867 18.8344Z"
      />
    </svg>
  );
};

FeatherIcon.propTypes = {
  color: PropTypes.string,
};

export default FeatherIcon;

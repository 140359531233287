import React from "react";
import PropTypes from "prop-types";

const AdressQuestion = ({
  color = "#5F6CFF",
  className,
  size = "24",
  circleColor = "#ECEDFF",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="6" fill={circleColor} />
      <path
        d="M10.0001 7.55888C9.54795 7.55888 9.12304 7.73429 8.80247 8.05485C8.64495 8.21193 8.52003 8.3986 8.43491 8.60413C8.34979 8.80966 8.30615 9.02999 8.3065 9.25245C8.3065 9.70457 8.48342 10.1295 8.80247 10.45C8.95955 10.6076 9.14622 10.7325 9.35175 10.8176C9.55728 10.9027 9.77761 10.9464 10.0001 10.946C10.4522 10.946 10.8771 10.7691 11.1977 10.45C11.5182 10.1295 11.6936 9.70457 11.6936 9.25245C11.6936 8.80033 11.5182 8.37542 11.1977 8.05485C10.8771 7.73429 10.4522 7.55888 10.0001 7.55888ZM15.1806 6.98579C14.8959 6.32935 14.4855 5.735 13.9724 5.23627C13.4577 4.73304 12.8508 4.33363 12.1851 4.05985C11.4925 3.77254 10.7576 3.62738 10.0001 3.62738C9.2425 3.62738 8.50761 3.77254 7.81506 4.05833C7.1452 4.33505 6.54489 4.73122 6.02774 5.23476C5.51502 5.73377 5.10462 6.32805 4.81956 6.98428C4.5247 7.66473 4.375 8.38752 4.375 9.13148C4.375 10.199 4.63055 11.2621 5.13257 12.2873C5.5363 13.1114 6.10032 13.9143 6.81102 14.6779C8.02525 15.9814 9.29845 16.7782 9.65984 16.9915C9.76243 17.052 9.87942 17.0839 9.99856 17.0837C10.1165 17.0837 10.2329 17.0535 10.3373 16.9915C10.6987 16.7782 11.9719 15.9814 13.1861 14.6779C13.8968 13.9158 14.4608 13.1114 14.8645 12.2873C15.3696 11.2636 15.6251 10.2021 15.6251 9.13299C15.6251 8.38903 15.4754 7.66624 15.1806 6.98579ZM10.0001 11.9138C8.53029 11.9138 7.33875 10.7222 7.33875 9.25245C7.33875 7.78267 8.53029 6.59113 10.0001 6.59113C11.4698 6.59113 12.6614 7.78267 12.6614 9.25245C12.6614 10.7222 11.4698 11.9138 10.0001 11.9138Z"
        fill={color}
      />
    </svg>
  );
};

AdressQuestion.propTypes = {
  color: PropTypes.string,
};

export default AdressQuestion;

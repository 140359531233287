import { isProduction } from "../utils/isProduction";
import { isPlayground } from "../utils/isPlayground";

const PLAYGROUND_SUBDOMAIN = "playground-app";
const PRODUCTION_SUBDOMAIN = "app";

const GLOBAL_ACCOUNT = {
  accountID: "A_5h0UTUcPCwt8xCT0aNv5r",
  userID: "U_Gdt5f03pdyxgOTYUsgoYg",
};

const API_URL = isProduction()
  ? "https://swiftsign-frontend-315412.ew.r.appspot.com/"
  : isPlayground()
  ? "https://playground-api.swiftsign.io/"
  : "http://localhost:8080/";

const APP_URL = isProduction()
  ? "https://app.swiftsign.io/"
  : isPlayground()
  ? "https://playground-app.swiftsign.io/"
  : "http://localhost:3000/";

const FORM_ICON = {
  FEATHER_ICON_ID: "IC_y9bV1iUkKkfepSQLGgSJY",
  PARTY_ICON_ID: "IC_bbeMO7HNN6UITS047G-he",
  DOCUMENT_ICON_ID: "IC_8Tz703i5UHSTB47g0Tzi-",
};

const FORM_TYPE = {
  INFO: "INFO",
  SINGLE_SELECT: "SINGLE_SELECT",
  OPTIONAL_COMPANY_NAME: "OPTIONAL_COMPANY_NAME",
  COMPANY_NAME: "COMPANY_NAME",
  PERSON_NAME: "PERSON_NAME",
  ADDRESS: "ADDRESS",
  NUMBER: "NUMBER",
  CONTRACT_LENGTH: "CONTRACT_LENGTH",
  DATE: "DATE",
  CURRENCY: "CURRENCY",
  STRING: "STRING",
  MULTI_SELECT: "MULTI_SELECT",
  ADDRESS_AUTO_COMPLETE: "ADDRESS_AUTO_COMPLETE",
  DROPDOWN: "DROPDOWN",
  VERIFICATION_NAME: "VERIFICATION_NAME",
  PLAN: "PLAN",
  NOTICE: "NOTICE",
  TIME_PERIOD: "TIME_PERIOD",
};

const AUTH = {
  DOMAIN: isProduction()
    ? "https://swiftsign-prod.eu.auth0.com"
    : "https://swiftsign-technologies.eu.auth0.com",
  CLIENT_ID: isProduction()
    ? "FUmvKkOBwH8VREb0pqLkwO7pqSLgZ16B"
    : "eirD7IASFhVLsJcrv06p3WOQoeiCYERj",
  AUDIENCE: isProduction() ? "https://app.swiftsign.io" : "http://swiftsign.io",
};

const GOOGLE_PLACES_API_KEY =
  process.env.NODE_ENV === "development"
    ? "AIzaSyC34BRZBCRzY1VrDOx1l7gVqTEu8zg718c"
    : "AIzaSyBCK4x-1oKrDinDGn52EI_Fc_uRa_MsLis";

export {
  API_URL,
  FORM_ICON,
  FORM_TYPE,
  AUTH,
  GOOGLE_PLACES_API_KEY,
  PLAYGROUND_SUBDOMAIN,
  PRODUCTION_SUBDOMAIN,
  APP_URL,
  GLOBAL_ACCOUNT,
};

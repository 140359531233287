import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import pageStyles from "./index.module.scss";
import salesforceLogo from "../../assets/images/salesforce.svg";
import { CButton, LoadErrorScreens } from "../../components";
import { jwtDecoder } from "../../utils/helper";
import { setToken } from "../../store/actions/auth";
import { api } from "../../services/api";
import { SALESFORCE_INTEGRATION_ID } from "../../constants/config";

const SalesforceAuthorization = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getAccessToken();
    getIdToken();
  }, []);

  useEffect(() => {
    if (idToken) {
      const user = jwtDecoder(idToken);
      setUser(user);
    }
  }, [idToken]);

  function getAccessToken() {
    const urlHash = window.location.hash;
    const hashKey = "access_token";
    const ampersandIndex = urlHash.indexOf("&");
    const accessToken = urlHash.substring(hashKey.length + 2, ampersandIndex);
    if (accessToken) {
      setAccessToken(accessToken);
    }
  }

  function getIdToken() {
    const params = new URLSearchParams(window.location.hash);
    const idToken = params.get("id_token");
    if (idToken) {
      setIdToken(idToken);
    }
  }

  async function allowIntegration() {
    // await api.post(`user-integrations/${SALESFORCE_INTEGRATION_ID}`)
    const salesforceUrl =
      "https://swiftsigntechnologies-dev-ed.develop.lightning.force.com/lightning/n/Swiftsign_Dashboard";
    window.location.href = `${salesforceUrl}#access_token=${accessToken}&id_token=${idToken}`;
  }

  return !!user ? (
    <div>
      <div className={pageStyles["container"]}>
        <div className={pageStyles["card"]}>
          <img src={salesforceLogo} alt="logo" className={pageStyles["logo"]} />
          <h1 className={pageStyles["title"]}>Allow access?</h1>
          <p className={pageStyles["text"]}>
            <strong>Salesforce</strong> is asking to:
            <br />
            <br />
            <ul>
              <li>Access your user information</li>
              <li>Access and modify your documents</li>
            </ul>
            <br />
            Do you want to allow access for {user.email}?
          </p>
          <div style={{ display: "flex" }}>
            <CButton
              customStyles={pageStyles["buttons"]}
              type="secondary"
              title={"Deny"}
              onClick={() => window.close()}
            ></CButton>
            <CButton
              customStyles={pageStyles["buttons"]}
              type="primary"
              title={"Allow"}
              onClick={allowIntegration}
            ></CButton>
          </div>
          {/* <div>
                            accessToken: {accessToken}<br/>
                            idToken: {idToken}
                        </div> */}
        </div>
      </div>
    </div>
  ) : (
    <LoadErrorScreens type="load" />
  );
};

export default SalesforceAuthorization;

import { Icons } from "../../..";
import styles from "../../index.module.scss";

export function KeysNavigations({ goBack, goForward }) {
  return (
    <div
      className={styles["c-wizard__controls"]}
      style={{ bottom: window.innerWidth > 1182 ? `1%` : `5%` }}
    >
      <div className={styles["c-wizard__controls__item"]} onClick={goBack}>
        <div className={styles["c-wizard__controls__btn"]}>
          <Icons.ArrowTopIcon />
        </div>
      </div>
      <div className={styles["c-wizard__controls__item"]} onClick={goForward}>
        <div className={styles["c-wizard__controls__btn"]}>
          <Icons.ArrowBottomIcon />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { isProduction } from "../../utils/isProduction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";
import { useAuth0 } from "@auth0/auth0-react";

import {
  accountsSelector,
  workspaceSelector,
} from "../../store/selectors/auth";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { setCurrentWorkspace } from "../../store/actions/auth";

import { DefaultUserImg } from "../../components";
import styles from "./index.module.scss";

const Workspaces = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accounts = useSelector(accountsSelector);
  const workspace = useSelector(workspaceSelector);
  const { isAuthenticated } = useAuth0();

  const doSetCurrentWorkspace = (args) => {
    dispatch(setCurrentWorkspace(args, "LAST_UPDATED"));
  };

  const IsActive = ({ currentWorkspace }) => {
    return (
      currentWorkspace && (
        <div
          style={{
            width: 3,
            height: 32,
            background: "#5F6CFF",
            position: "absolute",
            left: 3,
            borderRadius: "1px",
          }}
        />
      )
    );
  };

  return (
    <aside className={styles.dashboard__workspace}>
      {isAuthenticated && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: "10px 0px",
            position: "fixed",
          }}
        >
          {accounts?.map((workspaces) => {
            const isActive = workspace === workspaces?.accountID;
            const handleChangeWorkspace = () => {
              doSetCurrentWorkspace(workspaces?.accountID);
            };
            return (
              <div
                key={workspaces?.createdBy}
                style={{ position: "relative", padding: "0px 16px" }}
              >
                <IsActive currentWorkspace={isActive} />
                <div
                  style={{
                    boxShadow: "0px 2px 8px 0px #00000014",
                    borderRadius: "6px",
                    background: "#ECEDFF",
                  }}
                  onClick={() => !isActive && handleChangeWorkspace()}
                >
                  <DefaultUserImg
                    initials={workspaces?.info?.initials}
                    image={workspaces?.info?.image}
                  />
                </div>
              </div>
            );
          })}
          {/*  {accounts && (
         <div style={{ padding: "0px 16px" }}>
           <Button
             onClick={() => navigate(keys.CREATE_WORKSPACE)}
             type="text"
             className={styles.workspace__add}
             icon={<PlusOutlined />}
           />
         </div>
       )} */}
        </div>
      )}
    </aside>
  );
};

export default Workspaces;

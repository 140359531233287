import React from "react";
import PropTypes from "prop-types";

const CheckCircleIcon = ({ color = "#42B983", className, size = "24" }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 3C12.4031 3 3 12.4031 3 24C3 35.5969 12.4031 45 24 45C35.5969 45 45 35.5969 45 24C45 12.4031 35.5969 3 24 3ZM33.0703 17.1422L23.1984 30.8297C23.0605 31.0223 22.8786 31.1792 22.6678 31.2874C22.4571 31.3957 22.2236 31.4522 21.9867 31.4522C21.7498 31.4522 21.5163 31.3957 21.3056 31.2874C21.0949 31.1792 20.913 31.0223 20.775 30.8297L14.9297 22.7297C14.7516 22.4812 14.9297 22.1344 15.2344 22.1344H17.4328C17.9109 22.1344 18.3656 22.3641 18.6469 22.7578L21.9844 27.3891L29.3531 17.1703C29.6344 16.7812 30.0844 16.5469 30.5672 16.5469H32.7656C33.0703 16.5469 33.2484 16.8938 33.0703 17.1422Z"
        fill={color}
      />
    </svg>
  );
};

CheckCircleIcon.propTypes = {
  color: PropTypes.string,
};

export default CheckCircleIcon;

import React from "react";
import styles from "../../pages/ReviewInput/index.module.scss";
import moment from "moment-timezone";
import { Icons } from "../index";
import { useTranslation } from "react-i18next";

const Signees = ({
  list,
  partyItem,
  isLocal,
  signeeData,
  stylesForDocument,
}) => {
  const { t } = useTranslation();

  const filteredList = list?.filter(
    (filterItem) =>
      filterItem?.partyId === partyItem?.partyID ||
      filterItem?.partyID === partyItem?.partyID
  );

  if (filteredList?.length) {
    return filteredList?.map((listItem, index) => (
      <div
        key={`list-${listItem?.partyID}-${index}`}
        className={styles["teritary__creator__sign"]}
        style={{
          marginBottom: index + 1 === signeeData?.length ? 0 : 10,
        }}
      >
        <div
          className={styles["teritary__creator__sign__name"]}
          style={{ opacity: listItem?.signature?.createdAt ? 1 : 0.5 }}
        >
          {`${listItem?.contact?.firstName} ${
            listItem?.contact?.lastName ?? ""
          }`}
        </div>
        <div
          className={styles["teritary__creator__sign__date"]}
          style={{ opacity: listItem?.signature?.createdAt ? 1 : 0.5 }}
        >
          {listItem?.signature?.createdAt
            ? `${moment(listItem?.signature?.createdAt).format(
                "DD.MM.YYYY HH:mm:ss"
              )} ${moment.tz(moment.tz.guess()).zoneAbbr()}`
            : "Date and time"}
        </div>
        <div className={styles["creator__row"]}>
          {listItem?.contact?.image ? (
            <div className={styles["creator__logo"]}>
              {listItem?.order && (
                <div
                  className={styles["creator__order"]}
                  style={{
                    backgroundColor: stylesForDocument?.color,
                    fontFamily: stylesForDocument?.font,
                  }}
                >
                  {listItem.order}
                </div>
              )}
              <img src={listItem?.contact?.image} alt="" />
            </div>
          ) : (
            <div
              className={styles["creator__logo"]}
              style={{ color: stylesForDocument?.color }}
            >
              {listItem?.order && (
                <div
                  className={styles["creator__order"]}
                  style={{
                    backgroundColor: stylesForDocument?.color,
                    fontFamily: stylesForDocument?.font,
                  }}
                >
                  {listItem.order}
                </div>
              )}
              {`${listItem?.contact?.firstName?.[0]?.toUpperCase()}${
                listItem?.contact?.lastName
                  ? listItem?.contact?.lastName?.[0]?.toUpperCase()
                  : ""
              }`}
            </div>
          )}
          <div className={styles["creator__detail"]}>
            <div className={styles["creator__creatorname"]}>
              {`${listItem?.contact?.firstName} ${
                listItem?.contact?.lastName ?? ""
              }`}
            </div>
            <div className={styles["creator__creatoremail"]}>
              {listItem?.contact?.email}
            </div>
          </div>
          <div className={`${styles["creator__status"]} ${styles["pending"]}`}>
            {isLocal || listItem?.status === "TO_SIGN" ? (
              <Icons.StatusPendingIconFilled size={25} />
            ) : listItem?.status === "SIGNED" ? (
              <Icons.CheckCircleIcon color="#42B983" size={25} />
            ) : (
              <Icons.ErrorCircle color="#E53935" size={25} />
            )}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className={styles["teritary__creator__sign__default-name"]}>
      <div
        className={`${styles["teritary__creator__sign__name"]} ${styles["underline"]}`}
      >
        {t("contract:signature")}
      </div>
    </div>
  );
};

export default Signees;

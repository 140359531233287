import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col } from "antd";
import { CSelect, CInput, CCheckbox } from "../../../components";
import { currency, entities } from "../../../utils/helper";
import styles from "./index.module.scss";

const OptionalName = ({
  groupId = "",
  placeholder,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  checkbox,
  form,
  cardData,
  isCheckboxChecked,
  label,
  documentStyles,
  fieldsError,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###firstName`}>
            <CInput
              label={label?.name}
              placeholder={placeholder?.contact?.organizationName}
              focusOn={numberOfInputForFocus === 1}
              onChange={() => {
                setNumberOfInputForFocus(1);
              }}
              onClick={() => {
                setNumberOfInputForFocus(1);
              }}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name={`${groupId}###lastName`}>
            <CInput
              label={label?.optionalName}
              placeholder={placeholder?.contact?.optionalName}
              focusOn={numberOfInputForFocus === 2}
              onChange={() => {
                setNumberOfInputForFocus(2);
              }}
              onClick={() => {
                setNumberOfInputForFocus(2);
              }}
              font={documentStyles?.font}
              color={documentStyles?.color}
            />
          </Form.Item>
        </Col>
        {fieldsError && (
          <div className={styles["c-block-card__error"]}>{fieldsError}</div>
        )}
        {checkbox && (
          <Col className="gutter-row" span={24}>
            <Form.Item name={`${groupId}###checkbox`}>
              <CCheckbox
                type={"checkbox"}
                title={t("wizard:buttons.invite_recipient")}
                checked={isCheckboxChecked}
                font={documentStyles?.font}
                color={documentStyles?.color}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  );
};

export default OptionalName;

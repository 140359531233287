import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as keys from "../../utils/keys";
import { Icons } from "..";
import styles from "./index.module.scss";

const NavBarBottom = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles["nav-bar-bottom"]}>
      <div className={styles["nav-bar-bottom__item"]}>
        <Link
          to={keys.DASHBOARD}
          className={`${styles["nav-bar-bottom__link"]} ${
            pathname === "/" && styles["active"]
          }`}
        >
          <i>
            <Icons.DashboardIcon />
          </i>
          <span>Dashboard</span>
        </Link>
      </div>
      <div className={styles["nav-bar-bottom__item"]}>
        <Link
          to={keys.CREATE_DOCUMENT}
          className={`${styles["nav-bar-bottom__link"]} ${
            pathname.includes("create-document") && styles["active"]
          }`}
        >
          <i>
            <Icons.PlusFillIcon />
          </i>
          <span>Create</span>
        </Link>
      </div>
      <div className={styles["nav-bar-bottom__item"]}>
        <Link
          to={keys.FOLDERS}
          className={`${styles["nav-bar-bottom__link"]} ${
            pathname.includes("folders") && styles["active"]
          }`}
        >
          <i>
            <Icons.WorkspaceIcon />
          </i>
          <span>Workspace</span>
        </Link>
      </div>
    </div>
  );
};

export default NavBarBottom;

export const PROMPTS = {
  UPDATE_DOCUMENT_DATA: "UPDATE_DOCUMENT_DATA",
  UPDATE_DOCUMENT_DATA_SUCCESS: "UPDATE_DOCUMENT_DATA_SUCCESS",
  UPDATE_DOCUMENT_DATA_FAILURE: "UPDATE_DOCUMENT_DATA_FAILURE",

  SIGN_DOCUMENT: "SIGN_DOCUMENT",
  SIGN_DOCUMENT_SUCCESS: "SIGN_DOCUMENT_SUCCESS",
  SIGN_DOCUMENT_FAILURE: "SIGN_DOCUMENT_FAILURE",

  GET_DOCUMENT: "GET_DOCUMENT",
  GET_DOCUMENT_SUCCESS: "GET_DOCUMENT_SUCCESS",
  GET_DOCUMENT_FAILURE: "GET_DOCUMENT_FAILURE",

  LOAD_DOCUMENT_PDF: "LOAD_DOCUMENT_PDF",
  LOAD_DOCUMENT_PDF_SUCCESS: "LOAD_DOCUMENT_PDF_SUCCESS",
  LOAD_DOCUMENT_PDF_FAILURE: "LOAD_DOCUMENT_PDF_FAILURE",

  VERIFY_USER: "VERIFY_USER",
  VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
  VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",

  SET_INDEX: "SET_INDEX",
  SET_DOCUMENT_DATA: "SET_DOCUMENT_DATA",
  SET_ADDRESS_BEFORE_CONFIRM: "SET_ADDRESS_BEFORE_CONFIRM",
  SET_NEW_DOCUMENT_DATA: "SET_NEW_DOCUMENT_DATA",
  SET_SIGNEE_DATA: "SET_SIGNEE_DATA",
  SET_AVAILABLE_HEADER_STEP: "SET_AVAILABLE_HEADER_STEP",
  SET_IS_CREATING_DOCUMENT: "SET_IS_CREATING_DOCUMENT",
  SET_DOCUMENT_PDF: "SET_DOCUMENT_PDF",

  SHARE_USER: "SHARE_USER",
  ACCEPTED_SHARE: "ACCEPTED_SHARE",
  EDIT_SHARED_USER: "EDIT_SHARED_USER",
  DELETE_SHARED_USER: "DELETE_SHARED_USER",
};

export const AUTH = {
  GET_ACCOUNT_DATA: "GET_ACCOUNT_DATA",
  GET_ACCOUNT_DATA_SUCCESS: "GET_ACCOUNT_DATA_SUCCESS",
  GET_ACCOUNT_DATA_FAILURE: "GET_ACCOUNT_DATA_FAILURE",

  CHANGE_ACCOUNT_DATA: "CHANGE_ACCOUNT_DATA",
  DELETE_ACCOUNT_DATA: "DELETE_ACCOUNT_DATA",
  CHANGE_ACCOUNT_DATA_SUCCESS: "CHANGE_ACCOUNT_DATA_SUCCESS",
  CHANGE_ACCOUNT_DATA_FAILURE: "CHANGE_ACCOUNT_DATA_FAILURE",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  UPDATE_ACCOUNT_DATA: "UPDATE_ACCOUNT_DATA",
  UPDATE_ACCOUNT_DATA_SUCCESS: "UPDATE_ACCOUNT_DATA_SUCCESS",
  UPDATE_ACCOUNT_DATA_FAILURE: "UPDATE_ACCOUNT_DATA_FAILURE",

  CREATE_DOCUMENT_FUNCTION: "CREATE_DOCUMENT_FUNCTION",
  CREATE_DOCUMENT_FUNCTION_SUCCESS: "CREATE_DOCUMENT_FUNCTION_SUCCESS",
  CREATE_DOCUMENT_FUNCTION_FAILURE: "CREATE_DOCUMENT_FUNCTION_FAILURE",

  UPDATE_DOCUMENT_FROM_DASHBOARD: "UPDATE_DOCUMENT_FROM_DASHBOARD",
  UPDATE_DOCUMENT_FROM_DASHBOARD_SUCCESS:
    "UPDATE_DOCUMENT_FROM_DASHBOARD_SUCCESS",
  UPDATE_DOCUMENT_FROM_DASHBOARD_FAILURE:
    "UPDATE_DOCUMENT_FROM_DASHBOARD_FAILURE",

  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_FAILURE: "DELETE_DOCUMENT_FAILURE",

  GET_SORTED_DOCUMENTS: "GET_SORTED_DOCUMENTS",
  GET_SORTED_DOCUMENTS_SUCCESS: "GET_SORTED_DOCUMENTS_SUCCESS",
  GET_SORTED_DOCUMENTS_FAILURE: "GET_SORTED_DOCUMENTS_FAILURE",

  SET_TOKEN: "SET_TOKEN",
  SET_AUTH0: "SET_AUTH0",
  CLEAR_AUTH_DATA: "CLEAR_AUTH_DATA",
  SET_REDIRECT_PARAMS: "SET_REDIRECT_PARAMS",
  SET_ERROR_LOADING_SCREEN_TYPE: "SET_ERROR_LOADING_SCREEN_TYPE",

  SEND_WORKSPACE_INVITE: "SEND_WORKSPACE_INVITE",
  SET_CURRENT_WORKSPACE: "SET_CURRENT_WORKSPACE",
  SET_TEMP_USER: "SET_TEMP_USER",
};

export const DASHBOARD = {
  SET_ACTIVE_FOLDER: "SET_ACTIVE_FOLDER",

  SAVE_FILE: "SAVE_FILE",
  SAVE_FILE_SUCCESS: "SAVE_FILE_SUCCESS",
  SAVE_FILE_FAILURE: "SAVE_FILE_FAILURE",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

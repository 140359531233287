import { useMemo } from "react";
import { documentSelector } from "../../store/selectors/prompts";
import { useSelector } from "react-redux";
import { tempUserSelector, userSelector } from "../../store/selectors/auth";
import { useQuery } from "../../utils/helper";


export function useDocumentPermission() {
  const thisDocument = useSelector(documentSelector);
  const signedUser = useSelector(userSelector);
  const tempUser = useSelector(tempUserSelector);
  const query = useQuery();

  const approverId = query.get("approverId");

  const user = useMemo(() => {
    if (signedUser) {
      return thisDocument?.users?.find((u) => u.userID === signedUser?.userID);
    };

    if (tempUser) {
      return thisDocument?.users?.find((u) => u.userID === tempUser);
    }

    if (approverId) {
      const approver = thisDocument?.approvers?.find((a) => a.approverID === approverId)
      if (approver) {
        return {
          ...approver,
          userID: approver?.approverID
        };
      }
    }
    
    return null;
  }, [signedUser, tempUser, thisDocument, approverId])

  const isOwner = useMemo(() => {
    return !!thisDocument?.users?.some((u) => {
      return u?.userID === user?.userID && u.permission === "owner";
    })
  }, [thisDocument, user])

  const documentID = useMemo(() => {
    return thisDocument?.documentID;
  }, [thisDocument])

  return {
    isOwner,
    user,
    documentID,
    thisDocument
  }
}
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDoubleTap } from "use-double-tap";
import { useDrop } from "react-dnd";

import { Icons } from "../..";

import * as keys from "../../../utils/keys";

import styles from "../index.module.scss";

const FolderLine = React.forwardRef(
  (
    {
      changeAccountData,
      setActiveFolder,
      activeFolder,
      item,
      index,
      setNowEditing,
      nowEditing,
      folders,
      onFolderClick,
      dropdownId,
      setDropdownId,
      updateDocument,
    },
    ref
  ) => {
    const { pathname } = useLocation();
    let navigate = useNavigate();

    const inputRef = useRef();

    const [editedValue, setEditedValue] = useState(null);

    useEffect(() => {
      if (nowEditing !== item.id) {
        handleDetectEnter(null, true);
      } else {
        handleStartEdit();
      }
    }, [nowEditing]);

    const onDrop = (droppedDocument) => {
      if (item.type !== "MASTER") {
        updateDocument(droppedDocument.name, {
          folderID: item.id,
        });
      }
    };

    const [, drop] = useDrop({
      accept: "any",
      drop: onDrop,
    });

    const bindDoubleClick = useDoubleTap(
      (event) => {
        if (item.type !== "MASTER") {
          handleStartEdit();
        }
      },
      300,
      {
        onSingleTap: (event) => {
          if (nowEditing !== item.id) {
            setNowEditing(null);

            setActiveFolder(item.id);
          }

          if (window.innerWidth >= 1020) {
            navigate(keys.FOLDERS);
          } else {
            onFolderClick();
          }
        },
      }
    );

    const handleStartEdit = () => {
      setDropdownId(null);
      setNowEditing(item.id);
      setEditedValue(inputRef.current.value);

      const target = inputRef.current;
      target.focus();
      target.readOnly = false;
      target.classList.add(styles["edit-name"]);
    };

    const handleDetectEnter = (e, isForce = false) => {
      const target = inputRef.current;

      if (isForce) {
        target.readOnly = true;
        target.classList.remove(styles["edit-name"]);

        if (!!editedValue && editedValue !== item.name) {
          let tempFolders = folders.map((filterItem) => {
            if (filterItem.id === item.id) {
              return { ...filterItem, name: target.value };
            } else {
              return filterItem;
            }
          });

          changeAccountData({ folders: tempFolders }, () => {
            setEditedValue(null);
          });
        } else {
          setEditedValue(null);
        }

        return;
      }

      if (e?.key === "Enter" || e?.keyCode === 13) {
        target.readOnly = true;
        target.classList.remove(styles["edit-name"]);

        let tempFolders = folders.map((filterItem) => {
          if (filterItem.id === item.id) {
            return { ...filterItem, name: target.value };
          } else {
            return filterItem;
          }
        });

        changeAccountData({ folders: tempFolders }, () => {
          setEditedValue(null);
          setNowEditing(null);
        });
      }
    };

    return (
      <li key={index} ref={drop}>
        <span
          className={
            activeFolder === item?.id
              ? styles["c-folder__activeFolder"]
              : styles["c-folder__box"]
          }
          ref={ref}
          id={item?.id}
        >
          <span
            className={styles["c-folder__left-side-wrapper"]}
            {...bindDoubleClick}
          >
            <span className={styles["c-folder__icon"]}>
              <Icons.FolderIcon />
            </span>
            <span className={styles["c-folder__text"]}>
              <input
                ref={inputRef}
                onKeyDown={(e) => handleDetectEnter(e, false)}
                type="text"
                className={styles["c-folder__input"]}
                value={editedValue !== null ? editedValue : item?.name}
                readOnly={true}
                onChange={(e) => {
                  setEditedValue(e.target.value);
                }}
              />
            </span>
          </span>
          <span
            className={
              styles[
                `c-folder__more${item.type === "MASTER" ? "__hidden" : ""}`
              ]
            }
            onClick={() => {
              if (dropdownId === item.id) {
                setDropdownId(null);
              } else {
                setDropdownId(item.id);
              }
            }}
          >
            <Icons.MoreIcon />
          </span>
          <div
            className={`u-folder__dropdown ${
              item.type !== "MASTER" && item.id === dropdownId ? "active" : ""
            }`}
          >
            <div className={"u-folder__dropdown__row"}>
              <div className={"u-folder__dropdown__item"}>
                <div
                  onClick={() => {
                    handleStartEdit();
                  }}
                  className={"u-folder__dropdown__link"}
                >
                  <i>
                    <Icons.RenameIcon />
                  </i>
                  <div className={"text"}>Rename</div>
                </div>
              </div>
              <div className={"u-folder__dropdown__item"}>
                <div
                  className={"u-folder__dropdown__link"}
                  onClick={() => {
                    let tmpFolders = folders.filter(
                      (filterItem) => filterItem.id !== item.id
                    );

                    if (
                      index + 1 === folders.length &&
                      pathname.includes("folders")
                    ) {
                      setActiveFolder(folders[0].id);
                    }

                    setDropdownId(null);

                    changeAccountData({ folders: tmpFolders });
                  }}
                >
                  <i>
                    <Icons.DeleteIcon />
                  </i>
                  <div className={"text"}>Delete</div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </li>
    );
  }
);

export default FolderLine;

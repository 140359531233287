import { DropDown, DropDownItem, dropDownActiveClass } from "./Dropdown";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { UnorderedListOutlined, OrderedListOutlined, AlignRightOutlined } from '@ant-design/icons'
import styles from '../index.module.scss'

export const listTypeToListName = {
  bullet: 'Bulleted List',
  number: 'Numbered List',
}

export function ListDropdown({
  editor,
  listType,
  disabled = false,
}){
  const formatBulletList = () => {
    if (listType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (listType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNoneList = () => {
    editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  }

  return (
    <DropDown
      disabled={disabled}
      buttonClassName={`${styles["popup-item"]} ${styles["block-controls"]}`}
      hideIcon
      ButtonIcon={UnorderedListOutlined}
      buttonAriaLabel="Formatting options for list style"
    >
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(listType === 'bullet')}`}
        onClick={formatBulletList}>
        <UnorderedListOutlined className={styles["icon"]} />
        <span className="text">Bullet</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(listType === 'number')}`}
        onClick={formatNumberedList}>
        <OrderedListOutlined className={styles["icon"]} />
        <span className="text">Number</span>
      </DropDownItem>
      <DropDownItem
        className={`${styles["item"]} ${dropDownActiveClass(listType !== 'bullet' && listType !== 'number')}`}
        onClick={formatNoneList}>
        <AlignRightOutlined className={styles["icon"]} />
        <span className="text">None</span>
      </DropDownItem>
    </DropDown>
  );
}

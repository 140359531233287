import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import Icons from "../Icons";

import styles from "./index.module.scss";

const LongPressButton = ({
  onConfirm = () => {},
  onPressOutEarly = () => {},
  font,
  color,
  disabled,
}) => {
  const { t } = useTranslation();

  const [phase, setPhase] = useState(1);
  const [leftLineWidth, setLeftLinesWidth] = useState(0);
  const [timer, setTimer] = useState();
  const [confirmed, setConfirmed] = useState(false);

  const [width, setWidth] = useState(0);
  const textWrapperRef = useRef(null);

  useEffect(() => {
    if (textWrapperRef.current) {
      setWidth(textWrapperRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (leftLineWidth !== 0 && leftLineWidth <= 100) {
      const timeout = setTimeout(() => {
        setLeftLinesWidth(leftLineWidth + 1);
      }, 20);

      setTimer(timeout);
    }

    if (leftLineWidth >= 100) {
      setPhase(3);
    }
  }, [leftLineWidth]);

  const onPressOut = (disableConfirm = false) => {
    if (!confirmed) {
      if (leftLineWidth < 100) {
        clearTimeout(timer);
        if (!disableConfirm) onPressOutEarly();
        setLeftLinesWidth(0);
        setPhase(1);
      } else {
        onConfirm();
        setConfirmed(true);
      }
    }
  };

  return (
    <div
      className={styles[`confirm-contract__confirm`]}
      onMouseDown={() => {
        if (leftLineWidth === 0 && !disabled) {
          setPhase(2);

          setLeftLinesWidth(leftLineWidth + 1);
        }
      }}
      onMouseUp={() => onPressOut()}
      onMouseLeave={() => (disabled ? undefined : onPressOut(true))}
      onTouchStart={() => {
        if (leftLineWidth === 0 && !disabled) {
          setPhase(2);

          setLeftLinesWidth(leftLineWidth + 1);
        }
      }}
      onTouchEnd={() => onPressOut()}
      style={{
        backgroundColor: color ? `${color}33` : "",
        maxWidth: width ?? 300,
      }}
    >
      <div
        className={styles["confirm-contract__leftLine"]}
        style={{
          width: `${leftLineWidth}%`,
          backgroundColor: color ? color : undefined,
        }}
      ></div>
      <div
        className={styles["confirm-contract__rightLine"]}
        style={{
          width: `${100 - leftLineWidth}%`,
        }}
      ></div>
      <div
        className={styles["confirm-contract__text-wrapper"]}
        style={{ fontFamily: font }}
        ref={textWrapperRef}
      >
        <div
          style={{ paddingLeft: "12px", display: "flex", alignItems: "center" }}
        >
          <Icons.TickIcon color="white" />
        </div>

        <span className={styles["confirm-contract__text"]}>
          {phase === 1
            ? t("other:long_press_to_sign")
            : phase === 2
            ? t("other:signing")
            : `${t("other:signed")}!`}
        </span>
      </div>
    </div>
  );
};

LongPressButton.propTypes = {};

export default LongPressButton;

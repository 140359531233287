import { createActions } from "redux-actions";

import { PROMPTS } from "../types";

export const {
  setIndex,
  setDocumentData,
  updateDocumentData,
  setAddressBeforeConfirm,
  setNewDocumentData,
  setSigneeData,
  setAvailableHeaderStep,
  signDocument,
  getDocument,
  setIsCreatingDocument,
  loadDocumentPdf,
  setDocumentPdf,
  verifyUser,
  shareUser,
  acceptedShare,
  editSharedUser,
  deleteSharedUser,
} = createActions({
  [PROMPTS.SET_INDEX]: (index) => ({ index }),
  [PROMPTS.SET_DOCUMENT_DATA]: (document) => ({ document }),
  [PROMPTS.UPDATE_DOCUMENT_DATA]: (
    documentId,
    update,
    isPromptUpdate,
    callback,
    noAuth,
    passcode,
    returnRemainingPrompts,
    noNotification,
    useCallbackInError
  ) => ({
    documentId,
    update,
    isPromptUpdate,
    callback,
    noAuth,
    passcode,
    returnRemainingPrompts,
    noNotification,
    useCallbackInError,
  }),
  [PROMPTS.SET_ADDRESS_BEFORE_CONFIRM]: (addressData) => ({ addressData }),
  [PROMPTS.SET_NEW_DOCUMENT_DATA]: (newDocumentData) => ({ newDocumentData }),
  [PROMPTS.SET_SIGNEE_DATA]: (newSigneeData) => ({ newSigneeData }),
  [PROMPTS.SET_AVAILABLE_HEADER_STEP]: (step) => ({ step }),
  [PROMPTS.SIGN_DOCUMENT]: (documentId, userId, callback) => ({
    documentId,
    userId,
    callback,
  }),
  [PROMPTS.GET_DOCUMENT]: (
    documentId,
    passcode,
    noAuth,
    callback,
    returnRemainingPrompts,
    countAsVisit
  ) => ({
    documentId,
    passcode,
    noAuth,
    callback,
    returnRemainingPrompts,
    countAsVisit,
  }),
  [PROMPTS.SET_IS_CREATING_DOCUMENT]: (isCreating) => ({ isCreating }),
  [PROMPTS.LOAD_DOCUMENT_PDF]: (documentId, passcode, callback) => ({
    documentId,
    passcode,
    callback,
  }),
  [PROMPTS.SET_DOCUMENT_PDF]: (file) => ({ file }),
  [PROMPTS.VERIFY_USER]: (
    userId,
    firstName,
    lastName,
    documentID,
    callback
  ) => ({
    userId,
    firstName,
    lastName,
    documentID,
    callback,
  }),
  [PROMPTS.SHARE_USER]: (documentId, userEmail, permission, callback) => ({
    documentId,
    userEmail,
    permission,
    callback,
  }),
  [PROMPTS.ACCEPTED_SHARE]: (documentId, callback) => ({
    documentId,
    callback,
  }),
  [PROMPTS.EDIT_SHARED_USER]: (
    documentId,
    userEmail,
    permission,
    callback
  ) => ({
    documentId,
    userEmail,
    permission,
    callback,
  }),
  [PROMPTS.DELETE_SHARED_USER]: (documentId, userEmail, callback) => ({
    documentId,
    userEmail,
    callback,
  }),
});

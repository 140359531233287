import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Space, Typography, notification } from "antd";
import { InputWithSelect } from "./shareSelect";
import { SelectDropdown } from "../../SelectDropdown";

import { CButton } from "../../../components";
import DefaultUserImg from "../../DefaultUserImg";
import "../index.scss";
import { useRef } from "react";

const { Text } = Typography;

export const ShareContent = ({
  setFocused,
  focused,
  documentStyles,
  thisDocument,
  shareUser,
  editSharedUser,
  deleteSharedUser,
  user,
  shareOptions,
  invitedUsers,
  setInvitedUsers,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [signee, setSignee] = useState("");
  const [permission, setPermission] = useState(shareOptions?.[0].value);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [duplicateError, setDuplicateError] = useState(false);

  useEffect(() => {
    if (focused === 1) {
      inputRef.current.focus();
    }
  }, [focused]);

  const shareDocument = () => {
    const userAlreadyInvited = invitedUsers?.find(
      (invited) => invited?.email === signee
    );

    if (userAlreadyInvited) {
      setDuplicateError(true);
    } else {
      setDuplicateError(false);
      shareUser(thisDocument?.documentID, signee, permission);
      setSignee("");
      setFocused(0);
      setDisabled(true);
    }
  };

  const owner = thisDocument?.users?.find(
    (user) => user.permission === "owner"
  );

  const isNotOnwer = !user?.email ? true : owner?.email !== user?.email;

  const handleValuesChange = (changedValues) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      changedValues.target.value &&
      emailRegex.test(changedValues.target.value)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setSignee(changedValues.target.value.trim());
  };

  const handleBlur = () => {
    setFocused(0);
  };

  const handlePressEnter = (event) => {
    if (event.key === "Enter") {
      shareDocument();
    }
  };

  return (
    <Space size={0} direction="vertical" className="share-container">
      <div className="invite-share-container">
        <InputWithSelect
          setFocused={setFocused}
          setPermission={setPermission}
          permission={permission}
          shareOptions={shareOptions}
          disabled={disabled}
          focused={focused}
          signee={signee}
          ref={inputRef}
          handleBlur={handleBlur}
          handlePressEnter={handlePressEnter}
          handleValuesChange={handleValuesChange}
        />

        <CButton
          font={documentStyles?.font}
          title={t("share:invite")}
          backgroundColor={"#5F6CFF"}
          disabled={disabled}
          customStyles={"share-email-button"}
          onClick={shareDocument}
          onPressEnter={shareDocument}
        />
      </div>
      <small
        className="share-container__error"
        style={{
          marginBottom: duplicateError ? 12 : 0,
        }}
      >
        {duplicateError && "This document is already shared with this email."}
      </small>

      <Space
        className="share-container__list"
        direction="vertical"
        style={{ width: "100%", maxHeight: 230, overflow: "auto" }}
      >
        {invitedUsers?.map((invitedUser) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={invitedUser.email}
            >
              <Space>
                <DefaultUserImg
                  initials={invitedUser.initials}
                  firstName={invitedUser.firstName}
                  lastName={invitedUser.lastName}
                  image={invitedUser?.image}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: "normal",
                  }}
                >
                  <Text style={{ fontSize: 14 }}>
                    {invitedUser.firstName} {invitedUser.lastName}
                  </Text>

                  <Text
                    type="secondary"
                    style={{ fontSize: 12, lineHeight: "normal" }}
                  >
                    {invitedUser.email}
                  </Text>
                </div>
              </Space>
              <Space>
                <SelectDropdown
                  setFocused={setFocused}
                  permission={invitedUser.permission}
                  removeFunction={() =>
                    deleteSharedUser(
                      thisDocument?.documentID,
                      invitedUser.email
                    )
                  }
                  editHandler={(selectedPermission) =>
                    editSharedUser(
                      thisDocument?.documentID,
                      invitedUser.email,
                      selectedPermission
                    )
                  }
                  userEmail={invitedUser.email}
                  documentId={thisDocument?.documentID}
                  itemsArray={invitedUsers}
                  setChangedItemsArray={setInvitedUsers}
                  disabled={isNotOnwer}
                  options={shareOptions}
                  deleteNotificationMessage={`${t("share:removed")} ${
                    invitedUser.email
                  } ${t("share:removed_after")}`}
                  deleteOption
                />
              </Space>
            </div>
          );
        })}
      </Space>
    </Space>
  );
};

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";

import {
  Layout,
  Header,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  Card,
} from "../../components";

import {
  setIsCreatingDocument,
  getDocument,
  loadDocumentPdf,
} from "../../store/actions/prompts";
import { setRedirectParams } from "../../store/actions/auth";

import { documentSelector } from "../../store/selectors/prompts";
import { accountsSelector } from "../../store/selectors/auth";

import * as keys from "../../utils/keys";
import { useQuery } from "../../utils/helper";

import reviewInputStyles from "../ReviewInput/index.module.scss";
import styles from "./index.module.scss";
import { useAuth0 } from "@auth0/auth0-react";

const DocumentOverview = ({
  thisDocument,
  setIsCreatingDocument,
  getDocument,
  accounts,
  setRedirectParams,
  loadDocumentPdf,
}) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const query = useQuery();
  const location = useLocation();
  const { t } = useTranslation();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);

  const [numberOfSigned, setNumberOfSigned] = useState(0);
  const [documentStyles, setDocumentStyles] = useState(null);
  const [justSigned, setJustSigned] = useState(false);
  const [currentUserJustSigned, setCurrentUserJustSigned] = useState(false);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(
      <HowCanWeHelp color={documentStyles?.color} font={documentStyles?.font} />
    );
    setOpenPanelVisible(true);
  };

  useEffect(() => {
    setIsCreatingDocument(false);
  }, []);

  useEffect(() => {
    if (location?.state?.cameAfterEmail || location.state?.cameFromWockspace) {
      setJustSigned(true);
    } else {
      setJustSigned(false);
    }

    if (location?.state?.currentUserJustSigned) {
      setCurrentUserJustSigned(true);
    }

    if (
      !location?.state?.cameAfterEmail &&
      !location?.state?.currentUserJustSigned
    ) {
      navigate(keys.DASHBOARD, { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (accounts && thisDocument) {
      getDocument(thisDocument.documentID);
    }
  }, [accounts]);

  useEffect(() => {
    if (
      window.location.search &&
      window.location.href.includes(keys.DOCUMENT_OVERVIEW)
    ) {
      const isSender = query.get("isSender");

      if (isSender === "true" && !isAuthenticated) {
        setRedirectParams({
          search: window.location.search,
          path: keys.DOCUMENT_OVERVIEW,
        });

        navigate(keys.DASHBOARD);
      } else {
        setRedirectParams(null);

        const documentId = query.get("documentId");
        const passcode = query.get("passcode");

        getDocument(documentId, passcode, true);
      }
    }
  }, [window.location]);

  useEffect(() => {
    if (thisDocument) {
      let count = 0;
      thisDocument?.signees?.forEach((signeeItem) => {
        if (signeeItem.status === "SIGNED") {
          count++;
        }
      });
      setNumberOfSigned(count);

      if (thisDocument?.style?.isApplyToWizard) {
        setDocumentStyles(thisDocument?.style);
      } else {
        setDocumentStyles(null);
      }
    }
  }, [thisDocument]);

  const returnPageLabel = () => {
    return (
      <div>
        {thisDocument?.status === "COMPLETED" || justSigned ? (
          <Icons.CheckCircleIcon color="#42B983" size={48} />
        ) : currentUserJustSigned ? (
          <Icons.ArrowRightBig color={"#5BA288"} width={52} height={52} />
        ) : (
          <></>
        )}
        <div className={[styles["c-document-overview__title"]]}>
          <h1>
            {thisDocument?.status === "COMPLETED" || justSigned
              ? t("document_overview:you_signed")
              : currentUserJustSigned
              ? t("document_overview:you_sent_document")
              : ""}
          </h1>
        </div>
        <div className={styles["c-document-overview__title-small-text"]}>
          {thisDocument?.status === "COMPLETED" || justSigned
            ? t("document_overview:we_will_send_you_link")
            : t("document_overview:thank_for_using")}
        </div>
      </div>
    );
  };

  return (
    <Layout
      header={
        <Header
          title="Overview"
          shortTitle="Overview"
          progress={0}
          right={
            <CButton
              type="icon"
              icon={
                <Icons.CrossIcon
                  width={24}
                  height={24}
                  color={
                    documentStyles?.color ? documentStyles.color : "#5F6CFF"
                  }
                />
              }
              onClick={() => navigate(keys.DASHBOARD, { replace: true })}
            />
          }
          documentStyles={documentStyles}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `25px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      questionIconColor={documentStyles?.color}
    >
      <div className={styles["main-wrapper"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
          closeIconColor={documentStyles?.color}
        />

        <div className={styles["c-document-overview"]}>
          <div className={styles["c-document-overview__article"]}>
            <div style={{ fontFamily: documentStyles?.font }}>
              {returnPageLabel()}
              <div className={styles["c-document-overview__block"]}>
                <div className={styles["c-document-overview__block-header"]}>
                  <span className={styles["c-document-overview__block__title"]}>
                    {t("sign_send:document")}
                  </span>
                  <div
                    className={styles["c-document-overview__right-text"]}
                    onClick={() => {
                      navigate(keys.REVIEW_INPUT, {
                        state: {
                          isPreviw: true,
                          justSigned: justSigned,
                        },
                      });
                    }}
                  >
                    <Icons.EyeIcon
                      color={
                        documentStyles?.color ? documentStyles.color : "#5F6CFF"
                      }
                    />
                    <p style={{ color: documentStyles?.color }}>
                      {t("review_input:preview")}
                    </p>
                  </div>
                </div>
                <div>{thisDocument?.fileTitle}</div>
              </div>

              {!!thisDocument?.messages?.length && (
                <div className={styles["c-document-overview__block"]}>
                  <div className={styles["c-document-overview__block-header"]}>
                    <span
                      className={styles["c-document-overview__block__title"]}
                    >
                      {t("sign_send:message")}
                    </span>
                  </div>
                  <div className={styles["c-document-overview__block__body"]}>
                    {thisDocument.messages?.[0]?.content}
                  </div>
                </div>
              )}

              <div className={styles["c-document-overview__block"]}>
                <div className={styles["c-document-overview__block-header"]}>
                  <span className={styles["c-document-overview__block__title"]}>
                    {t("sign_send:signees")}
                  </span>
                  <div className={styles["c-document-overview__right-text"]}>
                    <p style={{ color: documentStyles?.color }}>
                      {numberOfSigned} of {thisDocument?.signees?.length}
                    </p>
                  </div>
                </div>
                <div>
                  <div className={reviewInputStyles["teritary__row"]}>
                    {thisDocument?.parties.map((partyItem) => {
                      return (
                        <div
                          key={`party-${partyItem?.partyID}`}
                          className={reviewInputStyles["teritary__item"]}
                        >
                          <div
                            className={reviewInputStyles["teritary__card"]}
                            style={{
                              borderColor: documentStyles?.color
                                ? `${documentStyles?.color}33`
                                : "#ecedff",
                            }}
                          >
                            <div
                              className={
                                reviewInputStyles["teritary__card__head"]
                              }
                              style={{
                                borderColor: documentStyles?.color
                                  ? `${documentStyles?.color}33`
                                  : "#ecedff",
                              }}
                            >
                              <div className={reviewInputStyles["icon"]}>
                                <Icons.OfficeIcon
                                  color={documentStyles?.color}
                                />
                              </div>
                              <div className={reviewInputStyles["title"]}>
                                {partyItem.type === "Company"
                                  ? `${
                                      partyItem.contact?.organizationName ?? ""
                                    } ${
                                      partyItem.contact?.organizationType ?? ""
                                    }`
                                  : `${partyItem.contact?.firstName ?? ""} ${
                                      partyItem.contact?.lastName ?? ""
                                    }`}
                              </div>
                            </div>

                            {thisDocument?.signees
                              ?.filter(
                                (filterItem) =>
                                  filterItem.partyID === partyItem.partyID
                              )
                              ?.map((signeeItem, index) => {
                                return (
                                  <div
                                    key={`signee-${signeeItem?.partyID}-${index}`}
                                    className={`${
                                      styles["c-document-overview__card"]
                                    } ${
                                      index === 0
                                        ? styles[
                                            "c-document-overview__card__first-child"
                                          ]
                                        : ""
                                    }`}
                                  >
                                    <Card
                                      item={signeeItem?.contact}
                                      index={signeeItem?.order}
                                      documentStyles={documentStyles}
                                      iconOnRight={
                                        <div
                                          className={
                                            styles[
                                              "c-document-overview__icons-on-cards"
                                            ]
                                          }
                                        >
                                          {signeeItem.status === "SIGNED" ? (
                                            <Icons.CheckCircleIcon
                                              color="#42B983"
                                              size={25}
                                            />
                                          ) : signeeItem.status ===
                                            "TO_SIGN" ? (
                                            <Icons.WaitIconCircle
                                              color="#F39465"
                                              width={25}
                                              height={25}
                                            />
                                          ) : (
                                            <Icons.ErrorCircle
                                              color="#E53935"
                                              size={25}
                                            />
                                          )}
                                        </div>
                                      }
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className={styles["c-document-overview__bottom-btn"]}>
              <CButton
                type="primary"
                title={
                  thisDocument?.status === "COMPLETED" || justSigned
                    ? "Download PDF"
                    : "Back to Workspace"
                }
                icon={
                  thisDocument?.status === "COMPLETED" || justSigned ? (
                    <Icons.DownloadIcon />
                  ) : (
                    ""
                  )
                }
                onClick={() => {
                  if (thisDocument?.status === "COMPLETED" || justSigned) {
                    loadDocumentPdf(
                      thisDocument?.documentID,
                      thisDocument?.passcode,
                      (link) => {
                        saveAs(link, `${thisDocument?.fileTitle}.pdf`);
                      }
                    );
                  } else {
                    navigate(keys.DASHBOARD);
                  }
                }}
                backgroundColor={documentStyles?.color}
                disabled={
                  thisDocument?.status === "COMPLETED" || justSigned
                    ? thisDocument?.status !== "COMPLETED"
                    : false
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  thisDocument: documentSelector(state),
  accounts: accountsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDocument,
      setIsCreatingDocument,
      setRedirectParams,
      loadDocumentPdf,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DocumentOverview);

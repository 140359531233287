import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import classNames from "classnames/bind";
import { FORM_TYPE } from "../../../constants/config";
import { CButton, Icons } from "../../../components";
import Name from "./Name";
import String from "./String";
import Address from "./Address";
import Terms from "./Terms";
import Date from "./Date";
import Selects from "./Selects";
import Plan from "./Plan";
import Notice from "./Notice";
import OptionalName from "./OptionalName";
import Dropdown from "./Dropdown";
import { TimePeriod } from "./TimePeriod";

import styles from "./index.module.scss";

const PromtForms = ({
  prompts,
  onOpenHelp,
  submitForm,
  onFieldsChange,
  onNextCardGoPress,
  setAddressBeforeConfirm,
  addressBeforeConfirm,
  fieldsError,
  setDefaultCardData,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  isAllDataValid,
  cardData,
  currentPrompt,
  isGoingForward,
  documentStyles,
  documentLanguage,
  documentStylesLogo,
  cameFromEmail,
  checkboxValue,
  fieldError,
  totalPrice,
  setTotalPrice,
  setTotalBilling,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm(); // link to the main form

  // states responsible for animating the transition between forms
  const [newUp, setNewUp] = useState(false);
  const [oldUp, setOldUp] = useState(false);
  const [newDown, setNewDown] = useState(false);
  const [oldDown, setOldDown] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState(false);

  const cx = classNames.bind(styles);
  const globalClasses = cx("c-block-card", {
    moveOldElementUp: oldUp,
    moveNewElementUp: newUp,
    moveOldElementDown: oldDown,
    moveNewElementDown: newDown,
  });

  useEffect(() => {
    //scroll function to start
    const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: "auto" });
    };

    const handleNextStep = () => {
      setNewUp(false); //start animation of lifting the shape up
      setOldUp(true);
      setTimeout(() => {
        scrollTop();
        setOldUp(false); //start animation of lifting the shape up
        // setPreviousPosition(visibleIndex);
        setNewUp(true); //the beginning of the animation of the rise of a new form from down
        setTimeout(() => {
          setNewUp(false); //complete the animation
        }, 150); //animation delay (must be less than and below so that it doesn't start later than the previous one ends!!!)
      }, 75);
    };

    const handlePrevStep = () => {
      setOldDown(false);
      setNewDown(true);
      setTimeout(() => {
        scrollTop();
        setOldDown(true);
        setNewDown(false);
        setTimeout(() => {
          setOldDown(false);
        }, 150);
      }, 75);
    };

    setNumberOfInputForFocus(0);
    if (isGoingForward === undefined) {
      // In the case of the first card
      return;
    }
    return isGoingForward ? handleNextStep() : handlePrevStep();
  }, [currentPrompt, isGoingForward]);

  useEffect(() => {
    if (!defaultFormValues && cardData?.length) {
      const fields = {};

      cardData.forEach(({ name, value }) => {
        fields[name] = value;
      });

      form.setFieldsValue(fields);
      setDefaultFormValues(true);
    }
  }, [cardData, defaultFormValues, form]);

  useEffect(() => {
    setDefaultFormValues(false);
  }, [currentPrompt]);

  const shouldShowCard = (promptID) => {
    return currentPrompt?.promptID === promptID ? "block" : "none";
  };

  const onFinish = () => {
    submitForm();
  };

  console.log(currentPrompt);

  const formContent = (type, promptID, position, prompt = {}) => {
    const {
      answerPlaceholder,
      inputFieldLabel,
      questionType,
      isAllowedReceiverEditOption,
      inputCondition,
      answerOptions,
      noticeOptions,
      contractOptions,
    } = prompt;

    return (
      <div className={styles["card__icon"]}>
        {(() => {
          switch (type) {
            case FORM_TYPE.INFO:
              return <></>;
            case FORM_TYPE.COMPANY_NAME:
            case FORM_TYPE.CURRENCY:
              return (
                <Name
                  groupId={`${promptID}###${type}###${position}`}
                  label={inputFieldLabel}
                  placeholder={answerPlaceholder}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  select={true}
                  form={form}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  prompts={prompts}
                  cardData={cardData}
                  isCheckboxChecked={checkboxValue}
                  documentStyles={documentStyles}
                  fieldError={fieldError}
                  options={answerOptions}
                />
              );
            case FORM_TYPE.ADDRESS_AUTO_COMPLETE:
            case FORM_TYPE.STRING:
            case FORM_TYPE.NUMBER:
              return (
                <String
                  groupId={`${promptID}###${type}###${position}`}
                  label={inputFieldLabel}
                  placeholder={answerPlaceholder}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  type={
                    type === FORM_TYPE.STRING
                      ? FORM_TYPE.STRING
                      : type === FORM_TYPE.ADDRESS_AUTO_COMPLETE
                      ? FORM_TYPE.ADDRESS_AUTO_COMPLETE
                      : FORM_TYPE.NUMBER
                  }
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  setAddressBeforeConfirm={setAddressBeforeConfirm}
                  addressBeforeConfirm={addressBeforeConfirm}
                  form={form}
                  fieldsError={fieldsError}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  prompts={prompts}
                  cardData={cardData}
                  isCheckboxChecked={checkboxValue}
                  setDefaultCardData={setDefaultCardData}
                  documentStyles={documentStyles}
                  documentLanguage={documentLanguage}
                  onNextCardGoPress={onNextCardGoPress}
                  isAllDataValid={isAllDataValid}
                />
              );
            case FORM_TYPE.ADDRESS:
              return (
                <Address
                  groupId={`${promptID}###${type}###${position}`}
                  placeholder={answerPlaceholder}
                  label={inputFieldLabel}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  addressBeforeConfirm={addressBeforeConfirm}
                  setAddressBeforeConfirm={setAddressBeforeConfirm}
                  form={form}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  prompts={prompts}
                  prompt={prompt}
                  cardData={cardData}
                  documentStyles={documentStyles}
                  options={answerOptions}
                />
              );
            case FORM_TYPE.CONTRACT_LENGTH:
              return (
                <Terms
                  groupId={`${promptID}###${type}###${position}`}
                  placeholder={answerPlaceholder}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  form={form}
                  isCheckboxChecked={checkboxValue}
                  label={inputFieldLabel}
                  documentStyles={documentStyles}
                  cardData={cardData}
                />
              );
            case FORM_TYPE.VERIFICATION_NAME:
            case FORM_TYPE.PERSON_NAME:
              return (
                <Name
                  groupId={`${promptID}###${type}###${position}`}
                  label={inputFieldLabel}
                  placeholder={answerPlaceholder}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  prompts={prompts}
                  cardData={cardData}
                  isCheckboxChecked={checkboxValue}
                  documentStyles={documentStyles}
                  options={answerOptions}
                />
              );
            case FORM_TYPE.OPTIONAL_COMPANY_NAME:
              return (
                <OptionalName
                  groupId={`${promptID}###${type}###${position}`}
                  label={inputFieldLabel}
                  placeholder={answerPlaceholder}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  fieldsError={fieldsError}
                  cardData={cardData}
                  isCheckboxChecked={checkboxValue}
                  documentStyles={documentStyles}
                />
              );
            case FORM_TYPE.DATE:
              return (
                <Date
                  groupId={`${promptID}###${type}###${position}`}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  placeholder={answerPlaceholder}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  fieldsError={fieldsError}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  form={form}
                  inputCondition={inputCondition}
                  isCheckboxChecked={checkboxValue}
                  documentStyles={documentStyles}
                  cardData={cardData}
                />
              );
            case FORM_TYPE.SINGLE_SELECT:
            case FORM_TYPE.MULTI_SELECT:
              return (
                <Selects
                  groupId={`${promptID}###${type}###${position}`}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  typeSelect={
                    questionType === FORM_TYPE.SINGLE_SELECT
                      ? "radio"
                      : "checkbox"
                  }
                  options={answerOptions}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  form={form}
                  cardData={cardData}
                  prompts={prompts}
                  documentStyles={documentStyles}
                />
              );
            case FORM_TYPE.DROPDOWN:
              return (
                <Dropdown
                  groupId={`${promptID}###${type}###${position}`}
                  placeholder={answerPlaceholder}
                  label={inputFieldLabel}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  addressBeforeConfirm={addressBeforeConfirm}
                  form={form}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  checkbox={checkboxValue}
                  isCheckboxChecked={currentPrompt?.isAllowedReceiverEdit}
                  documentStyles={documentStyles}
                />
              );

            case FORM_TYPE.PLAN:
              return (
                <Plan
                  groupId={`${promptID}###${type}###${position}`}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  checkbox={!cameFromEmail && isAllowedReceiverEditOption}
                  select={true}
                  form={form}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  position={position}
                  prompts={prompts}
                  id={promptID}
                  cardData={cardData}
                  isCheckboxChecked={checkboxValue}
                  documentStyles={documentStyles}
                  fieldError={fieldError}
                  planOptions={answerOptions.planOptions}
                  discountOptions={answerOptions.discountOptions}
                  moduleOptions={answerOptions.moduleOptions}
                  extrasOptions={answerOptions.extrasOptions}
                  billingOptions={answerOptions.billingOptions}
                  trainingOptions={answerOptions.trainingOptions}
                  setTotalPrice={setTotalPrice}
                  setTotalBilling={setTotalBilling}
                  totalPrice={totalPrice}
                />
              );

            case FORM_TYPE.NOTICE:
              return (
                <Notice
                  groupId={`${promptID}###${type}###${position}`}
                  placeholder={answerPlaceholder}
                  label={inputFieldLabel}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                  addressBeforeConfirm={addressBeforeConfirm}
                  form={form}
                  fieldsError={fieldsError}
                  setDefaultCardData={setDefaultCardData}
                  visibleIndex={currentPrompt.position}
                  prompts={prompts}
                  position={position}
                  noticeOptions={noticeOptions}
                  contractOptions={contractOptions}
                  checkbox={checkboxValue}
                  isCheckboxChecked={currentPrompt?.isAllowedReceiverEdit}
                  documentStyles={documentStyles}
                />
              );

            case FORM_TYPE.TIME_PERIOD:
              return (
                <TimePeriod
                  groupId={`${promptID}###${type}###${position}`}
                  documentStyles={documentStyles}
                  prompts={prompts}
                  position={position}
                  numberOfInputForFocus={numberOfInputForFocus}
                  setNumberOfInputForFocus={setNumberOfInputForFocus}
                />
              );
            default:
              return <></>;
          }
        })()}
      </div>
    );
  };

  const setButtonTitle = () => {
    if (!currentPrompt) {
      return;
    }
    const { questionType } = currentPrompt;

    if (questionType === FORM_TYPE.PLAN) {
      if (totalPrice > 0) {
        const formatedPrice = new Intl.NumberFormat("de-DE", {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol",
          minimumFractionDigits: 2,
        })
          .format(totalPrice)
          .replace("€", "")
          .trim();

        return `€${formatedPrice} Bestätigen`;
      } else {
        return "Kein gültiger Preis";
      }
    }

    if (currentPrompt.position === 1 && questionType === FORM_TYPE.INFO) {
      return t("wizard:buttons.lets_go");
    }

    if (questionType === FORM_TYPE.ADDRESS) {
      return t("wizard:buttons.save_and_continue");
    }

    if (
      questionType === FORM_TYPE.PERSON_NAME ||
      questionType === FORM_TYPE.DATE ||
      questionType === FORM_TYPE.STRING ||
      questionType === FORM_TYPE.DROPDOWN
    ) {
      return t("wizard:buttons.confirm");
    }

    return t("wizard:buttons.continue");
  };

  if (!currentPrompt) {
    return null;
  }

  return (
    <Form
      layout="vertical"
      name="basic"
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      form={form}
      className="document-form"
    >
      {prompts?.map((prompt) => {
        const {
          position,
          question,
          description,
          tips,
          questionType,
          promptID,
        } = prompt;
        return (
          <div
            key={promptID}
            className={`${globalClasses}`}
            style={{
              display: shouldShowCard(promptID),
            }}
          >
            {(currentPrompt?.icon?.useStyleIcon ||
              currentPrompt?.icon?.url) && (
              <div className={styles["c-block-card__info-icon-wrapper"]}>
                <img
                  src={
                    currentPrompt?.icon?.url
                      ? currentPrompt.icon.url
                      : documentStylesLogo
                  }
                  className={styles["c-block-card__info-icon"]}
                  alt=""
                />
              </div>
            )}

            <div
              className={`${styles["c-block-card__head"]} ${
                tips ? styles["has-tips"] : ""
              }`}
            >
              <h1>
                <span style={{ fontFamily: documentStyles?.font }}>
                  {question}
                </span>
                {currentPrompt?.isMandatory === false && (
                  <span style={{ fontFamily: documentStyles?.font }}>
                    {t("send-contract:optional")}
                  </span>
                )}{" "}
                {tips && (
                  <span
                    onClick={() => onOpenHelp(tips)}
                    className={styles["c-block-card__help"]}
                    style={{
                      fontFamily: documentStyles?.font,
                      color: documentStyles?.color,
                    }}
                  >
                    <Icons.QuestionCircleIcon color={documentStyles?.color} />
                    {t("wizard:info.help")}
                  </span>
                )}
              </h1>
            </div>

            {description && (
              <p
                style={{
                  fontFamily: documentStyles?.font,
                }}
              >
                {description}
              </p>
            )}

            {formContent(questionType, promptID, position, prompt)}

            {questionType !== FORM_TYPE.SINGLE_SELECT && (
              <>
                <CButton
                  type="primary"
                  onClick={() => {
                    onNextCardGoPress({ toNext: true });
                  }}
                  customStyles={styles["c-block-card__btn"]}
                  title={setButtonTitle()}
                  disabled={!isAllDataValid}
                  backgroundColor={
                    documentStyles?.isApplyToWizard
                      ? documentStyles?.color
                      : undefined
                  }
                  font={documentStyles?.font}
                />
                <div
                  className={styles["c-block-card__info"]}
                  style={{
                    fontFamily: documentStyles?.font,
                  }}
                >
                  {t("wizard:info.with_enter")}
                </div>
              </>
            )}
          </div>
        );
      })}
    </Form>
  );
};

export default PromtForms;

import React from "react";
import PropTypes from "prop-types";

const RadioFilled = ({
  color = "#5F6CFF",
  circleColor = "#ECEDFF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill={circleColor} />
      <path
        d="M8 12.375C5.58375 12.375 3.625 10.4162 3.625 8C3.625 5.58375 5.58375 3.625 8 3.625C10.4162 3.625 12.375 5.58375 12.375 8C12.375 10.4162 10.4162 12.375 8 12.375Z"
        stroke={color}
        strokeWidth="1.25"
      />
      <rect
        x="6.125"
        y="6.125"
        width="3.75"
        height="3.75"
        rx="1.875"
        fill={color}
        stroke={color}
        strokeWidth="1.25"
      />
    </svg>
  );
};

RadioFilled.propTypes = {
  color: PropTypes.string,
};

export default RadioFilled;

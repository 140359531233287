import React from "react";
import { EyeOutlined } from "@ant-design/icons";

import "./index.scss";

export const VisitCounter = ({ visitCount }) => {
  if (!Number.isInteger(visitCount)) {
    return null;
  }
  return (
    <div className="visitCounter-container">
      <EyeOutlined className="visitCounter-icon" />
      <span className="visitCounter-count">{visitCount}</span>
    </div>
  );
};

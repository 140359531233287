import { useRef, useState, useEffect } from "react";
import { Input, Switch } from "antd";

export const Reminder = ({
  switchFunction,
  recipient,
  defaultReminder,
  setNewDocumentData,
  saveEdit,
}) => {
  const inputDate = useRef(null);
  const [input, setInput] = useState();
  const [checked, setChecked] = useState(false);

  function setReminderDays(days) {
    if (saveEdit) {
      saveEdit({
        defaultReminder: {
          ...defaultReminder,
          active: true,
          days: days > 0 ? days : 0,
        },
      });
    } else {
      setNewDocumentData({
        reminder: {
          ...defaultReminder,
          active: true,
          days: days > 0 ? days : 0,
        },
      });
    }
  }

  function handleSetChecked() {
    if (checked && switchFunction) {
      switchFunction();
    }
    setChecked((prev) => !prev);
  }

  useEffect(() => {
    if (defaultReminder?.days) {
      setInput(Number(defaultReminder?.days));
    }
  }, [defaultReminder]);

  useEffect(() => {
    if (input > 0) {
      switchFunction && switchFunction();
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [input, switchFunction]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 6,
      }}
    >
      <span>
        Send automated reminders every&nbsp;
        <Input
          min="1"
          ref={inputDate}
          type="number"
          value={input}
          onChange={(event) => {
            setInput(event.target.value);
            setReminderDays(Number(event.target.value));
          }}
          style={{
            background: "#F3F3F6",
            padding: "6px 4px",
            borderRadius: "6px",
            width: 26,
            height: 28,
            border: 0,
            borderColor: "#F3F3F6 !important",
            boxShadow: "none",
          }}
          onClick={() => inputDate.current.focus()}
          onBlur={() => inputDate.current.blur()}
        />
        &nbsp;days to {recipient}
      </span>

      <Switch
        checked={checked}
        title="Pick a valid time before the chosen date to be reminded"
        size="small"
        onChange={handleSetChecked}
        disabled={input > 0 ? false : true}
      />
    </div>
  );
};

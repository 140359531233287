import React from "react";
import PropTypes from "prop-types";

const ArrowRightIcon = ({
  color = "currentColor",
  className,
  width = "34",
  height = "28",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.75 2.28523V0.214729C12.75 0.0352494 12.5438 -0.0638509 12.4045 0.0459691L0.3295 9.47723C0.2269 9.55703 0.143901 9.65913 0.0868006 9.77593C0.0297006 9.89263 0 10.0209 0 10.1508C0 10.2808 0.0297006 10.4091 0.0868006 10.5258C0.143901 10.6426 0.2269 10.7447 0.3295 10.8245L12.4045 20.2558C12.5465 20.3656 12.75 20.2665 12.75 20.087V18.0165C12.75 17.8852 12.6884 17.7593 12.5866 17.679L2.9438 10.1522L12.5866 2.62273C12.6884 2.54243 12.75 2.41653 12.75 2.28523Z"
        fill={color}
      />
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  color: PropTypes.string,
};

export default ArrowRightIcon;

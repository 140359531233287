import React from "react";
import { useTranslation } from "react-i18next";
import { Icons } from "..";

import styles from "./index.module.scss";

const HelpCenter = (props) => {
  const { data = [], allTips = [], font } = props;
  const { t } = useTranslation();

  return (
    <div className={styles["h-сenter-wrap"]}>
      <div className={styles["h-сenter-wrap-header"]}>
        <Icons.LightBulbIcon
          className={styles["h-сenter-wrap-header__question-icon"]}
        />
        <h1
          className={styles["h-сenter-wrap-header__title"]}
          style={{ fontFamily: font }}
        >
          {t("wizard:right_panel.help_center.title")}
        </h1>
      </div>
      <div className={styles["h-сenter-wrap-content"]}>
        {data?.map((item, index) => (
          <div key={index} className={styles["h-сenter-wrap-content-item"]}>
            {allTips.some((allTipsItem) => allTipsItem.tipID === item) && (
              <>
                <h2
                  className={styles["h-сenter-wrap-content-item__title"]}
                  style={{ fontFamily: font }}
                >
                  {
                    allTips.filter(
                      (allTipsItem) => allTipsItem.tipID === item
                    )?.[0]?.title
                  }
                </h2>

                <p
                  className={styles["h-сenter-wrap-content-item__text"]}
                  style={{ fontFamily: font }}
                >
                  {
                    allTips.filter(
                      (allTipsItem) => allTipsItem.tipID === item
                    )?.[0]?.content
                  }
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpCenter;

import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  color = "#5F6CFF",
  circleColor = "#ECEDFF",
  className,
  size = "24",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="16" height="16" rx="4" fill={circleColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.5H11.5C12.05 3.5 12.5 3.95 12.5 4.5V11.5C12.5 12.05 12.05 12.5 11.5 12.5H4.5C3.95 12.5 3.5 12.05 3.5 11.5V4.5C3.5 3.95 3.95 3.5 4.5 3.5ZM6.65 10.145C6.845 10.34 7.16 10.34 7.355 10.145L11.145 6.35C11.34 6.155 11.34 5.84 11.145 5.645C10.95 5.45 10.635 5.45 10.44 5.645L7 9.085L5.56 7.645C5.365 7.45 5.05 7.45 4.855 7.645C4.76137 7.73842 4.70876 7.86524 4.70876 7.9975C4.70876 8.12976 4.76137 8.25658 4.855 8.35L6.65 10.145Z"
        fill={color}
      />
    </svg>
  );
};

Checkbox.propTypes = {
  color: PropTypes.string,
};

export default Checkbox;

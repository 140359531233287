import React from "react";
import PropTypes from "prop-types";

const MoneyQuestion = ({
  color = "#5F6CFF",
  className,
  size = "24",
  circleColor = "#ECEDFF",
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="6" fill={circleColor} />
      <path
        d="M10 3.75C6.54855 3.75 3.75 6.54855 3.75 10C3.75 13.4515 6.54855 16.25 10 16.25C13.4515 16.25 16.25 13.4515 16.25 10C16.25 6.54855 13.4515 3.75 10 3.75ZM10.8859 8.99275C10.9473 8.99275 10.9975 9.04297 10.9975 9.10435V9.48382C10.9975 9.5452 10.9473 9.59542 10.8859 9.59542H8.98856C8.98438 9.65681 8.98438 9.72238 8.98438 9.78795V10.2902H10.8845C10.9459 10.2902 10.9961 10.3404 10.9961 10.4018V10.7812C10.9961 10.8426 10.9459 10.8929 10.8845 10.8929H9.06389C9.27734 11.7578 9.91909 12.2684 10.8747 12.2684C11.1523 12.2684 11.3923 12.2517 11.5974 12.2112C11.6657 12.1973 11.7299 12.2503 11.7299 12.32V12.9171C11.7299 12.9701 11.6922 13.0148 11.6406 13.0259C11.4188 13.0734 11.1621 13.0971 10.8691 13.0971C9.33733 13.0971 8.31613 12.2768 8.07617 10.8929H7.65625C7.59487 10.8929 7.54464 10.8426 7.54464 10.7812V10.4018C7.54464 10.3404 7.59487 10.2902 7.65625 10.2902H8.02037V9.77539C8.02037 9.71401 8.02037 9.65262 8.02455 9.59682H7.65625C7.59487 9.59682 7.54464 9.5466 7.54464 9.48521V9.10575C7.54464 9.04436 7.59487 8.99414 7.65625 8.99414H8.09849C8.37333 7.67997 9.38198 6.9029 10.8691 6.9029C11.1607 6.9029 11.4188 6.92941 11.6406 6.97824C11.6922 6.9894 11.7285 7.03404 11.7285 7.08705V7.68415H11.7299C11.7299 7.7553 11.6657 7.80692 11.596 7.79297C11.3909 7.75251 11.1523 7.73158 10.8747 7.73158C9.96233 7.73158 9.33454 8.19894 9.09459 8.99275H10.8859Z"
        fill={color}
      />
    </svg>
  );
};

MoneyQuestion.propTypes = {
  color: PropTypes.string,
};

export default MoneyQuestion;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import * as keys from "../../utils/keys";
import { isProduction } from "../../utils/isProduction";

import { Folder, Icons } from "..";
import styles from "./index.module.scss";
import { BookOutlined, RightOutlined } from "@ant-design/icons";

const WorkspaceSidebar = () => {
  const { pathname } = useLocation();

  return (
    <aside className={styles["c-workspace-sidebar"]}>
      <div style={{ position: "fixed", width: "252px" }}>
        <Link
          to={keys.DASHBOARD}
          className={`${styles["c-workspace-sidebar__link"]} ${
            pathname === "/" && styles["active"]
          }`}
        >
          <span className={styles["c-workspace-sidebar__link__icon"]}>
            <Icons.DashboardIcon />
          </span>
          <span className={styles["c-workspace-sidebar__link__text"]}>
            Dashboard
          </span>
        </Link>
        {<Folder />}
      </div>

      <div style={{ position: "fixed", width: "252px", bottom: 0 }}>
        <Link
          to={keys.TEMPLATES}
          className={styles["c-workspace-sidebar__link"]}
        >
          <span className={styles["c-workspace-sidebar__link__icon"]}>
            <BookOutlined
              style={{
                fontSize: 20,
              }}
            />
          </span>
          <span className={styles["c-workspace-sidebar__link__text"]}>
            Manage templates
          </span>
          <span>
            <RightOutlined />
          </span>
        </Link>
      </div>
    </aside>
  );
};

export default WorkspaceSidebar;

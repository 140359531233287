import React, { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";

import {
  Layout,
  Header,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  NavBarBottom,
  Modals,
  WorkspacesList,
} from "../../components";
import WorkspaceTemplateCard from "../../components/WorkspaceCard/template";

import { accountsSelector, userSelector } from "../../store/selectors/auth";

import {
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
} from "../../store/actions/prompts";

import {
  setErrorLoadingScreenType,
  createDocumentFunction,
  setToken,
} from "../../store/actions/auth";

import stylesWorkspace from "../../components/WorkspaceTabs/index.module.scss";
import styles from "../../styles/Workspace.module.scss";
import TemplateWorkspaceSidebar from "../../components/WorkspaceSidebar/template";
import { store } from "../../store";
import { api } from "../../services/api";
import { notification } from "antd";

const sortParse = {
  updatedAt: "Last updated",
  createdAt: "Created at",
  title: "Title",
};

const Templates = ({
  account,
  userData,
  createDocumentFunction,
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
}) => {
  let navigate = useNavigate();

  const templatesRef = useRef([]);
  const sortDropdownRef = useRef();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [activeSelect, setActiveSelect] = useState(false);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [itemToDelete, setItemToDelete] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [nowEditing, setNowEditing] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplatePosition, setSelectedTemplatePosition] = useState(0);

  useEffect(() => {
    templatesRef.current = templatesRef.current.slice(0, templates);
  }, [templates]);

  useEffect(() => {
    store.dispatch(setErrorLoadingScreenType("load"));
    setTimeout(() => {
      api
        .get("template-gen", {
          params: {
            order: sortBy,
          },
        })
        .then((res) => {
          setTemplates(res.data);
          store.dispatch(setErrorLoadingScreenType(""));
        })
        .catch(() => {
          store.dispatch(setErrorLoadingScreenType("error"));
        });
    }, 100);
  }, [sortBy]);

  function openHowCanWeHelp() {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  }

  function handleSort(e) {
    const sort = e.currentTarget.dataset.name;
    setSortBy(sort);
    setActiveSelect(false);
  }

  function handleConfirmDelete() {
    api
      .delete(`template/${itemToDelete}`)
      .then(() => {
        const item = templates.find((i) => i.templateID === itemToDelete);
        setTemplates((old) => old.filter((i) => i.templateID !== itemToDelete));
        notification.info({
          message: "Template deleted!",
          description: `Template "${item.fileTitle}" successfully deleted.`,
          duration: 3,
          placement: "bottom",
          icon: (
            <Icons.StatusAcceptedIconWithWhiteBackground color={"#42B983"} />
          ),
          closeIcon: <></>,
          bottom: 0,
        });
      })
      .catch(() => {
        notification.info({
          message: "Template not deleted!",
          description:
            "There was an error while trying to delete the template.",
          duration: 3,
          placement: "bottom",
          icon: (
            <Icons.WarningFillIconWithWhiteBackground
              color={"#E53935"}
              width={"22"}
              height={"20"}
            />
          ),
          closeIcon: <></>,
          bottom: 0,
        });
      });
    setItemToDelete();
  }

  const handleContextMenu = useCallback(
    (event) => {
      event.preventDefault();

      for (let i = 0; i < templates.length; i++) {
        if (
          !!templates?.current[i] &&
          templates?.current[i].contains(event.target)
        ) {
          setActiveDropdown(templates.current[i]?.id);
        }
      }

      setNowEditing(null);
    },
    [templates]
  );

  const handleClick = useCallback(
    (e) => {
      if (activeDropdown) {
        setActiveDropdown(null);
      }

      if (!!nowEditing) {
        e.preventDefault();
        for (let i = 0; i < templates.length; i++) {
          if (
            !!templatesRef?.current[i] &&
            templatesRef?.current[i].id === nowEditing &&
            !templatesRef?.current[i].contains(e.target)
          ) {
            setNowEditing(null);
          }
        }
      }
    },
    [nowEditing, templates, activeDropdown]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [handleClick, handleContextMenu]);

  function handleCreateTemplate() {
    store.dispatch(setErrorLoadingScreenType("load"));
    api
      .post(
        "/template",
        {
          fileTitle: "untitled",
          content: "",
          type: "GENERATED",
        },
        {
          params: {
            origin: "BLANK",
          },
        }
      )
      .then((res) => {
        navigate(`${keys.TEMPLATE}/${res.data?.templates?.templateID}`);
      })
      .catch(() => {
        store.dispatch(setErrorLoadingScreenType("error"));
      });
  }

  return (
    <Layout
      header={
        <Header
          leftType="auth"
          dashboard={
            <>
              <CButton
                onClick={handleCreateTemplate}
                icon={<Icons.PlusIcon />}
                type="primary"
                title={"New template"}
              />
            </>
          }
          dropdownOpened={(isOpen) => {
            setIsMenuOpen(isOpen);
          }}
          userData={userData}
          accountData={account?.[0]}
        />
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      workspaces={<WorkspacesList />}
    >
      <div className={styles["c-folders-view"]}>
        <TemplateWorkspaceSidebar />
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <>
          <div className={styles["c-folders-view__section"]}>
            <div className={styles["c-folders-view__head"]}>
              <div className={styles["c-folders-view__head__info"]}>
                All templates
              </div>

              <div className={styles["u-sorted-by"]}>
                <div className={styles["u-sorted-by__title"]}>Sorted by:</div>
                <div className={styles["u-sorted-by__value"]}>
                  <div
                    onClick={() => {
                      activeSelect === `select-${1}`
                        ? setActiveSelect(null)
                        : setActiveSelect(`select-${1}`);
                    }}
                    className={styles["u-sorted-by__name"]}
                  >
                    {sortParse[sortBy]}
                  </div>

                  <div
                    className={`u-workspace__dropdown is-sorted-by ${
                      activeSelect === `select-${1}` ? "active" : ""
                    }`}
                    ref={sortDropdownRef}
                  >
                    <div className={"u-workspace__dropdown__row"}>
                      <div className={"u-workspace__dropdown__item"}>
                        <div
                          onClick={handleSort}
                          data-name={"updatedAt"}
                          className={"u-workspace__dropdown__link"}
                        >
                          <i>
                            <Icons.RenameIcon />
                          </i>
                          <span>Last updated</span>
                        </div>
                      </div>
                      <div className={"u-workspace__dropdown__item"}>
                        <div
                          onClick={handleSort}
                          data-name={"createdAt"}
                          className={"u-workspace__dropdown__link"}
                        >
                          <i>
                            <Icons.ClockTimeIcon />
                          </i>
                          <span>Created at</span>
                        </div>
                      </div>
                      <div className={"u-workspace__dropdown__item"}>
                        <div
                          onClick={handleSort}
                          data-name={"title"}
                          className={"u-workspace__dropdown__link"}
                        >
                          <i>
                            <Icons.TitleIcon />
                          </i>
                          <span>Title</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles["c-folders-view__body"]}>
              <div
                data-tab-body={"recently-edited"}
                className={`${stylesWorkspace["table"]} ${stylesWorkspace["active"]}`}
              >
                <div className={stylesWorkspace["thead"]}>
                  <div className={stylesWorkspace["tr"]}>
                    <div
                      className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-title"]}`}
                    >
                      TITLE
                    </div>
                    <div
                      className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-last-update"]}`}
                    >
                      Last UPDATE
                    </div>
                    <div
                      className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-status"]}`}
                    >
                      STATUS
                    </div>
                    <div
                      className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-parties"]} ${stylesWorkspace["w-350"]}`}
                    ></div>
                    <div
                      className={`${stylesWorkspace["th"]} ${stylesWorkspace["th-action"]}`}
                    >
                      ACTIONS
                    </div>
                  </div>
                </div>
                <div className={stylesWorkspace["tbody"]}>
                  {templates.map((item, index) => {
                    return (
                      <div key={index} className={stylesWorkspace["item"]}>
                        <WorkspaceTemplateCard
                          onUseTemplate={() =>
                            setSelectedTemplatePosition(index + 1)
                          }
                          hideSignees
                          ref={(el) => (templatesRef.current[index] = el)}
                          key={`folder-${item.id}`}
                          item={item}
                          width={true}
                          onOptionsPress={() => {
                            if (activeDropdown !== item.templateID) {
                              setActiveDropdown(item.templateID);
                            } else {
                              setActiveDropdown(null);
                            }
                          }}
                          isDropdownActive={activeDropdown === item.templateID}
                          handleDelete={() => setItemToDelete(item.templateID)}
                          nowEditing={nowEditing}
                          setNowEditing={setNowEditing}
                          isLastCard={templates.length - 1 === index}
                          getDocument={() => {}}
                          setErrorLoadingScreenType={setErrorLoadingScreenType}
                          setTemplates={setTemplates}
                        />
                      </div>
                    );
                  })}
                  {itemToDelete && (
                    <Modals.ConfirmTemplateDelete
                      onClose={handleConfirmDelete}
                      onCancel={() => {
                        setItemToDelete();
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      {!isMenuOpen && !openRightPanel && <NavBarBottom />}

      {!!selectedTemplatePosition && (
        <Modals.PreviewDocument
          onClose={() => setSelectedTemplatePosition(0)}
          templateData={templates[selectedTemplatePosition - 1]}
          createDocumentFunction={createDocumentFunction}
          setDocumentData={setDocumentData}
          setIndex={setIndex}
          setIsCreatingDocument={setIsCreatingDocument}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: accountsSelector(state),
  userData: userSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createDocumentFunction,
      setDocumentData,
      setIndex,
      setIsCreatingDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Templates);

import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import CommentsAndEdits from "./components/CommentsAndEdits";
import useMediaQuery from "../../hooks/helpers/useMediaQuery";
import { Button, Drawer, Affix } from "antd";
import Icons from "../Icons";

const SuggestionsAndComments = ({
  thisDocument,
  isOpen,
  onClose,
  isSign,
  isPreview,
  currentShareUser,
  shouldShowComments,
  onOpen
}) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  if (!thisDocument || !shouldShowComments) return null;

  if (isMobile)
    return (
      <Drawer
        title={<span className={styles.drawerTitle}>Comments</span>}
        placement="bottom"
        height="100%"
        onClose={onClose}
        open={isOpen}
        closable={false}
        // mask={false}
        push={false}
        headerStyle={{
          padding: "12px 16px",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
        }}
        bodyStyle={{
          padding: 0,
          overflow: "hidden",
          display: "flex"
        }}
        extra={
          <Button
            type="text"
            icon={<Icons.CloseIcon size={16} color="#8C8C97" />}
            onClick={onClose}
          />
        }
      >
        <div
          className={styles.background}
        >
          <CommentsAndEdits {...{ isOpen, onClose }} />
        </div>
      </Drawer>
    );

  return (
    <Affix
      offsetTop={62}
      style={{
        display: isOpen ? "block" : "none",
        minWidth: "305px",
      }}
    >
      <div>
        <aside className={styles.wrapper}>
          <CommentsAndEdits {...{ isOpen, onClose, onOpen }} />
        </aside>
      </div>
    </Affix>
  );
};

export default SuggestionsAndComments;

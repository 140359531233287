import React from "react";
import PropTypes from "prop-types";

const MoreIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="4"
      height="13"
      viewBox="0 0 4 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.804688 1.69529C0.804688 2.01231 0.93062 2.31633 1.15478 2.5405C1.37894 2.76466 1.68297 2.89059 1.99998 2.89059C2.31699 2.89059 2.62102 2.76466 2.84518 2.5405C3.06934 2.31633 3.19528 2.01231 3.19528 1.69529C3.19528 1.37828 3.06934 1.07425 2.84518 0.850094C2.62102 0.625933 2.31699 0.5 1.99998 0.5C1.68297 0.5 1.37894 0.625933 1.15478 0.850094C0.93062 1.07425 0.804688 1.37828 0.804688 1.69529ZM0.804688 6.47647C0.804688 6.79348 0.93062 7.09751 1.15478 7.32167C1.37894 7.54583 1.68297 7.67177 1.99998 7.67177C2.31699 7.67177 2.62102 7.54583 2.84518 7.32167C3.06934 7.09751 3.19528 6.79348 3.19528 6.47647C3.19528 6.15946 3.06934 5.85543 2.84518 5.63127C2.62102 5.40711 2.31699 5.28118 1.99998 5.28118C1.68297 5.28118 1.37894 5.40711 1.15478 5.63127C0.93062 5.85543 0.804688 6.15946 0.804688 6.47647ZM0.804688 11.2576C0.804688 11.5747 0.93062 11.8787 1.15478 12.1028C1.37894 12.327 1.68297 12.4529 1.99998 12.4529C2.31699 12.4529 2.62102 12.327 2.84518 12.1028C3.06934 11.8787 3.19528 11.5747 3.19528 11.2576C3.19528 10.9406 3.06934 10.6366 2.84518 10.4124C2.62102 10.1883 2.31699 10.0624 1.99998 10.0624C1.68297 10.0624 1.37894 10.1883 1.15478 10.4124C0.93062 10.6366 0.804688 10.9406 0.804688 11.2576Z"
        fill={color}
      />
    </svg>
  );
};

MoreIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default MoreIcon;

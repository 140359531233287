import React from "react";
import { CDataSelect } from "../../../components";

const Date = ({
  groupId,
  previousDate,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
  placeholder,
  inputCondition,
  visibleIndex,
  form,
  position,
  checkbox,
  isCheckboxChecked,
  documentStyles,
  cardData,
}) => {
  return (
    <CDataSelect
      groupId={groupId}
      previousDate={previousDate} //data on the previous value of the fields
      numberOfInputForFocus={numberOfInputForFocus} //field number that is currently active
      setNumberOfInputForFocus={setNumberOfInputForFocus} //function to change the field number that is currently active
      placeholder={placeholder}
      inputCondition={inputCondition}
      visibleIndex={visibleIndex}
      position={position}
      form={form}
      checkbox={checkbox}
      isCheckboxChecked={isCheckboxChecked}
      documentStyles={documentStyles}
      cardData={cardData}
    />
  );
};

export default Date;

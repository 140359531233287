import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AutoComplete, Select } from "antd";
import styles from "./index.module.scss";

const { Option } = Select;

const arrayItems = ["Germany"];

const CAutoComplete = ({
  onChange = () => {},
  onSelect = () => {},
  onSearch = () => {},
  onClick = () => {},
  onBlur = () => {},
  label = false,
  tooltip,
  options,
  placeholder,
  customStyles,
  errors,
  focusOn,
  value,
  font,
  color,
  disabled,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (color) {
      const selectors = document.getElementsByClassName("ant-select-selector");

      for (let i = 0; i < selectors.length; i++) {
        if (selectors[i].offsetParent !== null) {
          if (isHover || isFocus) {
            selectors[i].style.borderColor = color;
          } else {
            selectors[i].style.borderColor = "#d9d9d9";
          }

          if (isFocus) {
            selectors[i].style.boxShadow = `0px 0px 0px 4px ${color}33`;
          } else {
            selectors[i].style.boxShadow = `none`;
          }
        }
      }

      const options = document.getElementsByClassName("option");

      for (let i = 0; i < options.length; i++) {
        options[i].style.background = "#fff";
        options[i].style.color = "#000";
      }

      const optionsActive = document.getElementsByClassName(
        "ant-select-item-option-active"
      );

      for (let i = 0; i < optionsActive.length; i++) {
        optionsActive[i].style.background = `${color}33`;
        optionsActive[i].style.color = color;
      }

      const optionsSelected = document.getElementsByClassName(
        "ant-select-item-option-selected"
      );

      for (let i = 0; i < optionsSelected.length; i++) {
        optionsSelected[i].style.background = `${color}33`;
        optionsSelected[i].style.color = color;
      }
    }
  }, [isHover, isFocus]);

  useEffect(() => {
    if (color) {
      const options = document.getElementsByClassName("option");

      for (let i = 0; i < options.length; i++) {
        options[i].style.background = "#fff";
        options[i].style.color = "#000";
      }

      const optionsActive = document.getElementsByClassName(
        "ant-select-item-option-active"
      );

      for (let i = 0; i < optionsActive.length; i++) {
        optionsActive[i].style.background = `${color}33`;
        optionsActive[i].style.color = color;
      }

      const optionsSelected = document.getElementsByClassName(
        "ant-select-item-option-selected"
      );

      for (let i = 0; i < optionsSelected.length; i++) {
        optionsSelected[i].style.background = `${color}33`;
        optionsSelected[i].style.color = color;
      }
    }
  });

  return (
    <div className={styles["u-select-wrap"]}>
      {label && (
        <label className={styles["u-style-label"]} style={{ fontFamily: font }}>
          {label} {tooltip}
        </label>
      )}
      <AutoComplete
        disabled={disabled}
        className={[
          styles["u-style-input"],
          errors && styles["u-style-input__error"],
          customStyles,
        ].join(" ")}
        placeholder={placeholder}
        onSearch={onSearch}
        onSelect={(value, params) => {
          onSelect(value, params.key);
          setIsFocus(false); // Set setIsFocus to false when user selects an option
        }}
        onChange={onChange}
        onClick={onClick}
        ref={(input) => {
          focusOn
            ? input && input.focus() //focus on the field if "true" is transmitted
            : input && input.blur(); //make the field inactive if "false" is passed
        }}
        value={value}
        style={{
          fontFamily: font,
        }}
        onMouseOver={() => {
          setIsHover(true);
        }}
        onMouseOut={() => {
          setIsHover(false);
        }}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          onBlur();
          setIsFocus(false);
        }}
        dropdownStyle={{ color: color }}
      >
        {options.map((item, index) => {
          return (
            <Option
              key={item?.id}
              value={item?.value || undefined}
              defaultChecked={false}
              style={{ fontFamily: font }}
              className={`option`}
            >
              {item?.icon || null} {item?.value || item}
            </Option>
          );
        })}
      </AutoComplete>
    </div>
  );
};

CAutoComplete.propTypes = {};

export default CAutoComplete;

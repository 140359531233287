import React from "react";
import PropTypes from "prop-types";

const MobileMenu = ({ size = 24, color = "currentColor", className }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 5.97634C10.5 6.37417 10.658 6.7557 10.9393 7.03701C11.2206 7.31832 11.6022 7.47635 12 7.47635C12.3978 7.47635 12.7794 7.31832 13.0607 7.03701C13.342 6.7557 13.5 6.37417 13.5 5.97634C13.5 5.57851 13.342 5.19697 13.0607 4.91566C12.7794 4.63436 12.3978 4.47632 12 4.47632C11.6022 4.47632 11.2206 4.63436 10.9393 4.91566C10.658 5.19697 10.5 5.57851 10.5 5.97634ZM10.5 11.9764C10.5 12.3742 10.658 12.7558 10.9393 13.0371C11.2206 13.3184 11.6022 13.4764 12 13.4764C12.3978 13.4764 12.7794 13.3184 13.0607 13.0371C13.342 12.7558 13.5 12.3742 13.5 11.9764C13.5 11.5786 13.342 11.197 13.0607 10.9157C12.7794 10.6344 12.3978 10.4764 12 10.4764C11.6022 10.4764 11.2206 10.6344 10.9393 10.9157C10.658 11.197 10.5 11.5786 10.5 11.9764ZM10.5 17.9765C10.5 18.3743 10.658 18.7558 10.9393 19.0372C11.2206 19.3185 11.6022 19.4765 12 19.4765C12.3978 19.4765 12.7794 19.3185 13.0607 19.0372C13.342 18.7558 13.5 18.3743 13.5 17.9765C13.5 17.5787 13.342 17.1971 13.0607 16.9158C12.7794 16.6345 12.3978 16.4765 12 16.4765C11.6022 16.4765 11.2206 16.6345 10.9393 16.9158C10.658 17.1971 10.5 17.5787 10.5 17.9765Z"
        fill={color}
      />
    </svg>
  );
};

MobileMenu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default MobileMenu;

// export const HOME = `/`;
export const WIZARD = `/wizard`;
export const REVIEW_INPUT = `/doc`;
export const REVIEW_CONTRACTS = `/review-contract`;
export const SEND_CONTRACT = `/send-contract`;
export const SIGNEE = `/signee`;
export const DOCUMENT_OVERVIEW = `/document`;
export const DOCUMENT_CONTRACT = `/document`;
export const DASHBOARD = `/`;
export const ACCOUNT_SETTINGS = `/account-settings`;
export const WORKSPACE_MEMBERS = `/workspace-members`;
export const WORKSPACE_SETTINGS = `/workspace-settings`;
export const CREATE_DOCUMENT = `/create-document`;
export const FOLDERS = "/folders";
export const DOCUMENT_STATUS = "/document-status";
export const SIGN_SEND = "/sign-send";
export const SIGN = "/sign";
export const TEMPLATES = "/templates";
export const CREATE_TEMPLATE = "/create-template";
export const TEMPLATE = "/template";
export const SALESFORCE_AUTHORIZATION = "/salesforce-callback";
export const CREATE_WORKSPACE = "/create-workspace";
export const SEARCH = "/search";

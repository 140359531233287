import { combineReducers } from "redux";

import promptsReducer from "./promptsReducer";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import token from "./token";
import auth0 from "./auth0Slice";

const rootReducer = combineReducers({
  promptsReducer,
  authReducer,
  dashboardReducer,
  token,
  auth0,
});

export default rootReducer;

import React from "react";
import PropTypes from "prop-types";

import { Icons } from "..";

import styles from "./index.module.scss";

const Layout = ({
  header,
  children,
  onQuestionButtonClick,
  helpButtonBottom,
  questionIconColor,
  showButtonBottom,
  workspaces,
  wrapperStyle,
  wrapperClass,
}) => {
  return (
    <div style={{ display: "flex", flex: 1, position: "relative" }}>
      {workspaces}
      <div
        className={`${styles["c-wrapper"]} ${wrapperClass ? wrapperClass : ""}`}
        style={{ ...wrapperStyle }}
      >
        <div
          style={{
            position: "fixed",
            width: "-webkit-fill-available",
            zIndex: 1,
          }}
        >
          {header}
        </div>
        <main className={styles["c-content"]}>{children}</main>
      </div>
      {showButtonBottom ? (
        <div
          className={styles["c-btn-question"]}
          onClick={onQuestionButtonClick}
          style={{ bottom: `${helpButtonBottom}` }}
        >
          <Icons.QuestionIcon color={questionIconColor} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

Layout.defaultProps = {
  header: "",
  children: "",
  showButtonBottom: true,
};

Layout.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onQuestionButtonClick: PropTypes.func,
  helpButtonBottom: PropTypes.string.isRequired,
  showButtonBottom: PropTypes.bool,
};

export default Layout;

import React from "react";
import PropTypes from "prop-types";

const PdfIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.647 5.01339L10.8435 0.209821C10.7095 0.0758929 10.5287 0 10.339 0H0.856864C0.461775 0 0.142578 0.319196 0.142578 0.714286V19.2857C0.142578 19.6808 0.461775 20 0.856864 20H15.1426C15.5377 20 15.8569 19.6808 15.8569 19.2857V5.52009C15.8569 5.33036 15.781 5.14732 15.647 5.01339ZM14.2095 5.84821H10.0087V1.64732L14.2095 5.84821ZM14.2497 18.3929H1.74972V1.60714H8.49079V6.42857C8.49079 6.67721 8.58957 6.91567 8.76538 7.09148C8.9412 7.2673 9.17965 7.36607 9.42829 7.36607H14.2497V18.3929Z"
        fill={color}
      />
      <path
        d="M3.02775 16.9732H3.79837V15.6232H4.71525C5.50837 15.6232 6.01462 15.072 6.01462 14.3182C6.01462 13.587 5.51962 13.0357 4.71525 13.0357H3.02775V16.9732ZM3.79837 14.9314V13.722H4.57462C4.98525 13.722 5.22712 13.9357 5.22712 14.3182C5.22712 14.7289 5.00212 14.9314 4.57462 14.9314H3.79837ZM6.6807 16.9732H8.02508C9.24008 16.9732 9.97695 16.1014 9.97695 15.0045C9.97695 13.8232 9.18383 13.0357 8.02508 13.0357H6.6807V16.9732ZM7.40633 16.287V13.7276H7.97445C8.75633 13.7276 9.18945 14.1889 9.18945 15.0045C9.18945 15.8257 8.75633 16.287 7.97445 16.287H7.40633ZM10.6413 16.9732H11.4119V15.3926H13.1725V14.7007H11.4119V13.7276H13.3525V13.0357H10.6413V16.9732Z"
        fill={color}
      />
    </svg>
  );
};

PdfIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

export default PdfIcon;

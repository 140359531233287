import React from "react";
import PropTypes from "prop-types";

const WarningIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M18.6665 16.7188L10.5415 2.65625C10.4204 2.44727 10.2114 2.34375 10.0005 2.34375C9.78956 2.34375 9.57862 2.44727 9.45948 2.65625L1.33448 16.7188C1.09424 17.1367 1.39503 17.6562 1.87549 17.6562H18.1255C18.606 17.6562 18.9067 17.1367 18.6665 16.7188ZM3.36378 16.1738L10.0005 4.68555L16.6372 16.1738H3.36378Z"
      />
      <path
        fill={color}
        d="M9.0625 14.0625C9.0625 14.3111 9.16127 14.5496 9.33709 14.7254C9.5129 14.9012 9.75136 15 10 15C10.2486 15 10.4871 14.9012 10.6629 14.7254C10.8387 14.5496 10.9375 14.3111 10.9375 14.0625C10.9375 13.8139 10.8387 13.5754 10.6629 13.3996C10.4871 13.2238 10.2486 13.125 10 13.125C9.75136 13.125 9.5129 13.2238 9.33709 13.3996C9.16127 13.5754 9.0625 13.8139 9.0625 14.0625ZM9.375 8.125V11.7188C9.375 11.8047 9.44531 11.875 9.53125 11.875H10.4688C10.5547 11.875 10.625 11.8047 10.625 11.7188V8.125C10.625 8.03906 10.5547 7.96875 10.4688 7.96875H9.53125C9.44531 7.96875 9.375 8.03906 9.375 8.125Z"
      />
    </svg>
  );
};

WarningIcon.propTypes = {
  color: PropTypes.string,
};

export default WarningIcon;

import { Space, Typography, Button } from "antd";
import { CInput } from "../../../components";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const CreateComponent = ({
  workspaceInitials,
  handleAddWorkspaceName,
  setNumberOfInputForFocus,
  numberOfInputForFocus,
  handleInputChange,
  createdWorkspace,
  inputValue,
  handleTextAreaChange,
  invitedEmails,
}) => {
  const { t } = useTranslation();

  function disabledInput() {
    if (createdWorkspace) {
      return invitedEmails?.length > 0 ? false : true;
    } else {
      return workspaceInitials.length >= 2 ? false : true;
    }
  }
  return (
    <Space direction="vertical" style={{ textAlign: "center" }}>
      <Text level={3} style={{ fontSize: "28px", fontWeight: "600" }}>
        {createdWorkspace
          ? "Workspaces are better with friends"
          : "Create a new workspace"}
      </Text>

      <div style={{ maxWidth: "456px" }}>
        <Text style={{ fontSize: "14px" }}>
          {createdWorkspace
            ? "Invite your teammates to collaborate. You can add as many as you’d like and manage permissions later on."
            : "A workspace is where you and your team can create and collaborate on contracts. Give it a nice name. (You can always change it later)."}
        </Text>
      </div>

      <Space
        direction="vertical"
        style={{ width: "346px", paddingTop: "24px" }}
      >
        {!createdWorkspace && (
          <Space size={24} style={{ display: "flex", justifyContent: "start" }}>
            <div
              style={{
                background: "#ECEDFF",
                width: "80px",
                height: "80px",
                borderRadius: "13px",
                color: "#7F89FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "26px",
                fontWeight: "500",
              }}
            >
              {workspaceInitials}
            </div>

            <Button type="ghost">Upload new icon</Button>
          </Space>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddWorkspaceName();
          }}
        >
          {!createdWorkspace ? (
            <div
              style={{
                width: "100%",
                gap: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                paddingTop: 12,
              }}
            >
              <Text style={{ fontWeight: 500 }}>Workspace name</Text>
              <div style={{ width: "100%" }}>
                <CInput
                  onClick={() => {
                    setNumberOfInputForFocus(1);
                  }}
                  onBlur={() => setNumberOfInputForFocus(0)}
                  focusOn={numberOfInputForFocus === 1}
                  onChange={handleInputChange}
                />
              </div>

              <Text
                type="secondary"
                style={{ fontSize: "12px", textAlign: "start" }}
              >
                Hint: Most people use the name of their team or organization.
              </Text>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                gap: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                paddingTop: 12,
              }}
            >
              <Text style={{ fontWeight: 500 }}>
                Work emails (comma separated)
              </Text>
              <div style={{ width: "100%", height: "153px" }}>
                <CInput
                  onClick={() => {
                    setNumberOfInputForFocus(1);
                  }}
                  onBlur={() => setNumberOfInputForFocus(0)}
                  focusOn={numberOfInputForFocus === 1}
                  onChange={handleTextAreaChange}
                  textArea
                  fullHeight
                  style={{
                    resize: "none",
                    height: "100%",
                    padding: "9px 12px",
                    borderRadius: "8px",
                  }}
                  value={inputValue}
                />
              </div>
            </div>
          )}

          <Space
            direction="vertical"
            size={8}
            style={{
              width: "100%",
              marginTop: 32,
              fontWeight: "500",
            }}
          >
            <Button
              type="primary"
              style={{
                width: "100%",
              }}
              disabled={disabledInput()}
            >
              {createdWorkspace ? "Send Invite" : "Create workspace"}
            </Button>
            <Text style={{ color: "#BBBBBE" }}>or with ENTER</Text>
          </Space>
        </form>
      </Space>
    </Space>
  );
};

export default CreateComponent;

import React, { useState, useRef, useMemo, useEffect } from "react";
import { Button, Modal, Space, Typography, Upload, Spin } from "antd";
import Icons from "../../components/Icons";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";

const { Text, Title } = Typography;

const LoadingIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

export function ConfirmPublishModal({
  isModalOpen,
  handlePublishTemplate,
  handleCancel,
  createPrompts,
  closeModal,
  isPublishDisabled,
}) {
  const navigate = useNavigate();

  const handleDocumentClick = async () => {
    await createPrompts();
    await handlePublishTemplate();
  };
  return (
    <Modal
      footer={false}
      open={isModalOpen}
      onCancel={closeModal}
      closeIcon={false}
      width={"376px"}
      destroyOnClose
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Space direction="vertical" size={24}>
          <Space
            direction="vertical"
            align="center"
            size={24}
            style={{ width: "100%", textAlign: "center" }}
          >
            <Icons.InfoIcon size={48} color="#1955C5" />
            <Space align="center" direction="vertical">
              <Title level={4} style={{ margin: 0 }}>
                Publish this template?
              </Title>
              <Text style={{ fontSize: "14px" }}>
                Your changes are still in draft. Publish this template to let
                workspace members use it.
              </Text>
            </Space>

            <Space>
              <Button
                onClick={handleCancel}
                type="ghost"
                style={{
                  width: "100%",
                  padding: "8px 32px",
                  fontSize: "16px",
                }}
              >
                Keep draft
              </Button>
              <Button
                type="primary"
                disabled={isPublishDisabled}
                style={{
                  width: "100%",
                  padding: "8px 32px",
                  fontSize: "16px",
                }}
                onClick={handleDocumentClick}
              >
                Publish template
              </Button>
            </Space>
          </Space>
        </Space>
      </div>
    </Modal>
  );
}

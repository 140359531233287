export const visibleIndexSelector = (state) =>
  state.promptsReducer.visibleIndex;
export const documentSelector = (state) => state.promptsReducer.document;
export const addressBeforeConfirmSelector = (state) =>
  state.promptsReducer.addressBeforeConfirm;
export const newDocumentdataSelector = (state) =>
  state.promptsReducer.newDocumentData;
export const newSigneeDataSelector = (state) =>
  state.promptsReducer.newSigneeData;
export const availableHeaderStepSelector = (state) =>
  state.promptsReducer.availableHeaderStep;
export const isCreatingDocumentSelector = (state) =>
  state.promptsReducer.isCreatingDocument;

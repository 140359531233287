import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({ color = "currentColor", className, size = "18" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill={color}
        d="M10.3876 8.99881L17.4188 0.617556C17.5367 0.47827 17.4376 0.266663 17.2554 0.266663H15.1179C14.992 0.266663 14.8715 0.322913 14.7885 0.419341L8.98936 7.33273L3.19025 0.419341C3.1099 0.322913 2.98936 0.266663 2.86079 0.266663H0.72329C0.541147 0.266663 0.442039 0.47827 0.559896 0.617556L7.59115 8.99881L0.559896 17.3801C0.533495 17.4111 0.516558 17.4491 0.511095 17.4895C0.505631 17.5298 0.511872 17.571 0.529075 17.6079C0.546279 17.6448 0.573723 17.6761 0.608148 17.6979C0.642574 17.7197 0.682536 17.7312 0.72329 17.7309H2.86079C2.98668 17.7309 3.10722 17.6747 3.19025 17.5783L8.98936 10.6649L14.7885 17.5783C14.8688 17.6747 14.9894 17.7309 15.1179 17.7309H17.2554C17.4376 17.7309 17.5367 17.5193 17.4188 17.3801L10.3876 8.99881Z"
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  color: PropTypes.string,
};

export default CloseIcon;

import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "../../../Forms/PromtForms/index.module.scss";
import Icons from "../../../Icons";
import { Input } from "antd";
import { isEqual, isUndefined } from "lodash";

const Select = (props) => {
  const {
    answerOptions,
    onEditPrompt,
    onUpdateVariable,
    variable,
    previewTemplate,
  } = props;
  const [options, setOptions] = useState(() => answerOptions || []);
  const [openOptions, setOpenOptions] = useState(false);
  const [currentOption, setCurrentOption] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const ulRef = useRef(null);

  const isCheckBox = useMemo(
    () => variable?.type === "checkbox",
    [variable?.type]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ulRef.current && !ulRef.current.contains(event.target)) {
        setOpenOptions(false);
      }
    };

    window.addEventListener("mouseup", handleClickOutside);

    return () => {
      window.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  function handleOpenOptions() {
    setOpenOptions(true);
  }

  async function handleCreateOption() {
    setOpenOptions(false);

    const trimmedCurrentOption = currentOption.trim();

    if (
      !trimmedCurrentOption ||
      options.some((option) => option === trimmedCurrentOption)
    )
      return;

    const updatedOptions = [...options, trimmedCurrentOption];

    await onEditPrompt({ answerOptions: updatedOptions });
    await onUpdateVariable({
      ...variable,
      options: {
        ...variable?.options,
        value: updatedOptions,
      },
    });

    setOptions(updatedOptions);
  }

  async function handleDeleteOption(value) {
    const updatedOptions = options.filter((option) => option !== value);

    if (!updatedOptions.length) return;

    await onEditPrompt({ answerOptions: updatedOptions });
    await onUpdateVariable({
      ...variable,
      options: {
        ...variable?.options,
        value: updatedOptions,
      },
    });

    setOptions(updatedOptions);
  }

  useEffect(() => {
    if (
      !isUpdating &&
      typeof answerOptions?.length === "number" &&
      (answerOptions.length !== options.length ||
        !isEqual(answerOptions, options))
    ) {
      setOptions(answerOptions);
    }
  }, [answerOptions, isUpdating, options]);

  useEffect(() => {
    if (!isUpdating && isUndefined(answerOptions) && options.length) {
      setOptions([]);
    }
  }, [answerOptions, isUpdating, options.length]);

  return (
    <ul ref={ulRef} className={styles["selects__list"]}>
      {options?.map((option, index) => {
        return (
          <li key={`options-${index}`} style={{ position: "relative" }}>
            <label className={styles["selects__label"]}>
              <span
                className={styles["selects__box"]}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <OptionItem
                  {...{
                    option,
                    options,
                    isCheckBox,
                    variable,
                    setOptions,
                    setIsUpdating,
                    onEditPrompt,
                    onUpdateVariable,
                  }}
                />
                {!isCheckBox && !previewTemplate && (
                  <div
                    style={{
                      position: "absolute",
                      top: -10,
                      right: -10,
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    onClick={async () => {
                      try {
                        setIsUpdating(true);
                        await handleDeleteOption(option);
                      } finally {
                        setIsUpdating(false);
                      }
                    }}
                  >
                    <Icons.CloseCicleFilled color="#8C8C97" size={20} />
                  </div>
                )}
              </span>
            </label>
          </li>
        );
      })}
      {!isCheckBox &&
        !previewTemplate &&
        (openOptions ? (
          <li
            style={{
              border: "1px solid #EEEEEF",
              padding: "8px 32px",
              borderRadius: "8px",
              height: "72px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <OptionInput
              autoFocus
              onChange={(e) => setCurrentOption(e.target.value)}
              onBlur={async () => {
                try {
                  setIsUpdating(true);
                  await handleCreateOption();
                } finally {
                  setIsUpdating(false);
                }
              }}
              onPressEnter={(e) => {
                // Unfocus the input on Enter key press
                e.target.blur();
              }}
            />
          </li>
        ) : (
          <li onClick={handleOpenOptions} style={{ cursor: "pointer" }}>
            <label className={styles["selects__label"]}>
              <span
                className={styles["selects__box"]}
                style={{
                  whiteSpace: "nowrap",
                  padding: 24,
                  display: "flex",
                  gap: 10,
                }}
              >
                <Icons.AddFolderIcon size={32} />
                Add options
              </span>
            </label>
          </li>
        ))}
    </ul>
  );
};

export default Select;

const OptionInput = (props) => (
  <Input
    type="text"
    bordered={false}
    style={{
      fontSize: "16px",
      width: "100%",
      minWidth: "50px",
      border: "none",
      boxShadow: "none",
      padding: "0",
      caretColor: "#5F6CFF",
      caretWidth: "5px",
      wordWrap: "break-word",
      textAlign: "center",
    }}
    {...props}
  />
);

const OptionItem = ({
  option,
  options,
  isCheckBox,
  variable,
  setOptions,
  setIsUpdating,
  onEditPrompt,
  onUpdateVariable,
}) => {
  const [editableOption, setEditableOption] = useState(option);
  const [isEditing, setIsEditing] = useState(false);

  const handleAlterOption = async () => {
    try {
      setIsUpdating(true);

      const trimmedEditableOption = editableOption.trim();

      if (
        !trimmedEditableOption ||
        options.some((option) => option === trimmedEditableOption)
      )
        return;

      const updatedOptions = options.map((currentOption) =>
        option === currentOption ? trimmedEditableOption : currentOption
      );

      await onEditPrompt({ answerOptions: updatedOptions });
      await onUpdateVariable({
        ...variable,
        options: {
          ...variable?.options,
          value: updatedOptions,
        },
      });

      setOptions(updatedOptions);
    } finally {
      setIsEditing(false);
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    if (editableOption !== option) {
      setEditableOption(option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  if (isCheckBox) return option;

  return (
    <>
      {isEditing ? (
        <OptionInput
          autoFocus
          value={editableOption}
          onChange={(e) => setEditableOption(e.target.value)}
          onBlur={handleAlterOption}
          onPressEnter={(e) => {
            e.target.blur();
          }}
        />
      ) : (
        <div
          style={{
            padding: 12,
            width: 216,
            height: "100%",
            wordWrap: "break-word",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          title={option}
          onClick={() => setIsEditing(true)}
        >
          {option}
        </div>
      )}
    </>
  );
};

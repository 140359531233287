import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import * as keys from "../../utils/keys";

import { Icons, CButton, DefaultUserImg } from "../../components";

import LOGO from "../../assets/images/logo.svg";

import styles from "./index.module.scss";

const Header = (props) => {
  const {
    progress,
    title,
    right,
    shortTitle,
    leftType,
    onLeftSideClick,
    notCreateTitle,
    dashboard,
    userData,
    hideLogo,
    dropdownOpened = () => {},
    accountData,
    stepTopBar,
    leftBlock,
    documentStyles,
    selectWorkspace,
    dashboardContainer,
    leftContainer,
    search,
  } = props;
  const [dropdown, setDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleClick = () => {
    if (dropdown) {
      dropdownOpened(false);
      setDropdown(false);
    }
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
    dropdownOpened(!dropdown);
  };

  const handleDropdownOpen = () => {
    setDropdown(true);
    dropdownOpened(true);
  };

  const handleDropdownClose = () => {
    setDropdown(false);
    dropdownOpened(false);
  };

  const { loginWithRedirect, logout, isLoading, user, isAuthenticated } =
    useAuth0();

  const setLeftHeaderSide = (type) => {
    switch (type) {
      case "arrowLeft":
        return (
          <div
            className={styles["c-header__arrow-left-icon"]}
            onClick={onLeftSideClick}
          >
            <Icons.ArrowLeft
              color={documentStyles?.color ? documentStyles.color : "#5f6cff"}
            />
          </div>
        );
      case "auth":
        return isLoading ? (
          <></>
        ) : isAuthenticated ? (
          <>
            <div className={styles["c-header__auth"]}>
              <div
                onClick={() => handleDropdown()}
                className={styles["c-header__auth__user"]}
              >
                <div className={styles["c-header__auth__photo"]}>
                  {!!accountData?.info?.logo ? (
                    <img src={accountData?.info?.logo} alt={user.name} />
                  ) : (
                    <DefaultUserImg
                      size={"30px"}
                      initials={accountData?.info?.initials}
                      initialsFontSize={"12px"}
                      initialsLineHeight={"13px"}
                    />
                  )}
                  {selectWorkspace}
                </div>
                <div className={styles["c-header__auth__detail"]}>
                  <div className={styles["c-header__auth__company"]}>
                    {accountData?.info?.name}
                  </div>
                  <div className={styles["c-header__auth__author"]}>
                    {userData?.firstName} {userData?.lastName}
                  </div>
                </div>
              </div>
              {dropdown && (
                <div className={styles["c-header__auth__dropdown"]}>
                  <div className={styles["c-header__auth__dropdown__head"]}>
                    <span>Settings</span>
                    <div
                      onClick={() => handleDropdownClose()}
                      className={styles["c-header__auth__dropdown__close"]}
                    >
                      <Icons.CloseIcon />
                    </div>
                  </div>
                  <div className={styles["c-header__auth__dropdown__body"]}>
                    <div className={styles["c-header__auth__dropdown__item"]}>
                      <Link
                        to={keys.ACCOUNT_SETTINGS}
                        className={styles["c-header__auth__dropdown__link"]}
                      >
                        <span className={styles["u-icon"]}>
                          {!!userData?.image ? (
                            <img src={userData?.image} alt={user.name} />
                          ) : (
                            <DefaultUserImg
                              size={"30px"}
                              initials={userData?.initials}
                              initialsFontSize={"9px"}
                              initialsLineHeight={"10px"}
                            />
                          )}
                        </span>
                        <span className={styles["u-name"]}>
                          Account settings
                        </span>
                      </Link>
                    </div>
                    <div className={styles["c-header__auth__dropdown__item"]}>
                      <Link
                        to={keys.WORKSPACE_MEMBERS}
                        className={styles["c-header__auth__dropdown__link"]}
                      >
                        <span className={styles["u-icon"]}>
                          <Icons.UserIcon />
                        </span>
                        <span className={styles["u-name"]}>
                          Workspace members
                        </span>
                      </Link>
                    </div>
                    <div className={styles["c-header__auth__dropdown__item"]}>
                      <Link
                        to={keys.WORKSPACE_SETTINGS}
                        className={styles["c-header__auth__dropdown__link"]}
                      >
                        <span className={styles["u-icon"]}>
                          <Icons.SettingsIcon />
                        </span>
                        <span className={styles["u-name"]}>
                          Workspace settings
                        </span>
                      </Link>
                    </div>
                    <div className={styles["c-header__auth__dropdown__item"]}>
                      <a
                        href="mailto:feedback@usercentrics.io"
                        className={styles["c-header__auth__dropdown__link"]}
                      >
                        <span className={styles["u-icon"]}>
                          <Icons.SendIcon />
                        </span>
                        <span className={styles["u-name"]}>
                          Send us feedback
                        </span>
                      </a>
                    </div>
                    <div className={styles["c-header__auth__dropdown__item"]}>
                      <div
                        onClick={() =>
                          logout({ returnTo: window.location.origin })
                        }
                        className={`${styles["c-header__auth__dropdown__link"]} ${styles["has-logout"]}`}
                      >
                        <span className={styles["u-icon"]}>
                          <Icons.LogOutIcon />
                        </span>
                        <span className={styles["u-name"]}>
                          Log out ({user.email})
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles["c-header__auth__dropdown__foot"]}>
                    <CButton
                      icon={<Icons.CreateAddFileIcon />}
                      type="white"
                      title={"Suggest a template"}
                      customStyles={styles["btn-suggest-template"]}
                      onClick={() => {
                        window.open(
                          `mailto:templates@swiftsign.io?subject=New Template Request - Account: ${accountData.accountID}`
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <CButton
            onClick={() => loginWithRedirect()}
            type="primary"
            title={"Log In"}
          />
        );

      case "arrowLeftFolders":
        return (
          <div
            className={styles["c-header__arrow-left-icon"]}
            onClick={onLeftSideClick}
          >
            <Icons.ArrowLeft width="12px" height="23px" />
          </div>
        );
      default:
        return <img src={LOGO} alt={"Swiftsign"} />;
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1023) {
        setDropdown(false);
        dropdownOpened(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <header
        className={`${styles["c-header"]} ${
          stepTopBar ? styles["c-header__wizard"] : ""
        }`}
        style={{ fontFamily: documentStyles?.font }}
      >
        {leftBlock ? (
          leftBlock
        ) : (
          <div
            className={`${styles["c-header__left-block"]} ${
              hideLogo ? styles["hide-logo"] : ""
            }`}
            style={{ ...leftContainer }}
          >
            {setLeftHeaderSide(leftType)}
          </div>
        )}
        <div className={styles["c-header__center-block"]}>
          {!!notCreateTitle && (
            <span
              className={[
                styles["c-header__center-block__title"],
                styles["c-header__center-block__title__short"],
              ].join(" ")}
            >
              {notCreateTitle}
            </span>
          )}
          {!!title && (
            <span
              className={[
                styles["c-header__center-block__title"],
                styles["c-header__center-block__title__long"],
              ].join(" ")}
            >
              {title}
            </span>
          )}
          {!!shortTitle && (
            <span
              className={[
                styles["c-header__center-block__title"],
                styles["c-header__center-block__title__short"],
              ].join(" ")}
            >
              {shortTitle}
            </span>
          )}
          {!!stepTopBar && stepTopBar}
        </div>

        {right && (
          <div className={styles["c-header__right-block"]}>{right}</div>
        )}
        {dashboard && leftType !== "arrowLeftFolders" && (
          <>
            <div
              className={styles["c-header__dashboard"]}
              style={{ ...dashboardContainer }}
            >
              {dashboard}
            </div>

            <div className={styles["c-header__buttonsContainer"]}>
              {search && search}
              <div
                onClick={() => handleDropdownOpen()}
                className={styles["c-header__settings"]}
              >
                <Icons.SettingsIcon />
              </div>
            </div>
          </>
        )}
      </header>
    </>
  );
};

Header.defaultProps = {
  progress: 0,
  title: "",
  right: "",
  notCreateTitle: "",
  userData: null,
  hideLogo: false,
  accountData: null,
};

Header.propTypes = {
  progress: PropTypes.number,
  title: PropTypes.string,
  right: PropTypes.node,
  hideLogo: PropTypes.bool,
};

export default Header;

import React from "react";
import PropTypes from "prop-types";

const CheckCircleOutlineIcon = ({
  color = "currentColor",
  size = 24,
  className,
}) => {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <g clipPath="url(#clip0_277_32906)">
        <path
          d="M17.0089 8.24023H15.7526C15.4794 8.24023 15.2196 8.37148 15.0589 8.59648L10.8481 14.4358L8.94099 11.7893C8.78028 11.567 8.52314 11.4331 8.24724 11.4331H6.99099C6.81689 11.4331 6.7151 11.6313 6.81689 11.7733L10.1544 16.4018C10.2332 16.5119 10.3372 16.6016 10.4576 16.6634C10.578 16.7253 10.7114 16.7575 10.8468 16.7575C10.9822 16.7575 11.1156 16.7253 11.236 16.6634C11.3564 16.6016 11.4604 16.5119 11.5392 16.4018L17.1803 8.58041C17.2847 8.43845 17.183 8.24023 17.0089 8.24023V8.24023Z"
          fill={color}
        />
        <path
          d="M12 0.5C5.37321 0.5 0 5.87321 0 12.5C0 19.1268 5.37321 24.5 12 24.5C18.6268 24.5 24 19.1268 24 12.5C24 5.87321 18.6268 0.5 12 0.5ZM12 22.4643C6.49821 22.4643 2.03571 18.0018 2.03571 12.5C2.03571 6.99821 6.49821 2.53571 12 2.53571C17.5018 2.53571 21.9643 6.99821 21.9643 12.5C21.9643 18.0018 17.5018 22.4643 12 22.4643Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_277_32906">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

CheckCircleOutlineIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default CheckCircleOutlineIcon;

import React, { useEffect } from "react";
import { Form, Row, Col } from "antd";
import CInput from "../../Input";
import CSelect from "../../Select";

export const TimePeriod = ({
  position,
  prompts,
  groupId,
  documentStyles,
  numberOfInputForFocus,
  setNumberOfInputForFocus,
}) => {
  const promptData = prompts.find((prompt) => prompt.position === position);
  const [form] = Form.useForm();

  useEffect(() => {
    const path = `${groupId}###value`;
    if (!form.getFieldValue(path)) {
      form.setFieldValue(path, promptData.default.value);
    }
  }, [form, groupId, promptData.default]);

  useEffect(() => {
    const path = `${groupId}###unit`;
    if (!form.getFieldValue(path)) {
      form.setFieldValue(path, promptData.default.unit);
    }
  }, [form, groupId, promptData.default]);

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 16, lg: 16 }}>
      <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
        <Form.Item
          name={`${groupId}###value`}
          initialValue={promptData.default.value}
        >
          <CInput
            label="Number"
            type="number"
            font={documentStyles?.font}
            color={documentStyles?.color}
            focusOn={numberOfInputForFocus === 0}
            onChange={() => {
              setNumberOfInputForFocus(0);
            }}
            onClick={() => {
              setNumberOfInputForFocus(0);
            }}
          />
        </Form.Item>
      </Col>
      <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }}>
        <Form.Item
          name={`${groupId}###unit`}
          initialValue={promptData.default.unit}
        >
          <CSelect
            label="Period"
            options={[
              {
                label: "Year(s)",
                value: "year",
              },
              { label: "Month(s)", value: "month" },
              { label: "Day(s)", value: "day" },
            ]}
            focusOn={numberOfInputForFocus === 1}
            onChange={() => {
              setNumberOfInputForFocus(1);
            }}
            onClick={() => {
              setNumberOfInputForFocus(1);
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

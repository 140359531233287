
import React from "react";
import PropTypes from "prop-types";

const CircleWithTickIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M13.6523 6.89392H12.7363C12.5371 6.89392 12.3476 6.98962 12.2304 7.15369L9.1601 11.4115L7.76947 9.48181C7.65229 9.3197 7.46479 9.22205 7.26361 9.22205H6.3476C6.22065 9.22205 6.14643 9.36658 6.22065 9.47009L8.65424 12.8451C8.71173 12.9253 8.78752 12.9907 8.87532 13.0358C8.96312 13.0809 9.06041 13.1045 9.15912 13.1045C9.25783 13.1045 9.35512 13.0809 9.44293 13.0358C9.53073 12.9907 9.60651 12.9253 9.66401 12.8451L13.7773 7.14197C13.8535 7.03845 13.7792 6.89392 13.6523 6.89392Z" fill="white"/>
      <path d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z" fill="white"/>
    </svg>
  );
};

CircleWithTickIcon.propTypes = {
  color: PropTypes.string,
};

export default CircleWithTickIcon;

import React from "react";
import classNames from "classnames";

import styles from "./index.module.scss";

const Stepper = ({
  prompts = [],
  currentPrompt,
  goToCard,
  documentStyles,
  canChangePosition,
}) => {
  if (!currentPrompt) return null;

  return (
    <nav className={styles["c-stepper"]}>
      <ul className={styles["list"]}>
        {prompts.map(
          ({
            shortPromptName,
            promptID,
            position,
            questionType,
            answer,
            isAllowedReceiverEdit,
          }) => {
            return (
              <li
                key={`${promptID}`}
                className={classNames(styles["listItem"], {
                  [`${styles["listItem--current"]}`]:
                    promptID === currentPrompt.promptID,
                  [`${styles["listItem--completed"]}`]:
                    promptID === currentPrompt.promptID,
                  [`${styles["listItem--clickable"]}`]:
                    !!answer && canChangePosition,
                  [`${styles["listItem--hidden"]}`]: false,
                })}
                onClick={() => {
                  if (!canChangePosition) {
                    return;
                  }
                  if (answer) {
                    goToCard({ id: promptID });
                  }
                }}
              >
                <span className={styles["c-stepper__box"]}>
                  <span
                    className={styles["c-stepper__text"]}
                    style={{ fontFamily: documentStyles?.font }}
                  >
                    {shortPromptName}
                  </span>
                </span>
              </li>
            );
          }
        )}
      </ul>
    </nav>
  );
};

export default Stepper;

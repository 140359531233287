import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as keys from "../../utils/keys";

import { SearchOutlined } from "@ant-design/icons";
import {
  Layout,
  Header,
  WorkspaceSidebar,
  RightPanel,
  Icons,
  HowCanWeHelp,
  CButton,
  QuickActions,
  WorkspaceTabs,
  NavBarBottom,
  Modals,
  WorkspacesList,
} from "../../components";

import {
  accountsSelector,
  documentsSelector,
  isAuthRequestSelector,
  redirectParamsSelector,
  userSelector,
  workspaceSelector,
  templatesSelector,
} from "../../store/selectors/auth";

import {
  updateUser,
  updateAccountData,
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  setErrorLoadingScreenType,
  setRedirectParams,
  setCurrentWorkspace,
  createDocumentFunction,
  setToken,
} from "../../store/actions/auth";
import {
  setAvailableHeaderStep,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  loadDocumentPdf,
  acceptedShare,
  setDocumentData,
} from "../../store/actions/prompts";
import { useQuery } from "../../utils/helper";

import dashboardStyles from "./index.module.scss";
import styles from "../../styles/Workspace.module.scss";
import { Divider } from "antd";
import { SEARCH_VALUE_KEY } from "../SearchDocuments";
import classNames from "classnames";

const Dashboard = ({
  userData,
  updateUser,
  isRequest,
  accounts,
  updateAccountData,
  documents,
  updateDocumentFromDashboard,
  deleteDocument,
  getSortedDocuments,
  setAvailableHeaderStep,
  getDocument,
  setIsCreatingDocument,
  setIndex,
  redirectParams,
  loadDocumentPdf,
  setErrorLoadingScreenType,
  acceptedShare,
  templates,
  createDocumentFunction,
  setDocumentData,
}) => {
  let navigate = useNavigate();
  const query = useQuery();

  const { isLoading, user, logout, isAuthenticated, loginWithRedirect } =
    useAuth0();

  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [timeMessage, getTimeMessage] = useState(null);
  const [showModalRegistration, setShowModalRegistration] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [lastEditedDocuments, setLastEditedDocuments] = useState([]);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [acceptShare, setAcceptShare] = useState(false);

  const location = useLocation();
  const accepted = query?.get("accepted");
  const documentId = query?.get("documentId");
  const shareCallback = query?.get("shareCallback");
  const passcode = query?.get("passcode");
  const dispatch = useDispatch();

  useEffect(() => {
    currentTime();
    setAvailableHeaderStep(null);
  }, []);

  useEffect(() => {
    if (
      !isUpdating &&
      !!user &&
      (!userData?.firstName || !userData?.lastName)
    ) {
      setShowModalRegistration(true);
    } else if (!user) {
      setShowSignUpModal(true);
    } else if (userData && redirectParams) {
      if (redirectParams?.reset) {
        dispatch(setRedirectParams(null));
      } else {
        navigate(`${redirectParams?.path}${redirectParams?.search}`);
      }
      setAcceptShare(true);
      setShowSignUpModal(false);
    } else {
      setAcceptShare(true);
      setShowSignUpModal(false);
    }
  }, [userData]);

  useEffect(() => {
    if (!!accounts) {
      updateAccountData(true);

      getSortedDocuments("LAST_UPDATED");
    }
  }, [accounts]);

  useEffect(() => {
    if (!!documents) {
      const editedDocuments = documents.map((item) => ({
        ...item,
      }));

      if (editedDocuments.length > 9) {
        setLastEditedDocuments(editedDocuments?.slice(0, 9));
      } else {
        setLastEditedDocuments(editedDocuments);
      }
    }

    if (shareCallback && documentId) {
      getDocument(documentId, passcode, true, () => {
        navigate(keys.REVIEW_INPUT);
      });
      return;
    }
  }, [documents]);

  useEffect(() => {
    if (acceptShare && accepted && documentId) {
      acceptedShare(documentId);
      getDocument(documentId, undefined, undefined, () => {
        navigate(keys.REVIEW_INPUT);
      });
      setAcceptShare(false);
    }
  }, [location.pathname, acceptShare]);

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  const currentTime = () => {
    const today = new Date();
    const curHr = today.getHours();

    curHr < 11
      ? getTimeMessage("Good Morning")
      : curHr >= 11 && curHr <= 18
      ? getTimeMessage("Hello")
      : getTimeMessage("Good Evening");
  };

  const onUpdateStart = () => {
    setIsUpdating(true);
  };

  const navigateSearch = () => {
    localStorage.removeItem(SEARCH_VALUE_KEY);
    navigate(keys.SEARCH, {
      state: {
        cameFromDashboard: true,
      },
    });
  };

  return (
    <Layout
      header={
        <>
          {showSignUpModal ? (
            <></>
          ) : (
            <Header
              right={<></>}
              search={
                <div>
                  <CButton
                    customStyles={classNames(
                      styles.searchIconButton,
                      styles.mobileOnly
                    )}
                    icon={<SearchOutlined />}
                    type="white"
                    onClick={navigateSearch}
                    style={{ padding: 10 }}
                    divTitle={"search documents"}
                  />
                </div>
              }
              dashboard={
                <>
                  <CButton
                    customStyles={styles.searchIconButton}
                    icon={<SearchOutlined />}
                    type="white"
                    onClick={navigateSearch}
                    style={{ padding: 10 }}
                    divTitle={"search documents"}
                  />

                  <Divider type="vertical" style={{ height: 24 }} />

                  <CButton
                    icon={<Icons.CreateAddFileIcon />}
                    type="white"
                    title={"Suggest a template"}
                    onClick={() => {
                      window.open(
                        `mailto:templates@swiftsign.io?subject=New Template Request - Account: ${accounts?.[0]?.accountID}`
                      );
                    }}
                  />
                  <CButton
                    onClick={() => navigate(keys.CREATE_DOCUMENT)}
                    icon={<Icons.PlusIcon />}
                    type="primary"
                    title={"Create document"}
                  />
                </>
              }
              selectWorkspace={() => {}}
              leftType="auth"
              userData={userData}
              accountData={accounts?.[0]}
              dropdownOpened={(isOpen) => {
                setIsMenuOpen(isOpen);
              }}
            />
          )}
        </>
      }
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
      showButtonBottom={!showSignUpModal}
      workspaces={<WorkspacesList />}
    >
      {showSignUpModal ? (
        <div className={dashboardStyles["c-dashboard__welcome"]}>
          <div className={dashboardStyles["c-dashboard__welcome__body"]}>
            <Icons.SwiftsignLogoIcon width={150} height={32} />

            <div className={dashboardStyles["c-dashboard__welcome__title"]}>
              Simple and fast contract <br /> management. All in one.
            </div>
            {/*             <div className={dashboardStyles["c-dashboard__welcome__text"]}>
              See how contract management software can help your company unlock
              efficiencies, reduce costs and minimize risks
            </div> */}
            <CButton
              type="primary"
              title={"Let’s build together"}
              onClick={() => {
                setErrorLoadingScreenType("load");
                loginWithRedirect();
                if (accepted && documentId) {
                  dispatch(
                    setRedirectParams({
                      path: "/",
                      search: `?accepted=${accepted}&documentId=${documentId}`,
                    })
                  );
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles["c-workspace"]}>
          <WorkspaceSidebar />
          <RightPanel
            children={RightPanelComponent}
            open={openRightPanel}
            onClose={() => setOpenPanelVisible(false)}
          />

          <div className={styles["c-workspace__section"]}>
            <div className={styles["c-workspace__detail"]}>
              <div className={styles["c-workspace__message"]}>
                <span>{timeMessage}</span>{" "}
                {isLoading
                  ? "Peter"
                  : isAuthenticated
                  ? `${userData?.firstName || ""}`
                  : ""}
              </div>

              <QuickActions
                templates={templates}
                createDocumentFunction={createDocumentFunction}
                setIsCreatingDocument={setIsCreatingDocument}
                setDocumentData={setDocumentData}
                setIndex={setIndex}
              />

              <WorkspaceTabs
                documents={lastEditedDocuments}
                updateDocument={updateDocumentFromDashboard}
                deleteDocument={deleteDocument}
                getSortedDocuments={getSortedDocuments}
                getDocument={getDocument}
                setIsCreatingDocument={setIsCreatingDocument}
                setIndex={setIndex}
                loadDocumentPdf={loadDocumentPdf}
                setErrorLoadingScreenType={setErrorLoadingScreenType}
              />
            </div>
          </div>
        </div>
      )}

      {!openRightPanel && !isMenuOpen && !showSignUpModal && <NavBarBottom />}

      {showModalRegistration && (
        <Modals.Registration
          onClose={() => setShowModalRegistration(false)}
          updateUser={updateUser}
          user={user}
          onUpdateStart={onUpdateStart}
          logout={logout}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userData: userSelector(state),
  isRequest: isAuthRequestSelector(state),
  accounts: accountsSelector(state),
  documents: documentsSelector(state),
  redirectParams: redirectParamsSelector(state),
  templates: templatesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser,
      updateAccountData,
      updateDocumentFromDashboard,
      deleteDocument,
      getSortedDocuments,
      setAvailableHeaderStep,
      getDocument,
      setIsCreatingDocument,
      setIndex,
      loadDocumentPdf,
      setErrorLoadingScreenType,
      acceptedShare,
      setRedirectParams,
      createDocumentFunction,
      setDocumentData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React from "react";
import PropTypes from "prop-types";

const ClockTimeIcon = ({ color = "currentColor", className }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 0C5.20156 0 0.5 4.70156 0.5 10.5C0.5 16.2984 5.20156 21 11 21C16.7984 21 21.5 16.2984 21.5 10.5C21.5 4.70156 16.7984 0 11 0ZM11 19.2188C6.18594 19.2188 2.28125 15.3141 2.28125 10.5C2.28125 5.68594 6.18594 1.78125 11 1.78125C15.8141 1.78125 19.7188 5.68594 19.7188 10.5C19.7188 15.3141 15.8141 19.2188 11 19.2188Z"
        fill={color}
      />
      <path
        d="M15.0947 13.465L11.7525 11.0486V5.2478C11.7525 5.14468 11.6682 5.0603 11.565 5.0603H10.4377C10.3346 5.0603 10.2502 5.14468 10.2502 5.2478V11.7025C10.2502 11.7634 10.2783 11.8197 10.3275 11.8548L14.2041 14.6814C14.2885 14.7423 14.4057 14.7236 14.4666 14.6416L15.1369 13.7275C15.1978 13.6408 15.1791 13.5236 15.0947 13.465Z"
        fill={color}
      />
    </svg>
  );
};

ClockTimeIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.object,
};

export default ClockTimeIcon;

import {
  DecoratorBlockNode, 
} from '@lexical/react/LexicalDecoratorBlockNode';
import { useMemo } from 'react';
// import styles from '../index.module.scss'
import { documentSelector } from '../../../store/selectors/prompts';
import { useSelector } from 'react-redux';
import { store } from '../../../store';

export class TagViewNode extends DecoratorBlockNode {
  __id;

  static getType() {
    return 'tag';
  }

  static clone(node) {
    return new TagViewNode(node.__id, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createTagViewNode(serializedNode.id);
    node.setFormat(serializedNode.format);
    return node;
  }

  isInline() {
    return true;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      type: 'tag',
      version: 1,
    };
  }

  static importDOM() {
    return {
      div: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-tag-id')) {
          return null;
        }
        return {
          conversion: convertTagElement,
          priority: 2,
        };
      },
    };
  }

  exportDOM() {
    const element = document.createElement('span');
    const thisDocument = store.getState().promptsReducer.document;
    const tag = thisDocument?.variables?.find(t => t.variableID === this.__id);
    element.innerText = tag?.value;
    element.setAttribute('data-lexical-tag-id', this.__id);
    return {element};
  }

  createDOM(_config) {
    const element = document.createElement('span');
    return element;
  }

  constructor(id, key) {
    super(key);
    this.__id = id;
  }

  getId() {
    return this.__id;
  }

  decorate(editor, config) {
    return (
      <TagViewComponent
        tagID={this.__id}
      />
    );
  }
}

export function $createTagViewNode(id) {
  return new TagViewNode(id);
}

function convertTagElement(
  domNode,
) {
  const id = domNode.getAttribute('data-lexical-tag-id');
  if (id) {
    const node = $createTagViewNode(id);
    return {node};
  }
  return null;
}

function TagViewComponent({
  tagID,
}) {
  const thisDocument = useSelector(documentSelector);

  const tag = useMemo(() => {
    return thisDocument?.variables?.find(t => t.variableID === tagID)
  }, [tagID, thisDocument.variables])

  return (
    <span>{tag?.value}</span>
  );
}


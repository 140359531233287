import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as keys from "../../utils/keys";
import { FileWordOutlined } from "@ant-design/icons";

import {
  Layout,
  RightPanel,
  HowCanWeHelp,
  NavBarBottom,
  Icons,
  CButton,
  Modals,
} from "../../components";

import { api } from "../../services/api";
import { store } from "../../store";
import { setErrorLoadingScreenType } from "../../store/actions/auth";

import documentNda from "../../assets/images/document-nda.png";
import documentLogo from "../../assets/images/document-logo.png";

import {
  updateAccountData,
  createDocumentFunction,
} from "../../store/actions/auth";
import {
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
} from "../../store/actions/prompts";

import {
  accountsSelector,
  templatesSelector,
} from "../../store/selectors/auth";
import TemplateItem from "./template-item";

import { Badge } from "antd";

import styles from "../../styles/Document.module.scss";
import { PlusOutlined } from "@ant-design/icons";

const colors = [
  "#E18264",
  "#72B1A1",
  "#EAB96D",
  "#72B1A1",
  "#EAB96D",
  "#E18264",
  "#EAB96D",
  "#E18264",
  "#72B1A1",
];

const CreateTemplate = ({
  updateAccountData,
  account,
  templates,
  createDocumentFunction,
  navi,
  setDocumentData,
  setIndex,
  setIsCreatingDocument,
}) => {
  const [openRightPanel, setOpenPanelVisible] = useState(false);
  const [RightPanelComponent, setRightPanelComponent] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [tabNumber, setTabNumber] = useState(1);
  const [currentTemplates, setCurrentTemplates] = useState([]);

  let navigate = useNavigate();

  async function handleCreateTemplate() {
    store.dispatch(setErrorLoadingScreenType("load"));
    api
      .post(
        "/template",
        {
          fileTitle: "untitled",
          content: "",
          type: "GENERATED",
        },
        {
          params: {
            origin: "BLANK",
          },
        }
      )
      .then((res) => {
        navigate(`${keys.TEMPLATE}/${res.data?.templates?.templateID}`);
      })
      .catch(() => {
        store.dispatch(setErrorLoadingScreenType("error"));
      });
  }

  const openHowCanWeHelp = () => {
    setRightPanelComponent(<HowCanWeHelp />);
    setOpenPanelVisible(true);
  };

  useEffect(() => {
    if (!!account) {
      updateAccountData(true);
    }
  }, [account]);

  useEffect(() => {
    if (!!templates) {
      if (tabNumber === 1) {
        setCurrentTemplates(
          templates?.filter((item) => item.type === "PREDEFINED")
        );
      }
    }
  }, [templates, tabNumber]);

  return (
    <Layout
      helpButtonBottom={window.innerWidth > 1182 ? `1%` : `90px`}
      onQuestionButtonClick={() => openHowCanWeHelp()}
    >
      <div className={styles["c-create-document"]}>
        <RightPanel
          children={RightPanelComponent}
          open={openRightPanel}
          onClose={() => setOpenPanelVisible(false)}
        />

        <div className={styles["c-navigation-bar"]}>
          <div className={styles["container"]}>
            <div className={styles["c-navigation-bar__title"]}>
              <Link to={keys.DASHBOARD} className={styles["link"]}>
                <Icons.ArrowLeft />
              </Link>
              Create or select template
            </div>

            <div className={styles["c-navigation-bar__nav"]}>
              <div className={styles["c-navigation-bar__nav__temp"]}>
                <div
                  className={styles["c-navigation-bar__nav__temp__item"]}
                  onClick={() => {
                    setTabNumber(1);
                  }}
                >
                  <div
                    data-nav="template-gallery"
                    className={`${styles["c-navigation-bar__nav__temp__link"]}${
                      tabNumber === 1 ? ` ${styles["active"]}` : ""
                    }`}
                  >
                    Template gallery
                  </div>
                </div>
              </div>

              {/* <div className={styles["btn-import-container"]}>
                <CButton
                  icon={<FileWordOutlined />}
                  type="white"
                  title="Import file"
                  customStyles={styles["btn-suggest-template"]}
                  onClick={() => setShowUpload(true)}
                />
                <Badge className={styles["btn-import-container__badge"]} count="Beta"/>
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles["c-create-document__body"]}>
          <div className={styles["container"]}>
            <div
              data-content="template-gallery"
              className={`${styles["template-content"]} ${styles["active"]}`}
            >
              <div className={styles["document__row"]}>
                <TemplateItem
                  noImage
                  Icon={() => <PlusOutlined className={styles["icon"]} />}
                  onClick={() => handleCreateTemplate()}
                  item={{
                    title: "Blank",
                    creatorInfo: {
                      name: "Swiftsign",
                    },
                  }}
                  color="#D9DCFF"
                  // color={colors[index % 9]}
                />
                {/* {!!currentTemplates &&
                  currentTemplates.map((item, index) => {
                    return (
                      <TemplateItem
                        key={index}
                        onClick={() => setShowModal(index + 1)}
                        item={item}
                        color={colors[index % 9]}
                      />
                    );
                  })} */}
              </div>
            </div>
            <div
              data-content="team-templates"
              className={`${styles["template-content"]}`}
            >
              Custom templates
            </div>
          </div>
        </div>
      </div>
      <NavBarBottom />
      {!!showUpload && (
        <Modals.UploadDocx onClose={() => setShowUpload(false)} />
      )}

      {!!showModal && (
        <Modals.PreviewDocument
          onClose={() => setShowModal(null)}
          templateData={currentTemplates[showModal - 1]}
          createDocumentFunction={createDocumentFunction}
          setDocumentData={setDocumentData}
          setIndex={setIndex}
          setIsCreatingDocument={setIsCreatingDocument}
        />
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: accountsSelector(state),
  templates: templatesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateAccountData,
      createDocumentFunction,
      setDocumentData,
      setIndex,
      setIsCreatingDocument,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);

import React from "react";
import styles from "./index.module.scss";

export const Stepper = (props) => {
  const { prompts, visibleIndex, setVisibleIndex } = props;

  return (
    <nav className={styles["c-stepper"]}>
      <ul>
        {prompts.map((prompt, index) => {
          if (!!prompt.defaultAnswer) return null;

          const isActive = index + 1 === visibleIndex;

          return (
            <li
              key={prompt.promptID}
              className={isActive ? styles.completed : undefined}
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setVisibleIndex(index + 1);
              }}
            >
              <span className={styles["c-stepper__box"]}>
                <span className={styles["c-stepper__text"]}>
                  {prompt.shortPromptName}
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
